
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { isNull, isNullOrEmpty } from "../../common/utils";
import { ESBuilderData } from "../models/data.model";

@Component({
    selector: "app-esbuilder-box-filter-view",
    templateUrl: "./esbuilder-box-filter-view.component.html",
    styleUrls: ["./esbuilder-box-filter-view.component.scss"],
    providers: [],
    encapsulation: ViewEncapsulation.None,
})
export class EsBuilderBoxFilterViewComponent { //implements OnInit, OnDestroy

    viewFilters: boolean = true;

    @Input()
    showToogle:boolean = true;

    //#region [ GET/SET - DataRules ]  

    _dataRules: ESBuilderData;// = new ESBuilderData();

    @Input()
    public set dataRules(dr: any) {

        if (!dr) return;
        this._dataRules = (typeof dr == "string")
            ? ESBuilderData.fromRaw(JSON.parse(dr))
            : ESBuilderData.fromRaw(dr);
    }

    public get dataRules() {
        return this._dataRules;
    }

    public get ruleExist() {

        if (isNull(this._dataRules) || this._dataRules.rules.length <= 0)
            return false;

        if (isNullOrEmpty(this._dataRules.rules[0].field))
            return false;

        return true;
    }

    //#endregion

    constructor() {
        this.dataRules = new ESBuilderData();
    }
}