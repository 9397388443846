import { ListaFiltroQueryPadraoComponent } from './../../filtro-query-padrao/lista-filtro-query-padrao/lista-filtro-query-padrao.component';
import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material';

import { Estrategia, EstrategiaAcaoCampanhaTipo } from "../../common/model/estrategia";
import { InfoExecucaoEstrategiaComponent } from "./info-execucao-estrategia/info-execucao-estrategia.component";
import { LogHistoricoComponent } from "./../../common/components/log-historico/log-historico.component";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { FluxoService } from "../../common/service/fluxo.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { Router } from "@angular/router";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import * as primeng from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Lista } from "../../common/model/lista";
import { VisualizarProvedorModalComponent } from "../visualizar-provedor-modal/visualizar-provedor-modal.component";
import { TemplateService } from "../../common/service/template.service";
import { QueryToModel } from "../../esbuilder/libs/utils";
import { ESBuilderData } from "../../esbuilder/models";

import { EstrategiaTipo } from "../../common/model/estrategia-tipo";
import { CarteiraService } from "../../common/service/carteira.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroLista } from "../../common/model/filtro-lista";
import { Carteira } from "../../common/model/carteira";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";
import { MigracaoEstrategiaComponent } from "../modais/migracao-estrategia/migracao-estrategia.component";
import { TranslateService } from "@ngx-translate/core";
import { FiltroQueryAgrupado } from "../../common/model/filtro-query-agrupado-dto";
import { FiltroQueryService } from '../../common/service/filtro-query.service';
import { FiltroPrioridadeAgrupado } from '../../common/model/filtro-prioridade-agrupado-dto';
import { ListaPrioridadePadraodService } from '../../common/service/lista-prioridade-padrao.service';
import { isNull, isNullOrEmpty } from '../../common/utils';
import { JSONParser } from '@amcharts/amcharts4/core';

@Component({
    selector: "app-lista-estrategia",
    templateUrl: "./lista-estrategia.component.html",
    styleUrls: ["./lista-estrategia.component.scss"],
    providers: [
        { provide: CarteiraService, useClass: CarteiraService },
        { provide: ListaService, useClass: ListaService },
        { provide: DefaultValuesService, useClass: DefaultValuesService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: FluxoService, provide: FluxoService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: DefaultValuesService, provide: DefaultValuesService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
        { useClass: FiltroQueryService, provide: FiltroQueryService },
        { useClass: ListaPrioridadePadraodService, provide: ListaPrioridadePadraodService }
    ]
})
export class ListaEstrategiaComponent {

    showFilter: boolean = false;
    preferenciasUsuario: PreferenciasUsuario;
    filtro: any = {};
    listaFiltroPadraoAgrupada: Array<FiltroQueryAgrupado> = [];
    listaFiltroPrioridadeAgrupada: Array<FiltroPrioridadeAgrupado> = [];
    carteiras: Array<Carteira> = [];
    listas: Array<Lista>;
    estrategiaAcaoCampanhaTipos: Array<any>;
    recorrencias: Array<any>;
    status: Array<any>;
    filtroPadrao: { label: any; value: boolean }[] = [];
    ordenacaoPadrao: { label: any; value: boolean }[] = [];

    estrategias: Array<Estrategia> = [];

    travarEscolhaLista: boolean = false;
    pesquisou: boolean = false;
    isRotaLista: boolean = false;
    size: number;
    sortField: string = 'ListarEstrategiaId';
    sortOrder: number = -1;
    totalRecords: number;
    paginaAtual: any = 0;
    tamanhoAtual: any = 10;

    public get carteirasCarregadas(): boolean {
        return (this.carteiras.length > 0);
    }

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1);
    }

    queryBaseTotal: any = {
        size: 10,
        _source: {
            includes: []
        },
        query: {
            bool: {
                must: [],
                must_not: []
            }
        }
    }


    @ViewChild(Table, { static: false }) dataTable: Table;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private carteiraService: CarteiraService,
        private customAlertService: CustomAlertService,
        private defaultValuesService: DefaultValuesService,
        private estrategiaService: EstrategiaService,
        private translateService: TranslateService,
        private fluxoService: FluxoService,
        private filtroPadraoService: FiltroQueryService,
        private filtroPrioridadePadraoService: ListaPrioridadePadraodService,
        private listaService: ListaService,
        private templateService: TemplateService,
        private preferenciasService: PreferenciasUsuarioService
    ) {

        //let filtroLista = new FiltroLista(true);
        this.defaultValuesService.obterBoolean().then(f => (this.ordenacaoPadrao = f))
        this.defaultValuesService.obterBoolean().then(f => (this.filtroPadrao = f))
        this.filtro.filtroPadrao = [];
        this.filtro.filtroPrioridadePadrao = [];

        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

        this.route.params.subscribe(params => {
            this.filtro = {};

            if (params.listaId != undefined) {
                this.filtro = { 'lista': parseInt(params.listaId) };
                this.travarEscolhaLista = true;
                this.isRotaLista = true;

                this.listaService.obterListaSemQuantidade().subscribe((listas: Array<Lista>) => {
                    let lista = listas.find(f => f.listaId == params.listaId);
                    this.filtro.carteiraId = (lista != null) ? lista.carteiraId : 1;
                    this.obterCarteiras(false);
                });
            } else {
                this.obterCarteiras();

            }

            this.obterEstrategiaAcaoCampanhaTipos();
            this.obterEstrategiaRecorrencia();
            this.obterEstrategiaStatus();
            this.obterEstrategiaFiltroPadrao();
            this.obterEstrategiaOrdenacaoPadrao();
        });
    }

    ngOnInit() {

    }

    populateFiltro() {
        this.filtro.paginar = true;
        this.filtro.paginaAtual = this.paginaAtual;
        this.filtro.tamanhoAtual = this.tamanhoAtual;
        this.filtro.sortField = this.sortField;
        this.filtro.sortOrder = this.sortOrder;
        this.filtroPadrao = this.filtroPadrao;
        this.ordenacaoPadrao = this.ordenacaoPadrao;
        this.filtro.estrategiaAcaoCampanhaTipos = (this.isRotaLista) ? [EstrategiaAcaoCampanhaTipo.Exportacao, EstrategiaAcaoCampanhaTipo.ExportacaoFluxo] : [this.filtro.estrategiaAcaoCampanhaTipoId];
    }

    populate(pageZero: boolean) {
        this.carteiras.sort((a, b) => {
            if (a.nome.toLowerCase() < b.nome.toLowerCase()) {
                return -1;
            }
            if (a.nome.toLowerCase() > b.nome.toLowerCase()) {
                return 1;
            }
            return 0;

        });


        if (this.carteirasCarregadas == false)
            return;
        this.estrategiaService.obterEstrategiasPaginadas(this.filtro).subscribe(result => {
            this.estrategias = result.data;
            this.totalRecords = result.total;
            if (pageZero)
                this.setCurrentPage(0);
        });
    }
    buscarFiltroQueryEstrategias(item) {
        return item.filter(x => x.ativo).length > 0;
    }
    buscarEstrategiaPrioridades(item) {
        return item.filter(x => x.listaPrioridadePadraoItemId != undefined).length > 0;
    }

    obterCarteiras(setCarteiraInicial: boolean = true) {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;

            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
                this.filtro.lista = this.preferenciasUsuario.listaId as number == 0 ?
                    null : this.preferenciasUsuario.listaId as number
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }

            this.obterListas();
            this.obterFiltroPadrao();
            this.obterOrdenacaoPadrao();
            this.populateFiltro();
            this.populate(false);
        });
    }

    obterListas() {
        let filtro2: any = {}
        filtro2.carteiraId = this.filtro.carteiraId;
        this.listaService.obterListaSemQuantidade(filtro2).subscribe(listas => {
            this.listas = listas.sort((a, b) => {
                if (a.nome.toLowerCase() < b.nome.toLowerCase()) {
                    return -1;
                }
                if (a.nome.toLowerCase() > b.nome.toLowerCase()) {
                    return 1;
                }
                return 0;

            })
        });

    }

    obterFiltroPadrao() {
        this.filtroPadraoService.obterFiltrosAgrupados({ 'listaId': this.filtro.lista, 'carteiraId': this.filtro.carteiraId }).subscribe((result) => {
            this.listaFiltroPadraoAgrupada = result;
        });
    }

    obterOrdenacaoPadrao() {
        this.filtroPrioridadePadraoService.obterListasPrioridadePadraoAgrupado({ 'listaId': this.filtro.lista, 'carteiraId': this.filtro.carteiraId }).subscribe((result) => {
            this.listaFiltroPrioridadeAgrupada = result;
        });
    }

    obterEstrategiaAcaoCampanhaTipos() {
        this.defaultValuesService
            .obterEstrategiaAcaoCampanhaTipos()
            .then(tipos => {
                this.estrategiaAcaoCampanhaTipos = tipos;
                this.filtro.estrategiaAcaoCampanhaTipoId = 1;
            });
    }

    obterEstrategiaRecorrencia() {
        this.recorrencias = this.listaService.obterEstrategiaRecorrencia();
    }
    obterEstrategiaFiltroPadrao() {
        this.defaultValuesService
            .obterBoolean()
            .then(lista => (this.filtroPadrao = lista));
    }
    obterEstrategiaOrdenacaoPadrao() {
        this.ordenacaoPadrao = this.listaService.obterEstrategiaOrdenacaoPadrao();
    }

    obterEstrategiaStatus() {
        this.filtro.status = 1;
        this.status = this.listaService.obterEstrategiaStatus();
    }

    carteiraChange() {
        this.filtro.lista = null;
        this.obterListas();
        this.obterFiltroPadrao();
    }

    listaChange() {
        this.filtro.filtroPadrao = []
        this.obterFiltroPadrao();
        this.obterOrdenacaoPadrao();
    }

    ativarEstrategia(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarReativacao")
            .then(() =>
                this.estrategiaService
                    .ativar(id)
                    .subscribe(() => {
                        this.filtrarEstrategias(this.filtro)
                        this.customAlertService.show("telaEstrategia.estrategia", "telaEstrategia.salvarEstrategia", "info");
                    })
            );
    }

    //#region [ Filtrar ]

    limpar() {
        this.filtro = {};
        let padrao = this.carteiras.find(f => f.padrao) as Carteira;
        this.filtro.carteiraId = padrao.carteiraId;
        this.filtro.ignorarErroPesquisa = true;
        this.travarEscolhaLista = false;
    }

    keyDownFunction($event: any) {
        this.filtrarEstrategias();
    }

    lazyPaginate(event: any) {
        if (!this.pesquisou) {
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.paginaAtual = event.first;
            this.tamanhoAtual = event.rows;
            this.populateFiltro();
            this.populate(false); 1
        }
        this.pesquisou = false;
    }

    paginate(event: any) {
        this.paginaAtual = event.first;
        this.tamanhoAtual = event.rows;

        this.populateFiltro();
        this.populate(false);
    }

    setCurrentPage(n: number) {
        if (this.dataTable)
            this.dataTable.first = n * this.tamanhoAtual;
    }

    filtrarEstrategias(filtro: any = null) {
        this.pesquisou = true;
        this.paginaAtual = 0;

        this.populateFiltro();
        this.populate(true);
    }

    download() {
        this.estrategiaService.downloadEstrategias(this.filtro);
    }

    //#endregion

    //#region [ Outros ]

    ativarEnvioImediato(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarAtivacaoEnvioImediato")
            .then(() =>
                this.estrategiaService
                    .ativarEnvioImediato(id)
                    .subscribe(() => this.filtrarEstrategias(this.filtro))
            );
    }

    ativarPendenteExecucao(estrategia) {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(estrategia.lista.listaId, estrategia.filtro)
            .subscribe((result: any) => {
                let quantidade = (result) ? result : 0;

                if (quantidade > 0) {
                    this.customAlertService
                        .confirmationMessage("telaEstrategia.confirmarReativacao")
                        .then(() =>
                            this.estrategiaService.ativarPendenteExecucao(estrategia.listaEstrategiaId).subscribe(() => this.filtrarEstrategias(this.filtro))
                        );
                } else {

                    this.customAlertService
                        .confirmationMessage("telaEstrategia.confirmarReativacaoSemRegistro")
                        .then(() =>
                            this.estrategiaService.ativarPendenteExecucao(estrategia.listaEstrategiaId).subscribe(() => this.filtrarEstrategias(this.filtro))
                        );
                }
            });
    }

    exibirEnvioImediato(estrategia: any) {
        return (estrategia.pendenteExecucao == true && estrategia.recorrente == false && estrategia.envioImediato == false && estrategia.listaEstrategiaStatusId == 1);
    }

    exibirPendenteExecucao(estrategia: any) {
        return (estrategia.pendenteExecucao == false && estrategia.recorrente == false && estrategia.listaEstrategiaStatusId == 1);
    }

    exibirForcarExecucao(estrategia: any) {
        return (estrategia.forcarExecucao == false && estrategia.recorrente && estrategia.listaEstrategiaStatusId == 1);
    }

    exibirBotaoFiltro(filtroRegras: string) {

        let dataRules: ESBuilderData = ESBuilderData.fromRaw(JSON.parse(filtroRegras));

        if (isNull(dataRules) || dataRules.rules.length <= 0)
            return false;

        if (isNullOrEmpty(dataRules.rules[0].field))
            return false;

        return true;
    }

    exibirInfoExecucao(estrategia): void {
        const dialogRef = this.dialog.open(InfoExecucaoEstrategiaComponent, {
            hasBackdrop: true,
            width: '500px',
            data: estrategia
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    forcarExecucao(estrategia) {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(estrategia.lista.listaId, estrategia.filtro)
            .subscribe((result: any) => {
                let quantidade = (result) ? result : 0;

                if (quantidade > 0) {
                    this.customAlertService
                        .confirmationMessage("telaEstrategia.forcarExecucao")
                        .then(() =>
                            this.estrategiaService.forcarExecucao(estrategia.listaEstrategiaId).subscribe(() => this.filtrarEstrategias(this.filtro))
                        );
                } else {

                    this.customAlertService
                        .confirmationMessage("telaEstrategia.forcarExecucaoSemRegistro")
                        .then(() =>
                            this.estrategiaService.forcarExecucao(estrategia.listaEstrategiaId).subscribe(() => this.filtrarEstrategias(this.filtro))
                        );
                }
            });
    }

    excluirEstrategias(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarExclusao")
            .then(() =>
                this.estrategiaService
                    .excluir(id)
                    .subscribe(() => this.filtrarEstrategias(this.filtro))
            );
    }

    duplicarEstrategia(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarDuplicarEstrategia")
            .then(() =>
                this.estrategiaService
                    .duplicarEstrategia(id)
                    .subscribe(model =>
                        this.router.navigate([`/app/estrategia/${model.listaEstrategiaId}`])
                    )
            );
    }

    mostrarPreview(filtro: any, lista: Lista, title: string) {
        let titulo = this.translateService.instant("telaEstrategia.encontrados");
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nome,
                listaId: lista.listaId,
                title: `${titulo} ${title}`,
                query: JSON.parse(filtro)
            }
        });
    }

    mostrarPreviewTotal(lista: Lista, title: string) {
        let titulo = this.translateService.instant("telaEstrategia.totaLista");
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nome,
                listaId: lista.listaId,
                title: `${titulo} ${title}`,
                query: this.queryBaseTotal
            }
        });
    }

    abrirLogHistorico(id, descricao) {

        let data = {
            id: id,
            entidade: 0,
            titulo: this.translateService.instant("telaEstrategia.estrategia") + " " + descricao
        };

        this.dialog.open(LogHistoricoComponent, {
            height: "500px",
            width: "40%",
            data
        });
    }

    visualizarProvedor(estrategia: Estrategia) {
        this.dialog.open(VisualizarProvedorModalComponent, {
            height: "450px",
            width: "50%",
            data: {
                listaEstrategiaId: estrategia.listaEstrategiaId
            }
        });
    }

    migrarFiltro(estrategia: any) {
        let camposMapeados: Array<any> = [];
        let json = estrategia.filtro;
        let dataRules: ESBuilderData | null = null;

        this.templateService.obterCamposMapeados(estrategia.lista.templateId, estrategia.lista.listaId).subscribe((result: any) => {
            camposMapeados = result.filter(f => f.itemTipo != 2);
            dataRules = QueryToModel.convert(json, camposMapeados);
        });
    }

    //#endregion


    baixarArquivo(estrategiaNome: string, listaEstrategiaTracking: any) {
        if (listaEstrategiaTracking.arquivoNome)
            this.estrategiaService.baixarHistorico(listaEstrategiaTracking.listaEstrategiaId, listaEstrategiaTracking.listaEstrategiaTrackingId, estrategiaNome);
        else
            this.customAlertService.show("telaEstrategia.download", "telaEstrategia.expurgoArquivo", "info");
    }


    migracaoEstrategias() {
        const agendamentoRef = this.dialog.open(MigracaoEstrategiaComponent, {
            hasBackdrop: true,
            width: "1000px",
            height: "750px",
            data: { "carteiraId": this.filtro.carteiraId }
        });

        agendamentoRef.afterClosed().subscribe((estrategia: Estrategia) => {
            // this.dirty = true;
            // if (!estrategia) return;

            // Object.assign(this.noSelecionado.estrategia, estrategia);
            // this.transformarFluxoNos(this.fluxo.fluxoProcesso);
        });
    }

}