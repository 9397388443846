import { BaseElasticsearchFilter } from "./base";

export class TermsElasticsearchFilter extends BaseElasticsearchFilter {
	protected getInstance(
		queryType: string,
		propertyName: string,
		parameters: any,
		extra?: any,
		keyword?: boolean
	): BaseElasticsearchFilter {
		return new TermsElasticsearchFilter(propertyName, queryType, parameters, extra, keyword);
	}

	public friendlyParameters(dataType: string, queryTypes) {
		if (Array.isArray(this.parameters))
			return `Em ${this.parameters.join(", ")}`;
		return super.friendlyParameters(dataType, queryTypes);
	}

	getConvertedInlinedContains() {
		return {
			[this.queryType]: {
				[this.property]: this.parameters.query
					.toString()
					.toLowerCase()
					.split(",")
					.map(filter => filter.trim())
			}
		};
	}

	convertFilter(): any {
		if (this.queryType == "terms" && this.parameters.query)
			return this.getConvertedInlinedContains();
		return super.convertFilter();
	}

	static fromRaw(raw): TermsElasticsearchFilter {
		return Object.assign(new TermsElasticsearchFilter(), raw);
	}
}
