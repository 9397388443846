import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { LogHistoricoAlteracao, LogHistoricoAlteracaoItem } from "../../../common/model/log-historico-alteracao";
import { LogHitoricoAlteracaoService } from "../../../common/service/log-historico-alteracao.service";
@Component({
    selector: "app-log-historico",
    templateUrl: "./log-historico.component.html",
    styleUrls: ["./log-historico.component.scss"],
    providers: [{ useClass: LogHitoricoAlteracaoService, provide: LogHitoricoAlteracaoService },]
})
export class LogHistoricoComponent {
    constructor(private ref: MatDialogRef<LogHistoricoComponent>,
        private logHitoricoAlteracaoService: LogHitoricoAlteracaoService,
        @Inject(MAT_DIALOG_DATA) public data) {
        logHitoricoAlteracaoService.obterListaLog(data.id,data.entidade).subscribe(s=>{
            this.dados = s;
            this.titulo = data.titulo;
        });
    }
    dados:any=[];
    titulo:string;
    step = 0;

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    fechar() {
        this.ref.close();
    }

}