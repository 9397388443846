import { ElementRef, HostBinding, Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-ftn-database',
    template: `<div class="databaseNodeCard {{node.data.cssClass}}" (click)="selectNodeGraph(node.id)">
                   <div class="back01">
                       <div class="back02"></div>
                   </div>
                   <div class="sub-title" [matTooltip]="node.data.listaNome">
                       {{node.data.listaNome}} ({{node.data.listaQtdRegistros}})
                   </div>
               </div>`,
    styles: []
})
export class FluxoTemplateNoDatabaseComponent {
    
    @Input()
    guidSelecionado: string;

    @Input()
    node: any;
   
    @Output()
    selectNode = new EventEmitter<string>();

    constructor() {
        this.selectNode = new EventEmitter<string>();
    }
    
    selectNodeGraph(guidId: string) {
        this.selectNode.emit(guidId);
    }
}
