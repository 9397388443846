import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatInputModule,
  MatDatepickerModule,
  MatButtonModule,
  MatGridListModule,
  MatCheckboxModule,
  MatDialogModule,
  MatSelectModule,
  MatListModule,
  MatIconModule,
  MatCardModule,
  MatProgressBarModule,
  MatDividerModule,
  MatPaginatorModule,
  MatRadioModule,
  MatTooltipModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
//import { NgxJsonViewerModule } from "ngx-json-viewer";
import { TableModule } from "primeng/table";
import { ListboxModule } from "primeng/listbox";
import { NgSelectModule } from '@ng-select/ng-select';

import { ElasticsearchFieldFilterComponent } from "./filter/elasticsearch-field-filter/elasticsearch-field-filter.component";
import { ElasticsearchFilterComponent } from "./filter/elasticsearch-filter/elasticsearch-filter.component";
import { ElasticsearchAddFilterComponent } from "./filter/elasticsearch-add-filter/elasticsearch-add-filter.component";
import { ElasticsearchEditComponent } from "./elasticsearch-edit/elasticsearch-edit.component";
import { ElasticsearchEditItemComponent } from "./elasticsearch-edit/elasticsearch-edit-item/elasticsearch-edit-item.component";
import { AppCommonModule } from "../common/common.module";
import { QuantidadesListaComponent } from "./quantidades/quantidades-lista/quantidades-lista.component";
import { ElasticsearchAddOrderComponent } from "./elasticsearch-add-order/elasticsearch-add-order.component";
import { QuantidadeFiltroListaComponent } from "./quantidades/quantidade-filtro-lista/quantidade-filtro-lista.component";
import { CardQuantidadeComponent } from "./quantidades/card-quantidade/card-quantidade.component";
import { CardConfiguravelComponent } from "./card-configuravel/card-configuravel.component";
import { CardSomarUnicosComponent } from "./card-somar-unicos/card-somar-unicos.component";
import { DateMathFilterComponent } from './filter/date-math-filter/date-math-filter.component';
import { environment } from "../../../environments/environment";
import { DateFilterComponent } from "./filter/date-filter/date-filter.component";
import { ListaElasticSearchComponent } from "./lista-elasticsearch/lista-elasticsearch.component";
import { FormElasticSearchComponent } from "./form-elasticsearch/form-elasticsearch.component";
import { ElasticsearchAddOrderModalComponent } from "./elasticsearch-add-order-modal/elasticsearch-add-order-modal.component";

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    NoopAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    FormsModule,
    //NgxJsonViewerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatPaginatorModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    AppCommonModule,
    TableModule,
    NgSelectModule,
    MatRadioModule,
    MatTooltipModule,
    ListboxModule,
    MatDatepickerModule
  ],
  declarations: [
    ElasticsearchFieldFilterComponent,
    ElasticsearchFilterComponent,
    ElasticsearchAddFilterComponent,
    QuantidadesListaComponent,
    ElasticsearchAddOrderComponent,
    QuantidadeFiltroListaComponent,
    CardQuantidadeComponent,
    CardConfiguravelComponent,
    CardSomarUnicosComponent,
    DateMathFilterComponent,
    DateFilterComponent,
    ElasticsearchEditComponent,
    ElasticsearchEditItemComponent,
    ListaElasticSearchComponent,
    FormElasticSearchComponent,
    ElasticsearchAddOrderModalComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` }}
  ],
  entryComponents: [
    ElasticsearchAddFilterComponent,
    ElasticsearchAddOrderComponent,
    ElasticsearchEditComponent,
    ElasticsearchEditItemComponent,
    CardConfiguravelComponent,
    CardSomarUnicosComponent
  ],
  exports: [
    ElasticsearchFilterComponent,
    QuantidadesListaComponent,
    ElasticsearchAddOrderComponent,
    ElasticsearchEditComponent,
    ElasticsearchEditItemComponent

  ]
})
export class ElasticsearchModule {}
