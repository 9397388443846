export function addCustomValidateCelular(validate: any) {
    validate.validators.validCelular = (value: any, options: any, key: any, attributes: any) => {

        let isNullOrEmpty = (valor: any) => (valor == undefined || valor == null || valor == '');

        if (!isNullOrEmpty(value)) {
            const regex = /^[1-9]{2}9[0-9]{4}[0-9]{4}$/;

            if (regex.test(value))
                return null;
        }

        return isNullOrEmpty(options.message) ? '^invalid' : options.message;
    };
}
