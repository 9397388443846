export class NNotificacaoTemplateImportacao {

    id: number;
    notificacaoId: number;
    listaTemplateId: number;

    constructor() { }

    static fromRaw(raw: any): NNotificacaoTemplateImportacao {
        let model: NNotificacaoTemplateImportacao = Object.assign(new NNotificacaoTemplateImportacao(), raw);
        return model;
    }
}