export enum MigracaoEstrategiaProgressoMigracao {
	inicial = 0,
	busca = 1,
	conversao = 2,
	gravacao = 3,
	finalizacao = 4
}

export enum MigracaoEstrategiaRegistroStatus {
	encontrado = 0,
	convertido = 1,
	erroConversao = 2,
	gravacao = 3,
	erroGravacao = 4,
	sucesso = 5
}

export class MigracaoEstrategiaModel {
	estrategiaId: number;
	estrategiaNome: string;
	estrategiaFiltro: string;
	estrategiaFiltroRegras: string;
	listaId: number;
	listaAlias: string;
	listaNome: string;
	templateId: number;
	camposMapeados: Array<any>;
	status: MigracaoEstrategiaRegistroStatus;

	constructor() { }

	static fromRaw(raw: any): MigracaoEstrategiaModel {
		return Object.assign(new MigracaoEstrategiaModel(), raw);
	}
}

export class MigracaoEstrategiaQuantidadesModel {
	total: number;
	ativas: number;
	inativas: number;
	migradas: number;
	naoMigradas: number;

	static fromRaw(raw: any): MigracaoEstrategiaQuantidadesModel {
		return Object.assign(new MigracaoEstrategiaQuantidadesModel(), raw);
	}
}