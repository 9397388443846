import { ReguaEstrategia } from './regua-estrategia';
const validate = require("validate.js");

export class Regua {
    reguaId: number;
    nome: string;
    listaId: number;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;
    reguaEstrategia: Array<ReguaEstrategia>;

    nomeUsuarioCadastro: string;

    constructor() {
        this.reguaEstrategia = [];
    }

    validar() {
        let validacao: any = {
            'nome': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
            'listaId': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(raw: any): Regua {
        let regua: Regua = Object.assign(new Regua(), raw);
        regua.reguaEstrategia = regua.reguaEstrategia.map((m: any) => ReguaEstrategia.fromRaw(m))
        return regua;
    }
}