export class DownloadRegistro {
    public downloadRegistroId: number;
    public listaId: number;
    public listaNome: string;
    public filtro: string;
    public usuarioCadastro: number;
    public dataCadastro: Date;
    public downloadRegistroStatusId: DownloadRegistroStatus;
    public dataInicioProcessamento: Date;
    public dataFimProcessamento: Date;
    public quantidadeRegistro: number;
    public diretorioArquivo: string;
    public nomeArquivo: string;
    public porcentagem: number;
    public restantes: number;
    public processados: number;
    public nomeUsuario: string;
    public ipUsuario: string;

    static fromRaw(raw: any): DownloadRegistro {
        return Object.assign(new DownloadRegistro(), raw);
    }    
}

export enum DownloadRegistroStatus {
    Pendente = 1,
    Processando = 2,
    Concluido = 3,
    Erro = 4
}