import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { convertToQueryString } from "../../common/service/query-string";
import { FiltroQuery } from "../model/filtro-query";
import { FiltroQueryAgrupado } from "../model/filtro-query-agrupado-dto";

@Injectable()
export class FiltroQueryService {
	private api: string;

    constructor(private httpClient: HttpClient) { 
        this.api = '/api/filtroquery';
    }

	public obterFiltros(filtro: any) {
		return this.httpClient.get<Array<FiltroQuery>>(`${this.api}/filtros-query${convertToQueryString(filtro)}`)
			.pipe(map(r => r.map(m => FiltroQuery.fromRaw(m))));
	}

	public obterFiltrosAgrupados(filtro: any) {
		return this.httpClient.get<Array<FiltroQueryAgrupado>>(`${this.api}/filtros-query/agrupado-lista${convertToQueryString(filtro)}`)
			.pipe(map(r => r.map(m => FiltroQueryAgrupado.fromRaw(m))));
	}

	public obterPorId(id) {
		return this.httpClient.get<FiltroQuery>(`${this.api}/filtro-query/${id}`)
			.pipe(map(query => FiltroQuery.fromRaw(query)));
	}

	public criar(filtroQuery: FiltroQuery) {
		return this.httpClient.post<number>(`${this.api}/filtro-query/`, filtroQuery);
	}

	public atualizar(filtroQueryId: number, filtroQuery: FiltroQuery) {
		return this.httpClient.put<number>(`${this.api}/${filtroQueryId}/filtro-query/`, filtroQuery);
	}

	public vincularEstrategias(filtroQueryId: number, filtroQueryEstrategiasAlterar: Array<any>) {
		return this.httpClient.put<number>(`${this.api}/${filtroQueryId}/vincular-estrategias/`, filtroQueryEstrategiasAlterar);
	}

	public desativar(id: any) {
		return this.httpClient.delete<number>(`${this.api}/${id}`)
	}
}
