import { Pipe, PipeTransform } from "@angular/core";
import * as dayjs from 'dayjs';

@Pipe({
  name: "dateFormat"
})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date, args?: any): any {
    if (!value) return "";
    if (!args) return dayjs(value).format("DD/MM/YYYY");
    return dayjs(value).format("DD/MM/YYYY HH:mm:ss");
  }
}
