import { isNull, isNullOrEmpty, isNullOrZero, isObjectEmpty } from './isNull';
import { esbFormatFieldName } from './esbFormatFieldName';

import { ESBuilderData, ESBuilderQuery, ESBuilderQueryBool, ESBuilderRules, ESBuilderRulesConditions, ESBuilderFilterType } from "../../models";
import { filter } from 'core-js/fn/array';


export class ModelToModelReverse {

    public static convert(data: ESBuilderData) {

        // data = ESBuilderData.fromRaw({
        //     "rules": [
        //         {
        //             "condition": "none",
        //             "field": "Inteiro",
        //             "fieldType": "Inteiro",
        //             "filterType": "maior_que",
        //             "filterValues": {
        //                 "gt": "50"
        //             },
        //             "order": 0,
        //             "filterParameters": [
        //                 {
        //                     "name": "gt",
        //                     "alias": "Maior que"
        //                 }
        //             ]
        //         }
        //     ],
        //     "type": "bool"
        // });

        if (isNullOrEmpty(data))
            return {};

        let dataReverse: ESBuilderData = new ESBuilderData();
        dataReverse.type = data.type;
        dataReverse.includeIntegratedRegister = data.includeIntegratedRegister;

        data.rules.forEach((ruleItem: ESBuilderRules, index: number) => {
            if (ruleItem.reverse == false) {
                dataReverse.rules.push(ruleItem);

            } else {

                let fieldType = ESBuilderFilterType[ruleItem.fieldType];
                let filterType = (ruleItem.fieldType == 'Nested')
                    ? fieldType.find((f: any) => (f.nestedType == ruleItem.fieldNestedType && f.filterType == ruleItem.filterType))
                    : fieldType.find((f: any) => (f.filterType == ruleItem.filterType));
                let filterTypeReverse = fieldType.find((f: any) => (f.filterType == filterType.reverse.filterType));

                // Create new RuleItem
                let item = new ESBuilderRules(ruleItem);

                if (index == 0 && item.condition !== ESBuilderRulesConditions.none)
                    item.condition = ESBuilderRulesConditions.none;

                // Reverse Condition
                if (ruleItem.notReverseCondition !== true && item.condition != ESBuilderRulesConditions.none)
                    item.condition = (item.condition == ESBuilderRulesConditions.and) ? ESBuilderRulesConditions.or : ESBuilderRulesConditions.and;

                // Reverse FilterType
                item.filterType = filterTypeReverse.filterType;

                if (Array.isArray(ruleItem.filterValues)) {
                    item.filterValues = ruleItem.filterValues;

                } else {

                    // Reverse FilterValues
                    item.filterValues = {};

                    ruleItem.filterParameters.forEach((prm: any, idx: number) => {

                        if (filterTypeReverse.reverse.value) {
                            item.filterValues[filterTypeReverse.parameters[idx].name] = this.filterValueReverse(filterTypeReverse.reverse.filterType, ruleItem.filterValues[prm.name])
                        }
                        else if (filterTypeReverse.reverse.negation) {
                            item.reverseNegation = true;
                            item.filterValues[filterTypeReverse.parameters[idx].name] = ruleItem.filterValues[prm.name];
                        }
                        else {
                            item.filterValues[filterTypeReverse.parameters[idx].name] = ruleItem.filterValues[prm.name];
                        }
                    });
                }


                // Reverse FilterParameters
                item.filterParameters = filterTypeReverse.parameters;


                dataReverse.rules.push(item);
            }
        });

        return dataReverse;

    }


    private static filterValueReverse(filterType: string, value: any) {
        if (filterType == 'regexp')
            return `~(${value})`;

        if (filterType == 'true' || filterType == 'false')
            return !value;

        return null;
    }
}
