import { isNull, isNullOrEmpty, isObjectEmpty } from "../utils";
import { addCustomValidateChildrenArray, addCustomValidateDependsAnotherFieldValue } from "../validators";
import { NNotificacaoTipoAutenticacao } from "./nnotificacao-tipo-autenticacao";

const validate = require("validate.js");

export class NNotificacaoConexaoApi {
    caminhoIntegracao: string;
    metodoIntegracao: string;                       // GET, POST, DELETE, PUT
    envioTipo: number;			                    // Define se o envio é individual ou por lista 
    tipoAutenticacao: NNotificacaoTipoAutenticacao;
    caminhoLogin: string | null;
    metodoLogin: string | null;                     // GET, POST, DELETE, PUT
    usuario: string | null;
    senha: string | null;
    clientId: string | null;
    clientSecret: string | null;
    scope: string | null;
    grantType: string | null;
    apiKey: string | null;
    tokenProperty: string | null;
    token: string | null;
    campoUsuario: string | null;
    campoSenha: string | null;
    campoToken: string | null;

    private _campos: any;

    constructor() {

        //this.nome = GuidService.newGuid();
        this.camposPorTipoAutenticacao();

        addCustomValidateChildrenArray(validate);

        addCustomValidateDependsAnotherFieldValue(validate);

    }

    //#region   

    tipoAutenticacaoChange() {
        this.limparCamposAutenticacao();
        this.camposPorTipoAutenticacao();
    }

    limparCamposAutenticacao() {
        this.caminhoLogin = null;
        this.metodoLogin = null;
        this.usuario = null;
        this.senha = null;
        this.clientId = null;
        this.clientSecret = null;
        this.scope = null;
        this.grantType = null;
        this.apiKey = null;
        this.tokenProperty = null;
        this.token = null;
        this.campoUsuario = null;
        this.campoSenha = null;
        this.campoToken = null;
    }

    mostrarCamposAutenticacao(campo: string | null): boolean {

        if (isNull(this._campos))
            return false;

        let mostrar = Object.entries(this._campos)
            .filter(([key, value]) => value) // Filtra pares chave-valor onde o valor é true
            .reduce((acc, [key, value]) => {
                acc[key] = value; // Adiciona ao objeto acumulador apenas as propriedades true
                return acc;
            }, {});

        if (isNullOrEmpty(campo))
            return (isObjectEmpty(mostrar)) ? false : true;
        else
            return (isObjectEmpty(mostrar)) ? false : Object.keys(mostrar).some((f: string) => f == campo);
    }

    camposPorTipoAutenticacao() {
        this._campos = {
            "caminhoLogin": false, "metodoLogin": false, "usuario": false, "senha": false, "clientId": false, "clientSecret": false, "scope": false,
            "grantType": false, "apiKey": false, "tokenProperty": false, "token": false, "campoUsuario": false, "campoSenha": false, "campoToken": false
        };

        switch (this.tipoAutenticacao) {
            case NNotificacaoTipoAutenticacao.APIKey:
                this._campos.apiKey = true;
            case NNotificacaoTipoAutenticacao.CorpoMensagem:
                break;
            case NNotificacaoTipoAutenticacao.Basic:
                this._campos.usuario = true;
                this._campos.senha = true;
                break;
            case NNotificacaoTipoAutenticacao.OAuth:
                this._campos.caminhoLogin = true;
                this._campos.metodoLogin = true;
                this._campos.grantType = true;
                this._campos.usuario = true;
                this._campos.senha = true;
                this._campos.clientId = true;
                this._campos.clientSecret = true;
                this._campos.scope = true;
                this._campos.campoUsuario = true;
                this._campos.campoSenha = true;
                this._campos.campoToken = true;
                break;
            case NNotificacaoTipoAutenticacao.UrlToken:
                this._campos.tokenProperty = true;
                this._campos.token = true;
                break;
            case NNotificacaoTipoAutenticacao.AllowAnonymous:
                break;
            case NNotificacaoTipoAutenticacao.Jwt:
                this._campos.caminhoLogin = true;
                this._campos.metodoLogin = true;
                this._campos.usuario = true;
                this._campos.senha = true;
                this._campos.campoUsuario = true;
                this._campos.campoSenha = true;
                this._campos.campoToken = true;
            default:
                break;
        }
    }

    static fromRaw(raw: any): NNotificacaoConexaoApi {
        let model: NNotificacaoConexaoApi = Object.assign(new NNotificacaoConexaoApi(), raw);
        return model;
    }

    // toJSON method to control serialization
    toJSON() {
        return {

            caminhoIntegracao: this.caminhoIntegracao,
            metodoIntegracao: this.metodoIntegracao,
            envioTipo: this.envioTipo,
            tipoAutenticacao: this.tipoAutenticacao,
            caminhoLogin: this.caminhoLogin,
            metodoLogin: this.metodoLogin,
            usuario: this.usuario,
            senha: this.senha,
            clientId: this.clientId,
            clientSecret: this.clientSecret,
            scope: this.scope,
            grantType: this.grantType,
            apiKey: this.apiKey,
            tokenProperty: this.tokenProperty,
            token: this.token,
            campoUsuario: this.campoUsuario,
            campoSenha: this.campoSenha,
            campoToken: this.campoToken
        };
    }

    //#endregion  

    validate() {

        let rules: any = {
            'caminhoIntegracao': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            },
            'metodoIntegracao': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            },
            'envioTipo': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            },
            'tipoAutenticacao': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            },
            'caminhoLogin': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4, 8],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'metodoLogin': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4, 8],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'grantType': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'usuario': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [3, 4, 8],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'senha': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [3, 4, 8],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'clientId': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'clientSecret': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'scope': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'campoUsuario': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4, 8],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'campoSenha': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4, 8],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'campoToken': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [4, 8],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'apiKey': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [1],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'tokenProperty': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [5],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            },
            'token': {
                'validDependsAnotherFieldValue': {
                    'anotherField': 'tipoAutenticacao', 'anotherFieldValue': [5],
                    'rules': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
                }
            }
        };

        return validate.validate(this, rules);
    }
}
