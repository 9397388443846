import * as validate from "validate.js";

export class FiltroDashIndividual {
    dataInicio: Date;
    dataFim: Date;
    listaTemplateItemId: number;
    valor: string;
    listaId: number;
    listaItemId: number;
    carteiraId: number;

    constructor() {
        this.dataFim = new Date();
        this.dataInicio = new Date(
            this.dataFim.getFullYear(),
            this.dataFim.getMonth(),
            this.dataFim.getDate()
        );
    }

    validar() {
        var validacao: any = {
            listaTemplateItemId: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                }
            },
            valor: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                }
            },            
            dataInicio: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                },
                dataMenorQue: {
                    campo: "dataFim"
                }
            },
            dataFim: {
                presence: {
                    allowEmpty: false,
                    message: "campoObrigatorio"
                }
            }
        };

        return validate(this, validacao, { fullMessages: false })
    }
}