import { ListaService } from "../service/lista.service";
const validate = require("validate.js");


export enum ImportacaoAutomaticaTipo {
    arquivo = 1,
    ftp = 2,
    sftp = 3
}

export class ImportacaoAutomaticaConfiguracao {

    importacaoAutomaticaConfiguracaoId: number;
    listaId: number;
    tipo: ImportacaoAutomaticaTipo;
    diretorio: string;
    host: string;
    username: string;
    password: string;
    frequencia: string;
    intervalo: number;
    horaExecucao: any;
    dataInicio: Date;
    dataTermino: Date;
    diaSemana: string;
    expressaoRecorrencia: string;
    proximaExecucao: Date;
    ultimaExecucao: Date;
    forcarExecucao: boolean;
    dataCadastro: Date;
    usuarioCadastro: string;
    dataAlteracao: Date;
    usuarioAlteracao: Date;
    ativo: boolean;
    alterado: boolean;
    dominio: string;
    retryAtivo: boolean;
    retryQuantidade :number;
    retryMinutos: number;

    _comDataFim: boolean;

    public get isArquivo(): boolean {
        //return (this.tipo == 2 || this.tipo == 3);
        return this.tipo == 1;
    }

    public get isFtp(): boolean {
        return (this.tipo == 2 || this.tipo == 3);
        //return this.tipo == 2;
    }

    public get isSftp(): boolean {
        //return (this.tipo == 2 || this.tipo == 3);
        return this.tipo == 3;
    }

    public get comDataFim(): boolean {
        return this._comDataFim != undefined ? this._comDataFim : this.dataTermino != undefined;
    }

    public set comDataFim(valor: boolean) {
        if (!valor)
            this.dataTermino = undefined;

        this._comDataFim = valor;
    }

    static fromRaw(raw: any) {
        let importacaoConfiguracao = new ImportacaoAutomaticaConfiguracao();
        Object.assign(importacaoConfiguracao, raw);
        return importacaoConfiguracao;
    }

    constructor() {

        this.tipo = ImportacaoAutomaticaTipo.arquivo;

        validate.validators.dataMenorQue = (value: any, options: any, key: any, attributes: any) => {
            if (value >= attributes[options.campo])
                return "dataMaior";
        };
        validate.validators.dataMaiorQue = (value: any, options: any, key: any, attributes: any) => {
            if (value <= attributes[options.campo])
                return "dataMenor";
        };
        validate.validators.diretorioVirtual = (value: any) => {
            var validRegex = /^(\\\\[^/\\\]\[":;|<>+=,?* _]+\\[^/\\\]\[":;|<>+=,?*]+)((?:\\[^\\/:*?"<>|]+)*\\?)$/i

           /* if (!validRegex.test(value))
                return "^caminhoInvalido";*/
        };

        validate.validators.diretorioExiste = (value: any, options: any, key: any, attributes: any) => {
            let service = options.service;
            let username = options.username;
            let password = options.password;
            let dominio = options.dominio;
            
            return new validate.Promise(function (resolve, reject) {
                service.validarDiretorio({ 'path': value, 'username': username, 'password': password, 'dominio': dominio })  
                    .subscribe((diretorio: any) => {
                        if (diretorio.exists == false)
                            reject({ "diretorio": ["diretorioInexistente"] });

                        resolve({});
                    });
            });
        };
    }

    validar() {

        let validacao: any = {
            frequencia: { presence: { allowEmpty: false } },
            horaExecucao: { presence: { allowEmpty: false } },
            dataInicio: { presence: { allowEmpty: false } }
        };

        if (this.frequencia == "1" || this.frequencia == "2") {
            validacao.intervalo = {
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                numericality: { onlyInteger: true, greaterThan: 0, message: "^menorque0" }
            };
        }

        if (this.retryAtivo)
        {
            validacao.retryQuantidade = {
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                numericality: { onlyInteger: true, greaterThan: 0, message: "^menorque0" }
            };

            validacao.retryMinutos = {
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                numericality: { onlyInteger: true, greaterThan: 0, message: "^menorque0" }
            };
        }

        if (this.comDataFim) {
            validacao.dataTermino = {
                presence: { allowEmpty: false },
                dataMaiorQue: { campo: "dataInicio" }
            };
        }

        if (this.tipo == ImportacaoAutomaticaTipo.arquivo) {
            validacao.diretorio = {
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                diretorioVirtual: {}
            };
        } else {
            validacao.diretorio = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
            //validacao.host = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
            validacao.username = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
            validacao.password = { presence: { allowEmpty: false, message: '^campoObrigatorio' } };
        }

        return validate.validate(this, validacao);
    }

    validarDiretorioExisteAsync(listaService: ListaService) {

        if (this.tipo != ImportacaoAutomaticaTipo.arquivo) return;

        var validacao: any = {
            diretorio: { diretorioExiste: { 'service': listaService, 'username': this.username, 'password': this.password, 'dominio': this.dominio,  } }
        };

        return validate.async(this, validacao);
    }
}