import { ElementRef } from "@angular/core";

export function generateCanvas(canvas: ElementRef<HTMLCanvasElement>, percentage: number, color: string) {
    if (canvas == undefined)
        return;

    let percentToRadians = (pct: number) => {
        // convert the percentage into degrees
        let degrees = pct * 360 / 100;

        // and so that arc begins at top of circle (not 90 degrees) we add 270 degrees
        return ((degrees + 270) / 180) * Math.PI
    };

    percentage = percentage > 100 ? 100 : percentage;
    percentage = Math.round(percentage * 100) / 100;

    let context = canvas.nativeElement.getContext('2d') as CanvasRenderingContext2D;
    context.clearRect(0, 0, 60, 60);
    let x = 30;
    let y = 30;
    let radius = 25;

    var startAngle = percentToRadians(0);
    var endAngle = percentToRadians(percentage);

    // set to true so that we draw the missing percentage
    var counterClockwise = true;

    context.beginPath();

    if (percentage == 0) {
        context.arc(x, y, radius, startAngle, percentToRadians(1), counterClockwise)
    } else {
        context.arc(x, y, radius, startAngle, endAngle, counterClockwise);
    }

    context.lineWidth = 10;

    // line color
    context.strokeStyle = '#f3f2f2';
    context.stroke();

    // set to false so that we draw the actual percentage
    counterClockwise = false;

    context.beginPath();

    if (percentage == 0) {
        context.arc(x, y, radius, startAngle, percentToRadians(1), counterClockwise)
    } else {
        context.arc(x, y, radius, startAngle, endAngle, counterClockwise);
    }
    context.lineWidth = 10;

    // line color
    context.strokeStyle = (percentage == 0) ? '#f3f2f2' : color;
    context.stroke();

    // now draw the inner text
    context.font = radius / 2.5 + "px Helvetica";
    context.fillStyle = color;
    context.textAlign = "center";
    // baseline correction for text
    context.fillText(percentage + "%", x, y * 1.05);

    return context;
}