export const FormatacaoCampo = [
	{ nome: "Dia", tipo: "Data", valor: "dd" },
	{ nome: "Mês", tipo: "Data", valor: "MM" },
	{ nome: "Ano", tipo: "Data", valor: "yyyy" },
	{ nome: "Dia/Mês/Ano", tipo: "Data", valor: "dd/MM/yyyy" },
	{ nome: "Dia/Mês/Ano Hora:Min:Seg", tipo: "Data", valor: "dd/MM/yyyy HH:mm:ss" },
	{ nome: "Hora:Min:Seg", tipo: "Data", valor: "HH:mm:ss" },
	{ nome: "Dia da semana, Mês Dia,Ano", tipo: "Data", valor: "dddd, MMMM d, yyyy" },
	{ nome: "Monetário", tipo: "Decimal", valor: "C2" },
	{ nome: "Inteiro", tipo: "Decimal", valor: "N0" },
	{ nome: "2 casas decimais", tipo: "Decimal", valor: "N2" },
	{ nome: "3 casas decimais", tipo: "Decimal", valor: "N3" },
	{ nome: "Porcentagem", tipo: "Decimal", valor: "P" },
	{ nome: "Inteiro", tipo: "Inteiro", valor: "N0" },
	{ nome: "Porcentagem", tipo: "Inteiro", valor: "P" },
	{ nome: "Inteiro", tipo: "Long", valor: "N0" },
	{ nome: "Porcentagem", tipo: "Long", valor: "P" }
];
