import { GuidService } from "../service/guid.service";
import { NNotificacaoTipoEvento } from "./nnotificacao-tipo-evento";

export class NNotificacaoCampos {
    id: number;
    nome: string;
    listaCampoTipoId: number;
    notificacaoTipoEventoId: NNotificacaoTipoEvento;
    permiteNulo: boolean;
    ativo: boolean;

    constructor() { }

    static fromRaw(raw: any): NNotificacaoCampos {
        let model: NNotificacaoCampos = Object.assign(new NNotificacaoCampos(), raw);
        return model;
    }
}

export class NNotificacaoCamposCliente {
    id: string;
    notificacaoCamposId: number;
    notificacaoCamposNome: string;
    nome: string;
    listaCampoTipoId: number;
    tipoCampo: number;
    conteudo: string | null;
    ordem: number;
    ativo: boolean;

    constructor() { }

    static fromRaw(raw: any): NNotificacaoCamposCliente {
        let model: NNotificacaoCamposCliente = Object.assign(new NNotificacaoCamposCliente(), raw);
        return model;
    }

    static instance(notificacaoCamposApiId: number, nome: string, ordem: number): NNotificacaoCamposCliente {
        let model: NNotificacaoCamposCliente = Object.assign(new NNotificacaoCamposCliente(), {
            'id': GuidService.newGuid(),
            'notificacaoCamposId': notificacaoCamposApiId,
            'notificacaoCamposNome': nome,
            'nome': '',
            'tipoCampo': 0,
            'conteudo': null,
            'ordem': ordem,
            'ativo': true
        });
        return model;
    }

}




