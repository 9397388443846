import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
	MatListModule,
	MatChipsModule,
	MatButtonModule,
	MatDividerModule,
	MatProgressBarModule,
	MatIconModule,
	MatSelectModule,
	MatInputModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatTooltipModule,
	MatTabsModule,
	MatCheckboxModule,
	MatCardModule,
	MatPaginatorModule,
	MatRadioModule,
	MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
import { TableModule } from "primeng/table";
import { FileUploadModule } from 'primeng/fileupload';
import { AmazingTimePickerModule } from "amazing-time-picker";
import { TextInputAutocompleteModule } from "angular-text-input-autocomplete";

import { TemplateModule } from "../template/template.module";
import { ElasticsearchModule } from "../elasticsearch/elasticsearch.module";
import { AppCommonModule } from "../common/common.module";

import { ListaTarefaComponent } from "./lista-tarefa/lista-tarefa.component";
import { FiltroTarefaComponent } from "./lista-tarefa/filtro-tarefa/filtro-tarefa.component";
import { environment } from "../../../environments/environment";
import { SituacaoExecucaoPipe } from "./pipes/situacao-execucao.pipe";
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomToolTipComponent } from "../common/directives/custom-tool-tip/custom-tool-tip.component";
import { ToolTipRendererDirective } from "../common/directives/tool-tip-renderer.directive";

@NgModule({
	imports: [
		AppCommonModule,
		CommonModule,
		MatListModule,
		MatChipsModule,
		MatButtonModule,
		MatDividerModule,
		MatProgressBarModule,
		MatIconModule,
		MatSelectModule,
		MatInputModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatTabsModule,
		TemplateModule,
		FormsModule,
		MatTooltipModule,
		MatCheckboxModule,
		AmazingTimePickerModule,
		MatCardModule,
		TableModule,
		ElasticsearchModule,
		MatPaginatorModule,
		MatRadioModule,
		TextInputAutocompleteModule,
		FileUploadModule,
		NgSelectModule
	],
	entryComponents: [
	
	],
	providers: [
		{ provide: LOCALE_ID, useValue: "pt-BR" },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` } }
	],
	declarations: [
		ListaTarefaComponent,
		FiltroTarefaComponent,
		SituacaoExecucaoPipe
	]
})
export class TarefaModule { }
