export class NNotificacaoListaEstrategia {

    id: number;
    notificacaoId: number;
    listaEstrategiaId: number;

    constructor() { }

    static fromRaw(raw: any): NNotificacaoListaEstrategia {
        let model: NNotificacaoListaEstrategia = Object.assign(new NNotificacaoListaEstrategia(), raw);
        return model;
    }
}