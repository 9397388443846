import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NNotificacao } from "../../common/model/nnotificacao";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { NotificacaoService } from "../../common/service/notificacao.service";
import { NotificacaoEventoPipe } from "../libs/pipes/notificacao-evento-pipe"
import { NNotificacaoCampos, NNotificacaoCamposCliente, NNotificacaoCarteira, NNotificacaoConexao, NNotificacaoDestinatario, NNotificacaoEvento, NNotificacaoLista, NNotificacaoListaEstrategia, NNotificacaoTemplateImportacao, NNotificacaoTipoEnvio, NNotificacaoTipoEvento } from "../../common/model/nnotificacao.index";
import { isNull, isNullOrEmpty, isNullOrZero, isObjectEmpty } from "../../common/utils";
import { CarteiraService } from "../../common/service/carteira.service";
import { TemplateService } from "../../common/service/template.service";
import { ListaService } from "../../common/service/lista.service";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { QueryFilter, SorterContainer } from "../../common/service/query-filter.dto";
import { LazyLoadEvent } from "primeng/api";
import { capitalizeFirstLetter } from "../../common/utils/string-utils";
import { NNotificacaoEventoConexao } from "../../common/model/nnotificacao-evento-conexao";
import { LanguageService } from "../../common/service/language.service";
@Component({
    selector: "app-notificacao-eventos",
    templateUrl: "./notificacao-eventos.component.html",
    styleUrls: ["./notificacao-eventos.component.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: ListaService, provide: ListaService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: LanguageService, provide: LanguageService },
        { useClass: NotificacaoEventoPipe, provide: NotificacaoEventoPipe }
    ]
})
export class NotificacaoEventosComponent implements OnInit {

    tabSelected: number = 0;
    idiomas: any[] = [];
    eventosTipos: any[] = [];
    enviosTipos: any[] = [];
    carteiras: Array<any> = [];
    templatesImportacao: Array<any> = [];
    listas: Array<any> = [];
    estrategias: Array<any> = [];
    listaConexoes: Array<NNotificacaoConexao> = [];
    camposApi: Array<NNotificacaoCampos> = [];
    submitted: boolean = false;
    notificacao: NNotificacao | null;

    //#region [ Propriedades do grid ]

    filtroPesquisa = {};

    // Table properties
    first: number = 0;
    rows: number = 10;
    totalRecords: number = 0;
    loading: boolean = true;

    // Back-end filter
    identifier: string = "DataCadastro";
    direction: number = -1;
    queryFilter: QueryFilter;

    listaNotificacoes: Array<NNotificacao> = []

    //#endregion

    //#region [ Gets ]

    public get isForm(): boolean {
        return (isNull(this.notificacao) == false);
    }

    public get isEdit(): boolean {
        return (isNullOrEmpty(this.notificacao.id) == false)
    }

    //#endregion

    //#region [ LiveError ]

    liveError(property: string, message: string) {
        if (this.submitted) {
            let result = this.notificacao.validate();
            if (!result) return null;

            if (isNullOrEmpty(message))
                return (isNull(result[property]) == false);
            else
                return result[property] ? result[property][0] == message : false;
        }
    }

    liveErrorFiltros(property: string, message: string) {
        if (this.submitted) {
            let result = this.notificacao.validateFiltros();
            if (!result) return null;

            if (isNullOrEmpty(message))
                return (isNull(result[property]) == false);
            else
                return result[property] ? result[property][0] == message : false;
        }
    }

    // liveErroraaa(property: string, message: string, subproperty: string = null) {
    //     if (this.notificacaoEvento.submitted) {
    //         let result = this.notificacaoEvento.validate();
    //         if (!result) return null;

    //         // if (subproperty == 'nome')
    //         //     debugger;

    //         // if (property == 'notificacaoEventosContato') {
    //         //     if (result[property]) {
    //         //         if (isNullOrEmpty(message))
    //         //             return (isNull(result[property][0][subproperty]) == false);
    //         //         else
    //         //             return result[property][0][subproperty] ? result[property][0][subproperty][0] == message : false;
    //         //     }
    //         //     return false;
    //         // } else {
    //         if (isNullOrEmpty(message))
    //             return (isNull(result[property]) == false);
    //         else
    //             return result[property] ? result[property][0] == message : false;
    //         //}
    //     }
    // }

    liveErrorEventos(property: string, message: string, index: number = 0, subproperty: string = null) {
        if (this.submitted) {
            let result = this.notificacao.validateEventos();
            if (!result) return null;

            if (isNullOrEmpty(message)) {
                if (result[property]) {
                    if (isNullOrEmpty(subproperty) == false) {
                        if (!isNull(result[property][0].errors) && !isNull(result[property][0].errors[index]) && !isNull(result[property][0].errors[index][subproperty]))
                            return (isNull(result[property][0].errors[index][subproperty]) == false);
                        else
                            return false;
                    }
                    return (isNull(result[property]) == false);
                }
                return false;

            } else {
                if (result[property]) {
                    if (isNullOrEmpty(subproperty) == false) {
                        if (!isNull(result[property][0].errors) && !isNull(result[property][0].errors[index]) && !isNull(result[property][0].errors[index][subproperty]))
                            return result[property][0].errors[index][subproperty][0] == message;
                        else
                            return false;
                    }
                    return result[property][0] == message;
                }
                return false;
                //return result[property] ? result[property][index] == message : false;
            }
        }
    }

    liveErrorEventosConexoes(evento: NNotificacaoEvento, property: string, message: string, index: number = 0, subproperty: string = null) {
        if (this.submitted) {
            let result = this.notificacao.validateEventosConexoes(evento);
            if (!result) return null;

            if (isNullOrEmpty(message)) {
                if (result[property]) {
                    if (isNullOrEmpty(subproperty) == false) {
                        if (!isNull(result[property][0].errors) && !isNull(result[property][0].errors[index]) && !isNull(result[property][0].errors[index][subproperty]))
                            return (isNull(result[property][0].errors[index][subproperty]) == false);
                        else
                            return false;
                    }
                    return (isNull(result[property]) == false);
                }
                return false;

            } else {
                if (result[property]) {
                    if (isNullOrEmpty(subproperty) == false) {
                        if (!isNull(result[property][0].errors) && !isNull(result[property][0].errors[index]) && !isNull(result[property][0].errors[index][subproperty]))
                            return result[property][0].errors[index][subproperty][0] == message;
                        else
                            return false;
                    }
                    return result[property][0] == message;
                }
                return false;
                //return result[property] ? result[property][index] == message : false;
            }
        }
    }

    liveErrorDestinatarios(property: string, message: string, index: number = 0, subproperty: string = null) {
        if (this.submitted) {
            let result = this.notificacao.validateDestinatarios();
            if (!result) return null;

            if (isNullOrEmpty(message)) {
                if (result[property]) {
                    if (isNullOrEmpty(subproperty) == false) {
                        if (!isNull(result[property][0].errors) && !isNull(result[property][0].errors[index]) && !isNull(result[property][0].errors[index][subproperty]))
                            return (isNull(result[property][0].errors[index][subproperty]) == false);
                        else
                            return false;
                    }
                    return (isNull(result[property]) == false);
                }
                return false;

            } else {
                if (result[property]) {
                    if (isNullOrEmpty(subproperty) == false) {
                        if (!isNull(result[property][0].errors) && !isNull(result[property][0].errors[index]) && !isNull(result[property][0].errors[index][subproperty]))
                            return result[property][0].errors[index][subproperty][0] == message;
                        else
                            return false;
                    }
                    return result[property][0] == message;
                }
                return false;
                //return result[property] ? result[property][index] == message : false;
            }
        }
    }

    // liveErrorDestinatarios(property: string, message: string, index: number = 0, subproperty: string = null) {
    //     if (this.submitted) {
    //         let result = this.notificacao.validateDestinatarios();
    //         if (!result) return null;

    //         if (isNullOrEmpty(message)) {
    //             if (result[property]) {
    //                 if (isNullOrEmpty(subproperty) == false) {
    //                     if (!isNull(result[property][0].errors) && !isNull(result[property][0].errors[index]) && !isNull(result[property][0].errors[index][subproperty]))
    //                         return (isNull(result[property][0].errors[index][subproperty]) == false);
    //                     else
    //                         return false;
    //                 }
    //                 return (isNull(result[property]) == false);
    //             }
    //             return false;

    //         } else {
    //             if (result[property]) {
    //                 if (isNullOrEmpty(subproperty) == false) {
    //                     if (!isNull(result[property][0].errors) && !isNull(result[property][0].errors[index]) && !isNull(result[property][0].errors[index][subproperty]))
    //                         return result[property][0].errors[index][subproperty][0] == message;
    //                     else
    //                         return false;
    //                 }
    //                 return result[property][0] == message;
    //             }
    //             return false;
    //             //return result[property] ? result[property][index] == message : false;
    //         }
    //     }
    // }

    //#endregion  

    constructor(
        private notificacaoService: NotificacaoService,
        private carteiraService: CarteiraService,
        private templateService: TemplateService,
        private listaService: ListaService,
        private estrategiaService: EstrategiaService,
        private languageService: LanguageService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        private customAlertService: CustomAlertService
    ) {
        this.idiomas = [
            { codigo: 'pt-BR', nome: 'Português', bandeira: 'assets/images/flags/brazil.jpg' },
            { codigo: 'en', nome: 'English', bandeira: 'assets/images/flags/us.jpg' },
            { codigo: 'es', nome: 'Español', bandeira: 'assets/images/flags/mexico.jpg' }
        ];
    }

    ngOnInit() {
        this.obterCamposApi();
        this.obterEventosTipo();
        this.obterEnviosTipo();
        this.obterCarteiras();
        this.obterConexoes();
    }

    //#region [ Carregamento dos combos ]

    sort = (a: any, b: any) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
    }

    obterCamposApi() {
        this.notificacaoService.obterCamposApi().subscribe((campos: Array<NNotificacaoCampos>) => this.camposApi = campos.map((m: NNotificacaoCampos) => NNotificacaoCampos.fromRaw(m)));
    }

    obterEventosTipo() {
        this.notificacaoService.obterEventosTipo().then(result => this.eventosTipos = result.sort(this.sort));
    }

    obterEnviosTipo() {
        let filtro = [2];

        if (this.listaConexoes.some((c: NNotificacaoConexao) => !isNullOrZero(c.id) && c.ativo && c.notificacaoTipoEnvioId == NNotificacaoTipoEnvio.Api)) filtro.push(1);
        //if (this.listaConexoes.some((c: NNotificacaoConexao) => !isNullOrZero(c.id) && c.ativo && c.notificacaoTipoEnvioId == NNotificacaoTipoEnvio.Email)) filtro.push(2);
        //if (this.listaConexoes.some((c: NNotificacaoConexao) => !isNullOrZero(c.id) && c.ativo && c.notificacaoTipoEnvioId == NNotificacaoTipoEnvio.RabbitMQ)) filtro.push(3);
        if (this.listaConexoes.some((c: NNotificacaoConexao) => !isNullOrZero(c.id) && c.ativo && c.notificacaoTipoEnvioId == NNotificacaoTipoEnvio.WhatsApp)) filtro.push(4);

        this.notificacaoService.obterEnviosTipo().then((result: Array<any>) => this.enviosTipos = result.filter((f: any) => filtro.includes(f.value)));
    }

    obterConexoes() {
        let filter = { ...this.queryFilter, ...this.filtroPesquisa }

        this.notificacaoService.obterNotificacaoConexao(filter).subscribe((result: any) => {
            this.listaConexoes = result.data.map((m: any) => NNotificacaoConexao.fromRaw(m));
            this.obterEnviosTipo();
        });

        // if (this.notificacao.notificacaoConexaoApi.some((c: NNotificacaoConexaoApi) => !isNullOrZero(c.id) && c.ativo))
        //     this.conexoesApi = this.notificacao.notificacaoConexaoApi.filter((f: any) => f.ativo).map((m: any) => ({ 'value': m.id, 'label': m.nome })).sort(this.sort);

        // // if (this.notificacao.notificacaoConexaoDb.some((c: NNotificacaoConexaoDb) => !isNullOrZero(c.id) && c.ativo))
        // // if (this.notificacao.notificacaoConexaoRabbitMQ.some((c: NNotificacaoConexaoRabbitMQ) => !isNullOrZero(c.id) && c.ativo))

        // if (this.notificacao.notificacaoConexaoWhatsapp.some((c: NNotificacaoConexaoWhatsapp) => !isNullOrZero(c.id) && c.ativo))
        //     this.conexoesWhatsapp = this.notificacao.notificacaoConexaoWhatsapp.filter((f: any) => f.ativo).map((m: any) => ({ 'value': m.id, 'label': m.nome })).sort(this.sort);
    }

    // obterCamposApi() {
    //     this.notificacaoService.obterCamposApi().subscribe((campos: Array<NNotificacaoCamposApi>) => this.camposApi = campos.map((m: NNotificacaoCamposApi) => NNotificacaoCamposApi.fromRaw(m)));
    // }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe((res: any) => this.carteiras = res.map((m: any) => ({ 'value': m.carteiraId, 'label': m.nome })).sort(this.sort));
    }

    obterTemplates() {
        let filtro = { 'carteiraId': this.notificacao.notificacaoCarteiras[0].carteiraId, 'categoria': 1 };
        this.templateService.obterTemplates(filtro).subscribe(templates => this.templatesImportacao = templates.map((m: any) => ({ 'value': m.listaTemplateId, 'label': m.nome })).sort(this.sort));
    }

    obterListas() {
        let filtro = { 'carteiraId': this.notificacao.notificacaoCarteiras[0].carteiraId };

        if (!isNull(this.notificacao.notificacaoTemplatesImportacao[0].listaTemplateId))
            filtro['template'] = this.notificacao.notificacaoTemplatesImportacao[0].listaTemplateId;

        this.listaService.obterListaSemQuantidade(filtro).subscribe(listas => this.listas = listas.map((m: any) => ({ 'value': m.listaId, 'label': m.nome })).sort(this.sort));
    }

    obterEstrategias() {
        let filtro = { 'carteiraId': this.notificacao.notificacaoCarteiras[0].carteiraId, 'listaId': this.notificacao.notificacaoListas[0].listaId, };
        this.estrategiaService.obterEstrategiasLookup(filtro).subscribe(estrategias => this.estrategias = estrategias.map((m: any) => ({ 'value': m.listaEstrategiaId, 'label': m.nome })).sort(this.sort));
    }

    filtrarCamposApi(evento: NNotificacaoEvento, eventoConexao: NNotificacaoEventoConexao | null = null) {

        if (isNull(eventoConexao) == false) {
            this.filtrarCamposApiRegra(evento, eventoConexao);
            return;
        }

        evento.eventosConexoes.forEach((item: NNotificacaoEventoConexao) => this.filtrarCamposApiRegra(evento, item));
    }

    filtrarCamposApiRegra(evento: NNotificacaoEvento, eventoConexao: NNotificacaoEventoConexao) {

        if (isNullOrEmpty(evento.notificacaoTipoEventoId) || eventoConexao.conexao.notificacaoTipoEnvioId != NNotificacaoTipoEnvio.Api) {
            if (isObjectEmpty(eventoConexao) == false)
                eventoConexao.camposPorTipoEvento.forEach((f: NNotificacaoCamposCliente) => f.ativo = false);
            else
                eventoConexao.camposPorTipoEvento = [];

            return;
        }

        if (isObjectEmpty(eventoConexao.camposPorTipoEvento) == false) {
            eventoConexao.camposPorTipoEvento.forEach((f: NNotificacaoCamposCliente) => f.ativo = false);

            let camposApiAdd: Array<NNotificacaoCamposCliente> = [];

            this.camposApi
                .filter((f: NNotificacaoCampos) => f.ativo && f.notificacaoTipoEventoId == evento.notificacaoTipoEventoId)
                .forEach((f: NNotificacaoCampos, i: number) => {

                    if (eventoConexao.camposPorTipoEvento.some((s: NNotificacaoCamposCliente) => s.notificacaoCamposId == f.id)) {
                        let index = eventoConexao.camposPorTipoEvento.findIndex((s: NNotificacaoCamposCliente) => s.notificacaoCamposId == f.id);
                        eventoConexao.camposPorTipoEvento[index].notificacaoCamposNome = f.nome;
                        eventoConexao.camposPorTipoEvento[index].ativo = true;
                    }
                    else {
                        camposApiAdd.push(NNotificacaoCamposCliente.instance(f.id, f.nome, i));
                    }
                });

            camposApiAdd.forEach((f: NNotificacaoCamposCliente) => eventoConexao.camposPorTipoEvento.push(f));

        } else {
            eventoConexao.camposPorTipoEvento = [];

            if (isNullOrZero(evento.notificacaoTipoEventoId) == false) {
                eventoConexao.camposPorTipoEvento = this.camposApi
                    .filter((f: NNotificacaoCampos) => f.ativo && f.notificacaoTipoEventoId == evento.notificacaoTipoEventoId)
                    .map((m: NNotificacaoCampos, i: number) => NNotificacaoCamposCliente.instance(m.id, m.nome, i));
            }
        }
    }

    //#endregion

    //#region [ Pesquisa do Grid ] 

    loadData(event: LazyLoadEvent) {
        this.loading = true;
        this.first = event.first;
        this.rows = event.rows;
        this.queryFilter = new QueryFilter();
        this.queryFilter.start = this.first;
        this.queryFilter.limit = this.rows;

        this.setSortProperties(event);

        this.buscar();
    }

    setSortProperties(event: LazyLoadEvent) {
        let sort: SorterContainer[] = [];

        if (event.sortField !== undefined) {
            sort = [{ property: capitalizeFirstLetter(event.sortField), direction: event.sortOrder.toString() }];
        }
        else {
            sort = [{ property: this.identifier, direction: this.direction.toString() }];
        }

        this.queryFilter.sort = sort;
    }

    buscar() {
        let filter = { ...this.queryFilter, ...this.filtroPesquisa }

        this.notificacaoService.obterNotificacao(filter).subscribe((result: any) => {
            this.loading = false;
            this.listaNotificacoes = result.data.map((m: any) => NNotificacao.fromRaw(m));
            this.totalRecords = result.total;
        }, (error: any) => {
            this.loading = false;
        });
    }

    defaultSettings() {
        this.filtroPesquisa = { notificacaoTipoEnvioId: NNotificacaoTipoEnvio.Api };
    }

    editar(conexao: NNotificacao) {
        this.notificacao = NNotificacao.fromRaw(conexao);
        // this.notificacao.configuracaoApi = NNotificacaoConexaoApi.fromRaw(JSON.parse(conexao.configuracaoJson));
        // this.notificacao.configuracaoApi.camposPorTipoAutenticacao();
      
        if (isNull(this.notificacao.notificacaoTemplatesImportacao) || this.notificacao.notificacaoTemplatesImportacao.length <= 0)
            this.notificacao.notificacaoTemplatesImportacao = [new NNotificacaoTemplateImportacao()];

        if (isNull(this.notificacao.notificacaoCarteiras) || this.notificacao.notificacaoCarteiras.length <= 0)
        {
            this.notificacao.notificacaoCarteiras = [new NNotificacaoCarteira()];
        }
        else
        {
            this.obterTemplates();
            this.obterListas();
        }
    
        if (isNull(this.notificacao.notificacaoListas) || this.notificacao.notificacaoListas.length <= 0)
            this.notificacao.notificacaoListas = [new NNotificacaoLista()];
        else
            this.obterEstrategias();

        if (isNull(this.notificacao.notificacaoListasEstrategias) || this.notificacao.notificacaoListasEstrategias.length <= 0)
            this.notificacao.notificacaoListasEstrategias = [new NNotificacaoListaEstrategia()];          

        this.notificacao.notificacaoEventos.forEach((evento: NNotificacaoEvento) => {
            evento.eventosConexoes.forEach((eventoConexao: NNotificacaoEventoConexao) => {
                eventoConexao.conexoesPorTipoEnvio = this.listaConexoes
                    .filter((f: NNotificacaoConexao) => f.ativo && f.notificacaoTipoEnvioId == eventoConexao.conexao.notificacaoTipoEnvioId)
                    .map((m: any) => ({ 'value': m.id, 'label': m.nome }))
                    .sort(this.sort);

                //this.filtrarCamposApiRegra(evento, eventoConexao);
            });
        });

    }

    excluir(conexao: NNotificacao) {
        // this.customAlertService
        //     .confirmationMessage("telaElasticSearch.confirmacaoDesativarElastic")
        //     .then(() => {
        //         this.notificacaoService.desativarConexaoApi(conexao.id).subscribe(() => {
        //             //this.onAtualizarListaConexoes.emit()
        //             //ATUALIZAR GRID
        //         });
        //     });
    }

    //#endregion

    //#region [ Change ]

    tipoEventoChange(evento: NNotificacaoEvento) {
        // this.notificacaoEvento.tipoEventoChange();
        // this.adicionarContato();
        this.filtrarCamposApi(evento);
        // this.notificacaoEvento.dispararErro = false;
    }

    tipoEnvioChange(evento: NNotificacaoEvento, eventoConexao: NNotificacaoEventoConexao) {
        //this.notificacao.tipoEnvioChange();

        eventoConexao.conexoesPorTipoEnvio = this.listaConexoes
            .filter((f: NNotificacaoConexao) => f.ativo && f.notificacaoTipoEnvioId == eventoConexao.conexao.notificacaoTipoEnvioId)
            .map((m: any) => ({ 'value': m.id, 'label': m.nome }))
            .sort(this.sort);

        // this.adicionarContato();
        this.filtrarCamposApi(evento, eventoConexao);
    }

    carteiraChange() {
        this.obterTemplates();
        this.obterListas();

        this.notificacao.notificacaoTemplatesImportacao[0].listaTemplateId = undefined;
        this.notificacao.notificacaoListas[0].listaId = undefined;
        this.notificacao.notificacaoListasEstrategias[0].listaEstrategiaId = undefined;

        this.templatesImportacao = [];
        this.listas = [];
        this.estrategias = [];
    }

    templateImportacaoChange() {
        this.obterListas();

        this.notificacao.notificacaoListas[0].listaId = undefined;
        this.notificacao.notificacaoListasEstrategias[0].listaEstrategiaId = undefined;

        this.listas = [];
        this.estrategias = [];
    }

    listaChange() {
        if (!isNullOrZero(this.notificacao.notificacaoListas[0].listaId)) {
            this.obterEstrategias();
            return;
        }
        this.notificacao.notificacaoListasEstrategias[0].listaEstrategiaId = undefined;
        this.estrategias = [];
    }

    //#endregion

    //#region [ Eventos ]

    novoEvento() {
        this.notificacao.notificacaoEventos.push(new NNotificacaoEvento());
    }

    excluirEvento(evento: NNotificacaoEvento) {
        this.notificacao.notificacaoEventos = this.notificacao.notificacaoEventos.map((ev: NNotificacaoEvento) => {
            if (ev.guid == evento.guid)
                ev.ativo = false;

            return NNotificacaoEvento.fromRaw(ev);
        });
    }

    conexaoApi(eventoConexao: NNotificacaoEventoConexao) {
        return (eventoConexao.conexaoApi);
    }

    habilitarAssincrono(evento: NNotificacaoEvento, eventoConexao: NNotificacaoEventoConexao) {
        return (evento.notificacaoTipoEventoId === NNotificacaoTipoEvento.EstrategiaIniciada && eventoConexao.conexaoApi);
    }

    //#endregion

    //#region [ Conexoes ]

    novaConexao(evento: NNotificacaoEvento) {
        let maiorOrdem = (evento.eventosConexoes.length <= 0) ? -1 : Math.max(...evento.eventosConexoes.map((m: NNotificacaoEventoConexao) => m.ordem));
        let eventoConexao: NNotificacaoEventoConexao = new NNotificacaoEventoConexao();
        eventoConexao.conexao = new NNotificacaoConexao();
        eventoConexao.ordem = maiorOrdem + 1;
        evento.eventosConexoes.push(eventoConexao);
    }

    excluirConexao(evento: NNotificacaoEvento, eventoConexao: NNotificacaoEventoConexao) {
        let ec = evento.eventosConexoes.filter((f: NNotificacaoEventoConexao) => f.guid != eventoConexao.guid).map((m: NNotificacaoEventoConexao, index: number) => {
            m.ordem = index;
            return NNotificacaoEventoConexao.fromRaw(m);
        });
        evento.eventosConexoes = ec;
    }

    mudarOrdemConexao(evento: NNotificacaoEvento, campo: any, mudanca: number, rowIndex: Number, mesmalinha: boolean = false) {
        let posicao = campo.ordem + mudanca;
        evento.eventosConexoes.forEach((conexao: NNotificacaoEventoConexao) => {
            if (mudanca > 0 && conexao.ordem >= posicao) conexao.ordem--;
            if (mudanca < 0 && conexao.ordem <= posicao) conexao.ordem++;
        });
        campo.ordem = posicao;

        evento.eventosConexoes = evento.eventosConexoes.sort((a: NNotificacaoEventoConexao, b: NNotificacaoEventoConexao) => a.ordem - b.ordem).map((m: NNotificacaoEventoConexao, index: number) => {
            m.ordem = index;
            return NNotificacaoEventoConexao.fromRaw(m);
        });
    }


    //#endregion

    //#region [ Destinatarios ] 

    adicionarContato() {
        this.notificacao.destinatarios.push(new NNotificacaoDestinatario())
    }

    excluirContato(campo: NNotificacaoDestinatario, rowIndex: Number, mesmalinha: boolean = false) {

        let dest = new Array<NNotificacaoDestinatario>();
        this.notificacao.destinatarios.forEach((f: NNotificacaoDestinatario) => {
            if (isNullOrZero(campo.id) == false) {
                if (f.id == campo.id)
                    f.ativo = false;
                dest.push(f);
            } else {
                if (f.guid != campo.guid)
                    dest.push(f);
            }
        });

        this.notificacao.destinatarios = dest;
    }

    //#endregion

    //#region [ CRUD ]

    novaNotificacao() {
        let eventoConexao: NNotificacaoEventoConexao = new NNotificacaoEventoConexao();
        eventoConexao.conexao = new NNotificacaoConexao();

        let evento = new NNotificacaoEvento();
        evento.eventosConexoes.push(eventoConexao);

        
        let linguagem = this.languageService.getLanguage();
        this.notificacao = new NNotificacao();
        this.notificacao.linguagem = (linguagem.codigo == 'pt') ? 'pt-BR' : linguagem.codigo;
        this.notificacao.notificacaoEventos.push(evento);
        this.notificacao.destinatarios.push(new NNotificacaoDestinatario());

        this.notificacao.notificacaoCarteiras.push(new NNotificacaoCarteira());
        this.notificacao.notificacaoTemplatesImportacao.push(new NNotificacaoTemplateImportacao());
        this.notificacao.notificacaoListas.push(new NNotificacaoLista());
        this.notificacao.notificacaoListasEstrategias.push(new NNotificacaoListaEstrategia());
    }

    cancelar() {
        this.notificacao = null;
        this.submitted = false;
    }

    salvar() {

        this.submitted = true;

        let notificacao = NNotificacao.fromRaw(this.notificacao);

        let notificacaoValidate = notificacao.validate();
        let notificacaoFiltrosValidate = notificacao.validateFiltros();
        let notificacaoEventosValidate = notificacao.validateEventos();
        let notificacaoDestinatariosValidate = notificacao.validateDestinatarios();

        if (notificacaoValidate) {
            if (notificacaoFiltrosValidate) this.tabSelected = 0;
            else if (notificacaoEventosValidate) this.tabSelected = 1;
            else if (notificacaoDestinatariosValidate) this.tabSelected = 2;
            else this.tabSelected = this.tabSelected;
            return;
        }

        if (notificacaoFiltrosValidate) {
            this.tabSelected = 0;
            return;
        }

        if (notificacaoEventosValidate) {
            this.tabSelected = 1;
            return;
        }

        if (notificacaoDestinatariosValidate) {
            this.tabSelected = 2;
            return;
        }

        // let continueEdit = (isNullOrEmpty(this.notificacaoEvento.id) == false);

        // //continueEdit = false;

        if (isNullOrZero(notificacao.notificacaoCarteiras[0].carteiraId))
            notificacao.notificacaoCarteiras = [];

        if (isNullOrZero(notificacao.notificacaoTemplatesImportacao[0].listaTemplateId))
            notificacao.notificacaoTemplatesImportacao = [];

        if (isNullOrZero(notificacao.notificacaoListas[0].listaId))
            notificacao.notificacaoListas = [];

        if (isNullOrZero(notificacao.notificacaoListasEstrategias[0].listaEstrategiaId))
            notificacao.notificacaoListasEstrategias = [];

        notificacao.notificacaoEventos.forEach((ev: NNotificacaoEvento) => {
            ev.eventosConexoes.forEach((conn: NNotificacaoEventoConexao) => {
                //conn.notificacaoConexaoId = conn.conexao.id;
                conn.jsonSaida = JSON.stringify(conn.camposPorTipoEvento);
            });
        });

        //debugger;

        this.notificacaoService.salvarNotificacao(notificacao).subscribe(
            () => {
                this.customAlertService.show("telaNNotificacao.mensagens.configuracaoEventos", "telaNNotificacao.mensagens.salvarEventos", "success");
                this.notificacao = null;
                this.submitted = false;
                this.tabSelected = 0;
                this.buscar();
            },
            (error: any) => {
                this.customAlertService.show("telaNNotificacao.mensagens.configuracaoEventos", error.error[0], "error");
            });

    }

    //#endregion

}