const validate = require("validate.js");

export class ProvedorAgendamentoConfiguracao {

    provedorAgendamentoConfiguracaoId: number;
    providerId: number;
    frequencia: string;
    intervalo: number;
    horaExecucao: any;
    dataInicio: Date;
    dataTermino: Date;
    diaSemana: string;
    expressaoRecorrencia: string;
    proximaExecucao: Date;
    ultimaExecucao: Date;
    forcarExecucao: boolean;
    dataCadastro: Date;
    usuarioCadastro: string;
    dataAlteracao: Date;
    usuarioAlteracao: Date;
    ativo: boolean;
    alterado: boolean;

    _comDataFim: boolean;

    public get comDataFim(): boolean {
        return this._comDataFim != undefined ? this._comDataFim : this.dataTermino != undefined;
    }

    public set comDataFim(valor: boolean) {
        if (!valor)
            this.dataTermino = undefined;

        this._comDataFim = valor;
    }

    static fromRaw(raw: any) {
        let provdorAgendamentoConfiguracao = new ProvedorAgendamentoConfiguracao();
        Object.assign(provdorAgendamentoConfiguracao, raw);
        return provdorAgendamentoConfiguracao;
    }

    constructor() {
        validate.validators.dataMenorQue = (value: any, options: any, key: any, attributes: any) => {
            if (value >= attributes[options.campo])
                return "dataMaior";
        };
        validate.validators.dataMaiorQue = (value: any, options: any, key: any, attributes: any) => {
            if (value <= attributes[options.campo])
                return "dataMenor";
        };
    }

    validar() {

        var validacao: any = {
            frequencia: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
            horaExecucao: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
            dataInicio: { presence: { allowEmpty: false, message: '^campoObrigatorio' } }
        };

        if (this.frequencia == "1") {
            validacao.intervalo = {
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                numericality: { onlyInteger: true, greaterThanOrEqualTo: 1, message: "^menorque1" }
            }
        }

        if (this.frequencia == "2") {
            validacao.intervalo = {
                presence: { allowEmpty: false, message: '^campoObrigatorio' },
                numericality: { onlyInteger: true, greaterThan: 0, message: "^menorque0" }
            }
        }

        if (this.comDataFim)
            validacao.dataTermino = { presence: { allowEmpty: false, message: '^campoObrigatorio' }, dataMaiorQue: { campo: "dataInicio" } };

        return validate.validate(this, validacao);
    }
}