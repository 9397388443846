import * as validateJS from "validate.js";
import { ESBuilderRulesConditions } from "./rules-conditions.model";
import { deepCopy } from "../../common/utils/deepCopy"
import { isNull, isNullOrEmpty } from "../libs/utils/isNull";

export class ESBuilderRules {
	public condition: ESBuilderRulesConditions;

	public field: string;
	public fieldType: string;
	public fieldNestedType: string;
	public fieldNestedList: boolean;

	public filterType: string;
	public filterDataType: number;
	public filterParameters: Array<any>;
	public filterValues: any;

	public nestedType: string;
	public nestedContent: string;
	public nestedList: boolean;

	public order: number;
	public rules: Array<ESBuilderRules>;

	public ruleReadOnly: boolean = false;
	public standardFilter: number | null;

	// Propriedades usadas para a criação da query reversa
	public reverse: boolean = false;
	public notReverseCondition: boolean = false;
	public reverseNegation: boolean = false;

	public constructor(init?: Partial<ESBuilderRules>) {
		Object.assign(this, init);

		this.customValidate();
	}

	public get isEmpty(): boolean {
		return (isNullOrEmpty(this.field) && isNullOrEmpty(this.filterType));
	}

	public _valid: boolean;

	public get isValid(): boolean {
		this.validateRule();
		return this._valid;
	};

	public get errors(): boolean {
		return this.validateRule();
	};

	validateRule() {
		let objectValidate = ESBuilderRules.fromRaw(deepCopy(this));

		let defaultRules: any = {
			'field': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
			'filterType': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
			'filterParameters': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } }
		};

		//console.log(`VALIDATERULE: ${JSON.stringify(this)}`);

		if (!isNull(this.filterParameters)) {

			let filterValuesValidation = this.filterParameters.filter(s => s.validation);

			if (filterValuesValidation.length > 0) {

				let fvv = filterValuesValidation
					.map((m: any) => (m.name))
					.reduce((accumulator: any, value: any, index: any) => ({ ...accumulator, [value]: undefined }), {});

				let fvvr = filterValuesValidation
					.map((m: any) => ({ [m.name]: m.validation }))
					.reduce((accumulator: any, value: any, index: any) => ({ ...accumulator, ...value }), {});

				objectValidate = ESBuilderRules.fromRaw(Object.assign(objectValidate, Object.assign({ 'filterValues': fvv }, { 'filterValues': objectValidate.filterValues })));

				if (this.fieldType == 'Lista' || this.fieldType == 'Nested') {
					defaultRules['filterValues'] = {
						'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' }
					};
				} else {
					defaultRules['filterValues'] = {
						'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
						'validateObject': fvvr // { 'query': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } } }
					};
				}
			}
		} 

		let response = validateJS(objectValidate, defaultRules);
		//this._valid = (this.isEmpty || isNull(response));
		this._valid = isNull(response);
		return response;
	}

	private customValidate() {
		validateJS.validators.validateObject = (items: any, validationRules: any) => {
			let errors: any = validateJS(items, validationRules);
			return errors

			/*const arrayItemErrors = Object.keys(items).reduce((errors, item, index) => {

				let vr: any = {};
				vr[item] = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };

				let error = validateJS(items, vr);

				if (error)
					errors = { ...errors, ...error };

				return errors;
			}, {})

			return (Object.keys(arrayItemErrors).length === 0) ? null : { errors: arrayItemErrors };        // ES5
			*/
		};
	}

	static fromRaw(raw: any) {
		let data = new ESBuilderRules();
		Object.assign(data, raw);
		return data;
	}
}
