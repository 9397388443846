import {
	Component,
	OnInit,
	Input,
	Output,
	Inject,
	EventEmitter
} from "@angular/core";
import * as dayjs from 'dayjs';
import { isNullOrEmpty } from "../../../common/utils";

@Component({
	selector: "app-elasticsearch-edit-item",
	templateUrl: "./elasticsearch-edit-item.component.html",
	styleUrls: ['./elasticsearch-edit-item.component.scss'],
})
export class ElasticsearchEditItemComponent implements OnInit {
	@Input()
	valor: any;

	@Input()
	campo: string;

	_tipoDado: string;

	@Input()
	identificadores: Array<string> = [];

	@Input()
	public get tipoDado(): string {
		return this._tipoDado;
	}

	public set tipoDado(v: string) {
		if (isNullOrEmpty(v))
			this.tipoDado = '';

		this._tipoDado = v.toString();
	}

	@Output()
	alterarDado: EventEmitter<{}>;

	public get tipoDadoDescricao(): string {

		switch (this.tipoDado) {
			case '8': return 'Texto';
			case '9': return 'Decimal';
			case '10': return 'Data';
			case '11': return 'Lista';
			case '12': return 'Sim/Não';
			case '16': return 'Inteiro';
			case '17': return 'Long';
			default: return '';
		}
	}

	item: any;

	constructor() {
		this.alterarDado = new EventEmitter<{}>();
	}

	campoEhData() {
		if (this.tipoDado != "10") return false;

		if (!this.valor) return true;
		let dado = dayjs(this.valor).toDate();

		return Object.prototype.toString.call(dado) === "[object Date]";
	}

	campoEhNumerico() {
		return (this.tipoDado && (this.tipoDado == "16" || this.tipoDado == "17")); // && !Number.isNaN(Number.parseFloat(this.valor))
	}

	campoEhDecimal() {
		return (this.tipoDado && this.tipoDado == "9"); // && !Number.isNaN(Number.parseFloat(this.valor))
	}

	campoEhBoolean() {
		return (this.tipoDado && this.tipoDado == "12"); // && Object.prototype.toString.call(this.valor) == "[object Boolean]"
	}

	ngOnInit() { }

	public get ehObrigatorio(): boolean {
		if (this.identificadores.some((s: string) => s == this.campo))
			return true;

		if (['10', '11'].some((s: string) => s == this.tipoDado))
			return true;

		return false;
	}

	dadoAlterado() {
		let valor = this.valor;
		if (this.campoEhData()) {
			try {
				valor = dayjs(this.valor).format("DD/MM/YYYY HH:mm:ss");
			} catch {
				return;
			}
		}
		this.alterarDado.emit({ 'campo': this.campo, 'tipoDado': this.tipoDado, 'valor': this.valor });
	}

	addValorPadrao() {
		switch (this.tipoDadoDescricao) {
			case "Inteiro":
			case "Long":
				this.valor = Math.floor(Math.random() * 1000000) + 1;
				break;

			case "Texto":
			case "Lista":
				this.valor = "Lorem ipsum";
				break;

			case "Decimal":
				let value = (Math.random() * (1000000 - 1 + 1)) + 1;
				this.valor = Number.parseFloat(value.toString()).toFixed(2)
				break;

			case "Data":
				this.valor = new Date(+(new Date()) - Math.floor(Math.random() * 10000000000));
				break;

			case "Sim/Não":
				this.valor = true;
				break

		}

		this.alterarDado.emit({ 'campo': this.campo, 'tipoDado': this.tipoDado, 'valor': this.valor });
	}
}
