import { Component, Output, EventEmitter } from "@angular/core";
import { Input } from "hammerjs";
import { Estrategia, EstrategiaStatus } from "../../../common/model/estrategia";
import { FiltroMonitorRetorno } from "../../../common/model/filtro-monitor-retorno";
import { Provider } from "../../../common/model/provider";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { EstrategiaService } from "../../../common/service/estrategia.service";
import { HistoricoRetornoService } from "../../../common/service/historico-retorno.service";

@Component({
  selector: "app-filtro-sem-retorno",
  templateUrl: "./filtro-sem-retorno.component.html",
  styleUrls: ["../style.scss"],
  providers: [
    { useClass: CustomAlertService, provide: CustomAlertService },
    { useClass: HistoricoRetornoService, provide: HistoricoRetornoService },
    { useClass: EstrategiaService, provide: EstrategiaService }
  ]
})

export class FiltroSemRetornoComponent {
  public filtro: FiltroMonitorRetorno = new FiltroMonitorRetorno();
  public isDirty: boolean = false;
  public provedoresListasHabilitados: Array<any> = [];
  public provedoresRetorno: Array<any> = [];
  public provedoresRetornoFiltrado: Array<any> = [];
  public listasRetorno: Array<any> = [];
  public listasRetornoFiltrado: Array<any> = [];
  public estrategias: Array<Estrategia> = [];
  public estrategiasFiltrado: Array<Estrategia> = [];

  public get erros() {
    if (!this.isDirty)
      return false;

    return this.filtro.validarPesquisaSemRetorno();
  }

  @Output()
  pesquisar: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private customAlertService: CustomAlertService,
    private historicoRetornoService: HistoricoRetornoService,
    private estrategiaService: EstrategiaService
  ) {

    this.historicoRetornoService.obterProvedoresListaRetorno({}).subscribe(provedores => {
      this.provedoresListasHabilitados = provedores;
      this.provedoresRetornoFiltrado = provedores.filter((a, b, arr) => { return arr.indexOf(arr.find(t => t.providerId === a.providerId)) === b });
      this.provedoresRetorno = [...this.provedoresRetornoFiltrado];
      this.obterListas(provedores);
      this.obterEstrategias();
    });
  }

  pesquisarFiltro() {
    this.isDirty = true;

    if (this.erros)
      return this.customAlertService.show(
        "menu.monitorRetorno",
        'telaPadrao.camposInvalidos',
        "error"
      );

    this.pesquisar.emit(this.filtro);
  }

  limpar() {
    this.filtro = new FiltroMonitorRetorno();
  }

  obterListas(provedores: Array<any>) {
    this.listasRetornoFiltrado = provedores.filter((a, b, arr) => { return arr.indexOf(arr.find(t => t.listaId === a.listaId)) === b })
      .map(l => ({ listaId: l.listaId, nome: l.nomeLista }))
      .sort((a, b) => (a.nome.localeCompare(b.nome)));
    this.listasRetorno = [...this.listasRetornoFiltrado];
  }

  obterEstrategias() {
    this.estrategiaService.obterEstrategias({ 'comEstrategiasFilhas': true }).subscribe(estrategias => {
      this.filtrarEstrategias(estrategias, this.provedoresRetornoFiltrado);
      this.estrategias = [...this.estrategiasFiltrado];
    })

    this.estrategiasFiltrado = this.estrategiasFiltrado.sort((a, b) => (a.nome.localeCompare(b.nome)))
  }

  //#region [ Controle dos changes dos Filtros ]

  filtroAlterado() {
    this.provedoresRetornoFiltrado = this.provedoresRetorno;
    this.listasRetornoFiltrado = this.listasRetorno;
    this.estrategiasFiltrado = this.estrategias;

    this.provedorSelected();
    this.listaSelected();
    this.estrategiaSelected();
  }

  provedorSelected() {
    if (this.filtro.providerId != null) {
      this.listasRetornoFiltrado = this.provedoresListasHabilitados.filter((l: any) => l.providerId.toString() == this.filtro.providerId).map(l => ({ 'listaId': l.listaId.toString(), 'nome': l.nomeLista }));
      this.filtrarEstrategias(this.estrategias, this.provedoresRetornoFiltrado.filter(p => p.providerId == this.filtro.providerId));
    }
  }

  listaSelected() {
    if (this.filtro.listaId != null) {
      this.estrategiaSelected();
      let idsProvedor: Array<any> = [];
      idsProvedor = this.provedoresRetornoFiltrado.map(p => p.providerId);
      
      this.provedoresRetornoFiltrado = this.provedoresListasHabilitados.filter(p => p.listaId == this.filtro.listaId && idsProvedor.find(s => s == p.providerId));
      this.estrategiasFiltrado = this.estrategias.filter((e: Estrategia) => e.listaId == this.filtro.listaId);
    }
  }

  estrategiaSelected() {
    if (this.filtro.listaEstrategiaId != null) {
      let estrategiaSelecionada = this.estrategiasFiltrado.find(e => e.listaEstrategiaId == this.filtro.listaEstrategiaId);
      this.listasRetornoFiltrado = this.listasRetornoFiltrado.filter((e: any) => e.listaId == estrategiaSelecionada.listaId);

      let idsProvedor: Array<any> = [];
      idsProvedor.push(estrategiaSelecionada.providerId);
      if (estrategiaSelecionada.estrategiasFilhas != null) idsProvedor.push(...estrategiaSelecionada.estrategiasFilhas.map(f => f.providerId))

      this.provedoresRetornoFiltrado = this.provedoresRetornoFiltrado.filter(f => idsProvedor.find(s => s == f.providerId));
    }
  }

  filtrarEstrategias(estrategias: Array<Estrategia>, provedores: Array<any>) {
    let idsProvedorRetorno = provedores.map(p => p.providerId);
    this.estrategiasFiltrado = estrategias.filter(e => {
      if (e.listaEstrategiaStatusId == EstrategiaStatus.Ativo && idsProvedorRetorno.find(p => p == e.providerId))
        return e;

      if (e.estrategiasFilhas &&
        idsProvedorRetorno.some(p => e.estrategiasFilhas.map(f => f.providerId).includes(p)))
        return e;
    });
  }

  //#endregion
}