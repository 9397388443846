
import swal from "sweetalert2";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { forkJoin } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { UsuarioService } from "../../common/service/usuario.service";
import { ProviderService } from "../../common/service/provider.service";
import { ListaService } from "../../common/service/lista.service";
import { CarteiraService } from "../../common/service/carteira.service";
import { DefaultValuesService } from "../../common/service/default-values.service";
import {mostrarSenha,impedirEspaco, onKey } from "../../common/utils/validacaoSenha";
import { Usuario, TipoUsuario } from "../../common/model/usuario";
import { ValidacaoSenhaModel } from "../../common/model/validacao-senha";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { StorageProxy } from '../../common/service/storage-proxy.service';
import { Credential } from "../../autenticacao/model/credential";

@Component({
  selector: 'app-form-perfil',
  templateUrl: './form-perfil.component.html',
  styleUrls: ['./form-perfil.component.scss'],
  providers: [
		{ useClass: UsuarioService, provide: UsuarioService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService }
	]
})
export class FormPerfilComponent {
	usuario: Usuario;
	submited: boolean;
	isEdit: boolean = true;
	isEditBloqueado: boolean = false;
	bloqueado: boolean = false;
	possuiLista: boolean = true;
	trocarSenha: boolean = false;
  filtro: ValidacaoSenhaModel;
  credencial:Credential;
  
  constructor(
    public dialog: MatDialog,
		private usuarioService: UsuarioService,
		private router: Router,
		private route: ActivatedRoute,
		private customAlertService: CustomAlertService,
		private translate: TranslateService,
		private defaultValuesServices: DefaultValuesService
  ) {
    this.usuario = new Usuario();
    this.filtro = new ValidacaoSenhaModel();
    this.credencial = new Credential();
   }

  ngOnInit() {
    this.usuarioService.meuUsuario().subscribe(user => {
      this.usuario = user;
      this.trocarSenha = false;
    });
  }

	public liveError(property: string) {
		let validationResult = this.usuario.validate();
		if (!validationResult) return null;
		return validationResult[property] ? validationResult[property][0] : null;
	}
	validaRegraSenha(event: any): void
	{

		this.filtro.confirmacaoSenha = this.usuario.repetirSenha == undefined ? "" : this.usuario.repetirSenha;
		this.filtro = onKey(this.filtro,this.usuario.senha);
	}

	get mostrarSenha()
	{
		return mostrarSenha;
	}

	get impedirEspaco()
	{
		return impedirEspaco;
	}

  salvar() {
		this.submited = true;
		this.usuario.validarSenha = this.trocarSenha;

		this.translate
			.get(["telaUsuario.usuario", "telaPadrao.camposInvalidos", "telaPadrao.sucessoSalvar", "telaPadrao.erroSalvar", ""])
			.subscribe((res: Object) => {
               
				let valid = this.usuario.validate();
				if (valid) {
					swal.fire(res["telaUsuario.usuario"], res["telaPadrao.camposInvalidos"], "error");

					let key = Object.keys(valid);
					return;
				}

				const sucesso = () => {
					swal.fire(res["telaUsuario.usuario"], res["telaPadrao.sucessoSalvar"], "success");
          this.credencial =  StorageProxy.get<Credential>('userData');
          this.credencial.user.userLogin = this.usuario.login;
          this.credencial.user.userName = this.usuario.nome;
          StorageProxy.set('userData', this.credencial);
					//this.router.navigate(["/app/usuario"]);
				};

				const erro = (s) =>{
					if (s.error.lenght !== null)
					{
						this.customAlertService.show(
							"telaUsuario.usuario",
							"telaUsuario."+s.error[0],
							"error"
						  );
					}
					else
					{
						swal.fire(res["telaUsuario.usuario"], res["telaPadrao.erroSalvar"], "error");
					}
				}

				this.usuarioService.atualizar(this.usuario).subscribe(sucesso,s =>  erro(s));
			});
	}
}
