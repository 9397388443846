import { SortEvent } from "primeng/api";
import { Component, OnInit, Input, Output, Inject, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ElasticsearchEditComponent } from "../../../elasticsearch/elasticsearch-edit/elasticsearch-edit.component";
import { ElasticsearchRepositoryService } from "../../../elasticsearch/repository/elasticsearch-repository.service";

import { ListaService } from "../../../common/service/lista.service";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { Template } from "../../../common/model/template";
import { TranslateService } from "@ngx-translate/core";


@Component({
	selector: "app-dados-lista",
	templateUrl: "./dados-lista.component.html",
	providers: [
		{
			useClass: ElasticsearchRepositoryService,
			provide: ElasticsearchRepositoryService
		},
		{
			useClass: ListaService,
			provide: ListaService
		},
		{
			useClass: CustomAlertService,
			provide: CustomAlertService
		}
	]
})
export class DadosListaComponent implements OnInit {
	dados: any[];
	total: number = 0;
	@Input()
	lista: any;

	@Input()
	listaId: number;

	@Input()
	template: Template;

	@Output()
	updateQuantidades: EventEmitter<any>;

	_query: any;

	@Input()
	public set query(query: any) {
		if (!query) return;

		this._query = query;
		this.getPreviewItem(query);
	}

	public get query() {
		return this._query;
	}

	_atualizar: boolean;

	@Input()
	public set atualizar(forcarAtualizar: boolean) {
		if (forcarAtualizar)
			this.getPreviewItem(this.query);
	}

	public get atualizar() {
		return this._atualizar;
	}

	sortField: string;
	sortDirection: number;
	camposMapeados: any[];

	constructor(
		private elasticsearchRepositoryService: ElasticsearchRepositoryService,
		private listaService: ListaService,
		private customAlertService: CustomAlertService,
		public dialog: MatDialog,
		private translate: TranslateService
	) {
		this.updateQuantidades = new EventEmitter<{}>();
	}

	editItem(item) {
		
		const editarItem = this.dialog.open(ElasticsearchEditComponent, {
			hasBackdrop: true,
			height: "800px",
			width: "80%",
			data: {
				template: this.template,
				lista: this.lista,
				listaId: this.listaId,
				item
			}
		});

		editarItem.afterClosed().subscribe(result => {
			if (result) {
				if (result.Erros)
					this.customAlertService.show(this.translate.instant("telaPadrao.alteracao"), this.translate.instant("telaPadrao.alteracaoErro"), "error");
				else
					this.customAlertService.show(this.translate.instant("telaPadrao.alteracao"), this.translate.instant("telaPadrao.alteracaoSucesso"), "success");
			}
			this.getPreviewItem(this.query);
		});
	}

	excluir(dados) {
		this.customAlertService
			.confirmationMessage("telaLista.confirmacaoExclusao")
			.then(() =>
				this.listaService
					.excluirItemLista(this.listaId, dados["id"])
					.subscribe(s => {
						this.getPreviewItem(this.query);
						this.updateQuantidades.emit();
						this.customAlertService.show(this.translate.instant("telaPadrao.exclusao"), this.translate.instant("telaPadrao.exclusaoSucesso"), "success");
					})
			);
	}

	duplicarItem(dado) {
		const duplicarItem = this.dialog.open(ElasticsearchEditComponent, {
			hasBackdrop: true,
			height: "800px",
			width: "80%",
			data: {
				template: this.template,
				lista: this.lista,
				listaId: this.listaId,
				item: dado,
				tipo: 3
			}
		});

		duplicarItem.afterClosed().subscribe(result => {
			if (result) {
				if (result.isError)
					this.customAlertService.show(this.translate.instant("telaPadrao.duplicacao"), this.translate.instant("telaPadrao.duplicacaoErro"), "error");
				else {
					this.updateQuantidades.emit();
					this.customAlertService.show(this.translate.instant("telaPadrao.duplicacao"), this.translate.instant("telaPadrao.duplicacaoSucesso"), "success");
				}
			}
		});
	}

	public get sort() {
		if (this.sortField == null) return [];
		return [{ [this.sortField]: this.sortDirection == -1 ? "desc" : "asc" }];
	}

	public get colunas(): string[] {
		return Object.keys(this.dados[0]).filter(f => f != "id").map(m => m);
	}

	public get colunasDisplay(): string[] {
		if (this.dados.length <= 0)
			return [];

		return Object.keys(this.dados[0]).filter(f => f != "id").map(
			item => `${item[0].toUpperCase()}${item.substring(1)}`
		);
	}

	getHasOrdenation(field: string): boolean {
		const fieldFounded = this.camposMapeados.find(
			c => c.nome.toLowerCase() == field.toLowerCase()
		);
		if (undefined == fieldFounded) return false;
		return (
			fieldFounded.tipoDado == "Numerico" || fieldFounded.tipoDado == "Lista"
		);
	}

	ngOnInit() {
		this.getPreviewItem(this.query);
	}

	getPreviewItem(query) {

		query.size = 10;

		this.elasticsearchRepositoryService
			.listaItem(this.lista, this.listaId, this.template.listaTemplateId, query)
			.subscribe(result => {
				this.dados = result.listaItem;
				this.total = result.total;
				this.camposMapeados = result.camposMapeados;
			});
	}

	changePage(event) {
		this.query.size = event.pageSize;
		this.query.from = event.pageIndex * event.pageSize;
		this.query.sort = this.sort;
		this.getPreviewItem(this.query);
	}

	formatElasticField(alias) {
		if (this.isUpperCase(alias)) return alias.toLowerCase();
		return `${alias[0].toLowerCase()}${alias.substring(1)}`;
	}

	isUpperCase(text) {
		return text.toUpperCase() == text;
	}

	clearSort() {
		this.sortField = null;
		this.sortDirection = null;
	}

	customSort(event: SortEvent) {
		event.field = this.formatElasticField(event.field);

		if (this.sortField == event.field && this.sortDirection == event.order)
			return;
		if (event.order == 0) this.clearSort();
		else {
			this.sortField = event.field;
			this.sortDirection = event.order;
		}

		this.changePage({ pageSize: this.query.size, pageIndex: 0 });
	}
}
