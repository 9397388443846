
import { combineLatest as observableCombineLatest, Observable, empty, Subscription, Subject } from 'rxjs';

import { map } from 'rxjs/operators';
import { MatDialog } from "@angular/material";
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, AfterViewInit } from "@angular/core";

import { mapToElasticSearchFilter, getFilterTypeByName } from "../../elasticsearch/model/elasticsearch-filter";
import { Estrategia } from "../../common/model/estrategia";
import { BaseElasticsearchFilter } from "../../elasticsearch/model/elasticsearch-filter/base";

// import { ListaService } from "../../common/service/lista.service";
// import { TemplateService } from "../../common/service/template.service";
import { EnqueueObservableService } from "../../common/service/enqueue-observable.service";
import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";

import { ExpressaoRegex } from "../../common/model/expressao-regex";
import { RegexElasticsearchFilter } from "../../elasticsearch/model/elasticsearch-filter/regex";
import { ListaExpressaoService } from "../../common/service/lista-expressao.service";
import { changeOrder } from "../../common/model/change-collection-item-order";

import { NotificationService } from "../../common/service/notification.service";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import {
	ESBuilderQueryBool,
	ESBuilderQueryConditions,
	ESBuilderQuery,
	ESBuilderRules,
	ESBuilderRulesConditions,
	ESBuilderRulesChange,
	ESBuilderRulesChangeType,
	ESBFiltroStatusRegistroLista,
	ESBuilderData,
	ESBFilterType
} from "../models";
import { ListaTemplateItem } from '../../common/model/lista-template-item';
import { Lista } from '../../common/model/lista';
import { isNull, isNullOrZero, isObjectEmpty } from '../../common/utils';
import { FiltroQuery } from '../../common/model/filtro-query';
import { EsBuilderService } from '../libs/services/esbuilder.service';

@Component({
	selector: "app-esbuilder-list",
	templateUrl: "./esbuilder-list.component.html",
	styleUrls: ["./esbuilder-list.component.scss"],
	providers: [
		// { useClass: TemplateService, provide: TemplateService },
		// { useClass: ListaService, provide: ListaService },
		// { useClass: ListaExpressaoService, provide: ListaExpressaoService }
	]
})
export class EsBuilderListComponent {

	condition: string = 'and';
	collapseState: string = 'mais';
	filtrolista: any = {};
	listas: Array<any> = [];

	@Output()
	dataRuleChange = new EventEmitter<ESBuilderRulesChange>();

	@Output()
	addRuleGeneral = new EventEmitter<boolean>();

	@Input()
	isAdd: boolean = false;

	@Input()
	appendToSelect: string = "body"

	@Input()
	isChield: boolean = false;

	@Input()
	level: number = 1;

	@Input()
	listaNome: string;

	@Input()
	templateId: number;

	@Input()
	listaId: number;

	@Input()
	enableConditionOr: boolean;

	@Input()
	readOnly: boolean = false;

	@Input()
	includeIntegratedRegister: ESBFiltroStatusRegistroLista;

	@Input()
	listaFiltrosQuerys: Array<FiltroQuery>

	testEventsSubject: Subject<void> = new Subject<void>();

	//#region [ GET/SET - Disabled ]  

	_disabled: boolean = false;

	@Input()
	public set disabled(l: boolean) {
		this._disabled = l;
	}

	public get disabled() {
		return this._disabled;
	}
	//#endregion

	//#region [ GET/SET - BuilderData ]  

	_builderData: ESBuilderData;// = new ESBuilderData();

	@Input()
	public set builderData(bd: ESBuilderData) {
		if (!bd) return;
		this._builderData = bd;
	}

	public get builderData() {
		return this._builderData;
	}

	//#endregion

	//#region [ GET/SET - Data Rules ]  

	_dataRules: Array<ESBuilderRules>;

	public get dataRules() {
		return this._dataRules;
	}

	@Input()
	public set dataRules(dr: Array<ESBuilderRules>) {
		if (!dr) return;
		this._dataRules = dr;
	}

	//#endregion

	//#region [ GET/SET - Campos Mapeados ]  

	_camposMapeados: Array<any>;

	public get camposMapeados() {
		return this._camposMapeados;
	}

	@Input()
	public set camposMapeados(cm: any) {
		if (!cm) return;
		this._camposMapeados = cm;
	}

	//#endregion

	//#region [ GET/SET - Lista Expressao Regex ]  

	_listaExpressaoRegex = new Array<ExpressaoRegex>();

	public get listaExpressaoRegex() {
		return this._listaExpressaoRegex;
	}

	@Input()
	public set listaExpressaoRegex(rg: Array<ExpressaoRegex>) {
		if (!rg) return;
		this._listaExpressaoRegex = rg;
	}

	//#endregion

	public get isFilterTypeAdvanced() {
		return this.builderData.filterType == ESBFilterType.advanced;
	}

	@Input()
	public filterType: ESBFilterType;

	constructor(private esBuilderService: EsBuilderService) {
		this.dataRuleChange = new EventEmitter<ESBuilderRulesChange>();
		this.addRuleGeneral = new EventEmitter<boolean>();


	}

	public get maior (){
		return this.builderData.rules.length;
	}
	

	//#region [ Rules ]

	addRuleG(scrollingDown: boolean = false) {
		this.addRuleGeneral.emit(scrollingDown);
	}

	addRule(dataRule: ESBuilderRules, scrollingDown: boolean = false) {

		if(this.disabled || dataRule.ruleReadOnly)
			return;

		dataRule.field = "";
		dataRule.fieldType = "";
		dataRule.filterType = "";
		dataRule.filterValues = {};

		if (dataRule.rules == undefined)
			dataRule.rules = [];

		let o = (dataRule.rules.length > 0) ? Math.max.apply(Math, dataRule.rules.map((m: ESBuilderRules) => (m.order))) + 1 : 0;

		dataRule.rules.push(new ESBuilderRules({
			"condition": (o == 0) ? ESBuilderRulesConditions.none : ESBuilderRulesConditions.and,
			"field": "",
			"fieldType": "",
			"filterType": "",
			"filterValues": {},
			"order": o
		}));

		// if (scrollingDown) {
		//     let esbuilderScrollbar = $('#esbuilderScrollbar');
		//     let scrollHeight = document.querySelector('#esbuilderScrollbar').scrollHeight;
		//     esbuilderScrollbar.stop().animate({ 'scrollTop': scrollHeight }, 1000);
		//     //let last = $('#esbuilderScrollbar LI:last-child');
		//     //esbuilderScrollbar.stop().animate({ 'scrollTop': last.position().top }, 1000);
		// }
	}

	removeRule(dr: ESBuilderRules, level: number, isFirst: boolean) {

		if(this.disabled || dr.ruleReadOnly)
			return;

		let index = this.dataRules.indexOf(dr);

		if (level == 1 && isFirst) {
			if (index !== -1) {
				let newRule = new ESBuilderRules();
				newRule.condition = (dr.order == 0) ? ESBuilderRulesConditions.none : ESBuilderRulesConditions.and;
				newRule.field = "";
				newRule.fieldType = "";
				newRule.filterType = "";
				newRule.filterValues = {};
				newRule.order = dr.order;

				this.dataRules[index] = newRule;
			}
		} else {
			if (index !== -1)
				this.dataRules.splice(index, 1);

			this.dataRules.forEach((i: ESBuilderRules, index: number) => (i.order = index));

			this.dataRuleChange.emit(new ESBuilderRulesChange(ESBuilderRulesChangeType.removeItem));
		}
	}

	removeRuleTooltip(isFirst: boolean, isAdd: boolean) {
		if (isFirst)
			return (isAdd == false) ? 'esBuilderComponent.limparRegra' : 'esBuilderComponent.limparFiltro';
		else
			return (isAdd == false) ? 'esBuilderComponent.excluirRegra' : 'esBuilderComponent.excluirFiltro';
	}

	//#endregion

	obterNomeFiltroPadraoPorCampo(filtroQueryId: number | null) {
		if (isNull(filtroQueryId))
			return '';

		let f = this.listaFiltrosQuerys.find((f: FiltroQuery) => f.filtroQueryId == filtroQueryId);

		if (isNull(f) || isObjectEmpty(f))
			return '';

		return (f as FiltroQuery).nome;
	}

	public verifyIsNullOrZero(v: any) {
		return isNullOrZero(v);
	}

	collapse() {
		this.collapseState = (this.collapseState === 'menos' ? 'mais' : 'menos');
	}

	drop(event: CdkDragDrop<string[]>, rules: ESBuilderRules[]) {
		if (this.readOnly || rules[event.currentIndex].ruleReadOnly) return;
		moveItemInArray(rules, event.previousIndex, event.currentIndex);
		this.dataRuleChange.emit(new ESBuilderRulesChange(ESBuilderRulesChangeType.position));
	}

	// //@Output() 
	// eventClicked = new EventEmitter<any>();

	dataRuleChanged(event: ESBuilderRulesChange) {
		this.dataRuleChange.emit(event);

		//this.filterType = this.filterType

		this.esBuilderService.emitFilterTypeChange('');
	//	this.eventClicked.emit(event);
	}

	// public clickedEvent: any;

	// childEventClicked(event: any) {
	// 	this.clickedEvent = event;
	// }
}