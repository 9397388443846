import { Component, OnInit, ContentChildren, QueryList, AfterContentInit, Input, AfterContentChecked, ContentChild } from "@angular/core";
import { TabFloatingBoxItemComponent } from "./tab-floating-box-item.component";
import { Observable, Subscription } from "rxjs";
import { startWith, map, take, tap, delay } from "rxjs/operators";
import { TabFloatingBoxContentComponent } from "./tab-floating-box-content.component";

@Component({
	selector: "tab-fb",
	template: `
	<div style="display: table; width: 100%; height:300px">
		<div style="display: table-cell; width: calc(100% - 1px); position: relative; padding: 10px 5px;">
			<ng-container [ngTemplateOutlet]="content.templateContent"></ng-container>
			
			<div class="caixa-overlay" [class.opened]="activeTab && activeTab.bodyComponent"></div>
		</div>
		<div style="display: table-cell; width: 1px; position: relative;">
			<div class="tabs-header" [class.active]="aaa">
				<div class="tab-label" (click)="selectTab(item)"
					[class.active]="activeTab === item" *ngFor="let item of tabItems$ | async">

					<span style="transform: rotate(-90deg); display: block; width: 100px; transform-origin: 50px 50px;">
						<ng-container *ngIf="item.labelComponent">
							<ng-container *ngTemplateOutlet="item.labelComponent.labelContent">
							</ng-container>
						</ng-container>

						<ng-container *ngIf="!item.labelComponent">
							{{ item.label }} A
						</ng-container>
					</span>
				</div>
			</div>
			<div class="caixa" [class.opened]="activeTab && activeTab.bodyComponent">
				<ng-container *ngIf="activeTab && activeTab.bodyComponent">
					<ng-container *ngTemplateOutlet="activeTab.bodyComponent.bodyContent">
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
    `,
	styles: [
		`
        .tabs-header {
			width: 35px;
			position: absolute;
			left: -35px;
			z-index: 100;
			overflow: hidden;
			height: 100%;
			transition: ease-in-out 600ms left;
        }
        .tabs-header.active {
			left: -831px !important;
			transition: ease-in-out 600ms left;
        }
  
        .tab-label {
			width: 35px;
			line-height: 35px;
			font-weight: bold;
			background-color: #66bb6a;
			text-align: center;
			border-color: #707070;
			border-style: solid;
			border-width: 1px 0 1px 1px;
			border-radius: 10px 0 0 10px;
			cursor: pointer;
			height: 100px;
        }

		.caixa-overlay {
			background-color: #000;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			opacity: 0;
			transition: ease-in-out 600ms opacity, ease-in-out 600ms visibility;
			visibility: hidden;
		}
		
		.caixa-overlay.opened {
			opacity: 0.5;
			transition: ease-in-out 600ms opacity, ease-in-out 600ms visibility;
			visibility: visible;
		}
		
		.caixa-botao {
			width: 130px;
			line-height: 35px;
			position: absolute;
			font-weight: bold;
			transform: rotate(-90deg);
			top: 47px;
			z-index: 100;
			background-color: #66bb6a; /*#f3f3f3;*/
			text-align: center;
		
			left: -78px;
			border-color:#707070; /*#CCC;*/
			border-style: solid;
			border-width: 1px 1px 0 1px;
			border-radius: 10px 10px 0 0;
			cursor: pointer;
			transition: ease-in-out 600ms left;
		}
		
		.caixa-botao.disabled {
			text-shadow: #e0e0e0 1px 1px 0;
			color: #a09f9f;
			background: #e6e6e6;
			cursor: no-drop;
		}
		
		.caixa-botao.opened {
			left: -879px;
			transition: ease-in-out 600ms left;
		}
		
		.caixa {
			width: 0;
			overflow: hidden;
			opacity: 0;
			background-color: #fff;
			right: 1px;
			height: calc(100% - 2px);
			position: absolute;
			top: 0;
			margin: 1px;
			padding: 10px;
			transition: ease-in-out 600ms width, ease-in-out 600ms opacity;
		}
		
		.caixa.opened {
			width: 795px;
			display: block;
			transition: ease-in-out 600ms width, ease-in-out 600ms opacity;
			opacity: 1;
		}

      `,
	],
})
export class TabFloatingBoxComponent implements AfterContentInit, AfterContentChecked {


	@ContentChild(TabFloatingBoxContentComponent, { static: false }) content!: TabFloatingBoxContentComponent;

	@ContentChildren(TabFloatingBoxItemComponent)
	tabs: QueryList<TabFloatingBoxItemComponent>;

	tabItems$: Observable<TabFloatingBoxItemComponent[]>;

	activeTab: TabFloatingBoxItemComponent | null;


	constructor() {



	}

	public get aaa():boolean{
		return (this.activeTab != null);
	}


	ngAfterContentInit(): void {

		// console.log('content');
		// console.log(this.content);

		this.tabItems$ = this.tabs.changes
			.pipe(startWith(""))
			.pipe(delay(0))
			.pipe(map(() => this.tabs.toArray()));
	}

	ngAfterContentChecked() {
		//choose the default tab
		// we need to wait for a next VM turn,
		// because Tab item content, will not be initialized yet
		/*if (!this.activeTab) {
			Promise.resolve().then(() => {
				this.activeTab = this.tabs.first;
			});
		}*/
	}

	selectTab(tabItem: TabFloatingBoxItemComponent) {



		if (this.activeTab === tabItem) {
			this.activeTab = null;
			return;
		}

		if (this.activeTab) {
			this.activeTab.isActive = false;

		}

		this.activeTab = tabItem;

		tabItem.isActive = true;
	}
}
