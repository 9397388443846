
import { map } from 'rxjs/operators';
import { Component, OnInit, Inject, Input, Output, EventEmitter } from "@angular/core";

import { TemplateService } from "../../common/service/template.service";
import { ElasticsearchRepositoryService } from "../repository/elasticsearch-repository.service";
import { formatFieldName } from "../model/elasticsearch-field-formarter";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { BaseElasticsearchFilter } from "../model/elasticsearch-filter/base";
import { changeOrder } from "../../common/model/change-collection-item-order";
import { FiltroStatusRegistroLista } from '../../common/model/lista';
import { isNullOrZero } from '../../common/utils';
import { CustomAlertService } from '../../common/service/custom-alert.service';
import { Order } from '../../estrategia/models/order';
import { OrderOperation, OrderTypes } from '../../estrategia/models/ordertypes';


@Component({
	selector: "app-elasticsearch-add-order",
	styleUrls: ["./elasticsearch-add-order.component.scss"],
	templateUrl: "./elasticsearch-add-order.component.html",
	providers: [
		{
			useClass: TemplateService,
			provide: TemplateService
		},
		{
			useClass: ElasticsearchRepositoryService,
			provide: ElasticsearchRepositoryService
		},
		{ useClass: DefaultValuesService, provide: DefaultValuesService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ElasticsearchAddOrderComponent implements OnInit {
	order: Order;
	static draggedOrder: Order;
	campos: any[] = [];
	opcoesPossiveis = [];
	@Output()
	aplicarOrdenacao = new EventEmitter<Array<Order>>();

	@Output()
	cancelarOrdenacao = new EventEmitter();

	@Input()
	reenviarDistribuido: FiltroStatusRegistroLista;

	@Input()
	lista: string;
	_listaId: number;

	@Input()
	ignorarflagPadrao: boolean = false;

	@Input()
	public set listaId(l: number) {
		if (!l || isNullOrZero(l)) {
			this._listaId = 0;
			this.campos = [];
			return;
		}

		this._listaId = l;
		this.obterCamposMapeados();
	}

	public get listaId() {
		return this._listaId;
	}

	_templateId: number;

	@Input()
	public set templateId(t: number) {
		if (!t || isNullOrZero(t)) {
			this._templateId = 0;
			this.campos = [];
			return;
		}

		this._templateId = t;
		this.obterCamposMapeados();
	}

	public get templateId() {
		return this._templateId;
	}

	_listaEstrategiaPrioridade: Array<any>;

	@Input()
	public set listaEstrategiaPrioridade(l: Array<any>) {
		if (!l || isNullOrZero(l)) {
			this._listaEstrategiaPrioridade = [];
			this.orderCollection = [];
			return;
		}

		this._listaEstrategiaPrioridade = Object.assign([], l);
		this.orderCollection = this._listaEstrategiaPrioridade.map(ordem => Object.assign(new Order(), ordem));
	}

	_query;
	@Input()
	public set query(queryData: any) {
		this._query = queryData
			? queryData.query
			: {
				size: 10,
				_source: {
					includes: []
				},
				query: {
					bool: {
						must: [...this.must.map(f => f.convertedFilter), ...this.mustUtilizado],
						must_not: this.mustNot
					}
				}
			};

	}

	public get query() {

		return this._query;

	}

	_orderCollection = [];

	@Input()
	public set orderCollection(listaEstrategiaPrioridade: any) {

		this._orderCollection = listaEstrategiaPrioridade
			? listaEstrategiaPrioridade.map(ordem =>
				Object.assign(new Order(), ordem)
			) : [];
	}

	public get orderCollection() {

		return this._orderCollection;

	}

	operacoes: any;
	must: Array<BaseElasticsearchFilter> = [];

	constructor(
		private templateService: TemplateService,
		private elasticsearchService: ElasticsearchRepositoryService,
		private customAlertService: CustomAlertService,
		private defaultValuesService: DefaultValuesService
	) {

		this.order = new Order();

		this.defaultValuesService
			.obterOperacoesOrdenacao()
			.then(operacoes => (this.operacoes = operacoes));
	}

	obterCamposMapeados() {
		if (isNullOrZero(this.templateId) || isNullOrZero(this.listaId))
			return;

		this.templateService.obterCamposMapeados(this.templateId, this.listaId).subscribe(campos => {
			this.campos = campos.filter(f => [1, 3, 5, 6, 7, 9].some(s => s == f.itemTipo));
		});
	}

	obterCampoSelecionado(campoSelecionado) {
		return this.campos.find(
			campo => campo.listaTemplateItemId == campoSelecionado
		);
	}

	public get activeOrderCollection() {
		return this.orderCollection
			.filter(o => o.ativo != false)
			.sort((orderA, orderB) => orderA.ordem - orderB.ordem);
	}

	public get tipoDadoLista(): boolean {
		var campoSelecionado = this.obterCampoSelecionado(
			this.order.listaTemplateItemId
		);
		if (!campoSelecionado) return false;
		return campoSelecionado.tipoDado == "Lista";
		
	}

	public get operadorAscDesc() {
		if (isNullOrZero(this.order.operador))
			return false;

		return (this.order.operador == OrderOperation.asc || this.order.operador == OrderOperation.desc);
	}

	public get operacoesPossiveis() {
		const campoSelecionado = this.obterCampoSelecionado(
			this.order.listaTemplateItemId
		);

		if (!campoSelecionado) return [];
		const possuiTipoNumerico = campoSelecionado.tipoDado == "Numerico";
		const possiveis = this.operacoes.filter(
			item =>
				OrderTypes[campoSelecionado.tipoDado].indexOf(parseInt(item.value)) !=
				-1
		);
		if (false == possuiTipoNumerico)
			return possiveis.filter(item => item.numeric == undefined);
		return possiveis.filter(
			item =>
				item.value != OrderOperation.equal ||
				(item.numeric == possuiTipoNumerico &&
					item.value == OrderOperation.equal)
		);
	}

	adicionar() {
		const campoSelecionado = this.obterCampoSelecionado(this.order.listaTemplateItemId);
		let validaValor = (campoSelecionado.tipoDado != "Lista" && this.operadorAscDesc == false);
		
		if (this.order.validate(validaValor))
			return;

		let permiteAdicionar = true;
		this.orderCollection.forEach(item => {
			if (item.listaTemplateItemId == this.order.listaTemplateItemId && item.ativo) {
				this.customAlertService.show("telaPrioridadePadrao.PrioridadePadrao", "telaPrioridadePadrao.erroAddLocal", "error");
				permiteAdicionar = false;
				return;
			}
		});

		if (permiteAdicionar) {
			if (campoSelecionado.tipoDado == 'Lista' && Array.isArray(this.order.valor)) {
				//if (Array.isArray(this.order.valor)) {

					let c = this.activeOrderCollection.length;

					this.order.valor.forEach((item: any) => {
						let o = new Order();
						o.listaTemplateItemId = this.order.listaTemplateItemId;
						o.operador = this.order.operador;
						o.valor = item;
						o.ordem = c;
						c++;
						this.orderCollection.push(o);
					});
				//}
			} else {
				if (Array.isArray(this.order.valor))
					this.order.valor = this.order.valor.join(",");

				this.order.ordem = this.activeOrderCollection.length;
				this.orderCollection.push(this.order);
			}

			this.order = new Order();
		}
	}

	excluir(ordem: Order) {
		ordem.ativo = false;
	}

	verificarPadrao(ordem: Order) {
		if (this.ignorarflagPadrao)
			return false;

		return (isNullOrZero(ordem.listaPrioridadePadraoItemId) == false);
	}

	obterOpcoesLista(campoId) {
		this.opcoesPossiveis = [];
		var format = new Intl.NumberFormat(navigator.language);
		var campoSelecionado = this.obterCampoSelecionado(parseInt(campoId));
		if (campoSelecionado.tipoDado != "Lista") return;
		this.elasticsearchService
			.getOptionForField(
				this.lista,
				this.listaId,
				this.templateId,
				formatFieldName(campoSelecionado.nome),
				this.query
			).subscribe(collection => {

                let r: any = collection;

                if (typeof (collection) == 'string')
                    r = JSON.parse(collection);

                this.opcoesPossiveis = Object.keys(r).map(label => ({
                    label: label.replace(/_/g, " "),
                    value: label,
                    suffix: format.format(r[label])
                }));

            });
	}

	obterNomeCampo(id) {
		const campo = this.obterCampoSelecionado(id);
		if (!campo) return "";
		return campo.nome;
	}

	obterNomeOperacao(id) {
		return OrderOperation[id];
	}

	obterValor(valor) {
		if (Array.isArray(valor)) return valor.join(", ");
		return valor;
	}

	obterPosicao(order: Order) {
		return this.activeOrderCollection.indexOf(order);
	}

	private get mustNot() {
		if (this.reenviarDistribuido == FiltroStatusRegistroLista.naoUtilizado)
			return [
				{
					exists: {
						field: "_integrado"
					}
				}
			];

		return [];
	}

	private get mustUtilizado() {
		if (this.reenviarDistribuido == FiltroStatusRegistroLista.utilizado)
			return [
				{
					exists: {
						field: "_integrado"
					}
				}
			];

		return [];
	}

	subirPosicao(order: Order) {
		var index = this.obterPosicao(order);
		if (index == 0) return;
		const previousOrder = this.activeOrderCollection[index - 1];
		order.ordem--;
		previousOrder.ordem++;
	}

	descerPosicao(order: Order) {
		var index = this.obterPosicao(order);
		if (index == this.activeOrderCollection.length - 1) return;
		const nextOrder = this.activeOrderCollection[index + 1];
		order.ordem++;
		nextOrder.ordem--;
	}

	save() {
		this.aplicarOrdenacao.emit(this.orderCollection)
	}

	close() {
		this.cancelarOrdenacao.emit();
	}


	handleDragStart(order: Order) {
		ElasticsearchAddOrderComponent.draggedOrder = order;
	}

	handleDragOver(event: Event) {
		event.preventDefault();
		(<Element>event.target).parentElement.classList.add("droppable");
	}

	handleDragLeave(event: Event) {
		event.preventDefault();
		(<Element>event.target).parentElement.classList.remove("droppable");
	}

	handleDrop(event: Event, o: Order) {

		if (this.verificarPadrao(ElasticsearchAddOrderComponent.draggedOrder) || this.verificarPadrao(o))
			return;

		(<Element>event.target).parentElement.classList.remove("droppable");
		var newOrderCollection = changeOrder(
			this.activeOrderCollection,
			ElasticsearchAddOrderComponent.draggedOrder,
			o
		);

		this.activeOrderCollection.forEach(
			ordem => (ordem.ordem = newOrderCollection.indexOf(ordem))
		);
	}

	ngOnInit() { }
}
