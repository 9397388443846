import { isNull, isNullOrEmpty, isNullOrZero, isObjectEmpty } from './isNull';
import { esbFormatFieldName } from './esbFormatFieldName';
import { ESBuilderData, ESBuilderRules, ESBuilderRulesConditions, ESBuilderFilterType, ESBFiltroStatusRegistroLista } from "../../models";
import { isRegExp } from 'util';

export class QueryToModel {

    public static convert(queryString: string, mappedFields: Array<any>, rulesAncient: string = null): ESBuilderData | undefined {

        if (isNullOrEmpty(queryString))
            return;

        let queryObject = JSON.parse(queryString);

        let model = new ESBuilderData();

        // IncludeIntegratedRegister
        model.includeIntegratedRegister = ESBFiltroStatusRegistroLista.total;

        // Type
        let queryTypes = Object.keys(queryObject.query);
        model.type = queryTypes[0];

        // Rules
        model.rules = new Array<ESBuilderRules>();

        // Aplicando possiveis filtros padrões
        let dataRulesAncient: Array<any> | null = [];

        // Aplicando possiveis filtros padrões
        if (!isNullOrEmpty(rulesAncient)) {
            let t = JSON.parse(rulesAncient);
            dataRulesAncient = (Array.isArray(t)) ? (t as Array<any>) : t.rules;
        }

        // Condições dentro do bool (should/must/must_not)
        let conditions = Object.keys(queryObject.query[model.type]);
        let o = 0;

        conditions.forEach((condition: string) => {
            let filters = queryObject.query.bool[condition];

            filters.forEach((filter: any) => {

                let negation = (condition == 'must_not');

                if (this.isFieldIntegrated(filter)) {
                    // IncludeIntegratedRegister
                    model.includeIntegratedRegister = (negation) ? ESBFiltroStatusRegistroLista.naoUtilizado : ESBFiltroStatusRegistroLista.utilizado;
                } else {

                    let queryValues = this.mapFromElasticFilter(null, filter, mappedFields, negation, dataRulesAncient);

                    let rule = new ESBuilderRules({
                        "condition": (o == 0) ? ESBuilderRulesConditions.none : ESBuilderRulesConditions.and,
                        "field": queryValues.field.nome,
                        "fieldType": queryValues.field.tipoDado,
                        "fieldNestedType": queryValues.fieldNestedType,
                        "fieldNestedList": queryValues.fieldNestedList,
                        "filterType": queryValues.filterType.filterType,
                        "filterDataType": queryValues.filterDataType,
                        "filterParameters": queryValues.filterType.parameters,
                        "filterValues": queryValues.filterValues,
                        "order": o,
                        "nestedType": (!isNull(queryValues.filterType.nestedType)) ? queryValues.filterType.nestedType : undefined,
                        "nestedContent": (!isNull(queryValues.filterType.nestedContent)) ? queryValues.filterType.nestedContent : undefined,
                        "nestedList": (!isNull(queryValues.filterType.nestedList) || queryValues.filterType.nestedList === false) ? queryValues.filterType.nestedList : undefined, // false
                        "standardFilter": null
                    });


                    // Aplicando possiveis filtros padrões
                    let ruleAncient = dataRulesAncient.find((fr: any) => fr.property.toLowerCase() == rule.field.toLowerCase() && (fr.queryType == queryValues.filterType.content || (rule.fieldType == 'Lista' && fr.queryType == "not_terms")));

                    if (!isNull(ruleAncient)) {
                        if (!isNullOrZero(ruleAncient.standardFilter)) {
                            rule.standardFilter = ruleAncient.standardFilter as number;
                            rule.ruleReadOnly = true;
                        }
                        else if (!isNullOrZero(ruleAncient.filtroPadrao)) {
                            rule.standardFilter = ruleAncient.filtroPadrao as number;
                            rule.ruleReadOnly = true;
                        } else {
                            rule.standardFilter = null;
                        }
                    }

                    model.rules.push(rule);
                    o++;
                }
            });
        });

        if (!isObjectEmpty(dataRulesAncient)) {
            let rulesNewOrder: Array<ESBuilderRules> = [];

            dataRulesAncient.forEach((item: any, index) => {
                let ruleNewOrder = model.rules.find((r: ESBuilderRules) => {
                    if (r.field.toLowerCase() == item.property.toLowerCase()) {
                        if (r.fieldType == 'Lista' && (r.filterType == 'contem_algum' || r.filterType == 'nao_contem') && (item.queryType == "terms" || item.queryType == "not_terms")) {
                            if (JSON.stringify(r.filterValues.map((m: any) => m.label)) == JSON.stringify(item.parameters))
                                return true;
                        // } else if (r.fieldType == 'Lista' && (r.filterType == 'contem_algum' || r.filterType == 'nao_contem') && item.queryType == "terms") {
                        //     if (JSON.stringify(r.filterValues.map((m: any) => m.label)) == JSON.stringify(item.parameters))
                        //         return true;
                        } else {
                            let jsonItem = JSON.stringify(item.parameters);

                            if (JSON.stringify(r.filterValues) == '{}' && (jsonItem == '{}' || jsonItem == '[]'))
                                return true;
                            
                            if (JSON.stringify(r.filterValues) == jsonItem)
                                return true;
                        }
                    }
                    return false;
                });
                ruleNewOrder.order = index;
                rulesNewOrder.push(ruleNewOrder);
            });

            model.rules = rulesNewOrder;
        }

        return model;
    }

    //#region [ Métodos de Map Elastic Filter ]

    // var filterPossibleType = {
    //     query_string: QueryStringElasticsearchFilter,
    //     terms: TermsElasticsearchFilter,
    //     term: TermElasticsearchFilter,
    //     not_terms: NotTermsElasticsearchFilter,
    //     exists: FieldQuery,
    //     bool: NotExistsQuery,
    //     script: EmptyQuery
    // };

    protected static mapFromElasticFilter(notInCollection: any, filter: any, mappedFields: Array<any>, negation: boolean, dataRulesAncient: Array<any>) {
        let type = this.getQueryType(filter);
        let isNested = false;

        if (type == 'nested') {
            isNested = true;
            filter = filter.nested.query.bool.must[0];
            type = this.getQueryType(filter);
        }

        switch (type) {
            case 'query_string':
                return this.mapFromElasticFilterQueryString(filter, mappedFields, negation, isNested);
            case 'terms':
                return this.mapFromElasticFilterTerms(filter, mappedFields, negation, isNested, dataRulesAncient);
            case 'exists': /*AQUI*/
                return this.mapFromElasticFilterFieldQuery(filter, mappedFields, negation, isNested);
            case 'script':
                return this.mapFromElasticFilterEmptyQuery(filter, mappedFields, negation, isNested);
            case 'term':
                return this.mapFromElasticFilterTerm(filter, mappedFields, negation, isNested);
            default:
                return this.mapFromElasticFilterBase(filter, mappedFields, negation, isNested);
        }
    }

    protected static mapFromElasticFilterBase(filter: any, mappedFields: Array<any>, negation: boolean, isNested: boolean) {

        // Funcoes auxiliares
        let getParameters = (filter: any, queryType: any, propertyName: any) => {
            if (queryType == 'regexp')
                return { 'query': filter[queryType][propertyName] };

            if (queryType == 'wildcard' && filter[queryType][propertyName] == '*')
                return {};

            return filter[queryType][propertyName];
        }

        let getFilterType = (queryTypes: Array<any>, queryType: string, parameters: any, mappedField: any, negation: boolean, isNested: boolean) => {
            let parameterKeys = (queryType == 'regexp') ? '["query"]' : JSON.stringify(Object.keys(parameters).sort());
            //let filterType = this.getFilterType(queryTypes, queryType, parameters, parameterKeys, isNested);

            let filterType = queryTypes.find((f: any) => {

                if (isNested) {
                    if (f.nestedContent == queryType && f.nestedType == mappedField.tipoDadoNested && JSON.stringify(f.parameters.map((m: any) => (m.name)).sort()) == parameterKeys)
                        return f;
                } else {
                    if (queryType == 'wildcard') {
                        if (f.content == queryType && f.negation == negation)
                            return f;
                    } else {
                        if (f.content == queryType && JSON.stringify(f.parameters.map((m: any) => (m.name)).sort()) == parameterKeys)
                            return f;
                    }
                }
            });

            return filterType;
        }

        let getFilterDataType = (parameters: any) => {
            let filterDataType = 1;

            if (parameters) {
                if ((parameters.lte && parameters.lte.toString().startsWith("now")) ||
                    (parameters.gte && parameters.gte.toString().startsWith("now")) ||
                    (parameters.lt && parameters.lt.toString().startsWith("now")) ||
                    (parameters.gt && parameters.gt.toString().startsWith("now"))) {
                    filterDataType = 2;
                }
                ///^\d+$/.test('12/10')
                if ((parameters.lte && parameters.lte.toString().length > 2 && /[a-z]/i.test(parameters.lte.toString().substring(0, 2))) ||
                    (parameters.gte && parameters.gte.toString().length > 2 && /[a-z]/i.test(parameters.gte.toString().substring(0, 2))) ||
                    (parameters.lt && parameters.lt.toString().length > 2 && /[a-z]/i.test(parameters.lt.toString().substring(0, 2))) ||
                    (parameters.gt && parameters.gt.toString().length > 2 && /[a-z]/i.test(parameters.gt.toString().substring(0, 2)))) {
                    filterDataType = 3;
                }
            }

            return filterDataType
        }

        let queryType = this.getQueryType(filter);                                                  // range
        let propertyName = Object.keys(filter[queryType])[0];                                       // inteiro
        let parameters = getParameters(filter, queryType, propertyName);                            // {gt:10}

        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, parameters, mappedField, negation, isNested);

        let filterDataType = getFilterDataType(parameters);

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "fieldNestedType": (!isNull(mappedField.tipoDadoNested)) ? mappedField.tipoDadoNested : undefined,
            "fieldNestedList": (!isNull(mappedField.listaNested)) ? mappedField.listaNested : false,
            "filterType": filterType,
            "filterDataType": filterDataType,
            "filterValues": parameters
        };
    }

    protected static mapFromElasticFilterQueryString(filter: any, mappedFields: Array<any>, negation: boolean, isNested: boolean) {

        let getFilterType = (queryTypes: Array<any>, queryType: string, parameters: any, negation: boolean) => {
            let parameterKeys = JSON.stringify(Object.keys(parameters).filter((f: string) => (f != 'default_field')).sort());

            let filterType = queryTypes.find((f: any) => {
                if (f.content == queryType && f.negation == negation && JSON.stringify(f.parameters.map((m: any) => (m.name)).sort()) == parameterKeys)
                    return f;
            });

            return filterType;
        }

        let getFilterValues = (filterValues: any) => {
            let values = {};
            Object.keys(filterValues).forEach((key: string) => {
                if (key != 'default_field')
                    values[key] = this.clearAsterisk(filterValues[key]);
            });
            return values;
        }

        let queryType = this.getQueryType(filter);
        let propertyName = filter[queryType].default_field;
        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);

        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, filter[queryType], negation);
        let filterValues = getFilterValues(filter[queryType]);

        // Filters do tipo QueryString serão sempre filterDataType = 1
        let filterDataType = 1;

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "fieldNestedType": (!isNull(mappedField.tipoDadoNested)) ? mappedField.tipoDadoNested : undefined,
            "fieldNestedList": (!isNull(mappedField.listaNested)) ? mappedField.listaNested : false,
            "filterType": filterType,
            "filterDataType": filterDataType,
            "filterValues": filterValues
        };
    }

    protected static mapFromElasticFilterTerms(filter: any, mappedFields: Array<any>, negation: boolean, isNested: boolean, dataRulesAncient: Array<any>) {
        // Funcoes auxiliares
        let getFilterType = (queryTypes: Array<any>, queryType: string, ruleTermNot: boolean) => {
            let filterType = queryTypes.find((f: any) => (f.content == queryType && f.negation == ruleTermNot));
            return filterType;
        }

        let queryType = this.getQueryType(filter);
        let propertyName = Object.keys(filter[queryType])[0];
        let propertyNameNotKeyword = propertyName.replace('.keyword', '');
        let parameters = undefined;
        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyNameNotKeyword);

        // Aplicando possiveis filtros padrões
        let rulesAncient = dataRulesAncient.find((fr: any) => fr.property.toLowerCase() == propertyNameNotKeyword.toLowerCase() && mappedField.alias == 'Lista' && fr.queryType == "not_terms");
        let ruleTermNot = !isNull(rulesAncient);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, ruleTermNot);
        let filterValues = undefined;

        if (mappedField.alias == 'Lista' && filterType.filterType == 'contem_algum'){
            parameters = (filter[queryType][propertyName] as Array<any>).map((m: any) => ({ "label": m, "value": m, "suffix": 0 }));
            filterValues = parameters;
        }        
        else if(mappedField.alias == 'Lista' && filterType.filterType == 'nao_contem') {
            
            if(isNull(rulesAncient))
                throw new Error("Rules ancient not implemented");

            parameters = (rulesAncient.parameters as Array<any>).map((m: any) => ({ "label": m, "value": m, "suffix": 0 }));
            filterValues = parameters;
        } else {
            parameters = filter[queryType][propertyName];
            filterValues = { [filterType.parameters[0].name.toString()]: parameters.join('') };
        }

        // Filters do tipo Terms serão sempre filterDataType = 1
        let filterDataType = 1;

        return {
            "queryType": queryType,
            "propertyName": propertyNameNotKeyword,
            "field": mappedField,
            "fieldNestedType": (!isNull(mappedField.tipoDadoNested)) ? mappedField.tipoDadoNested : undefined,
            "fieldNestedList": (!isNull(mappedField.listaNested)) ? mappedField.listaNested : false,
            "filterType": filterType,
            "filterDataType": filterDataType,
            "filterValues": filterValues
        };
    }

    protected static mapFromElasticFilterFieldQuery(filter: any, mappedFields: Array<any>, negation: boolean, isNested: boolean) {
        // Funcoes auxiliares
        let getFilterType = (queryTypes: Array<any>, queryType: string, parameters: any, mappedField: any, isNested: boolean) => {

            let parameterKeys = JSON.stringify(Object.keys(parameters).sort());

            //let filterType = this.getFilterType(queryTypes, queryType, parameters, parameterKeys);
            let filterType = queryTypes.find((f: any) => {

                if (isNested) {
                    if (f.nestedContent == queryType && f.nestedType == mappedField.tipoDadoNested && JSON.stringify(f.parameters.map((m: any) => (m.name)).sort()) == parameterKeys)
                        return f;
                } else {
                    if (f.content == queryType && JSON.stringify(f.parameters.map((m: any) => (m.name)).sort()) == parameterKeys)
                        return f;
                }
            });

            return filterType;
        }

        let queryType = this.getQueryType(filter);
        let propertyName = filter[queryType][Object.keys(filter[queryType])[0]];
        let parameters = filter[queryType];

        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, parameters, mappedField, isNested);

        // Filters do tipo Exists serão sempre filterDataType = 1
        let filterDataType = 1;

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "fieldNestedType": (!isNull(mappedField.tipoDadoNested)) ? mappedField.tipoDadoNested : undefined,
            "fieldNestedList": (!isNull(mappedField.listaNested)) ? mappedField.listaNested : false,
            "filterType": filterType,
            "filterDataType": filterDataType,
            "filterValues": {}
        };
    }

    protected static mapFromElasticFilterEmptyQuery(filter: any, mappedFields: Array<any>, negation: boolean, isNested: boolean) {

        let getFilterType = (queryTypes: Array<any>, queryType: string) => {
            let filterType = queryTypes.find((f: any) => (f.content == queryType));
            return filterType;
        }

        let getPropertName = (filterValue: string) => {
            let result = filterValue
                .match(/doc\[\'(.*?)\'\].empty/g)
                .map((value: string) => (value.replace(/doc\[\'/g, '').replace(/\'\].empty/g, '')));
            return (result.length > 0) ? result[0] : '';
        }

        let queryType = this.getQueryType(filter);
        let propertyName = getPropertName(filter[queryType].script.inline);

        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyName);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType);

        // Filters do tipo Script serão sempre filterDataType = 1
        let filterDataType = 1;

        return {
            "queryType": queryType,
            "propertyName": propertyName,
            "field": mappedField,
            "fieldNestedType": (!isNull(mappedField.tipoDadoNested)) ? mappedField.tipoDadoNested : undefined,
            "fieldNestedList": (!isNull(mappedField.listaNested)) ? mappedField.listaNested : false,
            "filterType": filterType,
            "filterDataType": filterDataType,
            "filterValues": {}
        };
    }

    protected static mapFromElasticFilterTerm(filter: any, mappedFields: Array<any>, negation: boolean, isNested: boolean) {
        // Funcoes auxiliares
        let getParameters = (filter: any, queryType: any, propertyName: any, fieldType: string) => {
            if (['Boolean', 'Texto'].some((s:string) => s == fieldType))
                return { 'query': filter[queryType][propertyName] };

            return filter[queryType][propertyName];
        }

        let getFilterType = (queryTypes: Array<any>, queryType: string, parameters: any) => {
            let parameterKeys = (queryType == 'regexp' || queryType == 'term') ? '["query"]' : JSON.stringify(Object.keys(parameters).sort());
            let filterType = this.getFilterType(queryTypes, queryType, parameters, parameterKeys);
            return filterType;
        }

        let queryType = this.getQueryType(filter);
        let propertyName = Object.keys(filter[queryType])[0];
        let propertyNameNotKeyword = propertyName.replace('.keyword', '');
        let mappedField = mappedFields.find(cm => esbFormatFieldName(cm.nome) == propertyNameNotKeyword);
        let parameters = getParameters(filter, queryType, propertyName, mappedField.tipoDado);
        let filterType = getFilterType(ESBuilderFilterType[mappedField.tipoDado], queryType, parameters);

        // Filters do tipo Term serão sempre filterDataType = 1
        let filterDataType = 1;

        return {
            "queryType": queryType,
            "propertyName": propertyNameNotKeyword,
            "field": mappedField,
            "fieldNestedType": (!isNull(mappedField.tipoDadoNested)) ? mappedField.tipoDadoNested : undefined,
            "fieldNestedList": (!isNull(mappedField.listaNested)) ? mappedField.listaNested : false,
            "filterType": filterType,
            "filterDataType": filterDataType,
            "filterValues": parameters
        };
    }

    //#endregion

    //#region [ Métodos auxiliares ]

    private static getQueryType = (filter: any) => (Object.keys(filter)[0]);

    private static getFilterType(queryTypes: Array<any>, queryType: string, parameters: any, queryParametersKeys: string, isNested: boolean = false) {
        let filterType = queryTypes.find((f: any) => {
            if (f.content == queryType && JSON.stringify(f.parameters.map((m: any) => (m.name)).sort()) == queryParametersKeys)
                return f;
        });
        return filterType;
    }

    private static isFieldIntegrated(filter: any): boolean {
        try {
            return (Object.keys(filter)[0] == 'exists' && Object.keys(filter.exists)[0] == 'field' && filter.exists.field == '_integrado');
        }
        catch (e) {
            return false;
        }
    }

    private static clearAsterisk(text: string) {
        if (text.startsWith("*"))
            text = text.substring(1);

        if (text.endsWith("*"))
            text = text.substring(0, text.length - 1);

        return text;
    }

    //#endregion
}
