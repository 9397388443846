import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Component, OnInit, Input, Inject } from "@angular/core";

import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";
import { Template } from "../../common/model/template";
import { ListaService } from "../../common/service/lista.service";
import { toElasticSearchName } from '../../common/utils/toElasticSearchName';
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ItemTipo, ListaTemplateItem } from "../../common/model/lista-template-item";
import { isNull, isNullOrEmpty } from "../../common/utils";
import { deepCopy } from "../../common/utils/deepCopy";
import { TranslateService } from "@ngx-translate/core";
import * as dayjs from 'dayjs';

const validateJs = require("validate.js");

@Component({
	selector: "app-elasticsearch-edit",
	templateUrl: "./elasticsearch-edit.component.html",
	styleUrls: ["./elasticsearch-edit-component.scss"],
	providers: [
		{ useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ElasticsearchEditComponent implements OnInit {
	template: Template;
	dados: any;
	lista: string;
	listaId: number;
	filtro: string;
	tipo: number;
	isEdit: boolean = false;

	public get colunas(): string[] {
		return this.template.listaTemplateItem.map(m => m.nome);
	}

	public get listaCamposEditaveis() {
		let listaItem = this.template.listaTemplateItem.filter(f => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Coluna) && (!this.filtro || f.nome.toLowerCase().indexOf(this.filtro.toLowerCase()) != -1));

		if (this.dados["id"] && this.tipo != 3) {
			let identificadores: Array<string> = this.obterCamposIdentificadores();
			listaItem = listaItem.filter(f => f.ativo && !identificadores.some((s: string) => s == f.nome));
		}

		return listaItem;
	}

	public get descricaoIdentificador(): string {

		let descricao = '';

		this.obterCamposIdentificadores().forEach((campo: string) => {
			let valor = (isNullOrEmpty(this.dados[this.getPropriedade(campo)])) ? '---' : this.dados[this.getPropriedade(campo)];
			descricao += (isNullOrEmpty(descricao)) ? `${campo}: ${valor}` : ` - ${campo}: ${valor}`;
		});

		return descricao;
	}

	constructor(
		private elasticsearchRepositoryService: ElasticsearchRepositoryService,
		private listaService: ListaService,
		private translate: TranslateService,
		private customAlertService: CustomAlertService,
		public dialogRef: MatDialogRef<ElasticsearchEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {

		this.template = data.template;
		this.lista = data.lista;

		if (!isNull(data.item)) {
			this.dados = deepCopy(data.item);
		}

		this.listaId = data.listaId;
		this.tipo = data.tipo;
		this.isEdit = (this.dados != null && this.dados["id"] != null);
	}

	obterCamposIdentificadores(): Array<string> {
		let identificadores: Array<string> = (!isNullOrEmpty(this.template.identificadorTelefone))
			? [this.template.identificadorAlias, ...this.template.identificadorTelefone.split(',')]
			: [this.template.identificadorAlias];

		return identificadores;
	}

	ngOnInit() { }

	alterarValor(retorno: any) {
		if (retorno) {
			let prop = this.getPropriedade(retorno.campo.toLowerCase());
			this.dados[prop] = retorno.valor;
		}
	}

	getPropriedade(nome: string): string {
		let item = this.colunas.find(f => f.toLowerCase() == nome.toLowerCase());
		return item.toLowerCase();
	}

	desabilitaSalvar() {
		var regras: any = {};
		let identificadores: Array<string> = this.obterCamposIdentificadores();

		identificadores.forEach((campo: string) => {
			regras[this.getPropriedade(campo)] = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
		});

		this.listaCamposEditaveis
			.filter((f: ListaTemplateItem) => [10, 11].some((s: number) => s == f.listaCampoTipoId))
			.forEach((campo: ListaTemplateItem) => {

				if (isNull(regras[this.getPropriedade(campo.nome)]))
					regras[this.getPropriedade(campo.nome)] = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
			});

		let validacao = validateJs.validate(this.dados, regras);
		return (!isNull(validacao));
	}

	salvar() {

		// let valido: boolean = true;
		// let identificadores: Array<string> = this.obterCamposIdentificadores();

		// identificadores.forEach((campo: string) => {
		// 	if (isNullOrEmpty(this.dados[this.getPropriedade(campo)]))
		// 		valido = false;
		// });

		// if (!valido) {
		// 	this.customAlertService.show("telaPadrao.validacao", "telaLista.identificadoresNaoInformados", "error");
		// 	return;
		// }

		// if (retorno.tipoDado == "10")
		// 	this.dados[prop] = dayjs(retorno.valor).format("DD/MM/YYYY");
		// else

		let envio: any = deepCopy(this.dados);

		this.listaCamposEditaveis.forEach((campo: ListaTemplateItem) => {
			if (campo.listaCampoTipoId == 10)
				envio[this.getPropriedade(campo.nome)] = dayjs(envio[this.getPropriedade(campo.nome)]).format("DD/MM/YYYY");
		});


		if (this.isEdit && this.tipo != 3)
			this.listaService.alterarItemLista(this.listaId, this.dados["_listaItemId"], envio).subscribe(
				(result: any) => { this.fechar(result); },
				(error: any) => {
					this.customAlertService.show("telaPadrao.alteracao", error.error[0], "error");
				});
		else
			this.listaService.incluirItemLista(this.listaId, envio).subscribe(
				(result: any) => { this.fechar(result); },
				(error: any) => {
					this.customAlertService.show("telaPadrao.inclusao", error.error[0], "error");
				});
	}

	formatElasticField(alias) {
		if (this.isUpperCase(alias)) return alias.toLowerCase();
		return `${alias[0].toLowerCase()}${alias.substring(1)}`;
	}

	isUpperCase(text) {
		return text.toUpperCase() == text;
	}

	addValorPadrao() {

		this.listaCamposEditaveis.forEach((f: ListaTemplateItem) => {

			switch (f.alias) {
				case "Inteiro":
				case "Long":
					this.dados[this.getPropriedade(f.nome)] = Math.floor(Math.random() * 1000000) + 1;
					break;

				case "Texto":
				case "Lista":
					this.dados[this.getPropriedade(f.nome)] = "Lorem ipsum";
					break;

				case "Decimal":
					let value = (Math.random() * (1000000 - 1 + 1)) + 1;
					this.dados[this.getPropriedade(f.nome)] = Number.parseFloat(value.toString()).toFixed(2)
					break;

				case "Data":
					this.dados[this.getPropriedade(f.nome)] = new Date(+(new Date()) - Math.floor(Math.random() * 10000000000));
					break;

				case "Sim/Não":
					this.dados[this.getPropriedade(f.nome)] = true;
					break
			}

		});

	}

	fechar(s: any = null) {
		this.dialogRef.close(s);
	}
}
