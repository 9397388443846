import { BaseElasticsearchFilter } from "./base";

export class NestedElasticsearchFilter extends BaseElasticsearchFilter {

    protected getInstance(
        queryType: string,
        propertyName: string,
        parameters: any,
        extra?: any,
        keyword?: boolean,
        nestedContent?: string,
        nestedType?: string,
        nestedList?: boolean
    ): BaseElasticsearchFilter {
        let instance = new NestedElasticsearchFilter(propertyName, queryType, parameters, extra, keyword, nestedContent, nestedType, nestedList);
        return instance;
    }

    public friendlyParameters(dataType: string, queryTypes) {
        // var value = (typeof this.parameters === "object") ? (this.parameters.query === true) : this.parameters;
        // if (dataType.toLowerCase() == 'boolean')
        //     return `É igual a ${value ? "sim" : "não"}`;
        // else
        //     return `É igual a ${this.parameters.query}`;

        switch (this.nestedContent) {
            case 'match':
                if (Array.isArray(this.parameters))
                    return `${((this.negation) ? 'Não em' : 'Em')} ${this.parameters.join(", ")}`;

                return this.friendlyParametersGeneral(queryTypes);
            //return super.friendlyParameters(dataType, queryTypes);
            case 'exists':
                return (this.negation) ? `Não Existe` : `Existe`;
            case 'range':
                return this.friendlyParametersGeneral(queryTypes);
            // return super.friendlyParameters(this.nestedType, queryTypes);
            // break;
        }
    }

    private friendlyParametersGeneral(queryTypes) {
        let qt = queryTypes.find((f: any) => f.nestedType == this.nestedType && f.nestedContent == this.nestedContent);

        if (qt && qt.regex) return qt.name + ": " + this.extra;

        let retorno = Object.keys(this.parameters)
            .map(parameter => this.getParameterDescription(qt, parameter))
            .join(" e ");

        return retorno;
    }

    getConvertedInlinedContains() {
        let converted = {
            "nested": {
                "path": this.property.split('.').slice(0, -1).join('.'),
                "query": { "bool": {} }
            }
        };

        switch (this.nestedContent) {
            case 'match':
                if (this.nestedList) {
                    converted.nested.query.bool['should'] = [];
                    this.parameters.forEach((prm: any) => {
                        let filter: any = { [this.nestedContent]: { [((this.keyword) ? `${this.property}.keyword` : this.property)]: prm } };
                        converted.nested.query.bool['should'].push({ "bool": { "must": [filter] } });
                    });
                } else {
                    let filter: any = { [this.nestedContent]: { [((this.keyword) ? `${this.property}.keyword` : this.property)]: this.parameters } };
                    converted.nested.query.bool['must'] = [filter];
                }

                break;
            case 'exists':
                converted.nested.query.bool['must'] = [{ [this.nestedContent]: { 'field': this.property } }];
                break;
            case 'range':
                let filter: any = { [this.nestedContent]: { [((this.keyword) ? `${this.property}.keyword` : this.property)]: this.parameters } };
                converted.nested.query.bool['must'] = [filter];
                break;

        }
        return converted;
    }

    convertFilter(): any {
        return this.getConvertedInlinedContains();
    }

    static fromRaw(raw): NestedElasticsearchFilter {
        return Object.assign(new NestedElasticsearchFilter(), raw);
    }
}
