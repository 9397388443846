import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import * as validate from "validate.js";
import { TranslateService } from "@ngx-translate/core";
import { ListaReguaEstrategiaComponent } from "../lista-regua-estrategia/lista-reguaestrategia.component";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { Estrategia } from "../../common/model/estrategia";
import { Router } from "@angular/router";
import { ReguaService } from "../../common/service/regua.service";

@Component({
    selector: "app-add-estrategia-regua-modal",
    templateUrl: "./add-estrategia-regua-modal.component.html",
    styleUrls: ["./add-estrategia-regua-modal.component.scss"],
    providers: [
        { useClass: ReguaService, provide: ReguaService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class AddEstrategiaReguaModalComponent {

    submited: boolean;
    estrategiaId: number;
    estrategias: Array<any> = [];

    constructor(
        private reguaService: ReguaService,
        private estrategiaService: EstrategiaService,
        private ref: MatDialogRef<ListaReguaEstrategiaComponent>,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA)
        public data: { reguaId: number, listaId: number, estrategiaId: number, reguaEstrategiaId: number, estrategiasVinculadas: number[] }
    ) {

        this.reguaService
            .obterEstrategias(data.listaId)
            .subscribe(data => {
                let d = data.filter((f: Estrategia) => !this.data.estrategiasVinculadas.some((s: number) => s == f.listaEstrategiaId));
                this.estrategias = d.map((m: Estrategia) => { return { 'estrategiaId': m.listaEstrategiaId, 'nome': m.nome } });
            });
    }

    save() {
        this.submited = true;

        if (this.validadeDisable()) {
            this.customAlertService.show("telaTemplate.template", "telaPadrao.camposInvalidos", "error");
            return;
        };

        this.data.estrategiaId = this.estrategiaId;
        return this.ref.close(this.data);
    }

    public get error() {
        if (this.submited) {
            return this.validate();
        }
    }

    public liveError(property: string) {

        if (this.submited) {
            var validationResult = this.validate();
            if (!validationResult) return null;
            return validationResult[property] ? validationResult[property][0] : null;
        }
    }

    validadeDisable() {
        var validationResult = this.validate();

        if (!validationResult)
            return false;

        return true;
    }

    validate() {

        let regras = {
            estrategiaId: {
                presence: { allowEmpty: false, message: "^obrigatorio" }
            }
        };

        let validationResult = validate.validate(this, regras);

        return validationResult;
    }

    redirecionarEstrategia() {
        if(!this.isNullOrZero(this.data.reguaEstrategiaId))
            this.router.navigate([`/app/jornada/${this.data.reguaId}/${this.data.reguaEstrategiaId}/${this.data.listaId}/estrategia/create`]);
        else
            this.router.navigate([`/app/jornada/${this.data.reguaId}/${this.data.listaId}/estrategia/create`]);

        this.ref.close();
    }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }

    isNullOrEmpty(valor: string) {
        return (valor == undefined || valor == null || valor == '');
    }

    isNull(valor: any) {
        return (valor == undefined || valor == null || valor == '' );
    }

    isNullOrZero(valor: any) {
        return (valor == undefined || valor == null || valor == '' || valor == 0 );
    }
    
}
