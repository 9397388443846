import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { convertToQueryString } from "../../common/service/query-string";
import { IndicadorProvedor } from "../model/indicador-provedor";

@Injectable()
export class IndicadorProvedorService {
    private api: string;

    constructor(private httpClient: HttpClient) {
        this.api = '/api/indicadorprovedor';
    }

    public obterIndicadores(filtro?: any) {
        var queryString = convertToQueryString(filtro);
        return this.httpClient.get<Array<IndicadorProvedor>>(`${this.api}${queryString}`);
    }
}
