import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Template } from "../../../common/model/template";
import { Provider } from "../../../common/model/provider";
import { TemplateService } from "../../../common/service/template.service";
import { ProviderService } from "../../../common/service/provider.service";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { isNullOrZero } from "../../../common/utils";

@Component({
    selector: "app-lista-template-exportacao",
    templateUrl: "./lista-template-exportacao.component.html",
    styleUrls: ["../../style.css", "./lista-template-exportacao.component.scss"],
    providers: [
        { useClass: TemplateService, provide: TemplateService },
        { useClass: ProviderService, provide: ProviderService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ListaTemplateExportacaoComponent implements OnInit {

    _templateImportacaoId: number;

    public get templateImportacaoId() {
        return this._templateImportacaoId;
    }

    @Input()
    public set templateImportacaoId(value: number) {
        if (!value) return;
        this._templateImportacaoId = value;
        this.filtrar();
    }

    templates: Array<Template> = [];
    provedores: Array<Provider> = [];
    filtroTemplateExportacao: any = { 'nome': '', 'provedor': null, 'templateImportacaoId': 0, 'categoria': 2 };

    constructor(
        private router: Router,
        private templateService: TemplateService,
        private providerService: ProviderService,
        private customAlertService: CustomAlertService
    ) { }

    ngOnInit() {
        this.obterProvedores();
        this.filtrar();
    }

    obterProvedores() {
        this.providerService.obterProviders().subscribe(listaProvider =>
             {this.provedores = listaProvider.sort((a,b) => {
                if ( a.descricao.toLowerCase() < b.descricao.toLowerCase()){
                    return -1;
                }
                if ( a.descricao.toLowerCase() > b.descricao.toLowerCase()){
                    return 1;
                }
                return 0;
            
        })});
    }

    filtrar() {
        if (isNullOrZero(this.templateImportacaoId))
            return;

        this.filtroTemplateExportacao.templateImportacaoId = this.templateImportacaoId;
        this.templateService.obterTemplates(this.filtroTemplateExportacao).subscribe(templates => (this.templates = templates));
    }

    limpar() {
        this.filtroTemplateExportacao = { 'nome': '', 'provedor': null, 'templateImportacaoId': 0, 'categoria': 2 };
        this.filtrar();
    }

    novo() {
        this.router.navigate([`/app/template/${this.templateImportacaoId}/create`]);
    }

    duplicar(listaTemplateId: number) {
        this.customAlertService.confirmationMessage("telaTemplate.confirmacaoDuplicacao").then(() => this.router.navigate(["/app/template/" + this.templateImportacaoId + "/create/" + listaTemplateId]));
    }

    editarTemplateExportacao(template: any) {
        this.router.navigate(["/app/template/" + this.templateImportacaoId + "/" + template.listaTemplateId]);
    }

    excluir(template: any) {
        if (template.quantidadeEstrategias > 0)
            return;

        this.customAlertService.confirmationMessage("telaTemplate.confirmacaoExclusao").then(() => {
            this.templateService.desativar(template.listaTemplateId).subscribe(() => this.templates = this.templates.filter((t: any) => t.listaTemplateId != template.listaTemplateId));
        });
    }
}
