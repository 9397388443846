import { find } from 'core-js/core/array';
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StorageProxy } from "./storage-proxy.service";

@Injectable({ providedIn: "root" })
export class LanguageService {

	public languages: string[] = ["pt", "en", "es"];

	public idiomas = [
		{ codigo: 'pt', nome: 'Português', bandeira: 'assets/images/flags/brazil.jpg' },
		{ codigo: 'en', nome: 'English', bandeira: 'assets/images/flags/us.jpg' },
		{ codigo: 'es', nome: 'Español', bandeira: 'assets/images/flags/mexico.jpg' }
	  ];

	constructor(public translate: TranslateService) {
        
		let browserLang;
		let linguagem  = StorageProxy.getString('linguagem')

		if (linguagem) {
			browserLang = linguagem;
		} else {
			browserLang = translate.getBrowserLang();
			
		}
		browserLang = browserLang.match(/pt|en|es|de|it|ru/) ? browserLang : "pt"
		this.translate.addLangs(this.languages);
		this.translate.use(browserLang);

		StorageProxy.set('linguagem', browserLang);
	}

	public setLanguage(lang) {
		this.translate.use(lang);
		StorageProxy.set('linguagem', lang);
	}

	public getLanguage()
	{
		return this.idiomas.find(objeto => objeto.codigo == StorageProxy.getString('linguagem'));
	}
}
