import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { environment } from "../../../environments/environment";
import { StorageProxy } from "../../modules/common/service/storage-proxy.service";
import { Router } from "@angular/router";
import { User } from "../../modules/autenticacao/model/user";
import { AutenticacaoService } from "../../modules/common/service/autenticacao.service";
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { Notificacao, NotificacaoTipo } from "../../modules/common/model/notificacao";
import { NotificacaoService } from "../../modules/common/service/notificacao.service";
import { FiltroNotificacao } from "../../modules/common/model/filtro-notificacao";
import { MatDialog, MatMenu, MatMenuTrigger } from "@angular/material";
import { DetalheNotificacaoComponent } from "../../modules/notificacao/detalhe-notificacao/detalhe-notificacao.component";
import { Injectable, Inject } from '@angular/core';
import { LayoutHeaderMenu } from '../../common/model/header-menu';
import { WINDOW } from "../../common/providers/window.provider";
import { PreferenciasUsuarioModalComponent } from "../../modules/preferencias-usuario/preferencias-usuario-modal.component";
import { LanguageService } from "../../modules/common/service/language.service";
import { Credential } from "../../modules/autenticacao/model/credential";
import { PermissaoCarteira, PermissaoLista } from "../../modules/common/model/permissoes-usuario";
import { ConfigProvider } from "../../common/providers/config.provider";


@Component({
	selector: "my-app-header",
	styleUrls: ["./header.component.scss"],
	templateUrl: "./header.component.html",
	providers: [
		{ useClass: NotificacaoService, provide: NotificacaoService },
		{ useClass: LanguageService, provide: LanguageService }
	]
})

export class AppHeaderComponent implements OnInit {
	public AppConfig: any;
	public usuario: User;
	public notificacao: Array<Notificacao> = new Array<Notificacao>();
	public notificacaoTipo = NotificacaoTipo;
	public novaNotificacao: boolean = false;
	public _hubConnection: HubConnection;
	public notificacaoTipoIcone: any = { "error": "cancel", "warning": "warning", "info": "info" };
	logo: string = 'talkmaestro'
	public menusContexto: Array<LayoutHeaderMenu> = [];
	idiomaSelecionado;
	idiomas;

	notificacoesNaoLidas: any;


	public get aaa() {
		return this.notificacoesNaoLidas.todos > 0;
	}


	@ViewChild(MatMenu, { static: true }) appHeaderMenu: MatMenu;
	@ViewChild(MatMenuTrigger, { static: true }) matHeaderMenuTrigger: MatMenuTrigger;

	@ViewChild(MatMenu, { static: true }) appNotificationMenu: MatMenu;
	@ViewChild(MatMenuTrigger, { static: true }) matNotificationMenuTrigger: MatMenuTrigger;

	@Output() menuClosed: EventEmitter<void>;


	constructor(private router: Router,
		private dialog: MatDialog,
		private autenticacaoService: AutenticacaoService,
		private notificacaoService: NotificacaoService,
		private languageService: LanguageService,
		private configProvider: ConfigProvider,
		@Inject(WINDOW) private window: Window
	) {

		this.idiomas = this.languageService.idiomas;
		this.idiomaSelecionado = this.languageService.getLanguage();

		if (this.window.location.hostname.includes('.teajudei'))
			this.logo = 'teajudei';

		if (this.window.location.hostname.includes('.epbx'))
			this.logo = 'talkmaestro';

		if (this.window.location.hostname.includes('.brbots'))
			this.logo = 'brbots';
	}

	ngOnInit() {
		this.AppConfig = environment;
		this.usuario = StorageProxy.get<Credential>('userData').user;
		this.menusContexto = this.getHeaderContextMenus();
		this.notificacaoService.obterNotificacao(new FiltroNotificacao()).subscribe(notificacao => (this.notificacao = [] /*notificacao*/));
		this.obterQuantidadeHistoricoNaoLido();
	}

	public get obterNomeUsuario() {
		this.usuario = StorageProxy.get<Credential>('userData').user;
		return this.usuario.userName;
	}

	toggleCollapsedNav() {
		this.AppConfig.navCollapsed = !this.AppConfig.navCollapsed;
	}

	notificacoes() {
		this.router.navigate(["app/notificacao"]);
	}

	possuiPermissaoNotificacao(notificacao: Notificacao): boolean {
		let carteiras = StorageProxy.get<Array<PermissaoCarteira>>('carteiras')
		let listas = StorageProxy.get<Array<PermissaoLista>>('listas')
		var permissaoLista = listas.length == 0 || notificacao.listaId == null || listas.some(l => l.listaId == notificacao.listaId);
		var permissaoCarteira = carteiras.some(c => c.carteiraId == notificacao.carteiraId);
		return permissaoLista && permissaoCarteira;
	}

	obterQuantidadeHistoricoNaoLido() {

		this.notificacaoService.obterQuantidadeHistoricoNaoLido().subscribe((result: any[]) => {
			this.notificacoesNaoLidas = result;//.map((m:any) => m as number);
		});
	}

	setLanguage(linguagem) {
		this.languageService.setLanguage(linguagem.codigo);
		this.idiomaSelecionado = linguagem;
		window.location.reload();
	}

	getHeaderContextMenus(): Array<LayoutHeaderMenu> {
		if (this.autenticacaoService.autenticado) {
			return [
				// {
				// 	name: "login.alterarSenha",
				// 	rota: 'alterarsenha',
				// 	acao: null
				// },
				{
					name: "header.preferenciasUsuario",
					rota: null,
					acao: () => {
						this.modalPreferenciasUsuario()
					},
				},
				{
					name: "header.perfil",
					rota: null,
					acao: () => {
						this.perfil()
					},
				},
				{
					name: "header.sair",
					rota: null,
					acao: () => {
						this.sair()
					},
				}];
		} else {
			return [];
		}
	}

	modalPreferenciasUsuario() {

		const preferenciasUsuarioRef = this.dialog.open(PreferenciasUsuarioModalComponent, {
			hasBackdrop: true,
			width: "800px",
			height: "280px",
			data: {}
		});

		preferenciasUsuarioRef.afterClosed().subscribe(() => { });
	}

	sair() {
		this.autenticacaoService.logoutUser().subscribe();
		this.router.navigate(["/login"]);
	}

	perfil() {
		this.router.navigate(["app/perfil"]);
	}
}