import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { EstrategiaCard } from "../../model/estrategia-card";
import { ListaCard } from "../../model/lista-card";
import { ListaService } from "../../../common/service/lista.service";
import { EstrategiaService } from "../../../common/service/estrategia.service";

@Component({
    selector: "app-dash-card-display",
    templateUrl: "./dash-card-display.component.html",
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: EstrategiaService, provide: EstrategiaService }
    ]
})
export class DashCardDisplayComponent implements OnInit {
    estrategiaCard: EstrategiaCard;
    listaCard: ListaCard;

    public get listaCardPrepared() {
        return {
            title: "dash.lista",
            titleValue: this.listaCard.totalLista,
            details: [
                {
                    label: "dash.quantidadeOffline",
                    value: this.listaCard.quantidadeOffline
                },
                {
                    label: "dash.quantidadeOnline",
                    value: this.listaCard.quantidadeOnline
                },
                {
                    label: "dash.quantidadeOutros",
                    value: this.listaCard.quantidadeOutros
                }
            ]
        };
    }

    public get registroCardPrepared() {
        return {
            title: "dash.registros",
            titleValue: this.listaCard.quantidadeRegistro,
            details: [
                {
                    label: "dash.quantidadeLivre",
                    value: this.listaCard.quantidadeLivre
                },
                {
                    label: "dash.quantidadeDistribuido",
                    value: this.listaCard.quantidadeDistribuido
                }
            ]
        };
    }

    public get estrategiaCardPrepared() {
        return {
            title: "dash.estrategia",
            titleValue: this.estrategiaCard.totalEstrategias,
            details: [
                {
                    label: "dash.totalExecucoes",
                    value: this.estrategiaCard.totalExecucoes
                },
                {
                    label: "dash.totalRecorrentes",
                    value: this.estrategiaCard.totalRecorrentes
                },
                {
                    label: "dash.totalRegistrosEnviados",
                    value: this.estrategiaCard.totalRegistrosEnviados
                }
            ]
        };
    }

    constructor(
        private listaService: ListaService,
        private estrategiaService: EstrategiaService
    ) {
        this.listaCard = new ListaCard();
        this.estrategiaCard = new EstrategiaCard();
        //this.updateData();
    }

    updateData(listId = null, carteiraId = null) {
        this.listaService
            .obterListaCard(listId, carteiraId)
            .subscribe(listaCard => (this.listaCard = listaCard));
        this.estrategiaService
            .obterCard(listId, carteiraId)
            .subscribe(estrategia => (this.estrategiaCard = estrategia));
    }

    ngOnInit() { }
}
