import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ConfigProvider } from "../../../common/providers/config.provider";

@Component({
    selector: "app-lista-documentacao",
    templateUrl: "./lista-documentacao.component.html",
    styleUrls: ["../style.scss", "../swagger-ui.css"],
    providers: []
})

export class ListaDocumentacaoComponent implements OnInit {
    swaggerURL: SafeResourceUrl = '';

    constructor(private translate: TranslateService, public sanitizer: DomSanitizer, private configProvider: ConfigProvider) 
    {
        let endpoint = this.configProvider.getConfig().serverUrl;
        this.swaggerURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${endpoint}/docs/index.html`);
    }

    ngOnInit() { }
}
