import { ModelToQuery } from "../../esbuilder/libs/utils";
import { ESBuilderData, ESBuilderRules } from "../../esbuilder/models";
import { isNullOrZero } from "../utils";
import { FiltroStatusRegistroLista } from "./lista";

const validate = require("validate.js");

export class ElasticSearch {

    elasticSearchId: number;
    padrao: boolean;
    carteiraId: number;
    nome: string;
    pathUrl: string;
    usuario: string;
    senha: string;
    timeoutMinutos: number;
    numeroShards: number;
    numeroReplicas: number;
    versao: string;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;

    carteiraNome: string;
    nomeUsuarioCadastro: string;
    existemListasVinculadas: boolean;
    versaoValida: boolean;

    constructor() {
        this.timeoutMinutos = 50
        this.numeroShards = 1;
        this.numeroReplicas = 0;
    }

    validar() {
        let validacao: any = {
            //'padrao': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
            'carteiraId': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
            'nome': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
            'pathUrl': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
            'timeoutMinutos': {
                'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                'numericality': { 'onlyInteger': true, 'greaterThanOrEqualTo': 20, 'message': "^menorque0" }
            },
            'numeroShards': {
                'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                'numericality': { 'onlyInteger': true, 'greaterThanOrEqualTo': 1, 'message': "^menorque0" }
            },
            'numeroReplicas': {
                'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                'numericality': { 'onlyInteger': true, 'greaterThanOrEqualTo': 0, 'message': "^menorque0" }
            }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(raw: any): ElasticSearch {
        return Object.assign(new ElasticSearch(), raw);
    }
}
