//import { isNullOrEmpty } from "../libs/utils";
import { ESBuilderRules } from "./rules.model";

export enum ESBFilterType {
    standard = 0,
    advanced = 1
}

export enum ESBFiltroStatusRegistroLista {
    naoUtilizado = 0,
    total = 1,
    utilizado = 2
}

export class ESBuilderData {
    rules: Array<ESBuilderRules>;
    type: string;
    filterType: ESBFilterType;
    includeIntegratedRegister: ESBFiltroStatusRegistroLista;

    constructor() {
        this.rules = new Array<ESBuilderRules>();
        this.type = 'bool';
        this.filterType = ESBFilterType.standard;
        this.includeIntegratedRegister = ESBFiltroStatusRegistroLista.naoUtilizado;
    }

    static fromRaw(raw: any) {
        let data = new ESBuilderData();
        Object.assign(data, raw);

        if(raw.filterType === undefined || raw.filterType === null || raw.filterType === '')
            data.filterType = ESBFilterType.advanced;

        data.rules = data.rules.map(m => ESBuilderRules.fromRaw(m));
        return data;
    }
}