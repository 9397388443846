import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NNotificacaoTipoEvento } from "../../../common/model/nnotificacao.index";

@Pipe({ name: "notificacaoTipoEvento" })
export class NotificacaoTipoEventoPipe implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(value: NNotificacaoTipoEvento, color: boolean = false, args?: any): any {
        switch (value) {
            case NNotificacaoTipoEvento.BancoDadosOffLine:
                return (color) ? '#ae7cff' : this.translate.instant('telaNNotificacao.eventos.enum.bancoDadosOffLine');
            case NNotificacaoTipoEvento.DownloadRegistro:
                return (color) ? '#c0851f' : this.translate.instant('telaNNotificacao.eventos.enum.downloadRegistro');
            case NNotificacaoTipoEvento.EstrategiaCriada:
                return (color) ? '#34c38f' : this.translate.instant('telaNNotificacao.eventos.enum.estrategiaCriada');
            case NNotificacaoTipoEvento.EstrategiaAlterada:
                return (color) ? '#34c38f' : this.translate.instant('telaNNotificacao.eventos.enum.estrategiaAlterada');
            case NNotificacaoTipoEvento.FiltroAlterado:
                return (color) ? '#55ace6' : this.translate.instant('telaNNotificacao.eventos.enum.filtroAlterado');
            case NNotificacaoTipoEvento.EstrategiaIniciada:
                return (color) ? '#25a14b' : this.translate.instant('telaNNotificacao.eventos.enum.estrategiaIniciada');
            case NNotificacaoTipoEvento.EstrategiaFinalizada:
                return (color) ? '#25a14b' : this.translate.instant('telaNNotificacao.eventos.enum.estrategiaFinalizada');
            case NNotificacaoTipoEvento.EstrategiaCancelada:
                return (color) ? '#25a14b' : this.translate.instant('telaNNotificacao.eventos.enum.estrategiaCancelada');
            case NNotificacaoTipoEvento.EstrategiaErro:
                return (color) ? '#f46a6a' : this.translate.instant('telaNNotificacao.eventos.enum.estrategiaErro');
            case NNotificacaoTipoEvento.EstrategiaZerada:
                return (color) ? '#df3b3b' : this.translate.instant('telaNNotificacao.eventos.enum.estrategiaZerada');
            case NNotificacaoTipoEvento.ListaCriada:
                return (color) ? '#556ee6' : this.translate.instant('telaNNotificacao.eventos.enum.listaCriada');
            case NNotificacaoTipoEvento.ListaAlterada:
                return (color) ? '#556ee6' : this.translate.instant('telaNNotificacao.eventos.enum.listaAlterada');
            case NNotificacaoTipoEvento.ListaLimpa:
                return (color) ? '#556ee6' : this.translate.instant('telaNNotificacao.eventos.enum.listaLimpa');
            case NNotificacaoTipoEvento.SaudeIndice:
                return (color) ? '#6f41da' : this.translate.instant('telaNNotificacao.eventos.enum.saudeIndice');
            case NNotificacaoTipoEvento.TemplateImportacaoCriado:
                return (color) ? '#f8ad2c' : this.translate.instant('telaNNotificacao.eventos.enum.templateImportacaoCriado');
            case NNotificacaoTipoEvento.TemplateImportacaoAlterado:
                return (color) ? '#f8ad2c' : this.translate.instant('telaNNotificacao.eventos.enum.templateImportacaoAlterado');
            default:
                return '';
        }
    }
}