import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { convertToQueryString } from "./query-string";
import { ListaPrioridadePadrao } from "../model/lista-prioridade-padrao";
import { FiltroPrioridadeAgrupado } from "../model/filtro-prioridade-agrupado-dto";


@Injectable()
export class ListaPrioridadePadraodService {
	private api: string;

	constructor(private httpClient: HttpClient) { 
		this.api = '/api/ListaPrioridadePadrao';
	}

	public obterListasPrioridadePadrao(filtro: any) {
		return this.httpClient.get<Array<ListaPrioridadePadrao>>(`${this.api}/prioridade-padrao${convertToQueryString(filtro)}`)
			.pipe(map(r => r.map(m => ListaPrioridadePadrao.fromRaw(m))));
	}

	public obterListasPrioridadePadraoAgrupado(filtro: any) {
		return this.httpClient.get<Array<FiltroPrioridadeAgrupado>>(`${this.api}/prioridade-padrao/agrupado-lista${convertToQueryString(filtro)}`)
			.pipe(map(r => r.map(m => FiltroPrioridadeAgrupado.fromRaw(m))));
	}

	public obterPorId(id) {
		return this.httpClient.get<ListaPrioridadePadrao>(`${this.api}/prioridade-padrao/${id}`)
			.pipe(map(query => ListaPrioridadePadrao.fromRaw(query)));
	}

	public criar(query: ListaPrioridadePadrao) {
		return this.httpClient.post<number>(`${this.api}/prioridade-padrao/`, query);
	}

	public atualizar(query: ListaPrioridadePadrao) {
		return this.httpClient.put<number>(`${this.api}/${query.listaPrioridadePadraoId}/prioridade-padrao?`, query);
	}

	public vincularEstrategias(listaPrioridadePadraoId: number, filtroQueryEstrategiasAlterar: Array<any>) {
		return this.httpClient.put<number>(`${this.api}/${listaPrioridadePadraoId}/vincular-estrategias/`, filtroQueryEstrategiasAlterar);
	}

    public desativar(id: any) {
        return this.httpClient.delete<number>(`${this.api}/${id}`)
    }
}
