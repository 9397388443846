import { TranslateModule } from '@ngx-translate/core';
import { APP_INITIALIZER, NgModule } from "@angular/core";

import { LayoutRoutingModule } from "./layout-routing.module";
import { SharedModule } from "../shared/shared.module";
import { AuthGuard } from "../modules/common/auth.guard";
import { FabMenuComponent } from "./fab-menu/fab-menu.component";
import { SimpleNotificationsModule } from "angular2-notifications";
import { APPCONFIG, ConfigProvider, ConfigProviderFactory } from "../common/providers/config.provider";
import { PreferenciasUsuarioModule } from "../modules/preferencias-usuario/preferencias-usuario.module";
import { NgSelectModule } from "@ng-select/ng-select";

import { AppCommonModule } from "../modules/common/common.module";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [LayoutRoutingModule, SharedModule, SimpleNotificationsModule, PreferenciasUsuarioModule, NgSelectModule,  AppCommonModule,CommonModule], 
  entryComponents: [],
  declarations: [],
  providers: [
    { useClass: AuthGuard, provide: AuthGuard }
  ]
})
export class LayoutModule { }
