import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-campanha-display",
  templateUrl: "./campanha-display.component.html"
})
export class CampanhaDisplayComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CampanhaDisplayComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {}

  fechar() {
    this.dialogRef.close();
  }
}
