import { SortEvent } from "primeng/api";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Component, OnInit, Input, Inject } from "@angular/core";
import { ElasticsearchRepositoryService } from "../../../elasticsearch/repository/elasticsearch-repository.service";
import { Subscription } from "rxjs";
import * as XLSX from 'xlsx';
import { ListaService } from "../../../common/service/lista.service";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { DownloadRegistro, DownloadRegistroStatus } from "../../../common/model/download-registro";
import { DownloadRegistroService } from "../../../common/service/download-registro.service";
import { isNullOrEmpty } from "../../../common/utils";
import { deepCopy } from "../../../common/utils/deepCopy";
@Component({
    selector: "app-preview-dados-estrategia",
    templateUrl: "./preview-dados-estrategia.component.html",
    styleUrls: ["./preview-dados-estrategia.component.scss"],
    providers: [
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: DownloadRegistroService, provide: DownloadRegistroService }
    ]
})
export class PreviewDadosEstrategiaComponent implements OnInit {
    dados: any[];
    total: number;
    @Input()
    lista: any;
    @Input()
    templateId: string;
    @Input()
    query;
    sortField: string;
    sortDirection: number;
    camposMapeados: any[];
    camposMapeadosFiltro: any[];
    camposSelecionados: string = "";
    campo: any;
    filterQuery: string;
    pageSize: number = 10;
    pageIndex: number = 0;
    pageIndexLast: number = 0;
    dataPreview: any;
    subDownload: Subscription;
    constructor(
        private elasticsearchRepositoryService: ElasticsearchRepositoryService,
        private listaService: ListaService,
        private customAlertService: CustomAlertService,
        private downloadRegistroService: DownloadRegistroService,
        private router: Router,
        public dialogRef: MatDialogRef<PreviewDadosEstrategiaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any    ) {
        
        this.dataPreview = deepCopy(this.data);
    }
    public get title() {
        if (this.dataPreview == null || this.dataPreview.title == null) return '';
        return ` - ${this.dataPreview.title}`;
    }
    public get sort() {
        if (this.sortField == null) return [];
        return [{ [this.sortField]: this.sortDirection == -1 ? "desc" : "asc" }];
    }
    public get colunas(): string[] {
        return Object.keys(this.dados[0]);
    }
    public get colunasDisplay(): string[] {
        if (this.dados.length == 0) return [];
        return Object.keys(this.dados[0]).map(
            item => `${item[0].toUpperCase()}${item.substring(1)}`        );
    }
    getHasOrdenation(field: string): boolean {
        const fieldFounded = this.camposMapeados.find(
            c => c.nome.toLowerCase() == field.toLowerCase()
        );
        if (undefined == fieldFounded) return false;
        return (
            fieldFounded.tipoDado == "Numerico" || fieldFounded.tipoDado == "Lista"        );
    }
    ngOnInit() {
        this.getPreviewItem(this.dataPreview.query);
    }
    atualizarPreview() {
        this.getPreviewItem(this.dataPreview.query)
    }
    getPreviewItem(query) {
        this.elasticsearchRepositoryService            .preview(this.dataPreview.listaId, this.dataPreview.templateId, query)
            .subscribe(result => {
                this.dados = result.listaItem;
                this.total = result.total;
                this.camposMapeados = result.camposMapeados;
                this.camposMapeadosFiltro = result.camposMapeados.filter(f => f.itemTipo == 1);
            });
    }
    queryChange() {
        this.pageIndex = 0;
        let query = deepCopy(this.dataPreview.query);
        query.size = this.pageSize;
        query.from = this.pageIndex * this.pageSize;
        query.sort = this.sort;
        var identificador = this.camposMapeados.find(c => c.listaTemplateItemId.toString() == this.camposSelecionados);
        //var identificador = this.camposMapeados.find(c => c.identificador == true);        
        //trocar o find por um filtro que escolha o nome do campo.        
        //colocar um dropdown dos itens mapeados. Ai usar o campo no find        
        this.campo = this.formatElasticField(identificador.nome);
        let must = {};
        switch (identificador.tipoDado) {
            case "Sim/Não":
            case "Boolean":
                must['term'] = {};
                must['term'][this.campo] = { query: this.filterQuery };
                break;
            case "Data":
                must['range'] = {};
                must['range'][this.campo] = { gte: this.filterQuery, lte: this.filterQuery };
                break;
            case "Inteiro":
                must['match'] = {};
                must['match'][this.campo] = { query: this.filterQuery };
                break;
            case "Long":
                must['match'] = {};
                must['match'][this.campo] = { query: this.filterQuery };
                break;
            case "Decimal":
                must['match'] = {};
                must['match'][this.campo] = { query: this.filterQuery };
                break;
            case "Texto":
            case "Lista":
                must['query_string'] = {};
                must['query_string'] = { default_field: this.campo, query: "*" + this.filterQuery + "*" };
                break;
        }
        query.query.bool.must = [must];
        this.dataPreview.query = query;
        if (isNullOrEmpty(this.filterQuery)) {
            this.pageIndex = this.pageIndexLast;
            this.getPreviewItem(this.dataPreview.query);
        } else {
            this.getPreviewItem(query);
        }
    }
    changePage(event) {
        this.dataPreview.query.size = event.pageSize;
        this.dataPreview.query.from = event.pageIndex * event.pageSize;
        this.dataPreview.query.sort = this.sort;
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.pageIndexLast = event.pageIndex;
        this.getPreviewItem(this.dataPreview.query);
    }
    fechar() {
        this.dialogRef.close();
    }
    formatElasticField(alias) {
        if (this.isUpperCase(alias)) return alias.toLowerCase();
        return `${alias[0].toLowerCase()}${alias.substring(1)}`;
    }
    isUpperCase(text) {
        return text.toUpperCase() == text;
    }
    clearSort() {
        this.sortField = null;
        this.sortDirection = null;
    }
    customSort(event: SortEvent) {
        event.field = this.formatElasticField(event.field);
        if (this.sortField == event.field && this.sortDirection == event.order)
            return;
        if (event.order == 0) this.clearSort();
        else {
            this.sortField = event.field;
            this.sortDirection = event.order;
        }
        this.changePage({ pageSize: this.dataPreview.query.size, pageIndex: 0 });
    }
    fazerDownloadPreview() {
        this.subDownload = this.elasticsearchRepositoryService.downloadPreview(this.dataPreview.lista, this.dataPreview.listaId, this.dataPreview.templateId, this.dataPreview.query).subscribe(result => {
            this.exportAsExcelFile(result.listaItem, 'Preview');
        });
    }
    fazerDownloadTotal() {
        this.customAlertService            .showWithConfiguration({
                title: "telaEstrategia.telaPreview.downloadTotal",
                text: "telaEstrategia.telaPreview.confirmacaoDownload",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "telaPadrao.sim",
                cancelButtonText: "telaPadrao.nao"            })
            .then(data => {
                if (data.dismiss == swal.DismissReason.cancel)
                    return;
                var downloadRegistro = new DownloadRegistro();
                downloadRegistro.listaId = this.dataPreview.listaId;
                downloadRegistro.filtro = JSON.stringify(this.dataPreview.query);
                downloadRegistro.downloadRegistroStatusId = DownloadRegistroStatus.Pendente;
                this.downloadRegistroService.criarDownloadTotal(downloadRegistro).subscribe(result => {
                    this.customAlertService.show(
                        "telaEstrategia.telaPreview.downloadTotal",
                        "telaEstrategia.telaPreview.sucessoCriar",
                        "success"                    );
                    this.router.navigate(["/app/downloadregistro"]);
                });
            });
    }
    ngOnDestroy() {
        if (this.subDownload) this.subDownload.unsubscribe();
    }
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const EXCEL_EXTENSION = '.xlsx';
        XLSX.writeFile(workbook, excelFileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}