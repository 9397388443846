
import { Order } from "../../estrategia/models/order";
import { FiltroStatusRegistroLista, Lista } from "./lista";

const validate = require("validate.js");

export class ListaPrioridadePadrao {
    listaPrioridadePadraoId: number;
    listaId: number;
    nome: string;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;
    listaPrioridadePadraoItens: Array<Order>;
    listaNome: string;
    nomeUsuarioCadastro: string;
    listaTemplateId: number;

    estrategiasVinculadas: Array<number>;

    constructor() {
        this.listaPrioridadePadraoItens = new  Array<Order>();       
        this.nome = "";
        this.listaPrioridadePadraoId = 0;
        this.listaId = 0;
        this.listaTemplateId = 0;
        this.ativo = true;
        this.estrategiasVinculadas = new Array<any>();
    }

    validar() {
        var validacao: any = {
            nome: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            },
            listaId: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(rawFluxo: any): ListaPrioridadePadrao {
        
        return Object.assign(new ListaPrioridadePadrao(), rawFluxo);
    }



}
