import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';

import { FiltroLista } from '../../common/model/filtro-lista';

import { CustomAlertService } from "../../common/service/custom-alert.service";
import { CarteiraService } from "../../common/service/carteira.service";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";

import { isNull } from "../../common/utils";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";
import { Carteira } from "../../common/model/carteira";
import { ElasticSearchService } from "../../common/service/elasticsearch.service";
import { ElasticSearch } from "../../common/model/elastic-search";


@Component({
    selector: "app-lista-elasticsearch",
    templateUrl: "./lista-elasticsearch.component.html",
    styleUrls: ["./lista-elasticsearch.component.scss"],
    providers: [
        { useClass: ElasticSearchService, provide: ElasticSearchService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ListaElasticSearchComponent implements OnInit {
    preferenciasUsuario: PreferenciasUsuario;
    carteiras: Array<Carteira> = [];

    filtro: { 'id': string, 'nome': string, 'carteiraId': number | null } = { 'id': '', 'nome': '', 'carteiraId': null };
    listaElasticSearchs: Array<ElasticSearch>;


    @ViewChild(DataView, { static: false }) dataTable: DataView;
    constructor(
        private elasticSearchService: ElasticSearchService,
        private carteiraService: CarteiraService,
        private preferenciasService: PreferenciasUsuarioService,
        private customAlertService: CustomAlertService,
        public dialog: MatDialog
    ) {
        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();
    }

    ngOnInit() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            })

            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }

            this.filtrar();
        });
    }


    filtrar() {
        this.elasticSearchService.obterElasticSearchs(this.filtro).subscribe(data => this.listaElasticSearchs = data);
    }

    carteiraChange() { }

    limpar() {
        this.filtro = { 'id': '', 'nome': '', 'carteiraId': null };
    }

    excluir(elasticSearchId: number) {
        this.customAlertService
            .confirmationMessage("telaElasticSearch.confirmacaoDesativarElastic")
            .then(() => {
                this.elasticSearchService.desativar(elasticSearchId).subscribe((retorno: any) => {
                    this.filtrar();
                });
            });
    }
}