export class NNotificacaoCarteira {

    id: number;
    notificacaoId: number;
    carteiraId: number;

    constructor() { }

    static fromRaw(raw: any): NNotificacaoCarteira {
        let model: NNotificacaoCarteira = Object.assign(new NNotificacaoCarteira(), raw);
        return model;
    }
}