import { Directive, ElementRef, Input } from "@angular/core";
import { ListaStatus } from "../../common/model/listaStatus";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "[appListaStatusDisplay]"
})
export class ListaStatusDisplayDirective {
  
  _status;
  @Input("appListaStatusDisplay")
  public set status(status: ListaStatus) {
    this._status = status;
    this.changeDisplayName();
  }

  public get status(): ListaStatus {
    return this._status;
  }

  changeDisplayName() {
    this.el.nativeElement.innerHTML =  this.translate.instant("statusLista." +  ListaStatus[this._status]);
  }

  constructor(private el: ElementRef,
    private translate: TranslateService) {}
}
