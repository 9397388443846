import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { isNullOrZero } from "../common/utils";
import { CustomAlertService } from "../common/service/custom-alert.service";
import { PreferenciasUsuarioService } from "../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../common/model/preferencias-usuario";
import { Carteira } from "../common/model/carteira";
import { CarteiraService } from "../common/service/carteira.service";
import { Lista } from "../common/model/lista";
import { ListaService } from "../common/service/lista.service";

@Component({
    selector: "app-preferencias-usuario-modal",
    templateUrl: "./preferencias-usuario-modal.component.html",
    styleUrls: ["./preferencias-usuario-modal.component.scss"],
    providers: [
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class PreferenciasUsuarioModalComponent {
    preferencias: PreferenciasUsuario;
    carteiras: Array<Carteira> = [];
    listas: Array<Lista>;

    public get existePreferenciaCarteira(): boolean {
        return this.preferencias.existePreferenciaCarteira;
    }

    constructor(
        private preferenciasService: PreferenciasUsuarioService,
        private carteiraService: CarteiraService,
        private listaService: ListaService,
        public dialogRef: MatDialogRef<PreferenciasUsuarioModalComponent>,
        private customAlertService: CustomAlertService,
        @Inject(MAT_DIALOG_DATA)
        public data: {}
    ) {
        this.preferencias = new PreferenciasUsuario();
        this.obterPreferencias();
        this.obterCarteiras();
    }

    obterPreferencias() {
        this.preferenciasService.obterPorUsuario().subscribe((preferencias: PreferenciasUsuario) => this.preferencias = preferencias);
    }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe((carteiras: Array<Carteira>) => {
            this.carteiras = carteiras;
            this.obterListas();
        });
    }

    obterListas() {
        this.listaService.obterListaSemQuantidade({ "carteiraId": this.preferencias.carteiraId }).subscribe((listas: Array<Lista>) => this.listas = listas);
    }

    carteiraChange() {
        this.preferencias.listaId = null;
        this.obterListas();
    }

    gravar() {
        const requisicao = (isNullOrZero(this.preferencias.preferenciaUsuarioId))
            ? this.preferenciasService.criar(this.preferencias)
            : this.preferenciasService.atualizar(this.preferencias);

        requisicao.subscribe(
            (result: PreferenciasUsuario) => {
                this.customAlertService.show("telaPreferenciasUsuario.preferenciasUsuario", "telaPreferenciasUsuario.salvarPreferencias", "success");
                this.preferenciasService.localStorageGravarPreferenciasUsuario(result);
                this.dialogRef.close();
            },
            (error: any) => {
                this.customAlertService.show("telaPreferenciasUsuario.preferenciasUsuario", error.error[0], "error");
            });
    }

    fechar() {
        this.dialogRef.close();
    }
}
