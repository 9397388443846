import { ChangeDetectorRef, Pipe, PipeTransform } from "@angular/core";
import { ESBuilderFilterType } from "../../../esbuilder/models";
import { ESBuilderData } from "../../../esbuilder/models/data.model";
import { ESBuilderRules } from "../../../esbuilder/models/rules.model";
import * as dayjs from 'dayjs';
import { esbFormatFieldName } from "../../../esbuilder/libs/utils/esbFormatFieldName";
import { isNull } from "../../../common/utils";
import { isNullOrEmpty } from "../utils";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";


@Pipe({
    name: 'dataruledescription',
    pure: false
})
export class ESBuilderDataRulePrettyDescriptionPipe implements PipeTransform {
    
    translatePipe: TranslatePipe;
    constructor(private translate: TranslateService, private _ref: ChangeDetectorRef) {
        this.translatePipe = new TranslatePipe(translate, _ref);
    }

    transform(value: any, args: any[]): any {

        //try {
        /**
         * check and try to parse value if it's not an object
         * if it fails to parse which means it is an invalid JSON
         */
        return this.generate(
            ESBuilderData.fromRaw(typeof value === 'object' ? value : JSON.parse(value)),
            args[0],
            args[1]
        );
        // } catch (e) {
        //     return this.applyColors({ error: 'Invalid JSON' }, args[0], args[1]);
        // }
    }

    generate(obj: ESBuilderData, showNumebrLine: boolean = false, padding: number = 4) {
        let html = this.convert(obj.rules);
        return html;
    }

    convert(rules: ESBuilderRules[]) {
        let html = '';

        rules.forEach(rule => {
            let condition = rule.condition;
            let content: string = '';

            if (rule.rules != null && rule.rules.length > 0) {
                content = `( <ul>${this.convert(rule.rules)}</ul> )`
            } else {
                let field = rule.field;
                let fieldType = ESBuilderFilterType[rule.fieldType];
                
                if (!isNullOrEmpty(fieldType)) {

                    let filterType = (rule.fieldType == 'Nested')
                        ? fieldType.find((f: any) => (f.nestedType == rule.fieldNestedType && f.filterType == rule.filterType))
                        : fieldType.find((f: any) => (f.filterType == rule.filterType));

                    let operation = this.translatePipe.transform(filterType.name) ;
                    let obj = { ...rule.filterValues }

                    Object.keys(obj).forEach((item: string) => {
                        if (obj[item] instanceof Date)
                            obj[item] = dayjs(rule.filterValues[item]).format("DD/MM/YYYY HH:mm:ss");
                    });

                    

                    let values = Object.values(obj).map((m: any) => {

                        if(m.suffix === undefined)
                            return (m instanceof Date) ? `"${dayjs(m).format("DD/MM/YYYY HH:mm:ss")}"` : `"${m}"`;
                        else
                            return `"${m.label}"`;

                    }).join(" e ");

                    content = `${field} ${operation} ${values}`;
                }
            }

            if (!isNullOrEmpty(content))
                html += `<li class="condition_${condition}">${content}</li>`;
        });

        return html;
    }


}

/*
<ul class="filter-description" *ngIf="viewFilters">
    <li class="none">Inteiro Maior que "200"</li>
    <li class="and">(
        <ul>
            <li class="none">Inteiro Igual à "251"</li>
            <li class="or">Ou Inteiro Igual à "252"</li>
        </ul>
        )
    </li>
</ul>

*/


//let text = `${field} ${operation} ${values}`;

// let filterValues = rule.filterValues.gt;
// let filter: any = {};

// switch (filterType.content) {
//     case 'query_string':
//         //return this.convertFilterQueryString(filterType, ruleItem);
//         break;
//     case 'terms':
//         //return this.convertFilterTerms(filterType, ruleItem);
//         break;
//     case 'exists':
//         //return this.convertFilterFieldQuery(filterType, ruleItem);
//         break;
//     case 'wildcard':
//         //return this.convertFilterWildcard(filterType, ruleItem);
//         break;
//     case 'script':
//         //return this.convertFilterEmptyQuery(filterType, ruleItem);
//         break;
//     case 'regexp':
//         //return this.convertFilterRegex(filterType, ruleItem);
//         break;
//     case 'term':
//         //return this.convertFilterTerm(filterType, ruleItem);
//         break;
//     case 'nested':
//         //return this.convertFilterNested(filterType, ruleItem);
//         break;
//     default:
//         //return this.convertFilterBase(filterType, ruleItem);
//         //Inteiro Maior que "200"

//let field = esbFormatFieldName(rule.field);
// let operation = filterType.name;
// let values = Object.values({ ...rule.filterValues }).map((m: any) => `"${m}"`).join(" e ");
// let text = `${field} ${operation} ${values}`;
//break;
//}