import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appTabIndexSelect]'
})
export class TabIndexSelectDirective {
  _tabindex;
  @Input("appTabIndexSelect")
  public set tabindex(tamanho: number) {
    if (!tamanho) tamanho = 0;
    this._tabindex = tamanho;
  }

  public get tabindex(): number {
    return this._tabindex;
  }

  constructor(private element: ElementRef) {

  }

  protected ngAfterViewInit(){    
    if(this.element.nativeElement){
      this.element.nativeElement.children[0].tabIndex = this._tabindex;
    }
  }
}
