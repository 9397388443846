import { CarteiraUsuario } from "./carteira-usuario";
import { CurrencyPipe } from "@angular/common";
import { Inject, Optional } from "@angular/core";
const validate = require("validate.js");

export class PlanoTarifaProvedor {

    planoTarifaProvedorId: number;
    provedorId: number;
    faixaInicial: number;
    faixaFinal: number;
    valor: number;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date | null;
    usuarioAlteracao: number;
    ativo: boolean

    public get valorFormatado(): string | null {
        let v = this.currencyPipe.transform(this.valor, 'BRL');
        return v;
    }

//     public set valorFormatado(value: string | null) {
//         let v = this.currencyPipe.transform(this.valor, 'BRL');
// return v;
//     }



@Inject(CurrencyPipe) private currencyPipe: CurrencyPipe
constructor(provedorId: number = 0) {
    this.planoTarifaProvedorId = 0;
    this.provedorId = provedorId;
    this.faixaInicial = 0;
    this.faixaFinal = 0;
    this.valor = 0;
    this.ativo = true;
}

validar() {
    let validacao: any = {
        "faixaInicial": { "presence": { "allowEmpty": false, "message": '^campoObrigatorio' } },
        "faixaFinal": { "presence": { "allowEmpty": false, "message": '^campoObrigatorio' } },
        "valor": { "presence": { "allowEmpty": false, "message": '^campoObrigatorio' } },
    };

    return validate.validate(this, validacao);
}

    static fromRaw(raw: any): PlanoTarifaProvedor {
    return Object.assign(new PlanoTarifaProvedor(), raw);
}
}