import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CampoFormato, ConfiguracaoCampoProvider } from "../../../../common/model/configuracao-campo-provedor";
import { MapeamentoCampanha } from "../../../../common/model/mapeamento-campanha";
import { Provider } from "../../../../common/model/provider";
import { CustomAlertService } from "../../../../common/service/custom-alert.service";
import { ProviderService } from "../../../../common/service/provider.service";
import { CampoJson } from "../../../../template/form-template-exportacao/model/campoJson";

@Component({
    selector: "app-teste-envio-modal",
    templateUrl: "./teste-envio-modal.component.html",
    styleUrls: ["./teste-envio-modal.component.scss"],
    providers: []
})
export class TesteEnvioModalComponent {
    abaSelecionada: number = 0;
    provedor: Provider;
    camposEnvioForm: Array<{ campo: string, valor: string }> = [];
    camposJson: Array<CampoJson> = [];
    campo: CampoJson = new CampoJson();
    campoEnvioRaw: any;
    campoFormatoEnum = CampoFormato;
    resultadoTeste: any;
    jsonRetorno: any

    //#region [ Campos de Campanha]

    configuracaoPorCampanha: boolean = false;
    layoutPorCampanha: boolean = false;
    listaCampanha = new Array<MapeamentoCampanha>();
    campanhaSelecionada: number;

    //#endregion


    constructor(
        private ref: MatDialogRef<TesteEnvioModalComponent>,
        public providerService: ProviderService,
        private customAlertService: CustomAlertService,
        @Inject(MAT_DIALOG_DATA) public data: { provedor: Provider }
    ) {
        this.provedor = Object.assign(new Provider(), data.provedor);

        if (this.provedor.envio.campoFormatoId != this.campoFormatoEnum.Raw) {
            if (this.provedor.configuracaoPorCampanha) {
                this.configuracaoPorCampanha = true;

                if (!this.provedor.campanha.layoutPorCampanha) {
                    this.camposEnvioForm = this.provedor.configuracaoCampoEnvioFormAtivo.map((c: any) => ({ campo: c.nome, valor: null }));
                } else {
                    this.layoutPorCampanha = true;
                }
                this.obterCampanhasConfiguradas();
            }
            else {
                this.camposEnvioForm = this.provedor.configuracaoCampoEnvioFormAtivo.map((c: any) => ({ campo: c.nome, valor: null }));
            }
        } else {
            this.provedor.configuracaoCampoEnvioRawAtivo.formatarJson();
            this.campoEnvioRaw = this.provedor.configuracaoCampoEnvioRawAtivo.campoJson;
            this.campoEnvioRaw = typeof (this.campoEnvioRaw) == 'object' ? this.campoEnvioRaw : JSON.parse(this.campoEnvioRaw);

            this.transformarCamposJsonEmArray(this.campoEnvioRaw);
        }
    }

    fechar() {
        this.ref.close();
    }

    enviar() {
        if (this.provedor.envio.campoFormatoId == this.campoFormatoEnum.Raw)
            this.atualizarCampoEnvioRaw();

        this.providerService.testarEnvio({ dadosEnvio: this.provedor.envio, camposEnvioForm: this.camposEnvioForm, campoEnvioRaw: this.campoEnvioRaw, idCampanha: this.campanhaSelecionada }).subscribe(
            result => {
                this.resultadoTeste = result;
                this.abaSelecionada = 1;

                this.jsonRetorno = JSON.parse(this.resultadoTeste.retorno);

                this.customAlertService.show("telaProvider.testeEnviado",
                    "telaProvider.verifiqueResultado",
                    "info"
                )
            }
        );
    }

	//#region [ Campanhas ]

    obterCampanhasConfiguradas() {
        this.providerService.obterCampanhasConfiguradasProvedor(this.provedor.providerId, 0)
            .subscribe(campanhas => {
                this.listaCampanha = campanhas;
            });
    }

	customSearchFn(term: string, item: any) {
		term = term.toLocaleLowerCase();
		return item.value.toString().toLocaleLowerCase().indexOf(term) > -1 || item.label.toString().toLocaleLowerCase().indexOf(term) > -1;
    }
    
	onCampanhaSelecionada() {
		if (this.campanhaSelecionada != null && this.layoutPorCampanha) {
			this.providerService
				.obterListaCampo(this.provedor.providerId, this.campanhaSelecionada)
				.subscribe(campos => {
					this.camposEnvioForm = campos.map((c: any) => ({ campo: c, valor: null }));
				});
		}
	}    

	//#endregion

    //#region [ Tratamento Campo Raw ]

    transformarCamposJsonEmArray(valorJson: any, chaves: Array<string> = []) {
        let chavesAnteriores: Array<string> = [];

        for (var key in valorJson) {
            if (valorJson.hasOwnProperty(key)) {
                chaves = chavesAnteriores.length == 0 ? chaves : [...chavesAnteriores];
                chavesAnteriores = [...chaves];

                if (typeof valorJson[key] == "object") {
                    chaves.push(key);
                    this.transformarCamposJsonEmArray(valorJson[key], chaves);
                } else {
                    this.campo.caminho = chaves.filter(c => c != "0").join(" > ");
                    chaves.push(key);
                    this.obterValorETipoExportacaoChave(valorJson, key);
                    this.campo.chaves = [...chaves];
                    this.camposJson.push({ ...this.campo });
                    this.campo = new CampoJson();
                }

                chaves = new Array<string>();
            }
        }

        chavesAnteriores = new Array<string>();
    }

    obterValorETipoExportacaoChave(valorJson: any, key: string) {
        let value: string = valorJson[key];
        this.campo.chave = key;
        this.campo.tipo = value.match(/(?<=\)\&T\=).+?(?=$)/g)[0]; //Retorna o tipo do campo no json conforme definido na criação do provedor
    }

    atualizarCampoEnvioRaw() {
        this.campoEnvioRaw = typeof (this.campoEnvioRaw) == 'object' ? this.campoEnvioRaw : JSON.parse(this.campoEnvioRaw);

        this.camposJson.forEach((c: CampoJson) => {
            var valorTipado = this.tratarValor(c);
            this.atualizarJson(this.campoEnvioRaw, valorTipado, c.chaves);
        });

        this.campoEnvioRaw = JSON.stringify(this.campoEnvioRaw);
    }

    atualizarJson(obj, value, propPath) {
        const [head, ...rest] = propPath;

        !rest.length ? obj[head] = value : this.atualizarJson(obj[head], value, rest);

        this.campoEnvioRaw = obj;
    }

    tratarValor(campo: CampoJson) {
        if (campo.tipo == "NUMBER" || campo.tipo == "BIGINT")
            return campo.valor == null || campo.valor == '' ? null : Number(campo.valor);

        if (campo.tipo == "BOOLEAN")
            return campo.valor == null || campo.valor == '' ? null : campo.valor;

        return campo.valor;
    };

    //#endregion   
}