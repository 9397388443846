export function addCustomValidateChildrenArray(validate: any) {
    validate.validators.validChildrenArray = (value: Array<any>, options: any, key: any, attributes: any) => {

        let validationRules: any = options.childrenRules;

        let appyValidade = (item: any, validationRules: any) => {
            let error: any = validate(item, validationRules);
            return (!error) ? null : error;
        };

        const arrayItemErrors = value.reduce((errors, item, index) => {
            let error = appyValidade(item, validationRules);

            if (error)
                errors[index] = error;

            return errors;
        }, {})

        return (Object.keys(arrayItemErrors).length === 0) ? null : { 'errors': arrayItemErrors };
    };
}