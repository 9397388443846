import { Pipe, PipeTransform } from "@angular/core";
import { ArquivoStatus } from "../../common/model/arquivo-status";

/* TODO: A descrição do status vai vir do back-end nao precisando mais desse pipe */

@Pipe({
  name: "statusArquivo"
})
export class StatusArquivoPipe implements PipeTransform {
  transform(value: ArquivoStatus, args?: any): any {
    switch (value) {
      case ArquivoStatus.AgImportacao:
        return "Ag. Importação";
      case ArquivoStatus.Processando:
        return "Processando";
      case ArquivoStatus.AgValidacao:
        return "Aguardando Validação";
      case ArquivoStatus.Concluido:
        return "Concluído";
      case ArquivoStatus.Erro:
        return "Erro";
      case ArquivoStatus.AgProcessamento:
        return "Aguardando Processamento";
    }
    return "";
  }
}
