import * as validateJS from "validate.js";
import * as dayjs from 'dayjs';
import { EstrategiaRefactor } from "../../common/model/estrategiaRefactor";
import { isNullOrEmpty } from "../../common/utils";

export class AgendamentoModel {

    tipoEnvio: number;

    execucaoUnica: boolean;     // OK
    recorrente: boolean;        // OK

    frequencia: string;         // OK
    intervalo: number | null;   // OK
    diaSemana: string | null;   // OK

    dataInicio: any;            // OK
    horaInicio: any;            // OK
    dataTermino: any;           // OK
    horaTermino: any;           // OK

    intervaloHoraInicio: any;   // OK
    intervaloHoraTermino: any;  // OK

    agendamentoMinimoMinutos: number = 1;

    //#region [ GET/SET - ExecucaoRecorrente ]  

    public get execucaoRecorrente(): boolean {
        return !this.execucaoUnica;
    }

    public set execucaoRecorrente(valor: boolean) {
        this.execucaoUnica = !valor;
    }

    //#endregion

    //#region [ GET/SET - ComDataFim ]  

    _comDataFim: boolean;

    public get comDataFim(): boolean {
        return this._comDataFim != undefined ? this._comDataFim : this.dataTermino != undefined;
    }

    public set comDataFim(checked: boolean) {
        if (!checked) {
            this.dataTermino = undefined;
            this.horaTermino = undefined;
        }
        this._comDataFim = checked;

        let dataAtual = new Date();
        dataAtual.setDate(dataAtual.getDate() + 1);
        dataAtual.setHours(23, 59, 59, 999);
        this.dataTermino = dataAtual;
        this.horaTermino = '';//dayjs(dataAtual).format("HH:mm");
    }

    //#endregion

    constructor() {
        this.tipoEnvio = 1;
        this.execucaoUnica = true;
        this.recorrente = false;
        this.validacaoCustomizadas();
    }


    static convertEstrategiaToAgendamento(estrategia: EstrategiaRefactor) {
        let data = new AgendamentoModel();

        // Imediata	    { "execucaoUnica": true, "recorrente": false }
        // Agendada	    { "execucaoUnica": true, "recorrente": true }
        // Recorrente 	{ "execucaoUnica": false, "recorrente": true }

        if (estrategia.execucaoUnica && estrategia.recorrente === false)
            data.tipoEnvio = 1;

        if (estrategia.execucaoUnica && estrategia.recorrente)
            data.tipoEnvio = 2;

        if (estrategia.execucaoUnica === false && estrategia.recorrente)
            data.tipoEnvio = 3;

        data.execucaoUnica = estrategia.execucaoUnica;
        data.recorrente = estrategia.recorrente;

        data.frequencia = estrategia.frequencia;
        data.intervalo = estrategia.intervalo;
        data.diaSemana = estrategia.diaSemana;

        data.dataInicio = estrategia.dataInicio;
        data.horaInicio = estrategia.horaInicio;
        data.dataTermino = estrategia.dataTermino;
        data.horaTermino = estrategia.horaTermino;

        data.intervaloHoraInicio = estrategia.intervaloHoraInicio;
        data.intervaloHoraTermino = estrategia.intervaloHoraTermino;

        data._comDataFim = estrategia._comDataFim;

        return data;
    }

    // #region [ Validação ]

    validar() {

        let regras: any = {};

        if (this.recorrente) {

            if (!this.execucaoRecorrente) {
                regras.dataInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
                regras.horaInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
            } else {
                regras.frequencia = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
                regras.dataInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };

                if (this.frequencia == '1' || this.frequencia == '2') {
                    let greaterThanOrEqualTo = ((this.frequencia == '1') ? this.agendamentoMinimoMinutos : 1);

                    regras.intervalo = {
                        'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                        'numericality': { 'onlyInteger': true, 'greaterThanOrEqualTo': greaterThanOrEqualTo, 'message': '^menorque' }
                    };

                    if (!isNullOrEmpty(this.intervaloHoraInicio) || !isNullOrEmpty(this.intervaloHoraTermino)) {
                        regras.intervaloHoraInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
                        regras.intervaloHoraTermino = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
                    }

                } else {
                    regras.horaInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
                }

                if (this.comDataFim) {
                    regras.dataTermino = {
                        'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                        'dataMaiorQue': { 'campo': 'dataInicio', 'message': '^dataTerminoInvalida' }
                    };
                }
            }
        }

        return validateJS(this, regras);
    }

    private validacaoCustomizadas() {
        validateJS.validators.dataMenorQue = function (value, options, key, attributes) {
            if (value >= attributes[options.campo]) return "dataMaior";
        };

        validateJS.validators.dataMaiorQue = function (value, options, key, attributes) {
            if (value <= attributes[options.campo]) return "dataMenor";
        };
    }

    //#endregion

}