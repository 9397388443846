import { isNullOrZero } from "../utils";

export class ConfiguracaoConversaoProvedor {

    public configuracaoConversaoProvedorId: number;
    public configuracaoCampoProviderId: number;
    public valor: string;
    public valor2: string;
    public ehPossivelValor: boolean;
    public conversaoProvedorOperadorId: ConversaoProvedorOperador;
    public dataCadastro: Date;
    public usuarioCadastro: number;
    public dataAlteracao: Date;
    public usuarioAlteracao: number;
    public ativo: boolean;

    constructor(configuracaoCampoProviderId?: number, valor?: string, conversaoProvedorOperadorId?: ConversaoProvedorOperador, ativo?: boolean) {
        this.configuracaoCampoProviderId = configuracaoCampoProviderId;
        this.valor = valor;
        this.conversaoProvedorOperadorId = conversaoProvedorOperadorId;
        this.ativo = !ativo ? false : true;
    }

    public get operador(): string {
        switch (this.conversaoProvedorOperadorId) {
            case ConversaoProvedorOperador.Igual: return '1';
            case ConversaoProvedorOperador.Entre: return '2';
            case ConversaoProvedorOperador.Maior: return '3';
            case ConversaoProvedorOperador.Menor: return '4';
        }
    }

    public set operador(valor: string) {
        switch (valor) {
            case '1': this.conversaoProvedorOperadorId = ConversaoProvedorOperador.Igual; break;
            case '2': this.conversaoProvedorOperadorId = ConversaoProvedorOperador.Entre; break;
            case '3': this.conversaoProvedorOperadorId = ConversaoProvedorOperador.Maior; break;
            case '4': this.conversaoProvedorOperadorId = ConversaoProvedorOperador.Menor; break;
        }
    }

    public get exibirValor2(): boolean {
        return (this.conversaoProvedorOperadorId == ConversaoProvedorOperador.Entre);
    }

    public get isEdit(): boolean {
        return (!isNullOrZero(this.configuracaoConversaoProvedorId));
    }

    static fromRaw(raw: any): ConfiguracaoConversaoProvedor {
        return Object.assign(new ConfiguracaoConversaoProvedor(), raw);
    }
}

export enum ConversaoProvedorOperador {
    Igual = 1,
    Entre = 2,
    Maior = 3,
    Menor = 4
}
