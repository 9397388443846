
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Estrategia } from "../model/estrategia";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { convertToQueryString } from "./query-string";
import { EstrategiaCard } from "../../dash/model/estrategia-card";
import { Http, ResponseContentType, Headers } from "@angular/http";
import { AutenticacaoService } from "./autenticacao.service";
import { downloadFile, downloadFileBlob } from "./download-file";
import { SituacaoExecucao } from '../model/situacao-execucao';
import { EstrategiaRefactor } from '../model/estrategiaRefactor';
import { MigracaoEstrategiaModel, MigracaoEstrategiaQuantidadesModel } from '../../estrategia/modais/migracao-estrategia/migracao-estrategia.model';
import { IndicadoresEstrategia } from '../model/indicadores-estrategia';
import { ConfigProvider } from '../../../common/providers/config.provider';
import { StorageProxy } from "./storage-proxy.service";
import { CustomAlertService } from './custom-alert.service';

@Injectable()
export class EstrategiaService {

	private api: string;
	private urlApiCompleta: string
	
	constructor(
		private _httpClient: HttpClient,
		private http: Http,
		private configProvider: ConfigProvider,
		private customAlertService: CustomAlertService,
		private authService: AutenticacaoService
	) { 
		
		this.api = '/api/estrategias';
		let appConfig = this.configProvider.getConfig();
		let language: string = (StorageProxy.getString('linguagem') === "pt") ? "pt-BR" : StorageProxy.getString('linguagem');
		this.urlApiCompleta = `${appConfig.serverUrl}/${language}${this.api}`;
	}

	obterEstrategias(filtro?:any) {
		var queryString = convertToQueryString(filtro);
		return this._httpClient.get<Array<Estrategia>>(
			`${this.api}${queryString}`
		);
	}


	obterEstrategiasLookup(filtro?:any) {
		var queryString = convertToQueryString(filtro);
		return this._httpClient.get<Array<Estrategia>>(
			`${this.api}/obter-estrategias-lookup${queryString}`
		);
	}

	downloadEstrategias(filtro?:any) {
		var queryString = convertToQueryString(filtro);
		this._httpClient
			.get(`${this.urlApiCompleta}/download${queryString}`, { responseType: 'blob' })
			.subscribe(
				(res) => { downloadFileBlob(res, `ListaEstrategias.csv`) },
				() => (this.customAlertService.show("telaEstrategia.download", "telaEstrategia.expurgoArquivo", "info")));
    }

	obterEstrategiasPaginadas(filtro?) {
		var queryString = convertToQueryString(filtro);
		return this._httpClient.get<any>(
			`${this.api}${queryString}`
		);
	}

	ObterIndicadoresEstrategia(filtro?) {
		var queryString = convertToQueryString(filtro);
		return this._httpClient.get<Array<IndicadoresEstrategia>>(
			`${this.api}/indicadoresEstrategia${queryString}`
		);
	}

	obterHorariosReservados(listaId: number, listaEstrategiaId: number) {
		return this._httpClient.get<Array<string>>(
			`${this.api}/horarios-ocupados/${listaId}/${listaEstrategiaId}`
		);
	}

	obterCard(listaId, carteiraId) {
		return this._httpClient.get<EstrategiaCard>(
			`/api/dashboard/card-estrategia?listaId=${listaId}&carteiraid=${carteiraId}`
		);
	}

	excluir(id: any) {
		return this._httpClient.delete(
			`${this.api}/${id}`
		);
	}

	ativar(id: any) {
		return this._httpClient.patch<Estrategia>(
			`${this.api}/${id}/ativar`,
			{}
		);
	}

	obterEstrategiaPorId(id) {
		return this._httpClient
			.get<Estrategia>(`${this.api}/${id}`).pipe(
				map(Estrategia.fromRaw));
	}

	obterHistoricoEstrategia(id, filtro?) {
		return this._httpClient.get<Array<any>>(
			`${this.api}/${id}/historico${convertToQueryString(filtro)}`
		);
	}

	criarEstrategia(estrategia: Estrategia) {
		return this._httpClient.post<Estrategia>(`${this.api}`, estrategia);
	}

	atualizarEstrategia(estrategia: Estrategia) {
		return this._httpClient.put<Estrategia>(`${this.api}/${estrategia.listaEstrategiaId}`, estrategia);
	}

	criarEstrategiaRefactor(estrategia: EstrategiaRefactor) {
		return this._httpClient.post<EstrategiaRefactor>(`${this.api}/nova`, estrategia);
	}

	atualizarEstrategiaRefactor(estrategia: EstrategiaRefactor) {
		return this._httpClient.put<EstrategiaRefactor>(`${this.api}/nova/${estrategia.listaEstrategiaId}`, estrategia);
	}

	duplicarEstrategia(id) {
		return this._httpClient.post<Estrategia>(
			`${this.api}/${id}/duplicar`,
			null
		).pipe(map(Estrategia.fromRaw));
	}


	ativarEnvioImediato(id) {
		return this._httpClient.patch<Estrategia>(
			`${this.api}/${id}/ativar-envio-imediato`,
			{}
		);
	}

	ativarPendenteExecucao(id) {
		return this._httpClient.patch<Estrategia>(
			`${this.api}/${id}/pendente-execucao`,
			{}
		);
	}

	forcarExecucao(id) {
		return this._httpClient.patch<Estrategia>(
			`${this.api}/${id}/forcar-execucao`,
			{}
		);
	}

	atualizarSituacaoExecucao(id: number, situacao: SituacaoExecucao) {
		return this._httpClient.patch<Estrategia>(`${this.api}/${id}/${situacao}/atualizar-situacao-execucao`, {});
	}

	baixarHistorico(estrategiaId, historicoId, estrategiaNome) {
		this.http
			.get(
				`${this.urlApiCompleta}/${estrategiaId}/historico/${historicoId}`,
				{
					responseType: ResponseContentType.Blob,
					headers: new Headers({
						Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`
					})
				}
			)
			.subscribe(res => {
				let nomeArquivo = `${estrategiaNome.replace(/[.*+\-?^${}()|[\]\\]/g, '').replace(/\s\s+/g, ' ').replace(/\s/g, '_')}.txt`;
				downloadFile(res, nomeArquivo)
			});
	}

	baixarUltimoHistorico(estrategiaId, tarefaId) {
		return this.http
			.get(
				`${this.urlApiCompleta}/${estrategiaId}/ultimoArquivoHistorico/${tarefaId}`,
				{
					responseType: ResponseContentType.Blob,
					headers: new Headers({
						Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`
					})
				}
			);
	}

	obterExecucoesTrackingEstrategias(filtro?) {
		var queryString = convertToQueryString(filtro);
		return this._httpClient.get<Array<any>>(
			`${this.api}/execucoestrackingestrategias${queryString}`
		);
	}

	verificarHabilitaControleRetorno() {
		return this._httpClient.get<boolean>(
			`${this.api}/verificarHabilitaControleRetorno`
		);
	}

	obterEstrategiaDetalheCampanha(id) {
		return this._httpClient
			.get<Estrategia>(`${this.api}/${id}/detalheCampanha`).pipe(
				map(Estrategia.fromRaw));
	}

	//#region [ Migração Estratégias ]

	migracaoObterQuantidadeEstrategias(carteiraId: number) {
		return this
			._httpClient
			.get<MigracaoEstrategiaQuantidadesModel>(`${this.api}/migracao-obter-quantidades?carteiraId=${carteiraId}`)
			.pipe(map(MigracaoEstrategiaQuantidadesModel.fromRaw));
	}

	migracaoObterEstrategias(carteiraId: number) {
		// const data = of<Array<MigracaoEstrategiaModel>>(
		// 	[
		// 		{
		// 			"estrategiaId": 406,
		// 			"estrategiaNome": 'Teste de migração - FAVOR NÃO ALTERAR',
		// 			"estrategiaFiltro": '{"size":10,"_source":{"includes":[]},"query":{"bool":{"must":[{"range":{"inteiro":{"gte":"2"}}}],"must_not":[]}}}',
		// 			"estrategiaFiltroRegras": '{}',
		// 			"listaId": 147,
		// 			"listaAlias": "lista_147",
		// 			"listaNome": "Tipos 01",
		// 			"templateId": 247,
		// 			"camposMapeados": [
		// 				{ "listaTemplateItemId": 2416, "nome": "Data", "alias": "Data", "tipoDado": "Data", "listaCampoTipoId": 10, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2431, "nome": "Decimal", "alias": "Decimal", "tipoDado": "Decimal", "listaCampoTipoId": 9, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2430, "nome": "Inteiro", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": true, "listaNested": false },
		// 				{ "listaTemplateItemId": 2429, "nome": "Lista", "alias": "Lista", "tipoDado": "Lista", "listaCampoTipoId": 11, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2428, "nome": "Long", "alias": "Long", "tipoDado": "Long", "listaCampoTipoId": 17, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2427, "nome": "Boolean", "alias": "Sim/Não", "tipoDado": "Boolean", "listaCampoTipoId": 12, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2426, "nome": "Texto", "alias": "Texto", "tipoDado": "Texto", "listaCampoTipoId": 8, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2425, "nome": "_tracking.providerId", "alias": "Nested", "tipoDado": "Nested", "listaCampoTipoId": 18, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 8, "identificador": false, "tipoDadoNested": "Inteiro", "listaNested": true },
		// 				{ "listaTemplateItemId": 2424, "nome": "_tracking.listaEstrategiaId", "alias": "Nested", "tipoDado": "Nested", "listaCampoTipoId": 18, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 8, "identificador": false, "tipoDadoNested": "Inteiro", "listaNested": true },
		// 				{ "listaTemplateItemId": 2423, "nome": "_tracking.dataCadastro", "alias": "Nested", "tipoDado": "Nested", "listaCampoTipoId": 18, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 8, "identificador": false, "tipoDadoNested": "Data", "listaNested": false },
		// 				{ "listaTemplateItemId": 2422, "nome": "_tracking.trackingId", "alias": "Nested", "tipoDado": "Nested", "listaCampoTipoId": 18, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 8, "identificador": false, "tipoDadoNested": "Long", "listaNested": false },
		// 				{ "listaTemplateItemId": 2421, "nome": "_dataCadastroMiner", "alias": "Data", "tipoDado": "Data", "listaCampoTipoId": 10, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2420, "nome": "_dataAtualizacaoMiner", "alias": "Data", "tipoDado": "Data", "listaCampoTipoId": 10, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2419, "nome": "_ultimoEnvioProviderId", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2418, "nome": "_ultimoEnvioListaEstrategiaId", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2417, "nome": "_ultimoEnvioData", "alias": "Data", "tipoDado": "Data", "listaCampoTipoId": 10, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2432, "nome": "_ultimoEnvioTrackingId", "alias": "Long", "tipoDado": "Long", "listaCampoTipoId": 17, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2433, "nome": "_quantidadeVezesExportada", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2434, "configuracaoCampoProviderId": 131, "nome": "purl_id", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2435, "configuracaoCampoProviderId": 132, "nome": "purl_telefone", "alias": "Texto", "tipoDado": "Texto", "listaCampoTipoId": 8, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2436, "configuracaoCampoProviderId": 133, "nome": "purl_score", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2437, "configuracaoCampoProviderId": 118, "nome": "ret_Inteiro", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2438, "configuracaoCampoProviderId": 120, "nome": "ret_Valor", "alias": "Decimal", "tipoDado": "Decimal", "listaCampoTipoId": 9, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2439, "configuracaoCampoProviderId": 121, "nome": "ret_Status", "alias": "Texto", "tipoDado": "Texto", "listaCampoTipoId": 8, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2622, "nome": "_statusRegistroMiner", "alias": "Lista", "tipoDado": "Lista", "listaCampoTipoId": 11, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false }
		// 			],
		// 			"status": 1
		// 		},
		// 		{
		// 			"estrategiaId": 415,
		// 			"estrategiaNome": 'Estratégia velha 01',
		// 			"estrategiaFiltro": '{"size":10,"_source":{"includes":[]},"query":{"bool":{"must":[{"match":{"inteiro":{"query":"1"}}}],"must_not":[{"exists":{"field":"_integrado"}}]}}}',
		// 			"estrategiaFiltroRegras": '{}',
		// 			"listaId": 147,
		// 			"listaAlias": "lista_147",
		// 			"listaNome": "Tipos 01",
		// 			"templateId": 247,
		// 			"camposMapeados": [
		// 				{ "listaTemplateItemId": 2416, "nome": "Data", "alias": "Data", "tipoDado": "Data", "listaCampoTipoId": 10, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2431, "nome": "Decimal", "alias": "Decimal", "tipoDado": "Decimal", "listaCampoTipoId": 9, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2430, "nome": "Inteiro", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": true, "listaNested": false },
		// 				{ "listaTemplateItemId": 2429, "nome": "Lista", "alias": "Lista", "tipoDado": "Lista", "listaCampoTipoId": 11, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2428, "nome": "Long", "alias": "Long", "tipoDado": "Long", "listaCampoTipoId": 17, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2427, "nome": "Boolean", "alias": "Sim/Não", "tipoDado": "Boolean", "listaCampoTipoId": 12, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2426, "nome": "Texto", "alias": "Texto", "tipoDado": "Texto", "listaCampoTipoId": 8, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 1, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2425, "nome": "_tracking.providerId", "alias": "Nested", "tipoDado": "Nested", "listaCampoTipoId": 18, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 8, "identificador": false, "tipoDadoNested": "Inteiro", "listaNested": true },
		// 				{ "listaTemplateItemId": 2424, "nome": "_tracking.listaEstrategiaId", "alias": "Nested", "tipoDado": "Nested", "listaCampoTipoId": 18, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 8, "identificador": false, "tipoDadoNested": "Inteiro", "listaNested": true },
		// 				{ "listaTemplateItemId": 2423, "nome": "_tracking.dataCadastro", "alias": "Nested", "tipoDado": "Nested", "listaCampoTipoId": 18, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 8, "identificador": false, "tipoDadoNested": "Data", "listaNested": false },
		// 				{ "listaTemplateItemId": 2422, "nome": "_tracking.trackingId", "alias": "Nested", "tipoDado": "Nested", "listaCampoTipoId": 18, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 8, "identificador": false, "tipoDadoNested": "Long", "listaNested": false },
		// 				{ "listaTemplateItemId": 2421, "nome": "_dataCadastroMiner", "alias": "Data", "tipoDado": "Data", "listaCampoTipoId": 10, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2420, "nome": "_dataAtualizacaoMiner", "alias": "Data", "tipoDado": "Data", "listaCampoTipoId": 10, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2419, "nome": "_ultimoEnvioProviderId", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2418, "nome": "_ultimoEnvioListaEstrategiaId", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2417, "nome": "_ultimoEnvioData", "alias": "Data", "tipoDado": "Data", "listaCampoTipoId": 10, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2432, "nome": "_ultimoEnvioTrackingId", "alias": "Long", "tipoDado": "Long", "listaCampoTipoId": 17, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2433, "nome": "_quantidadeVezesExportada", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2434, "configuracaoCampoProviderId": 131, "nome": "purl_id", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2435, "configuracaoCampoProviderId": 132, "nome": "purl_telefone", "alias": "Texto", "tipoDado": "Texto", "listaCampoTipoId": 8, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2436, "configuracaoCampoProviderId": 133, "nome": "purl_score", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2437, "configuracaoCampoProviderId": 118, "nome": "ret_Inteiro", "alias": "Inteiro", "tipoDado": "Inteiro", "listaCampoTipoId": 16, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2438, "configuracaoCampoProviderId": 120, "nome": "ret_Valor", "alias": "Decimal", "tipoDado": "Decimal", "listaCampoTipoId": 9, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2439, "configuracaoCampoProviderId": 121, "nome": "ret_Status", "alias": "Texto", "tipoDado": "Texto", "listaCampoTipoId": 8, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 6, "identificador": false, "listaNested": false },
		// 				{ "listaTemplateItemId": 2622, "nome": "_statusRegistroMiner", "alias": "Lista", "tipoDado": "Lista", "listaCampoTipoId": 11, "templateId": 247, "templateNome": "Tipos (NÃO ALTERAR)", "itemTipo": 5, "identificador": false, "listaNested": false }
		// 			],
		// 			"status": 1
		// 		}
		// 	]
		// );

		// return data;



		return this._httpClient.get<Array<MigracaoEstrategiaModel>>(`${this.api}/migracao-obter-estrategias?carteiraId=${carteiraId}`);
	}

	migracaoSalvarEstrategias(estrategia: MigracaoEstrategiaModel) {
		let headers = new HttpHeaders().set('blockLoading', 'true');
		return this._httpClient.put<MigracaoEstrategiaModel>(`${this.api}/migracao-salvar-estrategia/${estrategia.estrategiaId}`, estrategia, { headers: headers });
	}

	//#endregion

}