import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { PlanoTarifaProvedor } from "../model/plano-tarifa-provedor";
import { Observable } from "rxjs";

@Injectable()
export class PLanoTarifaProvedorService {
    private api: string;

	constructor(private httpClient: HttpClient) { 
		this.api = '/api/planotarifaprovedor';
	}

    public obterPlanos(provedorId: number): Observable<Array<PlanoTarifaProvedor>> {
        return this.httpClient.get<Array<PlanoTarifaProvedor>>(`${this.api}/${provedorId}`);
    }

    public salvar(planos: Array<PlanoTarifaProvedor>) {
        return this.httpClient.post<number>(`${this.api }/`, planos);
    }
}
