import { ArquivoStatus } from "./arquivo-status";
import { Usuario } from "./usuario";

export class ListaArquivo {
  listaArquivoId: number;
  listaFonteDadoId: number;
  usuarioId: number;
  diretorio: string;
  nome: string;
  tipo: number;
  quantidadeRegistro: number;
  quantidadeInvalo: number;
  descricao: string;
  dataProcessamento: Date;
  dataCadastro: Date;
  listaArquivoStatusId: ArquivoStatus;
  usuario: Usuario;
}
