import { Component, Inject, OnInit } from "@angular/core";
import { Router, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
import { LayoutService } from "./layout/layout.service";
import { APPCONFIG } from './common/providers/config.provider';
import { IAppConfig } from "./common/model/app-config";

// 3rd
import "styles/material2-theme.scss";
import "styles/bootstrap.scss";
// custom
import "styles/layout.scss";
import "styles/theme.scss";
import "styles/ui.scss";
import "styles/app.scss";
import { AppRoutes } from "./app-routing.module";
import { filter, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { environment } from "../environments/environment";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  providers: [LayoutService]
})
export class AppComponent implements OnInit {
  public AppConfig: any;

  constructor(private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    this.AppConfig = environment;

    // Scroll to top on route change
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationStart) {
        if (evt.url.startsWith("/app")) 
          return;
        var queryParamIndex = evt.url.indexOf("?") - 1;
        if (queryParamIndex == -2) queryParamIndex = evt.url.length;
        var rotaValida = AppRoutes.find(
          route => route.path.indexOf(evt.url.substr(1, queryParamIndex)) != -1
        );
        if (rotaValida) return;
        this.router.navigate(["/app/template"]);
      }

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this.dialog.closeAll())
    ).subscribe();
  }
}
