import { QueryStringElasticsearchFilter } from "./query_string";
import { TermsElasticsearchFilter } from "./terms";
import { WildcardElasticsearchFilter } from "./wildcard";
import { BaseElasticsearchFilter } from "./base";
import { NotTermsElasticsearchFilter } from "./not_terms";
import { TermElasticsearchFilter } from "./term";
import { FieldQuery } from "./field_query";
import { NotExistsQuery } from "./not";
import { EmptyQuery } from "./empty";
import { NestedElasticsearchFilter } from "./nested-filter";

var filterPossibleType = {
	query_string: QueryStringElasticsearchFilter,
	terms: TermsElasticsearchFilter,
	wildcard: WildcardElasticsearchFilter,
	term: TermElasticsearchFilter,
	not_terms: NotTermsElasticsearchFilter,
	exists: FieldQuery,
	bool: NotExistsQuery,
	script: EmptyQuery,
	nested: NestedElasticsearchFilter
};

export function mapToElasticSearchFilter(notInCollection, filter): BaseElasticsearchFilter {

	const type = Object.keys(filter)[0];
	if (type === "terms" && notInCollection) {
		const fieldName = Object.keys(filter[type])[0];
		const field = notInCollection.find(item => item.field == fieldName || `${item.field}.keyword` == fieldName);
		if (field == null) {
			const instanceOfFilterType = <BaseElasticsearchFilter>(
				new (getFilterTypeByName(type))()
			);
			return instanceOfFilterType.mapFromElasticFilter(filter);
		}


		let extra = filter[type][fieldName];
		let keys = Object.keys(field.collection).filter(key => extra.indexOf(key) === -1);

		const notTerms = new NotTermsElasticsearchFilter(fieldName, type, keys, extra);
		return notTerms;
	}

	const instanceOfFilterType = <BaseElasticsearchFilter>(new (getFilterTypeByName(type))());
	return instanceOfFilterType.mapFromElasticFilter(filter);
}

export function mapToElasticSearchFilterRules(filter: any, nestedList: boolean = false, notInCollection: Array<any> = null): BaseElasticsearchFilter {

	if (filter.queryType === "not_terms" && notInCollection && notInCollection.some((f: any) => f.field == filter.property || `${f.field}.keyword` == filter.property)) {
		//const fieldName = Object.keys(filter[filter.queryType])[0];
		const field = notInCollection.find(item => item.field == filter.property || `${item.field}.keyword` == filter.property);

		if (field == null) {
			const instanceOfFilterType = <BaseElasticsearchFilter>(new (getFilterTypeByName(filter.queryType))());
			if (filter.queryType == "nested") {
				instanceOfFilterType.keyword = filter.keyword;
				instanceOfFilterType.negation = filter.negation;
				instanceOfFilterType.nestedContent = filter.nestedContent;
				instanceOfFilterType.nestedList = filter.nestedList;
				instanceOfFilterType.nestedType = filter.nestedType;
				nestedList = filter.nestedList;
			}
			let base = instanceOfFilterType.mapRulesFromElasticFilter(filter, nestedList);
			base.negation = filter.negation;
			return base;
		}

		let extra = filter.extra;
		let keys = Object.keys(field.collection).filter(key => extra.indexOf(key) === -1);

		const notTerms = new NotTermsElasticsearchFilter(filter.property, filter.queryType, keys, extra);
		return notTerms;
	}


	filter.queryType = (filter.queryType == "terms" && (filter.not_in != null && filter.not_in.length > 0)) ? "not_terms" : filter.queryType;
	const instanceOfFilterType = <BaseElasticsearchFilter>(new (getFilterTypeByName(filter.queryType))());

	if (filter.queryType == "nested") {
		instanceOfFilterType.keyword = filter.keyword;
		instanceOfFilterType.negation = filter.negation;
		instanceOfFilterType.nestedContent = filter.nestedContent;
		instanceOfFilterType.nestedList = filter.nestedList;
		instanceOfFilterType.nestedType = filter.nestedType;
		nestedList = filter.nestedList;
	}

	let base = instanceOfFilterType.mapRulesFromElasticFilter(filter, nestedList);
	base.negation = filter.negation;
	return base;
}



export function getFilterTypeByName(type) {
	return filterPossibleType[type] || BaseElasticsearchFilter;
}
