import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatSlideToggleChange } from "@angular/material";

import { ProvedorAgendamentoConfiguracao } from "../../common/model/provedor-agendamento-configuracao";
import * as dayjs from 'dayjs';
import { ListaService } from "../../common/service/lista.service";
import { ProviderService } from "../../common/service/provider.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { TranslateService } from "@ngx-translate/core";
import { ParametrosAgendamentoDescricao, AgendamentoDescricao } from "../../common/libs/agendamento-descricao";
import { isNullOrEmpty } from "../../common/utils";

@Component({
	selector: "app-provedor-agendamento",
	templateUrl: "./provedor-agendamento.component.html",
	styleUrls: ["./provedor-agendamento.component.scss"],
	providers: [
		{ useClass: ListaService, provide: ListaService },
		{ useClass: ProviderService, provide: ProviderService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ProvedorAgendamentoComponent {
	provedorAgendamento: ProvedorAgendamentoConfiguracao;
	horaInicioFrequencia: Date;
	descricao: string;
	tipoRecorrencia: string;
	dirty: boolean;
	frequenciaCollection: any = [{ 'frequencia': '1', 'descricao': this.translate.instant("frequenciasEnvio.minuto") }, { 'frequencia': '2', 'descricao': this.translate.instant("frequenciasEnvio.hora") }, { 'frequencia': '3', 'descricao': this.translate.instant("frequenciasEnvio.dia") }, { 'frequencia': '4', 'descricao': this.translate.instant("frequenciasEnvio.semana") }];
	diaSemanaCompleto: any = { 'SUN': 'domingo', 'MON': 'segunda', 'TUE': 'terca', 'WED': 'quarta', 'THU': 'quinta', 'FRI': 'sexta', 'SAT': 'sabado' };
	horarioCollection: Array<any>;

	public get erros() {
		if (!this.dirty) return;
		return this.provedorAgendamento.validar();
	}

	constructor(
		private listaService: ListaService,
		private customAlertService: CustomAlertService,
		private translate: TranslateService,
		public dialogRef: MatDialogRef<ProvedorAgendamentoComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { provedorAgendamentoConfiguracao: ProvedorAgendamentoConfiguracao }
	) {
		this.dirty = false;

		this.provedorAgendamento = Object.assign(new ProvedorAgendamentoConfiguracao(), data.provedorAgendamentoConfiguracao);//data.provedorAgendamentoConfiguracao;

		if (this.provedorAgendamento.horaExecucao) {
			let h = this.provedorAgendamento.horaExecucao.split(":");
			this.provedorAgendamento.horaExecucao = `${h[0]}:${h[1]}`;
		}

		this.gerarDescricao();
	}

	isNew() {
		return isNullOrEmpty(this.provedorAgendamento.provedorAgendamentoConfiguracaoId);
	}

	gravar() {
		this.dirty = true;

		if (this.provedorAgendamento.validar()) {
			this.customAlertService.show("telaProvider.configuracaoAgendamentoRetorno", "telaPadrao.camposInvalidos", "error");
			return;
		}

		this.dialogRef.close(this.provedorAgendamento);
	}

	gerarDescricao() {
		let param: ParametrosAgendamentoDescricao = new ParametrosAgendamentoDescricao();
		param.execucaoUnica = false;
		param.frequencia = this.provedorAgendamento.frequencia;
		param.diaSemana = this.provedorAgendamento.diaSemana;
		param.intervalo = this.provedorAgendamento.intervalo;
		param.dataInicio = this.provedorAgendamento.dataInicio;
		param.horaInicio = this.provedorAgendamento.horaExecucao;
		param.dataTermino = this.provedorAgendamento.dataTermino;
		param.horaTermino = '23:59';
		param.comDataFim = this.provedorAgendamento.comDataFim;

		this.descricao = AgendamentoDescricao(param, 'telaProvider', this.translate);
	}

	frequenciaChange(event: any) {
		if (this.isNew() && this.dirty === undefined) {
			let dataAtual = new Date();
			this.provedorAgendamento.dataInicio = dataAtual;

			if (this.provedorAgendamento.frequencia == '1')
				this.provedorAgendamento.intervalo = 30;

			else if (this.provedorAgendamento.frequencia == '2')
				this.provedorAgendamento.intervalo = 1;
		}

		if (this.provedorAgendamento.frequencia == '3')
			this.provedorAgendamento.diaSemana = null;

		this.gerarDescricao();
	}

	dadosChange(event: any) {
		this.gerarDescricao();
	}

	comDataFimChange(event: MatSlideToggleChange) {
		if (event.checked) {
			let dataAtual = new Date();
			dataAtual.setDate(dataAtual.getDate() + 1);
			dataAtual.setHours(23, 59, 59, 999);
			this.provedorAgendamento.dataTermino = dataAtual;

		} else {
			this.provedorAgendamento.dataTermino = null;
		}

		this.gerarDescricao();
	}

	checkTerminoDesabilitado() {
		return (!this.provedorAgendamento.comDataFim) ? true : false;
	}

	fechar() {
		this.dialogRef.close();
	}
}
