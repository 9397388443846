export interface ElasticsearchFilterStructure {
	property: string;
	queryType: string;
	parameters: any;
}

const elasticFormatObject = {
	'm': 'Minuto(s)',
	'H': 'Hora(s)',
	'd': "Dia(s)",
	'w': "Semana(s)",
	'M': "Mês(es)",
	'y': "Ano(s)"
};

function formatElasticMathDate(value) {
	if (value == "now") return "Data Atual";

	let dateTypeNumberModifier = value.substr(value.length - 1);

	let dateNumberModifier: number = 0;

	if (dateTypeNumberModifier == "H")
		dateNumberModifier = parseInt(value.substring(3, value.length - 1)) + 3;
	else if (dateTypeNumberModifier == "m")
		dateNumberModifier = parseInt(value.substring(3, value.length - 1)) + 180;
	else
		dateNumberModifier = parseInt(value.substring(3, value.length - 1));

	// = (dateTypeNumberModifier == "H") 
	// ? parseInt(value.substring(3, value.length - 1)) + 3
	// : parseInt(value.substring(3, value.length - 1));

	return `Data Atual ${dateNumberModifier} ${elasticFormatObject[dateTypeNumberModifier]}`;
}

//Classe auxiliar para montar os filtros do elasticsearch
export class BaseElasticsearchFilter implements ElasticsearchFilterStructure {
	//baseElasticsearchFilterType: string;
	property: string;
	queryType: string;
	parameters: any;
	extra: any;
	keyword: boolean;
	negation: boolean;
	nestedContent: string;
	nestedType: string;
	nestedList: boolean;

	constructor(property?: string, queryType?: string, parameters?, extra?, keyword: boolean = false, nestedContent: string = null, nestedType: string = null, nestedList: boolean = false, negation: boolean = false) {
		//this.baseElasticsearchFilterType = '';
		this.property = property;
		this.queryType = queryType;
		this.parameters = parameters != undefined ? parameters : {};
		this.extra = extra;
		this.keyword = keyword;
		this.negation = negation;
		this.nestedContent = nestedContent;
		this.nestedType = nestedType;
		this.nestedList = nestedList;

	}

	public get isValid(): boolean {
		return this.property != undefined && this.queryType != undefined;
	}

	protected getQueryType(filter) {
		return Object.keys(filter)[0];
	}

	protected getPropertyName(filter, queryType) {
		return Object.keys(filter[queryType])[0];
	}

	public getFieldName() {
		return this.property.replace(new RegExp('.keyword', 'g'), '');
	}

	protected getParameters(filter, queryType, propertyName) {
		return filter[queryType][propertyName];
	}

	public mapFromElasticFilter(filter) {
		let queryType = this.getQueryType(filter);
		let propertyName = this.getPropertyName(filter, queryType);
		let parameters = this.getParameters(filter, queryType, propertyName);
		return this.getInstance(queryType, propertyName, parameters, this.keyword);
	}

	public mapRulesFromElasticFilter(filter: any, nestedList: boolean = false) {
		return this.getInstance(filter.queryType, filter.property, filter.parameters, filter.extra, filter.keyword, filter.nestedContent, filter.nestedType, nestedList);
	}

	protected getInstance(
		queryType: string,
		propertyName: string,
		parameters: any,
		extra?: any,
		keyword?: boolean,
		nestedContent?: string,
		nestedType?: string,
		nestedList?: boolean
	): BaseElasticsearchFilter {
		return new BaseElasticsearchFilter(propertyName, queryType, parameters, extra, keyword, nestedContent, nestedType, nestedList);
	}

	protected findQueryType(queryTypes: Array<any>, negation: boolean = false) {
		return queryTypes.find(q => q.content == this.queryType && q.negation == negation);
	}

	public friendlyParameters(dataType: string, queryTypes: Array<any>, negation: boolean = false) {
		var queryType = this.findQueryType(queryTypes, negation);

		if (queryType && queryType.regex) return queryType.name + ": " + this.extra;

		let retorno = Object.keys(this.parameters)
			.map(parameter => this.getParameterDescription(queryType, parameter))
			.join(" e ");

		return retorno;
	}

	protected getParameterDescription(queryType, parameter) {
		var alias = queryType.parameters.find(p => p.name == parameter).alias;
		var value = this.parameters[parameter];
		if (value.toString().startsWith("now"))
			value = formatElasticMathDate(value);
		return `${alias} ${value}`;
	}

	protected convertFilter(): any {
		let property = (this.keyword) ? `${this.property}.keyword` : this.property;
		return { [this.queryType]: { [property]: this.parameters } };
	}

	get convertedFilter(): any {
		return this.convertFilter();
	}

	static fromRaw(raw): BaseElasticsearchFilter {
        return Object.assign(new BaseElasticsearchFilter(), raw);
    }
}
