import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { TranslateService } from "@ngx-translate/core";
import { FormTemplateExportacaoComponent } from "../../form-template-exportacao/form-template-exportacao.component";
import { isNull, isNullOrEmpty, isObjectEmpty } from "../../../common/utils";
import { ListaTemplateExportacaoParametrosUrl } from "../../../common/model/lista-template-exportacao-parametros-url";
import { ListaTemplateItem } from "../../../common/model/lista-template-item";

@Component({
    selector: "app-campos-parametros-url-modal",
    templateUrl: "./campos-parametros-url-modal.component.html",
    styleUrls: ["./campos-parametros-url-modal.component.scss", "../../style.css",],
    providers: [{ useClass: CustomAlertService, provide: CustomAlertService }]
})
export class CamposParametrosUrlModalComponent {

    caminhoIntegracao: string = "";
    parametrosUrl: Array<ListaTemplateExportacaoParametrosUrl> = [];
    camposIntegracao: Array<any> = [];

    public get url(): string {
        if (isNullOrEmpty(this.caminhoIntegracao) || isObjectEmpty(this.parametrosUrl))
            return '';

        let url = this.caminhoIntegracao;
        //new RegExp('/{{([^}]+)}}/g');
        this.parametrosUrl.forEach(p => {
            let label: string = '';
            p.listaTemplateItemId.split(',').forEach((f: string) => {
                if (!isNullOrEmpty(f)) {
                    let campo = this.camposIntegracao.find(ci => f == ci.value);
                    label += (isNullOrEmpty(label)) ? campo.label : ` + ${campo.label}`
                }
            });

            if (!isNullOrEmpty(label))
                url = url.replace(`{{${p.parametro}}}`, `<mark>${label}</mark>`);
            else
                url = url.replace(`{{${p.parametro}}}`, `<mark class="no-select">${p.parametro}</mark>`);
        });

        return url;
    }


    constructor(
        private ref: MatDialogRef<FormTemplateExportacaoComponent>,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA)
        public data: { listaTemplateId: number, caminhoIntegracao: string, parametrosUrl: Array<ListaTemplateExportacaoParametrosUrl>, camposIntegracao: Array<ListaTemplateItem> }
    ) {
        if (data.caminhoIntegracao)
            this.caminhoIntegracao = `${data.caminhoIntegracao}`;

        if (!isNull(data.parametrosUrl))
            this.parametrosUrl = data.parametrosUrl;

        let listaParametrosUrl: Array<ListaTemplateExportacaoParametrosUrl> = [];
        let parametros: Array<string> = [];

        this.caminhoIntegracao.match(/{{([^}]+)}}/g).forEach(f => {
            let prm = f.replace('{{', '').replace('}}', '').toLowerCase();
            parametros.push(prm);

            let prmUrl = this.parametrosUrl.find(c => c.parametro == prm);
            let item = (prmUrl) ? ListaTemplateExportacaoParametrosUrl.fromRaw(prmUrl) : new ListaTemplateExportacaoParametrosUrl(null, data.listaTemplateId, prm, '', true);
            listaParametrosUrl.push(item);
        });

        this.parametrosUrl
            .filter((f: ListaTemplateExportacaoParametrosUrl) => (parametros.includes(f.parametro) == false))
            .forEach((prm: ListaTemplateExportacaoParametrosUrl) => {
                let item = ListaTemplateExportacaoParametrosUrl.fromRaw(prm);
                item.ativo = false;
                listaParametrosUrl.push(item);
            });

        this.parametrosUrl = listaParametrosUrl;

        if (data.camposIntegracao)
            this.camposIntegracao = data.camposIntegracao.map((m: ListaTemplateItem) => ({ 'value': m.listaTemplateItemId.toString(), 'label': m.nome }));
    }

    save() {
        
        if (this.parametrosUrl.some(s => isNullOrEmpty(s.listaTemplateItemId) && s.ativo)) {
            this.customAlertService.show("telaTemplate.template", "telaPadrao.camposInvalidos", "error");
            return;
        }


        return this.ref.close(this.parametrosUrl);
    }

    close() {
        this.ref.close();
    }

    listaTemplateItemIdArrayChange(p: ListaTemplateExportacaoParametrosUrl) {
        
        if (p.listaTemplateItemIdArray.length > 0) {
            p.listaTemplateItemId = p.listaTemplateItemIdArray.join(',');
        } else {
            p.listaTemplateItemId = '';
        }
    }
}
