import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';

import { Fluxo, FluxoTipo } from "../../common/model/fluxo";
import { Lista } from "../../common/model/lista";
import { FiltroLista } from '../../common/model/filtro-lista';

import { FluxoService } from "../../common/service/fluxo.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ProviderService } from "../../common/service/provider.service";
import { IndicadorProvedorService } from "../../common/service/indicador-provedor.service";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";
import { Provider } from "../../common/model/provider";
import { IndicadorProvedor } from "../../common/model/indicador-provedor";
import { isNullOrZero } from "../../common/utils";


@Component({
    selector: "app-lista-indicador-provedor",
    templateUrl: "./lista-indicador-provedor.component.html",
    styleUrls: ["./lista-indicador-provedor.component.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: ProviderService, provide: ProviderService },
        { useClass: IndicadorProvedorService, provide: IndicadorProvedorService },
    ]
})
export class ListaIndicadorProvedorComponent implements OnInit {

    travarEscolhaProvedor: boolean = false;
    provedorParametro: number = 0;

    filtro: any = {};
    provedores: Array<Provider>;
    indicadores: Array<IndicadorProvedor> = [];


    @ViewChild(DataView, { static: false }) dataTable: DataView;
    constructor(
        private customAlertService: CustomAlertService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private providerService: ProviderService,
        private indicadorProvedorService: IndicadorProvedorService,
    ) {
        this.getProvedorParametro();
        this.setDataPadraoFiltro();
    }

    //#region [ Carregamento da página ]    

    ngOnInit() {
        this.obterProvedores();
        this.obterIndicadores();
    }

    getProvedorParametro() {
        this.route.params.subscribe(params => {
            if (params.id != undefined) {
                this.provedorParametro = params.id;
                this.travarEscolhaProvedor = true;
            } else {
                this.provedorParametro = 0;
                this.travarEscolhaProvedor = false;
            }
        });
    }

    setDataPadraoFiltro() {
        let dataAtual = new Date();
        this.filtro.dataInicio = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1, 0, 0, 0);
        this.filtro.dataFim = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(), 23, 59, 59);
    }

    //#endregion

    //#region [ Carregamento dos dados ]

    obterProvedores() {
        this.providerService
            .obterListaProvedor({})
            .subscribe(provedores => {
                this.provedores = provedores;

                if (!isNullOrZero(this.provedorParametro))
                    this.filtro.provedorId = this.provedorParametro;
            });
    }

    obterIndicadores() {
        this.indicadorProvedorService
            .obterIndicadores(this.filtro)
            .subscribe(indicadores => {
                this.indicadores = indicadores;
                this.setCurrentPage(0);
            });
    }

    //#endregion

    filtrarIndicadores() {
        this.obterIndicadores();
    }

    setCurrentPage(n: number) {
        if (this.dataTable)
            this.dataTable.first = n;
    }

    limpar() {
        this.filtro = {};
    }
}