import { BaseElasticsearchFilter } from "./base";

export class QueryStringElasticsearchFilter extends BaseElasticsearchFilter {
  protected getInstance(
    queryType: string,
    propertyName: string,
    parameters: any,
    extra?: any,
    keyword?: boolean
  ): BaseElasticsearchFilter {
    return new QueryStringElasticsearchFilter(propertyName, queryType, parameters, extra, keyword);
  }

  public mapFromElasticFilter(filter) {
    let queryType = Object.keys(filter)[0];
    return this.getInstance(
      filter[queryType].default_field,
      queryType,
      filter[queryType]
    );
  }

  public friendlyParameters(dataType: string, queryTypes: Array<any>, negation: boolean = false) {
    if (this.parameters.default_field)
      return `Contém ${this.parameters.query.replace(/\*/g, "")}`;
    return super.friendlyParameters(dataType, queryTypes, negation);
  }

  public getFieldName() {
    if (!this.parameters.default_field) return super.getFieldName();
    return this.parameters.default_field;
  }

  convertFilter(): any {
    return {
      [this.queryType]: {
        default_field: this.property,
        query:
          this.parameters.query.indexOf("*") != -1
            ? this.parameters.query
            : `*${this.parameters.query}*`
      }
    };
  }
  static fromRaw(raw): QueryStringElasticsearchFilter {
    return Object.assign(new QueryStringElasticsearchFilter(), raw);
  }
}
