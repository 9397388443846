import { Provider } from "./provider";

export class ConfiguracaoListaProvedor {
  public configuracaoListaProvedorId: number;
  public listaId: number;
  public providerId: number;
  public ativo: boolean;
  public habilitaEnvio: boolean;
  public habilitaRetorno: boolean;
  public provider: Provider;
  public configuracaoCampoProviderId: number;
  public listaTemplateItemId: number;
  public configuracaoListaProvedorItem: Array<ConfiguracaoListaProvedorItem>;

  constructor(listaId?: number, providerId?: number, habilitaEnvio?: boolean, habilitaRetorno?: boolean, ativo?: boolean) {
    this.listaId = listaId;
    this.providerId = providerId;
    this.habilitaRetorno = habilitaRetorno;
    this.habilitaEnvio = habilitaEnvio;
    this.ativo = !ativo ? false : true;
    this.configuracaoListaProvedorItem = this.configuracaoListaProvedorItem == null ? new Array<ConfiguracaoListaProvedorItem>() : this.configuracaoListaProvedorItem;
  }
}

export class ConfiguracaoListaProvedorItem {
  public configuracaoListaProvedorItemId: number;
  public configuracaoListaProvedorId: number;
  public configuracaoCampoProviderId: number;
  public listaTemplateItemId: number;
  public ativo: boolean;

  constructor(configuracaoCampoProviderId: number, listaTemplateItemId: number) {
    this.configuracaoCampoProviderId = configuracaoCampoProviderId;
    this.listaTemplateItemId = listaTemplateItemId;
    this.ativo = true;
  }
}