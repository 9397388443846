import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";

import { TranslateService } from "@ngx-translate/core";
import { PlanoTarifaProvedor } from '../../common/model/plano-tarifa-provedor';
import { PLanoTarifaProvedorService } from '../../common/service/plano-tarifa-provedor.service';
import { CurrencyPipe } from "@angular/common";
import swal from "sweetalert2";

import { isNull, isObjectEmpty } from "../../common/utils";
import * as validateJS from "validate.js";

@Component({
	selector: "app-form-plano-tarifa-provedor",
	templateUrl: "./form-plano-tarifa-provedor.component.html",
	styleUrls: ["./form-plano-tarifa-provedor.component.scss"],
	providers: [
		{ useClass: PLanoTarifaProvedorService, provide: PLanoTarifaProvedorService },
		{ useClass: CurrencyPipe, provide: CurrencyPipe }
	]
})
export class FormPlanoTarifaProvedorComponent {
	provedorId: number;
	planos: Array<PlanoTarifaProvedor> = [];
	submited: boolean = true;

	formattedAmount: string | null = "";

	public get error(): any {
		if (this.submited)
			return this.validar();
	}

	public get planosAtivos(): Array<PlanoTarifaProvedor> {
		return this.planos.filter(f => f.ativo);
	}

	public get disabled(): boolean {
		if (this.planosAtivos.length > 0) {
			let result = this.validar();
			return (!isObjectEmpty(result['planos'][0]['errors']));
		}

		return true;
	}

	public liveError(plano: PlanoTarifaProvedor, property: string): any {
		if (this.submited) {
			let result = this.validar();
			let index = this.planosAtivos.indexOf(plano);


			let e = result['planos'][0]['errors'][index];
			return (e === undefined) ? null : ((e['error'][property]) ? e['error'][property][0] : null);
		}
	}

	constructor(
		public dialog: MatDialog,
		private planoTarifaProvedorService: PLanoTarifaProvedorService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private currencyPipe: CurrencyPipe
	) {
		this.planos = [];
		this.route.params.subscribe(params => {

			if (params.id) {
				this.provedorId = params.id

				this.planoTarifaProvedorService
					.obterPlanos(params.id)
					.subscribe((planos: Array<PlanoTarifaProvedor>) => {
						if (planos.length <= 0)
							this.adicionarPlano();
						else
							this.planos = planos;
					});
			}
		});

		this.validacaoCustomizadas();
	}

	adicionarPlano() {
		this.planos.push(new PlanoTarifaProvedor(this.provedorId));
	}

	salvar() {
		this.submited = true;

		this.validar();

		this.translate
			.get([
				"telaPlanoTarifaProvedor.planoTarifaProvedor",
				"telaPadrao.camposInvalidos",
				"telaPadrao.sucessoSalvar",
				"telaPadrao.erroSalvar"
			])
			.subscribe((res: Object) => {

				let result = this.validar();

				if (!isObjectEmpty(result['planos'][0]['errors'])) {
					swal.fire(res["telaPlanoTarifaProvedor.planoTarifaProvedor"], res["telaPadrao.camposInvalidos"], "error");
					return;
				}

				const sucesso = () => {
					swal.fire(res["telaPlanoTarifaProvedor.planoTarifaProvedor"], res["telaPadrao.sucessoSalvar"], "success");
					this.router.navigate(["/app/provedor"]);
				};

				const erro = () => {
					swal.fire(res["telaPlanoTarifaProvedor.planoTarifaProvedor"], res["telaPadrao.erroSalvar"], "error");
				}

				this.planoTarifaProvedorService.salvar(this.planos).subscribe(sucesso, erro);
			});
	}

	excluirPlano(plano: PlanoTarifaProvedor) {

		let index = this.planos.indexOf(plano);

		if (plano.planoTarifaProvedorId <= 0) {
			this.planos.splice(index, 1);
		} else {
			plano.ativo = false;
			this.planos[index] = plano;
		}
	}

	// transformAmount(element: any, value: string | null) {
	// 	//console.log(this.formattedAmount);	
	// 	let v = value;//.replace('.', '|').replace(',', '.').replace('|', ',')
	// 	//console.log(v)
	// 	value = this.currencyPipe.transform(v, 'BRL');
	// 	element.target.value = value;
	// }

	// #region [ Validação ]

	private validar() {
		let regras: any = {
			"planos": {
				"array": {
					"provedorId": {
						"presence": { "allowEmpty": false, "message": "^campoObrigatorio" }
					},
					"faixaInicial": {
						"presence": { "allowEmpty": false, "message": "^campoObrigatorio" },
						"numericality": { "onlyInteger": true, "greaterThanOrEqualTo": 1, "message": "^menorque1" },
						"equality": {
							"attribute": "faixaFinal",
							"message": "^faixaFinalMenor",
							"comparator": (v1: number, v2: number) => {
								//v1 = faixaInicial, v2 = faixaFinal
								return ((v1 >= v2) == false);
							}
						}
					},
					"faixaFinal": {
						"presence": { "allowEmpty": false, "message": "^campoObrigatorio" },
						"numericality": { "onlyInteger": true, "greaterThanOrEqualTo": 1, "message": "^menorque1" },
						"equality": {
							"attribute": "faixaInicial",
							"message": "^faixaInicialMaior",
							"comparator": (v1: number, v2: number) => {
								//v1 = faixaFinal, v2 = faixaInicial
								return ((v1 <= v2) == false);
							}
						}
					},
					"valor": {
						"presence": { "allowEmpty": false, "message": "^campoObrigatorio" },
						"numericality": { "onlyInteger": false, "greaterThan": 0.0000000001, "message": "^menorque0" }
					}
				}
			}
		};

		return validateJS.validate({ "planos": this.planosAtivos }, regras);
	}

	private validacaoCustomizadas() {
		validateJS.validators.array = (arrayItems: Array<any>, itemConstraints: any) => {
			const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
				const error = validateJS(item, itemConstraints)
				if (error) errors[index] = { error: error }
				return errors
			}, {});

			return isNull(arrayItemErrors) ? null : { errors: arrayItemErrors };
		};
	}

	//#endregion

}