import { Component } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";

import { EstrategiaService } from "../../common/service/estrategia.service";
import { DisplayMessagemErroEstrategiaComponent } from "./display-messagem-erro-estrategia/display-messagem-erro-estrategia.component";
import { CustomAlertService } from "../../common/service/custom-alert.service";

@Component({
	selector: "app-historico-estrategia",
	templateUrl: "./historico-estrategia.component.html",
	providers: [
		{
			useClass: EstrategiaService,
			provide: EstrategiaService
		},
		{
			useClass: CustomAlertService,
			provide: CustomAlertService
		}
	]
})
export class HistoricoEstrategiaComponent {
	listaHistorico: any[] = [];
	filtro:any = {};
	estrategiaId: any;

	constructor(
		private router: ActivatedRoute,
		private estrategiaService: EstrategiaService,
		public dialog: MatDialog,
		public customAlert: CustomAlertService
		) {
		this.filtro.dataMenorQue = new Date();
		this.filtro.dataMaiorQue = new Date(this.filtro.dataMenorQue.getFullYear(), this.filtro.dataMenorQue.getMonth(), this.filtro.dataMenorQue.getDate() - 3);

		this.router.params.subscribe(params => {
			this.estrategiaId = params.id;
			this.estrategiaService
				.obterHistoricoEstrategia(params.id, this.filtro)
				.subscribe(listaHistorico => {
					this.listaHistorico = listaHistorico || [];
					this.router.queryParams.subscribe(query => {
						if (!query.openLastError) return;
						this.abrirUltimaListaComErro();
					});
				});
		});

	}

	public get possuiCodigo(): boolean {
		if (this.listaHistorico.length == 0) return false;
		return this.listaHistorico[0].codigoIntegracao != undefined;
	}

	obterUltimaListaComErro() {
		const listasComErro =
			this.listaHistorico
				.sort(c => c.dataCadastro)
				.filter(c => c.mensagemErro) || [];
		if (listasComErro.length == 0) return;
		return listasComErro[0];
	}

	abrirUltimaListaComErro() {
		const data = this.obterUltimaListaComErro();
		this.abrirMensagemErro(data);
	}

	abrirMensagemErro(data) {
		if (!data) return;
		this.dialog.open(DisplayMessagemErroEstrategiaComponent, {
			width: "750px",
			data
		});
	}

	filtrarHistorico() {
		this.estrategiaService
			.obterHistoricoEstrategia(this.estrategiaId, this.filtro)
			.subscribe(listaHistorico => (this.listaHistorico = listaHistorico));
	}

	baixarHistorico(historico) {
		if (historico.arquivoNome)
			this.estrategiaService.baixarHistorico(
				historico.listaEstrategiaId,
				historico.listaEstrategiaTrackingId,
				historico.listaEstrategia.nome
			);
		else
			this.customAlert.show(
				"telaEstrategia.download",
				"telaEstrategia.expurgoArquivo",
				"info"
			);
	}

	limpar() {
		this.filtro = {};
	}
}
