
const validate = require("validate.js");

export class NNotificacaoConexaoWhatsapp {
    whatsAppSessaoId: string;
    telefone: string;

    conectado: boolean = false;

    constructor() {
        //this.nome = GuidService.newGuid()
    }

    static fromRaw(raw: any): NNotificacaoConexaoWhatsapp {
        let model: NNotificacaoConexaoWhatsapp = Object.assign(new NNotificacaoConexaoWhatsapp(), raw);
        return model;
    }

    // toJSON method to control serialization
    toJSON() {
        return {

            whatsAppSessaoId: this.whatsAppSessaoId,
            telefone: this.telefone
        };
    }

    validateConnection() {
        let rules: any = {
            'telefone': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            }
        };

        return validate.validate(this, rules);
    }

    validate() {
        let rules: any = {
            'whatsAppSessaoId': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            },
            'telefone': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            }
        };

        return validate.validate(this, rules);
    }
}