import { FonteDadoTipo } from "./fonte-dado-tipo";
import { validate } from "validate.js";

export class FonteDado {
  listaFonteDadoId: number;
  listaFonteDadoTipoId: FonteDadoTipo;
  descricao: string;
  diretorio: string;
  diretorioHistorico: string;
  usuario: string;
  senha: string;
  recorrente: boolean;
  intervalo: number;
  horaInicio = "12:00";
  horaTermino = "00:00";
  dataInicio: Date;
  dataTermino: Date;
  ativo: boolean;
  listaId: number;
  listaTemplateId: number;

  constructor() {
    this.ativo = true;
  }

  validar() {
    var validacaoFonteDado: any = {
      descricao: {
        presence: { allowEmpty: false }
      },
      // diretorio: {
      //   presence: { allowEmpty: false }
      // },
      // diretorioHistorico: {
      //   presence: { allowEmpty: false }
      // },
      // listaFonteDadoTipoId: {
      //   presence: { allowEmpty: false }
      // },
      listaTemplateId: {
        presence: { allowEmpty: false }
      }
    };

    if (this.recorrente) {
      validacaoFonteDado.intervalo = { presence: { allowEmpty: false } };
      validacaoFonteDado.horaInicio = { presence: { allowEmpty: false } };
      validacaoFonteDado.dataInicio = { presence: { allowEmpty: false } };
    }

    return validate(this, validacaoFonteDado);
  }

  static fromRaw(raw) {
    var fonteDado = new FonteDado();
    return Object.assign(fonteDado, raw);
  }
}
