import { LanguageService } from './../../service/language.service';
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { routes } from "../../../routing";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-breadcrumb",
	templateUrl: "./breadcrumb.component.html"
})
export class BreadcrumbComponent implements OnInit {
	breadCrumbCollection: Array<MenuItem> = [];
	home = { icon: "fa fa-home", routerLink: "/app/home" };
	constructor(private route: ActivatedRoute,
		        private translateService: TranslateService) { }

	findRouteByPath(path) {
		return routes.find(r => r.path == path);
	}

	ngOnInit() {
		var route = this.findRouteByPath(this.route.routeConfig.path);
		this.breadCrumbCollection = [{ label: this.translateService.instant(route.data.label) }];

		while (route.data.parent) {
			route = this.findRouteByPath(route.data.parent);

			this.route.params.subscribe((params: any) => {
				let path: string = '';
				route.path.split(':').forEach((item: any) => {
					let i = item.replace('/', '');
					path += (params[i]) ? `${params[i]}` : `${i}/`;
				});
			
				this.breadCrumbCollection.push({ label: this.translateService.instant(route.data.label), routerLink: `/app/${path}` });
			});
		}


		this.breadCrumbCollection = this.breadCrumbCollection.reverse();
	}
}
