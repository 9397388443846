import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, } from "@angular/common/http";
import { Tarefa } from "../model/tarefa";
import { convertToQueryString } from "../../common/service/query-string";

@Injectable()
export class TarefaService {
	private api: string;

	constructor(private httpClient: HttpClient) { 
		this.api = '/api/tarefas';
	}

	obterTarefas(filtro: any, blockLoading: boolean = false) {

		if (blockLoading) {
			let headers = new HttpHeaders().set('blockLoading', 'true');
			return this.httpClient.get<Array<Tarefa>>(`${this.api}/estrategias-executadas${convertToQueryString(filtro)}`, { headers: headers });
		}
		else {
			return this.httpClient.get<Array<Tarefa>>(`${this.api}/estrategias-executadas${convertToQueryString(filtro)}`);
		}
	}

	obterEstrategiasParaExecutar(filtro: any, blockLoading: boolean = false) {
		if (blockLoading) {
			let headers = new HttpHeaders().set('blockLoading', 'true');
			return this.httpClient.get<Array<any>>(`${this.api}/estrategias-para-executar${convertToQueryString(filtro)}`, { headers: headers });
		}
		else {
			return this.httpClient.get<Array<any>>(`${this.api}/estrategias-para-executar${convertToQueryString(filtro)}`);
		}
	}

	limparTarefasNaoExecutadas() {
		return this.httpClient.get<any>(`${this.api}/limpar-tarefas-nao-executadas`);
	}

}
