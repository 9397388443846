import * as dayjs from 'dayjs';

export enum ImportacaoAutomaticaNotificacaoPassos {
    inicio = 0,
    copia = 1,
    leitura = 2,
    processamento = 3,
    finalizacao = 4
}

export class ImportacaoAutomaticaNotificacao {

    listaId: number;
    importacaoAutomaticaConfiguracaoId: number;
    dataInicio: Date;
    dataAtual: Date;
    duracao: string;
    passo: ImportacaoAutomaticaNotificacaoPassos;
    listaArquivos: Array<any>;
    porcentagem: number;
    copia: ImportacaoAutomaticaNotificacaoQuantidades;
    upload: ImportacaoAutomaticaNotificacaoQuantidades;
    processamento: ImportacaoAutomaticaNotificacaoQuantidades;

    diferencaBackFront: number = 0;
    tempoExecucao: string;



    constructor() {
        this.passo = ImportacaoAutomaticaNotificacaoPassos.inicio;
        this.listaArquivos = new Array<ImportacaoAutomaticaNotificacaoListaArquivos>();
        this.copia = new ImportacaoAutomaticaNotificacaoQuantidades();
        this.upload = new ImportacaoAutomaticaNotificacaoQuantidades();
        this.processamento = new ImportacaoAutomaticaNotificacaoQuantidades();
    }

    public calcularDiferencaDataBackFront(): void {
        let atualFront = dayjs();
        let atualBack = dayjs(this.dataAtual);
        this.diferencaBackFront = (atualBack.isBefore(atualFront)) ? atualFront.diff(atualBack, 'seconds') : (0 - atualBack.diff(atualFront, 'seconds'));
    }

    static fromRaw(raw: any): ImportacaoAutomaticaNotificacao {
        return Object.assign(new ImportacaoAutomaticaNotificacao(), raw);
    }
}

export class ImportacaoAutomaticaNotificacaoListaArquivos {
    nome: string;
    tamanho: string;
}

export class ImportacaoAutomaticaNotificacaoQuantidades {
    quantidade: number;
    total: number;
}