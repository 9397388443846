import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';

import { isNullOrEmpty } from "../utils/";
import { PreferenciasUsuario } from "../model/preferencias-usuario";

@Injectable()
export class PreferenciasUsuarioService {
    private api: string;

    constructor(private httpClient: HttpClient) {
        this.api = '/api/usuarios';
    }

    public localStorageObterPreferenciasUsuario(): PreferenciasUsuario {
        let ls = localStorage.getItem('preferenciasUsuario');

        if (isNullOrEmpty(ls))
            return new PreferenciasUsuario();

        return PreferenciasUsuario.fromRaw(JSON.parse(ls as string));
    }

    public localStorageGravarPreferenciasUsuario(preferencias: PreferenciasUsuario) {
        localStorage.setItem('preferenciasUsuario', JSON.stringify(preferencias));
    }

    public obterPorUsuario() {
        return this.httpClient.get<PreferenciasUsuario>(`${this.api}/preferencias-usuario`).pipe(map(PreferenciasUsuario.fromRaw));
    }

    public criar(preferencias: PreferenciasUsuario) {
        return this.httpClient.post<PreferenciasUsuario>(`${this.api}/preferencias-usuario`, preferencias);
    }

    public atualizar(preferencias: PreferenciasUsuario) {
        return this.httpClient.put<PreferenciasUsuario>(`${this.api}/preferencias-usuario`, preferencias);
    }

}
