import { construct } from "core-js/fn/reflect";
import { EstrategiaAcaoCampanhaTipo } from "./estrategia";

export class FiltroLista {
	status: string;
	nome: string;
	template: string;
	carteiraId: number;
	lista: number | null;
	estrategiaAcaoCampanhaTipoId: number;
	provider: number;
	ignorarErroPesquisa: boolean;
	ignorarIndisponivel: boolean;
	remocao: boolean;
    usuarioRequestId: number;
    ignorarQueryFilter: boolean;

	constructor(ignorarErroPesquisa: boolean = false, usuarioRequestId: number = null, ignorarQueryFilter: boolean = false) {
		this.ignorarErroPesquisa = ignorarErroPesquisa;
        this.usuarioRequestId = usuarioRequestId;
        this.ignorarQueryFilter = ignorarQueryFilter
	}


	static fromRaw(raw: any): FiltroLista {
        return Object.assign(new FiltroLista(), raw);
    }
}
