import { Component, OnInit } from "@angular/core";
import swal from "sweetalert2";

import { Usuario } from "../../common/model/usuario";
import { UsuarioService } from "../../common/service/usuario.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-lista-apikey",
  templateUrl: "./lista-apikey.component.html",
  styleUrls: ["../style.scss"],
  providers: [{ useClass: UsuarioService, provide: UsuarioService }]
})
export class ListaApiKeyComponent implements OnInit {
  usuarios: Array<Usuario>;
  constructor(
    private usuarioService: UsuarioService,
    private translate: TranslateService) {
    this.usuarios = [];
  }

  ngOnInit() {
    this.usuarioService
      .obterApikeys()
      .subscribe(usuarios => (this.usuarios = usuarios));
  }

  filtrarUsuario(filtro) {
    this.usuarioService
      .obterApikeys(filtro)
      .subscribe(usuarios => (this.usuarios = usuarios));
  }

  excluir(usuarioId) {
    this.translate.get(['telaPadrao.perguntaConfirmacao', 'telaPadrao.descricaoConfirmacao', 'telaPadrao.confirmar', 'telaPadrao.cancelar']).subscribe((res: Object) => {
      swal.fire({
        title: res['telaPadrao.perguntaConfirmacao'],
        text: res['telaPadrao.descricaoConfirmacao'],
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: res['telaPadrao.confirmar'],
        cancelButtonText: res['telaPadrao.cancelar']
      }).then((result) => {
        if (result.value) {
          this.usuarioService
            .desativar(usuarioId)
            .subscribe(
              () =>
                (this.usuarios = this.usuarios.filter(
                  t => t.usuarioId != usuarioId
                ))
            );
        }
      })
    });
  }
}
