import { Component, Output, EventEmitter, Input } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";


import { Template } from "../../../common/model/template";
import { FiltroTarefa } from "../../../common/model/filtro-tarefa";
import { ListaService } from "../../../common/service/lista.service";
import { EstrategiaService } from "../../../common/service/estrategia.service";
import { Estrategia } from "../../../common/model/estrategia";
import { Lista } from "../../../common/model/lista";

import { CarteiraService } from "../../../common/service/carteira.service";
import { Carteira } from "../../../common/model/carteira";
import { FiltroLista } from "../../../common/model/filtro-lista";
import { PreferenciasUsuarioService } from "../../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../../common/model/preferencias-usuario";

@Component({
	selector: "app-filtro-tarefa",
	templateUrl: "./filtro-tarefa.component.html",
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
		{ provide: CarteiraService, useClass: CarteiraService },
		{ provide: PreferenciasUsuarioService, useClass: PreferenciasUsuarioService },
		{ provide: ListaService, useClass: ListaService },
		{ provide: EstrategiaService, useClass: EstrategiaService },
	]
})
export class FiltroTarefaComponent {
	preferenciasUsuario: PreferenciasUsuario;

	filtro: FiltroTarefa;
	estrategia: Array<Estrategia> = [];
	listas: Array<Lista> = [];
	carteiras: Array<Carteira> = [];
	dirty: boolean;
	listaStatus: Array<any> = [];
	@Input() filtroCompleto: boolean;
	@Output()
	filtrar: EventEmitter<FiltroTarefa>;

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	public get erros() {
		if (!this.dirty) return;
		return this.filtro.validar();
	}

	constructor(private listaService: ListaService,
		private carteiraService: CarteiraService,
		private preferenciasService: PreferenciasUsuarioService,
		private estrategiaService: EstrategiaService,
		private translate: TranslateService) {
		this.filtro = new FiltroTarefa();
		this.filtrar = new EventEmitter<FiltroTarefa>();
		this.filtro.somenteExecucaoComErro = false;

		// Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

		this.carteiraService.obterCarteiras().subscribe(carteiras => {
			this.carteiras = carteiras.sort((a,b) => {
				if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
					return -1;
				}
				if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
					return 1;
				}
				return 0;
			});


			if (this.preferenciasUsuario.existePreferenciaCarteira) {
				this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
			} else {
				let padrao = this.carteiras.find(f => f.padrao) as Carteira;
				this.filtro.carteiraId = padrao.carteiraId;
			}

			this.obterListas();
			this.filtrar.emit(this.filtro);
		});

		this.obterTarefaStatus();


	}

	obterTarefaStatus() {
		observableCombineLatest([
			this.translate.get("telaTarefa.tarefaStatus.execucao"),
			this.translate.get("telaTarefa.tarefaStatus.concluido"),
			this.translate.get("telaTarefa.tarefaStatus.erro"),
			this.translate.get("telaTarefa.tarefaStatus.naoExecutado"),
			this.translate.get("telaTarefa.tarefaStatus.listaOffline"),
			this.translate.get("telaTarefa.tarefaStatus.listaAguardandoIndexacao"),
			this.translate.get("telaTarefa.tarefaStatus.listaEmProcessamento"),
			this.translate.get("telaTarefa.tarefaStatus.listaInativa"),
			this.translate.get("telaTarefa.tarefaStatus.listaAguardandoMediacao"),
			this.translate.get("telaTarefa.tarefaStatus.concluidoComItensNaoExportados"),
			this.translate.get("telaTarefa.tarefaStatus.canceladaPeloUsuario"),
			this.translate.get("telaTarefa.tarefaStatus.execucaoZerada"),
			this.translate.get("telaTarefa.tarefaStatus.aguardandoInicioExecucao")
		])
			.toPromise()
			.then(resultCollection => {

				this.listaStatus = [
					{ 'value': '13', 'label': resultCollection[12] },
					{ 'value': '1', 'label': resultCollection[0] },
					{ 'value': '2', 'label': resultCollection[1] },
					{ 'value': '3', 'label': resultCollection[2] },
					{ 'value': '4', 'label': resultCollection[3] },
					{ 'value': '5', 'label': resultCollection[4] },
					{ 'value': '6', 'label': resultCollection[5] },
					{ 'value': '7', 'label': resultCollection[6] },
					{ 'value': '8', 'label': resultCollection[7] },
					{ 'value': '9', 'label': resultCollection[8] },
					{ 'value': '10', 'label': resultCollection[9] },
					{ 'value': '11', 'label': resultCollection[10] },
					{ 'value': '12', 'label': resultCollection[11] }
				];
			});
	}

	obterListas() {
		
		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = true;
		filtroLista.carteiraId = this.filtro.carteiraId;
		
		if (this.preferenciasUsuario.existePreferenciaLista) {
			this.filtro.lista = this.preferenciasUsuario.listaId as number;
		}

		this.listaService.obterListaSemQuantidade(filtroLista).subscribe(lista => {
			this.listas = lista.sort((a,b) => {
				if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
					return -1;
				}
				if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
					return 1;
				}
				return 0;
			});

			
			
		});
	}

	carteiraChange() {
		if (this.filtro.carteiraId){
			this.obterListas();
		}
		else
		{
			this.filtro.lista = undefined;
			this.listas = [];
			this.filtro.listaEstrategia = undefined;
			this.estrategia = [];
		}
	}

	changeLista() {
		if (this.filtro.lista) {
			this.estrategiaService.obterEstrategiasLookup({ 'lista': this.filtro.lista, 'carteiraId': this.filtro.carteiraId }).subscribe(estrategias => this.estrategia = estrategias);
		} else {
			this.filtro.listaEstrategia = undefined;
			this.estrategia = [];
		}
	}

	filtrarLista() {
		this.dirty = true;

		if (this.filtro.validar())
			return;

		this.filtrar.emit(this.filtro);
	}

	limpar() {
		this.filtro = new FiltroTarefa();
		let padrao = this.carteiras.find(f => f.padrao);
		this.filtro.carteiraId = padrao.carteiraId;
		this.filtro.somenteExecucaoComErro = false;
		this.filtrarLista();
	}

}
