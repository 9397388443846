import { CampoTipo } from "./campo-tipo";
import { validate } from "validate.js";
import { Template } from "../../common/model/template";
import { ConfiguracaoCampoProvider, ProvedorConfiguracaoTipo, CampoFormato } from "./configuracao-campo-provedor";
import { ProvedorConfiguracao } from "./provedor-configuracao";
import { ProvedorAgendamentoConfiguracao } from "./provedor-agendamento-configuracao";
import { isNull } from "../utils";
import { ConfiguracaoListaProvedor } from "./configuracao-lista-provedor";

export class Provider {
	public providerId: number;
	public descricao: string;
	public logo: string;
	public ativo: boolean;
	public alias: string;
	public listaCampoTipo: Array<CampoTipo>;
	public listaTemplate: Array<Template>;
	public configuracaoCampoProvider: Array<ConfiguracaoCampoProvider>;
	public configuracaoListaProvedor: Array<ConfiguracaoListaProvedor>;
	public provedorConfiguracao: Array<ProvedorConfiguracao>;
	public provedorAgendamentoConfiguracao: ProvedorAgendamentoConfiguracao;

	public providerPadrao: boolean;
	public permiteCampoTemplateExportacao: boolean;
	public configuracaoPorCampanha: boolean;
	public existeTemplateExportacao: boolean;

	public get listaCampoTipoAtiva(): Array<CampoTipo> {
		return this.listaCampoTipo.filter(c => c.ativo);
	}

	public get configuracaoCampoEnvioFormAtivo(): Array<ConfiguracaoCampoProvider> {
		let campos = this.configuracaoCampoProvider.filter(c => c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio && c.campoFormatoId == CampoFormato.Form && c.ativo);

		if (!isNull(campos))
			return campos.sort((a: any, b: any) => a.ordem - b.ordem);

		return campos;
	}

	public get configuracaoCampoEnvioRawAtivo(): ConfiguracaoCampoProvider {
		let campos = this.configuracaoCampoProvider.find(c => c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio && c.campoFormatoId == CampoFormato.Raw && c.ativo);
		return campos;
	}

	public get configuracaoCampoRetornoFormAtivo(): Array<ConfiguracaoCampoProvider> {
		let campos = this.configuracaoCampoProvider.filter(c => c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Retorno && c.campoFormatoId == CampoFormato.Form && c.ativo);

		if (!isNull(campos))
			return campos.sort((a: any, b: any) => a.ordem - b.ordem);

		return campos;
	}

	public get envio(): ProvedorConfiguracao {
		return this.provedorConfiguracao.find(c => c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio && c.ativo);
	}

	public get retorno(): ProvedorConfiguracao {
		return this.provedorConfiguracao.find(c => c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Retorno && c.ativo);
	}

	public get campanha(): ProvedorConfiguracao {
		return this.provedorConfiguracao.find(c => c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Campanha && c.ativo);
	}

	constructor() {
		this.listaCampoTipo = new Array<CampoTipo>();
		this.provedorConfiguracao = new Array<ProvedorConfiguracao>();
		this.listaTemplate = [];
		this.configuracaoCampoProvider = [];
		this.ativo = true;
	}

	validate() {
		let validacao = {
			descricao: { presence: { allowEmpty: false } },
			alias: {
				presence: { allowEmpty: false, message: "^obrigatorio" },
				length: { minimum: 3, message: "^minimo" } 
			}
		};

		let validacaoProvider = validate(this, validacao);
		let validacaoEnvio = this.envio.validate();
		let validacaoRetorno = this.retorno.validate();
		let validacaoCampanha = this.campanha != null && this.configuracaoPorCampanha ? this.campanha.validate() : null;
		let validacaoCampoJson = this.envio.campoFormatoId == CampoFormato.Raw ? this.configuracaoCampoEnvioRawAtivo.validarJson() : null;
		let validacaoCamposRetorno = this.retorno.integracaoTipoId != null
			? (this.configuracaoCampoRetornoFormAtivo.length == 0 ? { campoNaoAdicionado: { presence: { allowEmpty: false } } } : null)
			: (this.configuracaoCampoRetornoFormAtivo.length >= 1 ? { campoAdicionado: { presence: { allowEmpty: false } } } : null);

		if (validacaoProvider || validacaoEnvio || validacaoCampanha ||
			validacaoRetorno || validacaoCampoJson || validacaoCamposRetorno) {
			return {
				provider: validacaoProvider,
				envio: validacaoEnvio,
				campanha: validacaoCampanha,
				retorno: validacaoRetorno,
				camposRetorno: validacaoCamposRetorno,
				json: validacaoCampoJson
			};
		}

		return null;
	}

	public formatarCamposEnvio() {
		var camposForm = this.configuracaoCampoEnvioFormAtivo;
		var campoRaw = this.configuracaoCampoEnvioRawAtivo;

		if (this.envio.campoFormatoId == CampoFormato.Form) {
			if (campoRaw != null) {
				campoRaw.ativo = false;
			}
		} else if (this.envio.campoFormatoId == CampoFormato.Raw) {
			if (camposForm != null) {
				camposForm.forEach((campo) => {
					campo.ativo = false;
				});
			}

			campoRaw.formatarJson();
		}
	}
}