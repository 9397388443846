import { Component, OnDestroy, OnInit } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import { environment } from "../../../environments/environment";
import { DownloadRegistro, DownloadRegistroStatus } from "../common/model/download-registro";
import { FiltroDownloadRegistro } from "../common/model/filtro-download-registro";
import { FiltroLista } from "../common/model/filtro-lista";
import { Lista } from "../common/model/lista";
import { CustomAlertService } from "../common/service/custom-alert.service";
import { DownloadRegistroService } from "../common/service/download-registro.service";
import { LogHistoricoComponent } from "./../common/components/log-historico/log-historico.component";
import { ListaService } from "../common/service/lista.service";
import * as signalR from "@aspnet/signalr";
import { isNull, isNullOrEmpty } from "../common/utils";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { CarteiraService } from "../common/service/carteira.service";
import { Carteira } from "../common/model/carteira";
import { PreferenciasUsuarioService } from "../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../common/model/preferencias-usuario";
import { MatDialog } from "@angular/material";
import { ConfigProvider } from "../../common/providers/config.provider";

@Component({
    selector: "app-download-registro",
    templateUrl: "./download-registro.component.html",
    styleUrls: ["./download-registro.component.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
        { useClass: DownloadRegistroService, provide: DownloadRegistroService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: ListaService, provide: ListaService }
    ]
})
export class DownloadRegistroComponent implements OnInit, OnDestroy {
    isDirty: boolean;
    carteiras: Array<Carteira> = [];
    preferenciasUsuario: PreferenciasUsuario;
    listas: Array<Lista>;
    status: Array<any> = [];
    filtro: FiltroDownloadRegistro = new FiltroDownloadRegistro();
    downloadsRegistro: Array<DownloadRegistro>;
    downloadRegistroStatus = DownloadRegistroStatus;
    _hubConnection: HubConnection | undefined;

    public get downloadRegistroStatusIdString() {
        if (isNullOrEmpty(this.filtro.downloadRegistroStatusId))
            return null;
        return this.filtro.downloadRegistroStatusId.toString();
    }

    public set downloadRegistroStatusIdString(value: any) {
        this.filtro.downloadRegistroStatusId = value;
    }

    public get erros() {
        if (!this.isDirty)
            return false;

        return this.filtro.validate();
    }

    constructor(
        private downloadRegistroService: DownloadRegistroService,
        private carteiraService: CarteiraService,
        private preferenciasService: PreferenciasUsuarioService,
        private listaService: ListaService,
        public dialog: MatDialog,
        private customAlertService: CustomAlertService,
        private configProvider: ConfigProvider,
        private translate: TranslateService) {

        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

        this.filtro = new FiltroDownloadRegistro()
        this.popularSelectStatus();
    }

    ngOnInit() {
        this.obterCarteiras();

        this.conectarSignalR();
        this.escutarSignalR();
        this.reconectarSignalR();
    }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras.sort((a,b) => {
				if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
					return -1;
				}
				if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
					return 1;
				}
				return 0;
			});

            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }

            this.obterListas();

            this.setarFiltroPadrao();
            this.pesquisar();
        });
    }

    obterListas() {
        let filtroLista = new FiltroLista();
        filtroLista.ignorarErroPesquisa = true;
        filtroLista.carteiraId = this.filtro.carteiraId;
        this.listaService.obterListaSemQuantidade(filtroLista).subscribe(listas => {
            this.listas = listas.sort((a,b) => {
				if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
					return -1;
				}
				if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
					return 1;
				}
				return 0;
			});
        });
    }

    carteiraChange() {
        this.obterListas();
    }

    pesquisar() {
        this.isDirty = true;

        if (this.erros)
            return this.customAlertService.show(
                "telaDownloadRegistro.downloadRegistro",
                "telaPadrao.camposInvalidos",
                "error"
            );

        this.downloadRegistroService.obterDownloadRegistro(this.filtro).subscribe(downloadsRegistro => {
            this.downloadsRegistro = downloadsRegistro;
        });
    }

    downloadArquivo(downloadRegistro: DownloadRegistro) {
        this.downloadRegistroService.baixarArquivoRegistro(downloadRegistro);
    }

    abrirLogHistorico(id, descricao) {
        let data = { id: id, entidade: 3,titulo: "Download Registro - Lista "+ descricao };

        this.dialog.open(LogHistoricoComponent, {
            hasBackdrop: true,
            height: "500px",
            width: "40%",
            data
        });
    }

    reprocessarDownload(downloadRegistro: DownloadRegistro) {
        downloadRegistro.downloadRegistroStatusId = this.downloadRegistroStatus.Pendente;
        downloadRegistro.dataInicioProcessamento = null;
        downloadRegistro.dataFimProcessamento = null;
        downloadRegistro.quantidadeRegistro = null;
        downloadRegistro.diretorioArquivo = null;
        downloadRegistro.nomeArquivo = null;

        this.downloadRegistroService.criarDownloadTotal(downloadRegistro).subscribe(result => {
            this.customAlertService.show(
                "telaDownloadRegistro.downloadRegistro",
                "telaDownloadRegistro.reprocessarCriado",
                "success"
            );

            this.pesquisar();
        });
    }

    atualizarDownloadRegistroSignalR(dados: any) {
        if (dados.atualizacaoStatus) {
            this.downloadsRegistro = this.downloadsRegistro.map((d: DownloadRegistro) => {
                if (d.downloadRegistroId == dados.downloadRegistro.downloadRegistroId)
                    d = DownloadRegistro.fromRaw(dados.downloadRegistro);

                return d;
            });
        }

        if (dados.atualizacaoPorcentagem) {
            this.downloadsRegistro = this.downloadsRegistro.map((d: DownloadRegistro) => {

                if (d.downloadRegistroId == dados.downloadRegistro.downloadRegistroId) {
                    d.porcentagem = dados.porcentagem;
                    d.restantes = dados.restantes;
                    d.processados = dados.processados;
                }

                return d;
            });
        }
    }

    popularSelectStatus() {
        observableCombineLatest([
            this.translate.get("telaDownloadRegistro.statusDownload.pendente"),
            this.translate.get("telaDownloadRegistro.statusDownload.processando"),
            this.translate.get("telaDownloadRegistro.statusDownload.concluido"),
            this.translate.get("telaDownloadRegistro.statusDownload.erro")
        ])
            .toPromise()
            .then(result => {

                this.status = [
                    { 'value': '1', 'label': result[0] },
                    { 'value': '2', 'label': result[1] },
                    { 'value': '3', 'label': result[2] },
                    { 'value': '4', 'label': result[3] }
                ];
            });
    }

    //#region [ Métodos Filtro ]

    setarFiltroPadrao() {
        let dataFim = new Date();
        let dataInicio = new Date(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate() - 1);

        if (this.preferenciasUsuario.existePreferenciaCarteira) {
            this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
        } else {
            let padrao = this.carteiras.find(f => f.padrao) as Carteira;
            this.filtro.carteiraId = padrao.carteiraId;
        }

        this.filtro.dataInicio = dataInicio;
        this.filtro.dataFim = dataFim;
        this.filtro.listaId = null;
        this.filtro.downloadRegistroStatusId = null;
    }

    limpar() {
        this.setarFiltroPadrao();
        this.obterListas();
    }

    //#endregion

    //#region [ Métodos SignalR ]

    ngOnDestroy() {
        this._hubConnection.stop();
    }

    conectarSignalR() {
        let endpoint = this.configProvider.getConfig().serverUrl;

        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${endpoint}/notificacaohub`, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
            .configureLogging(signalR.LogLevel.Information)
            .build();
    }

    reconectarSignalR() {
        this._hubConnection.onclose(() => {
            this.conectarSignalR();
            this.escutarSignalR();
            this.reconectarSignalR();
        });
    }

    escutarSignalR() {
        return this._hubConnection.start().then(() => {
            this._hubConnection.on('AtualizarDownloadRegistro', dados => {
                if (!dados) return;
                this.atualizarDownloadRegistroSignalR(dados);
            });
        });
    }

    //#endregion

}