const validate = require("validate.js");

export class FluxoQuery {
    fluxoQueryId: number;
    listaId: number;
    nome: string;
    filtro: string;
    filtroRegras: string;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;

    quantidadeRegistros: number;

    constructor() { }

    validar() {
        var validacao: any = {
            nome: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            },
            listaId: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(rawFluxo): FluxoQuery {
        return Object.assign(new FluxoQuery(), rawFluxo);
    }
}
