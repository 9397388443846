import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { convertToQueryString } from "./query-string";
import { FiltroNotificacao } from "../model/filtro-notificacao";
import { Notificacao } from "../model/notificacao";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { NNotificacao } from "../model/nnotificacao";
import { isNull } from "../utils/isNull";
import { NNotificacaoCampos, NNotificacaoConexao, NNotificacaoEvento, NNotificacaoHistorico } from "../model/nnotificacao.index";

@Injectable()
export class NotificacaoService {
	private api: string;

	constructor(private httpClient: HttpClient, private translateService: TranslateService) {
		this.api = '/api/notificacao';
	}

	public obterNotificacaoAntigo(filtro: FiltroNotificacao) {
		return this.httpClient.get<Array<Notificacao>>(`${this.api}/antigo${this.convertToQueryString(filtro)}`);
	}

	public obterNotificacaoPorId(id: number) {
		return this.httpClient.get<Notificacao>(`${this.api}/antigo/${id}`);
	}


	public conectarWhatsapp(model: any): Observable<any> {
		return this.httpClient.post<any>(`${this.api}/whatsapp/conectar`, model)
	}

	public statusWhatsapp(sessaoId: string, telefone: string): Observable<any> {
		let headers = new HttpHeaders().set('blockLoading', 'true');
		return this.httpClient.get<any>(`${this.api}/whatsapp/status/${sessaoId}/${telefone}`, { headers: headers })
	}



	//#region \


	private convertToQueryString(queryObject, usePrefix = true) {
		if (!queryObject) { return ''; }

		const formUrlEncoded = Object.keys(queryObject)
			.map(
				prop => {
					if (queryObject[prop] != null) {
						return queryObject[prop] instanceof Date || queryObject[prop]._isAMomentObject || Array.isArray(queryObject[prop])
							? `${prop}=${encodeURIComponent(JSON.stringify(queryObject[prop]))}`
							: `${prop}=${encodeURIComponent(queryObject[prop]).trim()}`;
					}
					return '';
				})
			.join("&");;

		return usePrefix ? `?${formUrlEncoded}` : formUrlEncoded;
	}
	
	obterNotificacao(filtro: any) {
		return this.httpClient.get<Array<NNotificacao>>(`${this.api}/notificacoes${this.convertToQueryString(filtro)}`);
	}

	obterNotificacaoConexao(filtro: any) {
		return this.httpClient.get<Array<NNotificacaoConexao>>(`${this.api}/conexoes${this.convertToQueryString(filtro)}`);
	}

	obterCamposApi() {
		return this.httpClient.get<Array<NNotificacaoCampos>>(`${this.api}/campos-api`);//.pipe(map(f => NNotificacaoCamposApi.fromRaw(f)));
	}
	
	obterNotificacaoHistorico(filtro: any) {
		return this.httpClient.get<Array<NNotificacaoHistorico>>(`${this.api}/historico${this.convertToQueryString(filtro)}`);
	}

	salvarNotificacao(notificacao: NNotificacao) {
		return this.httpClient.post<NNotificacao>(`${this.api}/salvar`, notificacao);
	}

	salvarConexao(conexao: NNotificacaoConexao) {
		return this.httpClient.post<NNotificacao>(`${this.api}/salvar-conexao`, conexao);
	}


	definirHistoricoLido(id: string) {
		let headers = new HttpHeaders().set('blockLoading', 'true');
		return this.httpClient.patch<any>(`${this.api}/${id}/historico-lido/`, null, { headers: headers });
	}

	obterQuantidadeHistoricoNaoLido() {
		let headers = new HttpHeaders().set('blockLoading', 'true');
		return this.httpClient.get<Array<any>>(`${this.api}/quantidade-historico-nao-lido`, { headers: headers });
	}

	desativarConexao(id: number) {
		return this.httpClient.patch<any>(`${this.api}/${id}/desativar-conexao/`, null);
	}


	desativarEvento(id: number) {
		return this.httpClient.patch<any>(`${this.api}/${id}/desativar-evento/`, null);
	}

	// salvarEvento(notificacaoId: number, evento: NNotificacaoEvento) {
	// 	return this.httpClient.post<NNotificacaoEvento>(`${this.api}/${notificacaoId}/salvar-evento/`, evento);
	// }

	obterConfiguracaoNotificacao() {
		return this.httpClient.get<NNotificacao>(`${this.api}/configuracao-por-usuario`).pipe(map(f => NNotificacao.fromRaw(f)));
	}



	obterFiltrosEventosAgrupados() {
		return observableCombineLatest(
			[
				this.translateService.get("telaNNotificacao.eventos.grupo.todos"),
				this.translateService.get("telaNNotificacao.eventos.grupo.database"),
				this.translateService.get("telaNNotificacao.eventos.grupo.download"),
				this.translateService.get("telaNNotificacao.eventos.grupo.estrategia"),
				this.translateService.get("telaNNotificacao.eventos.grupo.execucaoEstrategia"),
				this.translateService.get("telaNNotificacao.eventos.grupo.lista"),
				this.translateService.get("telaNNotificacao.eventos.grupo.indice"),
				this.translateService.get("telaNNotificacao.eventos.grupo.templateImportacao")
			])
			.toPromise()
			.then(result => {

				return [
					{ 'id': 'todos', 'label': result[0], 'color': '#494f54', 'naolidos': 0, 'eventos': [] },
					{ 'id': 'database', 'label': result[1], 'color': '#ae7cff', 'naolidos': 0, 'eventos': [1] },
					{ 'id': 'download', 'label': result[2], 'color': '#c0851f', 'naolidos': 0, 'eventos': [2] },
					{ 'id': 'estrategia', 'label': result[3], 'color': '#34c38f', 'naolidos': 0, 'eventos': [3, 4, 5] },
					{ 'id': 'execucaoEstrategia', 'label': result[4], 'color': '#25a14b', 'naolidos': 0, 'eventos': [6, 7, 8, 9, 10] },
					{ 'id': 'lista', 'label': result[5], 'color': '#556ee6', 'naolidos': 0, 'eventos': [11, 12, 13] },
					{ 'id': 'indice', 'label': result[6], 'color': '#6f41da', 'naolidos': 0, 'eventos': [14] },
					{ 'id': 'templateImportacao', 'label': result[7], 'color': '#f8ad2c', 'naolidos': 0, 'eventos': [15, 16] }
				];
			});
	}

	obterEventosTipo() {
		return observableCombineLatest(
			[
				this.translateService.get("telaNNotificacao.eventos.enum.bancoDadosOffLine"),
				this.translateService.get("telaNNotificacao.eventos.enum.downloadRegistro"),
				this.translateService.get("telaNNotificacao.eventos.enum.estrategiaCriada"),
				this.translateService.get("telaNNotificacao.eventos.enum.estrategiaAlterada"),
				this.translateService.get("telaNNotificacao.eventos.enum.filtroAlterado"),
				this.translateService.get("telaNNotificacao.eventos.enum.estrategiaIniciada"),
				this.translateService.get("telaNNotificacao.eventos.enum.estrategiaFinalizada"),
				this.translateService.get("telaNNotificacao.eventos.enum.estrategiaCancelada"),
				this.translateService.get("telaNNotificacao.eventos.enum.estrategiaErro"),
				this.translateService.get("telaNNotificacao.eventos.enum.estrategiaZerada"),
				this.translateService.get("telaNNotificacao.eventos.enum.listaCriada"),
				this.translateService.get("telaNNotificacao.eventos.enum.listaAlterada"),
				this.translateService.get("telaNNotificacao.eventos.enum.listaLimpa"),
				this.translateService.get("telaNNotificacao.eventos.enum.saudeIndice"),
				this.translateService.get("telaNNotificacao.eventos.enum.templateImportacaoCriado"),
				this.translateService.get("telaNNotificacao.eventos.enum.templateImportacaoAlterado")
			])
			.toPromise()
			.then(result => {

				return [
					//{ 'value': 1, 'label': result[0] },
					//{ 'value': 2, 'label': result[1] },
					{ 'value': 3, 'label': result[2] },
					{ 'value': 4, 'label': result[3] },
					{ 'value': 5, 'label': result[4] },
					{ 'value': 6, 'label': result[5] },
					{ 'value': 7, 'label': result[6] },
					{ 'value': 8, 'label': result[7] },
					{ 'value': 9, 'label': result[8] },
					{ 'value': 10, 'label': result[9] },
					{ 'value': 11, 'label': result[10] },
					{ 'value': 12, 'label': result[11] },
					{ 'value': 13, 'label': result[12] },
					//{ 'value': 14, 'label': result[13] },
					{ 'value': 15, 'label': result[14] },
					{ 'value': 16, 'label': result[15] }
				];
			});
	}

	obterEnviosTipo() {
		return observableCombineLatest(
			[
				this.translateService.get("telaNNotificacao.enviosTipo.api"),
				this.translateService.get("telaNNotificacao.enviosTipo.email"),
				this.translateService.get("telaNNotificacao.enviosTipo.rabbitMQ"),
				this.translateService.get("telaNNotificacao.enviosTipo.whatsapp"),
				this.translateService.get("telaNNotificacao.enviosTipo.telegram"),
				this.translateService.get("telaNNotificacao.enviosTipo.teams"),
				this.translateService.get("telaNNotificacao.enviosTipo.slack")
			])
			.toPromise()
			.then(result => {
				return [
					{ 'value': 1, 'label': result[0] },
					{ 'value': 2, 'label': result[1] },
					{ 'value': 3, 'label': result[2] },
					{ 'value': 4, 'label': result[3] },
					{ 'value': 5, 'label': result[4] },
					{ 'value': 6, 'label': result[5] },
					{ 'value': 7, 'label': result[6] }
				];
			});
	}

	//#endregion

}