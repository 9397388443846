import { NgModule } from '@angular/core';
import { EChartsDirective } from './echarts.directive';
@NgModule({
  imports: [],
  declarations: [
    EChartsDirective
  ],
  exports: [
    EChartsDirective
  ]
})
export class SharedModule {}
