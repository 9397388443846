import { isNull, isNullOrEmpty, isObjectEmpty } from "../utils";
import { NNotificacaoTipoEnvio } from "./nnotificacao-tipo-envio";
import { NNotificacaoTipoEvento } from "./nnotificacao-tipo-evento";

const validate = require("validate.js");

export class NNotificacaoHistorico {
    id: string;
    notificacaoId: number;
    notificacaoTipoEventoId: NNotificacaoTipoEvento;
    notificacaoTipoEnvioId: NNotificacaoTipoEnvio;
    notificacaoSnapshot: any;
    dataLeitura: Date | null;
    dataCadastro: Date;
    data: Date;

    public mensagem: string;
    private _campos: any;



    public get dataEvento(): any {
        if (this.mostrarCampos('data'))
            return this.notificacaoSnapshot.Parametros.Data;

        return {};
    }

    public get ordenacao(): any {
        if (this.mostrarCampos('ordenacao'))
            return this.notificacaoSnapshot.Parametros.Ordenacao;

        return {};
    }

    public get urlEnvio(): any {
        if (this.mostrarCampos('urlEnvio'))
            return this.notificacaoSnapshot.UrlEnvio;

        return '';
    }

    public get raw(): any {
        if (this.mostrarCampos('raw'))
            return this.notificacaoSnapshot.Raw;

        return {};
    }

    public get naolida(): boolean {
        return (isNull(this.dataLeitura));
    }

    constructor() {
        this.camposPorHistorico();
    }

    static fromRaw(raw: any): NNotificacaoHistorico {
        let model: NNotificacaoHistorico = Object.assign(new NNotificacaoHistorico(), raw);

        if (!isNullOrEmpty(raw.notificacaoSnapshot))
            model.notificacaoSnapshot = JSON.parse(raw.notificacaoSnapshot);

        model.camposPorHistorico();

        return model;

    }

    mostrarCampos(campo: string | null): boolean {

        if (isNull(this._campos))
            return false;

        let mostrar = Object.entries(this._campos)
            .filter(([key, value]) => value) // Filtra pares chave-valor onde o valor é true
            .reduce((acc, [key, value]) => {
                acc[key] = value; // Adiciona ao objeto acumulador apenas as propriedades true
                return acc;
            }, {});

        if (isNullOrEmpty(campo))
            return (isObjectEmpty(mostrar)) ? false : true;
        else
            return (isObjectEmpty(mostrar)) ? false : Object.keys(mostrar).some((f: string) => f == campo);
    }

    camposPorHistorico() {
        switch (this.notificacaoTipoEnvioId) {
            case NNotificacaoTipoEnvio.Api:
                this._campos = { "data": true, "mensagem": true, "urlEnvio": true, "destinatarios": false, "fila": false, "raw": true, "ordenacao": false };
                break;
            case NNotificacaoTipoEnvio.Email:
                this._campos = { "data": true, "mensagem": true, "urlEnvio": false, "destinatarios": true, "fila": false, "raw": false, "ordenacao": false }
                break;
            case NNotificacaoTipoEnvio.RabbitMQ:
                this._campos = { "data": true, "mensagem": true, "urlEnvio": false, "destinatarios": false, "fila": true, "raw": true, "ordenacao": false }
                break;
            case NNotificacaoTipoEnvio.WhatsApp:
                this._campos = { "data": true, "mensagem": true, "urlEnvio": false, "destinatarios": true, "fila": false, "raw": false, "ordenacao": false }
                break;
            case NNotificacaoTipoEnvio.Teams:
            case NNotificacaoTipoEnvio.Telegram:
            case NNotificacaoTipoEnvio.Slack:
            default:
                this._campos = { "data": false, "mensagem": false, "ordenacao*": false, "urlEnvio": false, "contatos": false, "fila": false, "raw": false };
                break;
        }

        this._campos.ordenacao = (this.notificacaoTipoEventoId == NNotificacaoTipoEvento.EstrategiaIniciada);
    }

}