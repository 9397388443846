import { GuidService } from "../service/guid.service";
import { isNull, isNullOrEmpty, isObjectEmpty } from "../utils";
import { addCustomValidateChildrenArray, addCustomValidateDependsAnotherFieldValue } from "../validators";
import { NNotificacaoConexaoApi } from "./nnotificacao-conexao-api";
import { NNotificacaoConexaoWhatsapp } from "./nnotificacao-conexao-whatsapp";
import { NNotificacaoTipoEnvio } from "./nnotificacao-tipo-envio";
import { UsuarioSimples } from "./usuario";

const validate = require("validate.js");

export class NNotificacaoConexao {
    id: number;
    notificacaoTipoEnvioId: NNotificacaoTipoEnvio;
    nome: string;
    configuracaoJson: string;
    dataCadastro: Date;
    usuarioCadastroId: number;
    dataAlteracao: Date | null;
    usuarioAlteracaoId: number | null;
    ativo: boolean;

    usuarioCadastro: UsuarioSimples;
    usuarioAlteracao: UsuarioSimples | null;

    configuracaoApi: NNotificacaoConexaoApi | null;
    configuracaoWhatsapp: NNotificacaoConexaoWhatsapp | null;

    // Usado pela tela
    whatsappConectado:boolean;

    constructor() {
        addCustomValidateChildrenArray(validate);
        addCustomValidateDependsAnotherFieldValue(validate);
    }

    //#region   

    static fromRaw(raw: any): NNotificacaoConexao {
        let model: NNotificacaoConexao = Object.assign(new NNotificacaoConexao(), raw);

        if (!isNull(raw.usuarioCadastro))
            model.usuarioCadastro = UsuarioSimples.fromRaw(raw.usuarioCadastro);

        if (!isNull(raw.usuarioAlteracao))
            model.usuarioAlteracao = UsuarioSimples.fromRaw(raw.usuarioAlteracao);

        return model;
    }

    //#endregion  

    validate() {

        let rules: any = {
            'nome': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            }            
        };

        return validate.validate(this, rules);
    }

}
