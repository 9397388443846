import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import * as dayjs from 'dayjs';

const momentElasticMap = {
    'm': 'minute',
    'H': 'hour',
    'd': "day",
    'w': "week",
    'M': "month",
    'y': "year"
};

@Component({
    selector: "app-date-math-filter",
    styleUrls: ["./date-math-filter.component.scss"],
    templateUrl: "./date-math-filter.component.html"
})
export class DateMathFilterComponent implements OnInit {
    @Input("model")
    model;
    @Output("modelChange")
    modelChange: EventEmitter<any>;

    @Input("title")
    title: string;

    tipoData: string = null;
    _valorAlteracao: number = 0;

    public get valorAlteracao() {
        return this._valorAlteracao;
    }

    public set valorAlteracao(val) {
        this._valorAlteracao = val;
        this.emitChange();
    }

    public get displayDate(): Date {
        if (!this.tipoData || this.tipoData == "0" || !this.valorAlteracao) return new Date();
        return dayjs()
            .add(this.valorAlteracao, momentElasticMap[this.tipoData])
            .toDate();
    }

    public get elasticSearchData() {
        if (this.tipoData == "0" || !this.valorAlteracao) return `now`;
        if (this.tipoData == null) return;

        if (this.tipoData == "H" && this.valorAlteracao > 0) {
            let v = this.valorAlteracao - 3;

            if (v > 0)
                return `now+${v}${this.tipoData}`;

            if (v < 0)
                return `now${v}${this.tipoData}`;
        }

        if (this.tipoData == "H" && this.valorAlteracao < 0) {
            let v = this.valorAlteracao - 3;
            return `now${v}${this.tipoData}`;
        }

        if (this.tipoData == "m" && this.valorAlteracao > 0) {
            let v = this.valorAlteracao - 180;

            if (v > 0)
                return `now+${v}${this.tipoData}`;

            if (v < 0)
                return `now${v}${this.tipoData}`;
        }

        if (this.tipoData == "m" && this.valorAlteracao < 0) {
            let v = this.valorAlteracao - 180;
            return `now${v}${this.tipoData}`;
        }


        if (this.valorAlteracao > 0)
            return `now+${this.valorAlteracao}${this.tipoData}`;
            
        return `now${this.valorAlteracao}${this.tipoData}`;
    }

    constructor() {
        this.modelChange = new EventEmitter();
    }

    emitChange() {
        this.modelChange.emit(this.elasticSearchData);
    }

    ngOnInit() {
        if (this.model == "now") {
            this.tipoData = "0";
            let valorAlteracao = 0;

        } else if (this.model && this.model.startsWith("now") && !this.tipoData) {
            this.tipoData = this.model.substring(this.model.length - 1);

            let valorAlteracao: number = 0;

            if (this.tipoData == "H")
                valorAlteracao = parseInt(this.model.substring(3, this.model.length - 1)) + 3;
            else if (this.tipoData == "m")
                valorAlteracao = parseInt(this.model.substring(3, this.model.length - 1)) + 180;
            else
                valorAlteracao = parseInt(this.model.substring(3, this.model.length - 1));

            this.valorAlteracao = isNaN(valorAlteracao) ? 0 : valorAlteracao;
        }
    }

    ngOnChanges() {

    }

    add() {
        if (this.valorAlteracao == undefined) this.valorAlteracao = 0;
        this.valorAlteracao++;
    }

    remove() {
        if (this.valorAlteracao == undefined) this.valorAlteracao = 0;
        this.valorAlteracao--;
    }
}
