export class NNotificacaoLista {

    id: number;
    notificacaoId: number;
    listaId: number;

    constructor() { }

    static fromRaw(raw: any): NNotificacaoLista {
        let model: NNotificacaoLista = Object.assign(new NNotificacaoLista(), raw);
        return model;
    }
}