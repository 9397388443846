import { BaseElasticsearchFilter } from "./base";

export class WildcardElasticsearchFilter extends BaseElasticsearchFilter {
  protected getInstance(
    queryType: string,
    propertyName: string,
    parameters: any,
    extra?: any,
    keyword?: boolean
  ): BaseElasticsearchFilter {
    return new WildcardElasticsearchFilter(propertyName, queryType, parameters, extra, keyword);
  }

  public friendlyParameters(datatype:string, queryTypes) {
    return  this.negation ? `Não possui valor`: `Possui valor`;
  }

  getConvertedInlinedContains() {
    return {
      [this.queryType]: {
        [this.property]: "*"
      }
    };
  }

  convertFilter(): any {
    return {
        [this.queryType]: {
          [this.property]: "*"
        }
      }
  }

  static fromRaw(raw): WildcardElasticsearchFilter {
		return Object.assign(new WildcardElasticsearchFilter(), raw);
	}
}
