import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { GuidService } from "../../../common/service/guid.service";
import { ConfiguracaoConversaoProvedor, ConversaoProvedorOperador } from "../../../common/model/configuracao-conversao-provedor";
import * as validate from "validate.js";
import { TranslateService } from "@ngx-translate/core";
import { ListaCampoValidacao } from "../../../common/model/lista-campo-validacao";
import { isNull, isNullOrEmpty, isNullOrZero, isObjectEmpty } from "../../../common/utils";
import { deepCopy } from "../../../common/utils/deepCopy";
import { CampoIntegracao } from "../../../common/model/campo-integracao";
import { ConfiguracaoCampoProvider } from "../../../common/model/configuracao-campo-provedor";
import { TemplateItemListaValor } from "../../../common/model/template-item-lista-valor";

@Component({
    selector: "app-config-conversao-provedor-modal",
    templateUrl: "./config-conversao-provedor-modal.component.html",
    styleUrls: ["./config-conversao-provedor-modal.component.scss"],
    providers: [{ useClass: CustomAlertService, provide: CustomAlertService }]
})
export class ConfigConversaoProvedorModalComponent {

    submited: boolean;
    campo: ConfiguracaoCampoProvider;
    camposTipo: Array<any> = [];
    configuracoes: Array<ConfiguracaoConversaoProvedor> = [];

    public get isEdit(): boolean {
        return (!isNullOrZero(this.campo.configuracaoCampoProviderId));
    }

    public get campoNumber(): boolean {
        return ([9, 16, 17].some(s => s == this.campo.listaCampoTipoId));
    }

    public get campoData(): boolean {
        return (this.campo.listaCampoTipoId == 10);
    }

    public get campoTexto(): boolean {
        return (this.campo.listaCampoTipoId == 8);
    }

    public get campoLista(): boolean {
        return (this.campo.listaCampoTipoId == 11);
    }

    public get campoBoolean(): boolean {
        return (this.campo.listaCampoTipoId == 12);
    }

    public get campoTipoDescricao(): string {
        if (!isNull(this.camposTipo)) {
            let campo = this.camposTipo.find((f: any) => f.value == this.campo.listaCampoTipoId);

            if (!isNull(campo))
                return campo.label;
        }
        return '';
    }

    public get exibirBtnAdd(): boolean {
        return (this.campoLista || this.configuracoes.filter((s: ConfiguracaoConversaoProvedor) => s.ativo).length <= 0);
    }

    public get listaOperadores(): Array<any> {
        switch (this.campo.listaCampoTipoId) {
            case 8: case 11: case 12:
                return [{ 'id': '1', 'label': this.translate.instant("esbuilder.igual") }];
            case 9: case 10: case 16: case 17:
                return [{ 'id': '1', 'label': this.translate.instant("esbuilder.igual")  }, { 'id': '2', 'label': this.translate.instant("esbuilder.entre")  }, { 'id': '3', 'label': this.translate.instant("esbuilder.maior_que")  }, { 'id': '4', 'label': this.translate.instant("esbuilder.menor_que")  }];
        }
    }

    public get listaPossiveisValores(): Array<any> {
        return this.campo.templateItemListaValor.filter((s: TemplateItemListaValor) => s.ativo).map((m: any) => ({ 'id': m.valorSaida, 'label': m.valorSaida }))
    }

    public get listaValoresBoolean(): Array<any> {
        return [{ 'id': 'false', 'label':this.translate.instant('telaPadrao.nao') }, { 'id': 'true', 'label': this.translate.instant('telaPadrao.sim') }];
    }

    constructor(
        private ref: MatDialogRef<ConfigConversaoProvedorModalComponent>,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA)
        public data: { campo: ConfiguracaoCampoProvider, camposTipo: Array<any>, configuracoes: Array<ConfiguracaoConversaoProvedor> }
    ) {
        if (data.campo) {
            this.campo = data.campo;

            if (!isNullOrEmpty(this.campo.listaCampoTipoId) && typeof (this.campo.listaCampoTipoId) == "string")
                this.campo.listaCampoTipoId = Number(this.campo.listaCampoTipoId);
        }

        if (data.camposTipo)
            this.camposTipo = data.camposTipo;

        if (data.configuracoes && data.configuracoes.length > 0) {
            this.configuracoes = deepCopy(data.configuracoes).map((m: any) => {
                let c = ConfiguracaoConversaoProvedor.fromRaw(m);

                if (this.campoLista)
                    c.ehPossivelValor = this.campo.templateItemListaValor.some((s: TemplateItemListaValor) => s.valorSaida.toLowerCase() == c.valor.toLowerCase());

                return c;
            });
        } else {
            this.addConfiguracao();
        }
    }

    addConfiguracao() {
        let config = new ConfiguracaoConversaoProvedor(this.campo.configuracaoCampoProviderId, '', ConversaoProvedorOperador.Igual, true);
        config.ehPossivelValor = (this.campoLista);

        if (this.campoLista && this.configuracoes.length < 1 && this.listaPossiveisValores.length > 0)
            config.valor = this.listaPossiveisValores[0].id;

        this.configuracoes.push(config);
    }

    excluirConfiguracao(config: ConfiguracaoConversaoProvedor) {

        if (isNullOrZero(config.configuracaoConversaoProvedorId)) {
            this.configuracoes = this.configuracoes.filter((f: ConfiguracaoConversaoProvedor, idx: number) => this.configuracoes.indexOf(config) != idx);
        } else {
            this.configuracoes = this.configuracoes.map((f: ConfiguracaoConversaoProvedor, idx: number) => {
                if (f.configuracaoConversaoProvedorId == config.configuracaoConversaoProvedorId)
                    f.ativo = false;
                return f;
            });
        }
    }

    operadorChange(config: ConfiguracaoConversaoProvedor, $event: any) {
        config.valor = '';
        config.valor2 = '';
    }

    ehPossivelValorChange(config: ConfiguracaoConversaoProvedor, $event: any) {
        config.valor = '';
        config.valor2 = '';
    }

    save() {

        this.submited = true;

        if (this.validadeDisable()) {
            this.customAlertService.show("configConversaoProvedorModal.titulo", "telaPadrao.camposInvalidos", "error");
            return;
        };

        let configuracoes = this.configuracoes.filter((f: ConfiguracaoConversaoProvedor) => f.ativo).reduce((a, e) => {
            a[`${e.conversaoProvedorOperadorId}_${e.valor}`.toLowerCase()] = ++a[`${e.conversaoProvedorOperadorId}_${e.valor}`.toLowerCase()] || 0;
            return a;
        }, {});

        let duplicados = this.configuracoes.filter(e => configuracoes[`${e.conversaoProvedorOperadorId}_${e.valor}`.toLowerCase()]);

        if (duplicados.length > 0) {
            let d = duplicados.map((m: any) => m.valor).filter((value, index, self) => self.indexOf(value) === index);
            let msg = (d.length == 1)
                ? this.translate.instant("configConversaoProvedorModal.campoRepetido", { 'campo': d[0] })
                : this.translate.instant("configConversaoProvedorModal.camposRepetidos", { 'campo': d.join(', ').replace(/,\s([^,]+)$/, ' e $1') });

            this.customAlertService.show("telaPadrao.validacao", msg, "error");
            return;
        }

        this.data.configuracoes = this.configuracoes;

        return this.ref.close(this.configuracoes);
    }

    //#region [ Validação ]

    public get error() {
        if (this.submited)
            return this.validate();
    }

    public liveError(property: string) {
        if (this.submited) {
            let validationResult = this.validate();

            if (!validationResult)
                return null;

            return validationResult[property] ? validationResult[property][0] : null;
        }
    }

    public liveErrorConfiguracoes(config: ConfiguracaoConversaoProvedor, property: string) {
        let validationResult = this.validate();

        if (!validationResult)
            return null;

        let index = this.configuracoes.indexOf(config);

        if (validationResult["configuracoes"][0]["errors"][index] === undefined)
            return null;

        return validationResult["configuracoes"][0]["errors"][index]["error"][property] ? validationResult["configuracoes"][0]["errors"][index]["error"][property] : null;
    }

    public strTooltipErroConfiguracoes(config: ConfiguracaoConversaoProvedor, property: string) {
        let erro: Array<string> = this.liveErrorConfiguracoes(config, property);

        if (isNullOrEmpty(erro) || isObjectEmpty(erro))
            return "";

        if (erro.some((s: string) => s == 'obrigatorio'))
            return this.translate.instant("validacao.campoObrigatorio")
        else if (erro.some((s: string) => s == 'valorMenorQue'))
            return this.translate.instant("configConversaoProvedorModal.valor2MaiorQueValor")

        else if (erro.some((s: string) => s == 'dataMenorQue'))
            return this.translate.instant("configConversaoProvedorModal.dataFinalMaiorQueDataInicial")

        else
            return "";

    }

    public validadeDisable() {
        let validationResult = this.validate();

        if (!validationResult)
            return false;

        let ok = false;

        Object.keys(validationResult["configuracoes"][0]["errors"]).forEach((f: any) => {
            if (validationResult["configuracoes"][0]["errors"][f] !== undefined)
                ok = true;
        });

        return ok;
    }

    public validate() {

        validate.validators.dataMenorQue = (value: any, options: any, key: any, attributes: any) => {
            if (value <= attributes[options.campo])
                return "^dataMenorQue";
        };

        validate.validators.valorMenorQue = (value: any, options: any, key: any, attributes: any) => {
            if (Number(value) <= Number(attributes[options.campo]))
                return "^valorMenorQue";
        };

        validate.validators.array = (arrayItems: Array<any>, itemConstraints: any) => {
            const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
                if (item.conversaoProvedorOperadorId == 2) {
                    if ([9, 16, 17].some(s => s == item.listaCampoTipoId)) {

                        itemConstraints = Object.assign(itemConstraints, {
                            valor2: {
                                presence: { allowEmpty: false, message: "^obrigatorio" },
                                valorMenorQue: { campo: "valor" }
                            }
                        });
                    } else if ([10].some(s => s == item.listaCampoTipoId)) {
                        itemConstraints = Object.assign(itemConstraints, {
                            valor2: {
                                presence: { allowEmpty: false, message: "^obrigatorio" },
                                dataMenorQue: { campo: "valor" }
                            }
                        });
                    }
                }

                const error = validate(item, itemConstraints);
                if (error) errors[index] = { error: error };
                return errors;
            }, {});

            return isNull(arrayItemErrors) ? null : { errors: arrayItemErrors };
        };

        let regras = {
            configuracoes: {
                array: {
                    conversaoProvedorOperadorId: { presence: { allowEmpty: false, message: "^obrigatorio" } },
                    valor: { presence: { allowEmpty: false, message: "^obrigatorio" } }
                }
            }
        };

        let c = this.configuracoes.map((m: any) => ({ ...m, ...{ 'listaCampoTipoId': this.campo.listaCampoTipoId } }));

        return validate.validate({ 'configuracoes': c }, regras);
    }

    //#endregion

    close() {
        this.ref.close();
    }
}
