import { Component, Inject, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatSlideToggleChange } from "@angular/material";

import { EstrategiaService } from "../../../common/service/estrategia.service";
import { Estrategia } from "../../../common/model/estrategia";
import { environment } from "../../../../../environments/environment";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { isNullOrEmpty } from "../../../common/utils";
import { ModelToQuery, QueryToModel } from "../../../esbuilder/libs/utils";
import { TemplateService } from "../../../common/service/template.service";
import { MigracaoEstrategiaModel, MigracaoEstrategiaProgressoMigracao, MigracaoEstrategiaQuantidadesModel, MigracaoEstrategiaRegistroStatus } from "./migracao-estrategia.model";
import { generateCanvas } from "../../../common/utils/generateCanvas";

@Component({
	selector: "app-migracao-estrategia",
	templateUrl: "./migracao-estrategia.component.html",
	styleUrls: ["./migracao-estrategia.component.scss"],
	providers: [
		{ useClass: EstrategiaService, provide: EstrategiaService },
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class MigracaoEstrategiaComponent {
	AppConfig: any;
	progressoMigracao: MigracaoEstrategiaProgressoMigracao;
	quantidades: MigracaoEstrategiaQuantidadesModel;

	buscaEstrategiaNome: string;
	buscaEstrategiaTotal: number;

	migracaoEstrategiaNome: string;
	migracaoEstrategiaStatus: string;

	porcentagem: number;

	carteiraId: number;
	estrategias: Array<MigracaoEstrategiaModel> = [];

	public get desabilitarFechar(): boolean {
		if (isNullOrEmpty(this.dialogRef))
			return false;

		return this.dialogRef.disableClose as boolean;
	}

	public get estrategiasGrid(): Array<MigracaoEstrategiaModel> {
		return this.estrategias.filter((f: MigracaoEstrategiaModel) => f.status != MigracaoEstrategiaRegistroStatus.encontrado);
	}

	public ctxAtivas: CanvasRenderingContext2D | null | undefined;
	public ctxInativas: CanvasRenderingContext2D | null | undefined;
	public ctxMigradas: CanvasRenderingContext2D | null | undefined;
	public ctxNaoMigradas: CanvasRenderingContext2D | null | undefined;

	@ViewChild('canvasAtivas', { static: true }) public canvasAtivas: ElementRef<HTMLCanvasElement>;
	@ViewChild('canvasInativas', { static: true }) public canvasInativas: ElementRef<HTMLCanvasElement>;
	@ViewChild('canvasMigradas', { static: true }) public canvasMigradas: ElementRef<HTMLCanvasElement>;
	@ViewChild('canvasNaoMigradas', { static: true }) public canvasNaoMigradas: ElementRef<HTMLCanvasElement>;

	constructor(
		public estrategiaService: EstrategiaService,
		private templateService: TemplateService,
		public dialogRef: MatDialogRef<MigracaoEstrategiaComponent>,
		private customAlertService: CustomAlertService,
		@Inject(MAT_DIALOG_DATA)
		public data: { carteiraId: number }
	) {
		this.dialogRef.disableClose = false;
		this.progressoMigracao = MigracaoEstrategiaProgressoMigracao.inicial;
		this.porcentagem = 0;
		this.quantidades = new MigracaoEstrategiaQuantidadesModel();

		this.ctxAtivas = generateCanvas(this.canvasAtivas, 0, '#66BB6A');
		this.ctxInativas = generateCanvas(this.canvasInativas, 0, '#d13d3d');
		this.ctxMigradas = generateCanvas(this.canvasMigradas, 0, '#5665d1');
		this.ctxNaoMigradas = generateCanvas(this.canvasNaoMigradas, 0, '#e79d42');

		this.carteiraId = data.carteiraId;

		this.estrategiaService
			.migracaoObterQuantidadeEstrategias(this.carteiraId)
			.subscribe((quantidades) => {
				this.quantidades = quantidades;

				let pctAtivas = Math.round((this.quantidades.ativas * 100) / this.quantidades.total);
				let pctInativas = Math.round((this.quantidades.inativas * 100) / this.quantidades.total);
				let pctMigradas = Math.round((this.quantidades.migradas * 100) / this.quantidades.total);
				let pctNaoMigradas = Math.round((this.quantidades.naoMigradas * 100) / this.quantidades.total);

				this.ctxAtivas = generateCanvas(this.canvasAtivas, pctAtivas, '#66BB6A');
				this.ctxInativas = generateCanvas(this.canvasInativas, pctInativas, '#d13d3d');
				this.ctxMigradas = generateCanvas(this.canvasMigradas, pctMigradas, '#5665d1');
				this.ctxNaoMigradas = generateCanvas(this.canvasNaoMigradas, pctNaoMigradas, '#e79d42');
			});
	}

	processarMigracaoEstrategias() {

		// this.templateService.obterCamposMapeados(247, 147).subscribe((result: any) => {
		// 		console.log(result);
		// 		console.log(JSON.stringify(result));
		// });

		this.customAlertService
			.confirmationMessage("telaEstrategia.confirmacaoMigracao")
			.then(() => {

				// Desabilitar o fechar do modal
				this.dialogRef.disableClose = true;

				// Buscar estratégias para migrar
				this.progressoMigracao = MigracaoEstrategiaProgressoMigracao.busca;

				this.estrategiaService
					.migracaoObterEstrategias(this.carteiraId)
					.subscribe((estrategias) => {

						this.contarEstrategias(estrategias)
							.then(() => {
								this.progressoMigracao = MigracaoEstrategiaProgressoMigracao.conversao;
								this.porcentagem = 0

								this.migrarEstrategias()
									.then(() => {

										this.progressoMigracao = MigracaoEstrategiaProgressoMigracao.gravacao;

										// Habilitar o fechar do modal
										this.dialogRef.disableClose = false;
									});
							});
					});
			});
	}

	async contarEstrategias(estrategias: Array<any>) {
		this.estrategias = estrategias;
		this.buscaEstrategiaTotal = 0;

		for (let i = 0; i < this.estrategias.length; i++) {
			this.buscaEstrategiaNome = `${this.estrategias[i].estrategiaId} - ${this.estrategias[i].estrategiaNome}`;
			this.buscaEstrategiaTotal++;
			const x = await new Promise(r => setTimeout(r, 1000, i))
		}

		return 'done'
	}

	async migrarEstrategias() {
		
		for (let i = 0; i < this.estrategias.length; i++) {
			try {
				let e = this.estrategias[i];
				let camposMapeados = e.camposMapeados.filter((f: any) => f.itemTipo != 2);
				let dataRules = QueryToModel.convert(e.estrategiaFiltro, camposMapeados, e.estrategiaFiltroRegras);
				this.estrategias[i].estrategiaFiltro = JSON.stringify(ModelToQuery.convert(dataRules));
				this.estrategias[i].estrategiaFiltroRegras = JSON.stringify(dataRules);

				this.migracaoEstrategiaNome = `${this.estrategias[i].estrategiaId} - ${this.estrategias[i].estrategiaNome}`;
				this.migracaoEstrategiaStatus = 'Convertendo';

				const x = await new Promise(r => setTimeout(r, 1000, i))

				this.estrategias[i].status = MigracaoEstrategiaRegistroStatus.convertido;
				this.migracaoEstrategiaStatus = 'Convertida';
				this.porcentagem = Math.round(((i + 1) * 100) / this.estrategias.length);

				this.migracaoSalvarEstrategias(this.estrategias[i]);
			}
			catch (error) {
				this.estrategias[i].status = MigracaoEstrategiaRegistroStatus.erroConversao;
			}
		}

		return 'done'
	}

	migracaoSalvarEstrategias(estrategia: MigracaoEstrategiaModel) {
		estrategia.status = MigracaoEstrategiaRegistroStatus.gravacao;

		this.estrategiaService
			.migracaoSalvarEstrategias(estrategia)
			.subscribe(
				(result: any) => (estrategia.status = MigracaoEstrategiaRegistroStatus.sucesso),
				(erro: any) => (estrategia.status = MigracaoEstrategiaRegistroStatus.erroGravacao)
			);
	}

	fechar() {
		this.dialogRef.close();
	}
}
