import { deepCopy } from "../../common/utils/deepCopy";
import * as dayjs from 'dayjs';
const validate = require("validate.js");

export class Configuracao {
    configuracaoId: number;
    grupo: string;
    chave: string;
    valor: string;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;
    historicoInicio: Date;
    historicoFim: Date;
    configuracaoTipoId: ConfiguracaoTipo;
    valorAnterior: string;
    alterado: boolean;

    public get valorAlterado(): boolean {
        this.alterado = this.valor != this.valorAnterior;
        return this.alterado;
    }

    
    constructor() {
    }

    undo(): void {
        if (this.valorAlterado == false)
            return;

        this.valor = deepCopy(this.valorAnterior)
    }

    valorBooleanChange(e: any): void {
        this.valor = e.checked.toString().toLowerCase();
    }

    valorDateChange(e: any): void {
        this.valor = dayjs(e.value).format("YYYY-MM-DD");
    }

    validar() {
        let regras: any = {
            valor: { presence: { allowEmpty: false, message: '^campoObrigatorio' } }
        };

        let validacao = validate.validate(this, regras);
        
        return validacao;
    }

    static fromRaw(raw: any): Configuracao {
        let obj: Configuracao = Object.assign(new Configuracao(), raw);
        obj.valorAnterior = deepCopy(obj.valor);
        return obj;
    }
}

export enum ConfiguracaoTipo {
    texto = 1,
    numero = 2,
    data = 3,
    boolean = 4
}