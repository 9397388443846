import { Pipe, PipeTransform } from "@angular/core";
import { ArquivoStatus } from "../../common/model/arquivo-status";
import { ItemTipo } from "../../common/model/lista-template-item";

/* TODO: A descrição do status vai vir do back-end nao precisando mais desse pipe */

@Pipe({
	name: "listaTemplateItemTipoDescricao"
})
export class ListaTemplateItemTipoDescricaoPipe implements PipeTransform {
	transform(value: ItemTipo, args?: any): any {
		switch (value) {
			case ItemTipo.Padrao:
				return "TemplateItemTipoDescrica.padrao";
			case ItemTipo.Linha:
				return "TemplateItemTipoDescrica.linha";
			case ItemTipo.Coluna:
				return "TemplateItemTipoDescrica.coluna";
			case ItemTipo.Score:
				return "TemplateItemTipoDescrica.score";
			case ItemTipo.Sistema:
				return "TemplateItemTipoDescrica.sistema";
			case ItemTipo.Retorno:
				return "TemplateItemTipoDescrica.retorno";
			case ItemTipo.Validacao:
				return "TemplateItemTipoDescrica.validacao";
			case ItemTipo.Tracking:
				return "TemplateItemTipoDescrica.tracking";
			case ItemTipo.Conversao:
				return "TemplateItemTipoDescrica.conversao";
		}
		return "";
	}
}
