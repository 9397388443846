import * as validateJS from "validate.js";

export class NaturalLanguage {

    listId: number;
    condition: string;
    instructionIA: string;
    mappedFields: Array<any>;
    query: string;
    registers: string;

    constructor() { }

    static fromRaw(raw: any) {
        return Object.assign(new NaturalLanguage(), raw);
    }

    validate() {
        let validationRules: any = {
            'listId': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
            'condition': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } }
        };
        return validateJS.validate(this, validationRules);
    }
}