import { validate } from "validate.js";
import { isNullOrZero } from "../utils";

export class FiltroHistoricoRetorno {
    providerId: number;
    processado: boolean;
    listaId: number;
    listaEstrategiaId: number;
    dataInicio: Date;
    dataFim: Date;
    camposValor: Array<FiltroHistoricoRetornoCamposValor>;
    carteiraId: number;

    constructor() {
        this.camposValor = new Array<FiltroHistoricoRetornoCamposValor>()
    }

    validate() {
        let v1: any = {};    
        if ((isNullOrZero(this.providerId) && isNullOrZero(this.listaId)) || this.camposValor.length == 0 ) {
            v1["providerId"] = { presence: { allowEmpty: false, message: "campoObrigatorio" } };
            v1["listaId"] = { presence: { allowEmpty: false, message: "campoObrigatorio" } };
            v1["campoValor"] = { presence: { allowEmpty: false, message: "campoObrigatorio" } };            
        }

        let v2: any = {
            dataInicio:
            {
                presence: { allowEmpty: false, message: "campoObrigatorio" }
            },
            dataFim: {
                presence: { allowEmpty: false, message: "campoObrigatorio" }
            }
        };

        let validacao: any = { ...v1, ...v2 };
        return validate(this, validacao);
    }
}

export class FiltroHistoricoRetornoCamposValor {
    constructor(campo: string, valor: string) {
        this.campo = campo;
        this.valor = valor;
    }

    campo: string;
    valor: string;
}