import { ModelToQuery } from "../../esbuilder/libs/utils";
import { ESBuilderData, ESBuilderRules } from "../../esbuilder/models";
import { isNull, isNullOrEmpty, isNullOrZero, isObjectEmpty } from "../utils";

export class FiltroPrioridadeAgrupado {
    filtroPrioridadePadraoId: number;
    filtroPrioridadeNome: string;
    listaNome: string;

    constructor() {
    }

    static fromRaw(rawFluxo): FiltroPrioridadeAgrupado {
        return Object.assign(new FiltroPrioridadeAgrupado(), rawFluxo);
    }

}
