import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { MatDialog, MatSlideToggleChange } from "@angular/material";
import { ProviderService } from "../../../common/service/provider.service";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { DefaultValuesService } from "../../../common/service/default-values.service";
import { CamposIntegracaoService } from "../../../common/service/campos-integracao.service";
import { Provider } from "../../../common/model/provider";
import { ProvedorConfiguracao, TipoAutenticacao, IntegracaoTipo } from "../../../common/model/provedor-configuracao";
import { ProvedorConfiguracaoTipo, ConfiguracaoCampoProvider, CampoFormato } from "../../../common/model/configuracao-campo-provedor";
import { CampoModalComponent } from "../campo-modal/campo-modal.component";
import { CampanhaDisplayComponent } from "../campanha-display/campanha-display.component";
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ProvedorTesteConexao } from "../../../common/model/provedor-teste-conexao";
import { changeOrder } from "../../../common/model/change-collection-item-order";
import { isNull, isNullOrZero } from "../../../common/utils";
import { TesteEnvioModalComponent } from "./teste-envio-modal/teste-envio-modal.component";

@Component({
	selector: "app-form-provider-envio",
	templateUrl: "./form-provider-envio.component.html",
	styleUrls: ["./form-provider-envio.component.scss"],
	providers: [
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService },
		{ useClass: CamposIntegracaoService, provide: CamposIntegracaoService }
	]
})
export class FormProviderEnvioComponent implements OnInit {
	@ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

	@Input() listaMetodoHttp;
	@Input() listaTipoAutenticacao;
	@Input() listaCampoTipoPrimario;
	@Input() grantTypeCollection;
	@Input() erros;

	public TipoAutenticacao: any = TipoAutenticacao;
	public IntegracaoTipo: any = IntegracaoTipo;
	public editorOptions = new JsonEditorOptions();
	public campoFormatoEnum = CampoFormato;
	public campoJson: any;
	public listaIntegracaoTipo: Array<{ label: string; value: number }> = [];

	static campoArrastado: ConfiguracaoCampoProvider;
	public trackById: any;

	_provider: Provider;

	public get provider() {
		return this._provider;
	}

	@Input()
	public set provider(provider: Provider) {
		if (!provider)
			return;

		this._provider = provider;
		if (this.isEdit) {
			if (this.provider.envio.campoFormatoId == CampoFormato.Raw) {
				this.campoJson = JSON.parse(this.provider.configuracaoCampoEnvioRawAtivo.campoJson);
			}
		}
	}

	public get isEdit(): boolean {
		return this.provider.providerId != undefined;
	}

	public get providerPadrao() {
		return this.provider.providerPadrao;
	}

	constructor(
		public providerService: ProviderService,
		public dialog: MatDialog,
		private customAlertService: CustomAlertService,
		private defaultValuesService: DefaultValuesService) {

		this.defaultValuesService
			.obterIntegracaoTipoEnvio()
			.then(
				listaIntegracaoTipo =>
					(this.listaIntegracaoTipo = listaIntegracaoTipo)
			);
	}

	ngOnInit() {
		this.editor = new JsonEditorComponent;
		this.editorOptions.onChange = () => {
			try {
				this.provider.configuracaoCampoEnvioRawAtivo.campoJson = this.editor.getText();
			} catch { }
		}

		this.editorOptions.mode = 'code';
		this.editorOptions.enableSort = false;
		this.editorOptions.enableTransform = false;
		this.editorOptions.modes = ['code', 'tree'];
	}

	codigoCampanhaExportacaoChange(event: MatSlideToggleChange) {

		let campoTipo: ConfiguracaoCampoProvider;

		if (this.provider.campanha.codigoCampanhaExportacao) {
			campoTipo = new ConfiguracaoCampoProvider(ProvedorConfiguracaoTipo.Envio, CampoFormato.Form);
			campoTipo.nome = 'CodigoCampanha';
			campoTipo.providerId = this.provider.providerId;
			campoTipo.listaCampoTipoId = 8;
			campoTipo.obrigatorio = true;
			campoTipo.ordem = -1;
			campoTipo.campoSistemico = true;
			this.provider.configuracaoCampoProvider.push(campoTipo);
		} else {

			campoTipo = this.provider.configuracaoCampoProvider.find((f: ConfiguracaoCampoProvider) => f.nome == 'CodigoCampanha' && f.campoSistemico && f.ativo);

			if (!isNull(campoTipo)) {
				this.provider.configuracaoCampoProvider.forEach((f: ConfiguracaoCampoProvider) => {
					if (f.nome == 'CodigoCampanha' && f.campoSistemico && f.ativo)
						f.ativo = false;
				});
			}
		}

		this.sortCampos();
	}

	adicionarCampo() {
		var ref = this.dialog.open(CampoModalComponent, {
			width: "700px",
			data: {
				campoTipo: new ConfiguracaoCampoProvider(ProvedorConfiguracaoTipo.Envio, CampoFormato.Form),
				listaCampoTipoPrimario: this.listaCampoTipoPrimario
			}
		});

		ref.afterClosed().subscribe((campoTipo: ConfiguracaoCampoProvider) => {
			if (campoTipo) {
				campoTipo.ordem = (isNull(this.provider.configuracaoCampoEnvioFormAtivo)) ? 0 : Math.max.apply(Math, this.provider.configuracaoCampoEnvioFormAtivo.map((m) => m.ordem)) + 1;
				this.provider.configuracaoCampoProvider.push(campoTipo);
			}
		});
	}

	editarCampo(campo: ConfiguracaoCampoProvider) {
		var ref = this.dialog.open(CampoModalComponent, {
			width: "700px",
			data: {
				campoTipo: Object.assign(new ConfiguracaoCampoProvider(), campo),
				listaCampoTipoPrimario: this.listaCampoTipoPrimario
			}
		});

		ref.afterClosed().subscribe(alteracaoCampoTipo => {
			if (alteracaoCampoTipo)
				Object.assign(campo, alteracaoCampoTipo);
		});
	}

	excluirCampo(campo: ConfiguracaoCampoProvider) {
		campo.ativo = false;
		this.sortCampos();
	}

	obterCampanhasProvider() {
		const requisicao = Object.assign({}, this.provider, { listaTemplate: [] });

		this.providerService.obterCampanhasProvider(requisicao).subscribe(
			collection =>
				this.dialog.open(CampanhaDisplayComponent, {
					width: "500px",
					data: collection
				}),
			() =>
				this.customAlertService.show(
					"telaProvider.provedor",
					"telaProvider.autenticacaoFalha",
					"error"
				)
		);
	}

	campoFormatoRawSelecionado() {
		this.provider.permiteCampoTemplateExportacao = false;
		var campoRaw = this.provider.configuracaoCampoProvider.find(campo => campo.campoFormatoId == CampoFormato.Raw);
		if (campoRaw != null) {
			this.campoJson = campoRaw.ativo == false ? null : JSON.parse(campoRaw.campoJson);
			campoRaw.ativo = true;
		} else {
			this.provider.configuracaoCampoProvider.push(new ConfiguracaoCampoProvider(ProvedorConfiguracaoTipo.Envio, CampoFormato.Raw));
		}
	}

	configuracaoPorCampanhaChange() {
		if (this.provider.configuracaoPorCampanha) {

			this.provider.envio.parametrosUrl = false;

			if (this.provider.campanha == null) {
				this.provider.provedorConfiguracao.push(new ProvedorConfiguracao(ProvedorConfiguracaoTipo.Campanha, true, null));
				this.provider.campanha.integracaoTipoId = IntegracaoTipo.API;
			}
		}
	}

	copiarDadosAutenticacao() {
		this.provider.campanha.tipoAutenticacao = this.provider.envio.tipoAutenticacao;
		this.provider.campanha.caminhoLogin = this.provider.envio.caminhoLogin;
		this.provider.campanha.metodoLogin = this.provider.envio.metodoLogin;
		this.provider.campanha.username = this.provider.envio.username;
		this.provider.campanha.password = this.provider.envio.password;
		this.provider.campanha.clientId = this.provider.envio.clientId;
		this.provider.campanha.clientSecret = this.provider.envio.clientSecret;
		this.provider.campanha.scope = this.provider.envio.scope;
		this.provider.campanha.grantType = this.provider.envio.grantType;
		this.provider.campanha.apiKey = this.provider.envio.apiKey;
		this.provider.campanha.tokenProperty = this.provider.envio.tokenProperty;
		this.provider.campanha.token = this.provider.envio.token;
		this.provider.campanha.campoUsername = this.provider.envio.campoUsername;
		this.provider.campanha.campoPassword = this.provider.envio.campoPassword;
		this.provider.campanha.campoToken = this.provider.envio.campoToken;
	}

	testarConexao() {
		const requisicao = Object.assign(new ProvedorTesteConexao(), this.provider.envio);

		this.providerService.testarConexao(requisicao).subscribe(
			result =>
				this.customAlertService.show(
					"telaProvider.testarConexao",
					result ? "telaPadrao.sucesso" : "telaPadrao.erro",
					result ? "success" : "error"
				),
			() =>
				this.customAlertService.show(
					"telaProvider.testarConexao",
					"telaPadrao.erro",
					"error"
				)
		);
	}

	testarEnvio() {
		let dialogRef = this.dialog.open(TesteEnvioModalComponent, {
			width: "55%",
			data: {
				provedor: this.provider
			}
		});
	}

	obterPosicaoCampo(campo: any) {
		//this.configuracaoCampoProvider.filter(c => c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio && c.campoFormatoId == CampoFormato.Form && c.ativo);
		return this.provider.configuracaoCampoEnvioFormAtivo.indexOf(campo);
	}


	handleDragStart(campo: ConfiguracaoCampoProvider) {
		FormProviderEnvioComponent.campoArrastado = campo;
	}

	handleDragOver(event: Event) {
		event.preventDefault();
		(<Element>event.target).parentElement.classList.add("droppable");
	}

	handleDragLeave(event: Event) {
		event.preventDefault();
		(<Element>event.target).parentElement.classList.remove("droppable");
	}

	handleDrop(event: Event, campo: ConfiguracaoCampoProvider) {
		(<Element>event.target).parentElement.classList.remove("droppable");
		var newOrderCollection = changeOrder(this.provider.configuracaoCampoEnvioFormAtivo, FormProviderEnvioComponent.campoArrastado, campo);
		this.provider.configuracaoCampoEnvioFormAtivo.forEach((ordem: any) => ordem.ordem = newOrderCollection.indexOf(ordem));
		this.sortCampos();
	}

	mudarCampoOrdem(campo: ConfiguracaoCampoProvider, mudanca: number) {
		let posicao = campo.ordem + mudanca;
		let clone = Object.assign({}, campo);
		this.provider.configuracaoCampoEnvioFormAtivo.forEach((campoLista: any) => {
			if (mudanca > 0 && campoLista.ordem <= clone.ordem + 1)
				campoLista.ordem--;

			if (mudanca < 0 && campoLista.ordem >= clone.ordem - 1)
				campoLista.ordem++;
		});
		campo.ordem = posicao;
		this.sortCampos();
	}

	sortCampos() {

		let other = this.provider.configuracaoCampoProvider.filter(c => (c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio && c.campoFormatoId == CampoFormato.Raw)
			|| (c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Retorno && c.campoFormatoId == CampoFormato.Form && c.ativo));

		let newOrderAtivo = this.provider.configuracaoCampoEnvioFormAtivo
			.sort((a: any, b: any) => a.ordem - b.ordem)
			.map((configuracaoCampoProvider: any, index: number) => {
				configuracaoCampoProvider.ordem = index;
				return configuracaoCampoProvider;
			});

		let c = Math.max.apply(Math, newOrderAtivo.map((m) => m.ordem));

		let newOrderInativo = this.provider.configuracaoCampoProvider
			.filter(c => c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio && c.campoFormatoId == CampoFormato.Form && c.ativo == false)
			.sort((a: any, b: any) => a.ordem - b.ordem)
			.map(f => {
				c++;
				f.ordem = c;
				return f;
			});

		this.provider.configuracaoCampoProvider = [...newOrderAtivo, ...newOrderInativo, ...other]
	}

	integracaoTipoChanged() {
		this.provider.envio.limparCamposConfiguracao();
		this.providerService.onSelecionarIntegracaoTipoEnvio();
	}

	metodoIntegracaoTipoChanged() {
		this.provider.envio.parametrosUrl = false;

		//this.provider.campanha.limparCamposConfiguracao();
	}


	parametrosUrlChange() {
		if (this.provider.envio.parametrosUrl) {
			this.provider.configuracaoPorCampanha = false;
		} else {
			let final = this.provider.envio.caminhoIntegracao.indexOf('?');
			if (final >= 0)
				this.provider.envio.caminhoIntegracao = this.provider.envio.caminhoIntegracao.substring(0, final);
		}
	}

	envioRetornoTipoStringChanged() {
		this.provider.envio.parametrosUrl = false;
	}
}