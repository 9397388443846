import { LanguageService } from './../service/language.service';

import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';

import { zip, tap, catchError, switchMap, take, filter, finalize } from 'rxjs/operators';
import { Inject, Injectable, Injector } from '@angular/core';

import { AutenticacaoService } from '../service/autenticacao.service';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpErrorResponse,
	HttpEvent,
	HttpResponse
} from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CustomAlertService } from '../service/custom-alert.service';
import { isNullOrEmpty } from '../utils/isNull';
import { StorageProxy } from '../service/storage-proxy.service';
import { ConfigProvider } from '../../../common/providers/config.provider';
import { Credential } from "../../autenticacao/model/credential";
import { Console } from 'console';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
	isRefreshingToken: boolean = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	counter: number = 0;
	@BlockUI()
	blockUI: NgBlockUI;

	constructor(
		private authService: AutenticacaoService,
		private translateService: TranslateService,
		private router: Router,
		//@Inject(ConfigProvider) protected configProvider: ConfigProvider
		private configProvider: ConfigProvider
	) {
	}

	addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
		return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

		let appConfig = this.configProvider.getConfig();

		let linguagem = StorageProxy.getString('linguagem')

		if (req.url.startsWith('/api/') || req.url.startsWith('/connect/token')) {

			if (req.url.startsWith('/api/')) {
				let language: string = (linguagem === "pt") ? "pt-BR" : linguagem;

				if (!language)
					language = 'pt';

				req = req.clone({ url: `${appConfig.serverUrl}/${language}${req.url}` });

			} else {
				req = req.clone({ url: `${appConfig.serverUrl}${req.url}` });
			}
		}

		if (this.authService.isAutenticadoSemToken()) {
			req = req.clone({ withCredentials: true });
		}


		req = req.clone({headers: req.headers.set('Accept-Language',linguagem)});
		req = req.clone({headers: req.headers.set('Access-Control-Allow-Origin', '*')});

		this.counter++;
	
		if (req.headers.keys().length <= 0 || req.headers.keys().some((s: any) => s == 'blockLoading') == false)
			this.blockUI.start();
		
		if (
			req.url.indexOf(appConfig.serverUrl) < 0 ||
			!this.authService.obterTokenAutenticado() ||
			req.url.indexOf('/connect/token') >= 0 ||
			(req.url.indexOf('/provider/') >= 0 && req.url.indexOf('/campanhas') >= 0)
		) {
			return next
				.handle(req).pipe(
					tap(this.handleResponse.bind(this)),
					catchError((e: Error) => {
						if (e instanceof HttpErrorResponse) {
							if ((e as HttpErrorResponse).status == 401) {
								localStorage.removeItem('currentUser');
								this.router.navigate(['/login']);
							}
						}

						this.handleLoading();
						return observableThrowError(e);
					}));
		}

		return next
			.handle(this.addToken(req, this.authService.obterTokenAutenticado())).pipe(
				tap(this.handleResponse.bind(this)),
				catchError((e: Error) => this.errorHandler(e, req, next)));
	}

	private handleResponse(ev: HttpEvent<any>) {
		if (false === ev instanceof HttpResponse) { return; }

		this.handleLoading();
	}

	private handleLoading() {
		this.counter--;

		if (this.counter === 0) { this.blockUI.stop(); }
	}

	errorHandler(error: Error, req: HttpRequest<any>, next: HttpHandler) {
		this.handleLoading();
		if (error instanceof HttpErrorResponse) {
			switch ((error as HttpErrorResponse).status) {
				case 400:
					return this.handle400Error(error);
				case 401:
					return this.handle401Error(req, next);
				case 403:
					return this.handle403Error(req);
				case 500:
				case 0:
					return this.handle500Error(req.method, error);
				default:
					return observableThrowError(error);
			}
		} else {
			return observableThrowError(error);
		}
	}

	handle403Error(error) {
		if (error.url.indexOf('logout') > 0) {
			this.router.navigate(['/login']);
			return this.logoutUser();
		}
		else {
			return this.router.navigate(["/app/acesso-negado"], {
				queryParams: { rota: error.url }
			});
		}
	}

	handle400Error(error) {
		if (
			error &&
			error.status === 400 &&
			error.error &&
			error.error.error === 'invalid_grant'
		) {
			return this.logoutUser();
		}

		return observableThrowError(error);
	}

	handle401Error(req: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			this.authService
				.refreshToken().pipe(
					switchMap((credential) => {
						if (credential.newToken) {
							this.tokenSubject.next(credential.AccessToken);
							return next.handle(this.addToken(req, credential.AccessToken));
						}
						return this.logoutUser();
					}),
					catchError((_) => this.logoutUser()),
					finalize(() => (this.isRefreshingToken = false)))
				.subscribe();
		} else {
			return this.tokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((token) => next.handle(this.addToken(req, token))));
		}
	}

	handle500Error(method: string, error) {
		const mainMessage =
			method === 'GET'
				? 'errosPadrao.erroRequisicaoGet'
				: 'errosPadrao.erroRequisicao';
		this.translateService
			.get('errosPadrao.erroRequisicaoTitulo').pipe(
				zip(this.translateService.get(mainMessage)))
			.subscribe((data) => swal.fire(data[0], data[1], 'error'));

		return observableThrowError(error);
	}

	logoutUser() {
		this.authService.logoutUser();
		return observableThrowError('');
	}
}
