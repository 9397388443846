import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "./query-string";
import { Observable } from "rxjs";
import { FiltroDashIndividual } from "../../dash/model/filtro-dash-individual";
import { DashIndividualRegistro } from "../../dash/model/dash-individual-registro";
import { DashIndividualLinhaTempo } from "../../dash/model/dash-individual-linha-tempo";
import { StorageProxy } from "./storage-proxy.service";

@Injectable()
export class DashIndividualService {
    private api: string;

    constructor(private httpClient: HttpClient) { 
        this.api = '/api/dashboardindividual';
    }

    public obterRegistros(filtro: FiltroDashIndividual) {
        return this.httpClient.get<Array<DashIndividualRegistro>>(
            `${this.api}/registros-por-campo${convertToQueryString(filtro)}`
        );
    }

    public obterLinhaTempo(filtro: FiltroDashIndividual) {
        return this.httpClient.get<Array<DashIndividualLinhaTempo>>(
            `${this.api}/linha-tempo${convertToQueryString(filtro)}`
        );
    }
}