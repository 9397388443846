import { isNull } from "../utils";
import { addCustomValidateCelular, addCustomValidateChildrenArray, addCustomValidateDependsAnotherFieldValue } from "../validators";
import { NNotificacaoCarteira } from "./nnotificacao-carteira";
import { NNotificacaoDestinatario } from "./nnotificacao-destinatario";
import { NNotificacaoEvento } from "./nnotificacao-evento";
import { NNotificacaoLista } from "./nnotificacao-lista";
import { NNotificacaoListaEstrategia } from "./nnotificacao-lista-estrategia";
import { NNotificacaoTemplateImportacao } from "./nnotificacao-template-importacao";
import { NNotificacaoTipoEnvio } from "./nnotificacao-tipo-envio";
import { UsuarioSimples } from "./usuario";

const validate = require("validate.js");

export class NNotificacao {

    id: number;
    linguagem: string;
    nome: string;
    notificacaoTipoEnvioId: NNotificacaoTipoEnvio;
    dataCadastro: Date;
    usuarioCadastroId: number;
    dataAlteracao: Date | null;
    usuarioAlteracaoId: number | null;
    ativo: boolean;

    usuarioCadastro: UsuarioSimples;
    usuarioAlteracao: UsuarioSimples | null;

    notificacaoCarteiras: Array<NNotificacaoCarteira> = [];
    notificacaoTemplatesImportacao: Array<NNotificacaoTemplateImportacao> = [];
    notificacaoListas: Array<NNotificacaoLista> = [];
    notificacaoListasEstrategias: Array<NNotificacaoListaEstrategia> = [];
    notificacaoEventos: Array<NNotificacaoEvento> = [];
    destinatarios: Array<NNotificacaoDestinatario> = [];

    // notificacaoCarteiraId: number;
    // notificacaoListaTemplateId: number;
    // notificacaoListaId: number;
    // notificacaoListaEstrategiaId: number;

    constructor() {
        this.notificacaoEventos = [];

        this.addCustomValidate();
    }

    //#region [ Validate ]

    addCustomValidate() {

        addCustomValidateChildrenArray(validate);
        addCustomValidateDependsAnotherFieldValue(validate);
        addCustomValidateCelular(validate);

    }

    validate() {

        let rules: any = {
            'nome': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            },
            'linguagem': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            }
        }

        return validate.validate(this, rules);
    }

    validateFiltros() {

        let rules: any = {
            'carteiraId': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            }
        }

        return validate.validate(this.notificacaoCarteiras[0], rules);
    }

    validateEventos() {
        let rules: any = {
            'notificacaoEventos': {
                'validChildrenArray': {
                    'childrenRules': {
                        'notificacaoTipoEventoId': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        }
                    }
                }
            }
        };

        return validate.validate(this, rules);
    }

    validateEventosConexoes(evento: NNotificacaoEvento) {
        let rules: any = {
            'eventosConexoes': {
                'validChildrenArray': {
                    'childrenRules': {
                        'conexao.notificacaoTipoEnvioId': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'notificacaoConexaoId': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },

                    }
                }
            }
        };

        return validate.validate(evento, rules);
    }

    validateDestinatarios() {
        let rules: any = {
            'destinatarios': {
                'validChildrenArray': {
                    'childrenRules': {
                        'nome': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'celular': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' },
                            'validCelular': { 'message': '^invalido' }
                        },
                        'email': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' },
                            'email': { 'message': "^invalido" }
                        }
                    }
                }
            }
        };

        return validate.validate(this, rules);
    }


    validateEmail() {

        let rules: any = {
            'notificacaoConexaoEmail': {
                'validChildrenArray': {
                    'childrenRules': {
                        'servidor': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'usuario': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'senha': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'bancoDados': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'objetoDados': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'parametros': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        }
                    }
                }
            }
        };

        return validate.validate(this, rules);
    }

    validateRabbitMQ() {

        let rules: any = {
            'notificacaoConexaoRabbitMQ': {
                'validChildrenArray': {
                    'childrenRules': {
                        'servidor': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'virtualHost': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'fila': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'usuario': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'senha': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        }
                    }
                }
            }
        };

        return validate.validate(this, rules);
    }

    validateWhatsapp() {

        let rules: any = {
            'notificacaoConexaoWhatsapp': {
                'validChildrenArray': {
                    'childrenRules': {
                        'whatsAppSessaoId': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        },
                        'telefone': {
                            'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
                        }
                    }
                }
            }
        };

        return validate.validate(this, rules);
    }

    //#endregion


    static fromRaw(raw: any): NNotificacao {
        let model: NNotificacao = Object.assign(new NNotificacao(), raw);

        if (!isNull(raw.notificacaoCarteiras)) {
            if (raw.notificacaoCarteiras.length > 0)
                model.notificacaoCarteiras = raw.notificacaoCarteiras.map((m: any) => NNotificacaoCarteira.fromRaw(m));
        }

        if (!isNull(raw.notificacaoTemplatesImportacao)) {
            if (raw.notificacaoTemplatesImportacao.length > 0)
                model.notificacaoTemplatesImportacao = raw.notificacaoTemplatesImportacao.map((m: any) => NNotificacaoTemplateImportacao.fromRaw(m));
        }

        if (!isNull(raw.notificacaoListas)) {
            if (raw.notificacaoListas.length > 0)
                model.notificacaoListas = raw.notificacaoListas.map((m: any) => NNotificacaoLista.fromRaw(m));
        }

        if (!isNull(raw.notificacaoListasEstrategias)) {
            if (raw.notificacaoListasEstrategias.length > 0)
                model.notificacaoListasEstrategias = raw.notificacaoListasEstrategias.map((m: any) => NNotificacaoListaEstrategia.fromRaw(m));
        }

        if (!isNull(raw.notificacaoEventos)) {
            if (raw.notificacaoEventos.length > 0)
                model.notificacaoEventos = raw.notificacaoEventos.map((m: any) => NNotificacaoEvento.fromRaw(m));
        }

        if (!isNull(raw.destinatarios)) {
            if (raw.destinatarios.length > 0)
                model.destinatarios = raw.destinatarios.map((m: any) => NNotificacaoDestinatario.fromRaw(m));
        }

        if (!isNull(raw.usuarioCadastro))
            model.usuarioCadastro = UsuarioSimples.fromRaw(raw.usuarioCadastro);

        if (!isNull(raw.usuarioAlteracao))
            model.usuarioAlteracao = UsuarioSimples.fromRaw(raw.usuarioAlteracao);

        return model;
    }
}