import { TranslateService } from "@ngx-translate/core";
import { isNullOrEmpty } from "../../utils";
//import { ParametrosAgendamentoDescricao } from "./parametros-agendamento-descricao";
import * as dayjs from 'dayjs';

export function AgendamentoDescricao(param: any, nomeTela: string, translate: TranslateService) {

    let diaSemana: any = {
        'SUN': translate.instant(`${nomeTela}.descricaoExecucao.domingo`),
        'MON': translate.instant(`${nomeTela}.descricaoExecucao.segunda`),
        'TUE': translate.instant(`${nomeTela}.descricaoExecucao.terca`),
        'WED': translate.instant(`${nomeTela}.descricaoExecucao.quarta`),
        'THU': translate.instant(`${nomeTela}.descricaoExecucao.quinta`),
        'FRI': translate.instant(`${nomeTela}.descricaoExecucao.sexta`),
        'SAT': translate.instant(`${nomeTela}.descricaoExecucao.sabado`)
    };

    //#region [ Funcoes auxiliares ]  

    let acertaOrdemDiasSemana = (param: any): string[] => {
        let dias = param.diaSemana.split(',');
        let diasOrdenados: string[] = [];

        if (dias.some((i) => { return i == 'SUN'; })) diasOrdenados.push('SUN');
        if (dias.some((i) => { return i == 'MON'; })) diasOrdenados.push('MON');
        if (dias.some((i) => { return i == 'TUE'; })) diasOrdenados.push('TUE');
        if (dias.some((i) => { return i == 'WED'; })) diasOrdenados.push('WED');
        if (dias.some((i) => { return i == 'THU'; })) diasOrdenados.push('THU');
        if (dias.some((i) => { return i == 'FRI'; })) diasOrdenados.push('FRI');
        if (dias.some((i) => { return i == 'SAT'; })) diasOrdenados.push('SAT');

        param.diaSemana = diasOrdenados.join(',');

        return diasOrdenados;
    };

    let replaceUltimoDia = (str: string): string => {
        return str.split(' ').reverse().join(' ').replace(new RegExp(/,/), '*e').split(' ').reverse().join(' ').replace('*e', ' e');
    };

    let plural = (param: any): string => {
        return (param.intervalo > 1) ? translate.instant(`${nomeTela}.descricaoExecucao.s`) : '';
    }

    let aPartir = (horaInicio: string): string => {
        return (!isNullOrEmpty(horaInicio)) ? translate.instant(`${nomeTela}.descricaoExecucao.aPartir`, { 'horaInicio': horaInicio }) : '';
    }

    //#endregion

    let descricao: string = '';

    if (isNullOrEmpty(param))
        return descricao;

    if (param.execucaoUnica && param.recorrente === false) {
        descricao = translate.instant(`${nomeTela}.descricaoExecucao.imediata`);

    } else {

        if (!isNullOrEmpty(param.dataInicio)) {
            let dataInicio = dayjs(param.dataInicio).format("DD/MM/YYYY");
            let horaInicio: any;
            let valores: any = { 'dataInicio': dataInicio };

            if (!isNullOrEmpty(param.horaInicio))
                horaInicio = (typeof (param.horaInicio) == 'string') ? param.horaInicio : dayjs(param.horaInicio).format("HH:mm");

            if (!param.execucaoRecorrente) {
                if (!isNullOrEmpty(horaInicio))
                    descricao = translate.instant(`${nomeTela}.descricaoExecucao.agendada`, { ...valores, 'horaInicio': horaInicio });

            } else {

                if (param.frequencia == '1') {
                    descricao = translate.instant(`${nomeTela}.descricaoExecucao.recorrenteFrequencia1`, { ...valores, 'intervalo': param.intervalo, 's': plural(param) });

                } else if (param.frequencia == '2') {
                    descricao = translate.instant(`${nomeTela}.descricaoExecucao.recorrenteFrequencia2`, { ...valores, 'intervalo': param.intervalo, 's': plural(param), 'aPartir': aPartir(horaInicio) });

                } else if (param.frequencia == '3') {
                    if (!isNullOrEmpty(horaInicio))
                        descricao = translate.instant(`${nomeTela}.descricaoExecucao.recorrenteFrequencia3`, { ...valores, 'horaInicio': horaInicio });

                } else if (param.frequencia == '4') {
                    if (!isNullOrEmpty(horaInicio)) {

                        valores = { ...valores, 'horaInicio': horaInicio };

                        if (isNullOrEmpty(param.diaSemana)) {
                            descricao = translate.instant(`${nomeTela}.descricaoExecucao.recorrenteFrequencia4TodoDia`, valores);

                        } else {

                            let dias: Array<any> = [];
                            let diasOrdenados = acertaOrdemDiasSemana(param);
                            //diasOrdenados.forEach(function (v: string) { diaSemana.push(diaSemana[v]); }.bind(this));
                            diasOrdenados.forEach((v: string) => {
                                dias.push(diaSemana[v]);
                            });

                            if (diasOrdenados.length == 1)
                                descricao = translate.instant(`${nomeTela}.descricaoExecucao.recorrenteFrequencia4TodaSemana`, { ...valores, 'diaSemana': diaSemana[param.diaSemana] });
                            else if (diasOrdenados.length == 7)
                                descricao = translate.instant(`${nomeTela}.descricaoExecucao.recorrenteFrequencia4TodoDia`, valores);
                            else
                                descricao = translate.instant(`${nomeTela}.descricaoExecucao.recorrenteFrequencia4TodaSemana`, { ...valores, 'diaSemana': replaceUltimoDia(dias.join(', ')) });
                        }
                    }
                }
            }

            if (param.comDataFim && !isNullOrEmpty(param.dataTermino) && !isNullOrEmpty(param.horaTermino))
                descricao = (!isNullOrEmpty(descricao)) ? `${descricao.substring(0, descricao.length - 1)} ${translate.instant(`${nomeTela}.descricaoExecucao.ate`)} ${dayjs(param.dataTermino).format("DD/MM/YYYY")} 23:59:59.` : '';

            if (!isNullOrEmpty(param.intervaloHoraInicio) && !isNullOrEmpty(param.intervaloHoraTermino)) {
                descricao = (!isNullOrEmpty(descricao)) ? `${descricao} ${translate.instant(`${nomeTela}.descricaoExecucao.intervaloDiario`)} ${param.intervaloHoraInicio} ${translate.instant(`${nomeTela}.descricaoExecucao.intervaloDiarioE`)} ${param.intervaloHoraTermino}` : '';
            }

            return descricao;
        }
    }

    return descricao;
}