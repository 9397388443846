import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import {
  MatInputModule,
  MatRadioModule,
  MatButtonModule,
  MatListModule,
  MatDialogModule,
  MatIconModule,
  MatDividerModule,
  MatSelectModule,
  MatCardModule,
  MatCheckboxModule,
  MatTabsModule
} from "@angular/material";
import { AppCommonModule } from "../common/common.module";
import { TableModule } from "primeng/table";
import { PickListModule } from 'primeng/picklist';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormPerfilComponent } from './form-perfil/form-perfil.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    AppCommonModule,
    TableModule,
    PickListModule,
    NgSelectModule
  ],
  declarations: [
  FormPerfilComponent
]
})

export class PerfilModule { }
