import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';
import { IAppConfig } from '../model/app-config';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from "../../../environments/environment";

const AppConfigToken = new InjectionToken<IAppConfig>('AppConfig');

export { AppConfigToken as APPCONFIG };

@Injectable()
export class ConfigProvider {

    public config: IAppConfig;
    public configLoaded: boolean;

    constructor(@Optional() @Inject(APP_BASE_HREF) public baseHref?: string) {
        if (this.baseHref == null) {
            this.baseHref = document.baseURI != null ? document.baseURI : '/';
        }
        this.configLoaded = false;
        this.config = environment;
    }

    getDefaults() {
        return Object.assign(environment);
    }

    load(): Promise<void> {
        return fetch(this.baseHref + 'config.json')
            .catch((error) => error)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return environment;
                }
            })
            .then((data: IAppConfig) => {
                this.config = {
                    ...environment,
                    ...data
                };
                this.configLoaded = true;
            });
    }

    public getConfig(): IAppConfig {
        return this.config;
    }
}

export function ConfigProviderFactory(provider: ConfigProvider): () => Promise<void> {
    return () => provider.load();
}
