import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LimpezaFiltroArquivoStatus } from "../../common/model/limpeza-filtro-arquivo";
import { SituacaoExecucao } from "../../common/model/situacao-execucao";

@Pipe({ name: "situacaoExecucao" })
export class SituacaoExecucaoPipe implements PipeTransform {

	constructor(private translate: TranslateService) { }

	transform(value: SituacaoExecucao, args?: any): any {
		switch (value) {
			case SituacaoExecucao.play:
				return this.translate.instant('telaTarefa.playExecucao');
			case SituacaoExecucao.pause:
				return this.translate.instant('telaTarefa.pauseExecucao');
			case SituacaoExecucao.cancel:
				return this.translate.instant('telaTarefa.cancelExecucao');
			default:
				return "";
		}
	}
}
