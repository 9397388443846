export class FormEstrategiaListaSelecionada {
    listaId: number | null;
    providerId: number | null;
    templateId: number | null;
    nome: string | null;
    alias: string | null;

    constructor() { }


    // setar(raw: any) {
    //     this.listaId = raw.listaId;
    //     this.providerId = raw.providerId;
    //     this.templateId = raw.templateId;
    //     this.nome = raw.nome;
    //     this.alias = raw.alias;
    // }

    limpar() {
        this.listaId = null;
        this.providerId = null;
        this.templateId = null;
        this.nome = null;
        this.alias = null;
    }

    static fromRaw(raw: any) {
        let data = new FormEstrategiaListaSelecionada();
        Object.assign(data, raw);
        return data;
    }
}