import { MatDialog } from "@angular/material";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from "@ngx-translate/core";

import { Regua } from "../../common/model/regua";
import { ReguaEstrategia } from "../../common/model/regua-estrategia";
import { Estrategia, TipoEstrategia } from "../../common/model/estrategia";

import { ReguaService } from "../../common/service/regua.service";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";

import { FluxoService } from "../../common/service/fluxo.service";
import { isNullOrEmpty, isNullOrZero, isNull } from "../../common/utils";

import { FiltroStatusRegistroLista } from "../../common/model/lista";
import { EsBuilderModalFilterPreviewComponent } from "../../esbuilder/esbuilder-modal-filter-preview/esbuilder-modal-filter-preview.component";
import { ConfiguracoesAgendamentoModalComponent } from "../../estrategia/modais/configuracoes-agendamento/configuracoes-agendamento-modal.component";
import { AgendamentoModel } from "../../estrategia/models/agendamento.model";
import { AgendamentoDescricao } from "../../common/libs/agendamento-descricao/agendamento-descricao";
import { deepCopy } from "../../common/utils/deepCopy";
import { EstrategiaRefactor } from "../../common/model/estrategiaRefactor";
import { ProviderService } from "../../common/service/provider.service";
import { TemplateService } from "../../common/service/template.service";
import { CampanhaService } from "../../common/service/campanha.service";
import { Provider } from "../../common/model/provider";
import { Template } from "../../common/model/template";
import { IntegracaoTipo } from "../../common/model/provedor-configuracao";
import { NotificationService } from "../../common/service/notification.service";
import { ESBuilderData, ESBuilderQuantityList } from "../../esbuilder/models";
import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";



@Component({
    selector: "app-regua-estrategia",
    templateUrl: "./regua-estrategia.component.html",
    styleUrls: ["./regua-estrategia.component.scss"],
    providers: [
        { useClass: ReguaService, provide: ReguaService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ProviderService, provide: ProviderService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: CampanhaService, provide: CampanhaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: FluxoService, provide: FluxoService },
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService}
    ]
})
export class ReguaEstrategiaComponent implements OnInit {

    appConfig: any;
    
    alternarAgenda: boolean = true;
    horariosReservados: Array<string>;
    estrategias: Array<EstrategiaRefactor> = [];
    estrategiasDisponiveis: Array<EstrategiaRefactor> = [];
    providers: Array<Provider>;
    templatesExportacao: Array<Template>;

    _regua: Regua;

    public get regua(): Regua {
        return this._regua;
    }

    @Input()
    public set regua(regua: Regua) {
        if (!regua) return;

        this._regua = regua;
    }

    _listaId: number;

    public get listaId(): number {
        return this._listaId;
    }

    @Input()
    public set listaId(value: number) {
        if (!value) return;

        if (!isNullOrZero(value)) {
            this.obterEstrategias();
            this.obterProvedores();
            this.obterTemplatesExportacao();
        }

        this._listaId = value;
    }

    @Output()
    update: EventEmitter<any>;

    public get reguaEstrategiaItensAtivos() {
        let itens = this.regua.reguaEstrategia.filter((f: ReguaEstrategia) => f.ativo)
        return itens;
    }

    constructor(
        private reguaService: ReguaService,
        private estrategiaService: EstrategiaService,
        private providerService: ProviderService,
        private templateService: TemplateService,
        private fluxoService: FluxoService,
        private customAlertService: CustomAlertService,
        private notificationService: NotificationService,
        private elasticsearchService: ElasticsearchRepositoryService,
        private translate: TranslateService,
        public dialog: MatDialog
    ) {
        this.update = new EventEmitter<any>();
    }

    ngOnInit() {
        this.obterHorariosOcupados()
    }

    atualizarRegua(reguaId: number) {
        this.update.emit({ 'reguaId': reguaId });
    }

    //#region [ Obter dados ]

    obterHorariosOcupados() {
        this.estrategiaService
            .obterHorariosReservados(this.regua.listaId, 0)
            .subscribe((horarios: string[]) => (this.horariosReservados = horarios));
    }

    obterEstrategias() {
        this.reguaService
            .obterEstrategias(this.regua.listaId)
            .subscribe(result => {
                this.estrategias = result.map((m: any) => EstrategiaRefactor.fromRaw(m));
                this.separarEstrategiasDisponiveis();
            });
    }

    obterProvedores() {
        this.providerService
            .obterProvedorDisponivelPorLista(this.regua.listaId)
            .subscribe((providers: any) => {
                this.providers = providers.map((p: any) => this.providerService.convertObject(p));
            });
    }

    obterTemplatesExportacao() {
        this.templateService
            .obterTemplates({ listaId: this.regua.listaId, filtroEstrategia: true, categoria: 2 })
            .subscribe((templates: any) => {
                this.templatesExportacao = templates.map((t: any) => Template.fromRaw(t));
            });
    }

    obterCampanhas(ordem: number) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);

        this.providerService
            .obterCampanhasConfiguradas(this.regua.listaId, this.regua.reguaEstrategia[i].provedorSelecionado)
            .subscribe((campanhas: any) => {
                this.regua.reguaEstrategia[i].campanhas = campanhas;

                let providerSelecionado = this.providers.find((f: Provider) => f.providerId == this.regua.reguaEstrategia[i].provedorSelecionado) as Provider;
                let templateExportacaoSelecionado = this.regua.reguaEstrategia[i].templatesExportacaoFiltrado.find((f: Template) => f.listaTemplateId == this.regua.reguaEstrategia[i].templateSelecionado.toString()) as Template;

                if (!isNull(providerSelecionado!.campanha) && providerSelecionado!.campanha.layoutPorCampanha && !isNullOrZero(templateExportacaoSelecionado!.campanhaId))
                    this.regua.reguaEstrategia[i].campanhaSelecionada = templateExportacaoSelecionado!.campanhaId;
            });
    }

    //#endregion

    //#region [ Métodos de change ]

    providerChange(ordem: number) {

        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);

        // Caso nenhum provedor tenha sido selecionado limpa os campos dependentes
        if (isNullOrZero(this.regua.reguaEstrategia[i].provedorSelecionado)) {
            this.regua.reguaEstrategia[i].provedorSelecionado = 0;
            this.regua.reguaEstrategia[i].listaEstrategiaTipoId = 0;
            this.regua.reguaEstrategia[i].templateSelecionado = 0;
            this.regua.reguaEstrategia[i].campanhaSelecionada = 0;
            return;
        }

        let providerSelecionado = this.providers.find((f: Provider) => f.providerId == this.regua.reguaEstrategia[i].provedorSelecionado) as Provider;

        // Limpa os campos de template e campanha
        this.regua.reguaEstrategia[i].templateSelecionado = 0;
        this.regua.reguaEstrategia[i].campanhaSelecionada = 0;

        // Filtra os Templates Exportação do provedor selecionado
        this.regua.reguaEstrategia[i].templatesExportacaoFiltrado = this.templatesExportacao.filter((f: any) => (f.providerId == this.regua.reguaEstrategia[i].provedorSelecionado));

        if (this.regua.reguaEstrategia[i].templatesExportacaoFiltrado.length === 1)
            this.regua.reguaEstrategia[i].templateSelecionado = Number(this.regua.reguaEstrategia[i].templatesExportacaoFiltrado[0].listaTemplateId);

        // Seta o listaEstrategiaTipoId
        this.regua.reguaEstrategia[i].listaEstrategiaTipoId = (!isNullOrEmpty(providerSelecionado.envio.integracaoTipoId == IntegracaoTipo.Arquivo)) ? 2 : 1;

        // Verifica se a configuação é por campanha
        if (providerSelecionado.configuracaoPorCampanha)
            this.obterCampanhas(ordem);
    }

    templateExportacaoChange(ordem: number) {

        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);

        // Caso nenhum template tenha sido selecionado limpa os campos dependentes
        if (isNullOrZero(this.regua.reguaEstrategia[i].templateSelecionado)) {
            this.regua.reguaEstrategia[i].campanhaSelecionada = 0;
            return;
        }

        let providerSelecionado = this.providers.find((f: Provider) => f.providerId == this.regua.reguaEstrategia[i].provedorSelecionado) as Provider;
        //let templateExportacaoSelecionado = this.regua.reguaEstrategia[i].templatesExportacaoFiltrado.find((f: Template) => f.listaTemplateId == this.regua.reguaEstrategia[i].templateSelecionado.toString()) as Template;

        // Limpa os campos de template e campanha
        this.regua.reguaEstrategia[i].campanhaSelecionada = 0;

        // Verifica se a configuação é por campanha
        if (providerSelecionado.configuracaoPorCampanha)
            this.obterCampanhas(ordem);
    }

    //#endregion

    //#region [ Ordem da régua ]

    drop(event: CdkDragDrop<string[]>) {
        if (this.regua.reguaEstrategia[event.currentIndex].vincularEstrategia) {
            this.notificationService.error("telaPadrao.atencao", "telaRegua.erroPassoVinculo");
            return;
        }

        if (this.regua.reguaEstrategia[event.currentIndex].criarEstrategia) {
            this.notificationService.error("telaPadrao.atencao", "telaRegua.erroPassoCriando");
            return;
        }

        // Mover o agendamento do event.previousIndex para o event.currentIndex e vice versa
        if (this.alternarAgenda) {
            if (!this.verify(this.regua.reguaEstrategia[event.previousIndex].listaEstrategiaId) && !this.verify(this.regua.reguaEstrategia[event.currentIndex].listaEstrategiaId)) {
                const from = this.getSchedule(this.regua.reguaEstrategia, event.previousIndex);
                const to = this.getSchedule(this.regua.reguaEstrategia, event.currentIndex);
                this.setSchedule(this.regua.reguaEstrategia, to, event.previousIndex);
                this.setSchedule(this.regua.reguaEstrategia, from, event.currentIndex);
            }
        }
        moveItemInArray(this.regua.reguaEstrategia, event.previousIndex, event.currentIndex);
        this.reordenarRegua();
    }

    reordenarRegua() {
        let newOrdem = 1;
        let mapFnc = (f: ReguaEstrategia) => { f.ordem = newOrdem; newOrdem++; return f; };

        let ativos = this.regua.reguaEstrategia.filter((f: ReguaEstrategia) => f.ativo).map(mapFnc);
        let inativos = this.regua.reguaEstrategia.filter((f: ReguaEstrategia) => f.ativo == false).map(mapFnc);

        this.regua.reguaEstrategia = [...ativos, ...inativos];

        // let ordem = this.regua.reguaEstrategia.map((m: ReguaEstrategia) => { return { 'reguaEstrategiaId': m.reguaEstrategiaId, 'ordem': m.ordem }; });
        // this.reguaService
        //     .reordernarRegua(ordem)
        //     .subscribe(data => { });
    }

    //#endregion

    //#region [ Adicionar/Remover ReguaEstrategia]

    novaRegraEstrategia() {
        let ordem = (this.regua.reguaEstrategia.length <= 0) ? 0 : Math.max(...this.regua.reguaEstrategia.map(o => o.ordem));
        this.regua.reguaEstrategia.push(new ReguaEstrategia(this.regua.reguaId, null, ordem + 1));
    }

    excluirReguaEstrategia(ordem: number) {
        
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);

        if (isNullOrZero(this.regua.reguaEstrategia[i].reguaEstrategiaId))
            this.regua.reguaEstrategia.splice(i, 1);
        else
            this.regua.reguaEstrategia[i].ativo = false;

        this.reordenarRegua();
    }

    //#endregion

    //#region [ Vincular/Criar e Desvincular estratégias ]

    vincularEstrategia(ordem: number, vincular: boolean) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);

        if (vincular) {

            this.regua.reguaEstrategia[i].dirtyVincularEstrategia = true;

            if (this.regua.reguaEstrategia[i].validarVincularEstrategia()) {
                this.customAlertService.show("telaRegua.regua", "telaPadrao.camposInvalidos", "error");
                return;
            }

            // Procurar a estratégia 
            let estrategia = this.estrategiasDisponiveis.find((e: EstrategiaRefactor) => e.listaEstrategiaId == this.regua.reguaEstrategia[i].estrategiaSelecionada) as EstrategiaRefactor;

            // Vincular a estratégia
            this.regua.reguaEstrategia[i].listaEstrategiaId = estrategia.listaEstrategiaId;
            this.regua.reguaEstrategia[i].estrategia = estrategia;

            //Limpar a estratégia selecionada
            this.regua.reguaEstrategia[i].vincularEstrategia = false;
            this.regua.reguaEstrategia[i].estrategiaSelecionada = 0;

            //Busca as quantidades
            this.quantidade(i);

            // Atualizar estratégias disponiveis
            this.separarEstrategiasDisponiveis();

        } else {
            this.regua.reguaEstrategia[i].vincularEstrategia = false;
            this.regua.reguaEstrategia[i].estrategiaSelecionada = 0;
        }

        this.regua.reguaEstrategia[i].dirtyVincularEstrategia = false;

    }

    criarEstrategia(ordem: number, criar: boolean) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);

        if (criar) {

            this.regua.reguaEstrategia[i].dirtyCriarEstrategia = true;

            let validarCampoCampanha = this.validarCampoCampanha(this.regua.reguaEstrategia[i]);

            if (this.regua.reguaEstrategia[i].validarCriarEstrategia(validarCampoCampanha)) {
                this.customAlertService.show("telaRegua.regua", "telaPadrao.camposInvalidos", "error");
                return;
            }

            let filtro = '{"size":5,"_source":{"includes":[]},"query":{"bool":{"must_not":[{"exists":{"field":"_integrado"}}]}}}';
            let filtroRegras = '{"filterType":0,"rules":[{"ruleReadOnly":false,"reverse":false,"notReverseCondition":false,"reverseNegation":false,"condition":"none","field":"","fieldType":"","filterType":"","filterValues":{},"order":0,"_valid":false}],"type":"bool","includeIntegratedRegister":0}';

            this.fluxoService
                .obterQueryQuantidadeRegistrosPost(this.regua.listaId, filtro)
                .subscribe((result: any) => {

                    let providerSelecionado = this.providers.find((f: Provider) => f.providerId == this.regua.reguaEstrategia[i].provedorSelecionado) as Provider;
                    let templateExportacaoSelecionado = this.regua.reguaEstrategia[i].templatesExportacaoFiltrado.find((f: Template) => f.listaTemplateId == this.regua.reguaEstrategia[i].templateSelecionado.toString()) as Template;

                    let estrategia = new EstrategiaRefactor();
                    estrategia.listaEstrategiaId = 0;
                    estrategia.notificarZerada = false;
                    estrategia.envioImediato = false;
                    estrategia.nome = this.regua.reguaEstrategia[i].estrategiaNomeInformado;
                    estrategia.estrategiaAcaoCampanhaTipoId
                    estrategia.listaId = this.regua.listaId;
                    estrategia.filtro = filtro;
                    estrategia.filtroRegras = filtroRegras;
                    estrategia.quantidadeMaximaExportacao = (result) ? result : 0;
                    estrategia.quantidadeMaximaExportacaoPct = 100;
                    estrategia.medidaMaximaExportacaoPct = true;
                    estrategia.providerId = this.regua.reguaEstrategia[i].provedorSelecionado;
                    estrategia.provedorNome = providerSelecionado.descricao;
                    estrategia.listaTemplateExportacaoId = this.regua.reguaEstrategia[i].templateSelecionado;
                    estrategia.templateExportacaoNome = templateExportacaoSelecionado.nome;

                    estrategia.campanhaId = (isNullOrZero(this.regua.reguaEstrategia[i].campanhaSelecionada)) ? '' : this.regua.reguaEstrategia[i].campanhaSelecionada.toString();
                    estrategia.marcarDistribuido = !this.regua.reguaEstrategia[i].estrategiaEnviarReutilizar;
                    estrategia.listaEstrategiaPrioridade = [];
                    estrategia.reenviarDistribuido = FiltroStatusRegistroLista.naoUtilizado;
                    estrategia.listaEstrategiaTipoId = TipoEstrategia.provedor;
                    estrategia.enviarEstrategiaIdExportacao = false;
                    estrategia.recorrente = false;
                    estrategia.pendenteExecucao = true;
                    estrategia = Object.assign(estrategia, new AgendamentoModel());     // Agendamento padrão

                    // Vincular a estratégia
                    this.regua.reguaEstrategia[i].listaEstrategiaId = estrategia.listaEstrategiaId;
                    this.regua.reguaEstrategia[i].estrategia = EstrategiaRefactor.fromRaw(estrategia);

                    //Limpar a estratégia preenchida
                    this.regua.reguaEstrategia[i].criarEstrategia = false;
                    this.regua.reguaEstrategia[i].estrategiaSelecionada = 0;
                    this.regua.reguaEstrategia[i].estrategiaNomeInformado = '';
                    this.regua.reguaEstrategia[i].estrategiaEnviarReutilizar = false;
                    this.regua.reguaEstrategia[i].provedorSelecionado = 0;
                    this.regua.reguaEstrategia[i].listaEstrategiaTipoId = null;
                    this.regua.reguaEstrategia[i].templateSelecionado = 0;
                    this.regua.reguaEstrategia[i].campanhaSelecionada = 0;
                });

        } else {
            //Limpar a estratégia preenchida
            this.regua.reguaEstrategia[i].criarEstrategia = false;
            this.regua.reguaEstrategia[i].estrategiaSelecionada = 0;
            this.regua.reguaEstrategia[i].estrategiaNomeInformado = '';
            this.regua.reguaEstrategia[i].estrategiaEnviarReutilizar = false;
            this.regua.reguaEstrategia[i].provedorSelecionado = 0;
            this.regua.reguaEstrategia[i].listaEstrategiaTipoId = null;
            this.regua.reguaEstrategia[i].templateSelecionado = 0;
            this.regua.reguaEstrategia[i].campanhaSelecionada = 0;
        }

        this.regua.reguaEstrategia[i].dirtyCriarEstrategia = false;
    }

    desvincularEstrategia(ordem: number) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);
        this.regua.reguaEstrategia[i].listaEstrategiaId = null;
        this.regua.reguaEstrategia[i].estrategia = null;

        // Atualizar estratégias disponiveis
        this.separarEstrategiasDisponiveis();
    }

    separarEstrategiasDisponiveis() {
        let vinculadas = this.regua.reguaEstrategia.filter((f: ReguaEstrategia) => f.ativo && f.listaEstrategiaId != undefined && f.listaEstrategiaId != null).map((m: ReguaEstrategia) => m.listaEstrategiaId) as Array<number>;
        this.estrategiasDisponiveis = this.estrategias.filter((f: EstrategiaRefactor) => !vinculadas.some((s: number) => s == f.listaEstrategiaId));
    }

    errosVincularEstrategia(reguaEstrategia: ReguaEstrategia, campo: string) {
        if (!reguaEstrategia.dirtyVincularEstrategia)
            return;

        let validacao = reguaEstrategia.validarVincularEstrategia();

        return (!isNull(validacao) && !isNullOrEmpty(validacao[campo]));
    }

    errosCriarEstrategia(reguaEstrategia: ReguaEstrategia, campo: string) {
        if (!reguaEstrategia.dirtyCriarEstrategia)
            return;

        let validarCampoCampanha = this.validarCampoCampanha(reguaEstrategia);
        let validacao = reguaEstrategia.validarCriarEstrategia(validarCampoCampanha);

        return (!isNull(validacao) && !isNullOrEmpty(validacao[campo]));
    }

    validarCampoCampanha(reguaEstrategia: ReguaEstrategia): boolean {
        if (this.verify(reguaEstrategia.provedorSelecionado) == false)
            return false;

        let providerSelecionado = this.providers.find((f: Provider) => f.providerId == reguaEstrategia.provedorSelecionado) as Provider;

        if (this.verify(providerSelecionado!.campanha) == false)
            return false;

        return providerSelecionado.campanha.layoutPorCampanha;
    }

    //#endregion

    //#region [ ComboCampanha ]

    mostrarComboCampanha(reguaEstrategia: ReguaEstrategia): boolean {
        if (isNullOrZero(reguaEstrategia.provedorSelecionado))
            return false;

        let providerSelecionado = this.providers.find((f: Provider) => f.providerId == reguaEstrategia.provedorSelecionado) as Provider;

        return providerSelecionado.configuracaoPorCampanha;
    }

    desabilitarComboCampanha(reguaEstrategia: ReguaEstrategia): boolean {
        if (isNullOrZero(reguaEstrategia.provedorSelecionado) || isNullOrZero(reguaEstrategia.templateSelecionado))
            return true;

        let providerSelecionado = this.providers.find((f: Provider) => f.providerId == reguaEstrategia.provedorSelecionado) as Provider;
        return (providerSelecionado.campanha.layoutPorCampanha);
    }

    //#endregion

    //#region [ Métodos de agendamento da estratégia ]

    getSchedule(array: ReguaEstrategia[], index: number): any {
        let e = deepCopy(array[index].estrategia);

        let agendamento = {
            'execucaoUnica': e.execucaoUnica,
            'recorrente': e.recorrente,
            'frequencia': e.frequencia,
            'intervalo': e.intervalo,
            'diaSemana': e.diaSemana,
            'dataInicio': e.dataInicio,
            'horaInicio': e.horaInicio,
            'dataTermino': e.dataTermino,
            'horaTermino': e.horaTermino,
            'intervaloHoraInicio': e.intervaloHoraInicio,
            'intervaloHoraTermino': e.intervaloHoraTermino,
            'comDataFim': e._comDataFim,
        };

        return agendamento;
    }

    setSchedule(reguaEstrategia: ReguaEstrategia[], schedule: any, index: number) {

        if (isNull(reguaEstrategia[index].estrategia))
            return;

        (reguaEstrategia[index].estrategia as EstrategiaRefactor).execucaoUnica = schedule.execucaoUnica;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).recorrente = schedule.recorrente;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).frequencia = schedule.frequencia;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).intervalo = schedule.intervalo;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).diaSemana = schedule.diaSemana;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).dataInicio = schedule.dataInicio;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).horaInicio = schedule.horaInicio;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).dataTermino = schedule.dataTermino;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).horaTermino = schedule.horaTermino;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).intervaloHoraInicio = schedule.intervaloHoraInicio;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).intervaloHoraTermino = schedule.intervaloHoraTermino;
        (reguaEstrategia[index].estrategia as EstrategiaRefactor).comDataFim = schedule._comDataFim;
    }

    modalAgendamentoEstrategia(ordem: number) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);
        let estrategia = (this.regua.reguaEstrategia[i].estrategia as EstrategiaRefactor);

        let dialogRef = this.dialog.open(ConfiguracoesAgendamentoModalComponent, {
            hasBackdrop: true,
            width: "850px",
            data: {
                'agendamento': AgendamentoModel.convertEstrategiaToAgendamento(estrategia as EstrategiaRefactor),
                'horariosReservados': this.horariosReservados
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result)
                return;

            this.regua.reguaEstrategia[i].estrategia = Object.assign(estrategia, result);
        });
    }

    agendamentoDescricao(reguaEstrategia: ReguaEstrategia): string {
        let agendamento = AgendamentoModel.convertEstrategiaToAgendamento(reguaEstrategia.estrategia as EstrategiaRefactor);
        let descricao = AgendamentoDescricao(agendamento, 'telaEstrategia', this.translate);
        let tipoEnvio = "";

        switch (agendamento.tipoEnvio) {
            case 1:
                tipoEnvio = "IMEDIATA";
                break;
            case 2:
                tipoEnvio = "AGENDADA";
                break;
            case 3:
                tipoEnvio = "RECORRENTE";
                break;
        }

        return `${tipoEnvio} - ${descricao}`;
    }

    //#endregion

    //#region [ Métodos dos filtros da estratégia ]

    modalFiltros(ordem: number) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);
        let estrategia = (this.regua.reguaEstrategia[i].estrategia as EstrategiaRefactor);

        let dialogRef = this.dialog.open(EsBuilderModalFilterPreviewComponent, {
            hasBackdrop: true,
            width: "70%",
            height: "800px",
            data: { 'listaId': this.regua.listaId, 'dataRules': estrategia.filtroRegras, 'query': estrategia.filtro }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result)
                return;

            estrategia.filtro = JSON.stringify(result.query);
            estrategia.filtroRegras = JSON.stringify(result.dataRules);
            estrategia.quantidadeMaximaExportacao = result.quantityList;
            this.regua.reguaEstrategia[i].estrategia = estrategia;
            //Busca as quantidades
            this.quantidade(i);
        });
    }

    //#endregion

    //#region [ Métodos de execução da estratégia ]

    ativarEnvioImediato(ordem: number) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);
        let estrategia = this.regua.reguaEstrategia[i].estrategia as EstrategiaRefactor;

        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarAtivacaoEnvioImediato")
            .then(() =>
                this.estrategiaService
                    .ativarEnvioImediato(estrategia.listaEstrategiaId)
                    .subscribe(() => this.regua.reguaEstrategia[i].envioManualRealizado = true)
            );
    }

    ativarPendenteExecucao(ordem: number) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);
        let estrategia = this.regua.reguaEstrategia[i].estrategia as EstrategiaRefactor;

        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(this.regua.listaId, estrategia.filtro)
            .subscribe((result: any) => {
                let quantidade = (result) ? result : 0;
                let mensagem = (quantidade > 0) ? "telaEstrategia.confirmarReativacao" : "telaEstrategia.confirmarReativacaoSemRegistro";

                this.customAlertService
                    .confirmationMessage(mensagem)
                    .then(() => this.estrategiaService.ativarPendenteExecucao(this.regua.listaId).subscribe(() => this.regua.reguaEstrategia[i].envioManualRealizado = true));
            });
    }

    forcarExecucao(ordem: number) {
        let i = this.regua.reguaEstrategia.findIndex((f: ReguaEstrategia) => f.ordem == ordem);
        let estrategia = this.regua.reguaEstrategia[i].estrategia as EstrategiaRefactor;

        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(this.regua.listaId, estrategia.filtro)
            .subscribe((result: any) => {
                let quantidade = (result) ? result : 0;
                let mensagem = (quantidade > 0) ? "telaEstrategia.forcarExecucao" : "telaEstrategia.forcarExecucaoSemRegistro";

                this.customAlertService
                    .confirmationMessage(mensagem)
                    .then(() => this.estrategiaService.forcarExecucao(this.regua.listaId).subscribe(() => this.regua.reguaEstrategia[i].envioManualRealizado = true));
            });
    }

    //#endregion

    //#region [ Úteis ]

    estrategiaSelecionada = (estrategiaId: number): boolean => (estrategiaId != undefined && estrategiaId != null);

    isNullOrEmpty = (valor: any): boolean => isNullOrEmpty(valor);

    isNullOrZero = (valor: any): boolean => isNullOrZero(valor);

    verify = (valor: any): boolean => (valor == undefined || valor == null || valor === '');

    quantidade(indice:number){
 
        this.elasticsearchService
            .getQuantidadeLista(this.listaId, 0, (JSON.parse(this._regua.reguaEstrategia[indice].estrategia.filtro) || {}), null, null, null, null, null, true)
            .subscribe((quantityList: any) => {
                this._regua.reguaEstrategia[indice].quantityList = ESBuilderQuantityList.fromRaw(quantityList);
            });
    }

    //#endregion

}