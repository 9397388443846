import { BaseElasticsearchFilter } from "./base";
import { TermsElasticsearchFilter } from "./terms";

export class NotTermsElasticsearchFilter extends BaseElasticsearchFilter {
  not_in: string[];
  transformedExtra: boolean = false;

  protected getInstance(
    queryType: string,
    propertyName: string,
    parameters: any,
    extra?: any,
    keyword?: boolean
  ): BaseElasticsearchFilter {
    return new NotTermsElasticsearchFilter(propertyName, queryType, parameters, extra, keyword);
  }

  public friendlyParameters(dataType: string, queryTypes) {

    if (this.queryType = "not_terms") {
      this.not_in = this.parameters;
      this.parameters = this.extra;
    }

    if (undefined !== this.not_in && Array.isArray(this.not_in))
      return `Não em ${this.not_in.join(", ")}`;
    return super.friendlyParameters(dataType, queryTypes);
  }

  transformForNotContains() {
    this.queryType = "terms";
    this.not_in = this.parameters;
    this.parameters = this.extra;
  }

  convertFilter(): any {
    if (this.transformedExtra) return super.convertFilter();

    this.transformForNotContains();
    this.transformedExtra = true;
    return super.convertFilter();
  }
  static fromRaw(raw): NotTermsElasticsearchFilter {
    return Object.assign(new NotTermsElasticsearchFilter(), raw);
  }
}
