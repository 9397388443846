import { NgModule, LOCALE_ID } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import {
  MatInputModule,
  MatRadioModule,
  MatButtonModule,
  MatListModule,
  MatDialogModule,
  MatIconModule,
  MatDividerModule,
  MatSelectModule,
  MatCardModule,
  MatTabsModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatMenuModule
} from "@angular/material";

import { NgSelectModule } from '@ng-select/ng-select';

import { TableModule } from "primeng/table";
// import { SelectModule } from "ng-select";

import { AppCommonModule } from "../common/common.module";

import { FormTemplateImportacaoComponent } from "./form-template-importacao/form-template-importacao.component";
import { CampoImportacaoModalComponent } from "./form-template-importacao/campo-importacao-modal/campo-importacao-modal.component";
import { QuebraLinhaModalComponent } from "./form-template-importacao/quebra-linha-modal/quebra-linha-modal.component";

import { FormTemplateExportacaoComponent } from "./form-template-exportacao/form-template-exportacao.component";
import { CampoExportacaoModalComponent } from "./form-template-exportacao/campo-exportacao-modal/campo-exportacao-modal.component";

import { ListaTemplateComponent } from "./lista-template/lista-template.component";
import { FiltroTemplateComponent } from "./lista-template/filtro-template/filtro-template.component";
import { JsonFilterByPipe } from "./pipes/json-filter-by-pipe";
import { VazioTracoPipe } from "./pipes/vazio-pipe";
import { environment } from '../../../environments/environment';
import { CampoCampanhaInfoModalComponent } from "./form-template-exportacao/campo-campanha-info-modal/campo-campanha-info-modal.component";
import { CampoExportacaoRawComponent } from "./form-template-exportacao/campo-exportacao-raw/campo-exportacao-raw.component";
import { CampoRawModalComponent } from "./form-template-exportacao/campo-exportacao-raw/campo-raw-modal/campo-raw-modal.component";
import { ListaTemplateExportacaoComponent } from "./form-template-importacao/lista-template-exportacao/lista-template-exportacao.component";
import { CamposParametrosUrlModalComponent } from "./form-template-exportacao/campos-parametros-url-modal/campos-parametros-url-modal.component";
import { ListaTemplateItemTipoDescricaoPipe } from "./pipes/listatemplateitemtipoDescricao";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatSelectModule,
    MatTooltipModule,
    AppCommonModule,
    TableModule,
    // SelectModule,
    NgSelectModule
  ],
  declarations: [
    FormTemplateImportacaoComponent,
    CampoImportacaoModalComponent,
    QuebraLinhaModalComponent,
    FormTemplateExportacaoComponent,
    CampoExportacaoModalComponent,
    CamposParametrosUrlModalComponent,
    ListaTemplateComponent,
    ListaTemplateExportacaoComponent,
    FiltroTemplateComponent,
    CampoCampanhaInfoModalComponent,
    CampoExportacaoRawComponent,
    JsonFilterByPipe,
    VazioTracoPipe,
    ListaTemplateItemTipoDescricaoPipe,
    CampoRawModalComponent
  ],
  exports: [
    FormTemplateImportacaoComponent,
    FormTemplateExportacaoComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` }}
  ],
  entryComponents: [
    CampoImportacaoModalComponent,
    QuebraLinhaModalComponent,
    CampoExportacaoModalComponent,
    CamposParametrosUrlModalComponent,
    CampoCampanhaInfoModalComponent,
    CampoRawModalComponent
  ]
})
export class TemplateModule { }
