import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-monitor-retorno",
	templateUrl: "./monitor-retorno.component.html",
	styleUrls: ["./monitor-retorno.component.scss"],
	providers: [
		// { useClass: HistoricoRetornoService, provide: HistoricoRetornoService },
		// { useClass: EstrategiaService, provide: EstrategiaService },
		// { useClass: CustomAlertService, provide: CustomAlertService },
	]
})

export class MonitorRetornoComponent implements OnInit {
    constructor() {
        
    }
    
    ngOnInit() {
    };
}