
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import * as signalR from "@aspnet/signalr";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest, HttpHeaders, HttpParams } from "@angular/common/http";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";

import { Lista } from "../model/lista";
import { FiltroLista } from "../model/filtro-lista";
import { convertToQueryString } from "./query-string";
import { ListaCard } from "../../dash/model/lista-card";
import { FiltroDashPrincipal } from "../../dash/model/filtro-dash-principal";
import { EstrategiaRecorrencia, EstrategiaStatus } from "../model/estrategia";
import { ImportacaoAutomaticaConfiguracao } from "../model/importacao-automatica";
import { ImportacaoAutomaticaHistorico } from "../model/importacao-automatica-historico";
import * as dayjs from 'dayjs';
import { LimpezaAutomaticaConfiguracao } from '../model/limpeza-automatica-configuracao';
import { isNullOrZero } from '../utils';
import { LimpezaFiltroArquivo } from '../model/limpeza-filtro-arquivo';
import { Http, ResponseContentType, Headers } from "@angular/http";
import { AutenticacaoService } from "./autenticacao.service";
import { TranslateService } from '@ngx-translate/core';
import { ConfigProvider } from '../../../common/providers/config.provider';
import { StorageProxy } from './storage-proxy.service';
import { NaturalLanguage } from '../model/natural-language';

@Injectable()
export class ListaService {
	private api: string;
	private urlApiCompleta: string

	constructor(
		private _httpClient: HttpClient,
		private http: Http,
		private translateService: TranslateService,
		private authService: AutenticacaoService,
		private configProvider: ConfigProvider
	) {
		this.api = '/api/listas';
		let appConfig = this.configProvider.getConfig();
		let language: string = (StorageProxy.getString('linguagem') === "pt") ? "pt-BR" : StorageProxy.getString('linguagem');
		this.urlApiCompleta = `${appConfig.serverUrl}/${language}${this.api}`;
		setTimeout(() => {
			this.conectarSignalR();
		}, 1000);
	}

	obterQuantidadeLista(listaId, estrategiaId): Observable<any> {
		return this._httpClient.get<any>(
			`${this.api}${listaId}/quantidades?estrategiaId=${estrategiaId}`
		);
	}
	obterListaComTemplate(filter): any {
		var query = convertToQueryString(filter);
		return this._httpClient.get<Lista>(
			`${this.api}/template${query}`
		);
	}
	private _hubConnection: HubConnection | undefined;
	public signalRStatus: boolean = false;

	public conectarSignalR() {
		let endpoint = this.configProvider.getConfig().serverUrl;

		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${endpoint}/notificacaohub`, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
			.configureLogging(signalR.LogLevel.Information)
			.build();
	}

	public reconectarSignalR(listas: Array<Lista>) {
		this._hubConnection.onclose(() => {
			this.signalRStatus = false;
			this.conectarSignalR();
			this.escutarProgressoListas(listas);
			this.reconectarSignalR(listas);
		});
	}

	public escutarProgressoListas(listas: Array<Lista>) {
		this._hubConnection.start().then(() => {
			this.signalRStatus = true;
			this._hubConnection.on("ProgressoIndexacao", conteudo => {

				let lista = listas.find(l => l.listaId == conteudo.lista);

				if (!lista) return;

				if (conteudo.porcentagem)
					lista.porcentagemIndexacao = parseFloat(conteudo.porcentagem);

				if (conteudo.status)
					lista.listaStatusId = parseInt(conteudo.status);

				if (conteudo.processando !== undefined && conteudo.processando !== null && conteudo.processando !== '')
					lista.processando = conteudo.processando;

				if (conteudo.quantidadeLivre)
					lista["quantidadeLivre"] = parseInt(conteudo.quantidadeLivre);

				if (conteudo.quantidadeDistribuida)
					lista["quantidadeDistribuida"] = parseInt(conteudo.quantidadeDistribuida);

				if (conteudo.totalGeral)
					lista["totalGeral"] = parseInt(conteudo.totalGeral);

				if (conteudo.execucaoInicio)
					lista["execucaoInicio"] = conteudo.execucaoInicio;

				if (conteudo.execucaoAtual) {
					let ea = dayjs(conteudo.execucaoAtual, "DD/MM/YYYY HH:mm:ss");
					let eaf = dayjs();
					let diferenca = (ea.isBefore(eaf)) ? eaf.diff(ea, 'seconds') : (0 - ea.diff(eaf, 'seconds'));
					lista["execucaoAtual"] = conteudo.execucaoAtual;
					lista["execucaoAtualFront"] = dayjs().format("DD/MM/YYYY HH:mm:ss");
					lista["execucaoAtualDiferenca"] = diferenca;
				}

				if (conteudo.tempoPagina)
					lista["tempoPagina"] = conteudo.tempoPagina;

				if (conteudo.paginasRestantes)
					lista["paginasRestantes"] = conteudo.paginasRestantes;

				if (conteudo.tamanho)
					lista["tamanho"] = conteudo.tamanho;

				if (conteudo.totalIndexado)
					lista["totalIndexado"] = conteudo.totalIndexado;

				if (conteudo.totalRestante)
					lista["totalRestante"] = conteudo.totalRestante;

				localStorage.setItem(`listaIndexacao_${lista.listaId}`, JSON.stringify(lista));
			});
		});
	}

	public paraEscutarProgressoLista() {
		this._hubConnection.stop();
	}

	public obterLista(filtroLista?: FiltroLista): Observable<Array<Lista>> {
		let url = `${this.api}`;
		if (filtroLista)
			url +=
				"?" +
				Object.keys(filtroLista)
					.map(prop => `${prop}=${filtroLista[prop]}`)
					.join("&");
		return this._httpClient
			.get<Array<Lista>>(url).pipe(
				map(lista => lista.map(Lista.fromRaw)));
	}

	public obterListaSemQuantidade(filtroLista?: FiltroLista | any): Observable<Array<Lista>> {
		let url = `${this.api}/obter-sem-quantidade`;
		if (filtroLista)
			url +=
				"?" +
				Object.keys(filtroLista)
					.map(prop => `${prop}=${filtroLista[prop]}`)
					.join("&");
		return this._httpClient
			.get<Array<Lista>>(url).pipe(
				map(lista => lista.map(Lista.fromRaw)));
	}

	public obterListaIgnorandoPermissao(filtroLista?: FiltroLista): Observable<Array<Lista>> {
		let url = `${this.api}/obter-ignorando-permissao`;
		if (filtroLista)
			url +=
				"?" +
				Object.keys(filtroLista)
					.map(prop => `${prop}=${filtroLista[prop]}`)
					.join("&");
		return this._httpClient
			.get<Array<Lista>>(url).pipe(
				map(lista => lista.map(Lista.fromRaw)));
	}

	public obterListaEmIndexacao(listaId?: number): Observable<Array<any>> {
		let url = `${this.api}/listas-em-indexacao`;

		if (listaId)
			url += `?id=${listaId}`;

		return this._httpClient.get<Array<any>>(url)
	}


	public obterHistoricoIndexacao(listId, dataInicio, dataFim): Observable<any> {
		let url = `${this.api}/historicoIndexacao/${listId}`;
		url += "?dataInicio=" + dataInicio + "&dataFim=" + dataFim;
		return this._httpClient.get<any>(url)
	}

	public obterHistoricoIndexacaoTodasListas(listId, dataInicio, dataFim, carteiraId, origemId): Observable<Array<any>> {
		let url = `${this.api}/historicoIndexacaotodaslistas?dataInicio=${dataInicio}&dataFim=${dataFim}`;

		if (listId != null || listId != undefined)
			url += "&id=" + listId;

		if (carteiraId != null || carteiraId != undefined)
			url += "&carteiraId=" + carteiraId;

		if (origemId != null || origemId != undefined)
			url += "&origemId=" + origemId;

		return this._httpClient.get<any>(url)
	}

	public obterHistoricoIndexacaoPorListaId(listaId): Observable<Array<any>> {
		let url = `${this.api}/historico-indexacao-por-listaid/${listaId}`;
		return this._httpClient.get<any>(url)
	}

	public obterEstrategiaRecorrencia(): Array<any> {

		let recorrencias = new Array<any>(
			{ 'id': EstrategiaRecorrencia.agendada, 'descricao': this.translateService.instant('estrategiaTipoEnvio.agendado') },
			{ 'id': EstrategiaRecorrencia.imediata, 'descricao': this.translateService.instant('estrategiaTipoEnvio.imediato') },
			{ 'id': EstrategiaRecorrencia.recorrente, 'descricao': this.translateService.instant('estrategiaTipoEnvio.recorrente') }
		);

		return recorrencias;
	}

	public obterEstrategiaStatus(): Array<any> {

		let recorrencias = new Array<any>(
			{ 'id': EstrategiaStatus.Ativo, 'descricao': this.translateService.instant('telaPadrao.ativo') },
			{ 'id': EstrategiaStatus.Inativo, 'descricao': this.translateService.instant('telaPadrao.inativo') },
			{ 'id': EstrategiaStatus.Excluido, 'descricao': this.translateService.instant('telaPadrao.excluida') }
		);

		return recorrencias;
	}

	public obterEstrategiaFiltroPadrao(): Array<any> {
		let filtroPadrao = new Array<any>(
			{ 'id': true, 'descricao': 'Sim' },
			{ 'id': false, 'descricao': 'Não' }

		);

		return filtroPadrao;
	}
	public obterEstrategiaOrdenacaoPadrao(): Array<any> {
		let ordenacaoPadrao = new Array<any>(
			{ 'id': true, 'descricao': 'Sim' },
			{ 'id': false, 'descricao': 'Não' }

		);

		return ordenacaoPadrao;
	}


	public obterListaPorId(id) {
		return this._httpClient
			.get<Lista>(`${this.api}/${id}`).pipe(
				map(Lista.fromRaw));
	}

	private prepararFormData(lista: Lista, arquivos: Array<any>) {
		const formData = new FormData();
		formData.append("lista", JSON.stringify(lista));
		this.adicionarArquivosFormData(formData, arquivos);
		return formData;
	}

	private adicionarArquivosFormData(formData: FormData, arquivos: Array<any>) {
		arquivos.forEach(arquivo =>
			formData.append(arquivo.descricao, arquivo.file, arquivo.file.name)
		);
	}

	public criarLista(lista: Lista) {
		return this._httpClient.post<number>(
			`${this.api}/`,
			lista
		);
	}

	public atualizarLista(lista: Lista) {
		return this._httpClient.put<number>(
			`${this.api}/${lista.listaId}`,
			lista
		);
	}

	public ativarLista(id) {
		return this._httpClient.patch<Lista>(
			`${this.api}/${id}/indexar`,
			null
		);
	}

	public atualizarCardCustomizado(dados: any) {
		return this._httpClient.patch<Lista>(
			`${this.api}/${dados.id}/card-customizado`,
			dados
		);
	}

	public atualizarCardSomaUnicos(dados: any) {
		return this._httpClient.patch<Lista>(
			`${this.api}/${dados.id}/card-soma-unicos`,
			dados
		);
	}

	public pararLista(id) {
		return this._httpClient.patch<Lista>(
			`${this.api}/${id}/parar`,
			null
		);
	}

	public pausarLista(id) {
		return this._httpClient.patch<Lista>(
			`${this.api}/${id}/pausar`,
			null
		);
	}

	public cancelarLista(id) {
		return this._httpClient.patch<Lista>(
			`${this.api}/${id}/cancelar`,
			null
		);
	}

	public limparLista(listaId: number): any {
		return this._httpClient.patch<Lista>(
			`${this.api}/${listaId}/limpar`,
			null
		);
	}

	// public zerarLista(listaId: number): any {
	// 	return this._httpClient.patch<Lista>(
	// 		`${environment.serverUrl}/api/listas/${listaId}/zerar`,
	// 		null
	// 	);
	// }

	public obterArquivosLista(listaId) {
		return this._httpClient.get<Array<any>>(
			`${this.api}/${listaId}/arquivos`
		);
	}

	public obterListaCard(listId, carteiraId) {
		return this._httpClient.get<ListaCard>(
			`/api/dashboard/card-lista?listaId=${listId}&carteiraId=${carteiraId}`
		);
	}

	limparDistribuidosLista(listaId: number): any {
		return this._httpClient.patch<Lista>(
			`${this.api}/${listaId}/limpar-distribuidos`,
			null
		);
	}

	public obterListaGrafico(filtroDashPrincipal: FiltroDashPrincipal) {
		return this._httpClient.get<object>(
			`/api/dashboard/grafico${convertToQueryString(
				filtroDashPrincipal
			)}`
		);
	}

	public criarArquivosLista(listaId, listaArquivo: Array<any>) {
		let formData: FormData = new FormData();
		listaArquivo.forEach(arquivo =>
			formData.append(arquivo.name, arquivo, arquivo.name)
		);

		let params = new HttpParams();
		const options = {
			params: params,
			reportProgress: true
		};

		const req = new HttpRequest(
			'POST',
			`${this.api}/${listaId}/arquivos`,
			formData,
			options
		);
		return this._httpClient.request(req);
	}

	public getArquivoListaUrl(listaId, processarArquivoAutomaticamente)
	{ 
		return `${this.urlApiCompleta}/${listaId}/arquivos/${processarArquivoAutomaticamente}`;
	}

	obterPreviewArquivoImportacao(listaId, arquivoId: number, consistente: boolean, pagina) {
		return this._httpClient.get<any>(
			`${this.api}/${listaId}/arquivos/${arquivoId}/preview?consistente=${consistente}&pagina=${pagina}`
		);
	}

	obterPreviewHistoricoErro(historicoId, pagina) {
		return this._httpClient.get<any>(
			`${this.api}/historicoIndexacao/${historicoId}/previewerro?pagina=${pagina}`
		);
	}



	confirmarArquivos(listaId, listaArquivoSelecionado: Array<number>) {
		return this._httpClient.patch<any>(`${this.api}/${listaId}/arquivos/statusProcessamento?idArquivos=${listaArquivoSelecionado.join(",")}`, null);
	}

	obterCamposMapeados(id) {
		return this._httpClient.get<Array<any>>(
			`${this.api}/${id}/campos-mapeados`
		);
	}

	excluirArquivos(listaId, listaArquivoSelecionado: Array<number>) {
		const req = new HttpRequest(
			"DELETE",
			`${this.api}/${listaId}/arquivos?idArquivos=${listaArquivoSelecionado.join(
				","
			)}`
		);
		return this._httpClient.request(req);
	}

	incluirItemLista(listaId, json) {
		return this._httpClient.post<any>(
			`${this.api}/${listaId}/inserir-item`,
			json
		);
	}

	alterarItemLista(listaId, idItem, json) {
		return this._httpClient
			.put<number>(
				`${this.api}/${listaId}/${idItem}/alterar-item`,
				json
			).pipe(
				map(m => m));
	}

	excluirItemLista(lista, itemId) {
		return this._httpClient.delete(
			`${this.api}/${lista}/${itemId}/excluir-item`
		);
	}

	public getImportacaoAutomaticaPorId(id: number): Observable<ImportacaoAutomaticaConfiguracao> {
		let url = `${this.api}/importacaoautomaticaporid/${id}`;
		return this._httpClient.get<ImportacaoAutomaticaConfiguracao>(url).pipe(map(ImportacaoAutomaticaConfiguracao.fromRaw));
	}

	public getImportacaoAutomaticaHistorico(id: number, blockLoading: boolean): Observable<Array<ImportacaoAutomaticaHistorico>> {
		if (blockLoading) {
			let headers = new HttpHeaders().set('blockLoading', 'true');
			return this._httpClient.get<Array<ImportacaoAutomaticaHistorico>>(`${this.api}/importacaoautomaticahistorico/${id}`, { headers: headers });//.pipe(map(ImportacaoAutomaticaHistorico.fromRaw));
		}
		else {
			return this._httpClient.get<Array<ImportacaoAutomaticaHistorico>>(`${this.api}/importacaoautomaticahistorico/${id}`);//.pipe(map(ImportacaoAutomaticaHistorico.fromRaw));
		}
	}

	public getImportacaoAutomaticaPorListaId(listaId: number): Observable<ImportacaoAutomaticaConfiguracao> {
		let url = `${this.api}/importacaoautomaticaporlista/${listaId}`;
		return this._httpClient.get<ImportacaoAutomaticaConfiguracao>(url).pipe(map(ImportacaoAutomaticaConfiguracao.fromRaw));
	}

	public setStatusImportacaoAutomatica(id: number, listaId: number, status: boolean): Observable<ImportacaoAutomaticaConfiguracao> {
		return this._httpClient.post<ImportacaoAutomaticaConfiguracao>(
			`${this.api}/importacaoautomatica/${id}/status`,
			{ listaId: listaId, ativo: status }
		).pipe(map(ImportacaoAutomaticaConfiguracao.fromRaw));
	}

	atualizarImportacaoAutomatica(importacaoAutomatica: ImportacaoAutomaticaConfiguracao) {
		return this._httpClient.put<ImportacaoAutomaticaConfiguracao>(
			`${this.api}/importacaoautomatica/${importacaoAutomatica.importacaoAutomaticaConfiguracaoId}`,
			importacaoAutomatica
		);
	}

	validarDiretorio(diretorio: any) {
		return this._httpClient.post(
			`${this.api}/validar-diretorio`,
			diretorio
		);
	}


	public obterLimpezaAutomaticaPorListaId(listaId: number): Observable<LimpezaAutomaticaConfiguracao> {
		let url = `${this.api}/limpezaautomaticaporlista/${listaId}`;
		return this._httpClient.get<LimpezaAutomaticaConfiguracao>(url).pipe(map(LimpezaAutomaticaConfiguracao.fromRaw));
	}

	public setStatusLimpezaAutomatica(id: number, listaId: number, status: boolean): Observable<LimpezaAutomaticaConfiguracao> {
		return this._httpClient.post<LimpezaAutomaticaConfiguracao>(
			`${this.api}/limpezaautomatica/${id}/status`,
			{ listaId: listaId, ativo: status }
		).pipe(map(LimpezaAutomaticaConfiguracao.fromRaw));
	}

	public gravarLimpezaAutomatica(model: LimpezaAutomaticaConfiguracao) {
		if (isNullOrZero(model.limpezaAutomaticaConfiguracaoId)) {
			return this._httpClient.post<number>(`${this.api}/limpezaautomatica/`, model);
		} else {
			return this._httpClient.put<number>(`${this.api}/limpezaautomatica/${model.limpezaAutomaticaConfiguracaoId}`, model);
		}
	}


	public getUrlLimpezaFiltroArquivoUpload = (listaId) => `${this.urlApiCompleta}/${listaId}/upload-limpeza-filtro-arquivo`;

	public obterLimpezaFiltroArquivoPorListaId(listaId: number): Observable<Array<LimpezaFiltroArquivo>> {
		return this._httpClient.get<Array<LimpezaFiltroArquivo>>(`${this.api}/${listaId}/obter-limpezas-filtro-arquivo-lista/`)
			.pipe(map(LimpezaFiltroArquivo.fromRawArray));
		//return this._httpClient.get<LimpezaFiltroArquivo>(url).pipe(map(LimpezaFiltroArquivo.fromRaw));
	}

	public obterListasAguardandoIndexacao(blockLoading: boolean = false): Observable<any> {
		if (blockLoading) {
			let headers = new HttpHeaders().set('blockLoading', 'true');
			return this._httpClient.get<any>(`${this.api}/listas-aguardando-indexacao`, { headers: headers });
		}
		else {
			return this._httpClient.get<any>(`${this.api}/listas-aguardando-indexacao`);
		}
	}

	public baixarArquivoErro(idArquivo: number) {
		return this.http
			.get(`${this.urlApiCompleta}/baixarArquivoErro/${idArquivo}`,
				{
					responseType: ResponseContentType.Blob,
					headers: new Headers({
						Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`
					})
				}
			);
	}

	public obterListaInformacoesBasicas(listaId: number): Observable<any> {
		return this._httpClient.get<any>(`${this.api}/obter-lista-informacoes-basicas/${listaId}`);
	}

	public gerarRegistrosIA(id:number): Observable<NaturalLanguage> {
		return this._httpClient.post<NaturalLanguage>(`${this.api}/${id}/gerar-registros-ia`, null);
	}

}