import { Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
//import "rxjs/add/operator/combineLatest";
import { Subscription, combineLatest as observableCombineLatest } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";
import { isNull, isNullOrEmpty, isNullOrZero } from "../../common/utils";

import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroQueryService } from "../../common/service/filtro-query.service";

import { FiltroLista } from "../../common/model/filtro-lista";
import { FiltroStatusRegistroLista, Lista } from "../../common/model/lista";
import { Estrategia, EstrategiaAcaoCampanhaTipo } from "../../common/model/estrategia";
import { FiltroQuery } from "../../common/model/filtro-query";

import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { ESBuilderData } from "../../esbuilder/models";
import { FormEstrategiaListaSelecionada } from "../../estrategia/models/form-estrategia-lista-selecionada.model";
import { EstrategiaRefactor } from "../../common/model/estrategiaRefactor";
import { EstrategiaService } from "../../common/service/estrategia.service";
import MD5 from 'crypto-js/md5';
import { FiltroQueryEstrategia } from "../../common/model/filtro-query-estrategia";
import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";

@Component({
    selector: "app-form-filtro-query-padrao",
    templateUrl: "./form-filtro-query-padrao.component.html",
    styleUrls: ["./form-filtro-query-padrao.component.scss"],
    providers: [
        { useClass: FiltroQueryService, provide: FiltroQueryService },
        { useClass: ListaService, provide: ListaService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass:CarteiraService, provide: CarteiraService},
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService }
    ]
})
export class FormFiltroQueryPadraoComponent implements OnDestroy {

    //#region [ Properts ] 	

    debug: boolean = false;
    dirty: boolean;
	listaId: number;
	carteiraId:number;
    filtroQuery: FiltroQuery;
    listas: Lista[];
    carteiras: Array<Carteira> = [];
    preferenciasUsuario: PreferenciasUsuario;
    tabSelected: number = 0;

    query: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
    dataRules: ESBuilderData = new ESBuilderData();
    finishedLoading: boolean = false;

    estrategias: EstrategiaRefactor[] = [];
    estrategiasSource: EstrategiaRefactor[] = [];
    estrategiasTarget: EstrategiaRefactor[] = [];

    subFiltroQuery: Subscription;
    subListas: Subscription;
    subEstrategias: Subscription;

    //#endregion

    //#region [ GETS/SETS ]

    public get filtroDefault(): string {
        return '{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}';
    }

    public get filtroRegraDefault(): string {
        return '[]';
    }

    public get erros() {
        if (!this.dirty) 
            return;

        return this.filtroQuery.validar();
    }

    public get isEdit(): boolean {
        return (this.filtroQuery != undefined && this.filtroQuery.filtroQueryId != undefined);
    }

    

    //#endregion

    @ViewChild("elasticsearchFilter", { static: false })
    elasticsearchFilter: ElasticsearchFilterComponent;
    constructor(
        private route: ActivatedRoute,
        private filtroQueryService: FiltroQueryService,
        private listaService: ListaService,
        private estrategiaService: EstrategiaService,
        private carteiraService: CarteiraService,
        private translate: TranslateService,
        private preferenciasService: PreferenciasUsuarioService,
        private router: Router,
        private customAlertService: CustomAlertService
    ) {
        this.filtroQuery = new FiltroQuery();
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();
        this.route.params.subscribe(params => this.carregar(params));
        this.route.queryParams.subscribe(params => {
            if (params.debug != undefined)
                this.debug = (params.debug === 'true');
        });
    }

    //#region [ Eventos do componente ]

    ngOnDestroy() {
        if (this.subFiltroQuery) this.subFiltroQuery.unsubscribe();
        if (this.subListas) this.subListas.unsubscribe();
        if (this.subEstrategias) this.subEstrategias.unsubscribe();
    }

    //#endregion

    //#region [ Dados ]	

    carregar(params: any) {
        if (!params.id) {
            this.obterCarteiras();
            return;
        }

        this.subFiltroQuery = this.filtroQueryService.obterPorId(params.id).subscribe((filtro: FiltroQuery) => {
            this.filtroQuery = filtro;

            this.query = JSON.parse(filtro.filtro);
            this.dataRules = ESBuilderData.fromRaw(JSON.parse(filtro.filtroRegras));

            this.obterEstrategias();

            this.finishedLoading = true;
        });
    }

    obterCarteiras()
	{
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
      
			this.carteiras = carteiras.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            });

			if (this.preferenciasUsuario.existePreferenciaCarteira) {
				this.carteiraId = this.preferenciasUsuario.carteiraId as number;
			} else {
				let padrao = this.carteiras.find(f => f.padrao) as Carteira;
				this.carteiraId = padrao.carteiraId;
			}

			this.obterListas();
		});
	}

	carteiraChange()
	{
		this.listaId = null;
		this.filtroQuery = new FiltroQuery();
		this.obterListas();
	}

    obterListas() {
        let filtro: FiltroLista = {carteiraId: this.carteiraId  ,ignorarErroPesquisa: true } as FiltroLista;

		this.subListas = this.listaService.obterLista(filtro).subscribe((listas: Lista[]) => {
			this.listas = listas.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            });
		});
    }
        
    obterEstrategias() {
        let filtro = { 'lista': this.filtroQuery.listaId, 'estrategiaAcaoCampanhaTipos': [EstrategiaAcaoCampanhaTipo.Exportacao] };

        this.subEstrategias = this.estrategiaService.obterEstrategiasPaginadas(filtro).subscribe(result => {
            this.estrategias = result.filter((f: EstrategiaRefactor) => f.filtroMigrado).sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            })
                .map((m: EstrategiaRefactor) => { m.hashFiltroAntigo = MD5(m.filtro).toString(); return m; });

             if (this.isEdit) {
                
                this.estrategiasSource = this.estrategias.filter((f: EstrategiaRefactor) => 
                    f.filtroQueryEstrategias.length == 0 ||  f.filtroQueryEstrategias.filter((a:FiltroQueryEstrategia)=> a.filtroQueryId == this.filtroQuery.filtroQueryId && a.ativo).length == 0 );
                 this.estrategiasTarget = this.estrategias.filter((f: EstrategiaRefactor) => 
                    f.filtroQueryEstrategias.filter((a:FiltroQueryEstrategia)=> a.filtroQueryId == this.filtroQuery.filtroQueryId && a.ativo).length>0);
             } else {
                 this.estrategiasSource = this.estrategias;
                 this.estrategiasTarget = [];
            }
        });
    }

    //#endregion

    //#region [ Eventos ]	

	disabledTab(index: number) {
        if (this.isEdit)
            return false;

		return !(this.tabSelected >= index);
	}

	tabChange(e: MatTabChangeEvent) {
		this.tabSelected = e.index;
	}

    next() {
		if (this.tabSelected >= 0 && this.tabSelected < 2)
			this.tabSelected++;

            this.translate
			.get(["telaFiltrosPadroes.filtrosPadroes", "telaEstrategia.filtrosInvalidos"])
			.subscribe((res: Object) => {
				let valid = this.ExisteErrosCadastro();

				if (valid) {
					swal.fire(res["telaFiltrosPadroes.filtrosPadroes"], res["telaEstrategia.filtrosInvalidos"], "error");
					let k = Object.keys(valid);
					if (k) 
                        this.tabSelected = 0;
					return;
				}
			});
    }

    previous() {
		if (this.tabSelected > 0 && this.tabSelected <= 1)
			this.tabSelected--;
	}

    listaChange(event: any) {
        if (isNullOrZero(this.filtroQuery.listaId)) {
            this.estrategiasSource = []
            this.estrategiasTarget = [];
            return;
        }

        let listaSelecionada = this.listas.find((f: Lista) => f.listaId.toString() == this.filtroQuery.listaId.toString()) as Lista;
        this.filtroQuery.listaId = listaSelecionada.listaId;
        this.filtroQuery.filtro = this.filtroDefault;
        this.filtroQuery.filtroRegras = this.filtroRegraDefault;

        this.obterEstrategias();

        this.finishedLoading = true;
    }

    updateQuery(event: any) {
        
        if (!isNullOrEmpty(event.query)) {
            this.query = event.query;
            this.filtroQuery.filtro = JSON.stringify(event.query);
        }

        if (!isNullOrEmpty(event.dataRules)) {
            this.dataRules = event.dataRules;
            this.filtroQuery.filtroRegras = JSON.stringify(event.dataRules);
        }

        // Atualizar as estratégias
        this.estrategiasTarget = this.estrategiasTarget.map((m: EstrategiaRefactor) => {
            let update = FiltroQueryEstrategia.AtualizarFiltroPadrao(this.filtroQuery, m);
            m.filtro = update.query;
            m.filtroRegras = update.filtroRegras;
            m.filtroAlterado = (m.hashFiltroAntigo != MD5(m.filtro).toString());
            return m;
        });
    }

    quantityListChanged(event: any) { }

    //#endregion

    //#region [ Salvar ]

    ExisteErrosCadastro() :boolean
    {
        this.dirty = true;
   
        if (this.dataRules.rules.length > 0) {
            if (this.dataRules.rules.some(s => !isNull(s.rules) && s.rules.length > 0 ? s.rules.some(a=> a._valid == false) == true :s._valid == false)) {
                return true;
            }
        }

        if (this.filtroQuery.validar()) {
            return true;
        }

        return false
    }

    salvar() {
        if (this.ExisteErrosCadastro())
        {
            this.customAlertService.show("telaFiltrosPadroes.filtrosPadroes", "telaPadrao.camposInvalidos", "error");
            return;
        }

        let sucesso = (response: any) => {
            this.customAlertService.show("telaFiltrosPadroes.filtrosPadroes", "telaPadrao.sucessoSalvar", "success");
            this.router.navigate(["/app/filtroquery/"]);
        };

        let erro = () => {
            this.customAlertService.show("telaFiltrosPadroes.filtrosPadroes", "telaPadrao.erroSalvar", "error");
        };

        let e: EstrategiaRefactor[] = [...this.estrategiasSource, ...this.estrategiasTarget];

        this.filtroQuery.filtroQueryEstrategiasAlterar = e
            .filter((f: EstrategiaRefactor) => (f.filtroAlterado))
            .map((m: EstrategiaRefactor) => ({
                "listaEstrategiaId": m.listaEstrategiaId,
                "filtro": m.filtro,
                "filtroRegras": m.filtroRegras,
                "filtroQueryEstrategias": m.filtroQueryEstrategias
            }));

        if (!this.filtroQuery.filtroQueryId)
            return this.filtroQueryService.criar(this.filtroQuery).subscribe(sucesso, erro);

        this.filtroQueryService.atualizar(this.filtroQuery.filtroQueryId, this.filtroQuery).subscribe(sucesso, erro);
    }

    //#endregion
}