import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, NgZone, ElementRef } from "@angular/core";
import { ListaService } from "../../common/service/lista.service";

import { DashListaProvedorService } from '../../common/service/dash-lista-provedor.service';
import { FiltroDashListaProvedor } from "../model/filtro-dash-lista-provedor";
import { Lista } from "../../common/model/lista";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";
import { FiltroLista } from "../../common/model/filtro-lista";
import { isNullOrEmpty } from "../../common/utils";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";

@Component({
    selector: "app-dash-lista-provedor",
    templateUrl: "./dash-lista-provedor.component.html",
    styleUrls: ["./dash-lista-provedor.component.scss"],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService }
    ]
})
export class DashListaProvedorComponent implements OnDestroy {
    filtro: FiltroDashListaProvedor = new FiltroDashListaProvedor();
    dirty: boolean;
    listas: Array<Lista> = [];
    preferenciasUsuario: PreferenciasUsuario;
    carteiras: Array<Carteira> = [];

    constructor(private listaService: ListaService,
        private dashListaProvedorService: DashListaProvedorService,
        private preferenciasService: PreferenciasUsuarioService,
        private carteiraService: CarteiraService,
        private zone: NgZone) {

        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

        this.obterCarteiras();
    }

    public get erros() {
        if (!this.dirty) return;
        return this.filtro.validar();
    }

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1);
    }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;

            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }

            this.obterListas();
            this.carteiras.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
            });
        });
    }

    obterListas() {
        let filtro = new FiltroLista();
        filtro.carteiraId = this.filtro.carteiraId;

        this.listaService.obterLista(filtro).subscribe(result => {
            this.listas = result;
            if (result.length > 0) {
                this.filtro.listaId = result[0].listaId;
                this.pesquisar();
            }
            this.listas.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
            });
        });
    }

    carteiraChange() {
        this.obterListas();
    }

    ngOnDestroy() { }

    //#region [ Carregamento dos dados ]

    pesquisar() {
        this.dirty = true;

        this.filtro.listaNome = this.listas.find((l: Lista) => l.listaId == this.filtro.listaId).nome;

        if (this.filtro.validar())
            return;

        this.dashListaProvedorService.onPesquisar(this.filtro);
    }

    //#endregion
}