export class QueryFilter {
    //filter: any;
    sort: SorterContainer[];
    start: number;
    limit: number;
}
/*
export interface FilterContainer {
    property: string;
    value: any;
    typeFilterAnd: boolean;
}*/

export class SorterContainer {
    property: string;
    direction: string;
}