let filterText: Array<any> = [
    {
        filterType: "igual",
        name: "esbuilder.igual",
        negation: false,
        keyword: true,
        content: "term",
        parameters: [{ name: "query", alias: "esbuilder.igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "diferente", value: false, negation: true }
    },
    {
        filterType: "diferente",
        name: "esbuilder.diferente",
        negation: true,
        keyword: true,
        content: "term",
        parameters: [{ name: "query", alias: "esbuilder.diferente", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "igual", value: false, negation: false }
    },
    {
        filterType: "contem",
        name: "esbuilder.contem",
        negation: false,
        keyword: false,
        content: "query_string",
        parameters: [{ name: "query", alias: "esbuilder.contem", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "nao_contem", value: false, negation: false }
    },
    {
        filterType: "nao_contem",
        name: "esbuilder.nao_contem",
        negation: true,
        keyword: false,
        content: "query_string",
        parameters: [{ name: "query", alias: "esbuilder.nao_contem", negation: true, validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "contem", value: false, negation: false }
    },
    {
        filterType: "contem_algum",
        name: "esbuilder.contem_algum",
        negation: false,
        keyword: true,
        content: "terms",
        parameters: [{ name: "query", alias: "esbuilder.contem_algum", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "contem_algum", value: false, negation: true }
    },
    {
        filterType: "entre",
        name: "esbuilder.entre",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [
            { name: "gte", alias: "esbuilder.maior_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } },
            { name: "lte", alias: "esbuilder.menor_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }
        ],
        reverse: { filterType: "entre", value: false, negation: true }
    },
    {
        filterType: "vazio",
        name: "esbuilder.vazio",
        negation: true,
        keyword: false,
        content: "wildcard",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_vazio", value: false, negation: false }
    },
    {
        filterType: "nao_vazio",
        name: "esbuilder.nao_vazio",
        negation: false,
        keyword: false,
        content: "wildcard",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "vazio", value: false, negation: true }
    },
    {
        filterType: "existe",
        name: "esbuilder.existe",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: false }
    },
    {
        filterType: "nao_existe",
        name: "esbuilder.nao_existe",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false }
    }
];

let filterNumeric: Array<any> = [
    {
        filterType: "igual",
        name: "esbuilder.igual",
        negation: false,
        keyword: false,
        content: "match",
        parameters: [{ name: "query", alias: "esbuilder.igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "diferente", value: false, negation: false }
    },
    {
        filterType: "diferente",
        name: "esbuilder.diferente",
        negation: true,
        keyword: false,
        content: "match",
        parameters: [{ name: "query", alias: "esbuilder.diferente", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "igual", value: false, negation: false }
    },
    {
        filterType: "entre",
        name: "esbuilder.entre",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [
            { name: "gte", alias: "esbuilder.maior_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } },
            { name: "lte", alias: "esbuilder.menor_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }
        ],
        reverse: { filterType: "entre", value: false, negation: true }
    },
    {
        filterType: "maior_que",
        name: "esbuilder.maior_que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "gt", alias: "esbuilder.maior_que", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "menor_ou_igual", value: false, negation: false }
    },
    {
        filterType: "menor_que",
        name: "esbuilder.menor_que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "lt", alias: "esbuilder.menor_que", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "maior_ou_igual", value: false, negation: false }
    },
    {
        filterType: "maior_ou_igual",
        name: "esbuilder.maior_ou_igual",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "gte", alias: "esbuilder.maior_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "menor_que", value: false, negation: false }
    },
    {
        filterType: "menor_ou_igual",
        name: "esbuilder.menor_ou_igual",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "lte", alias: "esbuilder.menor_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "maior_que", value: false, negation: false }
    },
    {
        filterType: "existe",
        name: "esbuilder.existe",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: false }
    },
    {
        filterType: "nao_existe",
        name: "esbuilder.nao_existe",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false }
    }
];

let filterDate = [
    {
        filterType: "entre",
        name: "esbuilder.entre",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [
            { name: "gte", alias: "esbuilder.maior_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } },
            { name: "lte", alias: "esbuilder.menor_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }
        ],
        reverse: { filterType: "entre", value: false, negation: true }
    },
    {
        filterType: "maior_que",
        name: "esbuilder.maior_que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "gt", alias: "esbuilder.maior_que", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "menor_ou_igual", value: false, negation: false }
    },
    {
        filterType: "menor_que",
        name: "esbuilder.menor_que",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "lt", alias: "esbuilder.menor_que", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "maior_ou_igual", value: false, negation: false }
    },
    {
        filterType: "maior_ou_igual",
        name: "esbuilder.maior_ou_igual",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "gte", alias: "esbuilder.maior_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "menor_que", value: false, negation: false }
    },
    {
        filterType: "menor_ou_igual",
        name: "esbuilder.menor_ou_igual",
        negation: false,
        keyword: false,
        content: "range",
        parameters: [{ name: "lte", alias: "esbuilder.menor_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "maior_que", value: false, negation: false }
    },
    {
        filterType: "vazio",
        name: "esbuilder.vazio",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_vazio", value: false, negation: false }
    },
    {
        filterType: "nao_vazio",
        name: "esbuilder.nao_vazio",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "vazio", value: false, negation: false }
    },
    {
        filterType: "existe",
        name: "esbuilder.existe",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: false }
    },
    {
        filterType: "nao_existe",
        name: "esbuilder.nao_existe",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false }
    }
];

let filterList = [
    {
        filterType: "contem_algum",
        name: "esbuilder.contem_algum_list",
        negation: false,
        keyword: true,
        content: "terms",
        parameters: [{ name: "query", alias: "esbuilder.contem", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "nao_contem", value: false, negation: false }
    },
    {
        filterType: "nao_contem",
        name: "esbuilder.nao_contem",
        negation: true,
        keyword: true,
        content: "terms",
        parameters: [{ name: "query", alias: "esbuilder.nao_contem", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "contem_algum", value: false, negation: false }
    },
    {
        filterType: "vazio",
        name: "esbuilder.vazio",
        negation: true,
        keyword: false,
        content: "wildcard",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_vazio", value: false, negation: false }
    },
    {
        filterType: "nao_vazio",
        name: "esbuilder.nao_vazio",
        negation: false,
        keyword: false,
        content: "wildcard",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "vazio", value: false, negation: true }
    },
    {
        filterType: "existe",
        name: "esbuilder.existe",
        negation: false,
        keyword: false,
        content: "exists",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: false }
    },
    {
        filterType: "nao_existe",
        name: "esbuilder.nao_existe",
        negation: false,
        keyword: false,
        content: "script",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false }
    }
];

let filterBoolean = [
    {
        filterType: "true",
        name: "esbuilder.true",
        negation: false,
        keyword: false,
        content: "term",
        parameters: [{ name: "query", alias: "esbuilder.texto", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "false", value: false, negation: false }
    },
    {
        filterType: "false",
        name: "esbuilder.false",
        negation: false,
        keyword: false,
        content: "term",
        parameters: [{ name: "query", alias: "esbuilder.texto", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "true", value: true, negation: false }
    }
];

let filterJson = [
    // Inteiro /////////////////////////////////////////////////////////////////////////////
    {
        filterType: "contem",
        name: "esbuilder.contem",
        negation: false,
        keyword: false,
        content: "nested",
        parameters: [{ name: "query", alias: "esbuilder.contem", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "nao_contem", value: false, negation: true },
        nestedType: "Inteiro",
        nestedContent: "match",
        nestedList: true
    },
    {
        filterType: "nao_contem",
        name: "esbuilder.nao_contem",
        negation: true,
        keyword: false,
        content: "nested",
        parameters: [{ name: "query", alias: "esbuilder.nao_contem", negation: true, validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "contem", value: false, negation: false },
        nestedType: "Inteiro",
        nestedContent: "match",
        nestedList: true
    },
    {
        filterType: "existe",
        name: "esbuilder.existe",
        negation: false,
        keyword: false,
        content: "nested",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: true },
        nestedType: "Inteiro",
        nestedContent: "exists",
        nestedList: false
    },
    {
        filterType: "nao_existe",
        name: "esbuilder.nao_existe",
        negation: true,
        keyword: false,
        content: "nested",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false },
        nestedType: "Inteiro",
        nestedContent: "exists",
        nestedList: false
    },
    // Long ////////////////////////////////////////////////////////////////////////////////
    {
        filterType: "igual",
        name: "esbuilder.igual",
        negation: false,
        keyword: false,
        content: "nested",
        parameters: [{ name: "query", alias: "esbuilder.igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }],
        reverse: { filterType: "igual", value: false, negation: true },
        nestedType: "Long",
        nestedContent: "match",
        nestedList: false
    },
    {
        filterType: "existe",
        name: "esbuilder.existe",
        negation: false,
        keyword: false,
        content: "nested",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: true },
        nestedType: "Long",
        nestedContent: "exists",
        nestedList: false
    },
    {
        filterType: "nao_existe",
        name: "esbuilder.nao_existe",
        negation: true,
        keyword: false,
        content: "nested",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false },
        nestedType: "Long",
        nestedContent: "exists",
        nestedList: false
    },
    // Data ////////////////////////////////////////////////////////////////////////////////
    {
        filterType: "entre",
        name: "esbuilder.entre",
        negation: false,
        keyword: false,
        content: "nested",
        parameters: [
            { name: "gte", alias: "esbuilder.maior_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } },
            { name: "lte", alias: "esbuilder.menor_ou_igual", validation: { presence: { allowEmpty: false, message: "^campoObrigatorio" } } }
        ],
        reverse: { filterType: "entre", value: false, negation: true },
        nestedType: "Data",
        nestedContent: "range",
        nestedList: false
    },
    {
        filterType: "existe",
        name: "esbuilder.existe",
        negation: false,
        keyword: false,
        content: "nested",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "nao_existe", value: false, negation: true },
        nestedType: "Data",
        nestedContent: "exists",
        nestedList: false
    },
    {
        filterType: "nao_existe",
        name: "esbuilder.nao_existe",
        negation: true,
        keyword: false,
        content: "nested",
        parameters: [{ name: "field", alias: "Campo" }],
        reverse: { filterType: "existe", value: false, negation: false },
        nestedType: "Data",
        nestedContent: "exists",
        nestedList: false
    }
]

export const ESBuilderFilterType: any = {
    Texto: filterText,
    Inteiro: filterNumeric,
    Long: filterNumeric,
    Decimal: filterNumeric,
    Numerico: filterNumeric,
    Data: filterDate,
    Lista: filterList,
    Boolean: filterBoolean,
    Nested: filterJson
}