import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CampoIntegracao } from "../model/campo-integracao";

@Injectable()
export class CamposIntegracaoService {
  private api: string;

  constructor(private httpClient: HttpClient) { 
      this.api = '/api/listaCampoTipo/';
  }

  obterTodosCamposIntegracao(): Observable<Array<CampoIntegracao>> {
    return this.httpClient.get<Array<CampoIntegracao>>(`${this.api}`);
  }
}