import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { FiltroDashPrincipal } from "../../model/filtro-dash-principal";
import { ListaService } from "../../../common/service/lista.service";
import { FiltroLista } from "../../../common/model/filtro-lista";
import { environment } from "../../../../../environments/environment";
import { CarteiraService } from "../../../common/service/carteira.service";
import { Carteira } from "../../../common/model/carteira";
import { Lista } from "../../../common/model/lista";
import { PreferenciasUsuarioService } from "../../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../../common/model/preferencias-usuario";
import { Subscription, interval } from "rxjs";

@Component({
    selector: "app-filtro-dash",
    templateUrl: "./filtro-dash.component.html",
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
    ]
})
export class FiltroDashComponent implements OnInit {
    preferenciasUsuario: PreferenciasUsuario;
    filtro: FiltroDashPrincipal;
    cardState: string = "in";
    @Output()
    search: EventEmitter<FiltroDashPrincipal>;
    private intervalSubscription: Subscription;
    dirty: boolean;
    carteiras: Array<Carteira> = [];
    listas: Array<Lista> = [];

    public AppConfig: any;

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1 || this.filtro.carteira.length == 1);
    }

    constructor(
        private listaService: ListaService,
        private carteiraService: CarteiraService,
        private preferenciasService: PreferenciasUsuarioService
    ) {
        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

        this.filtro = new FiltroDashPrincipal();
        this.search = new EventEmitter<FiltroDashPrincipal>();
    }

    public get erros() {
        if (!this.dirty) return;
        return this.filtro.validar();
    }

    ngOnInit() {
        let filtroLista = new FiltroLista();
        filtroLista.ignorarErroPesquisa = true;
        filtroLista.ignorarIndisponivel = true;

        // this.listaService
        //     .obterLista(filtroLista)
        //     .subscribe(lista => {
        //         this.listas = lista;
        //         this.filtro.listaPossivel = lista;
        //     });
        // //this.emit();
        this.AppConfig = environment;

        this.obterCarteiras();
    }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;
            this.filtro.carteira = carteiras;

            if (this.preferenciasUsuario.existePreferenciaCarteira && this.carteiras.some((carteira) => carteira.carteiraId === this.preferenciasUsuario.carteiraId)) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }

            // this.carteiraChange();
            // this.emit();
            this.obterListas(true);
        });
    }

    carteiraChange() {
        this.filtro.carteiraId = Number(this.filtro.carteiraId);
        //this.obterListas();
        this.obterListas(false);
    }

    obterListas(forcarEmit:boolean) {
        let filtroLista = new FiltroLista();
        filtroLista.ignorarErroPesquisa = true;
        filtroLista.ignorarIndisponivel = true;

        if (this.filtro.carteiraId > 0)
            filtroLista.carteiraId = this.filtro.carteiraId;

            this.listaService.obterListaSemQuantidade(filtroLista).subscribe(listas => {
                this.filtro.listaPossivel = listas
        
            
                if (this.preferenciasUsuario.existePreferenciaLista && this.filtro.listaPossivel.some((lista) => lista.listaId === this.preferenciasUsuario.listaId)) 
                {
                    this.filtro.listaSelecionada = this.preferenciasUsuario.listaId.toString();
                }
                if (forcarEmit)
                    this.emit();
            });
        }
    startAutoRefresh(event) {
       
        if (event.checked) 
        this.iniciarIntervalo();
    else 
        this.ngOnDestroy();
}
iniciarIntervalo() {
    this.intervalSubscription = interval(300000).subscribe(() => {
        this.emit();
    });
}
ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
    emit() {
        this.dirty = true;

        if (this.filtro.validar()) return;

        this.search.emit(this.filtro);
        this.cardState = "out";
    }

    clean() {
        this.filtro = new FiltroDashPrincipal();
        this.filtro.listaPossivel = this.listas;
        this.filtro.carteira = this.carteiras;
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
        this.search.emit(this.filtro);
    }

    openReportPaneas() {
        const url = 'https://bi.talktelecom.net/reports/bradesco/rel_miner.aspx';
        window.open(url, '_blank');
    }
}
