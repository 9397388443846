import { CarteiraUsuario } from "./carteira-usuario";
import { Template } from "./template";
const validate = require("validate.js");
export class Carteira {

    carteiraId: number;
    nome: string;
    dataCadastro: Date;
    usuarioCadastro: number;
    ativo: boolean;
    padrao: boolean;
    carteiraUsuario: CarteiraUsuario[];
    template: Array<any>;

    nomeUsuarioCadastro: string;

    constructor() {

    }

    validar() {
        let validacao: any = {
            nome: { presence: { allowEmpty: false, message: '^campoObrigatorio' } }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(raw: any): Carteira {
        return Object.assign(new Carteira(), raw);
    }
}