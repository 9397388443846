
import {map} from 'rxjs/operators';
import "rxjs/add/operator/map";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Usuario } from "../model/usuario";
import { convertToQueryString } from "./query-string";
import { Senha } from '../../autenticacao/model/senha';

@Injectable()
export class UsuarioService {
	private api: string;

	constructor(private httpClient: HttpClient) { 
		this.api = '/api/usuarios';
	}

	obterUsuario(id) {
		return this.httpClient
			.get<Usuario>(`${this.api}/${id}`).pipe(
			map(usuario => Usuario.fromRaw(usuario)));
	}

	
	meuUsuario() {
		return this.httpClient
			.get<Usuario>(`${this.api}/meu-usuario`).pipe(
			map(usuario => Usuario.fromRaw(usuario)));
	}

	obterUsuarios(filtro?) {
		return this.httpClient.get<Array<Usuario>>(
			`${this.api}${convertToQueryString(filtro)}`
		);
	}

	verificaUsuarioExiste(login: string) {
		return this.httpClient.get<Array<Usuario>>(
			`${this.api}/verificaUsuarioExiste?login=${login}`
		);
	}

	obterApikeys(filtro?) {
		return this.httpClient.get<Array<Usuario>>(
			`${this.api}/apikey${convertToQueryString(
				filtro
			)}`
		);
	}

	recuperarSenha(usuario: Usuario) {
		return this.httpClient.post<any>(
			`${this.api}/recuperarSenha`,
			usuario
		);
	}

	alterarSenha(senha : Senha) {
		return this.httpClient.post<any>(
			`${this.api}/alterarSenha`,
			senha
		);
	}

	verificarChaveAlteracao(chave: string) {
		let objChave = {
			chave: chave
		}
		return this.httpClient.post<any>(
			`${this.api}/validarChaveAlteracaoSenha`,
			objChave
		);
	}

	criarApiKey(usuario: Usuario) {
		return this.httpClient.post(
			`${this.api}/apikey`,
			usuario
		);
	}

	desativar(id) {
		return this.httpClient.patch(
			`${this.api}/${id}/desativar`,
			null
		);
	}

	criar(usuario: Usuario) {
		return this.httpClient.post(
			`${this.api}`,
			usuario
		);
	}

	atualizar(usuario: Usuario) {
		return this.httpClient.put(
			`${this.api}`,
			usuario
		);
	}
}
