import { Component } from '@angular/core';
import { FabMenuModel } from './fab-menu/fab-menu.model';

@Component({
  selector: 'my-app-layout',
  templateUrl: './layout.component.html',
  providers: [
 
]
})
export class LayoutComponent {

  public fabMenuLista: FabMenuModel[] = [
    { icone: "person", descricao: "menu.novoUsuario" , rota: ['/app/usuario/create'] },
    { icone: "storage", descricao: "menu.novaLista" , rota: ['/app/lista/create'] },
    { icone: "business", descricao: "menu.novaestrategia" , rota: ['/app/estrategia/create'] }
  ];
}
