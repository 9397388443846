import { TranslateService } from '@ngx-translate/core';
import { find } from 'core-js/core/array';
import { IndicadoresEstrategia } from './../../common/model/indicadores-estrategia';
import { style } from '@angular/animations';
import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2, ComponentFactoryResolver, ViewContainerRef, ViewChild, TemplateRef, ComponentRef } from "@angular/core";
import { ListaService } from "../../common/service/lista.service";
import { MatDialog, MatTabChangeEvent } from '@angular/material';
import { FiltroLista } from "../../common/model/filtro-lista";
import { FiltroIndicadoresExtrategia } from "../../common/model/filtro-indicadores-extrategia";
import { Tarefa } from "../../common/model/tarefa";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ExcelService } from "../../common/service/excel.service";
import { TarefaService } from "../../common/service/tarefa.service";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { Estrategia } from "../../common/model/estrategia";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";
import { environment } from "../../../../environments/environment";
import * as signalR from "@aspnet/signalr";
import { FiltroTarefa } from "../../common/model/filtro-tarefa";
import { downloadFile } from "../../common/service/download-file";
import { InfoExecucaoEstrategiaComponent } from "../../estrategia/lista-estrategia/info-execucao-estrategia/info-execucao-estrategia.component";
import { isNullOrEmpty, isNull } from "../../common/utils";
import { Observable, Subscription } from "rxjs";
import { SituacaoExecucao } from "../../common/model/situacao-execucao";
import { FluxoService } from "../../common/service/fluxo.service";
import { ConfigProvider } from '../../../common/providers/config.provider';
import { CustomToolTipComponent } from '../../common/directives/custom-tool-tip/custom-tool-tip.component';

@Component({
	selector: "app-lista-tarefa",
	templateUrl: "./lista-tarefa.component.html",
	styleUrls: ["./lista-tarefa.scss"],
	providers: [
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ useClass: TarefaService, provide: TarefaService },
		{ useClass: ExcelService, provide: ExcelService },
		{ useClass: EstrategiaService, provide: EstrategiaService },
		{ useClass: FluxoService, provide: FluxoService }
	]
})
export class ListaTarefaComponent implements OnInit, OnDestroy {
	listaTarefa: Array<Tarefa>;
	listaTarefaHistorico: Array<Tarefa>;
	listaTarefaExecucao: Array<Tarefa>;
	listaEstrategia: Array<Estrategia>;
	filtro: FiltroTarefa;
	filtroDashboard: FiltroIndicadoresExtrategia;
	btnNextVisible: boolean;
	signalRStatus: boolean;
	tabSelected: number = 0;
	filtroCompleto: boolean;
	totalDistrivuidos: number = 0;
	totalExportado: number;
	totalErro: number = 0;
	totalSucesso: number = 0;
	dataAtual = new Date();
	diaCorrente = this.dataAtual.getDate();
	anoCorrente = this.dataAtual.getFullYear();
	mesCorrente = this.dataAtual.getMonth();
	monthYear: Element | null;
	previousBtn: Element | null;
	nextBtn: Element | null;
	dates: NodeListOf<Element>;
	tooltip: HTMLElement | null;
	delay: number = 0;
	offset: number = 10;
	placement: string = "right";
	private _hubConnection: HubConnection | undefined;


	constructor(private tarefaService: TarefaService,
		private excelService: ExcelService,
		private estrategiaService: EstrategiaService,
		private customAlertService: CustomAlertService,
		private fluxoService: FluxoService,
		private translateService: TranslateService,
		private configProvider: ConfigProvider,
		private componentFactoryResolver: ComponentFactoryResolver,
		public dialog: MatDialog,
		private renderer: Renderer2
	) {
		this.filtro = new FiltroTarefa();
		this.filtroDashboard = new FiltroIndicadoresExtrategia();
		this.filtroCompleto = true;
		this.totalExportado = 0;
	}


	disabledTab(index: number) {
		return !(this.tabSelected >= index);
	}

	tabChange(e: MatTabChangeEvent) {
		if (e.index == 1) {
			this.anoCorrente = this.dataAtual.getFullYear();
			this.mesCorrente = this.dataAtual.getMonth();
			this.obterIndicadores();
		}

		this.filtroCompleto = e.index == 0;
		this.tabSelected = e.index;
	}

	dataIguais(data1: Date, data2: Date) {
		if (data1.getDate() === data2.getDate() &&
			data1.getMonth() === data2.getMonth() &&
			data1.getFullYear() === data2.getFullYear())
			return true;
		else
			return false;
	}

	dataMaior(data1: Date, data2: Date) {
		if (data1.getDate() > data2.getDate() &&
			data1.getMonth() === data2.getMonth() &&
			data1.getFullYear() === data2.getFullYear())
			return true;
		else
			return false;
	}

	obterIndicadores() {
		this.filtroDashboard.ano = this.anoCorrente;
		this.filtroDashboard.mes = this.mesCorrente + 1;
		this.filtroDashboard.carteiraId = this.filtro.carteiraId;
		this.filtroDashboard.listaId = this.filtro.lista;
		this.filtroDashboard.listaEstrategiaId = this.filtro.listaEstrategia;

		this.estrategiaService.ObterIndicadoresEstrategia(this.filtroDashboard).subscribe(
			indicadores => {
				this.montarCalendario(indicadores);
			},
			error => {
				this.customAlertService.show(
					"telaEstrategia.estrategia",
					"telaPadrao.erro",
					"error"
				);
			}
		);

	}

	show(div: any, texto: string, titulo: string, dia: Date) {
		this.create(texto, titulo, dia);
		this.setPosition(div, dia);
		this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
	}

	hide() {
		if (this.tooltip != undefined) {
			this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
			this.renderer.removeChild(document.body, this.tooltip);
			this.tooltip = undefined;
		}
	}

	create(texto: string, titulo: string, dia: Date) {
		this.hide();
		this.tooltip = this.renderer.createElement('div');

		let tooltipcorpo = this.renderer.createElement('div');
		this.renderer.addClass(tooltipcorpo, 'TituloToolTip');

		let tooltipTitulo = this.renderer.createElement('h4');
		tooltipTitulo.innerHTML = titulo;
		this.renderer.addClass(tooltipTitulo, 'H4ToolTip');
		this.renderer.appendChild(tooltipcorpo, tooltipTitulo);

		let tooltipmsg = this.renderer.createElement('div');
		this.renderer.addClass(tooltipmsg, 'CorpoToolTip');
		tooltipmsg.innerHTML = texto
		this.renderer.appendChild(tooltipcorpo, tooltipmsg);

		this.renderer.appendChild(this.tooltip, tooltipcorpo);
		this.tooltip.addEventListener("mouseleave", function () {
			this.hide();
		}.bind(this));
		this.renderer.appendChild(document.body, this.tooltip);
		this.renderer.addClass(this.tooltip, 'ng-tooltip');

		if (dia.getDay() === 6)
			this.renderer.addClass(this.tooltip, `ng-tooltip-left`);
		else
			this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
		this.renderer.addClass(this.tooltip, 'tooltip-conatiner');
		this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);


	}

	setPosition(div: any, dia: Date) {
		const hostPos = div.getBoundingClientRect();
		const tooltipPos = this.tooltip.getBoundingClientRect();
		const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		let top, left;

		top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
		if (dia.getDay() === 6)
			left = hostPos.left - tooltipPos.width - this.offset;
		else
			left = (hostPos.right) + this.offset;
		this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
		this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
	}

	montarCalendario(indicadoresEstrategia: Array<IndicadoresEstrategia>) {
		let ExisteRetorno = false;
		this.totalDistrivuidos = 0;
		this.totalSucesso = 0;
		this.totalErro = 0;

		if (indicadoresEstrategia != null && indicadoresEstrategia != undefined)
			ExisteRetorno = true;

		this.monthYear = document.querySelector("#month-year");
		this.previousBtn = document.querySelector("#previous-month");
		this.nextBtn = document.querySelector("#next-month");
		this.dates = document.querySelectorAll(".date");

		const firstDay = new Date(this.anoCorrente, this.mesCorrente).getDay();
		const lastDate = new Date(this.anoCorrente, this.mesCorrente + 1, 0).getDate();
		let ano = new Date().getFullYear()
		let mes = new Date().getMonth();
		this.btnNextVisible = this.mesCorrente >= mes && this.anoCorrente >= ano ? false : true;

		if (this.monthYear != null && this.monthYear != undefined)
			this.monthYear.textContent = `${this.translateService.instant('calendario.mes.' + this.mesCorrente.toString())} ${this.anoCorrente}`;

		for (let i = 0; i < this.dates.length; i++) {
			this.dates[i].classList.remove("day-disabled")
			this.dates[i].classList.remove("diaFuturo");
			this.dates[i].classList.remove("diaAtual");
			this.dates[i].innerHTML = "";

			this.dates[i].addEventListener("mouseenter", function () {
				this.hide();
			}.bind(this));

			if (i < firstDay || i >= lastDate + firstDay) {
				this.dates[i].classList.add("day-disabled")
			} else {

				let data = new Date(this.anoCorrente, this.mesCorrente, i - firstDay + 1);

				let evento = document.createElement("div");
				let dia = document.createElement("div");

				if (this.dataIguais(data, this.dataAtual))
					this.dates[i].classList.add("diaAtual");

				dia.classList.add("dia");
				dia.addEventListener("mouseenter", function () {
					this.hide();
				}.bind(this));
				evento.classList.add("evento");
				dia.textContent = (i - firstDay + 1).toString();
				evento.appendChild(dia);

				if (ExisteRetorno) {

					let itemDia = indicadoresEstrategia.find(w => this.dataIguais(new Date(w.data), data));
					if (itemDia != null && itemDia != undefined) {
						this.totalDistrivuidos += itemDia.totalExportado;
						this.totalErro += itemDia.qtdeErro;
						this.totalSucesso += itemDia.qtdeSucesso;

						if ((this.filtro.somenteExecucaoComErro && itemDia.qtdeErro > 0) || (!this.filtro.somenteExecucaoComErro)) {
							let info = document.createElement("div");
							let alerta = document.createElement("div");
							let erro = document.createElement("div");
							let sucesso = document.createElement("div");

							alerta.classList.add("task--warning");
							info.classList.add("task--info");
							sucesso.classList.add("task--sucesso");
							erro.classList.add("task--danger");

							let labelLista = document.createElement("label");
							let labelEstrategia = document.createElement("label");

							labelLista.innerHTML = this.translateService.instant('telaLista.listas') + ": <b>" + itemDia.qtdeLista + "</b>";

							labelLista.addEventListener("mouseenter", function () {
								this.show(labelLista, itemDia != undefined ? itemDia.nomeListas : "", this.translateService.instant('telaLista.listas'), data);
							}.bind(this));

							if (this.filtroDashboard.listaId == undefined) {
								let br = document.createElement("br");
								info.appendChild(labelLista);
								info.appendChild(br)
							}

							labelEstrategia.innerHTML = this.translateService.instant('telaEstrategia.estrategias') + ":<b>" + itemDia.qtdeEstrategia + "</b>";
							labelEstrategia.addEventListener("mouseenter", function () {
								this.show(labelEstrategia, itemDia != undefined ? itemDia.nomeEstrategias : "", this.translateService.instant('telaEstrategia.estrategias'), data);
							}.bind(this));
							info.appendChild(labelEstrategia);

							alerta.addEventListener("mouseenter", function () {
								this.hide();
							}.bind(this));

							alerta.innerHTML = this.translateService.instant('telaTarefa.media') + ": <b>" + itemDia.mediaExecucao + "</b><br>" +
								this.translateService.instant('telaTarefa.distribuidos') + ": <b>" + itemDia.totalExportado.toLocaleString('pt-BR') + "</b><br>" +
								this.translateService.instant('telaTarefa.execucoes') + ": <b>" + itemDia.qtdeExecucoes + "</b>";
							sucesso.innerHTML = this.translateService.instant('telaTarefa.sucesso') + ": <b>" + itemDia.qtdeSucesso + "</b>";
							erro.innerHTML = this.translateService.instant('telaTarefa.erro') + ": <b>" + itemDia.qtdeErro + "</b>";

							if (!this.filtroDashboard.listaId == undefined || this.filtroDashboard.listaEstrategiaId == undefined)
								evento.appendChild(info);

							evento.appendChild(alerta);
							evento.appendChild(sucesso);

							if (+itemDia.qtdeErro > 0)
								evento.appendChild(erro);

						}
						else {
							evento.appendChild(this.criarMensagemCalendario("calendario.execucaoComSucesso", "task--mensage-sucess"));
						}
					}
					else {
						if (this.dataMaior(data, this.dataAtual)) {
							this.dates[i].classList.add("diaFuturo");
						}
						else {
							evento.appendChild(this.criarMensagemCalendario("calendario.semExecucao", "task--mensage"));
						}
					}
				}


				this.dates[i].appendChild(evento);
			}

		}
	}

	criarMensagemCalendario(mensagem: string, cssMensagem: string) {
		let info = document.createElement("div");
		info.classList.add(cssMensagem);
		this.translateService.get(mensagem)
			.subscribe((res: string) => {

				info.textContent = res;
			});
		return info;
	}

	previousBtnClick() {
		this.mesCorrente--;
		if (this.mesCorrente < 0) {
			this.mesCorrente = 11;
			this.anoCorrente--;
		}
		this.obterIndicadores();
	};

	nextBtnClick() {
		this.mesCorrente++;
		if (this.mesCorrente > 11) {
			this.mesCorrente = 0;
			this.anoCorrente++;
		}
		this.obterIndicadores();
	};

	interval: any;

	ngOnInit() {
		//this.carregarLista();
		this.conectarSignalR();
		this.escutarSignalR();
		this.reconectarSignalR();

		// if (isNull(this.interval) == false)
		// 	clearInterval(this.interval);

		// this.interval = setInterval(() => { this.carregarLista(true); }, 30000);

		// this.carregarLista();
	}


	ngOnDestroy() {
		this._hubConnection.stop();

		if (isNull(this.interval) == false)
			clearInterval(this.interval);
	}

	conectarSignalR() {
		let endpoint = this.configProvider.getConfig().serverUrl;

		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${endpoint}/notificacaohub`, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
			.configureLogging(signalR.LogLevel.Information)
			.build();
	}

	reconectarSignalR() {
		this._hubConnection.onclose(() => {
			this.signalRStatus = false;
			this.conectarSignalR();
			this.escutarSignalR();
			this.reconectarSignalR();
		});
	}

	escutarSignalR() {
		this._hubConnection.start().then(() => {
			this.signalRStatus = true;
			this._hubConnection.on('AtualizarTarefa', (conteudo: any) => {
				if (!conteudo) return;

				// if (conteudo.atualizacaoStatus)
				// 	this.carregarLista(true);

				if (conteudo.atualizacaoPorcentagem && !isNull(this.listaTarefaExecucao)) {
					this.listaTarefaExecucao.forEach((f: Tarefa, idx: number) => {
						if (f.tarefaId == conteudo.tarefaId)
							f.porcentagemExecucao = conteudo.porcentagem;
						//f.ordem=idx;
					});
				}
				let lista1 = this.listaTarefaExecucao.filter(f => (f.status == 1) && f.situacaoExecucao != SituacaoExecucao.cancel).sort((a: any, b: any) => {

					let key1 = new Date(a.execucaoInicio);
					let key2 = new Date(b.execucaoInicio);
					return (key1 > key2) ? 1 : ((key1 == key2) ? 0 : -1);
				});
				let lista2 = this.listaTarefaExecucao.filter(f => (f.status == 13) && f.situacaoExecucao != SituacaoExecucao.cancel).sort((a: any, b: any) => {

					let key1 = new Date(a.execucaoInicio);
					let key2 = new Date(b.execucaoInicio);
					return (key1 > key2) ? 1 : ((key1 == key2) ? 0 : -1);
				});
				this.listaTarefaExecucao = lista1.concat(lista2);

			});
			// this._hubConnection.on('AtualizarInfoEstrategiasParaExecutar', (conteudo: any) => {
			// 	if (!conteudo) return;
			// 	this.carregarLista(true);
			// });
		}).catch(err => console.log(err));
	}

	carregarLista(blockLoading: boolean = false) {
		this.obterEstrategiasParaExecutar(blockLoading);
		this.obterTarefas(blockLoading);
	}

	obterEstrategiasParaExecutar(blockLoading: boolean = false) {
		this.tarefaService.obterEstrategiasParaExecutar(this.filtro, blockLoading).subscribe(estrategias => {
			this.listaEstrategia = estrategias.sort((a: any, b: any) => {
				let key1 = new Date(a.proximaExecucao);
				let key2 = new Date(b.proximaExecucao);
				return (key1 > key2) ? 1 : ((key1 == key2) ? 0 : -1);
			});
		});
	}

	obterTarefas(blockLoading: boolean = false) {
		this.tarefaService.obterTarefas(this.filtro, blockLoading).subscribe(tarefa => {
			this.listaTarefaExecucao = tarefa.filter(f => (f.status == 13 || f.status == 1) && f.situacaoExecucao != SituacaoExecucao.cancel).sort((a: any, b: any) => {
				let key1 = new Date(a.execucaoInicio);
				let key2 = new Date(b.execucaoInicio);
				return (key1 > key2) ? 1 : ((key1 == key2) ? 0 : -1);
			});
			this.listaTarefaHistorico = tarefa.filter(f => f.status != 1 && f.status != 13);
			this.totalExportado = this.listaTarefaHistorico.map(x => x.quantidade).reduce(function (a, b) {
				return a + b;
			}, 0);
		});
	}

	filtrarTarefa(filtroTarefa: FiltroTarefa) {
		this.filtro = filtroTarefa;
		this.totalExportado = 0;

		if (this.filtroCompleto) {
			if (this.signalRStatus == false) {
				this.conectarSignalR();
				this.escutarSignalR();
				this.reconectarSignalR();

			}

			if (isNull(this.interval) == false)
				clearInterval(this.interval);

			this.interval = setInterval(() => { this.carregarLista(true); }, 30000);
			this.carregarLista();
		}
		else {
			this.obterIndicadores();
		}
	}

	existeCampanha(campanhaNome: string): boolean {
		return (!isNullOrEmpty(campanhaNome));
	}

	ativarPendenteExecucao(estrategia) {
		this.fluxoService
			.obterQueryQuantidadeRegistrosPost(estrategia.listaId, estrategia.estrategiaFiltro)
			.subscribe((result: any) => {
				let quantidade = (result) ? result : 0;

				if (quantidade > 0) {
					this.customAlertService
						.confirmationMessage("telaEstrategia.confirmarReativacao")
						.then(() =>
							this.estrategiaService.ativarPendenteExecucao(estrategia.listaEstrategiaId).subscribe(() => this.carregarLista())
						);
				} else {
					this.customAlertService
						.confirmationMessage("telaEstrategia.confirmarReativacaoSemRegistro")
						.then(() =>
							this.estrategiaService.ativarPendenteExecucao(estrategia.listaEstrategiaId).subscribe(() => this.carregarLista())
						);
				}
			});
	}

	forcarExecucao(estrategia) {
		this.fluxoService
			.obterQueryQuantidadeRegistrosPost(estrategia.listaId, estrategia.estrategiaFiltro)
			.subscribe((result: any) => {
				let quantidade = (result) ? result : 0;

				if (quantidade > 0) {
					this.customAlertService
						.confirmationMessage("telaEstrategia.forcarExecucao")
						.then(() =>
							this.estrategiaService.forcarExecucao(estrategia.estrategiaId || estrategia.listaEstrategiaId).subscribe(() => this.carregarLista())
						);
				} else {

					this.customAlertService
						.confirmationMessage("telaEstrategia.forcarExecucaoSemRegistro")
						.then(() =>
							this.estrategiaService.forcarExecucao(estrategia.estrategiaId || estrategia.listaEstrategiaId).subscribe(() => this.carregarLista())
						);
				}
			});
	}

	playExecucao(listaEstrategiaId: number) {
		this.confirmacao("telaTarefa.playExecucaoConfirm", () => this.estrategiaService.atualizarSituacaoExecucao(listaEstrategiaId, SituacaoExecucao.play).subscribe(() => this.obterEstrategiasParaExecutar(true)));
	}

	pauseExecucao(listaEstrategiaId: number) {
		this.confirmacao("telaTarefa.pauseExecucaoConfirm", () => this.estrategiaService.atualizarSituacaoExecucao(listaEstrategiaId, SituacaoExecucao.pause).subscribe(() => this.obterEstrategiasParaExecutar(true)));
	}

	cancelExecucao(listaEstrategiaId: number) {
		this.confirmacao("telaTarefa.cancelExecucaoConfirm", () => this.estrategiaService.atualizarSituacaoExecucao(listaEstrategiaId, SituacaoExecucao.cancel).subscribe(() => this.carregarLista(true)));
	}

	exibirInfoExecucao(listaEstrategiaId: number): void {
		this.estrategiaService.obterEstrategiaPorId(listaEstrategiaId).subscribe(e => this.dialog.open(InfoExecucaoEstrategiaComponent, { width: '500px', data: e }));
	}

	confirmacao(tranalateMsg: string, execute: () => {}) {
		return this.customAlertService.confirmationMessage(tranalateMsg).then(execute);
	}

	limparTarefasNaoExecutadas() {
		this.tarefaService.limparTarefasNaoExecutadas().subscribe(r => this.carregarLista());
	}

	exportarExcel() {
		this.excelService.exportAsExcelFile(this.converterTarefas(this.listaTarefaHistorico), this.translateService.instant('telaTarefa.execucaoEstrategia'));
	}

	formatarData(data: Date): string {
		const dia = data.getDate().toString().padStart(2, '0');
		const mes = (data.getMonth() + 1).toString().padStart(2, '0');
		const ano = data.getFullYear();
		const horas = data.getHours().toString().padStart(2, '0');
		const minutos = data.getMinutes().toString().padStart(2, '0');

		return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
	}

	converterTarefas(tarefas: Tarefa[]) {
		return tarefas.map(tarefa => {
			return {
				tarefaId: tarefa.tarefaId,
				listaId: tarefa.listaId,
				listaNome: tarefa.listaNome,
				listaEstrategiaId: tarefa.listaEstrategiaId,
				estrategiaNome: tarefa.estrategiaNome,
				campanhaNome: tarefa.campanhaNome,
				quantidade: tarefa.quantidade,
				quantidadeRegistrosErro: tarefa.QuantidadeRegistrosErro,
				execucaoTempo: tarefa.execucaoTempo,
				execucaoInicio: tarefa.execucaoInicio ? this.formatarData(new Date(tarefa.execucaoInicio)) : '',
				execucaoFim: tarefa.execucaoFim ? this.formatarData(new Date(tarefa.execucaoFim)) : '',
				recorrente: tarefa.recorrente,
				usuarioSolicitanteNome: tarefa.usuarioSolicitanteNome,
			};
		});
	}

	baixarUltimoHistorico(lista: any) {
		this.estrategiaService.baixarUltimoHistorico(lista.listaEstrategiaId, lista.tarefaId).subscribe(
			(res) => {
				let extensao = res["_body"].type == "application/text" ? "txt" : "zip";
				let nomeArquivo = `${lista.estrategiaNome.replace(/[.*+\-?^${}()|[\]\\]/g, '').replace(/\s\s+/g, ' ').replace(/\s/g, '_')}_${lista.tarefaId}.${extensao}`;
				(downloadFile(res, nomeArquivo))
			},
			() => (this.customAlertService.show("telaEstrategia.download", "telaEstrategia.expurgoArquivo", "info")));
	}
}
