import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Estrategia } from "../../../common/model/estrategia";

@Component({
  selector: 'app-info-execucao-estrategia',
  templateUrl: 'info-execucao-estrategia.component.html',
})
export class InfoExecucaoEstrategiaComponent {
  estrategia: Estrategia;
  constructor(
    public dialogRef: MatDialogRef<InfoExecucaoEstrategiaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.estrategia = data;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}