import { TranslateService } from '@ngx-translate/core';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { convertToQueryString } from "../../common/service/query-string";
import { Fluxo, FluxoTipo } from "../model/fluxo";
import { FluxoQuery } from "../model/fluxo-query";
import { FluxoProcessoTipo, FluxoProcesso } from "../model/fluxo-processo";
import { Estrategia } from "../model/estrategia";
import { isNull } from "../utils/";

@Injectable()
export class FluxoService {
    private api: string;

    constructor(private httpClient: HttpClient, private translateService: TranslateService) 
    { 
        this.api = '/api/fluxos';
    }
                
    public obterFluxos(filtro: any) {
        return this.httpClient.get<Array<Fluxo>>(
            `${this.api}${convertToQueryString(filtro)}`
        );
    }

    public obterPorId(id) {
        return this.httpClient.get<Fluxo>(`${this.api}/${id}`)
            .pipe(map(f => {
                let fluxo = Fluxo.fromRaw(f);
                if (!isNull(fluxo.fluxoProcesso)) {
                    fluxo.fluxoProcesso = fluxo.fluxoProcesso.map((i: FluxoProcesso) => {
                        let fp = FluxoProcesso.fromRaw(i);

                        if (!isNull(fp.estrategia))
                            fp.estrategia = Estrategia.fromRaw(fp.estrategia);

                        return fp;
                    });
                }
                return fluxo;
            }));
    }

    public criar(fluxo: Fluxo) {
        return this.httpClient.post<number>(`${this.api}/`, fluxo);
    }

    public atualizar(fluxo: Fluxo) {
        return this.httpClient.put<number>(`${this.api}/${fluxo.fluxoId}`, fluxo);
    }

    public desativarFluxo(fluxoId: number) {
        return this.httpClient.patch<any>(`${this.api}/${fluxoId}/desativar-fluxo`, null);
    }

    public obterEstrategias(fluxoId: number, listaId: number) {
        return this.httpClient.get<Array<any>>(`${this.api}/estrategias-sem-fluxo/${fluxoId}/${listaId}`);
    }

    public obterFluxosTipos() {
        const tipos = of<Array<any>>([
            { 'fluxoTipoId': FluxoTipo.processo, 'nome': this.translateService.instant('telaFluxo.processo') },
            { 'fluxoTipoId': FluxoTipo.cascata, 'nome': this.translateService.instant('telaFluxo.cascata')},
            { 'fluxoTipoId': FluxoTipo.circular, 'nome': this.translateService.instant('telaFluxo.circular') }
        ]);
        return tipos;
    }

    public obterFluxosProcessosTipos(fluxoTipoId: FluxoTipo) {

        let tipos: Observable<Array<any>>;

        if (fluxoTipoId == FluxoTipo.cascata) {
            tipos = of<Array<any>>([
                //{ 'tipoId': FluxoProcessoTipo.filtro, 'nome': 'Nó de filtro' },
                { 'tipoId': FluxoProcessoTipo.criacaoEstrategia, 'nome': this.translateService.instant('telaFluxo.noCriacaoEstrategia') },
                { 'tipoId': FluxoProcessoTipo.informativoEstrategia, 'nome': this.translateService.instant('telaFluxo.noInformativoEstrategia') }
            ]);
        } else if (fluxoTipoId == FluxoTipo.circular) {
            tipos = of<Array<any>>([
                { 'tipoId': FluxoProcessoTipo.decisao, 'nome':  this.translateService.instant('telaFluxo.noDecisao') },
                { 'tipoId': FluxoProcessoTipo.selecaoEstrategia, 'nome': this.translateService.instant('telaFluxo.noSelecaoEstrategia') },
                { 'tipoId': FluxoProcessoTipo.quantidade, 'nome': this.translateService.instant('telaFluxo.noQuantidade') }
            ]);
        } else {
            tipos = of<Array<any>>([
                { 'tipoId': FluxoProcessoTipo.decisao, 'nome': this.translateService.instant('telaFluxo.noDecisao') },
                { 'tipoId': FluxoProcessoTipo.selecaoEstrategia, 'nome': this.translateService.instant('telaFluxo.noSelecaoEstrategia') },
                { 'tipoId': FluxoProcessoTipo.quantidade, 'nome': this.translateService.instant('telaFluxo.noQuantidade') },
                { 'tipoId': FluxoProcessoTipo.informativo, 'nome': this.translateService.instant('telaFluxo.noInformativo') }
            ]);
        }

        return tipos;
    }

    //#region [ Querys ]

    public obterQueryQuantidadeRegistros(listaId: number, query: string) {
        return this.httpClient.get<number>(`${this.api}/query-registros/${listaId}?query=${query}`);
    }

    public obterQueryQuantidadeRegistrosPost(listaId: number, query: string) {
        return this.httpClient.post<number>(`${this.api}/query-registros/`, { 'listaId': listaId, 'query': query });
    }

    public obterQueryQuantidadeRegistrosEstrategiasFiltros(listaId: number, filtroGuid: string, query: string) {
        return this.httpClient.post<number>(`${this.api}/query-registros/`, { 'listaId': listaId, 'filtroGuid': filtroGuid, 'query': query });
    }

    public obterFluxosQuerys(filtro: any) {
        return this.httpClient.get<Array<FluxoQuery>>(`${this.api}/query/${convertToQueryString(filtro)}`);
    }

    public obterQueryPorId(id) {
        return this.httpClient.get<FluxoQuery>(`${this.api}/query/${id}`)
            .pipe(map(query => FluxoQuery.fromRaw(query)));
    }

    public criarQuery(query: FluxoQuery) {
        return this.httpClient.post<number>(`${this.api}/query/`, query);
    }

    public atualizarQuery(query: FluxoQuery) {
        return this.httpClient.put<number>(`${this.api}/query/${query.fluxoQueryId}`, query);
    }

    //#endregion 	
}
