
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "../../../../environments/environment";
import { ElasticsearchResultado } from "../model/elasticsearch-resultado";
import { StorageProxy } from '../../common/service/storage-proxy.service';

@Injectable()
export class ElasticsearchRepositoryService {

	private api: string;
	
	constructor(private _http: HttpClient) { 
		this.api = `/api/Search`;
	}

	public getFromIndex(
		path: String,
		index: String,
		query?
	): Observable<ElasticsearchResultado> {
		return this._http.post<ElasticsearchResultado>(
			`${this.api}/${index}`,
			query
		);
	}

	public getOptionForField(
		index: string,
		listaId: any,
		templateId: any,
		field: string,
		query: any,
		negation: boolean = false
	) {

		field = field.replace('.keyword', '');
		return this._http.post<Array<string>>(
			`${this.api}/lista_${listaId}/${listaId}/${templateId}/${field}/${negation}`,
			query
		);
	}

	public preview(listaId: string, templateId: string, query?): Observable<any> {
		return this._http.post<Array<any>>(
			`${this.api}/lista_${listaId}/${listaId}/${templateId}`,
			query
		);
	}

	public downloadPreview(index: string, listaId: string, templateId: string, query?): Observable<any> {
		return this._http.post<Array<any>>(
			`${this.api}/download/lista_${listaId}/${listaId}/${templateId}`,
			query
		);
	}

	public listaItem(index: string, listaId: number, templateId: string, query?): Observable<any> {
		return this._http.post<Array<any>>(
			`${this.api}/item/lista_${listaId}/${listaId}/${templateId}`,
			query
		);
	}

	public getQuantidadeFiltros(listaId: number, query?) {
		return this._http.post<Array<number>>(
			`${this.api}/quantidadeFiltros/${listaId}`,
			query
		);
	}

	public getQuantidadeFiltrosOrdem(listaId: number, query, ordem = null) {
		return this._http.post<Array<number>>(
			`${this.api}/quantidadeFiltrosOrdem/${listaId}`,
			{ "query": query, "ordem": ordem }
		);
	}

	public getQuantidadeLista(idLista, idEstrategia, query, campo, operacao, campoAgrupadorSomaUnicos, formatacao, somaUnicosCardFormatacao, blockLoading: boolean = false) {

		let headers = new HttpHeaders();

		if (blockLoading)
			headers = headers.set('blockLoading', 'true');

		let parametros = campo ? `?campoExtra=${campo}&operacao=${operacao}` : '';

		if (campo && campoAgrupadorSomaUnicos)
			parametros = parametros + `&campoAgrupadorSomaUnicosExtra=${campoAgrupadorSomaUnicos}`;

		if (campo && formatacao)
			parametros = parametros + `&formatacao=${formatacao}`;

		if (somaUnicosCardFormatacao)
			parametros = parametros + `&somaUnicosCardFormatacao=${somaUnicosCardFormatacao}`;


		return this._http.post<Array<number>>(`${this.api}/quantidades-lista/${idLista}/${idEstrategia}` + parametros, query, { headers: headers });
	}

	public getQuantidadeRegistros(idLista, query, blockLoading: boolean = false) {

		let headers = new HttpHeaders();

		if (blockLoading)
			headers = headers.set('blockLoading', 'true');

		return this._http.post<Array<number>>(`${this.api}/quantidade-registros/${idLista}`, query, { headers: headers });
	}
}
