import { ElementRef, HostBinding, Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-ftn-informativo-estrategia',
    template: `<div class="strategyCreationNodeCard {{node.data.cssClass}}">
                   <div class="contentErrors" *ngIf="node.data.erros">
                      <mat-icon>info</mat-icon>
                   </div>
                   <div class="contentInfo" (click)="selectNodeGraph(node.id)">
                       <label class="sub-title">
                           {{ 'telaFluxo.informativo' | translate }}
                       </label>
                   </div>
                   <div class="contentIcons">
                       <mat-icon class="actions clickable" [matTooltip]="'telaFluxo.visualizarFiltros' | translate" (click)="visualizarFiltros(node.id)" >
                           filter_list
                       </mat-icon>
                       <mat-icon class="actions clickable" [matTooltip]="'telaFluxo.visualizarRegistros' | translate" (click)="mostrarPreview(node.id)" >
                           search
                       </mat-icon>
                   </div>
                   <div class="contentQuantity" (click)="selectNodeGraph(node.id)">
                       {{node.data.informativoQtdRegistros | suffixNumber : 1}}
                   </div>
               </div>`,
    styles: []
})
export class FluxoTemplateNoInformativoEstrategiaComponent {

    @Input()
    guidSelecionado: string;

    @Input()
    node: any;

    @Output()
    selectNode = new EventEmitter<string>();

    @Output()
    visualizaFiltros = new EventEmitter<string>();

    @Output()
    mostraPreview = new EventEmitter<string>();

    @Output()
    ativaExecucao = new EventEmitter<any>();

    @Output()
    forcaExecucao = new EventEmitter<any>();

    constructor() {
        this.selectNode = new EventEmitter<string>();
        this.visualizaFiltros = new EventEmitter<string>();
        this.mostraPreview = new EventEmitter<string>();
        this.ativaExecucao = new EventEmitter<any>();
        this.forcaExecucao = new EventEmitter<any>();
    }

    selectNodeGraph(guidId: string) {
        this.selectNode.emit(guidId);
    }

    visualizarFiltros(guidId: string) {
        this.visualizaFiltros.emit(guidId);
    }

    mostrarPreview(guidId: string) {
        this.mostraPreview.emit(guidId);
    }
}
