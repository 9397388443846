import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { LogHistoricoAlteracao } from "../model/log-historico-alteracao";

@Injectable()
export class LogHitoricoAlteracaoService {
	private api: string;

	constructor(private httpClient: HttpClient) {
		this.api = '/api/logHistoricoAlteracao';
	}

	obterListaLog(id, tipoEntidade) {
		return this.httpClient.get<Array<LogHistoricoAlteracao>>(`${this.api}/${id}/${tipoEntidade}/lista`);
	}
}
