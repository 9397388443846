import { ElementRef, HostBinding, Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-ftn-decisao',
    template: `<div class="decisionNodeCardL {{node.data.cssClass}}" (click)="selectNodeGraph(node.id)">
               </div>
               <div class="decisionNodeCard {{node.data.cssClass}}" (click)="selectNodeGraph(node.id)">
                   <label class="value">{{node.data.descricao}}</label>
               </div>
               <div class="decisionNodeCardR {{node.data.cssClass}}" (click)="selectNodeGraph(node.id)">
               </div>`,
    styles: []
})
export class FluxoTemplateNoDecisaoComponent {
    
    @Input()
    guidSelecionado: string;
    
    @Input()
    node: any;
   
    @Output()
    selectNode = new EventEmitter<string>();

    constructor() {
        this.selectNode = new EventEmitter<string>();
    }

    selectNodeGraph(guidId: string) {
        this.selectNode.emit(guidId);
    }
}