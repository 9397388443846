import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-elasticsearch-field-filter",
  templateUrl: "./elasticsearch-field-filter.component.html"
})
export class ElasticsearchFieldFilterComponent {
  @Input()
  queryType: any;
  _queryParameters: any;
  @Output()
  buscarQuantidadeFiltro: EventEmitter<{}>;

  @Input()
  get queryParameters(): any {
    return this._queryParameters;
  }
  @Output()
  queryParametersChange = new EventEmitter<any>();

  set queryParameters(parameters: any) {
    this._queryParameters = parameters;
    this.queryParametersChange.emit(parameters);
  }

  tipoData: string = "1";

  @Input()
  tipoCampo: string;

  constructor() {
    this.buscarQuantidadeFiltro = new EventEmitter();
  }

  emitirBuscarQuantidadeFiltro() {
    this.buscarQuantidadeFiltro.emit();
  }

  ngOnChanges() {
    if (
      this.queryParameters &&
      ((this.queryParameters.lte &&
        this.queryParameters.lte.toString().startsWith("now")) ||
        (this.queryParameters.gte &&
          this.queryParameters.gte.toString().startsWith("now")))
    ) {
      this.tipoData = "2";
    }
  }
}
