import { GuidService } from "../service/guid.service";
import { isNull, isNullOrEmpty, isObjectEmpty } from "../utils";
import { NNotificacaoTipoEvento } from "./nnotificacao-tipo-evento";
import { UsuarioSimples } from "./usuario";
import { addCustomValidateChildrenArray, addCustomValidateDependsAnotherFieldValue } from "../validators";
import { NNotificacaoEventoConexao } from "./nnotificacao-evento-conexao";

const validate = require("validate.js");

export class NNotificacaoEvento {
    id: number;
    notificacaoId: number;
    notificacaoTipoEventoId: NNotificacaoTipoEvento;
    dataCadastro: Date;
    usuarioCadastroId: number;
    dataAlteracao: Date | null;
    usuarioAlteracaoId: number | null;
    ativo: boolean;

    eventosConexoes: Array<NNotificacaoEventoConexao> = []

    usuarioCadastro: UsuarioSimples;
    usuarioAlteracao: UsuarioSimples | null;

    public get quantidadeConexoes(): number {
        return this.eventosConexoes.length;
    }

    // camposApi: Array<NNotificacaoEventoCamposApi> = [];

    // Usados pela tela
    guid: string;

    private _campos: any;
    private _camposEnvio: any;

    public get isEstrategiaIniciada(): boolean {
        return (this.notificacaoTipoEventoId == NNotificacaoTipoEvento.EstrategiaIniciada);
    }


    constructor() {
        this.guid = GuidService.newGuid();
        this.ativo = true;

        addCustomValidateChildrenArray(validate);
        addCustomValidateDependsAnotherFieldValue(validate);
    }

    tipoEventoChange() {
        this.limparCampos();
        this.camposPorTipoEvento();

        // if (!(this.notificacaoTipoEnvioId != NNotificacaoTipoEnvio.Api && this.notificacaoTipoEventoId != NNotificacaoTipoEvento.EstrategiaIniciada)) {
        //     this.assincrono = true;
        //     this.dispararErro = false;
        // }
    }

    // tipoEnvioChange() {
    //     this.limparCamposTipoEnvio();
    //     //this.camposPorTipoEnvio();

    //     // if (!(this.notificacaoTipoEnvioId != NNotificacaoTipoEnvio.Api && this.notificacaoTipoEventoId != NNotificacaoTipoEvento.EstrategiaIniciada)) {
    //     //     this.assincrono = true;
    //     //     this.dispararErro = false;
    //     // }
    // }

    limparCampos() {
        // this.carteiraId = null;
        // this.listaTemplateId = null;
        // this.listaId = null;
        // this.listaTemplateExportacaoId = null;
        // this.listaTemplateExportacaoId = null;
        // this.provedorId = null;
    }

    // limparCamposTipoEnvio() {
    //     // this.notificacaoConexaoApiId = null;
    //     // this.notificacaoConexaoDbId = null;
    //     // this.notificacaoConexaoRabbitMQId = null;
    //     // this.notificacaoConexaoWhatsappId = null;
    // }

    mostrarCampos(campo: string | null): boolean {

        if (isNull(this._campos))
            return false;

        let mostrar = Object.entries(this._campos)
            .filter(([key, value]) => value) // Filtra pares chave-valor onde o valor é true
            .reduce((acc, [key, value]) => {
                acc[key] = value; // Adiciona ao objeto acumulador apenas as propriedades true
                return acc;
            }, {});

        if (isNullOrEmpty(campo))
            return (isObjectEmpty(mostrar)) ? false : true;
        else
            return (isObjectEmpty(mostrar)) ? false : Object.keys(mostrar).some((f: string) => f == campo);
    }



    camposPorTipoEvento() {
        switch (this.notificacaoTipoEventoId) {
            case NNotificacaoTipoEvento.BancoDadosOffLine:
                this._campos = { "carteira": false, "templateImportacao": false, "lista": false, "estrategia": false };
                break;
            case NNotificacaoTipoEvento.DownloadRegistro:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": false };
                break;
            case NNotificacaoTipoEvento.EstrategiaCriada:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": false };
                break;
            case NNotificacaoTipoEvento.EstrategiaAlterada:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": true };
                break;
            case NNotificacaoTipoEvento.FiltroAlterado:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": true };
                break;
            case NNotificacaoTipoEvento.EstrategiaIniciada:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": true };
                break;
            case NNotificacaoTipoEvento.EstrategiaFinalizada:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": true };
                break;
            case NNotificacaoTipoEvento.EstrategiaCancelada:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": true };
                break;
            case NNotificacaoTipoEvento.EstrategiaErro:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": true };
                break;
            case NNotificacaoTipoEvento.EstrategiaZerada:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": true };
                break;
            case NNotificacaoTipoEvento.ListaCriada:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": false, "estrategia": false };
                break;
            case NNotificacaoTipoEvento.ListaAlterada:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": false };
                break;
            case NNotificacaoTipoEvento.ListaLimpa:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": false };
                break;
            case NNotificacaoTipoEvento.SaudeIndice:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": true, "estrategia": false };
                break;
            case NNotificacaoTipoEvento.TemplateImportacaoCriado:
                this._campos = { "carteira": true, "templateImportacao": false, "lista": false, "estrategia": false };
                break;
            case NNotificacaoTipoEvento.TemplateImportacaoAlterado:
                this._campos = { "carteira": true, "templateImportacao": true, "lista": false, "estrategia": false };
                break;
            default:
                this._campos = { "carteira": false, "templateImportacao": false, "lista": false, "estrategia": false };
                break;
        }
    }




    validate() {

        // if (this.isConexaoApiChanged() == false)
        //     return null;

        //BancoDadosOffLine				=> null
        //DownloadRegistro				=> Carteira, Template, Lista
        //EstrategiaCriada				=> Carteira, Template, Lista
        //EstrategiaAlterada			=> Carteira, Template, Lista, ListaEstrategiaId
        //FiltroAlterado				=> Carteira, Template, Lista, ListaEstrategiaId
        //EstrategiaIniciada			=> Carteira, Template, Lista, ListaEstrategiaId
        //EstrategiaFinalizada			=> Carteira, Template, Lista, ListaEstrategiaId
        //EstrategiaCancelada			=> Carteira, Template, Lista, ListaEstrategiaId
        //EstrategiaErro				=> Carteira, Template, Lista, ListaEstrategiaId
        //EstrategiaZerada				=> Carteira, Template, Lista, ListaEstrategiaId
        //ListaCriada					=> Carteira, Template
        //ListaAlterada					=> Carteira, Template, Lista
        //ListaLimpa					=> Carteira, Template, Lista
        //SaudeIndice					=> Carteira, Template, Lista
        //TemplateImportacaoAlterado	=> Carteira
        //TemplateImportacaoAlterado	=> Carteira, Template


        let rules: any = {
            'notificacaoTipoEventoId': {
                'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
            },
            // FUNCIONA 
            // 'notificacaoEventosContato': {
            //     'validDependsAnotherFieldValue': {
            //         'anotherField': 'notificacaoTipoEnvioId', 'anotherFieldValue': [3, 5],
            //         'rules': {
            //             'validChildrenArray': {
            //                 'childrenRules': {
            //                     'nome': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } },
            //                     'contato': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
            //                 }
            //             }
            //         }
            //     }
            // }
        };

        // if (this.mostrarCamposTipoEnvio('contatos')) {
        //     rules.notificacaoEventosContato = {
        //         'validChildrenArray': {
        //             'childrenRules': {
        //                 'nome': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } },
        //                 'contato': { 'presence': { 'allowEmpty': false, 'message': '^obrigatorio' } }
        //             }
        //         }
        //     };
        // }

        // if (this.mostrarCamposTipoEnvio('camposApi')) {
        //     rules.camposApi = {
        //         'validChildrenArray': {
        //             'childrenRules': {
        //                 'nome': {
        //                     'presence': { 'allowEmpty': false, 'message': '^obrigatorio' }
        //                 }
        //             }
        //         }
        //     };
        // }


        return validate.validate(this, rules);
    }

    static fromRaw(raw: any): NNotificacaoEvento {
        let model: NNotificacaoEvento = Object.assign(new NNotificacaoEvento(), raw);
        model.guid = GuidService.newGuid();

        if (!isNull(raw.eventosConexoes)) {
            if (raw.eventosConexoes.length > 0)
                model.eventosConexoes = raw.eventosConexoes.map((m: any) => NNotificacaoEventoConexao.fromRaw(m));
        }

        if (!isNull(raw.usuarioCadastro))
            model.usuarioCadastro = UsuarioSimples.fromRaw(raw.usuarioCadastro);

        if (!isNull(raw.usuarioAlteracao))
            model.usuarioAlteracao = UsuarioSimples.fromRaw(raw.usuarioAlteracao);

        return model;
    }
}