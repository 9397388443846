import { ElementRef, Component, ContentChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CustomFloatingBoxContentDirective } from './custom-floating-box-content.directive';
import { CustomFloatingBoxTabContentDirective } from './custom-floating-box-tab-content.directive';

export interface CustomFloatingBoxContext {
    $implicit: () => void;
}

@Component({
    selector: 'app-custom-floating-box',
    templateUrl: './custom-floating-box.component.html',
    styleUrls: ['./custom-floating-box.component.scss'],
    // animations: [
    //     trigger('grow', [
    //         state('menos', style({ height: '0px', opacity: 0, display: 'none' })),
    //         state('mais', style({ height: 'auto', opacity: 100, display: 'block' })),
    //         transition('mais <=> menos', animate('3000ms ease-in-out')),
    //     ])
    // ]
})
export class CustomFloatingBoxComponent {
    showStandardFilter = false;
    @ContentChild(CustomFloatingBoxContentDirective, { static: false }) content!: CustomFloatingBoxContentDirective;
    @ContentChild(CustomFloatingBoxTabContentDirective, { static: false }) tabContent!: CustomFloatingBoxTabContentDirective;

    public get width():number {
        return (this.showStandardFilter)? 850 : 0;
    }

    constructor(private element: ElementRef) { }

    toggleStandardFilter() {
        this.showStandardFilter = !this.showStandardFilter;
    }
}
