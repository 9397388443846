
import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { map } from 'rxjs/operators';

import { DefaultValuesService } from "../../common/service/default-values.service";
import { TemplateService } from "../../common/service/template.service";

import { FiltroStatusRegistroLista } from '../../common/model/lista';
import { isNull, isNullOrZero } from '../../common/utils';

import { Order } from "../models/order";
import { OrderOperation, OrderTypes } from "../models/ordertypes";
import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";

@Component({
	selector: "app-ordenacao-estrategia",
	styleUrls: ["./ordenacao-estrategia.component.scss"],
	templateUrl: "./ordenacao-estrategia.component.html",
	providers: [
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class OrdenacaoEstrategiaComponent {

	@Input()
	listaNome: string;

	@Input()
	reenviarDistribuido: FiltroStatusRegistroLista

	@Input()
	query: any;

	static draggedOrder: Order;

	order: Order;
	campos: any[] = [];
	opcoesPossiveis: any[] = [];
	lista: any;
	orderCollection: Order[] = [];
	operacoes: any;

	@Output()
	aplicarOrdenacao = new EventEmitter<Array<Order>>();

	@Output()
	cancelarOrdenacao = new EventEmitter();

	//#region [ GET/SET - ListaId ]  

	_listaId: number;

	@Input()
	public set listaId(l: number) {
		if (!l || isNullOrZero(l)) {
			this._listaId = 0;
			this.campos = [];
			return;
		}

		this._listaId = l;
		this.obterCamposMapeados();
	}

	public get listaId() {
		return this._listaId;
	}

	@Input()
	ignorarflagPadrao: boolean = false;

	//#endregion

	//#region [ GET/SET - TemplateId ]  

	_templateId: number;

	@Input()
	public set templateId(t: number) {
		if (!t || isNullOrZero(t)) {
			this._templateId = 0;
			this.campos = [];
			return;
		}

		this._templateId = t;
		this.obterCamposMapeados();
	}

	public get templateId() {
		return this._templateId;
	}

	//#endregion

	//#region [ GET/SET - ListaEstrategiaPrioridade ]

	_listaEstrategiaPrioridade: Array<any>;

	@Input()
	public set listaEstrategiaPrioridade(l: Array<any>) {
		if (!l || isNullOrZero(l)) {
			this._listaEstrategiaPrioridade = [];
			this.orderCollection = [];
			return;
		}

		this._listaEstrategiaPrioridade = Object.assign([], l);
		this.orderCollection = this._listaEstrategiaPrioridade.map(ordem => Object.assign(new Order(), ordem));
	}


	setarOrdenacao(l: Array<any>) {

		this.listaEstrategiaPrioridade = l;
	}

	//#endregion

	public get activeOrderCollection() {
		return this.orderCollection.filter(o => o.ativo != false).sort((orderA, orderB) => orderA.ordem - orderB.ordem);
	}

	public get tipoDadoLista(): boolean {
		if (!this.order.listaTemplateItemId)
			return false;

		let campoSelecionado = this.obterCampoSelecionado(parseInt(this.order.listaTemplateItemId));

		if (!campoSelecionado)
			return false;

		return (campoSelecionado.tipoDado == "Lista");
	}

	public get operadorAscDesc() {
		if (isNullOrZero(this.order.operador))
			return false;

		return (this.order.operador == OrderOperation.asc || this.order.operador == OrderOperation.desc);
	}

	public get operacoesPossiveis() {
		if (!this.order.listaTemplateItemId)
			return [];

		const campoSelecionado = this.obterCampoSelecionado(parseInt(this.order.listaTemplateItemId));

		if (!campoSelecionado)
			return [];

		const possuiTipoNumerico = (campoSelecionado.tipoDado == "Numerico");
		const possiveis = this.operacoes.filter((item: any) => OrderTypes[campoSelecionado.tipoDado].indexOf(parseInt(item.value)) != -1);

		if (possuiTipoNumerico == false)
			return possiveis.filter((item: any) => item.numeric == undefined);

		return possiveis.filter((item: any) => item.value != OrderOperation.equal || (item.numeric == possuiTipoNumerico && item.value == OrderOperation.equal));
	}


	constructor(
		private templateService: TemplateService,
		private elasticsearchService: ElasticsearchRepositoryService,
		private customAlertService: CustomAlertService,
		private defaultValuesService: DefaultValuesService
	) {
		this.aplicarOrdenacao = new EventEmitter<Array<Order>>();
		this.cancelarOrdenacao = new EventEmitter();

		this.order = new Order();
		this.obterOperacoesOrdenacao();
	}

	obterCamposMapeados() {
		if (isNullOrZero(this.templateId) || isNullOrZero(this.listaId))
			return;

		this.templateService.obterCamposMapeados(this.templateId, this.listaId).subscribe(campos => {
			this.campos = campos.filter(f => [1, 3, 5, 6, 7, 9].some(s => s == f.itemTipo));
		});
	}

	obterOperacoesOrdenacao() {
		this.defaultValuesService.obterOperacoesOrdenacao().then(operacoes => (this.operacoes = operacoes));
	}

	adicionar() {
		if (!this.order.listaTemplateItemId)
		return false;

		const campoSelecionado = this.obterCampoSelecionado(parseInt(this.order.listaTemplateItemId));
		let validaValor = (campoSelecionado.tipoDado != "Lista" && this.operadorAscDesc == false);
		
		if (this.order.validate(validaValor))
			return;

		let permiteAdicionar = true;
		this.orderCollection.forEach(item => {
			if (item.listaTemplateItemId == this.order.listaTemplateItemId && item.ativo) {
				this.customAlertService.show("telaPrioridadePadrao.PrioridadePadrao", "telaPrioridadePadrao.erroAddLocal", "error");
				permiteAdicionar = false;
				return;
			}
		});

		if (permiteAdicionar) {
			if (campoSelecionado.tipoDado == 'Lista') {
				if (Array.isArray(this.order.valor)) {

					let c = this.activeOrderCollection.length;

					this.order.valor.forEach((item: any) => {
						let o = new Order();
						o.listaTemplateItemId = this.order.listaTemplateItemId;
						o.operador = this.order.operador;
						o.valor = item;
						o.ordem = c;
						c++;
						this.orderCollection.push(o);
					});
				}
			} else {
				if (Array.isArray(this.order.valor))
					this.order.valor = this.order.valor.join(",");

				this.order.ordem = this.activeOrderCollection.length;
				this.orderCollection.push(this.order);
			}

			this.order = new Order();
		}
		this.save();
	}

	verificarPadrao(ordem: Order) {
		if (this.ignorarflagPadrao)
			return false;

		return (isNullOrZero(ordem.listaPrioridadePadraoItemId) == false);
	}

	excluir(ordem: Order) {
		ordem.ativo = false;
		this.save();
	}

	obterCampoSelecionado(campoSelecionado: number) {
		return this.campos.find(campo => campo.listaTemplateItemId == campoSelecionado);
	}

	obterOpcoesLista(campoId) {
		this.opcoesPossiveis = [];
		var format = new Intl.NumberFormat(navigator.language);
		let campoSelecionado = this.obterCampoSelecionado(parseInt(campoId));

		if (campoSelecionado.tipoDado != "Lista")
			return;

		let mustUtilizado = (this.reenviarDistribuido == FiltroStatusRegistroLista.utilizado) ? [{ exists: { field: "_integrado" } }] : [];
		let mustNot = (this.reenviarDistribuido == FiltroStatusRegistroLista.naoUtilizado) ? [{ exists: { field: "_integrado" } }] : [];

		let query = this.query
			? this.query.query
			: {
				size: 10,
				_source: {
					includes: []
				},
				query: {
					bool: {
						must: mustUtilizado,
						must_not: mustNot
					}
				}
			};

		this.elasticsearchService
			.getOptionForField(
				this.lista,
				this.listaId,
				this.templateId,
				this.formatFieldName(campoSelecionado.nome),
				query
			).subscribe(collection => {

                let r: any = collection;

                if (typeof (collection) == 'string')
                    r = JSON.parse(collection);

                this.opcoesPossiveis = Object.keys(r).map(label => ({
                    label: label.replace(/_/g, " "),
                    value: label,
                    suffix: format.format(r[label])
                }));

            });
	}

	obterNomeCampo(id: number) {
		const campo = this.obterCampoSelecionado(id);
		if (!campo) return "";
		return campo.nome;
	}

	obterNomeOperacao(id: number) {
		return OrderOperation[id];
	}

	obterValor(valor: any) {
		return (Array.isArray(valor)) ? valor.join(", ") : valor;
	}

	obterPosicao(order: Order) {
		return this.activeOrderCollection.indexOf(order);
	}

	//#region [ Ordenação ]  

	subirPosicao(order: Order) {
		let index = this.obterPosicao(order);

		if (index == 0)
			return;

		const previousOrder = this.activeOrderCollection[index - 1];
		order.ordem--;
		previousOrder.ordem++;
		this.save();
	}

	descerPosicao(order: Order) {
		let index = this.obterPosicao(order);

		if (index == this.activeOrderCollection.length - 1)
			return;

		const nextOrder = this.activeOrderCollection[index + 1];
		order.ordem++;
		nextOrder.ordem--;
		this.save();
	}

	handleDragStart(order: Order) {
		OrdenacaoEstrategiaComponent.draggedOrder = order;
	}

	handleDragOver(event: Event) {
		event.preventDefault();
		let element: HTMLElement = (<Element>event.target).parentElement as HTMLElement;
		element.classList.add("droppable");
	}

	handleDragLeave(event: Event) {
		event.preventDefault();
		let element: HTMLElement = (<Element>event.target).parentElement as HTMLElement;
		element.classList.remove("droppable");
	}

	handleDrop(event: Event, o: Order) {
		let element: HTMLElement = (<Element>event.target).parentElement as HTMLElement;
		element.classList.remove("droppable");
		let newOrderCollection = this.changeOrder(this.activeOrderCollection, OrdenacaoEstrategiaComponent.draggedOrder, o);
		this.activeOrderCollection.forEach(ordem => (ordem.ordem = newOrderCollection.indexOf(ordem)));
	}

	changeOrder(collection: any, itemToChange: any, referenceItem: any) {
		let newCollection = collection.filter((item: any) => item != itemToChange).map((c: any) => c);
		newCollection.splice(collection.indexOf(referenceItem), 0, itemToChange);
		this.save();
		return newCollection;
	}

	//#endregion

	save() {
		this.aplicarOrdenacao.emit(this.orderCollection)
	}


	formatFieldName(name: string) {
		if (this.isUpperCase(name)) return name.toLowerCase();
		return `${name[0].toLowerCase()}${name.substring(1)}`;
	}

	isUpperCase(text: string) {
		return text.toUpperCase() == text;
	}
}
