export enum NNotificacaoTipoEvento {
    BancoDadosOffLine = 1,
    DownloadRegistro = 2,
    EstrategiaCriada = 3,
    EstrategiaAlterada = 4,
    FiltroAlterado = 5,
    EstrategiaIniciada = 6,
    EstrategiaFinalizada = 7,
    EstrategiaCancelada = 8,
    EstrategiaErro = 9,
    EstrategiaZerada = 10,
    ListaCriada = 11,
    ListaAlterada = 12,
    ListaLimpa = 13,
    SaudeIndice = 14,
    TemplateImportacaoCriado = 15,
    TemplateImportacaoAlterado = 16
}