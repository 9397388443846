export class ParametrosAgendamentoDescricao {

    execucaoUnica: boolean;
    frequencia: string;
    diaSemana: string;
    intervalo: number;

    dataInicio: Date;
    horaInicio: any;
    dataTermino: Date;
    horaTermino: any;

    _comDataFim: boolean;

    public get execucaoRecorrente(): boolean {
        return !this.execucaoUnica;
    }

    public set execucaoRecorrente(valor: boolean) {
        this.execucaoUnica = !valor;
    }

    public get comDataFim(): boolean {
        return this._comDataFim != undefined ? this._comDataFim : this.dataTermino != undefined;
    }

    public set comDataFim(valor: boolean) {
        if (!valor) {
            this.dataTermino = undefined;
            this.horaTermino = undefined;
        }
        this._comDataFim = valor;
    }
}