import { Response } from "@angular/http";

export function downloadFile(res: Response, fileName: string) {
  var a = document.createElement("a");
  a.href = URL.createObjectURL(res.blob());
  a.download = fileName;
  a.click();
}

export function downloadFileBlob(res: Blob, fileName: string) {
  var a = document.createElement("a");
  a.href = URL.createObjectURL(res);
  a.download = fileName;
  a.click();
}
