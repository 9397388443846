import { Component, OnInit, Input, EventEmitter, ViewChild, TemplateRef, ContentChild, } from "@angular/core";
import { TabFloatingBoxLabelComponent } from "./tab-floating-box-label.component";
import { TabFloatingBoxBodyComponent } from "./tab-floating-box-body.component";

@Component({
    selector: "tab-fb-item",
    template: "<ng-content></ng-content>",
})
export class TabFloatingBoxItemComponent implements OnInit {
    @Input()
    label: string;

    @Input()
    isActive: boolean;

    @ContentChild(TabFloatingBoxBodyComponent, { static: true })
    bodyComponent: TabFloatingBoxBodyComponent;

    @ContentChild(TabFloatingBoxLabelComponent, { static: true })
    labelComponent: TabFloatingBoxLabelComponent;

    constructor() { }

    ngOnInit(): void { }
}
