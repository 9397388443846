import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatStepperModule,
    MatSelectModule,
    MatCardModule,
    MatRadioModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatListModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
import { TableModule } from "primeng/table";
import { DataViewModule } from "primeng/dataview";
import { AppCommonModule } from "../common/common.module";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { ElasticsearchModule } from "../elasticsearch/elasticsearch.module";

import { environment } from "../../../environments/environment";
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { EsBuilderModule } from "../esbuilder/esbuilder.module";
import { NgSelectModule } from '@ng-select/ng-select';
import { ListaFiltroQueryPadraoComponent } from "./lista-filtro-query-padrao/lista-filtro-query-padrao.component";
import { FormFiltroQueryPadraoComponent } from "./form-filtro-query-padrao/form-filtro-query-padrao.component";
import { FiltroQueryPadraoModalComponent } from "./filtro-query-padrao-modal/filtro-query-padrao-modal.component";
import { FiltroQueryPadraoAplicarComponent } from "./filtro-query-padrao-aplicar/filtro-query-padrao-aplicar.component";
import { PickListModule } from "primeng/primeng";


@NgModule({
    imports: [
        CommonModule,
        AppCommonModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        FormsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatStepperModule,
        MatSlideToggleModule,
        ElasticsearchModule,
        EsBuilderModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCardModule,
        MatDividerModule,
        MatListModule,
        NgSelectModule,
        TableModule,
        DataViewModule,
        AmazingTimePickerModule,
        MatRadioModule,
        MatExpansionModule,
        MatCardModule,
        MatPaginatorModule,
        MatDialogModule,
        NgxGraphModule,
        DragDropModule,
        PickListModule,
        MatTabsModule
    ],
    providers: [
        { provide: FormBuilder, useClass: FormBuilder },
        { provide: LOCALE_ID, useValue: "pt-BR" },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` } }
    ],
    entryComponents: [
        FiltroQueryPadraoModalComponent
    ],
    declarations: [
        FiltroQueryPadraoAplicarComponent,
        ListaFiltroQueryPadraoComponent,
        FormFiltroQueryPadraoComponent,
        FiltroQueryPadraoModalComponent
    ],
    exports: [
        FiltroQueryPadraoAplicarComponent
    ]
})
export class FiltroQueryPadraoModule { }
