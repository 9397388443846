
import { map, tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { Login } from "../../autenticacao/model/login";
import { Injectable } from "@angular/core";
import { Credential } from "../../autenticacao/model/credential";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { convertToQueryString } from "./query-string";
import { environment } from "../../../../environments/environment";
import { StorageProxy } from "./storage-proxy.service";

import * as jwt_decode from "jwt-decode";
import { Permissao } from "../model/permissoes-usuario";
import { User } from "../../autenticacao/model/user";

const Auth = {
	client_id: environment.client_id,
	grant_type: "password"
};

@Injectable()
export class AutenticacaoService /*extends BaseService*/ {
	private headers: HttpHeaders;

	private apiAutenticacao: string;
	private apiUsuario: string;

	constructor(private httpClient: HttpClient) {
		this.headers = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");

		this.apiAutenticacao = `/api/autenticacao`;
		this.apiUsuario = `/api/usuarios`;
	}

	get autenticado() {
		let userData = StorageProxy.get<Credential>('userData')
		return (
			userData != undefined && (userData.tokenType == "Cookie" || (userData.accessToken != undefined /*&& !isTokenExpired*/))
		);
	}

	get tokenExpired() {
		let userData = StorageProxy.get<Credential>('userData')
		let isTokenExpired = this.isTokenExpired(userData.accessToken);
		return (userData.accessToken != undefined && isTokenExpired);
	}

	realizarLogin(login: Login) {

		let loginData = Object.assign({}, Auth, {
			username: login.login,
			password: login.senha
		});

		return this.httpClient
			.post<Credential>('/connect/token',
				convertToQueryString(loginData, false),
				{
					headers: new HttpHeaders().set(
						"Content-Type",
						"application/x-www-form-urlencoded"
					)
				}
			).pipe(
				tap(credential => StorageProxy.set('userData', credential)));
	}



	obterCredenciaisPorCookie() {
		return this.httpClient
			.get<any>(`${this.apiAutenticacao}/credenciais`, {
				withCredentials: true
			}).pipe(
				tap(result => {
					let user = new User();
					user.id = result.user.id;
					user.userLogin = result.user.userLogin;
					user.userName = result.user.userName;

					let userData = StorageProxy.get<Credential>('userData')

					StorageProxy.set('userData', Object.assign({}, userData, {
						TokenType: result.tokenType,
						User: user
					}));
				}));
	}

	obterTokenAutenticado() {
		let userData = StorageProxy.get<Credential>('userData')
		if (!userData) return undefined;
		return userData.accessToken;
	}

	isAutenticadoSemToken() {
		//return StorageProxy.userData && !StorageProxy.userData.accessToken;
		return StorageProxy.get<Credential>('userData') && !StorageProxy.get<Credential>('userData').accessToken;
	}

	logoutUser() {
		return this.httpClient
			.post(`${this.apiAutenticacao}/logout`, {}).pipe(
				tap(
					res => localStorage.removeItem("userData"),
					error => localStorage.removeItem("userData")
				));
	}

	private getTokenExpirationDate(token: string): Date {
		const decoded = jwt_decode(token);
		if (decoded.exp === undefined) return undefined;

		const date = new Date(0);
		date.setUTCMinutes(decoded.exp);
		return date;
	}

	public obterPermissoes() {
		return this.httpClient.get<Permissao>(`${this.apiUsuario}/permissoes`);
	}

	private isTokenExpired(acess_token: any): boolean {
		if (!acess_token) return true;

		const date = this.getTokenExpirationDate(acess_token);
		if (date === undefined) return false;
		return !(date.valueOf() > new Date().valueOf());
	}

	refreshToken(): Observable<any> {
		let userData = StorageProxy.get<Credential>('userData')
		let body = Object.assign({}, Auth, {
			grant_type: "refresh_token",
			refresh_token: userData.refreshToken
		});

		return this.httpClient
			.post<Credential>(
				'/connect/token',
				convertToQueryString(body, false),
				{
					headers: this.headers
				}
			).pipe(
				tap(
					result =>
					(StorageProxy.set('userData', Object.assign({}, userData, {
						accessToken: result.accessToken
						//RefreshToken: result.RefreshToken
					})))
				),
				map(res => Object.assign({}, res, { newToken: true })));
	}
}
