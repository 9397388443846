import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import {
	MatButtonModule,
	MatInputModule,
	MatIconModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatStepperModule,
	MatSelectModule,
	MatCardModule,
	MatRadioModule,
	MatMenuModule,
	MatDividerModule,
	MatTabsModule,
	MatSlideToggleModule,
	MatExpansionModule,
	MatListModule,
	MatPaginatorModule,
	MatTooltipModule,
	MatDialogModule,
	MatSliderModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MAT_MENU_DEFAULT_OPTIONS,
	MatProgressBarModule
} from "@angular/material";
import { TableModule } from "primeng/table";
import { SpinnerModule } from 'primeng/spinner';

import { AppCommonModule } from "../common/common.module";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { ElasticsearchModule } from "../elasticsearch/elasticsearch.module";

import { ListaEstrategiaComponent } from "./lista-estrategia/lista-estrategia.component";
import { InfoExecucaoEstrategiaComponent } from "./lista-estrategia/info-execucao-estrategia/info-execucao-estrategia.component";

import { FormEstrategiaComponent } from "./form-estrategia/form-estrategia.component";
import { AgendamentoEstrategiaComponent } from "./form-estrategia/agendamento-estrategia/agendamento-estrategia.component";
import { PreviewDadosEstrategiaComponent } from "./form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";

import { HistoricoEstrategiaComponent } from "./historico-estrategia/historico-estrategia.component";
import { DisplayMessagemErroEstrategiaComponent } from "./historico-estrategia/display-messagem-erro-estrategia/display-messagem-erro-estrategia.component";
import { VisualizarProvedorModalComponent } from "./visualizar-provedor-modal/visualizar-provedor-modal.component";

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material';
import { environment } from "../../../environments/environment";

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EsBuilderModule } from "../esbuilder/esbuilder.module";
import { OrdenacaoEstrategiaComponent } from "./ordenacao-estrategia/ordenacao-estrategia.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomFloatingBoxComponent } from "../common/components/custom-floating-box/custom-floating-box.component";
import { CustomFloatingBoxContentDirective } from "../common/components/custom-floating-box/custom-floating-box-content.directive";
import { CustomFloatingBoxTabContentDirective } from "../common/components/custom-floating-box/custom-floating-box-tab-content.directive";

import { TabFloatingBoxModule } from "../common/components/tab-floating-box/tab-floating-box.module";
import { MigracaoEstrategiaComponent } from "./modais/migracao-estrategia/migracao-estrategia.component";

import {ProgressBarModule} from 'primeng/progressbar';
import { ConfiguracoesAgendamentoModalComponent } from "./modais/configuracoes-agendamento/configuracoes-agendamento-modal.component";
import { TooltipDirective } from "../common/directives/tooltip.diretive";
import { TooltipComponent } from "../common/directives/tooltip.component";
import { CustomToolTipComponent } from "../common/directives/custom-tool-tip/custom-tool-tip.component";
import { ToolTipRendererDirective } from "../common/directives/tool-tip-renderer.directive";

@NgModule({
	imports: [
		CommonModule,
		AppCommonModule,
		ProgressBarModule,
		DragDropModule,
		ScrollingModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		MatSliderModule,
		MatTooltipModule,
		MatMenuModule,
		FormsModule,
		MatCheckboxModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatStepperModule,
		MatSlideToggleModule,
		ElasticsearchModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatCardModule,
		MatDividerModule,
		MatListModule,
		MatTabsModule,
		MatButtonToggleModule,
		TableModule,
		SpinnerModule,
		AmazingTimePickerModule,
		MatRadioModule,
		MatExpansionModule,
		MatCardModule,
		MatPaginatorModule,
		MatDialogModule,
		NgSelectModule,
		EsBuilderModule,
		TabFloatingBoxModule
	],
	providers: [
		{ provide: FormBuilder, useClass: FormBuilder },
		{ provide: LOCALE_ID, useValue: "pt-BR" },
		{ provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` } }
	],
	entryComponents: [
		PreviewDadosEstrategiaComponent,
		AgendamentoEstrategiaComponent,
		InfoExecucaoEstrategiaComponent,
		DisplayMessagemErroEstrategiaComponent,
		VisualizarProvedorModalComponent,
		OrdenacaoEstrategiaComponent,
		MigracaoEstrategiaComponent,
		ConfiguracoesAgendamentoModalComponent

	],
	declarations: [
		ListaEstrategiaComponent,
		FormEstrategiaComponent,
		PreviewDadosEstrategiaComponent,
		HistoricoEstrategiaComponent,
		AgendamentoEstrategiaComponent,
		InfoExecucaoEstrategiaComponent,
		DisplayMessagemErroEstrategiaComponent,
		VisualizarProvedorModalComponent,
		OrdenacaoEstrategiaComponent,
		CustomFloatingBoxComponent,
		CustomFloatingBoxContentDirective,
		CustomFloatingBoxTabContentDirective,
		MigracaoEstrategiaComponent,
		ConfiguracoesAgendamentoModalComponent
		
	],
	exports: [
		OrdenacaoEstrategiaComponent
	]
})
export class EstrategiaModule { }
