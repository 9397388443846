import * as validate from "validate.js";

export enum SistemaRecurso {
  Usuario = 1,
  Lista = 2,
  Estrategia = 3,
  Campanha = 4
}

export enum RecursoAcao {
  Leitura = 1,
  Escrita = 2
}

export class UsuarioPermissao {
  usuarioPermissaoId: number;
  usuarioId: number;  
  recursoAcaoId: RecursoAcao;
  sistemaRecursoId: SistemaRecurso;
  recursoAcao: string;
  sistemaRecurso: string;

  constructor(usuarioId?: number, recursoAcaoId?: number, sistemaRecursoId?: number, usuarioPermissaoId?: number) {
    this.usuarioPermissaoId = usuarioPermissaoId;
    this.usuarioId = usuarioId;
    this.recursoAcaoId = recursoAcaoId;
    this.sistemaRecursoId = sistemaRecursoId;
  }

  static fromRaw(rawUsuario): UsuarioPermissao {
    return Object.assign(new UsuarioPermissao(), rawUsuario);
  }

  validate() {
    let validationResult = validate.validate(this, {
      recursoAcaoId: {
        presence: { allowEmpty: false }
      },
      sistemaRecursoId: {
        presence: { allowEmpty: false }
      }
    });

    return validationResult;
  }
}
