import { Component, OnInit, ViewChild } from '@angular/core';
import swal from "sweetalert2";
import { ListaService } from '../../common/service/lista.service';
import { EstrategiaService } from '../../common/service/estrategia.service';
import { CustomAlertService } from '../../common/service/custom-alert.service';
import { FiltroStatusRegistroLista, Lista } from '../../common/model/lista';
import { Estrategia } from '../../common/model/estrategia';
import { Subscription, forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatTabChangeEvent } from '@angular/material';
import { ListaPrioridadePadraodService } from '../../common/service/lista-prioridade-padrao.service';
import { ListaPrioridadePadrao } from '../../common/model/lista-prioridade-padrao';
import { isNullOrEmpty, isNullOrZero } from '../../common/utils';
import { FiltroLista } from '../../common/model/filtro-lista';
import MD5 from 'crypto-js/md5';
import { ElasticsearchAddOrderComponent } from '../../elasticsearch/elasticsearch-add-order/elasticsearch-add-order.component';
import { CarteiraService } from '../../common/service/carteira.service';
import { Carteira } from '../../common/model/carteira';
import { PreferenciasUsuario } from '../../common/model/preferencias-usuario';
import { PreferenciasUsuarioService } from '../../common/service/preferencias-usuario.service';
import { Order } from '../../estrategia/models/order';
import { EstrategiaRefactor } from '../../common/model/estrategiaRefactor';


@Component({
	selector: 'app-form-ordenacao-padrao',
	templateUrl: './form-ordenacao-padrao.component.html',
	styleUrls: ['./form-ordenacao-padrao.component.scss'],
	providers: [
		{ useClass: ListaService, provide: ListaService },
		{ useClass: EstrategiaService, provide: EstrategiaService },
		{ useClass: ListaPrioridadePadraodService, provide: ListaPrioridadePadraodService },
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ useClass:CarteiraService, provide: CarteiraService},
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService }
	]
})
export class FormOrdenacaoPadraoComponent implements OnInit {

	dirty: boolean;
	listas: Lista[];
	reenviarDistribuido = FiltroStatusRegistroLista.total;
	listaId: number;
	carteiraId:number;
	listaNome: string;
	listaTemplateId: number;
	estrategia: Estrategia
	finishedLoading: boolean = false;
	prioridadePadrao: ListaPrioridadePadrao;
	debug: boolean = false;
	erros: any;
	subListas: Subscription;
	subEstrategias: Subscription;
	subPrioridadePadrao: Subscription;
	carteiras: Array<Carteira> = [];
	preferenciasUsuario: PreferenciasUsuario;
	query:any = null;
	tabSelected: number = 0;
  

	@ViewChild("elasticsearchOrder", { static: false })
	elasticsearchOrder: ElasticsearchAddOrderComponent;

	estrategias: Estrategia[] = [];
	estrategiasSource: Estrategia[] = [];
	estrategiasTarget: Estrategia[] = [];

	constructor(private route: ActivatedRoute,
		private listaService: ListaService,
		private estrategiaService: EstrategiaService,
		private router: Router,
		private customAlertService: CustomAlertService,
		private carteiraService: CarteiraService,
		private preferenciasService: PreferenciasUsuarioService,
		private translateService: TranslateService,
		private listaPrioridadePadraodService: ListaPrioridadePadraodService,
		private dialog: MatDialog) {

		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();
		this.prioridadePadrao = new ListaPrioridadePadrao();
		this.route.params.subscribe(params => this.carregar(params));

		this.route.queryParams.subscribe(params => {
			if (params.debug != undefined)
				this.debug = (params.debug === 'true');
		});

	}

	ngOnInit() {
		
	}

	obterCarteiras()
	{
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
      
			this.carteiras = carteiras.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            });

			if (this.preferenciasUsuario.existePreferenciaCarteira) {
				this.carteiraId = this.preferenciasUsuario.carteiraId as number;
			} else {
				let padrao = this.carteiras.find(f => f.padrao) as Carteira;
				this.carteiraId = padrao.carteiraId;
			}

			this.obterListas();
		});
	}

	carteiraChange()
	{
		this.listaId = null;
		this.prioridadePadrao = new ListaPrioridadePadrao();
		this.obterListas();
	}

	ngOnDestroy() {

		if (this.subListas) this.subListas.unsubscribe();
		if (this.subEstrategias) this.subEstrategias.unsubscribe();
		if (this.subPrioridadePadrao) this.subPrioridadePadrao.unsubscribe();
	}

	public get isEdit(): boolean {
		return (this.prioridadePadrao != undefined && !isNullOrZero(this.prioridadePadrao.listaPrioridadePadraoId));
	}
	public get isListaSelecionada(): boolean {
		return (!isNullOrZero(this.prioridadePadrao.listaId));
	}

	disabledTab(index: number) {
        if (this.isEdit)
            return false;

		return !(this.tabSelected >= index);
	}

	tabChange(e: MatTabChangeEvent) {
		this.tabSelected = e.index;
	}

    next() {
		if (this.tabSelected >= 0 && this.tabSelected < 2)
			this.tabSelected++;

            this.translateService
			.get(["telaPrioridadePadrao.PrioridadePadrao", "telaEstrategia.filtrosInvalidos"])
			.subscribe((res: Object) => {
				let valid = this.ExisteErrosCadastro();

				if (valid) {
					swal.fire(res["telaPrioridadePadrao.PrioridadePadrao"], res["telaEstrategia.filtrosInvalidos"], "error");
					let k = Object.keys(valid);
					if (k) 
                        this.tabSelected = 0;
					return;
				}
			});
    }

    previous() {
		if (this.tabSelected > 0 && this.tabSelected <= 1)
			this.tabSelected--;
	}

	carregar(params: any) {
		if (!params.id) {
			this.obterCarteiras();
			return;
		}

		this.subPrioridadePadrao = this.listaPrioridadePadraodService.obterPorId(params.id).subscribe((ordenacao: ListaPrioridadePadrao) => {
			this.prioridadePadrao = ordenacao;
			this.listaId = this.prioridadePadrao.listaId;
			this.listaNome = this.prioridadePadrao.listaNome;
			this.listaTemplateId = this.prioridadePadrao.listaTemplateId;
			this.estrategia = new Estrategia();
			this.estrategia.listaEstrategiaId = 0;
			this.obterEstrategias();

			this.finishedLoading = true;
		});
	}

	obterListas(listaId: number | null = null) {
		let filtro: FiltroLista = {carteiraId: this.carteiraId  ,ignorarErroPesquisa: true } as FiltroLista;

		if (!isNullOrZero(listaId))
			filtro.lista = listaId;

		this.subListas = this.listaService.obterLista(filtro).subscribe((listas: Lista[]) => {
			this.listas = listas.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            });
		});
	}

	obterEstrategias() {
		let filtro = { 'lista': this.listaId, 'estrategiaAcaoCampanhaTipos': [1] };

		this.subEstrategias = this.estrategiaService.obterEstrategiasPaginadas(filtro).subscribe(result => {
			this.estrategias = result.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            });

			if (this.isEdit) {
				this.estrategiasSource = this.estrategias;
				//this.estrategiasTarget = this.estrategias.filter((f: Estrategia) => f.listaEstrategiaPrioridade != null && f.listaEstrategiaPrioridade.some((s: Order) => s.ativo && s.listaPrioridadePadraoItemId == this.prioridadePadrao.listaPrioridadePadraoId) == true);

				if (isNullOrEmpty(this.prioridadePadrao.estrategiasVinculadas)){
					this.estrategiasSource = this.estrategias;
					this.estrategiasTarget = [];
				}
				else{
					this.estrategiasSource = this.estrategias.filter((f: Estrategia) => this.prioridadePadrao.estrategiasVinculadas.some(s => s == f.listaEstrategiaId) == false);
					this.estrategiasTarget = this.estrategias.filter((f: Estrategia) => this.prioridadePadrao.estrategiasVinculadas.some(s => s == f.listaEstrategiaId));
				}
			} else {
				this.estrategiasSource = this.estrategias;
				this.estrategiasTarget = [];
			}
		});
	}

	listaChange(event: any) {
		if (isNullOrZero(this.prioridadePadrao.listaId))
			return;

		let listaSelecionada = this.listas.find((f: Lista) => f.listaId.toString() == this.prioridadePadrao.listaId.toString()) as Lista;

		this.prioridadePadrao.listaId = listaSelecionada.listaId;
		this.listaId = listaSelecionada.listaId
		this.listaNome = listaSelecionada.nome;
		this.listaTemplateId = listaSelecionada.listaTemplateId;
		this.estrategia = new Estrategia();
		this.estrategia.listaEstrategiaId = 0;

		this.obterEstrategias();
		this.finishedLoading = true;
	}

	onMoveToTarget(e: any) {
		e.items = e.items.map((m: EstrategiaRefactor) => {
			m.filtroAlterado = true;
      		this.prioridadePadrao.estrategiasVinculadas.push(m.listaEstrategiaId);
		})
	}

	onMoveAllToTarget(e: any) {
		e.items = e.items.map((m: EstrategiaRefactor) => {
			m.filtroAlterado = true;
      		this.prioridadePadrao.estrategiasVinculadas.push(m.listaEstrategiaId);
		})
	}

	onMoveToSource(e: any) {
		e.items = e.items.map((m: EstrategiaRefactor) => {
			m.filtroAlterado = true;
      		this.prioridadePadrao.estrategiasVinculadas = this.prioridadePadrao.estrategiasVinculadas.filter(item => item !== m.listaEstrategiaId);
		})
	}

	onMoveAllToSource(e: any) {
		
		e.items = e.items.map((m: EstrategiaRefactor) => {
			m.filtroAlterado = true;
      		this.prioridadePadrao.estrategiasVinculadas = this.prioridadePadrao.estrategiasVinculadas.filter(item => item !== m.listaEstrategiaId);
		})
	}

	ExisteErrosCadastro() :Boolean{
		this.dirty = true;
		this.prioridadePadrao.listaPrioridadePadraoItens = this.elasticsearchOrder.orderCollection;

		this.erros = this.prioridadePadrao.validar()

		if (this.erros) {
			return true;
		}

		if (this.prioridadePadrao.listaPrioridadePadraoItens == null || this.prioridadePadrao.listaPrioridadePadraoItens.filter((f:Order)=>f.ativo).length == 0) {
			return true;
		}

		return false;
	}

	salvar() {
		
	
		
		if (this.ExisteErrosCadastro())
		{
			this.customAlertService.show("telaPrioridadePadrao.PrioridadePadrao", "telaPrioridadePadrao.erroOrdenacao", "error");
			return
		}

		let sucesso = (response: any) => {
			this.customAlertService.show("telaPrioridadePadrao.PrioridadePadrao", "telaPadrao.sucessoSalvar", "success");
			this.router.navigate(["/app/ordenacaopadrao/"]);
		};

		let erro = () => {
			this.customAlertService.show("telaPrioridadePadrao.PrioridadePadrao", "telaPadrao.erroSalvar", "error");
		};

		if (!this.prioridadePadrao.listaPrioridadePadraoId)
			return this.listaPrioridadePadraodService.criar(this.prioridadePadrao).subscribe(sucesso, erro);

		this.listaPrioridadePadraodService.atualizar(this.prioridadePadrao).subscribe(sucesso, erro);
	}

}
