import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatSlideToggleChange } from "@angular/material";

import { LimpezaAutomaticaConfiguracao } from "../../common/model/limpeza-automatica-configuracao";
import * as dayjs from 'dayjs';
import { ListaService } from "../../common/service/lista.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { TranslateService } from "@ngx-translate/core";
import { ParametrosAgendamentoDescricao, AgendamentoDescricao } from "../../common/libs/agendamento-descricao";
import { isNullOrEmpty } from "../../common/utils";

@Component({
	selector: "app-limpeza-automatica",
	templateUrl: "./limpeza-automatica.component.html",
	styleUrls: ["./limpeza-automatica.component.scss"],
	providers: [
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class LimpezaAutomaticaComponent {
	zerarAgora: boolean = false;
	listaId: number;
	limpezaAutomatica: LimpezaAutomaticaConfiguracao;
	horaInicioFrequencia: Date;
	descricao: string;
	tipoRecorrencia: string;
	dirty: boolean;
	frequenciaCollection: any = [{ 'frequencia': 3, 'descricao': this.translate.instant("frequenciasEnvio.dia") }, { 'frequencia': 4, 'descricao': this.translate.instant("frequenciasEnvio.semana") }];
	diaSemanaCompleto: any = { 'SUN': 'domingo', 'MON': 'segunda', 'TUE': 'terca', 'WED': 'quarta', 'THU': 'quinta', 'FRI': 'sexta', 'SAT': 'sabado' };
	horarioCollection: Array<any>;

	public get erros() {
		if (!this.dirty) return;
		return this.limpezaAutomatica.validar();
	}

	constructor(
		private listaService: ListaService,
		private customAlertService: CustomAlertService,
		private translate: TranslateService,
		public dialogRef: MatDialogRef<LimpezaAutomaticaComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { listaId: number }
	) {
		this.dirty = false;
		this.limpezaAutomatica = new LimpezaAutomaticaConfiguracao();
		this.listaId = data.listaId;

		this.listaService.obterLimpezaAutomaticaPorListaId(this.listaId)
			.subscribe((result: LimpezaAutomaticaConfiguracao) => {
				this.limpezaAutomatica = result;

				if (this.isNew())
					this.limpezaAutomatica.listaId = this.listaId;

				if (this.limpezaAutomatica.horaExecucao) {
					let h = this.limpezaAutomatica.horaExecucao.split(":");
					this.limpezaAutomatica.horaExecucao = `${h[0]}:${h[1]}`;
				}
				this.gerarDescricao();
			});
	}

	isNew() {
		return isNullOrEmpty(this.limpezaAutomatica.limpezaAutomaticaConfiguracaoId);
	}

	zerarLista() {
		this.customAlertService
			.confirmationMessage("telaLimpezaAutomaticaConfiguracao.confirmacaoZerar")
			.then(() => {
				this.zerarAgora = true;
				this.listaService
					.limparLista(this.listaId)
					.subscribe(() => {
						this.customAlertService.show("telaLimpezaAutomaticaConfiguracao.zerarLista", "telaLimpezaAutomaticaConfiguracao.listaZeradaSucesso", "success");
					})
			});
	}

	removerConfiguracao() {
		this.customAlertService
		.confirmationMessage("telaLimpezaAutomaticaConfiguracao.confirmacaoRemoverConfiguracao")
		.then(() => {
			this.listaService
				.setStatusLimpezaAutomatica(this.limpezaAutomatica.limpezaAutomaticaConfiguracaoId, this.listaId, false)
				.subscribe(() => {
					this.customAlertService.show("telaLimpezaAutomaticaConfiguracao.limpezaAutomatica", "telaLimpezaAutomaticaConfiguracao.removerConfiguracaoSucesso", "success");
					this.dialogRef.close();
				})
		});
	}

	gravar() {
		this.dirty = true;

		if (this.limpezaAutomatica.validar()) {
			this.customAlertService.show("telaLimpezaAutomaticaConfiguracao.limpezaAutomatica", "telaPadrao.camposInvalidos", "error");
			return;
		}

		this.listaService.gravarLimpezaAutomatica(this.limpezaAutomatica)
			.subscribe(() => {
				this.customAlertService.show("telaLimpezaAutomaticaConfiguracao.limpezaAutomatica", "telaLimpezaAutomaticaConfiguracao.salvarConfiguracao", "success");
				this.dialogRef.close(this.limpezaAutomatica);
			}, (error: any) => {
				this.customAlertService.show("telaLimpezaAutomaticaConfiguracao.limpezaAutomatica", error.error[0], "error");
			});
	}

	gerarDescricao() {
		let param: ParametrosAgendamentoDescricao = new ParametrosAgendamentoDescricao();
		param.execucaoUnica = false;
		param.frequencia = this.limpezaAutomatica.frequencia;
		param.diaSemana = this.limpezaAutomatica.diaSemana;
		param.intervalo = this.limpezaAutomatica.intervalo;
		param.dataInicio = this.limpezaAutomatica.dataInicio;
		param.horaInicio = this.limpezaAutomatica.horaExecucao;
		param.dataTermino = this.limpezaAutomatica.dataTermino;
		param.horaTermino = '23:59';
		param.comDataFim = this.limpezaAutomatica.comDataFim;

		this.descricao = AgendamentoDescricao(param, 'telaLimpezaAutomaticaConfiguracao', this.translate);
	}

	frequenciaChange(event: any) {
		if (this.isNew() && this.dirty === undefined) {
			let dataAtual = new Date();
			this.limpezaAutomatica.dataInicio = dataAtual;

			if (this.limpezaAutomatica.frequencia == '1')
				this.limpezaAutomatica.intervalo = 30;

			else if (this.limpezaAutomatica.frequencia == '2')
				this.limpezaAutomatica.intervalo = 1;
		}

		if (this.limpezaAutomatica.frequencia == '3')
			this.limpezaAutomatica.diaSemana = null;

		this.gerarDescricao();
	}

	dadosChange(event: any) {
		this.gerarDescricao();
	}

	comDataFimChange(event: MatSlideToggleChange) {
		if (event.checked) {
			let dataAtual = new Date();
			dataAtual.setDate(dataAtual.getDate() + 1);
			dataAtual.setHours(23, 59, 59, 999);
			this.limpezaAutomatica.dataTermino = dataAtual;

		} else {
			this.limpezaAutomatica.dataTermino = null;
		}

		this.gerarDescricao();
	}

	checkTerminoDesabilitado() {
		return (!this.limpezaAutomatica.comDataFim) ? true : false;
	}

	fechar() {
		this.limpezaAutomatica.validar();

		if (this.zerarAgora)
			this.dialogRef.close(this.limpezaAutomatica);
		else
			this.dialogRef.close();
	}


}
