import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Provider } from "../model/provider";
import { convertToQueryString } from "./query-string";
import { HistoricoRetorno } from "../model/historico-retorno";
import { ProvedorAgendamentoConfiguracao } from "../model/provedor-agendamento-configuracao";
import { FiltroHistoricoRetorno } from "../model/filtro-historico-retorno";
import { FiltroMonitorRetorno } from "../model/filtro-monitor-retorno";
import { StorageProxy } from "./storage-proxy.service";

@Injectable()
export class HistoricoRetornoService {
	
	private api: string;

    constructor(private httpClient: HttpClient) { 
        this.api = '/api/historicoretorno';
    }

	public obterProvedoresRetorno(carteiraId: number) {
		return this.httpClient.get<Array<Provider>>(`${this.api}/provedores-retorno/${carteiraId}`);
	}

	public obterProvedoresListaRetorno(filtro: any) {
		return this.httpClient.get<Array<any>>(`${this.api}/obter-provedor-lista${convertToQueryString(filtro)}`);
	}

	public obterHistoricoRetorno(filtro: FiltroHistoricoRetorno) {
		return this.httpClient.get<Array<HistoricoRetorno>>(`${this.api}${this.convertToQueryString(filtro)}`);
	}
	
	public obterHistoricoRetornoCompleto(filtro: FiltroHistoricoRetorno) {
		return this.httpClient.get<Array<HistoricoRetorno>>(`${this.api}/obter-historico-completo${this.convertToQueryString(filtro)}`);
	}

	public obterProximaExecucao(providerId: number) {
		return this.httpClient.get<ProvedorAgendamentoConfiguracao>(`${this.api}/proxima-execucao/${providerId}`);
	}

	public obterErroProcessamentoRetorno(filtro: FiltroMonitorRetorno) {
		return this.httpClient.get<Array<any>>(`${this.api}/obter-erro-processamento${this.convertToQueryString(filtro)}`);
	}

	public obterEnvioSemRetorno(filtro: FiltroMonitorRetorno) {
		return this.httpClient.get<Array<any>>(
			`${this.api}/obter-sem-retorno${this.convertToQueryString(filtro)}`);
	}

	public obterEnvioSemRetornoDetalhe(tarefaId: number, pagina: number) {
		return this.httpClient.get<any>(`${this.api}/obter-sem-retorno-detalhe/${tarefaId}?pagina=${pagina}`);
	}

	//#region [ Métodos Auxiliares ]

	convertToQueryString(queryObject, usePrefix = true) {
		if (!queryObject) return "";

		let formUrlEncoded = Object.keys(queryObject)
			.map(
				prop => Array.isArray(queryObject[prop])
					? this.convertArrayToQueryString(queryObject[prop], prop)
					: (queryObject[prop] instanceof Date
						? `${prop}=${encodeURIComponent(queryObject[prop].toISOString())}`
						: `${prop}=${encodeURIComponent(queryObject[prop])}`)
			)
			.join("&");

		return usePrefix ? `?${formUrlEncoded}` : formUrlEncoded;
	};

	convertArrayToQueryString(arrayObject, nameObject) {
		let queryStringArray = "";

		for (let index = 0; index < arrayObject.length; index++) {
			queryStringArray += "&" + Object.keys(arrayObject[index]).map(prop => `${nameObject}[${index}].${prop}=${arrayObject[index][prop]}`).join("&");
		}

		return queryStringArray;
	}

	//#endregion
}