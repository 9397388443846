import { Pipe, PipeTransform } from "@angular/core";
const filesize = require("filesize");

@Pipe({
  name: "fileSizeFormat"
})
export class FileSizeFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return filesize(value || 0);
  }
}
