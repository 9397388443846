export function addCustomValidateDependsAnotherFieldValue(validate: any) {
    validate.validators.validDependsAnotherFieldValue = (value: any, options: any, key: any, attributes: any) => {

        let isNull = (valor: any) => (valor == undefined || valor == null || valor == '');

        // if(key == 'notificacaoEventosContato')
        //     debugger;

        if (Array.isArray(options.anotherFieldValue)) {
            if (options.anotherFieldValue.some((c: any) => c === attributes[options.anotherField])) {
                let r = {};
                r[key] = options.rules;
                let v = validate.validate(attributes, r);

                if (!isNull(v))
                    return v[key].map((m: string) => `^${m}`);

                // if (!isNull(v))
                //     return v[key].map((m: any) => {
                //         if (typeof (m) === 'string')
                //             return `^${m}`;

                //         if (typeof (m) === 'object') {
                //             var r: any = {};
                //             Object.keys(m.errors).forEach(f1 => {
                //                 Object.keys(m.errors['0']).forEach(f2 => {
                //                     r[f2] = m.errors['0'][f2].map((m: string) => `${m}`)
                //                 });
                //             });
                            
                //             return r;
                //         }
                //     });
            }
        } else {
            if (attributes[options.anotherField] === options.anotherFieldValue) {
                let r = {};
                r[key] = options.rules;
                let v = validate.validate(attributes, r);

                if (!isNull(v))
                    return v[key].map((m: string) => `^${m}`);

                // if (!isNull(v))
                //     return v[key].map((m: any) => {
                //         if (typeof (m) === 'string')
                //             return `^${m}`;

                //         if (typeof (m) === 'object') {
                //             var r: any = {};
                //             Object.keys(m.errors['0']).forEach(ff => {
                //                 r[ff] = m.errors['0'][ff].map((m: string) => `${m}`)
                //             });
                //             return r;
                //         }
                //     });
            }
        }

        return null;
    };
}