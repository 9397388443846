import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberFormat"
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return 0;
    return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
}
