import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';

import { Carteira } from "../model/carteira";
import { CarteiraUsuario } from "../model/carteira-usuario";
import { isNull } from "../utils/";

@Injectable()
export class CarteiraService {
    private api: string;

    constructor(private httpClient: HttpClient) { 
        this.api = '/api/carteiras';
    }

    public obterCarteiras(filtro?: any) {
        let url = this.api;

        if (filtro)
            url += "?" + Object.keys(filtro).map(prop => `${prop}=${filtro[prop]}`).join("&");

        return this.httpClient.get<Array<Carteira>>(url);
    }

    public obterPorId(id: number) {
        // return this.httpClient.get<Carteira>(`${environment.serverUrl}/api/carteiras/${id}`)
        //     .pipe(map(f => Carteira.fromRaw(f)));

        return this.httpClient.get<Carteira>(`${this.api}/${id}`)
            .pipe(map(f => {
                let carteira = Carteira.fromRaw(f);

                if (!isNull(carteira.carteiraUsuario))
                    carteira.carteiraUsuario = carteira.carteiraUsuario.map((m: CarteiraUsuario) => CarteiraUsuario.fromRaw(m));

                return carteira;
            }));
    }

    public criar(carteira: Carteira) {
        return this.httpClient.post<number>(`${this.api}/`, carteira);
    }

    public atualizar(carteira: Carteira) {
        return this.httpClient.put<number>(`${this.api}/${carteira.carteiraId}`, carteira);
    }

    public desativarCarteira(carteiraId: number) {
        return this.httpClient.patch<any>(`${this.api}/${carteiraId}/desativar-carteira`, null);
    }

    public obterCarteiraPadrao() {
        return this.httpClient.get<any>(`${this.api}/carteira-padrao`);
    }
}
