import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NNotificacao, NNotificacaoCampos, NNotificacaoConexaoApi, NNotificacaoConexaoWhatsapp, NNotificacaoEvento } from "../../common/model/nnotificacao.index";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { NotificacaoService } from "../../common/service/notificacao.service";
import { NotificacaoEventoPipe } from "../libs/pipes/notificacao-evento-pipe"
import { NotificacaoHistoricoComponent } from "../notificacao-historico/notificacao-historico.component";

@Component({
    selector: "app-lista-notificacao",
    templateUrl: "./lista-notificacao.component.html",
    styleUrls: ["./lista-notificacao.component.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: NotificacaoEventoPipe, provide: NotificacaoEventoPipe }
    ]
})
export class ListaNotificacaoComponent implements OnInit {
    debug: boolean = false;
    notificacaoAcao: number = 3;
    eventosAgrupados: any[] = [];
    notificacaoTipoIcone: any = { "error": "cancel", "warning": "warning", "info": "info" };

    notificacao: NNotificacao = new NNotificacao();
    camposApi: Array<NNotificacaoCampos> = [];

    notificacaoConexaoApi: NNotificacaoConexaoApi | null;
    notificacaoConexaoWhatsapp: NNotificacaoConexaoWhatsapp | null;
    notificacaoEvento: NNotificacaoEvento | null;


    editarConexaoApi(conexao: NNotificacaoConexaoApi) {
        this.notificacaoConexaoApi = NNotificacaoConexaoApi.fromRaw(conexao);
    }

    editarConexaoWhatsapp(conexao: NNotificacaoConexaoWhatsapp) {
        this.notificacaoConexaoWhatsapp = NNotificacaoConexaoWhatsapp.fromRaw(conexao);
    }

    editarEvento(evento: NNotificacaoEvento) {
        this.notificacaoEvento = NNotificacaoEvento.fromRaw(evento);
    }

    @ViewChild("historicoComponent", { static: false })
    historicoComponent: NotificacaoHistoricoComponent;

    constructor(
        private notificacaoService: NotificacaoService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        private customAlertService: CustomAlertService) {



        this.route.queryParams.subscribe(params => {
            if (params.debug != undefined)
                this.debug = (params.debug === 'true');
        });

        this.route.params.subscribe(params => {
            if (!params.id)
                return;
            //this.visualizarNotificacao(null, params.id);
        });

        this.notificacaoConexaoApi = null;
        this.notificacaoConexaoWhatsapp = null;
        this.notificacaoEvento = null;
    }

    ngOnInit() {
        this.setarFiltroPadrao();
        this.obterConfiguracaoNotificacao();
        this.obterFiltrosEventosAgrupados();
    }

    obterConfiguracaoNotificacao() {
        //this.notificacaoService.obterConfiguracaoNotificacao().subscribe((result: NNotificacao) => this.notificacao = result);
    }

    obterFiltrosEventosAgrupados() {
        this.notificacaoService.obterFiltrosEventosAgrupados().then((result: any) => {
            this.eventosAgrupados = result;

            this.obterQuantidadeHistoricoNaoLido();

        });
    }

    obterQuantidadeHistoricoNaoLido() {
        
        this.notificacaoService.obterQuantidadeHistoricoNaoLido().subscribe((result: any[]) => {
            this.eventosAgrupados.forEach((item: any) => {
                item.naolidos = result[item.id] as number;
            });
        }, (error: any) => { });
    }



    mostrarHistorico(item: any) {
        this.setarAcao(1);

        setTimeout(() => {
            this.historicoComponent.obterNotificacaoHistorico(this.notificacao.id, item.eventos);
        }, 200);
    }

    setarAcao(acao: number) {
        this.notificacaoAcao = acao;
        this.notificacaoConexaoApi = null
        this.notificacaoConexaoWhatsapp = null;
        this.notificacaoEvento = null;
    }


    //#region [ Métodos Filtro ]

    setarFiltroPadrao() {
        // let dataFim = new Date();
        // let dataInicio = new Date(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate() - 1);
        // this.filtro = new FiltroNotificacao();
        // this.filtro.dataInicio = dataInicio;
        // this.filtro.dataFim = dataFim;
        // this.filtro.notificacaoEventoId = null;
        // this.filtro.notificacaoTipoId = null;
        // this.filtro.tamanhoLote = 100;
    }

    limpar() {
        this.setarFiltroPadrao();
    }

    //#endregion


}

// guardarConexoes() {
//     // this.conexoesOriginais = {
//     //     notificacaoConexaoApi: this.notificacao.notificacaoConexaoApi.map((m: NNotificacaoConexaoApi) => NNotificacaoConexaoApi.fromRaw(JSON.parse(JSON.stringify(m)))),
//     //     notificacaoConexaoDb: this.notificacao.notificacaoConexaoDb.map((m: NNotificacaoConexaoDb) => NNotificacaoConexaoDb.fromRaw(JSON.parse(JSON.stringify(m)))),
//     //     notificacaoConexaoRabbitMQ: this.notificacao.notificacaoConexaoRabbitMQ.map((m: NNotificacaoConexaoRabbitMQ) => NNotificacaoConexaoRabbitMQ.fromRaw(JSON.parse(JSON.stringify(m)))),
//     //     notificacaoConexaoWhatsapp: this.notificacao.notificacaoConexaoWhatsapp.map((m: NNotificacaoConexaoWhatsapp) => NNotificacaoConexaoWhatsapp.fromRaw(JSON.parse(JSON.stringify(m))))
//     // };
// }

// public get conexoesAlteradas() {
//     //return (this.isModelChanged('notificacaoConexaoApi') || this.isModelChanged('notificacaoConexaoDb') || this.isModelChanged('notificacaoConexaoRabbitMQ') || this.isModelChanged('notificacaoConexaoWhatsapp'))
// }

// conexoesOriginais: {
//     notificacaoConexaoApi: Array<NNotificacaoConexaoApi>;
//     notificacaoConexaoDb: Array<NNotificacaoConexaoDb>;
//     notificacaoConexaoRabbitMQ: Array<NNotificacaoConexaoRabbitMQ>;
//     notificacaoConexaoWhatsapp: Array<NNotificacaoConexaoWhatsapp>;
// } = { notificacaoConexaoApi: [], notificacaoConexaoDb: [], notificacaoConexaoRabbitMQ: [], notificacaoConexaoWhatsapp: [] };

// isModelChanged(property: string): boolean {

//     // if (this.conexoesOriginais[property].length !== this.notificacao[property].length) {
//     //     return true;  // Retorna true se o tamanho do array for diferente
//     // }

//     // for (let i = 0; i < this.conexoesOriginais[property].length; i++) {
//     //     const originalItem = this.conexoesOriginais[property][i];
//     //     const currentItem = this.notificacao[property][i];

//     //     if (!this.deepEqual(originalItem, currentItem)) {
//     //         return true;  // Retorna true se qualquer item no array for diferente
//     //     }
//     // }

//     return false;  // Retorna false se nenhum valor foi alterado

// }

// private getNonEmptyKeys(obj: any): string[] {
//     // Função para verificar se o valor não é nulo, indefinido ou vazio
//     const isNonEmpty = (value: any) => value !== null && value !== undefined && value !== "";

//     // Filtra as chaves do objeto, mantendo apenas aquelas cujos valores são não vazios
//     return Object.keys(obj).filter(key => isNonEmpty(obj[key]));
// }

// private deepEqual(obj1: any, obj2: any): boolean {
//     // Normaliza undefined, null e "" para null
//     const normalize = (value: any) => (value === undefined || value === null || value === "") ? null : value;

//     // Se ambos forem null, undefined ou "", são equivalentes
//     if (normalize(obj1) === normalize(obj2)) {
//         return true;
//     }

//     // Se um for null, undefined ou "", e o outro não, ou se forem tipos primitivos diferentes, são diferentes
//     if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
//         return false;
//     }

//     // Comparação de arrays
//     if (Array.isArray(obj1) && Array.isArray(obj2)) {
//         if (obj1.length !== obj2.length) return false;
//         for (let i = 0; i < obj1.length; i++) {
//             if (!this.deepEqual(obj1[i], obj2[i])) return false;
//         }
//         return true;
//     }

//     // Comparação de objetos: Verifica se têm o mesmo conjunto de chaves
//     const keys1 = this.getNonEmptyKeys(obj1);
//     const keys2 = this.getNonEmptyKeys(obj2);

//     if (keys1.length !== keys2.length) {
//         return false;
//     }

//     // Compara recursivamente cada propriedade, normalizando null, undefined e ""
//     for (const key of keys1) {
//         if (!this.deepEqual(normalize(obj1[key]), normalize(obj2[key]))) {
//             return false;
//         }
//     }

//     return true;  // Retorna true se todos os valores forem iguais
// }



// Função de comparação profunda
// private deepEqual(obj1: any, obj2: any): boolean {
//     // Normaliza undefined para null
//     if (obj1 === undefined) obj1 = null;
//     if (obj2 === undefined) obj2 = null;

//     if (obj1 === obj2) {
//         return true;  // Objetos idênticos
//     }

//     if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
//         return false;  // Se não forem objetos ou algum for null
//     }

//     // Verifica se têm o mesmo conjunto de chaves
//     const keys1 = Object.keys(obj1);
//     const keys2 = Object.keys(obj2);

//     if (keys1.length !== keys2.length) {
//         return false;  // Se o número de propriedades for diferente
//     }

//     // Compara recursivamente cada propriedade
//     for (const key of keys1) {
//         if (!this.deepEqual(obj1[key], obj2[key])) {
//             return false;  // Retorna false se qualquer valor for diferente
//         }
//     }

//     return true;  // Retorna true se todos os valores forem iguais
// }


// visualizarNotificacao(notificacao: any, id: number) {
//     // this.dialog.open(DetalheNotificacaoComponent, {
//     //     hasBackdrop: true,
//     //     width: "50%",
//     //     data: {
//     //         'notificacao': notificacao,
//     //         'id': id
//     //     }
//     // });
// }

// preparaSalvar(notificacaoPost: NNotificacao, acao: string, tabSelected: number, notificacaoAcao: number): boolean{
//     if (this.isModelChanged(`notificacaoConexao${acao}`)) {
//         let resultValidate = eval(`this.notificacao.validate${acao}()`);

//         if (resultValidate) {
//             this.tabSelected = tabSelected;
//             this.notificacaoAcao = notificacaoAcao;
//             return true;
//         }
//     } else {
//         notificacaoPost[`notificacaoConexao${acao}`] = null;
//     }
//     return false;
// }