
import { Subject, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, NgZone, KeyValueDiffer, KeyValueDiffers } from "@angular/core";
import { MatDialog } from '@angular/material';
import * as jquery from 'jquery';

import { ListaService } from "../../common/service/lista.service"
import { TemplateService } from "../../common/service/template.service";
import { ListaExpressaoService } from '../../common/service/lista-expressao.service';
import { NotificationService } from "../../common/service/notification.service";
import { ExpressaoRegex } from '../../common/model/expressao-regex';

import { ESBuilderData, ESBuilderRules, ESBuilderRulesConditions, ESBuilderRulesChange, ESBFiltroStatusRegistroLista, ESBuilderQuantityList, ESBFilterType } from "../models";
import { QueryToModel, ModelToQuery, isObjectEmpty, ModelToModelReverse } from '../libs/utils';
import { EsBuilderTestService } from '../libs/services/esbuilder-test.service';
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { Lista } from '../../common/model/lista';
import { EsBuilderService } from '../libs/services/esbuilder.service';
import { isNull, isNullOrEmpty, isNullOrZero } from '../../common/utils';
import { ElasticsearchRepositoryService } from '../../elasticsearch/repository/elasticsearch-repository.service';
import { generateCanvas } from '../../common/utils/generateCanvas';
import { FiltroQueryService } from '../../common/service/filtro-query.service';
import { FiltroQuery } from '../../common/model/filtro-query';
import { EsBuilderModalIAComponent } from '../esbuilder-modal-ia/esbuilder-modal-ia.component';

const $ = jquery;


@Component({
    selector: "app-esbuilder-filter",
    templateUrl: "./esbuilder-filter.component.html",
    styleUrls: ["./esbuilder-filter.component.scss"],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: FiltroQueryService, provide: FiltroQueryService },
        { useClass: ListaExpressaoService, provide: ListaExpressaoService },
        //{ useClass: EsBuilderService, provide: EsBuilderService },
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
        { useClass: EsBuilderTestService, provide: EsBuilderTestService }
    ]
})
export class EsBuilderFilterComponent implements OnInit, OnDestroy {

    private queryDefaultTotal: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[]}}}');
    private queryDefaultNotIntegrated: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[{"exists":{"field":"_integrado"}}]}}}');
    private queryDefaultIntegrated: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[{"exists":{"field":"_integrado"}}],"must_not":[]}}}');

    pctEncontrados: number;
    pctDescartados: number;

    //#region [ Inputs ]

    @Input()
    debug: boolean = false;

    @Input()
    enableConditionOr: boolean = true;

    @Input()
    appendToSelect: string = "body"

    @Input()
    activeStandardFilter: boolean = true;

    @Input()
    readOnly: boolean = false;

    @Input()
    edit: boolean = false;

    _heightActivateScrollbar: number | null;
    _activateScrollbar: boolean = false;

    @Input()
    public set heightActivateScrollbar(h: number | null) {
        if (!h || isNullOrZero(h)) {
            this._activateScrollbar = false;
            return
        }

        this._heightActivateScrollbar = h;
        this._activateScrollbar = true;
    }

    public get heightActivateScrollbar() {
        return this._heightActivateScrollbar;
    }


    //#endregion

    //#region [ Subscriptions ]

    subLista: Subscription;
    subCamposTemplate: Subscription;
    subRegex: Subscription;
    subFiltrosPadrao: Subscription;

    //#endregion

    //#region [ GET - Disabled ]  

    public get disabled() {
        return (isNullOrZero(this.listaId));
    }

    //#endregion

    //#region [ GET/SET - ListaId ]  

    _listaId: number;

    @Input()
    public set listaId(l: number) {
        if (!l || isNullOrZero(l)) {
            this._listaId = 0;
            this.lista = new Lista();
            this.listaNome = "";
            this.templateId = 0;
            this.quantityList.limpar();
            this.listaFiltrosQuerys = []
            return;
        }

        this._listaId = l;
        this.obterLista();
        this.obterFiltrosPadrao();

        if (this.edit == false)
            this.updateQuantityList();

    }

    public get listaId() {
        return this._listaId;
    }

    //#endregion

    //#region [ GET/SET - Query ]  

    _query: any = {};

    @Input()
    public set query(q: any) {
        if (!q || isObjectEmpty(q)) return;


        //console.log(`changed_query: ${JSON.stringify(q)}`);

        this._query = q;
    }

    public get query() {
        return this._query;
    }

    //#endregion

    //#region [ GET/SET - DataRules ]  

    _dataRules: ESBuilderData;// = new ESBuilderData();

    @Input()
    public set dataRules(dr: ESBuilderData) {
        if (!dr) return;
        this._dataRules = dr;

        //console.log(`changed_dataRules: ${JSON.stringify(dr)}`);



        if (this._dataRules.rules.length <= 0)
            this.addRule();
    }

    public get dataRules() {
        return this._dataRules;
    }

    //#endregion

    //#region [ GET/SET - Campos Mapeados ]  

    _camposMapeados: Array<any>;

    public set camposMapeados(cm: Array<any>) {
        if (!cm) return;
        this._camposMapeados = cm;
    }

    public get camposMapeados() {
        return this._camposMapeados;
    }

    //#endregion

    //#region [ GET/SET - Lista Expressao Regex ]  

    _listaExpressaoRegex = new Array<ExpressaoRegex>();

    public set listaExpressaoRegex(rg: Array<ExpressaoRegex>) {
        if (!rg) return;
        this._listaExpressaoRegex = rg;
    }

    public get listaExpressaoRegex() {
        return this._listaExpressaoRegex;
    }

    //#endregion

    //#region [ GET/SET - ListaId ]  

    _finishedLoading: boolean;

    @Input()
    public set finishedLoading(l: boolean) {
        this._finishedLoading = (!l || isNull(l)) ? false : l;

        //if (this._finishedLoading === true)
        //this.updateQuantityList();
    }

    public get finishedLoading() {
        return this._finishedLoading;
    }

    //#endregion

    //#region [ GET/SET - StandardFilters ]  

    _standardFilters: Array<number>;// = new ESBuilderData();

    @Input()
    public set standardFilters(sf: Array<number>) {
        if (!sf) return;
        this._standardFilters = sf;
    }

    public get standardFilters() {
        return this._standardFilters;
    }

    //#endregion

    //#region [ GETS ]

    public get listaNaoSelecionada() {
        return (isNullOrZero(this.listaId));
    }

    //#endregion

    //#region [ Propriedades ]   

    showStandardFilter = false;

    lista: Lista;
    listaNome: string;
    templateId: number;
    statusRegistroLista = ESBFiltroStatusRegistroLista;
    listaFiltrosQuerys: Array<FiltroQuery> = [];
    listaFiltrosQuerysSelected: FiltroQuery[] = [];

    @Output('updateQuery')
    updateQuery: EventEmitter<any> = new EventEmitter<any>();

    @Output('quantityListChanged')
    quantityListChanged = new EventEmitter<any>();

    //#endregion

    public ctxEncontrados: CanvasRenderingContext2D | null | undefined;
    public ctxDescartados: CanvasRenderingContext2D | null | undefined;

    @ViewChild('canvasEncontrados', { static: true }) public canvasEncontrados: ElementRef<HTMLCanvasElement>;
    @ViewChild('canvasDescartados', { static: true }) public canvasDescartados: ElementRef<HTMLCanvasElement>;

    constructor(private zone: NgZone,
        public dialog: MatDialog,
        private listaService: ListaService,
        private templateService: TemplateService,
        private elasticsearchService: ElasticsearchRepositoryService,
        private filtroQueryService: FiltroQueryService,
        private listaExpressaoService: ListaExpressaoService,
        private notificationService: NotificationService,
        private esBuilderService: EsBuilderService,
        private esBuilderTestService: EsBuilderTestService,
        private differs: KeyValueDiffers) {

        this.updateQuery = new EventEmitter<any>();
        this.quantityListChanged = new EventEmitter<any>();

        this._dataRules = new ESBuilderData();
        this._query = ModelToQuery.convert(this._dataRules);

        this.agendamentoDiferenca = this.differs.find(this.dataRules).create();
    }

    //#region [ Eventos do componente ]

    ngOnInit() {
        this.obterLista();
        this.obterListaExpressao();

        this.ctxEncontrados = generateCanvas(this.canvasEncontrados, 0, '#2bcb43');
        this.ctxDescartados = generateCanvas(this.canvasDescartados, 0, '#f73a2f');
    }

    ngOnDestroy() {
        if (this.subLista)
            this.subLista.unsubscribe();

        if (this.subCamposTemplate)
            this.subCamposTemplate.unsubscribe();

        if (this.subRegex)
            this.subRegex.unsubscribe();

        if (this.subFiltrosPadrao)
            this.subFiltrosPadrao.unsubscribe();
    }

    //#endregion

    //#region [ Dados ]

    obterLista() {
        if (this.listaId <= 0) return;

        this.subLista = this.listaService.obterListaPorId(this.listaId).subscribe((result: any) => {
            this.lista = result;
            this.listaNome = this.lista.nome
            this.templateId = this.lista.listaTemplateId;

            this.updateQuantityList();
            this.obterCamposMapeados();
        });
    }

    obterCamposMapeados() {
        this.subCamposTemplate = this.templateService.obterCamposMapeados(this.templateId, this.lista.listaId).subscribe((result: any) => {
            this.camposMapeados = result.filter(f => f.itemTipo != 2);
            this.esBuilderService.emitDadosListaCarregados(this.listaNome, this.templateId);
        });
    }

    obterListaExpressao(): any {
        this.subRegex = this.listaExpressaoService.obterExpressao().subscribe((result: any) => {
            this.listaExpressaoRegex = result;
            this.esBuilderService.emitDadosListaExpressaoCarregados(this.listaExpressaoRegex);
        });
    }

    //#endregion

    //#region [ Clipboard ]

    notify(): void {
        this.notificationService.success("esBuilderComponent.sucesso", "esBuilderComponent.queryCopiadaSucesso");
    }

    copyQueryClipboard(): string {
        return JSON.stringify({ 'dataRule': this.dataRules, 'query': this.query });
    }

    //#endregion 

    //#region [ QuantityList ] 

    quantityList: ESBuilderQuantityList = new ESBuilderQuantityList();

    updateQuantityList() {

        if (isNullOrZero(this.listaId))
            return;

        this.elasticsearchService
            .getQuantidadeLista(this.listaId, 0, (this.query || {}), null, null, null, null, null, true)
            .subscribe((quantityList: any) => {

                this.quantityList = ESBuilderQuantityList.fromRaw(quantityList);

                let total = 0;

                if (this.allRegisters)
                    total = quantityList.totalGeral;

                if (this.onlyRegistersAvailable)
                    total = quantityList.quantidadeLivre;

                if (this.onlyUsedRegisters)
                    total = quantityList.quantidadeDistribuida;

                if (total <= 0)
                    total = 1;

                this.pctEncontrados = quantityList.quantidadeEstrategia * 100 / total;
                this.pctDescartados = quantityList.quantidadeDescartado * 100 / total;

                this.ctxEncontrados = generateCanvas(this.canvasEncontrados, this.pctEncontrados, '#2bcb43');
                this.ctxDescartados = generateCanvas(this.canvasDescartados, this.pctDescartados, '#f73a2f');

                //this.reqUpdate = false;

                this.quantityListChanged.emit(this.quantityList);

            });
    }

    //#endregion

    //#region [ IncludeIntegratedRegister ]

    checkOnClickAllRegisters(event: any) {
        if (this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.total)
            event.preventDefault();
    }

    checkOnClickOnlyRegistersAvailable(event: any) {
        if (this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.naoUtilizado)
            event.preventDefault();
    }

    checkOnClickOnlyUsedRegisters(event: any) {
        if (this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.utilizado)
            event.preventDefault();
    }

    public get allRegisters() {
        return this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.total;
    }

    public set allRegisters(value: boolean) {
        if (this.readOnly) return;
        this.dataRules.includeIntegratedRegister = ESBFiltroStatusRegistroLista.total;
    }

    public get onlyRegistersAvailable() {
        return this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.naoUtilizado;
    }

    public set onlyRegistersAvailable(value: boolean) {
        if (this.readOnly) return;
        this.dataRules.includeIntegratedRegister = ESBFiltroStatusRegistroLista.naoUtilizado;
    }

    public get onlyUsedRegisters() {
        return this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.utilizado;
    }

    public set onlyUsedRegisters(value: boolean) {
        if (this.readOnly) return;
        this.dataRules.includeIntegratedRegister = ESBFiltroStatusRegistroLista.utilizado;
    }

    updateIntegrated(filtroStatus: ESBFiltroStatusRegistroLista) {
        if (this.readOnly) return;

        if (this.query == undefined) {
            this.query = (filtroStatus == ESBFiltroStatusRegistroLista.naoUtilizado) ?
                this.queryDefaultNotIntegrated : (filtroStatus == ESBFiltroStatusRegistroLista.utilizado) ? this.queryDefaultIntegrated : this.queryDefaultTotal;
        }
        else {
            if (!isNull(this.query.query.bool.must))
                this.query.query.bool.must = this.query.query.bool.must.filter(f => {
                    if (f.exists != undefined)
                        return f.exists.field != "_integrado";

                    return true;
                })
            if (!isNull(this.query.query.bool.must_not))
                this.query.query.bool.must_not = this.query.query.bool.must_not.filter(f => {
                    if (f.exists != undefined)
                        return f.exists.field != "_integrado";

                    return true;
                });
        }

        if (filtroStatus == ESBFiltroStatusRegistroLista.naoUtilizado) {
            if (isNull(this.query.query.bool.must_not))
                this.query.query.bool.must_not = [];

            this.query.query.bool.must_not.push({ exists: { field: "_integrado" } });
        }
        else if (filtroStatus == ESBFiltroStatusRegistroLista.utilizado) {
            if (isNull(this.query.query.bool.must))
                this.query.query.bool.must = [];

            this.query.query.bool.must.push({ exists: { field: "_integrado" } });
        }

        this.dataRules.includeIntegratedRegister = filtroStatus;
        this.updateQuantityList();
        this.updateQuery.emit({ 'query': this.query, 'dataRules': this.dataRules });
    }

    //#endregion

    //#region [ Rules ]

    addRule(scrollingDown: boolean = false) {
        if (this.dataRules.rules == undefined)
            this.dataRules.rules = [];

        let o = (this.dataRules.rules.length > 0) ? Math.max.apply(Math, this.dataRules.rules.map((m: ESBuilderRules) => (m.order))) + 1 : 0;

        this.dataRules.rules.push(new ESBuilderRules({
            "condition": (o == 0) ? ESBuilderRulesConditions.none : ESBuilderRulesConditions.and,
            "field": "",
            "fieldType": "",
            "filterType": "",
            "filterValues": {},
            "order": o
        }));

        if (scrollingDown) {
            let esbuilderScrollbar = $('#esbuilderScrollbar');
            let scrollHeight = (document.querySelector('#esbuilderScrollbar') as Element).scrollHeight;
            esbuilderScrollbar.stop().animate({ 'scrollTop': scrollHeight }, 1000);
            //let last = $('#esbuilderScrollbar LI:last-child');
            //esbuilderScrollbar.stop().animate({ 'scrollTop': last.position().top }, 1000);
        }
    }

    //#endregion

    //#region [ Filtros Padrão ]

    toggleStandardFilter() {
        if (isNullOrZero(this.listaId))
            return;
        this.showStandardFilter = !this.showStandardFilter;
    }

    obterFiltrosPadrao() {
        this.subFiltrosPadrao = this.filtroQueryService.obterFiltros({ 'listaId': this.listaId }).subscribe((result: FiltroQuery[]) => {
            this.listaFiltrosQuerys = result.filter((f: FiltroQuery) => {
                if (ESBuilderData.fromRaw(JSON.parse(f.filtroRegras)).filterType === this.dataRules.filterType)
                    return f;
            });

            if (!isNull(this.standardFilters) && !isObjectEmpty(this.standardFilters)) {
                this.listaFiltrosQuerys.filter(item => item.ativo).forEach((item: FiltroQuery) => {
                    if (this.standardFilters.some((s: number) => s == item.filtroQueryId))
                        this.listaFiltrosQuerysSelected.push(item);
                });
            }
        });
    }

    aplicar() {
        if (!this.activeStandardFilter)
            return;

        let data: any = { 'standardFilters': [] };
        let dataRules: Array<ESBuilderRules> = [];

        // Adiciona os novos filtros padrões
        this.listaFiltrosQuerysSelected.forEach((filtro: FiltroQuery) => {
            let filtroRegrasData = ESBuilderData.fromRaw(JSON.parse(filtro.filtroRegras));
            filtroRegrasData.rules.forEach((rule: ESBuilderRules) => {
                rule.ruleReadOnly = true;
                rule.standardFilter = filtro.filtroQueryId;
                dataRules.push(rule);
            });
            data.standardFilters.push(filtro.filtroQueryId);
        });

        // Adiciona os filtros já existentes que não sao de nenum filtro padrão
        this.dataRules.rules
            .filter((r: ESBuilderRules) => isNullOrZero(r.standardFilter))
            .forEach((rule: ESBuilderRules) => {
                // let excpt = ["vazio", "nao_vazio", "existe", "nao_existe"];

                // if (isObjectEmpty(rule.filterValues) && isNull(rule.rules) && !excpt.some((s: string) => (s == rule.filterType)))
                //     return;

                dataRules.push(rule);
            });

        this.dataRules.rules = dataRules;
        this.query = ModelToQuery.convert(this.dataRules);

        data.dataRules = this.dataRules;
        data.query = this.query;

        this.updateQuery.emit(data);
        this.showStandardFilter = false;
    }

    //#endregion

    private agendamentoDiferenca: KeyValueDiffer<string, any>;



    ngDoCheck(): void {
        const changes = this.agendamentoDiferenca.diff(this.dataRules);

        //console.log(changes);

        if (changes) {
            this.query = ModelToQuery.convert(this.dataRules);

            if (this.finishedLoading === true)
                this.updateQuantityList();

            this.updateQuery.emit({ 'query': this.query, 'dataRules': this.dataRules });

            this.showStandardFilter = false;
        }
        //this.agendamentoChange.emit(this.dataRules);
    }


    dataRuleChanged(event: ESBuilderRulesChange) {
        this.agendamentoDiferenca = this.differs.find(this.dataRules).create();

        /*
        this.query = ModelToQuery.convert(this.dataRules);

        if (this.finishedLoading === true)
            this.updateQuantityList();

        this.updateQuery.emit({ 'query': this.query, 'dataRules': this.dataRules });

        this.showStandardFilter = false;
        */
    }

    //#region [ Debug ]

    // obterDataRules() {
    //     this.subDataRule = this.esBuilderTestService.dataRuleCondicao07().subscribe((result: ESBuilderData) => {
    //         this.dataRules = result;
    //     });
    // }

    // modelToQuery() {
    //     let query = ModelToQuery.convert(this.dataRules);
    //     console.log(JSON.stringify(query));
    // }

    // queryToModel() {
    //     this.esBuilderTestService.queryCondicao07().subscribe((result: string) => {
    //         let query = result;
    //         let model = QueryToModel.convert(query, this.camposMapeados);
    //         this.listaExpressaoRegex = this.listaExpressaoRegex;
    //         this.dataRules = model;
    //         console.log(JSON.stringify(model));
    //     });
    // }

    //#endregion

    showPreview(reverse: boolean = false) {

        if (isNullOrZero(this.listaId))
            return;

        let query: any = this.query;


        if (reverse) {
            // Muda os filtros para reverter a query
            this.dataRules.rules.forEach((i: ESBuilderRules) => {
                i.reverse = true;
                if (i.rules)
                    i.rules.forEach((i: ESBuilderRules) => (i.reverse = true));
            });

            // Inverte as regras e gera o filtro Inverso do ElasticSearch
            let rulesReverse: any = ModelToModelReverse.convert(this.dataRules);
            query = ModelToQuery.convert(rulesReverse);
        }


        query["size"] = 10;

        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                listaId: this.listaId,
                lista: this.listaNome,
                templateId: this.templateId,
                query: query
            }
        });
    }

    createfilterIA() {
        let dialogRef = this.dialog.open(EsBuilderModalIAComponent, {
            height: "550px",
            width: "750px",
            hasBackdrop: true,
            data: {
                listId: this.listaId,
                debug: this.debug
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result)
                return;

            result.dataRules.includeIntegratedRegister = this.dataRules.includeIntegratedRegister;

            if (this.dataRules.rules.length == 1 && (isNullOrEmpty(this.dataRules.rules[0].field) || isNullOrEmpty(this.dataRules.rules[0].filterType)))
                this.dataRules = result.dataRules;
            else
                this.dataRules.rules.push(...result.dataRules.rules);
            this.query = ModelToQuery.convert(this.dataRules);


            // estrategia.filtro = JSON.stringify(result.query);
            // estrategia.filtroRegras = JSON.stringify(result.dataRules);
            // estrategia.quantidadeMaximaExportacao = result.quantityList;
            // this.regua.reguaEstrategia[i].estrategia = estrategia;
        });
    }

    // eventsSubject: Subject<void> = new Subject<void>();

    // private filterTypeChanged: EventEmitter<any> = new EventEmitter();

    filterTypeChange(event: any) {
        //this.enableConditionOr = (event.value == ESBFilterType.advanced);
        // this.filterTypeChanged.emit(true);

        let dataRules = new ESBuilderData();
        dataRules.type = this.dataRules.type;
        dataRules.filterType = this.dataRules.filterType;
        dataRules.includeIntegratedRegister = this.dataRules.includeIntegratedRegister;

        this.dataRules.rules.forEach((r: ESBuilderRules, index: number) => {
            if (isNullOrZero(r.standardFilter)) {
                if (index > 0)
                    r.condition = ESBuilderRulesConditions.and;

                r.rules = [];
                dataRules.rules.push(r);
            }
        });

        this.dataRules = dataRules;

        this.obterFiltrosPadrao();

        this.esBuilderService.emitFilterTypeChange('');
    }





}