import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { FormsModule, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatStepperModule,
    MatSelectModule,
    MatCardModule,
    MatRadioModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatListModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatTabsModule,
    MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
import { TableModule } from "primeng/table";
import { DataViewModule } from "primeng/dataview";
import { PickListModule } from 'primeng/picklist';
import { AppCommonModule } from "../common/common.module";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { ElasticsearchModule } from "../elasticsearch/elasticsearch.module";
import { FormPlanoTarifaProvedorComponent } from './form-plano-tarifa-provedor/form-plano-tarifa-provedor.component';
import { environment } from "../../../environments/environment";
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { EsBuilderModule } from "../esbuilder/esbuilder.module";
import { NgSelectModule } from '@ng-select/ng-select';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

@NgModule({
    imports: [
        CommonModule,
        AppCommonModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        FormsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatStepperModule,
        MatSlideToggleModule,
        ElasticsearchModule,
        EsBuilderModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCardModule,
        MatDividerModule,
        MatListModule,
        MatTabsModule,
        NgSelectModule,
        TableModule,
        DataViewModule,
        AmazingTimePickerModule,
        MatRadioModule,
        MatExpansionModule,
        MatCardModule,
        MatPaginatorModule,
        MatDialogModule,
        NgxGraphModule,
        DragDropModule,
        PickListModule
    ],
    providers: [
        { provide: FormBuilder, useClass: FormBuilder },
        { provide: LOCALE_ID, useValue: "pt-BR" },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` } }
    ],
    entryComponents: [],
    declarations: [
        FormPlanoTarifaProvedorComponent
        //CurrencyPipe
    ],
    exports: []
})
export class PlanoTarifaProvedorModule { }
