import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { convertToQueryString } from "../../common/service/query-string";
import { Configuracao } from "../model/configuracao";

@Injectable()
export class ConfiguracaoService {
    private api: string;

    constructor(private httpClient: HttpClient) {
        this.api = '/api/configuracao';
    }

    public obterConfiguracoes(filtro: any): Observable<Configuracao[]> {
        return this.httpClient.get<Configuracao[]>(`${this.api}${convertToQueryString(filtro)}`)
            .pipe(map(query => query.map(m => Configuracao.fromRaw(m))));
    }

    public obterConfiguracao(grupo: string, chave : string): Observable<Configuracao> {
        return this.httpClient.get<Configuracao[]>(`${this.api}/grupo/${grupo}/chave/${chave}`)
            .pipe(map(query =>  Configuracao.fromRaw(query)));
    }

    public loginOktaAtivo(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('blockLoading', 'true');
        return this.httpClient.get<any>(`${this.api}/loginOktaAtivo`, { headers: headers });
    }

    public atualizar(configuracoes: Array<Configuracao>) {
        return this.httpClient.put<number>(`${this.api}/`, configuracoes);
    }
}
