import "rxjs/add/operator/map";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { SistemaRecursoAcao } from "../model/sistema-recurso-acao";

@Injectable()
export class SistemaRecursoAcaoService {
	private api: string;

	constructor(private httpClient: HttpClient) {
		this.api = '/api/sistemaRecursoAcao';
	}

	obterRecursos() {
		return this.httpClient.get<Array<SistemaRecursoAcao>>(`${this.api}`);
	}
}
