import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ProviderService } from "../../../common/service/provider.service";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { DefaultValuesService } from "../../../common/service/default-values.service";
import { CamposIntegracaoService } from "../../../common/service/campos-integracao.service";
import { Provider } from "../../../common/model/provider";
import { TipoAutenticacao, IntegracaoTipo } from "../../../common/model/provedor-configuracao";
import { ProvedorConfiguracaoTipo, ConfiguracaoCampoProvider, CampoFormato } from "../../../common/model/configuracao-campo-provedor";
import { CampoModalComponent } from "../campo-modal/campo-modal.component";
import { ProvedorTesteConexao } from "../../../common/model/provedor-teste-conexao";
import { ParametrosAgendamentoDescricao, AgendamentoDescricao } from "../../../common/libs/agendamento-descricao";
import { TranslateService } from "@ngx-translate/core";
import { ProvedorAgendamentoComponent } from "../../provedor-agendamento/provedor-agendamento.component";
import { ProvedorAgendamentoConfiguracao } from "../../../common/model/provedor-agendamento-configuracao";
import { GuidService } from "../../../common/service/guid.service";
import { ConfiguracaoRetornoWebhook } from "../../../common/model/configuracao-retorno-webhook";
import { NotificationService } from "../../../common/service/notification.service";
import { Subscription } from "rxjs";
import { CampoListaValorModalComponent } from "../../../template/campo-lista-valor-modal/campo-lista-valor-modal.component";
import { ConfigConversaoProvedorModalComponent } from "../config-conversao-provedor-modal/config-conversao-provedor-modal.component";
import { ConfiguracaoConversaoProvedor } from "../../../common/model/configuracao-conversao-provedor";


@Component({
    selector: "app-form-provider-retorno",
    templateUrl: "./form-provider-retorno.component.html",
    styleUrls: ["./form-provider-retorno.component.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: DefaultValuesService, provide: DefaultValuesService },
        { useClass: CamposIntegracaoService, provide: CamposIntegracaoService },
        { useClass: GuidService, provide: GuidService }
    ]
})
export class FormProviderRetornoComponent implements OnInit, OnDestroy {
    @Input() listaMetodoHttp;
    @Input() listaTipoAutenticacao;
    @Input() listaCampoTipoPrimario;
    @Input() grantTypeCollection;
    @Input() erros;

    public TipoAutenticacao: any = TipoAutenticacao;
    public IntegracaoTipo: any = IntegracaoTipo;
    public descricao: string;
    public listaIntegracaoTipo: Array<{ label: string; value: number, disabled: boolean }> = [];
    public configuracaoWebhook: ConfiguracaoRetornoWebhook;
    public guidWebhook: string = '';
    public tipoObjetoRetorno: string;
    public subEnvioIntegracaoTipo: Subscription;
    public listaTipoAutenticacaoWebhook: Array<{ label: string; value: number }> = [];

    _provider: Provider;

    public get provider() {
        return this._provider;
    }

    @Input()
    public set provider(provider: Provider) {
        if (!provider)
            return;

        this._provider = provider;

        if (this.provider.retorno.provedorConfiguracaoGuid == null) {
            this.provider.retorno.provedorConfiguracaoGuid = GuidService.newGuid();
        }

        this.tratarIntegracaoResponse();
        this.tratarIntegracaoWebhook();
        this.descricaoAgendamento();
    }

    public get isEdit(): boolean {
        return this.provider.providerId != undefined;
    }

    public get possuiRetornoHabilitado(): boolean {
        return this.provider.configuracaoListaProvedor != null && this.provider.configuracaoListaProvedor.some(c => c.habilitaRetorno);
    }

    public get providerPadrao() {
        return this.provider.providerPadrao;
    }

    constructor(
        public providerService: ProviderService,
        public dialog: MatDialog,
        private translate: TranslateService,
        private customAlertService: CustomAlertService,
        private defaultValuesService: DefaultValuesService,
        private notificationService: NotificationService) {

        this.defaultValuesService
            .obterIntegracaoTipoRetorno()
            .then(
                listaIntegracaoTipo =>
                    (this.listaIntegracaoTipo = listaIntegracaoTipo)
            );

        this.providerService
            .obterConfiguracaoRetornoWebhook()
            .subscribe(result => {
                this.configuracaoWebhook = result;
            });
    }

    ngOnInit() {
        this.subEnvioIntegracaoTipo = this.providerService.onTipoIntegracaoEnvioAtualizar.subscribe(data => {
            if (this.tratarIntegracaoResponse()) {
                if (this.provider.retorno.isIntegracaoTipoResponse()) {
                    this.provider.retorno.integracaoTipoId = null;
                    this.provider.retorno.limparCamposConfiguracao();
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.subEnvioIntegracaoTipo)
            this.subEnvioIntegracaoTipo.unsubscribe();
    }

    adicionarCampo() {
        var ref = this.dialog.open(CampoModalComponent, {
            hasBackdrop: true,
            width: "700px",
            data: {
                campoTipo: new ConfiguracaoCampoProvider(ProvedorConfiguracaoTipo.Retorno, CampoFormato.Form),
                listaCampoTipoPrimario: this.listaCampoTipoPrimario
            }
        });

        ref.afterClosed().subscribe(campoTipo => {
            if (campoTipo)
                this.provider.configuracaoCampoProvider.push(campoTipo);
        });
    }

    editarCampo(campo: ConfiguracaoCampoProvider) {
        var ref = this.dialog.open(CampoModalComponent, {
            hasBackdrop: true,
            width: "700px",
            data: {
                campoTipo: Object.assign(new ConfiguracaoCampoProvider(), campo),
                listaCampoTipoPrimario: this.listaCampoTipoPrimario
            }
        });

        ref.afterClosed().subscribe(alteracaoCampoTipo => {
            if (alteracaoCampoTipo)
                Object.assign(campo, alteracaoCampoTipo);
        });
    }

    excluirCampo(campo: ConfiguracaoCampoProvider) {
        campo.ativo = false;
    }

    modalConfigurarAgendamentoRetorno() {
        const modalRef = this.dialog.open(ProvedorAgendamentoComponent, {
            hasBackdrop: true,
            width: '50%',
            data: { 'provedorAgendamentoConfiguracao': this.provider.provedorAgendamentoConfiguracao }
        });

        modalRef.afterClosed().subscribe((result: ProvedorAgendamentoConfiguracao) => {
            if (!result) return;
            this.provider.provedorAgendamentoConfiguracao = result;
            this.descricaoAgendamento();
        });
    }

    descricaoAgendamento() {
        let param: ParametrosAgendamentoDescricao = new ParametrosAgendamentoDescricao();
        param.execucaoUnica = false;
        param.frequencia = this._provider.provedorAgendamentoConfiguracao.frequencia;
        param.diaSemana = this._provider.provedorAgendamentoConfiguracao.diaSemana;
        param.intervalo = this._provider.provedorAgendamentoConfiguracao.intervalo;
        param.dataInicio = this._provider.provedorAgendamentoConfiguracao.dataInicio;
        param.horaInicio = this._provider.provedorAgendamentoConfiguracao.horaExecucao;
        param.dataTermino = this._provider.provedorAgendamentoConfiguracao.dataTermino;
        param.horaTermino = '23:59';
        param.comDataFim = this._provider.provedorAgendamentoConfiguracao.comDataFim;

        this.descricao = AgendamentoDescricao(param, 'telaProvider', this.translate);
    }

    testarConexao() {
        const requisicao = Object.assign(new ProvedorTesteConexao(), this.provider.retorno);

        this.providerService.testarConexao(requisicao).subscribe(
            result =>
                this.customAlertService.show(
                    "telaProvider.testarConexao",
                    result ? "telaPadrao.sucesso" : "telaPadrao.erro",
                    result ? "success" : "error"
                ),
            () =>
                this.customAlertService.show(
                    "telaProvider.testarConexao",
                    "telaPadrao.erro",
                    "error"
                )
        );
    }

    integracaoTipoChanged() {
        this.provider.retorno.limparCamposConfiguracao();
        this.tratarIntegracaoWebhook();
    }

    tipoObjetoRetornoChanged() {
        this.tratarIntegracaoWebhook();
    }

    tipoAutenticacaoChanged() {
        this.tratarIntegracaoWebhook();
    }

    tratarIntegracaoWebhook() {
        if (this.provider.retorno.integracaoTipoId == IntegracaoTipo.Webhook) {

            if (this.provider.retorno.caminhoIntegracao == null ||
                (this.tipoObjetoRetorno == null && !this.provider.retorno.caminhoIntegracao.includes("lista")) ||
                this.tipoObjetoRetorno == "individual") {
                if (this.provider.retorno.tipoAutenticacao == 6) {
                    this.tipoObjetoRetorno = "individual";
                    this.provider.retorno.caminhoIntegracao = this.configuracaoWebhook.urlIndividualAnonimo + "?guid=" + this.provider.retorno.provedorConfiguracaoGuid;

                } else {
                    this.tipoObjetoRetorno = "individual";
                    this.provider.retorno.caminhoIntegracao = this.configuracaoWebhook.urlIndividual + "?guid=" + this.provider.retorno.provedorConfiguracaoGuid;
                }
            } else {
                if (this.provider.retorno.tipoAutenticacao == 6) {
                    this.tipoObjetoRetorno = "lista";
                    this.provider.retorno.caminhoIntegracao = this.configuracaoWebhook.urlListaAnonimo + "?guid=" + this.provider.retorno.provedorConfiguracaoGuid;

                } else {
                    this.tipoObjetoRetorno = "lista";
                    this.provider.retorno.caminhoIntegracao = this.configuracaoWebhook.urlLista + "?guid=" + this.provider.retorno.provedorConfiguracaoGuid;
                }
            }

            this.listaTipoAutenticacaoWebhook = this.listaTipoAutenticacao.filter(f => f.label == "Key" || f.label == "AllowAnonymous");
            this.provider.retorno.metodoIntegracao = "POST";
            this.provider.retorno.apiKey = 'ApiKey ' + this.configuracaoWebhook.apiKey;
        }
    }

    tratarIntegracaoResponse() {
        var opcaoResponse = this.listaIntegracaoTipo.find(i => i.value == IntegracaoTipo.Response);

        if (opcaoResponse == null)
            return null;

        if (this.provider.envio.isIntegracaoTipoAPI()
            || this.provider.envio.isIntegracaoTipoBancoDeDados()) {
            opcaoResponse.disabled = false;
        } else {
            opcaoResponse.disabled = true;
        }

        this.listaIntegracaoTipo = [...this.listaIntegracaoTipo];

        return opcaoResponse.disabled;
    }

    configurarValoresLista(campo: any) {
        let dialogRef = this.dialog.open(CampoListaValorModalComponent, {
            hasBackdrop: true,
            width: "750px",
            data: {
                campo: campo,
                desabilitaLookup: this.possuiRetornoHabilitado
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) return;

            campo.possuiLookup = result.possuiLookup;
            campo.templateItemListaValor = result.templateItemListaValor;
        });
    }

    configurarValoresConversao(campo: ConfiguracaoCampoProvider) {

        let dialogRef = this.dialog.open(ConfigConversaoProvedorModalComponent, {
            hasBackdrop: true,
            width: "900px",
            data: {
                campo: campo,
                camposTipo: this.listaCampoTipoPrimario,
                configuracoes: campo.configuracaoConversaoProvedor,
            }
        });

        dialogRef.afterClosed().subscribe((result: Array<ConfiguracaoConversaoProvedor>) => {
            if (!result) return;
            campo.configuracaoConversaoProvedor = result;
        });
    }

    //#region [ Clipboard ]

    notify(): void {
        this.notificationService.success("telaPadrao.sucesso", "telaPadrao.informacaoCopiada");
    }

    copyToClipboard(campo: string): string {
        if (campo == "caminho") {
            return this.provider.retorno.caminhoIntegracao;
        } else {
            return this.provider.retorno.apiKey;
        }
    }

    //#endregion   
}