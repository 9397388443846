import { Component, OnInit, Injectable, Input, Output, EventEmitter } from "@angular/core";
import { Provider } from "../../common/model/provider";
import { ProviderService } from "../../common/service/provider.service";
import { MatDialog } from "@angular/material";
import { CampoModalComponent } from "./campo-modal/campo-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { ProvedorConfiguracaoTipo, CampoFormato } from "../../common/model/configuracao-campo-provedor";
import { CamposIntegracaoService } from "../../common/service/campos-integracao.service";
import { ProvedorConfiguracao, TipoAutenticacao, IntegracaoTipo } from "../../common/model/provedor-configuracao";
import { ProvedorAgendamentoConfiguracao } from "../../common/model/provedor-agendamento-configuracao";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";
import { isNull, isNullOrEmpty } from "../../common/utils";
import { Observable, of } from "rxjs";


@Component({
	selector: "app-form-provider",
	templateUrl: "./form-provider.component.html",
	styleUrls: ["./form-provider.component.scss"],
	providers: [
		{ useClass: ProviderService, provide: ProviderService },
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService },
		{ useClass: CamposIntegracaoService, provide: CamposIntegracaoService }
	]
})
export class FormProviderComponent implements OnInit {
	public provider: Provider;
	public isDirty: boolean;
	public listaMetodoHttp: Array<{ label: string; value: string }> = [];
	public listaTipoAutenticacao: Array<{ label: string; value: number }> = [];
	public grantTypeCollection: { label: any; value: string }[];
	public listaCampoTipoPrimario: Array<any>;
	public TipoAutenticacao: any = TipoAutenticacao;
	public IntegracaoTipo: any = IntegracaoTipo;
	public provedorAlias: Array<any> = [];
	public aliasValido: boolean = true;
	public possuiTemplateParametrosUrl: boolean;
	public caminhoIntegracaoEnvio: string;

	public get isEdit(): boolean {
		return this.provider.providerId != undefined;
	}

	public get erros() {
		if (!this.isDirty)
			return false;

		return this.provider.validate();
	}

	desabilitarAlias = false;

	public get providerPadrao() {
		return this.provider.providerPadrao;
	}

	constructor(
		public providerService: ProviderService,
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router,
		private customAlertService: CustomAlertService,
		private defaultValuesService: DefaultValuesService,
		private camposIntegracaoService: CamposIntegracaoService,
		private translate: TranslateService
	) {

		let provider = new Provider();
		provider.provedorConfiguracao.push(new ProvedorConfiguracao(ProvedorConfiguracaoTipo.Envio, true, CampoFormato.Form));
		provider.provedorConfiguracao.push(new ProvedorConfiguracao(ProvedorConfiguracaoTipo.Retorno, true, CampoFormato.Form));
		provider.provedorAgendamentoConfiguracao = new ProvedorAgendamentoConfiguracao();
		this.provider = provider;

		this.obterAlias();
		this.route.params.subscribe(params => {
			if (params.id) {
				this.providerService
					.obterProviderPorId(params.id)
					.subscribe(provider => {
						this.provider = provider;
						this.caminhoIntegracaoEnvio = `${this.provider.envio.caminhoIntegracao}`;

						this.obterProvedorAgendamentoConfiguracaoPadrao();
						this.providerService.possuiTemplateParametrosUrl(provider.providerId).subscribe(result => (this.possuiTemplateParametrosUrl = result));
						this.desabilitarAlias = (this.isEdit && isNull(this.provider.alias) == false);
					});
			} else {
				this.obterProvedorAgendamentoConfiguracaoPadrao();
			}
		});

		this.camposIntegracaoService
			.obterTodosCamposIntegracao()
			.subscribe(result => {
				this.listaCampoTipoPrimario = result.filter(campo => campo.tipoPrimario && campo.ativo);
				this.listaCampoTipoPrimario = this.listaCampoTipoPrimario.map((c: any) => ({
					value: c.listaCampoTipoId,
					label: this.translate.instant("CampoTipo." + c.tipoDado)
				}));
			});
		this.defaultValuesService
			.obterMetodosHttp()
			.then(listaMetodoHttp => (this.listaMetodoHttp = listaMetodoHttp));

		this.defaultValuesService
			.obterTipoAutenticacaoProvedor()
			.then(
				listaTipoAutenticacao =>
					(this.listaTipoAutenticacao = listaTipoAutenticacao)
			);

		this.defaultValuesService
			.obterGrantType()
			.then(
				grantTypeCollection => (this.grantTypeCollection = grantTypeCollection)
			);
	}

	obterAlias() {
		this.providerService
			.obterAlias()
			.subscribe(result => {
				this.provedorAlias = result;
			});
	}

	obterProvedorAgendamentoConfiguracaoPadrao() {
		if (this.provider.provedorAgendamentoConfiguracao.provedorAgendamentoConfiguracaoId == null) {
			this.providerService.obterConfiguracaoAgendamentoRetornoPadrao().subscribe(retorno => {
				let p = Object.assign(new Provider(), this.provider);
				p.provedorAgendamentoConfiguracao = retorno;
				this.provider = p;
			});
		}
	}

	ngOnInit() { }

	salvar() {
		this.isDirty = true;

		if (!this.provedorAlias.some((p: any) => p.providerId != this.provider.providerId && p.alias == this.provider.alias)) {
			this.erros
		}

		if (this.erros || !this.aliasValido)
			return this.customAlertService.showWithConfiguration({
				title: "telaProvider.provedor",
				text: this.gerarMensagemErro(),
				icon: "error",
				showCancelButton: false,
				confirmButtonText: "Ok",
				cancelButtonText: "telaPadrao.nao"
			});

		let validarArquivo = (): Observable<boolean> => {
			if (this.provider.envio.isIntegracaoTipoArquivo() && !isNull(this.provider.envio.caminhoArquivo)) {
				return this.providerService.ValidarArquivoPath(this.provider.envio.caminhoArquivo);
			} else {
				return of(true);
			}
		}

		let gravar = () => {
			validarArquivo().subscribe(valido => {
				if (valido)
					gravarProvedor();
				else
					this.customAlertService.show("telaProvider.provedor", "telaProvider.validarDiretorio", "error");
			});
		}

		let gravarProvedor = () => {
			this.provider.formatarCamposEnvio();

			const requisicao = Object.assign({}, this.provider, { listaTemplate: [] });

			let observable = this.isEdit
				? this.providerService.editarProvider(requisicao)
				: this.providerService.criarProvider(requisicao);

			observable.subscribe(() => {
				this.customAlertService.show("telaProvider.provedor", "telaProvider.sucessoGravar", "success");
				this.router.navigate(["/app/provedor"]);
			}, (err) => {
				if (err.error.message == "Alias existente") {
					this.obterAlias();
					return this.customAlertService.show("telaProvider.provedor", "telaProvider.aliasExistente", "error")
				}
			});
		};

		if (this.isEdit && this.provider.envio.parametrosUrl && this.possuiTemplateParametrosUrl && this.caminhoIntegracaoEnvio != this.provider.envio.caminhoIntegracao) {
			return this.customAlertService
				.showWithConfiguration({
					title: "telaProvider.provedor",
					text: "telaProvider.erroTemplateParametrosUrl",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "telaPadrao.continuar",
					cancelButtonText: "telaPadrao.cancelar"
				})
				.then(data => {
					if (data.dismiss == swal.DismissReason.cancel)
						return;

					gravar();
				});
		}

		gravar();
	}

	validarAlias() {
		this.aliasValido = !this.provedorAlias.some((p: any) =>
			p.providerId != this.provider.providerId && p.alias == this.provider.alias);
		return this.aliasValido;
	}

	gerarMensagemErro(): string {
		var mensagem = this.erros && (this.erros["envio"] || this.erros["json"] || this.erros["campanha"] ||
			this.erros["retorno"] || this.erros["camposRetorno"]) ? 'telaProvider.camposInvalidosNaAba' : 'telaPadrao.camposInvalidos';
		var abas = '';
		if (this.erros) {
			abas += this.erros["envio"] || this.erros["json"] || this.erros["campanha"] ? '\n' + this.translate.instant('telaProvider.tabEnvio') : '';
			abas += this.erros["retorno"] || this.erros["camposRetorno"] ? '\n' + this.translate.instant('telaProvider.tabRetorno') : '';
		}

		var translateMensagem = this.translate.instant(mensagem, {
			'aba': abas
		});

		return translateMensagem;
	}
}