import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { Component, ViewChild } from "@angular/core";
import { Lista } from "../../common/model/lista";
import { ListaService } from "../../common/service/lista.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ElasticsearchEditComponent } from "../../elasticsearch/elasticsearch-edit/elasticsearch-edit.component";
import { TemplateService } from "../../common/service/template.service";
import { Template } from "../../common/model/template";
import { ESBuilderData } from "../../esbuilder/models";
import { isNullOrEmpty } from "../../common/utils";
import { EsBuilderFilterComponent } from "../../esbuilder/esbuilder-filter/esbuilder-filter.component";
import { CardSomarUnicosComponent } from "../../elasticsearch/card-somar-unicos/card-somar-unicos.component";
import { CardConfiguravelComponent } from "../../elasticsearch/card-configuravel/card-configuravel.component";
import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";

@Component({
    selector: "app-form-lista",
    templateUrl: "./form-lista.component.html",
    styleUrls: ['./form-lista.component.scss'],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
    ]
})
export class FormListaComponent {
    dirty: boolean;
    finishedLoading: boolean = false;
    forcarAtualizar: boolean = false;

    listaId: number;
    lista: Lista;
    informacoes: any;
    historicoIndexacao: Array<any>;
    template: Template;
    quantidadesCardsCustomizados: any = {};
    query: any = {};
    dataRules: ESBuilderData = new ESBuilderData();

    //#region [ GETs ]  

    public get queryBase() {
        return JSON.parse('{"size":10,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}')
    }

    //#endregion

    @ViewChild("esbuilderFilter", { static: false })
    esbuilderFilter: EsBuilderFilterComponent;
    constructor(
        private route: ActivatedRoute,
        private listaService: ListaService,
        private templateService: TemplateService,
        private customAlertService: CustomAlertService,
        private elasticsearchService: ElasticsearchRepositoryService,
        public dialog: MatDialog
    ) {
        this.query = this.queryBase;
        this.query.size = 5;
        this.route.params.subscribe(params => {
            if (params.id) {
                this.listaId = params.id;
                this.obterLista();
                this.obterListaInformacoesBasicas();
                this.obterHistoricoIndexacaoPorListaId();
                this.finishedLoading = true;
            } else {
                this.listaId = 0;
            }
        });
    }

    obterLista() {
        this.listaService.obterListaPorId(this.listaId).subscribe(s => {
            this.lista = s;
            this.templateService.obterTemplate(this.lista.listaTemplateId).subscribe(t => this.template = t);
            this.obterQuantidadeLista();
        });
    }

    obterListaInformacoesBasicas() {
        this.listaService.obterListaInformacoesBasicas(this.listaId).subscribe(s => {
            this.informacoes = s;
        });
    }

    obterHistoricoIndexacaoPorListaId() {
        this.listaService.obterHistoricoIndexacaoPorListaId(this.listaId).subscribe(s => {
            this.historicoIndexacao = s;
        });
    }



    updateQuery(event: any) {
        if (!isNullOrEmpty(event.query))
            this.query = event.query;

        if (!isNullOrEmpty(event.dataRules))
            this.dataRules = event.dataRules;
    }

    quantityListChanged(event: any) {
        this.obterQuantidadeLista();
    }

    //#region [ Incluir registros na lista ]

    incluir() {
        const incluirItem = this.dialog.open(ElasticsearchEditComponent, {
            hasBackdrop: true,
            height: "800px",
            width: "80%",
            data: {
                template: this.template,
                lista: this.lista,
                listaId: this.lista.listaId,
                item: {}
            }
        });

        incluirItem.afterClosed().subscribe(result => {
            if (result) {
                if (result.isError)
                    this.customAlertService.show("telaPadrao.inclusao", "telaPadrao.inclusaoErro", "error");
                else {
                    setTimeout(() => {
                        this.setQuantidadeLista();
                        this.forcarAtualizar = true;
                        this.customAlertService.show("telaPadrao.inclusao", "telaPadrao.inclusaoSucesso", "success");
                    }, 1000);
                }
            }
        });
    }

    setQuantidadeLista() {
        this.esbuilderFilter.updateQuantityList();
    }

    //#endregion

    //#region [ Cards Customizaveis ]     

    gravarCardCustomizado(event: any) {
        let dados = {
            'id': this.lista.listaId,
            'campoCard': this.lista.campoCard,
            'operacaoCampoCard': this.lista.operacaoCampoCard,
            'campoAgrupadorSomaUnicosCard': this.lista.campoAgrupadorSomaUnicosCard,
            'formatacaoCard': this.lista.formatacaoCard
        };

        this.listaService
            .atualizarCardCustomizado(dados)
            .subscribe(retorno => {
                this.lista.campoCard = retorno.campoCard;
                this.lista.operacaoCampoCard = retorno.operacaoCampoCard;
                this.lista.campoAgrupadorSomaUnicosCard = retorno.campoAgrupadorSomaUnicosCard;
                this.lista.formatacaoCard = retorno.formatacaoCard;
            });
    }

    gravarCardSomarUnicos(event: any) {

        let dados = {
            'id': this.lista.listaId,
            'somaUnicosCardCampo': this.lista.somaUnicosCardCampo,
            'somaUnicosCardAgrupador': this.lista.somaUnicosCardAgrupador,
            'somaUnicosCardFormatacao': this.lista.somaUnicosCardFormatacao
        };

        this.listaService
            .atualizarCardSomaUnicos(dados)
            .subscribe(retorno => {
                this.lista.somaUnicosCardCampo = retorno.somaUnicosCardCampo;
                this.lista.somaUnicosCardAgrupador = retorno.somaUnicosCardAgrupador;
                this.lista.somaUnicosCardFormatacao = retorno.somaUnicosCardFormatacao;
                this.lista.somaUnicosCardAlterado = retorno.somaUnicosCardAlterado;
                this.lista.somaUnicosCardProcessamento = retorno.somaUnicosCardProcessamento;
                this.lista.somaUnicosCardValor = retorno.somaUnicosCardValor;
            });
    }

    abrirConfiguracaoLista() {
        let ref = this.dialog.open(CardConfiguravelComponent, {
            width: "65%",
            data: {
                origem: 'lista',
                campoSelecionado: this.lista.campoCard,
                operacaoSelecionada: this.lista.operacaoCampoCard,
                templateId: this.lista.listaTemplateId,
                formatacaoSelecionada: this.lista.formatacaoCard,
                listaId: this.listaId
            }
        });

        ref.afterClosed().subscribe(result => {
            if (!result) return;
            this.lista.campoCard = result.campo;
            this.lista.operacaoCampoCard = result.operacao;
            this.lista.formatacaoCard = (result.formatacao) ? result.formatacao : 'N0';

            this.gravarCardCustomizado(this.lista);
            this.obterQuantidadeLista();
        });
    }

    abrirConfiguracaoSomarUnicosPorLista() {
        let ref = this.dialog.open(CardSomarUnicosComponent, {
            width: "65%",
            data: {
                origem: 'lista',
                templateId: this.lista.listaTemplateId,
                somaUnicosCardCampoSelecionado: this.lista.somaUnicosCardCampo,
                somaUnicosCardAgrupadorSelecionado: this.lista.somaUnicosCardAgrupador,
                somaUnicosCardFormatacaoSelecionada: this.lista.somaUnicosCardFormatacao,
                somaUnicosCardValor: this.lista.somaUnicosCardValor,
                somaUnicosCardProcessamento: this.lista.somaUnicosCardProcessamento,
                somaUnicosCardAlterado: this.lista.somaUnicosCardAlterado,
                listaId: this.listaId
            }
        });

        ref.afterClosed().subscribe(result => {
            if (!result) return;
            this.lista.somaUnicosCardAgrupador = result.somaUnicosCardAgrupador;
            this.lista.somaUnicosCardAlterado = result.somaUnicosCardAlterado;
            this.lista.somaUnicosCardCampo = result.somaUnicosCardCampo;
            this.lista.somaUnicosCardFormatacao = result.somaUnicosCardFormatacao;
            this.lista.somaUnicosCardProcessamento = result.somaUnicosCardProcessamento;
            this.lista.somaUnicosCardValor = result.somaUnicosCardValor;

            this.gravarCardSomarUnicos(this.lista);
            this.obterQuantidadeLista();
        });
    }

    obterQuantidadeLista() {
        if (this.listaId == undefined || this.finishedLoading == false) {
            this.quantidadesCardsCustomizados = {};
            return;
        }

        let campo = (this.lista.campoCard) ? this.lista.campoCard : null;
        let operacao = (this.lista.operacaoCampoCard) ? this.lista.operacaoCampoCard : null;
        let campoAgrupadorSomaUnicos = (this.lista.campoAgrupadorSomaUnicosCard) ? this.lista.campoAgrupadorSomaUnicosCard : null;
        let formatacao = (this.lista.formatacaoCard) ? this.lista.formatacaoCard : null;
        let somaUnicosCardFormatacao = (this.lista.somaUnicosCardFormatacao) ? this.lista.somaUnicosCardFormatacao : null;

        this.elasticsearchService
            .getQuantidadeLista(this.listaId, 0, (this.query || {}), campo, operacao, campoAgrupadorSomaUnicos, formatacao, somaUnicosCardFormatacao)
            .subscribe(quantidades => this.quantidadesCardsCustomizados = quantidades);
    }

    // #endregion 
}
