import { Component, Output, EventEmitter } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { Template } from "../../../common/model/template";
import { FiltroLista } from "../../../common/model/filtro-lista";
import { CarteiraService } from "../../../common/service/carteira.service";
import { TemplateService } from "../../../common/service/template.service";
import { DefaultValuesService } from "../../../common/service/default-values.service";
import { Carteira } from "../../../common/model/carteira";
import { PreferenciasUsuario } from "../../../common/model/preferencias-usuario";
import { PreferenciasUsuarioService } from "../../../common/service/preferencias-usuario.service";

@Component({
	selector: "app-filtro-lista",
	templateUrl: "./filtro-lista.component.html",
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
		{ provide: CarteiraService, useClass: CarteiraService },
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
		{ provide: TemplateService, useClass: TemplateService },
		{ provide: DefaultValuesService, useClass: DefaultValuesService }
	]
})
export class FiltroListaComponent {
	preferenciasUsuario: PreferenciasUsuario;
	public listaStatus = [];
	filtro: FiltroLista;
	carteiras: Array<Carteira> = [];
	templates: Array<Template> = [];

	@Output()
	filtrar: EventEmitter<FiltroLista>;

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	constructor(private templateService: TemplateService, private carteiraService: CarteiraService, private preferenciasService: PreferenciasUsuarioService, private defaultValuesService: DefaultValuesService) {
		this.filtro = new FiltroLista();
		this.filtrar = new EventEmitter<FiltroLista>();

		// Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

		this.carteiraService.obterCarteiras().subscribe(carteiras => {
				this.carteiras = carteiras;

				if (this.preferenciasUsuario.existePreferenciaCarteira) {
					this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
					this.filtro.lista = this.preferenciasUsuario.listaId as number == 0 ?
					null : this.preferenciasUsuario.listaId as number;
					
				} else {
					let padrao = this.carteiras.find(f => f.padrao) as Carteira;
					this.filtro.carteiraId = padrao.carteiraId;
				}

				this.obterTemplates();
				
				this.carteiras.sort((a,b) => {
					if ( a.nome.toLowerCase() < b.nome.toLowerCase())
						return -1;
					
					if ( a.nome.toLowerCase() > b.nome.toLowerCase())
						return 1;
					
					return 0;
				});

				this.filtrar.emit(this.filtro);
			});

				this.defaultValuesService
			.obterListaStatus()
			.then(listaStatus => (this.listaStatus = listaStatus));
	}

	obterTemplates() {
		this.templateService
			.obterTemplates({ 'carteiraId': this.filtro.carteiraId, 'categoria': 1 })
			.subscribe(templates => 
				{
					this.templates = templates.sort((a,b) => {
						if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
							return -1;
						}
						if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
							return 1;
						}
						return 0;
					})});
	
				}

	carteiraChange() {
		this.obterTemplates();
	}

	filtrarLista() {
		this.filtrar.emit(this.filtro);
	}

	limpar() {
		this.filtro = new FiltroLista();
		
        if (this.preferenciasUsuario.existePreferenciaCarteira) {
			this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
		} else {
			let padrao = this.carteiras.find(f => f.padrao) as Carteira;
			this.filtro.carteiraId = padrao.carteiraId;
		}
	}
}
