import { Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
//import "rxjs/add/operator/combineLatest";
import { Subscription, combineLatest as observableCombineLatest } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";
import { isNullOrEmpty, isNullOrZero } from "../../common/utils";

import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroQueryService } from "../../common/service/filtro-query.service";

import { FiltroLista } from "../../common/model/filtro-lista";
import { FiltroStatusRegistroLista, Lista } from "../../common/model/lista";
import { Estrategia, EstrategiaAcaoCampanhaTipo } from "../../common/model/estrategia";
import { FiltroQuery } from "../../common/model/filtro-query";

import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { ESBuilderData } from "../../esbuilder/models";
import { FormEstrategiaListaSelecionada } from "../../estrategia/models/form-estrategia-lista-selecionada.model";
import { EstrategiaRefactor } from "../../common/model/estrategiaRefactor";
import { EstrategiaService } from "../../common/service/estrategia.service";
import MD5 from 'crypto-js/md5';
import { ElasticSearchService } from "../../common/service/elasticsearch.service";
import { CarteiraService } from "../../common/service/carteira.service";
import { ElasticSearch } from "../../common/model/elastic-search";
import { Carteira } from "../../common/model/carteira";

@Component({
    selector: "app-form-elasticsearch",
    templateUrl: "./form-elasticsearch.component.html",
    styleUrls: ["./form-elasticsearch.component.scss"],
    providers: [
        { useClass: ElasticSearchService, provide: ElasticSearchService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class FormElasticSearchComponent implements OnDestroy {

    //#region [ Properts ] 	

    debug: boolean = false;
    dirty: boolean;

    elasticSearch: ElasticSearch;
    carteiras: Carteira[];

    query: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
    dataRules: ESBuilderData = new ESBuilderData();
    finishedLoading: boolean = false;

    subElasticSearch: Subscription;
    subCarteiras: Subscription;

    //#endregion

    //#region [ GETS/SETS ]

    public get erros() {
        if (!this.dirty) return;
        return this.elasticSearch.validar();
    }

    public get isEdit(): boolean {
        return (this.elasticSearch != undefined && this.elasticSearch.elasticSearchId != undefined);
    }

    //#endregion

    @ViewChild("elasticsearchFilter", { static: false })
    elasticsearchFilter: ElasticsearchFilterComponent;
    constructor(
        private route: ActivatedRoute,
        private elasticSearchService: ElasticSearchService,
        private carteiraService: CarteiraService,
        private router: Router,
        private customAlertService: CustomAlertService
    ) {
        this.elasticSearch = new ElasticSearch();
        this.route.params.subscribe(params => this.carregar(params));
        this.route.queryParams.subscribe(params => {
            if (params.debug != undefined)
                this.debug = (params.debug === 'true');
        });
    }

    //#region [ Eventos do componente ]

    ngOnDestroy() {
        if (this.subElasticSearch) this.subElasticSearch.unsubscribe();
        if (this.subCarteiras) this.subCarteiras.unsubscribe();
    }

    //#endregion

    //#region [ Dados ]	

    carregar(params: any) {
        if (!params.id) {
            this.obterCarteiras();
            return;
        }
        this.subElasticSearch = this.elasticSearchService.obterPorId(params.id).subscribe((elastic: ElasticSearch) => {
            this.elasticSearch = elastic;
            this.finishedLoading = true;
        });
    }

    obterCarteiras() {
        this.subCarteiras = this.carteiraService.obterCarteiras()
            .subscribe((carteiras: any) => {
                this.carteiras = carteiras.map((l: any) => Carteira.fromRaw(l)).sort((a,b) => {
                    if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                        return -1;
                    }
                    if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                        return 1;
                    }
                    return 0;
                    
                })
            });
    }

    //#endregion

    //#region [ Salvar ]

    salvar() {
        this.dirty = true;

        if (this.elasticSearch.validar()) {
            this.customAlertService.show("telaElasticSearch.elasticSearch", "telaPadrao.camposInvalidos", "error");
            return;
        }

        this.elasticSearchService.validarVersao(this.elasticSearch).subscribe((result: ElasticSearch) => {
            this.elasticSearch.versao = result.versao;
            this.elasticSearch.versaoValida = result.versaoValida;

            if (result.versaoValida != true) {
                this.customAlertService.show("telaElasticSearch.elasticSearch", "telaElasticSearch.versaoIncompativel", "error");
                return;
            }

            let sucesso = (response: any) => {
                this.customAlertService.show("telaElasticSearch.elasticSearch", "telaPadrao.sucessoSalvar", "success");
                this.router.navigate(["/app/elasticsearch/"]);
            };

            let erro = () => {
                this.customAlertService.show("telaElasticSearch.elasticSearch", "telaPadrao.erroSalvar", "error");
            };

            if (!this.elasticSearch.elasticSearchId)
                return this.elasticSearchService.criar(this.elasticSearch).subscribe(sucesso, erro);

            this.elasticSearchService.atualizar(this.elasticSearch).subscribe(sucesso, erro);
        });



    }

    //#endregion
}