import { Directive, ElementRef, Input } from "@angular/core";
import { PermissoesUsuario } from "../model/permissoes-usuario";

@Directive({
  selector: "[appPermissao]"
})
export class PermissaoDirective {
  _recurso;
  public get recurso(): string {
    return this._recurso;
  }

  @Input()
  public set recurso(recurso: string) {
    this._recurso = recurso;
    this.atualizar();
  }

  _acao;
  public get acao(): string {
    return this._acao;
  }

  @Input()
  public set acao(acao: string) {
    this._acao = acao;
    this.atualizar();
  }

  @Input()
  public set subMenus(recursos: string[]) {
    var escondeMenuPai = true;

    for (let i = 0; i < recursos.length; i++) {
      if (PermissoesUsuario.possuiPermissao(recursos[i], this.acao)) {
        escondeMenuPai = false;
        continue;
      }
    }
    this.el.nativeElement.hidden = escondeMenuPai;
  }

  atualizar() {
    if (this.acao == undefined || this.recurso == undefined) return;
    this.el.nativeElement.hidden =
      false == PermissoesUsuario.possuiPermissao(this.recurso, this.acao);
  }

  constructor(private el: ElementRef) {}
}
