import { StorageProxy } from "../service/storage-proxy.service";
import { isNullOrEmpty } from "../utils";
import { PreferenciasUsuario } from "./preferencias-usuario";

export class Permissao {
	permissaoRecurso: Array<PermissaoRecurso>;
	permissaoCarteira: Array<PermissaoCarteira>;
	permissaoLista: Array<PermissaoLista>;
	preferenciasUsuario: PreferenciasUsuario;
}

export class PermissaoRecurso {
	modulo: string;
	acao: string;
}

export class PermissaoCarteira {
	carteiraId: number;
	nome: string;
}

export class PermissaoLista {
	listaId: number;
}

export class PermissoesUsuario {

	//#region [ Recurso ]

	public static get recursos(): Array<PermissaoRecurso> {
		let retorno = StorageProxy.get<Array<PermissaoRecurso>>('recursos');
		if (isNullOrEmpty(retorno)) return [];
		return retorno;
	};

	public static set recursos(v: Array<PermissaoRecurso>) {
		StorageProxy.set('recursos', v);
	};

	public static possuiPermissao(modulo: string, acao: string): boolean {

		let p = PermissoesUsuario.recursos.find(
			l => l.acao == acao && l.modulo == modulo
		) != undefined;

		return p;
	}

	//#endregion

	//#region [ Carteira ]

	public static get carteiras(): Array<PermissaoCarteira> {
		let retorno = StorageProxy.get<Array<PermissaoCarteira>>('carteiras')
		
		if (isNullOrEmpty(retorno)) return [];
		return retorno;
	};

	public static set carteiras(v: Array<PermissaoCarteira>) {
		StorageProxy.set('carteiras', v);
	};

	//#endregion

	//#region [ Lista ]

	public static get listas(): Array<PermissaoLista> {
		let retorno = StorageProxy.get<Array<PermissaoLista>>('listas')

		if (isNullOrEmpty(retorno)) return [];
		return retorno;
	};

	public static set listas(v: Array<PermissaoLista>) {
		StorageProxy.set('listas', v);
	};

	//#endregion	

	//#region [ PreferenciasUsuario ]

	public static get preferenciasUsuario(): PreferenciasUsuario {
		let retorno = StorageProxy.get<PreferenciasUsuario>('preferenciasUsuario')

		if (isNullOrEmpty(retorno)) return new PreferenciasUsuario();
		return retorno;
	};

	public static set preferenciasUsuario(v: PreferenciasUsuario) {
		StorageProxy.set('preferenciasUsuario', v);
	};

	//#endregion	
}