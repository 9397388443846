import { isNullOrEmpty } from "../utils";
export class ListaTemplateExportacaoParametrosUrl {
    public listaTemplateExportacaoParametrosUrlId: number;
    public listaTemplateId: number;
    public parametro: string;
    public listaTemplateItemId: string;
    public ativo: boolean;

    public listaTemplateItemIdArray: string[];    

    constructor(
        listaTemplateExportacaoParametrosUrlId: number = null,
        listaTemplateId: number = null,
        parametro: string = null,
        listaTemplateItemId: string = null,
        ativo: boolean = true
    ) {
        this.listaTemplateExportacaoParametrosUrlId = listaTemplateExportacaoParametrosUrlId;
        this.listaTemplateId = listaTemplateId;
        this.parametro = parametro;
        this.listaTemplateItemId = listaTemplateItemId;
        this.ativo = ativo;

        this.listaTemplateItemIdArray = [];
    }

    static fromRaw(raw: any): ListaTemplateExportacaoParametrosUrl {
        let obj = Object.assign(new ListaTemplateExportacaoParametrosUrl(), raw);

        if(isNullOrEmpty(obj.listaTemplateItemId))
            obj.listaTemplateItemIdArray = [];
        else
            obj.listaTemplateItemIdArray = obj.listaTemplateItemId.split(',');

        return obj;
    }
}
