import { Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatTabGroup, ShowOnDirtyErrorStateMatcher } from "@angular/material";
import { Subject, Operator, Subscription, forkJoin, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { param } from "jquery";
import * as _ from 'lodash';
import * as cloneDeep from 'lodash/cloneDeep';
import * as XLSX from "xlsx";
import swal from "sweetalert2";

import { isNull, isNullOrZero, isNullOrEmpty } from "../../common/utils";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { CamposIntegracaoService } from "../../common/service/campos-integracao.service";
import { ProviderService } from "../../common/service/provider.service";
import { TemplateService } from "../../common/service/template.service";
import { CarteiraService } from "../../common/service/carteira.service";
import { deepCopy } from '../../common/utils/deepCopy';

import { changeOrder } from "../../common/model/change-collection-item-order";
import { CampoIntegracao } from "../../common/model/campo-integracao";
import { Provider } from "../../common/model/provider";
import { ListaTemplateItem, ItemTipo } from "../../common/model/lista-template-item";
import { Template, TipoTemplate, TipoCategoria } from "../../common/model/template";
import { ListaTipoCampoFormatacao, ListaTipoCampoFormatacaoLocal } from "../../common/model/lista-tipo-campo-formatacao";
import { ListaCampoValidacao } from "../../common/model/lista-campo-validacao";

import { CampoImportacaoModalComponent } from "./campo-importacao-modal/campo-importacao-modal.component";
import { QuebraLinhaModalComponent } from "./quebra-linha-modal/quebra-linha-modal.component";
import { CampoListaValorModalComponent } from "../campo-lista-valor-modal/campo-lista-valor-modal.component";
import { GuidService } from "../../common/service/guid.service";
import { Carteira } from "../../common/model/carteira";
import { NotificationService } from "../../common/service/notification.service";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";


@Component({
	selector: "app-form-template-importacao",
	templateUrl: "./form-template-importacao.component.html",
	styleUrls: ["../style.css", "./form-template-importacao.component.scss"],
	providers: [
		{ useClass: CamposIntegracaoService, provide: CamposIntegracaoService },
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class FormTemplateImportacaoComponent implements OnDestroy {
	static campoArrastado: any;

	show = false;

	abaVisivel: any;
	template: Template;
	templateSource: Template;
	camposIntegracao: Array<CampoIntegracao>;
	listaTemplateItemImportacao: Array<ListaTemplateItem>;
	linhaEdicao: { 'rowIndex': Number, 'campo': ListaTemplateItem } = { 'rowIndex': null, 'campo': null };
	isEdit: boolean = false;
	exibeIdenficadorExtra: boolean = false;
	submited: boolean;
	listaCamposValidacao: Array<ListaCampoValidacao>
	funcoesCollection: Array<any> = [];
	listaLinhasColunas: Array<any>;
	subDados: Subscription;


	carteiraNome: string = "";
	carteiras: Array<any> = [];


	camposIntegracaoPrimarios: Array<any> = [];
	// public get camposIntegracaoPrimarios(): Array<any> {
	// 	return this.camposIntegracaoPrimarios = this.camposIntegracao.filter((f: CampoIntegracao) => f.tipoPrimario).map((i: any) => ({ label: this.translate.instant("CampoTipo." + i.tipoDado), value: i.listaCampoTipoId }));
	// }

	public get listaTemplateItensEdicao() {
		let templateItems = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Linha))
		return templateItems;
	}

	public get listaTemplateItensMonitorarUltimoUpdate() {
		let l = [ItemTipo.Padrao, ItemTipo.Coluna, ItemTipo.Retorno, ItemTipo.Validacao, ItemTipo.Conversao];

		let templateItems = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && l.some((s: ItemTipo) => s == f.itemTipo))
		return templateItems;
	}

	// public get listaTemplateItensIdentificadores() {
	// 	let templateItems = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Coluna))
	// 		.map((i: any) => ({ label: i.nome, value: i.nome }));
	// 	return templateItems;
	// }

	listaTemplateItensIdentificadores: Array<any> = [];

	atualizarListaIdentificadores() {
		this.listaTemplateItensIdentificadores = this.template.listaTemplateItem
			.filter((f: ListaTemplateItem) => f.ativo && (f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Coluna))
			.map((i: any) => ({ label: i.nome, value: i.nome }));
	}

	public get error() {
		if (this.submited) {
			let campos = this.camposIntegracao.filter((f: any) => this.template.listaTemplateItem.some((s: any) => f.listaCampoTipoId == s.listaCampoTipoId));
			return this.template.validar(campos, this.exibeIdenficadorExtra);
		}
	}

	public get reindexacao() {
		if (this.validarReidexacao(this.templateSource, this.template))
			return true;
		else
			return false;
	}

	@ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
	constructor(
		public dialog: MatDialog,
		private camposIntegracaoService: CamposIntegracaoService,
		private templateService: TemplateService,
		private carteiraService: CarteiraService,
		private preferenciasService: PreferenciasUsuarioService,
		private router: Router,
		private translate: TranslateService,
		private route: ActivatedRoute,
		private customAlertService: CustomAlertService,
		private notificationService: NotificationService
	) {
		this.template = new Template();
		this.template.listaTemplateTipoId = TipoTemplate.delimitado;
		this.route.params.subscribe(params => this.carregar(params));
	}

	//#region [ Eventos da página ]

	ngOnDestroy(): void {
		if (this.subDados) this.subDados.unsubscribe();
	}

	//#endregion

	//#region [ Carregamento da página ]

	carregar(params: any) {
		let p1 = this.camposIntegracaoService.obterTodosCamposIntegracao();
		let p2 = this.templateService.obterListaCamposValidacao();
		let p3 = this.templateService.obterFuncoesTemplateImportacao(this.translate);
		let p4 = this.carteiraService.obterCarteiras();

		this.subDados = forkJoin([p1, p2, p3, p4]).subscribe((results: any) => {
			this.camposIntegracao = _.sortBy(results[0], ['nome']).map((i: any) => { i.value = i.listaCampoTipoId; i.label = i.nome; return i; });

			this.camposIntegracaoPrimarios = this.camposIntegracao.filter((f: CampoIntegracao) => f.tipoPrimario).map((i: any) => ({ label: this.translate.instant("CampoTipo." + i.tipoDado), value: i.listaCampoTipoId }));

			this.listaCamposValidacao = results[1];
			this.funcoesCollection = results[2];
			this.carteiras = results[3].map((m: any) => ({ 'value': m.carteiraId, 'label': m.nome }));

			this.carteiras.sort((a, b) => {
				if (a.label.toLowerCase() < b.label.toLowerCase()) {
					return -1;
				}
				if (a.label.toLowerCase() > b.label.toLowerCase()) {
					return 1;
				}
				return 0;

			});

			// Preferencias do usuário
			let preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();
/*
			if (preferenciasUsuario.existePreferenciaCarteira) {
				this.template.carteiraId = preferenciasUsuario.carteiraId as number;
			} else {
				let padrao = results[3].find(f => f.padrao) as Carteira;
				this.template.carteiraId = padrao.carteiraId;
			}*/

			let templateItemMap = (item: ListaTemplateItem) => {
				let lti = ListaTemplateItem.fromRaw(item);

				if (params.duplicar) {
					lti.listaTemplateItemId = 0;
					lti.listaTemplateId = 0;
				}

				lti.setLinhaColuna();

				if (!isNullOrZero(lti.listaCampoTipoId)) {
					lti.listaCampoTipo = this.obterCampoTipo(lti.listaCampoTipoId);
					lti.listaCampoTipoFormatacao = this.obterFormatacoes(lti.listaCampoTipoId);
					lti.listaCamposValidacao = this.obterValidacoes(lti.listaCampoTipoId);
				}
				return lti;
			};

			if (params.duplicar) {
				this.templateService.obterTemplate(params.duplicar).subscribe(template => {
					this.template = template;

					this.template.listaTemplateItem = this.template.listaTemplateItem.map(templateItemMap);
					this.listaTemplateItemImportacao = this.template.listaTemplateItem.map(templateItemMap);

					this.template.nome = `${template.nome} - Copy`;
					this.template.listaTemplateId = "";

					this.reordernarItens();
					this.atualizarColunas();
					this.atualizarListaIdentificadores();
				});
			} else {
				if (!params.id) return;
				this.isEdit = true;
				this.templateService.obterTemplate(params.id).subscribe(template => {
					this.template = template;

					let carteira = this.carteiras.find(f => f.value == this.template.carteiraId.toString());
					this.carteiraNome = (carteira) ? carteira.label : '---';

					this.template.listaTemplateItem = this.template.listaTemplateItem.map(templateItemMap);
					this.listaTemplateItemImportacao = this.template.listaTemplateItem.map(templateItemMap);

					this.reordernarItens();
					this.atualizarColunas();


					this.carregarIdentificadores();
					this.carregarAbaTemplateExportacao();

					this.templateSource = deepCopy(this.template);

					this.atualizarListaIdentificadores();
				});
			}
		});
	}

	carregarIdentificadores() {
		if (this.template.identificadorTelefone) {
			let identificadorTels = this.template.identificadorTelefone.split(',');

			if (identificadorTels.length > 1)
				this.exibeIdenficadorExtra = true;

			identificadorTels.forEach((item, index) => {
				if (index == 0)
					this.template.identificadorTelefone1 = item;
				else
					this.template.identificadorTelefone2 = item;
			});
		}
	}

	carregarAbaTemplateExportacao() {
		let abaTemplate: string = localStorage.getItem("abaTemplate");

		if (abaTemplate) {
			this.tabGroup.selectedIndex = parseInt(abaTemplate);
			localStorage.removeItem('abaTemplate');
		}
	}

	obterCampoTipo(listaCampoTipoId: any): CampoIntegracao {
		return this.camposIntegracao.find((f: CampoIntegracao) => f.listaCampoTipoId.toString() == listaCampoTipoId.toString());
	}

	obterValidacoes(listaCampoTipoId: any): Array<ListaCampoValidacao> {
		return this.listaCamposValidacao.filter((f: ListaCampoValidacao) => f.listaCampoTipoId.toString() == listaCampoTipoId.toString());
	}

	obterFormatacoes(listaCampoTipoId: any): Array<ListaTipoCampoFormatacao> {
		return this.obterCampoTipo(listaCampoTipoId).listaCampoTipoFormatacao;
	}

	toElasticSearchName(field: string) {
		if (isNullOrEmpty(field))
			return "";

		if (field.toUpperCase() == field)
			return field.toLowerCase().trimLeft().trimRight();

		return `${field.substring(0, 1).toLowerCase()}${field.substring(1)}`.trimLeft().trimRight();
	}

	//#endregion

	//#region [ CRUD campos importação ]

	filtrarCamposIntegracao(campoSelecionado: any = null) {
		let c = this.camposIntegracao.filter((f: any) => {
			let existe = this.template.listaTemplateItem.some((s: any) => f.listaCampoTipoId == s.listaCampoTipoId);
			if (f.permiteMultiplo || (!f.permiteMultiplo && !existe))
				return f;
		});

		if (campoSelecionado)
			c.push(this.camposIntegracao.find((f: any) => f.listaCampoTipoId == campoSelecionado.listaCampoTipoId));

		return c.filter((f: any) => f.tipoPrimario);
	}

	modalCampoImportacao(campoSelecionado: ListaTemplateItem = null) {
		let alterarIdentificador: boolean = false;

		if (!isNullOrEmpty(this.template.identificadorAlias) && !isNull(campoSelecionado))
			alterarIdentificador = (this.template.identificadorAlias == campoSelecionado.nome);

		let dialogRef = this.dialog.open(CampoImportacaoModalComponent, {
			hasBackdrop: true,
			width: "750px",
			data: {
				camposIntegracao: this.filtrarCamposIntegracao(campoSelecionado),
				listaTemplateItem: this.template.listaTemplateItem,
				campoSelecionado: (campoSelecionado) ? campoSelecionado : null,
				exibirQuantidade: (this.template.listaTemplateTipoId == TipoTemplate.posicional),
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result) return;

			if (alterarIdentificador)
				this.template.identificadorAlias = result.nome;

			this.atualizarCampoImportacao(result, campoSelecionado)
		});
	}

	atualizarCampoImportacao(result: ListaTemplateItem, campoEditado: any) {
		let campo = this.camposIntegracao.find(c => c.listaCampoTipoId == result.listaCampoTipoId);

		if (campoEditado) {

			if (!isNull(result.guidUltimoUpdate)) {

				if (this.template.listaTemplateItem.some((s: ListaTemplateItem) => s.itemTipo == ItemTipo.Sistema && s.guidUltimoUpdate == result.guidUltimoUpdate)) {
					this.template.listaTemplateItem.forEach((f: ListaTemplateItem) => {
						if (f.itemTipo == ItemTipo.Sistema && f.guidUltimoUpdate == result.guidUltimoUpdate) {
							f.nome = `_ultimoUpdate_${result.nome}`;
						}
					});
				}
			}

			this.template.listaTemplateItem = this.template.listaTemplateItem.map(
				(lti: any) => {
					if (lti.nome == campoEditado.nome && lti.ordem == campoEditado.ordem) {




						lti.nome = result.nome;
						lti.tamanho = result.tamanho;
						lti.listaCampoTipoId = campo.listaCampoTipoId;
						lti.listaCampoTipo = campo;
						lti.alias = campo.nome;
						lti.tipoCampoImportacao = result.tipoCampoImportacao;
						lti.conteudo = result.conteudo;

						if (!isNullOrZero(lti.listaCampoTipoId)) {
							lti.listaCampoTipo = this.obterCampoTipo(lti.listaCampoTipoId);
							lti.listaCampoTipoFormatacao = this.obterFormatacoes(lti.listaCampoTipoId);
							lti.listaCamposValidacao = this.obterValidacoes(lti.listaCampoTipoId);
						} else {
							lti.conteudo = '';
							lti.listaCampoTipoId = null;
						}
					}
					return lti;
				}
			);
		} else {
			let lti = new ListaTemplateItem(result.nome, campo, this.template.listaTemplateItem.length, result.tamanho);
			lti.tipoCampoImportacao = result.tipoCampoImportacao;
			lti.conteudo = result.conteudo;
			lti.itemTipo = ItemTipo.Padrao;

			if (!isNullOrZero(lti.listaCampoTipoId)) {
				lti.listaCampoTipo = campo;
				lti.listaCampoTipoFormatacao = this.obterFormatacoes(lti.listaCampoTipoId);
				lti.listaCamposValidacao = this.obterValidacoes(lti.listaCampoTipoId);
			} else {
				lti.listaCampoTipoId = null;
			}

			lti.listaLinhasColunas = this.combinacoesLinhasColunasUnicoItem(lti.ordem);
			this.template.listaTemplateItem.push(lti);
			this.template.listaTemplateItem = [...this.template.listaTemplateItem];
		}
		this.reordernarItens();
		this.atualizarListaIdentificadores();
	}

	editarCampoImportacao(campo: ListaTemplateItem, rowIndex: Number, mesmalinha: boolean = false) {
		if (this.desabilitarEdicaoLinha(rowIndex, mesmalinha)) return;
		let campoEdit = this.template.listaTemplateItem.find((f: any) => f.nome == campo.nome && f.tamanho == campo.tamanho && f.ordem == campo.ordem);
		this.modalCampoImportacao(campoEdit);
	}

	excluirCampoImportacao(campo: ListaTemplateItem, rowIndex: Number, mesmalinha: boolean = false) {
		if (this.desabilitarEdicaoLinha(rowIndex, mesmalinha))
			return;

		if (this.template.identificadorAlias == campo.nome)
			this.template.identificadorAlias = null;

		if (this.template.identificadorTelefone1 == campo.nome)
			this.template.identificadorTelefone1 = null;

		if (this.template.identificadorTelefone2 == campo.nome)
			this.template.identificadorTelefone2 = null;

		let li = new Array<ListaTemplateItem>();

		this.template.listaTemplateItem.forEach((f: ListaTemplateItem) => {
			if (f.nome == campo.nome && f.tamanho == campo.tamanho && f.ordem == campo.ordem)
				f.ativo = false;

			li.push(f);
		});

		this.template.listaTemplateItem = li;

		this.atualizarListaIdentificadores();
	}

	//#endregion

	//#region [ Edição em linha dos campos ]

	descricaoFormato(id: number){
		let t = this.linhaEdicao.campo.formatos.find((cc:ListaTipoCampoFormatacao) => cc.listaTipoCampoFormatacaoId == id);
		return t.label;
	}

	editarLinha(rowIndex: Number, campo: ListaTemplateItem) {
		this.linhaEdicao.rowIndex = rowIndex;
		this.linhaEdicao.campo = ListaTemplateItem.fromRaw(deepCopy(campo));
	}

	salvarEdicaoLinha(rowIndex: Number, campo: ListaTemplateItem) {
		this.template.listaTemplateItem = this.template.listaTemplateItem.map((f: ListaTemplateItem) => {
			if (f.nome == campo.nome && f.tamanho == campo.tamanho && f.ordem == campo.ordem)
				f = ListaTemplateItem.fromRaw(deepCopy(this.linhaEdicao.campo))

			return f;
		});
		this.linhaEdicao = { 'rowIndex': null, 'campo': null };
		this.atualizarListaIdentificadores();
	}

	cancelarEdicaoLinha(rowIndex: Number, campo: ListaTemplateItem) {
		this.linhaEdicao.campo.listaCampoValidacaoId = campo.listaCampoValidacaoId;
		this.linhaEdicao.campo.chaveValidacao = campo.chaveValidacao;

		this.listaCampoValidacaoChange(null);
		this.linhaEdicao = { 'rowIndex': null, 'campo': null };
		this.atualizarListaIdentificadores();
	}

	verificaIndiceEdicaoLinha(rowIndex: Number) {
		return (this.linhaEdicao.rowIndex == rowIndex);
	}

	desabilitarEdicaoLinha(rowIndex: Number, mesmalinha: boolean = false) {
		if (this.isEdit && (this.template.listaTemplateItem[Number(rowIndex)].nome == this.template.identificadorAlias))
			return true;

		if (this.isEdit &&
			(this.template.listaTemplateItem[Number(rowIndex)].nome == this.template.identificadorTelefone) ||
			(this.template.listaTemplateItem[Number(rowIndex)].nome == this.template.identificadorTelefone1) ||
			(this.template.listaTemplateItem[Number(rowIndex)].nome == this.template.identificadorTelefone2))
			return true;

		if (this.linhaEdicao.rowIndex === null)
			return false;

		if (mesmalinha == true && this.linhaEdicao.rowIndex == rowIndex)
			return true

		return (this.linhaEdicao.rowIndex != rowIndex);
	}

	obterDescricaoFormatacao(campo: ListaTemplateItem) {
		if (isNullOrEmpty(campo.conteudo))
			return "";

		let formatacao = campo.formatos.find((f: ListaTipoCampoFormatacao) => f.formato == campo.conteudo);

		if (formatacao == null)
			return "";

		return formatacao.label;
	}

	obterDescricaoValidacao(campo: ListaTemplateItem) {
		if (isNullOrEmpty(campo.listaCampoValidacaoId))
			return "";

		let validacao = campo.validacoes.find((f: ListaCampoValidacao) => f.listaCampoValidacaoId.toString() == campo.listaCampoValidacaoId.toString());

		if (validacao == null)
			return "";

		return validacao.descricao;
	}

	obterDescricaoLinhaColuna(campo: ListaTemplateItem) {
		if (isNullOrEmpty(campo.linhaColuna))
			return "";

		let linhaColuna = campo.listaLinhasColunas.find((f: any) => f.valor == campo.linhaColuna);

		if (linhaColuna == null)
			return "";

		return linhaColuna.label;
	}

	//#endregion

	//#region [ Eventos de change dos campos  ]

	templateTipoAlteradoChange() {
		this.template.delimitador = null;
	}

	listaCampoTipoChange($event: any) {
		this.linhaEdicao.campo.conteudo = '';
		

		if (!isNullOrZero(this.linhaEdicao.campo.listaCampoTipoId)) {
			this.linhaEdicao.campo.listaCampoTipo = this.obterCampoTipo(this.linhaEdicao.campo.listaCampoTipoId);
			this.linhaEdicao.campo.listaCampoTipoFormatacao = this.obterFormatacoes(this.linhaEdicao.campo.listaCampoTipoId);
			this.linhaEdicao.campo.listaCamposValidacao = this.obterValidacoes(this.linhaEdicao.campo.listaCampoTipoId);
			this.linhaEdicao.campo.alias = this.linhaEdicao.campo.listaCampoTipo.nome;
		} else {
			this.linhaEdicao.campo.listaCampoTipoId = null;
			this.linhaEdicao.campo.alias = null;
			this.linhaEdicao.campo.listaCampoTipo = null;
			this.linhaEdicao.campo.listaCampoTipoFormatacao = [];
			this.linhaEdicao.campo.listaCamposValidacao = [];
		}
	}

	listaCampoValidacaoChange($event: any) {
		if (!isNullOrEmpty(this.linhaEdicao.campo.listaCampoValidacaoId)) { // Caso alguma validação foi selecionada

			// Verifica se é uma validação de um campo que nunca foi selecionada a validação
			if (isNullOrEmpty(this.linhaEdicao.campo.chaveValidacao))
				this.linhaEdicao.campo.chaveValidacao = this.novaChaveValidacao();

			let existeCampoValidacao = this.template.listaTemplateItem.some((f: ListaTemplateItem) => f.itemTipo == ItemTipo.Validacao && f.chaveValidacao == this.linhaEdicao.campo.chaveValidacao);
			let campoIntegracaoBoolean = this.camposIntegracao.find((c: any) => c.tipoDado == "Boolean");
			let nome = `${this.toElasticSearchName(this.linhaEdicao.campo.nome)}_Valido`;

			if (!existeCampoValidacao) { // Caso nunca tenha sido criada uma validação para campo, cria uma validação
				let lti = new ListaTemplateItem(nome, campoIntegracaoBoolean, this.template.listaTemplateItem.length, 0);
				lti.alias = "Boolean";
				lti.tipoCampoImportacao = 0;
				lti.conteudo = null;
				lti.listaCampoTipoFormatacao = campoIntegracaoBoolean.listaCampoTipoFormatacao;
				lti.listaCampoTipoId = campoIntegracaoBoolean.listaCampoTipoId;
				lti.itemTipo = ItemTipo.Validacao;
				lti.chaveValidacao = this.linhaEdicao.campo.chaveValidacao;
				this.template.listaTemplateItem.push(lti);
				this.template.listaTemplateItem = [...this.template.listaTemplateItem];

			} else { // Caso já tenha sido criada uma validação para campo, edita a validação
				this.template.listaTemplateItem.forEach((lti: ListaTemplateItem) => {
					if (lti.itemTipo == ItemTipo.Validacao && lti.chaveValidacao == this.linhaEdicao.campo.chaveValidacao) {
						lti.alias = "Boolean";
						lti.tipoCampoImportacao = 0;
						lti.conteudo = null;
						lti.listaCampoTipoFormatacao = campoIntegracaoBoolean.listaCampoTipoFormatacao;
						lti.listaCampoTipoId = campoIntegracaoBoolean.listaCampoTipoId;
						lti.ativo = true;
					}
				});
			}

		} else { // Caso NENHUMA validação foi selecionada
			if (!isNullOrZero(this.linhaEdicao.campo.listaCampoTipoId))
				this.linhaEdicao.campo.listaCamposValidacao = this.obterValidacoes(this.linhaEdicao.campo.listaCampoTipoId);

			this.template.listaTemplateItem.forEach((lti: ListaTemplateItem) => {
				if (lti.itemTipo == ItemTipo.Validacao && lti.chaveValidacao == this.linhaEdicao.campo.chaveValidacao)
					lti.ativo = false;
			});

			this.linhaEdicao.campo.listaCampoValidacaoId = null;
			this.linhaEdicao.campo.chaveValidacao = null;
		}

		this.reordernarItens();

		this.atualizarListaIdentificadores();
	}

	nomeCampoChange(campo: ListaTemplateItem, $event: any) {
		this.template.listaTemplateItem.forEach((lti: ListaTemplateItem) => {
			if (lti.itemTipo == ItemTipo.Validacao && lti.chaveValidacao == campo.chaveValidacao)
				lti.nome = `${this.toElasticSearchName(campo.nome)}_Valido`;
		});
	}

	novaChaveValidacao(): string {
		let guid = GuidService.newGuid();

		if (this.template.listaTemplateItem.length <= 0) return guid;

		while (this.template.listaTemplateItem.some((s: ListaTemplateItem) => (s.chaveValidacao == guid)))
			guid = GuidService.newGuid();

		return guid;
	}

	configurarValoresLista(campo: ListaTemplateItem) {
		let dialogRef = this.dialog.open(CampoListaValorModalComponent, {
			hasBackdrop: true,
			width: "750px",
			data: {
				campo: campo,
				desabilitaLookup: false
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result) return;
			campo.possuiLookup = result.possuiLookup;
			campo.templateItemListaValor = result.templateItemListaValor;
		});
	}

	//#endregion

	//#region [ Ordenação dos campos importação ]

	countItensNaoArrastaveis() {
		return this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.itemTipo == ItemTipo.Score || f.itemTipo == ItemTipo.Retorno).length;
	}

	obterPosicaoCampo(campo: any) {
		return this.listaTemplateItensEdicao.indexOf(campo);
	}

	sortImportacao() {
		this.template.listaTemplateItem = this.template.listaTemplateItem
			.filter((listaTemplate: any) => listaTemplate.ativo)
			.sort((a: any, b: any) => a.ordem - b.ordem)
			.map((templateItem: any, index: number) => {
				templateItem.ordem = index;
				return templateItem;
			});
	}

	mudarCampoOrdem(campo: any, mudanca: number, rowIndex: Number, mesmalinha: boolean = false) {
		if (this.desabilitarEdicaoLinha(rowIndex, mesmalinha))
			return;

		let posicao = campo.ordem + mudanca;
		this.template.listaTemplateItem.forEach((campoLista: any) => {
			if (mudanca > 0 && campoLista.ordem >= posicao) campoLista.ordem--;
			if (mudanca < 0 && campoLista.ordem <= posicao) campoLista.ordem++;
		});
		campo.ordem = posicao;
		this.sortImportacao();
	}

	handleDragStart(campo: any) {
		FormTemplateImportacaoComponent.campoArrastado = campo;
	}

	handleDragOver(event: Event) {
		event.preventDefault();
		(event.target as Element).parentElement.classList.add("droppable");
	}

	handleDragLeave(event: Event) {
		event.preventDefault();
		(event.target as Element).parentElement.classList.remove("droppable");
	}

	handleDrop(event: Event, campo: any) {
		(event.target as Element).parentElement.classList.remove("droppable");
		if (campo.itemTipo == ItemTipo.Score || campo.itemTipo == ItemTipo.Retorno) return;
		let newOrderCollection = changeOrder(this.template.listaTemplateItem, FormTemplateImportacaoComponent.campoArrastado, campo);
		this.template.listaTemplateItem.forEach((ordem: any) => ordem.ordem = newOrderCollection.indexOf(ordem));
		this.sortImportacao();
	}

	reordernarItens() {
		let newOrdem = 0;
		let sortFnc = (a: ListaTemplateItem, b: ListaTemplateItem) => a.ordem - b.ordem;
		let mapFnc = (f: ListaTemplateItem) => { f.ordem = newOrdem; newOrdem++; return f; };
		let normais = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && f.itemTipo == ItemTipo.Padrao || f.itemTipo == ItemTipo.Linha).sort(sortFnc).map(mapFnc);
		let colunas = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && f.itemTipo == ItemTipo.Coluna).sort(sortFnc).map(mapFnc);
		let scores = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && f.itemTipo == ItemTipo.Score).sort(sortFnc).map(mapFnc);
		let retornos = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && f.itemTipo == ItemTipo.Retorno).sort(sortFnc).map(mapFnc);
		let validacoes = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && f.itemTipo == ItemTipo.Validacao).sort(sortFnc).map(mapFnc);
		let sistemas = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && f.itemTipo == ItemTipo.Sistema).sort(sortFnc).map(mapFnc);
		let tracking = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && f.itemTipo == ItemTipo.Tracking).sort(sortFnc).map(mapFnc);
		let conversao = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo && f.itemTipo == ItemTipo.Conversao).sort(sortFnc).map(mapFnc);
		let inativos = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.ativo == false).sort(sortFnc).map(mapFnc);

		this.template.listaTemplateItem = [...normais, ...colunas, ...scores, ...retornos, ...validacoes, ...tracking, ...sistemas, ...conversao, ...inativos];
	}

	//#endregion

	//#region [ Envio modelo ]

	validarExtensao(event: any) {
		let validExts = new Array(".txt", ".xlsx", ".xls", ".csv");
		let fileExt = event.target.files[0].name;
		fileExt = fileExt.substring(fileExt.lastIndexOf("."));
		if (validExts.indexOf(fileExt) < 0) {
			this.customAlertService.show("telaTemplate.arquivoModelo", "telaTemplate.arquivoPermitido", "error");
			return null;
		} else {
			return fileExt;
		}
	}

	getColunasExcel(result: any) {
		let colunas = [];
		const wb: XLSX.WorkBook = XLSX.read(result, { type: "binary" });
		wb.SheetNames.forEach(element => {
			let sheet = wb.Sheets[element];
			var range = XLSX.utils.decode_range(sheet["!ref"]);
			var C,
				R = range.s.r; /* start in the first row */
			/* walk every column in the range */
			for (C = range.s.c; C <= range.e.c; ++C) {
				var cell =
					sheet[
					XLSX.utils.encode_cell({ c: C, r: R })
					]; /* find the cell in the first row */
				var hdr = "UNKNOWN " + C; // <-- replace with your desired default
				if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
				colunas.push(hdr);
			}
		});
		return colunas;
	}


	incluirTemplateModelo(event: any) {

		let input = event.target;
		let extensaoPermitida = this.validarExtensao(event);

		if (this.template.listaTemplateCategoriaId == TipoCategoria.importacao && extensaoPermitida && input.files.length > 0) {
			let reader = new FileReader();

			if (extensaoPermitida == ".xls" || extensaoPermitida == ".xlsx")
				reader.readAsBinaryString(input.files[0]);
			else
				reader.readAsText(input.files[0]);

			reader.onload = e => {
				let result: string = (e.target as any).result;
				let possuiDelimitador = result.indexOf(this.template.delimitador);

				if (possuiDelimitador == -1)
					this.customAlertService.show("telaTemplate.arquivoModelo", "telaTemplate.arquivoSemDelimitador", "error");
				else {
					let colunas = (extensaoPermitida == ".xls" || extensaoPermitida == ".xlsx")
						? this.getColunasExcel(result)
						: result.split("\n")[0].split(this.template.delimitador);

					const findDuplicates = (arr: any) => {
						let sorted_arr = arr.slice().sort();
						let results: Array<string> = [];
						for (let i = 0; i < sorted_arr.length - 1; i++) {
							if ((sorted_arr[i + 1] as string).trim().toLowerCase() == (sorted_arr[i] as string).trim().toLowerCase())
								results.push(sorted_arr[i]);
						}
						return results;
					}


					if (colunas.some((s: any) => s.toLowerCase() == "id" || s.toLowerCase() == "_id" || s.toLowerCase() == "id_" || s.toLowerCase() == "_id_")) {
						this.customAlertService.show("telaTemplate.arquivoModelo", "telaTemplate.colunaNaoPermitida", "error");
					} else {

						colunas = colunas.map((m: any) => m.toString().replace(/[^a-zA-Z0-9|_]/g, '').replace(/\s/g, '')).filter((f: any) => (f != undefined && f != null && f != ''));

						let duplicados = findDuplicates(colunas);

						if (duplicados.length == 1) {
							let msg = this.translate.instant("colunaDuplicada").replace("colunaduplicada", duplicados[0]);
							this.customAlertService.show("telaTemplate.arquivoModelo", msg, "warning");
						}
						else if (duplicados.length > 1) {
							let msg = this.translate.instant("colunasDuplicadas").replace("colunaduplicada", duplicados.join(', ').replace(/,\s([^,]+)$/, ' e $1'));
							this.customAlertService.show("telaTemplate.arquivoModelo", msg, "warning");
						}

						colunas = colunas.map((item: string) => item.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "_").replace(/\s/g, ''));

						this.adicionarListaTemplateItem(colunas);
					}
				}
			};

		}
	}

	adicionarListaTemplateItem(colunas: any, tipoCampoImportacao: number = 0) {

		this.template.listaTemplateItem = [];
		let campoTexto = this.camposIntegracao.find(f => f.nome == "Texto");

		for (let i = 0; i < colunas.length; i++) {
			if (this.template.listaTemplateItem.filter(f => f.nome.trim().toLowerCase() == colunas[i].trim().toLowerCase()).length == 0 && colunas[i] != '') {
				let lti = new ListaTemplateItem(colunas[i], campoTexto, this.template.listaTemplateItemAtiva.length, 0);
				lti.itemTipo = ItemTipo.Padrao;
				lti.tipoCampoImportacao = tipoCampoImportacao;
				lti.listaCampoTipo = campoTexto;
				lti.listaCampoTipoFormatacao = this.obterFormatacoes(lti.listaCampoTipoId);
				lti.listaCamposValidacao = this.obterValidacoes(this.listaCamposValidacao);
				this.template.listaTemplateItem.push(lti);
			}
		}

		this.atualizarListaIdentificadores();
	}

	//#endregion

	//#region [ Baixar modelo ]

	baixarModelo() {
		let itens = this.template.listaTemplateItem.filter(f => f.ativo == true && f.itemTipo < 5).map(m => m.nome);
		const blob = new Blob([itens.join(this.template.delimitador)], { type: "text/plain" });
		const url = window.URL.createObjectURL(blob);
		let a = document.createElement('a');
		a.href = url;
		a.download = 'template.txt';
		a.click();
	}

	notify(): void {
		this.notificationService.success("esBuilderComponent.sucesso", "telaTemplate.queryCopiadaSucesso");
	}

	copyToClipboard() {

		if (this.template.listaTemplateItem.length > 0 && this.template.delimitador.length > 0) {
			let itens = this.template.listaTemplateItem.filter(f => f.itemTipo < 5).map(m => ({ campo: m.nome.toLocaleLowerCase(), tipo: m.alias }));
			let arr = {};

			itens.forEach(f => {
				switch (f.tipo) {
					case "Inteiro":
					case "Long":
						arr[f.campo] = Math.floor(Math.random() * 100) + 1;
						break;
					case "Texto":
					case "Lista":
						arr[f.campo] = "Lorem ipsum";
						break;
					case "Decimal":
						let value = (Math.random() * (100 - 1 + 1)) + 1;
						arr[f.campo] = Number.parseFloat(value.toString()).toFixed(2)
						break;
					case "Data":
						arr[f.campo] = new Date(+(new Date()) - Math.floor(Math.random() * 10000000000));
						break;
					case "Sim/Não":
						arr[f.campo] = true;
				};
			});

			const create_copy = async (e: ClipboardEvent) => {
				if (e.clipboardData != null)
					e.clipboardData.setData('text/plain', (JSON.stringify(arr)));
				e.preventDefault();
				this.notify();
			};

			document.addEventListener('copy', create_copy);
			document.execCommand('copy');
			document.removeEventListener('copy', create_copy);
		}
	}

	//#endregion

	//#region [ Identificadores ]

	adicionarIdenficadorExtra() {
		this.template.identificadorTelefone1 = null;
		this.template.identificadorTelefone2 = null;
	}

	verificarIdentificadorExtra() {

		if (this.template.quebraLinhas) {
			this.template.identificadorTelefone1 = this.exibeIdenficadorExtra == true ? null : this.template.identificadorTelefone1;
			this.template.identificadorTelefone2 = null;
			this.exibeIdenficadorExtra = false;
		} else {
			this.template.listaTemplateItem.forEach((lti: ListaTemplateItem) => {
				if (lti.itemTipo == ItemTipo.Coluna) {
					lti.ativo = false;
					if (!isNullOrEmpty(lti.chaveValidacao)) {
						// Retirar a validação dos filhos se tiver e retira o campo validação se tiver
						this.template.listaTemplateItem.forEach((item: ListaTemplateItem) => {

							if (item.itemTipo == ItemTipo.Linha && item.chaveValidacao == lti.chaveValidacao) {
								item.chaveValidacao = null;
								item.listaCampoValidacaoId = null;
							}

							if (item.itemTipo == ItemTipo.Validacao && item.chaveValidacao == lti.chaveValidacao)
								item.ativo = false;
						});
					}
				}
			});
		}
	}

	//#endregion

	toggleGravarUltimoUpdate(campo: ListaTemplateItem) {

		if (campo.gravarUltimoUpdate) {
			if (isNull(campo.guidUltimoUpdate))
				campo.guidUltimoUpdate = GuidService.newGuid();

			if (this.template.listaTemplateItem.some((s: ListaTemplateItem) => s.itemTipo == ItemTipo.Sistema && s.guidUltimoUpdate == campo.guidUltimoUpdate)) {
				this.template.listaTemplateItem.forEach((f: ListaTemplateItem) => {
					if (f.itemTipo == ItemTipo.Sistema && f.guidUltimoUpdate == campo.guidUltimoUpdate) {
						f.nome = `_ultimoUpdate_${campo.nome}`;
						f.ativo = true;
					}
				});

			} else {
				let campoTipo = this.camposIntegracao.find(c => c.listaCampoTipoId == 10);

				let lti = new ListaTemplateItem(`_ultimoUpdate_${campo.nome}`, campoTipo, this.template.listaTemplateItem.length, 0);
				lti.tipoCampoImportacao = 0;
				lti.itemTipo = ItemTipo.Sistema;
				lti.guidUltimoUpdate = campo.guidUltimoUpdate;
				lti.listaCampoTipo = campoTipo;
				lti.listaCampoTipoFormatacao = this.obterFormatacoes(lti.listaCampoTipoId);
				lti.listaCamposValidacao = this.obterValidacoes(lti.listaCampoTipoId);

				lti.listaLinhasColunas = this.combinacoesLinhasColunasUnicoItem(lti.ordem);
				this.template.listaTemplateItem.push(lti);
				this.template.listaTemplateItem = [...this.template.listaTemplateItem];

			}
		} else {
			this.template.listaTemplateItem.forEach((f: ListaTemplateItem) => {
				if (f.itemTipo == ItemTipo.Sistema && f.guidUltimoUpdate == campo.guidUltimoUpdate)
					f.ativo = false;
			});
		}
	}
	editarTemplateChange(template: any) {

		this.customAlertService
			.showWithConfiguration({
				title: "telaTemplate.confirmacao",
				text: "telaTemplate.editarCampoTemplate",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "telaPadrao.sim",
				cancelButtonText: "telaPadrao.nao"
			}
			).then(result => this.template.editarTemplate = result.value)
	}

	//#region [ Salvar ]

	salvar() {
		this.submited = true;

		this.reordernarItens();

		this.template.identificadorTelefone = null;

		if (this.template.identificadorTelefone1 || this.template.identificadorTelefone2)
			this.template.identificadorTelefone = this.template.identificadorTelefone2 ? this.template.identificadorTelefone1 + ',' + this.template.identificadorTelefone2 : this.template.identificadorTelefone1;

		let templateAlteracao: Template = cloneDeep(this.template);

		if (this.template.listaTemplateId)
			this.reordernarItens();

		if (this.template.quebraLinhas) {
			let semLinhaColuna = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => !isNullOrEmpty(f.linhaColuna)).length;
			if (semLinhaColuna < this.combinacoesLinhasColunas().length) {
				this.customAlertService.show("telaTemplate.template", "telaTemplate.erroQuantidadeLinhas", "error");
				return;
			}
		}

		let campos = this.camposIntegracao.filter((f: any) => (templateAlteracao.listaTemplateItem.some((s: any) => f.listaCampoTipoId == s.listaCampoTipoId)));

		if (templateAlteracao.validar(campos, this.exibeIdenficadorExtra)) {
			this.customAlertService.show("telaTemplate.template", "telaPadrao.camposInvalidos", "error");
			return;
		}

		templateAlteracao.providerId = null;

		if (!templateAlteracao.listaTemplateId) {
			return this.templateService.criar(templateAlteracao).subscribe((result: any) => {
				this.customAlertService
					.showWithConfiguration({
						title: "telaTemplate.template",
						text: "telaTemplate.templateSalvo",
						icon: "success",
						showCancelButton: true,
						confirmButtonText: "telaPadrao.sim",
						cancelButtonText: "telaPadrao.nao"
					})
					.then(data => {
						if (data.dismiss == swal.DismissReason.cancel) {
							localStorage.setItem("abaTemplate", '1');
							return this.router.navigate(["/app/template/", result.listaTemplateId]);
						}
						return this.router.navigate(["/app/lista/create"]);
					});
			},
				(error: any) => { this.customAlertService.show("telaEstrategia.estrategia", error.error[0], "error") });
		}

		this.templateService.atualizar(templateAlteracao).subscribe((result: any) => {
			this.customAlertService.show("telaTemplate.template", "telaPadrao.sucessoSalvar", "success");
			this.router.navigate(["/app/template"]);
		},
			(error: any) => { this.customAlertService.show("telaEstrategia.estrategia", error.error[0], "error") });
	}

	//#endregion

	//#region [ Quebra de linhas ]

	modalConfigurarQuebraLinha() {

		if (!this.template.quebraLinhas) return;

		let dialogRef = this.dialog.open(QuebraLinhaModalComponent, {
			hasBackdrop: true,
			width: "950px",
			data: {
				'listaTemplateTipoId': this.template.listaTemplateTipoId,
				'camposIntegracao': this.filtrarCamposIntegracao(null),
				'listaCamposValidacao': this.listaCamposValidacao,
				'quebraQuantidadeLinhas': this.template.quebraQuantidadeLinhas,
				'colunas': this.template.listaTemplateItem
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result) return;

			this.template.quebraQuantidadeLinhas = result.quebraQuantidadeLinhas;
			this.template.listaTemplateItem = result.colunas;
			this.reordernarItens();
			this.atualizarColunas();
			this.atualizarListaIdentificadores();
		});
	}

	// colunasChange() {
	// 	let listaLinhasColunas = [];
	// 	if (this.template.quebraQuantidadeLinhas > 0)
	// 		listaLinhasColunas = this.combinacoesLinhasColunas();

	// 	this.template.listaTemplateItem.forEach((f: ListaTemplateItem) => f.listaLinhasColunas = listaLinhasColunas);
	// }

	atualizarColunas() {
		this.template.listaTemplateItem.forEach((lti: ListaTemplateItem) => {

			if (lti.linhaColuna) {
				let coluna = this.template.listaTemplateItem.find((f: ListaTemplateItem) => f.guidQuebraColuna == lti.guidQuebraColuna);

				if (!isNull(coluna))
					lti.listaCampoTipoId = coluna.listaCampoTipoId;
			}

			lti.listaLinhasColunas = this.combinacoesLinhasColunasUnicoItem(lti.ordem);
		});
	}


	desabilitarListaCampoTipo() {

		// if (this.linhaEdicao.rowIndex == null)
		// 	return false;


		if ((this.template.quebraLinhas && this.linhaEdicao.campo.linhaColuna) || this.linhaEdicao.campo.itemTipo == ItemTipo.Score || this.linhaEdicao.campo.itemTipo == ItemTipo.Retorno)
			return true;

		return false;
	}

	desabilitarListaCampoValidacao() {
		if ((this.template.quebraLinhas && this.linhaEdicao.campo.linhaColuna) || (!this.linhaEdicao.campo.permiteValidacao) || this.linhaEdicao.campo.itemTipo == ItemTipo.Score || this.linhaEdicao.campo.itemTipo == ItemTipo.Retorno) {
			return true;
		}

		return false;
	}

	countQuebraColuna() {
		return this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.itemTipo == ItemTipo.Coluna).length;
	}

	combinacoesLinhasColunas() {

		let listaLinhasColunas = [];

		if (this.template.quebraQuantidadeLinhas > 0) {
			let listaLinhas = Array.from(Array(this.template.quebraQuantidadeLinhas), (_, x) => x + 1);

			listaLinhas.forEach((l: any) => {
				this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.itemTipo == ItemTipo.Coluna).forEach((c: ListaTemplateItem) => {
					listaLinhasColunas.push({ 'valor': `L${l}:C${c.guidQuebraColuna}`, 'label': `Linha ${l} - ${c.nome}` });
				});
			});
		}

		return listaLinhasColunas;
	}

	itensQuebraPreenchidos() {
		let itens = this.template.listaTemplateItem.filter((f: ListaTemplateItem) => f.linhaColuna).map((m: ListaTemplateItem) => ({ 'ordem': m.ordem, 'linhaColuna': m.linhaColuna }));

		if (!isNull(this.linhaEdicao.campo) && !isNullOrEmpty(this.linhaEdicao.campo.linhaColuna))
			itens.push({ 'ordem': this.linhaEdicao.campo.ordem, 'linhaColuna': this.linhaEdicao.campo.linhaColuna });

		return itens;
	}

	combinacoesLinhasColunasUnicoItem(ordem: number) {
		let listaOriginal = this.combinacoesLinhasColunas();
		let preenchidos = this.itensQuebraPreenchidos();
		let combinacoesItem = listaOriginal.filter((org: any) => {
			let p = !preenchidos.some((pre: any) => pre.linhaColuna == org.valor && pre.ordem != ordem);
			return p;
		});

		return combinacoesItem;
	}

	listaLinhasColunasChange($event: any) {
		this.template.listaTemplateItem.forEach((lti: ListaTemplateItem) => {
       
			if (lti.itemTipo == ItemTipo.Padrao || lti.itemTipo == ItemTipo.Linha) {

				lti.listaLinhasColunas = this.combinacoesLinhasColunasUnicoItem(lti.ordem);

				if (lti.ordem == this.linhaEdicao.campo.ordem) {
					//this.linhaEdicao.campo.listaLinhasColunas = this.combinacoesLinhasColunasUnicoItem(this.linhaEdicao.campo.ordem);

					if ($event) {
						let sp = $event.valor.split(':');
						let guidQuebraColuna = sp[1].substr(1, sp[1].length - 1);
						let valorReferencia = this.template.listaTemplateItem.find((f: ListaTemplateItem) => f.itemTipo == ItemTipo.Coluna && f.guidQuebraColuna == guidQuebraColuna);
						let campoTipo = this.obterCampoTipo(valorReferencia.listaCampoTipoId);

						// Formatação dos itens
						valorReferencia.listaCampoTipoFormatacao = campoTipo.listaCampoTipoFormatacao;

						let valorReferenciaFuncoesCollection = this.funcoesCollection.filter((f: any) => (f.restricao as Array<any>).some(s => s == campoTipo.nome));
						let verificacaoFormatacaoFnc = (f: string) => (valorReferencia.formatos.length > 0 && !valorReferencia.formatos.some((s: ListaTipoCampoFormatacao) => s.formato == f));
						let verificacaoFuncaoFnc = (f: string) => (valorReferencia.formatos.length > 0 && !valorReferenciaFuncoesCollection.some((s: any) => s.funcaoNome == f));
						let erro = '';

						if (this.linhaEdicao.campo.conteudoFormatacoesDatas.some(verificacaoFormatacaoFnc))
							erro = "telaTemplate.erroFormatacaoData";

						else if (this.linhaEdicao.campo.conteudoFormatacoesNumericas.some(verificacaoFormatacaoFnc))
							erro = "telaTemplate.erroFormatacaoNumerica";

						else if (this.linhaEdicao.campo.conteudoFormatacoesFuncoes.some(verificacaoFuncaoFnc))
							erro = "telaTemplate.erroFormatacaoFuncao";

						if (!isNullOrEmpty(erro)) {
							this.customAlertService.show("telaTemplate.template", erro, "error");
							this.linhaEdicao.campo.tipoCampoImportacaoAnteriorQuebraLinha = lti.tipoCampoImportacao;
							this.linhaEdicao.campo.tipoCampoImportacao = 0;
						}

						this.linhaEdicao.campo.listaCampoTipoIdAnteriorQuebraLinha = lti.listaCampoTipoId;
						this.linhaEdicao.campo.listaCampoValidacaoIdAnteriorQuebraLinha = lti.listaCampoValidacaoId;
						this.linhaEdicao.campo.chaveValidacaoAnteriorQuebraLinha = lti.chaveValidacaoAnteriorQuebraLinha
						this.linhaEdicao.campo.conteudoAnteriorQuebraLinha = lti.conteudo;

						this.linhaEdicao.campo.listaCampoTipoId = valorReferencia.listaCampoTipoId;
						this.linhaEdicao.campo.listaCampoValidacaoId = valorReferencia.listaCampoValidacaoId;
						this.linhaEdicao.campo.chaveValidacao = valorReferencia.chaveValidacao;
						this.linhaEdicao.campo.alias = valorReferencia.alias;
						this.linhaEdicao.campo.tamanho = valorReferencia.tamanho;
						this.linhaEdicao.campo.itemTipo = ItemTipo.Linha;

					} else {
						this.linhaEdicao.campo.listaCampoTipoId = (isNullOrZero(lti.listaCampoTipoIdAnteriorQuebraLinha)) ? this.camposIntegracao[0].listaCampoTipoId : lti.listaCampoTipoIdAnteriorQuebraLinha;
						this.linhaEdicao.campo.listaCampoValidacaoId = (isNullOrZero(lti.listaCampoValidacaoIdAnteriorQuebraLinha)) ? null : lti.listaCampoValidacaoIdAnteriorQuebraLinha;
						this.linhaEdicao.campo.chaveValidacao = (isNullOrEmpty(lti.conteudoAnteriorQuebraLinha)) ? undefined : lti.chaveValidacaoAnteriorQuebraLinha;
						this.linhaEdicao.campo.conteudo = (isNullOrEmpty(lti.conteudoAnteriorQuebraLinha)) ? '' : lti.conteudoAnteriorQuebraLinha;
						this.linhaEdicao.campo.tipoCampoImportacao = (isNullOrZero(lti.tipoCampoImportacaoAnteriorQuebraLinha)) ? 0 : lti.tipoCampoImportacaoAnteriorQuebraLinha;
						this.linhaEdicao.campo.listaCampoTipoIdAnteriorQuebraLinha = undefined;
						this.linhaEdicao.campo.listaCampoValidacaoIdAnteriorQuebraLinha = undefined;
						this.linhaEdicao.campo.chaveValidacaoAnteriorQuebraLinha = undefined;
						this.linhaEdicao.campo.conteudoAnteriorQuebraLinha = '';
						this.linhaEdicao.campo.tipoCampoImportacaoAnteriorQuebraLinha = undefined;
						this.linhaEdicao.campo.alias = this.camposIntegracao[0].nome;
						this.linhaEdicao.campo.tamanho = 0;
						this.linhaEdicao.campo.itemTipo = ItemTipo.Padrao;
					}

					if (!isNullOrZero(this.linhaEdicao.campo.listaCampoTipoId)) {
						this.linhaEdicao.campo.listaCampoTipo = this.obterCampoTipo(this.linhaEdicao.campo.listaCampoTipoId)
						this.linhaEdicao.campo.listaCampoTipoFormatacao = this.obterFormatacoes(this.linhaEdicao.campo.listaCampoTipoId);
						this.linhaEdicao.campo.listaCamposValidacao = this.obterValidacoes(this.linhaEdicao.campo.listaCampoTipoId);
					} else {
						this.linhaEdicao.campo.conteudo = '';
						this.linhaEdicao.campo.listaCampoTipoId = null;
						this.linhaEdicao.campo.listaCampoTipo = null;
						this.linhaEdicao.campo.listaCampoTipoFormatacao = [];
						this.linhaEdicao.campo.listaCamposValidacao = [];
					}

				}/* else {
					lti.listaLinhasColunas = this.combinacoesLinhasColunasUnicoItem(lti.ordem);
				}*/
			}
		});

		this.atualizarListaIdentificadores();
	}

	//#endregion

	validarReidexacao(templateSource: Template, templateAlteracao: Template): boolean {
		if (templateSource !== undefined && templateAlteracao !== undefined) {
			if (templateSource.quebraLinhas != templateAlteracao.quebraLinhas) return true;
			if (templateSource.identificadorAlias != templateAlteracao.identificadorAlias) return true;
			if (templateSource.identificadorTelefone != templateAlteracao.identificadorTelefone) return true;
			if (templateSource.identificadorTelefone1 != templateAlteracao.identificadorTelefone1) return true;
			if (templateSource.identificadorTelefone2 != templateAlteracao.identificadorTelefone2) return true;

			let source = templateSource.listaTemplateItem.filter(f => f.itemTipo < 5).map(m => ({ alias: m.alias, ativo: m.ativo, nome: m.nome.toLocaleLowerCase(), ordem: m.ordem }));
			let alteracao = templateAlteracao.listaTemplateItem.filter(f => f.itemTipo < 5).map(m => ({ alias: m.alias, ativo: m.ativo, nome: m.nome == null ? '' : m.nome.toLocaleLowerCase(), ordem: m.ordem }));

			let result: boolean;

			alteracao.forEach(f => {
				source.some((v, i) => {
					if (f.ativo) {
						if (f.nome == v.nome && f.alias != v.alias) result = true;
					}
				})
			});

			if (result) return true;

			return false;
		}
	}
	obterToolTipSobrescrever() {
		return this.translate.instant("telaTemplate.ignorarQtdHeaderInformação");
	}
	ignoraHeaderAlteracao(ev) {
		if (ev.value == true) {
			this.template.quantidadeQuebraLinha = 1;
		}
	}
}
