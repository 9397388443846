import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { DashListaProvedorService } from "../../../common/service/dash-lista-provedor.service";

@Component({
    selector: "app-dash-lista-provedor-configurado",
    templateUrl: "./dash-lista-provedor-configurado.component.html",
    styleUrls: ["../dash-lista-provedor.component.scss", "./dash-lista-provedor-configurado.component.scss"],
    providers: []
})

export class DashListaProvedorConfiguradoComponent implements OnInit, OnDestroy {
    
    subProvedor: Subscription;
    provedores: Array<any> = [];

    constructor(
        private dashListaProvedorService: DashListaProvedorService,
        private zone: NgZone) {
    }

    ngOnInit() {      
        this.subProvedor = this.dashListaProvedorService.onRegistroAtualizar.subscribe(data => {
            this.dashListaProvedorService.obterProvedorConfigurado(data.filtro).subscribe(result => {
                this.provedores = result;
            });
        });
    }

    ngOnDestroy() {
        if (this.subProvedor)
            this.subProvedor.unsubscribe();
    }
}