export class FormEstrategiaEstrategiaCampanhaBase {
    estrategiaId: number;
    estrategiaNome: string;
    estrategiaFiltro: string;
	estrategiaFiltroRegras: string;
    campanhaId: number;
    campanhaNome: string;

    constructor() { }

    static fromRaw(raw: any) {
        let data = new FormEstrategiaEstrategiaCampanhaBase();
        Object.assign(data, raw);
        return data;
    }
}