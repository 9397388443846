import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { find } from "core-js/core/array";
import { HistoricoRetornoService } from "../../../../common/service/historico-retorno.service";

@Component({
    selector: "app-detalhe-sem-retorno",
    templateUrl: "./detalhe-sem-retorno.component.html",
    styleUrls: ["../../style.scss"],
    providers: [
        { useClass: HistoricoRetornoService, provide: HistoricoRetornoService }
    ]
})
export class DetalheSemRetornoComponent {
    public envioSemRetorno: any;
    public chave: string;
    public valoresChave: Array<any> = [];
    public totalSemRetorno: number;

    constructor(
        private ref: MatDialogRef<DetalheSemRetornoComponent>,
        private historicoRetornoService: HistoricoRetornoService,
        @Inject(MAT_DIALOG_DATA)
        public data: { envioSemRetorno: any }
    ) {
        this.envioSemRetorno = data.envioSemRetorno;
        this.preview(this.envioSemRetorno.tarefaId, 1);
    }

	changePage(event) {
		const pagina = event.first / event.rows + 1;
		this.preview(this.envioSemRetorno.tarefaId, pagina);
	}
    
    preview(tarefaId: number, pagina: number) {

        this.historicoRetornoService
           .obterEnvioSemRetornoDetalhe(tarefaId, pagina)
           .subscribe(dados => {
               this.chave = dados.collection[0].campoChave;
               this.valoresChave = dados.collection;
               this.totalSemRetorno = dados.total;

               if (dados.collection.length == 0) return;
           });
   }

    close() {
        this.ref.close();
    }

    fechar() {
        this.ref.close();
    }
}