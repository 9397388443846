
import { map } from 'rxjs/operators';
import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Provider } from "../model/provider";
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "../../common/service/query-string";
import { isNull } from '../../common/utils';
import { ConfiguracaoListaProvedor } from '../model/configuracao-lista-provedor';
import { ProvedorConfiguracaoTipo, ConfiguracaoCampoProvider } from '../model/configuracao-campo-provedor';
import { ProvedorConfiguracao } from '../model/provedor-configuracao';
import { ProvedorTesteConexao } from '../model/provedor-teste-conexao';
import { ProvedorAgendamentoConfiguracao } from '../model/provedor-agendamento-configuracao';
import { StorageProxy } from './storage-proxy.service';
import { Observable, of } from 'rxjs';
import { query } from '@angular/animations';

@Injectable()
export class ProviderService {
  onTipoIntegracaoEnvioAtualizar: EventEmitter<any> = new EventEmitter();

  private api: string;

	constructor(private httpClient: HttpClient) { 
		this.api = '/api/providers';
	}

  obterListaProvedor(filtro) {
    let query = convertToQueryString(filtro);
    return this.httpClient.get<Array<Provider>>(
      `${this.api}${query}`
    );
  }

  obterProvedorConfiguradoPorLista(listaId) {
    return this.httpClient.get<Array<ConfiguracaoListaProvedor>>(
      `${this.api}/lista/${listaId}`
    ).pipe(map(configuracao => {

      let configs = configuracao.map(c => Object.assign(new ConfiguracaoListaProvedor(), c));
      configs.forEach(c => (c.provider = this.convertObject(c.provider)));

      return configs;
    }));
  }

  convertObject(object: any): Provider {
    let provider = Object.assign(new Provider(), object);
    provider.provedorConfiguracao = provider.provedorConfiguracao.map(p => Object.assign(new ProvedorConfiguracao(), p));
    provider.configuracaoCampoProvider = provider.configuracaoCampoProvider.map(c => Object.assign(new ConfiguracaoCampoProvider(), c));
    Object.assign(new ProvedorAgendamentoConfiguracao(), object.provedorAgendamentoConfiguracao);
    return provider;
  }

  obterProvedorDisponivelPorLista(listaId) {
    let providers = this.httpClient
      .get<Array<Provider>>(`${this.api}/lista/${listaId}/provedores`)
      .pipe(map(provider => {
        provider.forEach(c => (c = this.convertObject(c)));
        return provider;
      }));
    return providers;
  }

  obterProviders() {
    return this.httpClient.get<Array<Provider>>(
      `${this.api}`
    );
  }

  obterProviderPorId(id) {
    return this.httpClient
      .get<Provider>(`${this.api}/${id}`).pipe(
        map(provider => {
          let prov = Object.assign(new Provider(), provider);

          if (isNull(prov.provedorConfiguracao.find(p => p.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Envio && p.ativo)))
            prov.provedorConfiguracao.push(new ProvedorConfiguracao(ProvedorConfiguracaoTipo.Envio, true))

          if (isNull(prov.provedorConfiguracao.find(p => p.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Retorno && p.ativo)))
            prov.provedorConfiguracao.push(new ProvedorConfiguracao(ProvedorConfiguracaoTipo.Retorno, true))

          if (isNull(prov.provedorAgendamentoConfiguracao))
            prov.provedorAgendamentoConfiguracao = new ProvedorAgendamentoConfiguracao();

          prov.provedorConfiguracao = prov.provedorConfiguracao.map(p => Object.assign(new ProvedorConfiguracao(), p));
          prov.configuracaoCampoProvider = prov.configuracaoCampoProvider.map(c => Object.assign(new ConfiguracaoCampoProvider(), c));
          Object.assign(new ProvedorAgendamentoConfiguracao(), prov.provedorAgendamentoConfiguracao);

          return prov;
        }));
  }

  possuiTemplateParametrosUrl(providerId: number) {
    return this.httpClient.get<boolean>(
      `${this.api}/${providerId}/possui-template-parametros-url`
    );
  }



  criarProvider(provider: Provider) {
    return this.httpClient.post(
      `${this.api}`,
      provider
    );
  }

  editarProvider(provider: Provider) {
    return this.httpClient.put<Provider>(
      `${this.api}/${provider.providerId}`,
      provider
    );
  }

  excluir(providerId) {
    return this.httpClient.patch(
      `${this.api}/${providerId}/desativar`,
      {}
    );
  }

  duplicar(providerId) {
    return this.httpClient.get<any>(
      `${this.api}/${providerId}/duplicar-provedor`,
      {}
    );
  }

  obterCampanhas(providerId) {
    return this.httpClient.get<Array<any>>(
      `${this.api}/${providerId}/campanhas`
    );
  }

  obterCampanhasConfiguradasProvedor(providerId: number, templateImportacaoId: number) {
    return this.httpClient.get<Array<any>>(
      `${this.api}/${providerId}/campanhas-provedor?templateImportacaoId=${templateImportacaoId}`
    );
  }

  obterCampanhasConfiguradas(listaId, providerId) {
    return this.httpClient.get<Array<any>>(
      `${this.api}/${providerId}/lista/${listaId}/campanhas`
    );
  }

  obterCampanhasProvider(provider: Provider) {
    return this.httpClient.post<Array<any>>(
      `${this.api}/${provider.providerId}/campanhas`,
      provider
    );
  }

  obterListaCampo(providerId: number, campanhaId = null) {
    return this.httpClient.get<Array<string>>(
      `${this.api}/${providerId}/campos?campanhaId=${campanhaId}`
    );
  }

  obterConfiguracaoPorId(configuracaoId) {
    return this.httpClient.get<ConfiguracaoListaProvedor>(
      `${this.api}/configuracao/${configuracaoId}`
    );
  }

  testarConexao(dados: ProvedorTesteConexao) {
    return this.httpClient.post(
      `${this.api}/testarConexao`,
      dados
    );
  }

  testarEnvio(dados: any) {
    return this.httpClient.post(
      `${this.api}/testarEnvio`,
      dados
    );
  }

  obterConfiguracaoAgendamentoRetornoPadrao() {
    return this.httpClient
      .get<ProvedorAgendamentoConfiguracao>(`${this.api}/configuracao-agendamento-retorno-padrao`)
      .pipe(map(m => (ProvedorAgendamentoConfiguracao.fromRaw(m))));
  }

  obterAlias() {
    return this.httpClient.get<Array<any>>(
      `${this.api}/alias`
    );
  }

  obterConfiguracaoRetornoWebhook() {
    return this.httpClient.get<any>(
      `${this.api}/configuracao-webhook`
    );
  }
  ValidarArquivoPath(query):Observable<boolean> {
    return this.httpClient.get<any>(
      `${this.api}/ValidarDiretorio?query=${query}`
    );
  }

  onSelecionarIntegracaoTipoEnvio() {
    this.onTipoIntegracaoEnvioAtualizar.emit({});
  }

  obterProviderComCampos(providerId) {
    return this.httpClient.get<Provider>(
      `${this.api}/obter-campos/${providerId}`
    );
  }
}