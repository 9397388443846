import { Component, OnInit, Input, EventEmitter, ViewChild, TemplateRef, ContentChild, } from "@angular/core";

// @Component({
//     selector: "tab-fb-content",
//     template: "<ng-template><ng-content></ng-content></ng-template>"
// })
// export class TabFloatingBoxContentComponent implements OnInit {

//     @ViewChild(TemplateRef, { static: false })
//     bodyContent: TemplateRef<any>;

//     constructor() { }

//     ngOnInit(): void { }
// }



// import { TabFloatingBoxLabelComponent } from "./tab-floating-box-label.component";
// import { TabFloatingBoxBodyComponent } from "./tab-floating-box-body.component";

@Component({
    selector: "tab-fb-content",
    template: "<ng-template><ng-content></ng-content></ng-template>",
})
export class TabFloatingBoxContentComponent implements OnInit {
    // @Input()
    // label: string;

    // @Input()
    // isActive: boolean;

    // @ContentChild(TabFloatingBoxBodyComponent, { static: true })
    // bodyComponent: TabFloatingBoxBodyComponent;

    // @ContentChild(TabFloatingBoxLabelComponent, { static: true })
    // labelComponent: TabFloatingBoxLabelComponent;

    @ViewChild(TemplateRef, { static: false })
    templateContent: TemplateRef<any>;

    constructor() { }

    ngOnInit(): void { }
}

