import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { NotificacaoService } from "../../common/service/notificacao.service";
import { NotificacaoEventoPipe } from "../libs/pipes/notificacao-evento-pipe"
import { DefaultValuesService } from "../../common/service/default-values.service";
import { isNull, isNullOrEmpty } from "../../common/utils";
import { NNotificacaoConexaoApi } from "../../common/model/nnotificacao-conexao-api";
import { QueryFilter, SorterContainer } from "../../common/service/query-filter.dto";
import { LazyLoadEvent, MenuItem } from "primeng/api";
import { capitalizeFirstLetter } from "../../common/utils/string-utils";
import { NNotificacaoConexao, NNotificacaoTipoEnvio } from "../../common/model/nnotificacao.index";
import { ContextMenuComponent } from "ngx-contextmenu";

@Component({
    selector: "app-notificacao-conexao-api",
    templateUrl: "./notificacao-conexao-api.component.html",
    styleUrls: ["./notificacao-conexao-api.component.scss"],
    providers: [
        { useClass: DefaultValuesService, provide: DefaultValuesService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: NotificacaoEventoPipe, provide: NotificacaoEventoPipe }
    ]
})
export class NotificacaoConexaoApiComponent implements OnInit {

    metodosHttp: any[];
    tiposEnvio: any[];
    tiposAutenticacao: any[];
    grantTypes: any[];
    submitted: boolean = false;
    notificacaoConexao: NNotificacaoConexao | null;

    //#region [ Propriedades do grid ]

    filtroPesquisa = { notificacaoTipoEnvioId: NNotificacaoTipoEnvio.Api };

    // Table properties
    first: number = 0;
    rows: number = 10;
    totalRecords: number = 0;
    loading: boolean = true;

    // Back-end filter
    identifier: string = "DataCadastro";
    direction: number = -1;
    queryFilter: QueryFilter;

    listaConexoes: Array<NNotificacaoConexao> = []

    //#endregion

    public get isForm(): boolean {
        return (isNull(this.notificacaoConexao) == false);
    }

    liveError(property: string, message: string) {
        if (this.submitted) {
            let result = this.notificacaoConexao.validate();
            if (!result) return null;

            if (isNullOrEmpty(message))
                return (isNull(result[property]) == false);
            else
                return result[property] ? result[property][0] == message : false;
        }
    }

    liveErrorConfiguracao(property: string, message: string) {
        if (this.submitted) {
            let result = this.notificacaoConexao.configuracaoApi.validate();
            if (!result) return null;

            if (isNullOrEmpty(message))
                return (isNull(result[property]) == false);
            else
                return result[property] ? result[property][0] == message : false;
        }
    }

    itemsContextMenu: MenuItem[] | undefined;

    @ViewChild(ContextMenuComponent, { static: true }) basicMenu: ContextMenuComponent;

    constructor(
        private notificacaoService: NotificacaoService,
        private defaultValuesService: DefaultValuesService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        private customAlertService: CustomAlertService
    ) {   }

    ngOnInit() {
        this.obterMetodosHttp();
        this.obterTiposEnvio();
        this.obterTiposAutenticacao();
        this.obterGrantType();
    }

    //#region [ Carregamento dos dados ]

    obterMetodosHttp() {
        this.defaultValuesService.obterMetodosHttp().then(result => this.metodosHttp = result);
    }

    obterTiposEnvio() {
        this.defaultValuesService.obterTiposEnvio().then(result => this.tiposEnvio = result);
    }

    obterTiposAutenticacao() {
        this.defaultValuesService.obterTipoAutenticacaoProvedor().then(result => this.tiposAutenticacao = result);
    }

    obterGrantType() {
        this.defaultValuesService.obterGrantType().then(result => (this.grantTypes = result));
    }

    //#endregion

    //#region [ Pesquisa do Grid ] 

    loadData(event: LazyLoadEvent) {
        this.loading = true;
        this.first = event.first;
        this.rows = event.rows;
        this.queryFilter = new QueryFilter();
        this.queryFilter.start = this.first;
        this.queryFilter.limit = this.rows;

        this.setSortProperties(event);

        this.buscar();
    }

    setSortProperties(event: LazyLoadEvent) {
        let sort: SorterContainer[] = [];

        if (event.sortField !== undefined) {
            sort = [{ property: capitalizeFirstLetter(event.sortField), direction: event.sortOrder.toString() }];
        }
        else {
            sort = [{ property: this.identifier, direction: this.direction.toString() }];
        }

        this.queryFilter.sort = sort;
    }

    buscar() {
        let filter = { ...this.queryFilter, ...this.filtroPesquisa }

        this.notificacaoService.obterNotificacaoConexao(filter).subscribe((result: any) => {
            this.loading = false;
            this.listaConexoes = result.data.map((m: any) => NNotificacaoConexao.fromRaw(m));
            this.totalRecords = result.total;
        }, (error: any) => {
            this.loading = false;
        });
    }

    defaultSettings() {
        this.filtroPesquisa = { notificacaoTipoEnvioId: NNotificacaoTipoEnvio.Api };
    }

    editar(conexao: NNotificacaoConexao) {
        this.notificacaoConexao = NNotificacaoConexao.fromRaw(conexao);
        this.notificacaoConexao.configuracaoApi = NNotificacaoConexaoApi.fromRaw(JSON.parse(conexao.configuracaoJson));
        this.notificacaoConexao.configuracaoApi.camposPorTipoAutenticacao();
    }

    excluir(conexao: NNotificacaoConexao) {
        // this.customAlertService
        //     .confirmationMessage("telaElasticSearch.confirmacaoDesativarElastic")
        //     .then(() => {
        //         this.notificacaoService.desativarConexaoApi(conexao.id).subscribe(() => {
        //             //this.onAtualizarListaConexoes.emit()
        //             //ATUALIZAR GRID
        //         });
        //     });
    }

    //#endregion

    //#region [ CRUD ]

    novaConexao() {
        this.notificacaoConexao = new NNotificacaoConexao();
        this.notificacaoConexao.notificacaoTipoEnvioId = NNotificacaoTipoEnvio.Api;
        this.notificacaoConexao.configuracaoApi = new NNotificacaoConexaoApi();
    }

    cancelar() {
        this.notificacaoConexao = null;
    }

    salvar() {
        this.submitted = true;

        if (this.notificacaoConexao.validate() || this.notificacaoConexao.configuracaoApi.validate()) {
            this.customAlertService.show("telaElasticSearch.elasticSearch", "telaPadrao.camposInvalidos", "error");
            return;
        }

        this.notificacaoConexao.configuracaoJson = JSON.stringify(this.notificacaoConexao.configuracaoApi);

        this.notificacaoService.salvarConexao(this.notificacaoConexao).subscribe(
            (result) => {
                this.customAlertService.show("telaNNotificacao.mensagens.configuracaoConexoes", "telaNNotificacao.mensagens.salvarConexoes", "success");
                this.notificacaoConexao = null;
                this.submitted = false;
                this.buscar();
            },
            (error: any) => {
                this.customAlertService.show("telaNNotificacao.mensagens.configuracaoConexoes", error.error[0], "error");
            });
    }

    //#endregion

}