import { BaseElasticsearchFilter } from "./base";
import { FieldQuery } from "./field_query";

/*
  Classe para o not exists. Ele nega os valores ao criar um bool com negação:

  {
    "query" : {
        "bool": {
        	"must": [{
        		"bool":{
        			"must_not": {
		                "exists" : { "field" : "tags" }
		            }
        		}
        	}]
        }
    }
  }
  Isso pode ser utilizado para outras negações, por motivo de tempo não deixei genérico, porém seria simples fazer a alteração
*/
export class NotExistsQuery extends FieldQuery {
  protected getInstance(
    queryType: string,
    propertyName: string,
    parameters: any,
    extra?: any,
    keyword?: boolean
  ): BaseElasticsearchFilter {
    return new NotExistsQuery(propertyName, queryType, parameters, extra, keyword);
  }

  protected getQueryType(filter) {
    return "bool";
  }

  protected getPropertyName(filter, queryType) {
    return filter.bool.must_not[0].exists.field;
  }

  public friendlyParameters(dataType: string, queryTypes: Array<any>) {
    return `Não possui valor`;
  }

  protected convertFilter() {
    return {
      bool: {
        must_not: [
          { exists: { field: this.property } },
          { term: { [this.property]: "" } }
        ]
      }
    };
  }
  static fromRaw(raw): NotExistsQuery {
    return Object.assign(new NotExistsQuery(), raw);
  }
}
