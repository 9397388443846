import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[appTamanho]"
})
export class TamanhoDirective {
  _tamanho;
  @Input("appTamanho")
  public set tamanho(tamanho: number) {
    if (!tamanho) tamanho = 0;
    this._tamanho = tamanho;
    this.updateInterface();
  }

  public get tamanho(): number {
    return this._tamanho;
  }
  constructor(private el: ElementRef) {}

  public updateInterface() {
    this.el.nativeElement.innerHTML = `${this.tamanho
      .toFixed(2)
      .replace(".", ",")} GB`;
  }
}
