import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, ViewChild, NgZone } from "@angular/core";
import { Subscription } from "rxjs";

import * as primeng from 'primeng/primeng';
import { Table } from 'primeng/table';
import * as validate from "validate.js";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import { ILocale } from '@amcharts/amcharts4/.internal/core/utils/Language';

import { isNull, isNullOrEmpty, isNullOrZero } from "../../common/utils";
import { ListaService } from "../../common/service/lista.service";
import { DashEstrategiasService } from '../../common/service/dash-estrategias.service';
import { FiltroLista } from "../../common/model/filtro-lista";
import { Lista } from "../../common/model/lista";
import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";

am4core.useTheme(am4themes_animated);

@Component({
    selector: "app-dash-estrategias",
    templateUrl: "./dash-estrategias.component.html",
    styleUrls: ["./dash-estrategias.component.scss"],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: DashEstrategiasService, provide: DashEstrategiasService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
        { useClass: TranslateService, provide: TranslateService }
    ]
})
export class DashEstrategiasComponent implements OnInit, OnDestroy {
    filtro: any = {};
    dirty: boolean;
    idiomaChart: ILocale = am4lang_pt_BR;

    // Combos 
    listas: Array<Lista> = [];
    carteiras: Array<Carteira> = [];
    preferenciasUsuario: PreferenciasUsuario;

    // Dados
    dadosMaisExportadasEmQuantidade: Array<any>;
    dadosMaisExportadasEmVezes: Array<any>;
    dadosIndicadoresEstrategias: any;
    dadosCampeas: Array<any>;
    dadosMenosExportadasEmVezes: Array<any>;
    dadosMaisRetornosQuantidade: Array<any>;

    // Charts
    chartMaisExportadasEmQuantidade: am4charts.XYChart;
    chartMaisExportadasEmVezes: am4charts.XYChart;
    chartIndicadoresEstrategias: am4charts.XYChart;
    chartCampeas: Array<am4charts.XYChart> = [];

    // Subscriptions
    subListas: Subscription;
    subEstrategiasMaisExportadasEmQuantidade: Subscription;
    subEstrategiasMaisExportadasEmVezes: Subscription;
    subIndicadoresEstrategias: Subscription;
    subEstrategiasCampeas: Subscription;
    subEstrategiasMenosExportadasEmVezes: Subscription;
    subEstrategiasMaisRetornosQuantidade: Subscription;

    public get erros() {
        if (!this.dirty) return;
        return this.validarFiltros();
    }

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1);
    }

    @ViewChild("tbMenosExportadasEmVezes", { static: false }) tableMenosExportadasEmVezes: Table;
    @ViewChild("tbMaisRetornosQuantidade", { static: false }) tableMaisRetornosQuantidade: Table;
    constructor(
        private listaService: ListaService,
        private dashEstrategiasService: DashEstrategiasService,
        private carteiraService: CarteiraService,
        private preferenciasService: PreferenciasUsuarioService,
        private translateService: TranslateService,
        private zone: NgZone) {
        let dataAtual = new Date();
        this.filtro.dataInicio = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(), 0, 0, 0);
        this.filtro.dataFim = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(), 23, 59, 59);

        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();
    }

    ngOnInit() {
        this.obterCarteiras();

    }

    ngOnDestroy() {

        if (this.subListas) { this.subListas.unsubscribe(); }
        if (this.subEstrategiasMaisExportadasEmQuantidade) { this.subEstrategiasMaisExportadasEmQuantidade.unsubscribe(); }
        if (this.subEstrategiasMaisExportadasEmVezes) { this.subEstrategiasMaisExportadasEmVezes.unsubscribe(); }
        if (this.subIndicadoresEstrategias) { this.subIndicadoresEstrategias.unsubscribe(); }
        if (this.subEstrategiasCampeas) { this.subEstrategiasCampeas.unsubscribe(); }
        if (this.subEstrategiasMenosExportadasEmVezes) { this.subEstrategiasMenosExportadasEmVezes.unsubscribe(); }
        if (this.subEstrategiasMaisRetornosQuantidade) { this.subEstrategiasMaisRetornosQuantidade.unsubscribe(); }

        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.zone.runOutsideAngular(() => {
                    if (this.chartMaisExportadasEmQuantidade) {
                        this.chartMaisExportadasEmQuantidade.dispose();
                        this.chartMaisExportadasEmQuantidade = undefined;
                    }
                    if (this.chartMaisExportadasEmVezes) {
                        this.chartMaisExportadasEmVezes.dispose();
                        this.chartMaisExportadasEmVezes = undefined;
                    }
                    if (this.chartIndicadoresEstrategias) {
                        this.chartIndicadoresEstrategias.dispose();
                        this.chartIndicadoresEstrategias = undefined;
                    }
                    this.chartCampeas.forEach((f: any) => {
                        f.dispose();
                        f = undefined;
                    });
                })
            }, 0);
        });
    }

    //#region [ Carregamento dos dados ]

    search() {
        this.dirty = true;

        if (this.validarFiltros())
            return;

        this.setCurrentPage(0);

        this.obterEstrategiasMaisExportadasEmQuantidade();
        this.obterEstrategiasMaisExportadasEmVezes();
        this.obterIndicadoresEstrategias();
        this.obterEstrategiasCampeas();
        this.obterEstrategiasMenosExportadasEmVezes();
        this.obterEstrategiasMaisRetornosQuantidade();
    }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;


            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }


            this.obterListas();
            this.search();
            this.carteiras.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
            });

        });
    }

    obterListas(listaId: number = null) {
        let filtro: FiltroLista = { ignorarErroPesquisa: true } as FiltroLista;
        if (!isNullOrZero(listaId)) filtro.lista = listaId;
        if (!isNullOrZero(Number(this.filtro.carteiraId))) filtro.carteiraId = Number(this.filtro.carteiraId);
        this.subListas = this.listaService.obterLista(filtro).subscribe((listas: Lista[]) => 
        {
            this.listas = listas.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
            });
        });
       

    }

    obterEstrategiasMaisExportadasEmQuantidade() {
        this.subEstrategiasMaisExportadasEmQuantidade = this.dashEstrategiasService.obterDadosEstrategiasMaisExportadasEmQuantidade(this.filtro).subscribe((data: any) => {
            this.dadosMaisExportadasEmQuantidade = data.map((m: any) => {
                m.nome = `${m.estrategiaNome} - ${m.listaNome}`;
                return m;
            });
            this.chartMaisExportadasEmQuantidade = this.criarGraficoBarras("chart-div-mais-exportadas-quantidade", data, "nome", "quantidade");
            setTimeout(() => { $('g[shape-rendering=auto][stroke*=3cabff]').parent().hide(); }, 500);
        });
    }

    obterEstrategiasMaisExportadasEmVezes() {
        this.subEstrategiasMaisExportadasEmVezes = this.dashEstrategiasService.obterDadosEstrategiasMaisExportadasEmVezes(this.filtro).subscribe((data: any) => {
            this.dadosMaisExportadasEmVezes = data.map((m: any) => {
                m.nome = `${m.estrategiaNome} - ${m.listaNome}`;
                return m;
            });
            this.chartMaisExportadasEmVezes = this.criarGraficoBarras("chart-div-mais-exportadas-vezes", data, "nome", "quantidade");
            setTimeout(() => { $('g[shape-rendering=auto][stroke*=3cabff]').parent().hide(); }, 500);
        });
    }

    obterIndicadoresEstrategias() {
        this.subIndicadoresEstrategias = this.dashEstrategiasService.obterDadosIndicadoresEstrategias(this.filtro).subscribe((data: Array<any>) => {
            this.dadosIndicadoresEstrategias = { 'data': [], 'estrategiasUnicas': [] };

            data.forEach((f: any) => {
                if (!this.dadosIndicadoresEstrategias.data.some((s: any) => s.periodo == f.periodo)) {
                    let dados = { 'periodo': f.periodo };

                    data.filter((c: any) => c.periodo == f.periodo).forEach((i: any) => {
                        if (!isNullOrEmpty(i.listaEstrategiaId)) {
                            dados[`exportados_${i.listaEstrategiaId}`] = i.quantidadeExportacao;
                            dados[`retornos_${i.listaEstrategiaId}`] = i.quantidadeRetorno;
                        }
                    });

                    this.dadosIndicadoresEstrategias.data.push(dados);
                }

                if (!isNullOrEmpty(f.listaEstrategiaId) && !this.dadosIndicadoresEstrategias.estrategiasUnicas.some((s: any) => s.listaEstrategiaId == f.listaEstrategiaId)) {
                    this.dadosIndicadoresEstrategias.estrategiasUnicas.push({
                        'listaId': f.listaId,
                        'listaNome': f.listaNome,
                        'listaEstrategiaId': f.listaEstrategiaId,
                        'estrategiaNome': f.estrategiaNome,
                        'chaveExportados': `exportados_${f.listaEstrategiaId}`,
                        'chaveRetornos': `retornos_${f.listaEstrategiaId}`,
                        'nome': `${f.estrategiaNome} - ${f.listaNome}`
                    });
                }
            });

            this.chartMaisExportadasEmVezes = this.criarGraficoIndicadores("chart-div-indicadores-estrategias", this.dadosIndicadoresEstrategias);
            setTimeout(() => { $('g[shape-rendering=auto][stroke*=3cabff]').parent().hide(); }, 500);
        });
    }

    obterEstrategiasCampeas() {
        this.subEstrategiasCampeas = this.dashEstrategiasService.obterDadosEstrategiasCampeas(this.filtro).subscribe((data: any) => {

            let dados = data.map((m: any) => {
                m.nome = `${m.estrategiaNome} - ${m.listaNome}`;
                return m;
            });

            this.dadosCampeas = [
                {
                    tipo: 1,
                    categoria: this.translateService.instant("dash.melhorRetorno"),
                    data: dados.filter((f: any) => f.tipoCampea == 1)
                },
                {
                    tipo: 2,
                    categoria: this.translateService.instant("dash.tempoInferior") ,
                    data: dados.filter((f: any) => f.tipoCampea == 2)
                }
            ];

            setTimeout(() => {
                this.zone.runOutsideAngular(() => {
                    this.dadosCampeas.forEach((d: any, index: number) => {
                        if (!isNull(this.chartCampeas[index]))
                            this.chartCampeas[index] = null;

                        this.chartCampeas[index] = this.criarGraficoBarras(`chart-div-campeas-${d.tipo}`, d.data, "nome", "valor");
                    });
                })
            }, 0);

            setTimeout(() => { $('g[shape-rendering=auto][stroke*=3cabff]').parent().hide(); }, 500);
        });
    }

    obterEstrategiasMenosExportadasEmVezes() {
        this.subEstrategiasMenosExportadasEmVezes = this.dashEstrategiasService.obterDadosEstrategiasMenosExportadasEmVezes(this.filtro).subscribe((data: any) => {
            this.dadosMenosExportadasEmVezes = data;
        });
    }

    obterEstrategiasMaisRetornosQuantidade() {
        this.subEstrategiasMaisRetornosQuantidade = this.dashEstrategiasService.obterDadosEstrategiasMaisRetornosQuantidade(this.filtro).subscribe((data: any) => {
            this.dadosMaisRetornosQuantidade = data;
        });
    }

    //#endregion

    //#region [ Montagem dos gráficos ]

    criarGraficoBarras(divId: string, data: any, categoria: string, serie: string): am4charts.XYChart {

        //https://codepen.io/team/amcharts/pen/GRRoamd
        //https://www.amcharts.com/demos/column-with-rotated-series/

        let chart = am4core.create(divId, am4charts.XYChart);
        chart.language.locale = this.idiomaChart;

        // Add data
        chart.data = data;

        // Create value axis
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.renderer.minWidth = 30;
        // valueAxis.min = 0;
        // valueAxis.cursorTooltipEnabled = false;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = categoria;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        // Configure axis label
        let label = categoryAxis.renderer.labels.template;
        label.truncate = true;
        label.maxWidth = 90;
        label.tooltipText = `{${categoria}}`;
        //label.wrap = true;

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = serie;
        series.dataFields.categoryX = categoria;
        series.sequencedInterpolation = true;
        series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
        series.columns.template.strokeWidth = 0;

        series.tooltip.pointerOrientation = "vertical";

        series.columns.template.column.cornerRadiusTopLeft = 10;
        series.columns.template.column.cornerRadiusTopRight = 10;
        series.columns.template.column.fillOpacity = 0.8;

        // on hover, make corner radiuses bigger
        let hoverState = series.columns.template.column.states.create("hover");
        hoverState.properties.cornerRadiusTopLeft = 0;
        hoverState.properties.cornerRadiusTopRight = 0;
        hoverState.properties.fillOpacity = 1;

        series.columns.template.adapter.add("fill", function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });

        // Cursor
        chart.cursor = new am4charts.XYCursor();

        return chart;
    }

    criarGraficoCampeas(divId: string, data: any): am4charts.XYChart {

        let chart = am4core.create(divId, am4charts.XYChart);
        chart.language.locale = this.idiomaChart;

        // Add data
        chart.data = data;
        chart.colors.step = 2;
        chart.legend = new am4charts.Legend();
        chart.legend.position = 'top';
        chart.legend.paddingBottom = 20;
        chart.legend.labels.template.maxWidth = 95;

        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = 'categoria';
        xAxis.renderer.cellStartLocation = 0.1;
        xAxis.renderer.cellEndLocation = 0.9;
        xAxis.renderer.grid.template.location = 0;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;

        function createSeries(value, name, nome) {
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = value;
            series.dataFields.categoryX = 'categoria';
            series.name = name;
            series.columns.template.tooltipText = "{" + nome + "}: [bold]{valueY}[/]";
            //series.stacked = stacked;
            //series.columns.template.width = am4core.percent(95);

            var bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.interactionsEnabled = false;
            bullet.dy = 30;;
            bullet.label.text = '{valueY}';
            bullet.label.fill = am4core.color('#ffffff');

            return series;
        }

        createSeries('primeiro', this.translateService.instant("dash.1lugar"), 'nome_primeiro');
        createSeries('segundo', this.translateService.instant("dash.2lugar"), 'nome_segundo');
        createSeries('terceiro', this.translateService.instant("dash.3lugar"), 'nome_terceiro');

        return chart;

    }

    criarGraficoIndicadores(divId: string, data: any): am4charts.XYChart {

        let chart = am4core.create(divId, am4charts.XYChart);
        chart.language.locale = this.idiomaChart;

        // Add data
        chart.data = data.data;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "periodo";
        categoryAxis.renderer.grid.template.location = 0;
        //categoryAxis.renderer.
        categoryAxis.fontSize = 11;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;

        // Create series
        function createSeries(field: string, name: string, cor: am4core.Color, stacked: boolean = true, text: string = this.translateService.instant("dash.tempoInferior")  ) {

            // Set up series
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.name = name;
            series.dataFields.valueY = field;
            series.dataFields.categoryX = "periodo";
            series.sequencedInterpolation = true;

            // Make it stacked
            series.stacked = stacked;

            // Configure columns
            series.columns.template.width = am4core.percent(60);
            series.columns.template.tooltipText = `[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY} ${text}`;
            //series.columns.template.fill = am4core.color("#00ff00");
            series.columns.template.fill = cor;

            // Add label
            let labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = "{valueY}";
            labelBullet.locationY = 0.5;
            labelBullet.label.hideOversized = true;

            return series;
        }

        let colorSet = new am4core.ColorSet();

        data.estrategiasUnicas.forEach((f: any) => {
            f.cor = colorSet.next();
            createSeries(f.chaveExportados, f.nome, f.cor)
        });

        data.estrategiasUnicas.forEach((f: any, i: number) => {
            createSeries(f.chaveRetornos, f.nome, f.cor, (i > 0), 'registros retornados');
        });

        // createSeries("quantidadeExportacao", "Quantidade Registros");
        // createSeries("quantidadeExportacaoErro", "Quantidade Registros com Erro");

        // Legend
        //chart.legend = new am4charts.Legend();

        return chart;
    }

    //#endregion

    //#region [ Uteis ]

    carteiraChange() {
        this.obterListas();
    }

    clean() {
        let dataAtual = new Date();
        this.filtro.listaId = null;
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
        this.filtro.dataInicio = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(), 0, 0, 0);
        this.filtro.dataFim = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(), 23, 59, 59);
    }

    validarFiltros() {
        validate.validators.dataMenorQue = function (value, options, key, attributes) {
            if (value > attributes[options.campo]) return "dataMaior";
        };

        let validacao: any = {
            dataInicio: {
                presence: { allowEmpty: false, message: "campoObrigatorio" },
                dataMenorQue: { campo: "dataFim" }
            },
            dataFim: {
                presence: { allowEmpty: false, message: "campoObrigatorio" }
            }
        };
        return validate(this.filtro, validacao, { fullMessages: false })
    }

    setCurrentPage(n: number) {
        if (this.tableMenosExportadasEmVezes)
            this.tableMenosExportadasEmVezes.first = n * 5;

        if (this.tableMaisRetornosQuantidade)
            this.tableMaisRetornosQuantidade.first = n * 5;
    }

    //#endregion
}
