import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';

import { Configuracao } from "../../common/model/configuracao";

import { ConfiguracaoService } from "../../common/service/configuracao.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { DayjsDateAdapter } from './material-dayjs-adapter';
//import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

@Component({
    selector: "app-lista-configuracao",
    templateUrl: "./lista-configuracao.component.html",
    styleUrls: ["./lista-configuracao.component.scss"],
    providers: [
        { useClass: ConfiguracaoService, provide: ConfiguracaoService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { provide: DateAdapter, useClass: DayjsDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class ListaConfiguracaoComponent implements OnInit {
    dirty: boolean;
    configuracoes: Array<Configuracao>;
    rowGroupMetadata: any;
    expandedRows: any;
    filtro = { 'nome': '', 'lista': '', 'fluxoTipoId': '' };
    expandirTodos: boolean = true;

    

    @ViewChild(DataView, { static: false }) dataTable: DataView;
    constructor(
        private configuracaoService: ConfiguracaoService,
        private route: ActivatedRoute,
        private customAlertService: CustomAlertService,
        public dialog: MatDialog
        //private dateAdapter: DateAdapter<Date>
    ) {
        // this.dateAdapter.setLocale('en-US');
    }

    ngOnInit() {
        this.obterConfiguracoes();
    }

    obterConfiguracoes() {
        this.configuracaoService.obterConfiguracoes(this.filtro).subscribe(data => {
            this.configuracoes = data;
            this.updateRowGroupMetaData();
            //this.setCurrentPage(0);
        });
    }

    updateRowGroupMetaData() {
        this.rowGroupMetadata = {};
        this.expandedRows = {};

        if (this.configuracoes) {
            for (let i = 0; i < this.configuracoes.length; i++) {
                let rowData = this.configuracoes[i];
                let group = rowData.grupo;

                if (i == 0) {
                    this.rowGroupMetadata[group] = { index: 0, size: 1 };
                } else {
                    let previousRowData = this.configuracoes[i - 1];
                    let previousRowGroup = previousRowData.grupo;

                    if (group === previousRowGroup)
                        this.rowGroupMetadata[group].size++;
                    else
                        this.rowGroupMetadata[group] = { index: i, size: 1 };
                }
            }

            Object.keys(this.rowGroupMetadata).forEach((f: any) => (this.expandedRows[f] = 1))

        }
    }

    expandirTodosChange() {
        Object.keys(this.rowGroupMetadata).forEach((f: any) => (this.expandedRows[f] = (this.expandirTodos) ? 1 : 0));
    }


    // setCurrentPage(n: number) {
    //     if (this.dataTable)
    //         this.dataTable.first = n;
    // }

    // limpar() {
    //     this.filtro = { 'nome': '', 'lista': '', 'fluxoTipoId': '' };
    // }

    salvar() {
        this.dirty = true;

        if (this.validar()) {
            this.customAlertService.show("telaConfiguracao.configuracoes", "telaPadrao.camposInvalidos", "error");
            return;
        }

        this.configuracaoService.atualizar(this.configuracoes).subscribe((response: any) => {
            this.obterConfiguracoes();
            this.customAlertService.show("telaConfiguracao.configuracoes", "telaPadrao.sucessoSalvar", "success");
        }, () => {
            this.customAlertService.show("telaConfiguracao.configuracoes", "telaPadrao.erroSalvar", "error");
        });
    }

    desabilitarGravar(): boolean {
        return !this.configuracoes.some((s:Configuracao) => (s.valorAlterado))
    }

    erros(config: Configuracao) {
        if (!this.dirty) return;
        return config.validar();
    }

    validar() {
        return this.configuracoes.some((config: Configuracao) => {
            return config.validar();
        });
    }
}