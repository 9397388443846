import { Component, Output, EventEmitter } from "@angular/core";
import { Carteira } from "../../common/model/carteira";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";
import { CarteiraService } from "../../common/service/carteira.service";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";

@Component({
    selector: "app-filtro-usuario",
    templateUrl: "./filtro-usuario.component.html",
    styleUrls: ["../style.scss"],
    providers: [
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService }
    ]
})

export class FiltroUsuarioComponent {
    preferenciasUsuario: PreferenciasUsuario;
    filtro: any = {};
    carteiras: Array<Carteira> = [];

    @Output()
    filtrar: EventEmitter<any> = new EventEmitter<any>();

    constructor(private carteiraService: CarteiraService, private preferenciasService: PreferenciasUsuarioService) { }

    ngOnInit() {
        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

        this.obterCarteiras();
    }

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1);
    }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;

            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }

            this.filtrarUsuario();
        });
    }

    filtrarUsuario() {
        this.carteiras.sort((a,b) => {
            if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                return -1;
            }
            if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                return 1;
            }
            return 0;
        });
    
        this.filtrar.emit(this.filtro)
    }

    limpar() {
        this.filtro = {};
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
    }
}
