import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import {
  MatFormFieldModule,
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatCardModule,
  MatTabsModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatDialogModule
} from "@angular/material";

import { TableModule } from "primeng/table";
import { TextMaskModule } from 'angular2-text-mask';
import { AppCommonModule } from "../common/common.module";
import { PesquisaEstrategiaComponent } from "./pesquisa-estrategia/pesquisa-estrategia.component";
import { PesquisaListaComponent } from "./pesquisa-lista/pesquisa-lista.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { ElasticsearchModule } from "../elasticsearch/elasticsearch.module";
import { EsBuilderModule } from "../esbuilder/esbuilder.module";

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    TableModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,    
    TextMaskModule,
    NgSelectModule,
    ElasticsearchModule,
    EsBuilderModule,
  ],
  declarations: [
    PesquisaEstrategiaComponent,
    PesquisaListaComponent
  ],
  entryComponents: []
})
export class PesquisaModule {}
