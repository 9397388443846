import { Component, OnInit, ViewChild } from "@angular/core";
import { FiltroLista } from "../../common/model/filtro-lista";
import { MatTabGroup, MatDialog } from "@angular/material";
import { Subject, Operator, Subscription, forkJoin, Observable } from "rxjs";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { TemplateService } from "../../common/service/template.service";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { ListaService } from "../../common/service/lista.service";
import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import * as _ from 'lodash';
import { montarQueryPesquisaCampo, obterQueryBasePesquisaCampo } from "../utils/montarQueryPesquisaCampo";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";
import { Template } from "../../common/model/template";
import { isNull, isNullOrEmpty, isObjectEmpty } from "../../common/utils";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";
import { triggerAsyncId } from "async_hooks";
import { TemplateItemListaValor } from "../../common/model/template-item-lista-valor";
import { FiltroPesquisaElastic } from "../../common/model/filtro-pesquisa-elastic";
import { toElasticSearchName } from "../../common/utils/toElasticSearchName";
import { ExcelService } from "../../common/service/excel.service";
import { Lista } from "../../common/model/lista";
import { FiltroQuery } from "../../common/model/filtro-query";
import { ESBFiltroStatusRegistroLista, ESBuilderData } from "../../esbuilder/models";
import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
import { Estrategia } from "../../common/model/estrategia";
import { ModelToQuery } from "../../esbuilder/libs/utils";

@Component({
    selector: "app-pesquisa-estrategia",
    templateUrl: "./pesquisa-estrategia.component.html",
    styleUrls: ["./pesquisa-estrategia.component.css"],
    providers: [
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ListaService, provide: ListaService },
        { useClass: ExcelService, provide: ExcelService },
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})

export class PesquisaEstrategiaComponent implements OnInit {
    public preferenciasUsuario: PreferenciasUsuario;
    public filtro: any = { 'carteiraId': null, 'template': null, 'tipo': null, 'valor': null, 'lista': null };
    public listaTemplateItensCollectionOriginal = [];
    public listaTemplateItensCollection = [];
    public estrategiaCollection:Array<Estrategia> = [];
    public listaCollection = [];
    public abaVisivel: any;
    public addFiltro: boolean = false;
    totalEstrategia: number = 0;
    totalLista: number = 0;
    carteiras: Array<Carteira> = [];
    templates: Array<Template> = [];
    submited: boolean = false;
    listaId: number| null;
    listaNome: string|null;
    filtroQuery: FiltroQuery;
    listas: Lista[];

    query: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
    dataRules: ESBuilderData = new ESBuilderData();
    finishedLoading: boolean = false;
    
    public get filtroDefault(): string {
        return '{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}';
    }

    public get filtroRegraDefault(): string {
        return '[]';
    }



    @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
    constructor(private carteiraService: CarteiraService,
        private templateService: TemplateService,
        private estrategiaService: EstrategiaService,
        private preferenciasService: PreferenciasUsuarioService,
        private listaService: ListaService,
        private excelService: ExcelService,
        private searchService: ElasticsearchRepositoryService,
        public dialog: MatDialog) {

        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

        this.obterCarteiras();
    }

    ngOnInit() { }

    //#region [ Eventos ]

    carteiraChange() {
        this.filtro.template = null;
        this.listaId = null;
        this.listaNome = null;
        this.filtro.lista = null;
        this.limparObjetos();
        this.obterLista();
    }

    listaChange() {
        var listaSelecionada = this.listas.filter((lista: Lista) => lista.listaId == this.filtro.lista);

        if (listaSelecionada.length > 0) {
            this.listaId = listaSelecionada[0].listaId;
            this.listaNome = listaSelecionada[0].alias;
            this.filtro.template = listaSelecionada[0].listaTemplateId;
            this.filtro.lista = listaSelecionada[0].listaId;
            this.addFiltro = true;
        }
        else
        {
            this.listaId = null;
            this.listaNome = null;
            this.filtro.template = null;
            this.filtro.lista = null;
            this.filtroQuery = new FiltroQuery();
            this.addFiltro = false;
        }

        this.criarObjetos();
        this.finishedLoading = true;

    }

    criarObjetos()
    {
        this.filtroQuery = new FiltroQuery();
        this.filtroQuery.listaId =  this.listaId;
        this.filtroQuery.filtro = this.filtroDefault;
        this.filtroQuery.filtroRegras = this.filtroRegraDefault;
        this.query = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
        this.dataRules = new ESBuilderData();
        this.dataRules.includeIntegratedRegister = ESBFiltroStatusRegistroLista.total;
    }

    obterLista() {
        this.listaService.obterListaSemQuantidade(this.filtro).subscribe(listas => {
            
            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                var listaIdPreferencia = this.preferenciasUsuario.listaId as number == 0 ?
                    null : this.preferenciasUsuario.listaId as number;
                this.listas = listas.sort((a,b) => {
                    if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                        return -1;
                    }
                    if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                        return 1;
                    }
                    return 0;
                });
                if (listaIdPreferencia != null) {
                    var listaPreferencia = this.listas.filter((lista: Lista) => lista.listaId == listaIdPreferencia);
                    if (listaPreferencia.length > 0) {
                        this.filtro.lista = listaPreferencia[0].listaId;
                        this.listaId = listaPreferencia[0].listaId;
                        this.listaNome = listaPreferencia[0].alias;
                        this.filtro.template = listaPreferencia[0].listaTemplateId;
                        this.addFiltro = true;
                    }
                }

                
                this.criarObjetos();
            }
        });
    }

    limparObjetos() {
        this.estrategiaCollection = [];
        this.listaCollection = [];
        this.filtro.tipo = null;
        this.addFiltro = this.filtro.template ? true : false;
        this.submited = false;
    }

    //#endregion

    //#region [ Consultar dados basicos ]

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;

            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.filtro.carteiraId = padrao.carteiraId;
            }

            this.obterLista();
        });
    }

    //#endregion

    pesquisar() {
        this.totalLista = 0;
        this.totalEstrategia = 0;
        let filtroLista = new FiltroLista();
        filtroLista.status = '3';
        filtroLista.template = this.filtro.template;
        filtroLista.carteiraId = this.filtro.carteiraId;

        let p1 = this.estrategiaService.obterEstrategias({ 'ativo': 1, 'carteiraId': this.filtro.carteiraId, 'templateId': this.filtro.template });
        let p2 = this.listaService.obterListaSemQuantidade(filtroLista);

        forkJoin([p1, p2]).subscribe((results: any) => {
            this.estrategiaCollection = results[0];
            this.listaCollection = results[1];

            this.verificarListas();
            this.verificarEstrategias();
            this.tabGroup.selectedIndex = 0;
            this.submited = true;
        });

    }

    desativarPesquisa() {
        if (!isNull(this.dataRules.rules) && this.dataRules.rules.length > 0)
            return false;
        else
            return true;
    }

    updateQuery(event: any) {
        
        if (!isNullOrEmpty(event.query)) {
            this.query = event.query;
            this.filtroQuery.filtro = JSON.stringify(event.query);
        }

        if (!isNullOrEmpty(event.dataRules)) {
            this.dataRules = event.dataRules;
            this.filtroQuery.filtroRegras = JSON.stringify(event.dataRules);
        }

    }

    quantityListChanged(event: any) { }


    verificarEstrategias() {
        this.limpaTotalEstrategias();

        this.estrategiaCollection.forEach(estrat => {
            let newDataRules = ESBuilderData.fromRaw(JSON.parse(estrat.filtroRegras));
            newDataRules.rules = [... newDataRules.rules, ...this.dataRules.rules];

            this.searchService.getQuantidadeRegistros(estrat.listaId.toString(), ModelToQuery.convert(newDataRules)).subscribe((result:any) => {
                estrat.total = result;
                this.totalEstrategia += estrat.total;
            });

        });

    }

    verificarListas() {
        this.limpaTotalListas();

        this.listaCollection.forEach(list => {

            list.total = 0;

            this.searchService.getQuantidadeRegistros(list.listaId, ModelToQuery.convert(this.dataRules)).subscribe((result:any) => {
                list.total = result;
                this.totalLista += list.total;
            });
        });

    }

    exportarExcelEstrategia() {
        this.excelService.exportAsExcelFile(this.estrategiaCollection.map(result => {
            return { 'Estrategia':result.nome,
                     'Lista': result.displayNomeLista,
                     'Resultado': result.total> 0 ? 'Encontrado':'Não Encontrado',
                     'Quantidade':result.total} 
        
        }), 'Pesquisa Estratégia');
    }

    exportarExcelLista() {
        this.excelService.exportAsExcelFile(this.listaCollection.map(result => {
            return { 'Lista':result.nome,
                     'Resultado': result.total> 0 ? 'Encontrado':'Não Encontrado',
                     'Quantidade':result.total} 
        
        }), 'Pesquisa Lista');
    }

    obterItem(listaTemplateItemId) {
        return this.listaTemplateItensCollectionOriginal.find(f => f.listaTemplateItemId == listaTemplateItemId);
    }

    obterTemplateItem(listaTemplateItemId) {
        let item = this.obterItem(listaTemplateItemId);
        return (item == null) ? '' : (item).templateNome;
    }

    obterTipoItem(listaTemplateItemId) {
        let item = this.obterItem(listaTemplateItemId);
        return (item == null) ? '' : (item).tipoDado;
    }

    mostrarPreview(lista: any) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.templateId,
                lista: lista.nome,
                listaId: lista.listaId,
                query: lista.filtro
            }
        });
    }

    limpar() {
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro = { 'carteiraId': padrao.carteiraId, 'template': null, 'tipo': null, 'valor': null };

        this.estrategiaCollection = [];
        this.listaCollection = [];

        this.addFiltro = false;
        this.submited = false;

        this.limpaTotalEstrategias();
        this.limpaTotalListas();
    }


    isNumber(value: string | number): boolean {
        return ((value != null) && !isNaN(Number(value.toString())));
    }

    getEstrategiasOrdernado() {
        return _.sortBy(this.estrategiaCollection, "total").reverse();
    }

    getListasOrdernado() {
        return _.sortBy(this.listaCollection, "total").reverse();
    }

    limpaTotalEstrategias() {
        this.estrategiaCollection.forEach(item => { item.total = undefined; });
    }

    limpaTotalListas() {
        this.listaCollection.forEach(item => { item.total = undefined; });
    }

    customSearchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.value.toString().toLocaleLowerCase().indexOf(term) > -1 || item.label.toString().toLocaleLowerCase().indexOf(term) > -1;
    }
}
