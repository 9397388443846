import { FluxoProcesso, FluxoProcessoTipo } from './fluxo-processo';
import { TipoEstrategia } from './estrategia';
const validate = require("validate.js");

export class Fluxo {
    fluxoId: number;
    nome: string;
    listaId: number;
    fluxoTipoId: FluxoTipo;
    lista: FluxoLista;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;
    fluxoProcesso: Array<FluxoProcesso>;

    posicoes: number = 0;
    nomeUsuarioCadastro: string;

    private get noValidacao() {
        let fp = this.fluxoProcesso.filter((f: FluxoProcesso) => (f.fluxoProcessoTipoId != FluxoProcessoTipo.database));
        return fp;
    }

    constructor() {

        validate.validators.dataMenorQue = function (value, options, key, attributes) {
            if (value >= attributes[options.campo]) return "dataMaior";
        };

        validate.validators.dataMaiorQue = function (value, options, key, attributes) {
            if (value <= attributes[options.campo]) return "dataMenor";
        };

        validate.validators.fluxoArray = (items: Array<any>, validationRules: any) => {

            // Regras de validação da criação da estratégia
            let regrasCriacaoEstrategia = (item: any) => {
                let validacao: any = {
                    nome: { presence: { allowEmpty: false, message: "^obrigatorio" } },
                    listaId: { presence: { allowEmpty: false, message: "^obrigatorio" } },
                    providerId: { presence: { allowEmpty: false, message: "^obrigatorio" } },
                    estrategiaAcaoCampanhaTipoId: { presence: { allowEmpty: false, message: "^obrigatorio" } },
                    quantidadeMaximaExportacaoPct: { presence: { allowEmpty: false, message: "^obrigatorio" } },
                    quantidadeMaximaExportacao: {
                        presence: { allowEmpty: false, message: '^campoObrigatorio' },
                        numericality: { onlyInteger: true, greaterThan: 0, message: "^menorque0" }
                    }
                };

                if (item.estrategia.recorrente) {

                    if (!item.estrategia.execucaoRecorrente) {
                        validacao.dataInicio = { presence: { allowEmpty: false, message: "^obrigatorio" } };
                        validacao.horaInicio = { presence: { allowEmpty: false, message: "^obrigatorio" } };

                    } else {
                        validacao.frequencia = { presence: { allowEmpty: false, message: "^obrigatorio" } };
                        validacao.dataInicio = { presence: { allowEmpty: false, message: "^obrigatorio" } };

                        if (item.estrategia.frequencia == '1' || item.estrategia.frequencia == '2')
                            validacao.intervalo = { presence: { allowEmpty: false, message: "^obrigatorio" } };
                        else
                            validacao.horaInicio = { presence: { allowEmpty: false, message: "^obrigatorio" } };

                        if (item.estrategia.comDataFim) {
                            validacao.dataTermino = {
                                presence: { allowEmpty: false, message: "^obrigatorio" },
                                dataMaiorQue: { campo: "^dataInicio" }
                            };
                        }
                    }
                }

                //console.log(item.estrategia);

                if (item.estrategia.listaEstrategiaTipoId != TipoEstrategia.arquivo)
                    validacao.campanhaId = { presence: { allowEmpty: false, message: "^obrigatorio" } };

                if (item.estrategia.existeTemplateExportacao)
                    validacao.listaTemplateExportacaoId = { presence: { allowEmpty: false, message: "^obrigatorio" } };

                return validacao;
            };

            let appyValidade = (item: any, validationRules: any, validationRulesStrategy: any) => {
                let error: any = validate(item, validationRules);
                let errorStrategy: any;

                if (validationRulesStrategy) {
                    errorStrategy = validate(item.estrategia, validationRulesStrategy);

                    if (errorStrategy) {
                        if (!error) error = {};
                        error.estrategia = errorStrategy;
                    }
                }

                if (!error) return;
                return { 'fluxoProcessoGuid': item.fluxoProcessoGuid, 'error': error };
            };

            const arrayItemErrors = items.filter(f => f.ativo).reduce((errors, item, index) => {

                let validationRulesObjectStrategy: any = null;

                switch (item.fluxoProcessoTipoId) {
                    case FluxoProcessoTipo.database:
                        break;
                    case FluxoProcessoTipo.decisao:
                        break;
                    case FluxoProcessoTipo.selecaoEstrategia:
                        break;
                    case FluxoProcessoTipo.quantidade:
                        break;
                    case FluxoProcessoTipo.filtro:
                        break;
                    case FluxoProcessoTipo.criacaoEstrategia:
                        validationRulesObjectStrategy = regrasCriacaoEstrategia(item);
                        break;
                    case FluxoProcessoTipo.informativoEstrategia:
                        break;
                }

                let error = appyValidade(item, validationRules, validationRulesObjectStrategy);
                if (error) errors[index] = error;
                return errors;
            }, {})

            return (Object.keys(arrayItemErrors).length === 0) ? null : { errors: arrayItemErrors };        // ES5
            //return (Object.entries(arrayItemErrors).length === 0) ? null : { errors: arrayItemErrors };   // ES6 diferente do keys
            //return this.isNull(arrayItemErrors) ? null : { errors: arrayItemErrors };
        };
    }


    validar() {
        let validacao: any = {
            nome: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
            listaId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } },
            fluxoTipoId: { presence: { allowEmpty: false, message: '^campoObrigatorio' } }
        };

        if (this.fluxoId > 0) {

            validacao.noValidacao = {
                fluxoArray: {
                    cor: { presence: { allowEmpty: false, message: "^obrigatorio" } }
                }
            };
        }

        return validate.validate(this, validacao);
    }

    static fromRaw(rawFluxo): Fluxo {
        return Object.assign(new Fluxo(), rawFluxo);
    }
}

export enum FluxoTipo {
    processo = 1,
    cascata = 2,
    circular = 3,
    regua = 4
}

export class FluxoLista {
    listaId: number;
    nome: string;
    quantidadeRegistros: number;
}