import { NgModule } from "@angular/core";
import { PreviewRetornoModalComponent } from "./historico-retorno/preview-retorno-modal/preview-retorno-modal.component";
import { HistoricoRetornoComponent } from "./historico-retorno/historico-retorno.component";
import { VisualizarExecucoesModalComponent } from "./historico-retorno/visualizar-execucoes-modal/visualizar-execucoes-modal.component";
import { MatButtonModule, 
         MatCardModule,
         MatCheckboxModule,
         MatDatepickerModule,
         MatDialogModule,
         MatDividerModule,
         MatExpansionModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatPaginatorModule,
         MatRadioModule,
         MatSelectModule,
         MatSlideToggleModule,
         MatTooltipModule,
         MatTabsModule } from "@angular/material";
import { AppCommonModule } from "../common/common.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TableModule } from "primeng/table";
import { MonitorRetornoComponent } from "./monitor-retorno/monitor-retorno.component";
import { FiltroSemRetornoComponent } from "./monitor-retorno/filtro-sem-retorno/filtro-sem-retorno.component";
import { FiltroErroProcessamentoComponent } from "./monitor-retorno/filtro-erro-processamento/filtro-erro-processamento.component";
import { ListaErroProcessamentoComponent } from "./monitor-retorno/lista-erro-processamento/lista-erro-processamento.component";
import { ListaSemRetornoComponent } from "./monitor-retorno/lista-sem-retorno/lista-sem-retorno.component";
import { DetalheErroProcessamentoComponent } from "./monitor-retorno/lista-erro-processamento/detalhe-erro-processamento/detalhe-erro-processamento.component";
import { DetalheSemRetornoComponent } from "./monitor-retorno/lista-sem-retorno/detalhe-sem-retorno/detalhe-sem-retorno.component";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        AppCommonModule,
        CommonModule,        
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatCardModule,
        MatRadioModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatListModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        FormsModule,
        TableModule,
        MatTabsModule,
        NgSelectModule
    ],
    providers: [
    ],
    declarations: [
        HistoricoRetornoComponent,
        PreviewRetornoModalComponent,
        VisualizarExecucoesModalComponent,
        MonitorRetornoComponent,
        ListaSemRetornoComponent,
        ListaErroProcessamentoComponent,
        FiltroSemRetornoComponent,
        DetalheSemRetornoComponent,
        FiltroErroProcessamentoComponent,
        DetalheErroProcessamentoComponent
    ],
    entryComponents: [
        PreviewRetornoModalComponent,
        DetalheSemRetornoComponent,
        DetalheErroProcessamentoComponent,
        VisualizarExecucoesModalComponent
    ]
})

export class RetornoModule { }