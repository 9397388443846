import swal from "sweetalert2";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";

import { UsuarioService } from "../../common/service/usuario.service";
import { ProviderService } from "../../common/service/provider.service";

import { Usuario, TipoUsuario } from "../../common/model/usuario";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-form-apikey",
  templateUrl: "./form-apikey.component.html",
  styleUrls: ["../style.scss"],
  providers: [
    { useClass: UsuarioService, provide: UsuarioService },
    { useClass: ProviderService, provide: ProviderService }
  ]
})

export class FormApiKeyComponent {
  usuario: Usuario;
  submited: boolean;
  isEdit: boolean = false;
  possuiLista: boolean = true;
  trocarSenha: boolean = false;

  public get error() {
    if (this.submited)
      return this.usuario.validate();
  }

  constructor(
    public dialog: MatDialog,
    private usuarioService: UsuarioService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.usuario = new Usuario();
    this.usuario.usuarioTipoId = 1;

    this.route.params.subscribe(params => {
      if (!params.id) return;

      this.isEdit = true;
      this.usuarioService
        .obterUsuario(params.id)
        .subscribe(usuario => (this.usuario = usuario));
    });
  }

  salvar() {
    this.translate.get(['telaUsuario.apikey', 'telaPadrao.sucessoSalvar', 'telaPadrao.erroSalvar', 'telaUsuario.sucessoApiKey']).subscribe(async (res: Object) => {
      const sucessoCriar = (apikey: Usuario) => {
        swal.fire({
          title: res['telaUsuario.apikey'],
          icon: 'success',
          html:
            res['telaUsuario.sucessoApiKey'] +
            '<span style="margin: 8px 0; display: block; border: solid 1px #bababa; background-color: #dcdcdc; border-radius: 10px; padding: 8px; font-size: 22px;">' +
            apikey.login +
            '</span>',
          showCloseButton: true,
        });
        this.router.navigate(["/app/usuario"]);
      }
      const sucessoAtualizar = () => {
        swal.fire(res['telaUsuario.apikey'], res['telaPadrao.sucessoSalvar'], "success");
        this.router.navigate(["/app/usuario"]);
      };
      const erro = () => swal.fire(res['telaUsuario.apikey'], res['telaPadrao.erroSalvar'], "error");

      if (!this.usuario.usuarioId)
        return this.usuarioService.criarApiKey(this.usuario).subscribe(sucessoCriar, erro);
      this.usuarioService.atualizar(this.usuario).subscribe(sucessoAtualizar, erro);
    });
  }
}
