import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NNotificacaoTipoEnvio } from "../../../common/model/nnotificacao.index";

@Pipe({ name: "notificacaoTipoEnvio" })
export class NotificacaoTipoEnvioPipe implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(value: NNotificacaoTipoEnvio, color: boolean = false, args?: any): any {

        switch (value) {
            case NNotificacaoTipoEnvio.Api:
                return (color) ? '#2196f3' : this.translate.instant('telaNNotificacao.enviosTipo.api');
            // case NNotificacaoTipoEnvio.Database:
            //     return (color) ? '#c0851f' : this.translate.instant('telaNNotificacao.enviosTipo.database');
            case NNotificacaoTipoEnvio.Email:
                return (color) ? '#ff5bb0' : this.translate.instant('telaNNotificacao.enviosTipo.email');
            case NNotificacaoTipoEnvio.RabbitMQ:
                return (color) ? '#df9800' : this.translate.instant('telaNNotificacao.enviosTipo.rabbitMQ');
            case NNotificacaoTipoEnvio.WhatsApp:
                return (color) ? '#40bb16' : this.translate.instant('telaNNotificacao.enviosTipo.whatsapp');
            case NNotificacaoTipoEnvio.Teams:
                return (color) ? '#7852d9' : this.translate.instant('telaNNotificacao.enviosTipo.teams');
            case NNotificacaoTipoEnvio.Telegram:
                return (color) ? '#55ace6' : this.translate.instant('telaNNotificacao.enviosTipo.telegram');
            case NNotificacaoTipoEnvio.Slack:
                return (color) ? '#00c1b6' : this.translate.instant('telaNNotificacao.enviosTipo.slack');
            default:
                return '';
        }
    }
}