import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";
import { convertToQueryString } from "../../common/service/query-string";

@Injectable()
export class DashEstrategiasService {
    private api: string;

    constructor(private httpClient: HttpClient) { 
        this.api = '/api/dashboardestrategias';
    }

    public obterDadosEstrategiasMaisExportadasEmQuantidade(filtro: any) {
        return this.httpClient.get<Array<any>>(
            `${this.api}/estrategias-mais-exportadas-quantidade${convertToQueryString(filtro)}`
        );
    }

    public obterDadosEstrategiasMaisExportadasEmVezes(filtro: any) {
        return this.httpClient.get<Array<any>>(
            `${this.api}/estrategias-mais-exportadas-vezes${convertToQueryString(filtro)}`
        );
    }

    public obterDadosEstrategiasCampeas(filtro: any) {
        return this.httpClient.get<Array<any>>(
            `${this.api}/estrategias-campeas${convertToQueryString(filtro)}`
        );
    }

    public obterDadosIndicadoresEstrategias(filtro: any) {
        return this.httpClient.get<Array<any>>(
            `${this.api}/indicadores-estrategias${convertToQueryString(filtro)}`
        );
    }

    public obterDadosEstrategiasMenosExportadasEmVezes(filtro: any) {
        return this.httpClient.get<Array<any>>(
            `${this.api}/estrategias-menos-exportadas-vezes${convertToQueryString(filtro)}`
        );
    }

    public obterDadosEstrategiasMaisRetornosQuantidade(filtro: any) {
        return this.httpClient.get<Array<any>>(
            `${this.api}/estrategias-mais-retornos-quantidade${convertToQueryString(filtro)}`
        );
    }

    public definirIdiomaCharts(currentLang: string) {
        //let translateService = ServicesModule.injector.get(TranslateService);

        switch (currentLang) {
            case 'pt':
                return am4lang_pt_BR;
            case 'en':
                return am4lang_en_US;
            case 'es':
                return am4lang_es_ES;
            default:
                return am4lang_pt_BR;
        }
    }
}
