import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';
import { isNull } from "../../common/utils";

import { Fluxo, FluxoTipo } from "../../common/model/fluxo";
import { Lista } from "../../common/model/lista";
import { FiltroLista } from '../../common/model/filtro-lista';

import { FluxoService } from "../../common/service/fluxo.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroQueryService } from "../../common/service/filtro-query.service";
import { FiltroQuery } from "../../common/model/filtro-query";
import { CarteiraService } from "../../common/service/carteira.service";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";
import { Carteira } from "../../common/model/carteira";
import { FiltroQueryPadraoAplicarComponent } from "../../filtro-query-padrao/filtro-query-padrao-aplicar/filtro-query-padrao-aplicar.component";
import { ListaPrioridadePadraodService } from "../../common/service/lista-prioridade-padrao.service";
import { ListaPrioridadePadrao } from "../../common/model/lista-prioridade-padrao";




@Component({
	selector: 'app-lista-ordenacao-padrao',
	templateUrl: './lista-ordenacao-padrao.component.html',
	styleUrls: ['./lista-ordenacao-padrao.component.scss'],
	providers: [
		{ useClass: ListaPrioridadePadraodService, provide: ListaPrioridadePadraodService },
		{ provide: CarteiraService, useClass: CarteiraService },
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ListaOrdenacaoPadraoComponent implements OnInit {
	debug: boolean = false;
	preferenciasUsuario: PreferenciasUsuario;
	carteiras: Array<Carteira> = [];
	listaPrioridades: Array<ListaPrioridadePadrao> = [];
	listas: Array<Lista>;
	tipos: Array<any>;
	filtro: { 'nome': string, 'carteiraId': number | null, 'listaId': string } = { 'nome': '', 'carteiraId': null, 'listaId': '' };


	@ViewChild(DataView, { static: false }) dataTable: DataView;
	constructor(
		private listaPrioridadePadraodService: ListaPrioridadePadraodService,
		private carteiraService: CarteiraService,
		private preferenciasService: PreferenciasUsuarioService,
		private listaService: ListaService,
		private route: ActivatedRoute,
		private customAlertService: CustomAlertService,
		public dialog: MatDialog
	) {
		// Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

		this.route.queryParams.subscribe(params => {
			if (params.debug != undefined)
				this.debug = (params.debug === 'true');
		});
	}

	ngOnInit() {


		this.carteiraService.obterCarteiras().subscribe(carteiras => {

			this.carteiras = carteiras.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
		})

			if (this.preferenciasUsuario.existePreferenciaCarteira) {
				this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
			} else {
				let padrao = this.carteiras.find(f => f.padrao) as Carteira;
				this.filtro.carteiraId = padrao.carteiraId;
			}

			this.obterListas();
			this.filtrar();
		});
	}

	filtrar() {
		this.listaPrioridadePadraodService.obterListasPrioridadePadrao(this.filtro).subscribe(data => {

			this.listaPrioridades = data
		});
	}

	obterListas() {
		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = true;
		this.filtro.listaId = "0";

		if (!isNull(this.filtro.carteiraId)) {
			if (this.preferenciasUsuario.existePreferenciaLista)
				this.filtro.listaId = String(this.preferenciasUsuario.listaId)
		}

		filtroLista.carteiraId = this.filtro.carteiraId as number;
		this.listaService.obterListaSemQuantidade(filtroLista).subscribe(listas => 
			{this.listas = listas.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
		})});
	}

	carteiraChange() {
		this.obterListas();
	}

	limpar() {
		this.filtro = { 'nome': '', 'carteiraId': 0, 'listaId': '' };
	}

	excluir(id: number) {
		let erro = () => {
			this.customAlertService.show("telaPadrao.erroSalvar", "telaPrioridadePadrao.estrategiasVinculadas", "error");
		};

		let sucesso = (response: any) => {
			this.filtrar();
		};

		this.customAlertService
			.confirmationMessage("telaPrioridadePadrao.desativar")
			.then(() => {
				this.listaPrioridadePadraodService.desativar(id).subscribe(sucesso, erro);
			});
	}

	modalFiltroPadraoAplicar(filtroQuery: FiltroQuery) {
		const dialogRef = this.dialog.open(FiltroQueryPadraoAplicarComponent, {
			"hasBackdrop": true,
			"width": "1100px",
			"height": "540px",
			"data": {
				"listaId": filtroQuery.listaId,
				"filtroQuery": filtroQuery,
				"debug": this.debug
			}
		});

		dialogRef.afterClosed().subscribe((filtroQuery: FiltroQuery) => {
			if (isNull(filtroQuery))
				return;
		});
	}
}