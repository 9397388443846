import { Component, Inject, Input } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FiltroPorTipo } from "../../model/filtros";
import { getFilterTypeByName } from "../../model/elasticsearch-filter";
import { formatFieldName } from "../../model/elasticsearch-field-formarter";

import { TemplateService } from "../../../common/service/template.service";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ElasticsearchRepositoryService } from "../../repository/elasticsearch-repository.service";
import { PreviewDadosEstrategiaComponent } from "../../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { ExpressaoRegex } from "../../../common/model/expressao-regex";
import { RegexElasticsearchFilter } from "../../model/elasticsearch-filter/regex";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { isNullOrEmpty } from "../../../common/utils"

@Component({
    selector: "app-elasticsearch-add-filter",
    templateUrl: "./elasticsearch-add-filter.component.html",
    styleUrls: ["./elasticsearch-add-filter.component.scss"],
    providers: [
        { useClass: TemplateService, provide: TemplateService },
        {
            useClass: ElasticsearchRepositoryService,
            provide: ElasticsearchRepositoryService
        },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ElasticsearchAddFilterComponent {
    templateId;
    listaId;
    camposMapeados: any[];
    _campoSelecionado;
    _query: any;
    _nomeCampoSelecionado: string;
    disponivel: any = 0;
    queryInicial: any;
    isEdit: boolean;
    queryAnterior: any;
    _parameters: any = {};
    setFocus: boolean = true;
    encontrado: number;
    atualizarValor: boolean = false;

    exibirAplicarESalvar: boolean = false;


    public get parameters() {
        return this._parameters;
    }

    public set parameters(parameters) {
        //Todo: Identificar causa raiz
        if (parameters == "on") return;
        this._parameters = parameters;

    }

    public get query() {

        if ((this._parameters && Object.keys(this._parameters).length) && (this._parameters && this._parameters.query != "") || this.ehTipoDadoBoolean) {
            this.habilitaAplicar = true;
        }
        else
            this.habilitaAplicar = false;

        return this._query;
    }

    public set query(query) {
        this._query = query;
    }

    _nomeFiltroSelecionado: string;
    listaOpcoes = [];
    listaExpressaoRegex = new Array<ExpressaoRegex>();
    listaExpressaoExpressaoSelecao: any;
    opcoesEscolhidas = [];
    index: any;
    filtros: Array<any> = [];
    listaFiltro: Array<any> = [];
    habilitaAplicar = false;
    nomeLista: string;

    constructor(
        private ref: MatDialogRef<ElasticsearchAddFilterComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private templateService: TemplateService,
        private elasticsearchRepositoryService: ElasticsearchRepositoryService,
        private customAlertService: CustomAlertService,
        public dialog: MatDialog

    ) {

        this.exibirAplicarESalvar = data.exibirAplicarESalvar;
        this.templateId = data.templateId;
        this.listaId = data.listaId;
        this.index = data.index;
        this.queryInicial = data.query;
        this.nomeLista = data.nomeLista;
        this.query = Object.assign({}, data.query);
        this.queryAnterior = data.queryAnterior;
        this.listaExpressaoRegex = data.listaExpressaoRegex;
        this.templateService
            .obterCamposMapeados(this.templateId, this.listaId)
            .subscribe(camposMapeados => {
                this.camposMapeados = camposMapeados.filter(f => f.itemTipo != 2).map(campo =>
                    Object.assign({}, campo, {
                        alias: formatFieldName(campo.nome)
                    })
                );
                if (!data.isEdit) return;
                this.nomeCampoSelecionado = data.nomeCampoSelecionado;
                this.nomeFiltroSelecionado = data.nomeFiltroSelecionado;

                if (this.ehTipoDadoBoolean && data.parameters.query == undefined) {
                    this.parameters = { query: data.parameters }
                } else {
                    if (this.ehTipoDadoLista || (this.ehTipoDadoNested && !this.ehTipoDadoNested_Data)) {
                        this.parameters = [];
                        Object.keys(data.parameters).forEach(k => { this.parameters.push(data.parameters[k]); });
                    }
                    else {
                        this.parameters = data.parameters;
                    }
                }

                this.isEdit = data.isEdit;
            });
    }

    public get nomeCampoSelecionado(): string {
        return this._nomeCampoSelecionado;
    }

    public set nomeCampoSelecionado(nome: string) {
        this._nomeCampoSelecionado = nome;
        if (this.campoSelecionado) {

            if (this.campoSelecionado.tipoDado != 'Nested')
                this.filtros = FiltroPorTipo[this.campoSelecionado.tipoDado];
            else
                this.filtros = FiltroPorTipo[this.campoSelecionado.tipoDado].filter((f: any) => f.nestedType == this.campoSelecionado.tipoDadoNested);
            // if (this.listaExpressaoRegex.filter(f => f.tipoDado == this.campoSelecionado.tipoDado).length > 0)
            // 	this.adicionarOpcaoRegex();
        }
        this.parameters = {};
        this.nomeFiltroSelecionado = undefined;

        // if (!this.ehTipoDadoLista && !this.ehTipoDadoNested) return;
        // if (this.ehTipoDadoNested && this.campoSelecionado.listaNested === false) return;
        // this.obterListaOpcoes();
    }

    public get nomeFiltroSelecionado(): string {
        return this._nomeFiltroSelecionado;
    }

    public set nomeFiltroSelecionado(nomeFiltroSelecionado: string) {
        this._nomeFiltroSelecionado = nomeFiltroSelecionado;
        this.parameters = {};

        if (this.ehTipoDadoBoolean && (nomeFiltroSelecionado == "Sim" || nomeFiltroSelecionado == "Não"))
            this.parameters[this.filtroSelecionado.parameters[0].name] = (nomeFiltroSelecionado == "Sim");

        if (!this.ehTipoDadoLista && !this.ehTipoDadoNested) return;
        if (this.ehTipoDadoNested && this.campoSelecionado.listaNested === false) return;
        if (isNullOrEmpty(nomeFiltroSelecionado)) return;
        this.obterListaOpcoes();
    }

    public get campoSelecionado() {
        if (this.camposMapeados == null) return;
        return this.camposMapeados.find(c => c.nome == this.nomeCampoSelecionado);
    }

    public get filtroSelecionado() {
        return this.filtros.find(f => f.name == this.nomeFiltroSelecionado);
    }

    public get filtroTipoCampo() {
        if (!this.nomeFiltroSelecionado) return;

        let excpt = ['Existe', 'Não Existe', 'Existe (retorno)', 'Não Existe (retorno)', 'Vazio', 'Não Vazio'];
        return excpt.some((s: string) => s == this.nomeFiltroSelecionado);
    }

    public get ehTipoDadoLista(): boolean {
        return this.campoSelecionado && this.campoSelecionado.tipoDado == "Lista";
    }

    public get ehTipoDadoNested(): boolean {
        return this.campoSelecionado && this.campoSelecionado.tipoDado == "Nested";
    }
    public get ehTipoDadoNested_Data(): boolean {
        return this.campoSelecionado && this.campoSelecionado.tipoDado == "Nested" && this.campoSelecionado.tipoDadoNested == "Data";
    }
    public get ehListaNested(): boolean {
        return this.campoSelecionado && this.campoSelecionado.tipoDado == "Nested" && this.campoSelecionado.listaNested === true;
    }


    public get ehTipoDadoBoolean(): boolean {
        return this.campoSelecionado && this.campoSelecionado.tipoDado == "Boolean";
    }

    public get ehTipoDadoNumerico(): boolean {
        return (
            this.campoSelecionado && this.campoSelecionado.tipoDado == "Numerico"
        );
    }

    public get ehFiltroRegex(): boolean {
        if (!this.nomeFiltroSelecionado) return false;

        let filtro = this.filtros.find(f => f.name == this.nomeFiltroSelecionado);

        return filtro && filtro.regex;
    }

    mostrarPreview(query: any) {
        if (query == undefined) query = this.query;
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: this.templateId,
                lista: this.nomeLista,
                listaId: this.listaId,
                query: {
                    query: this._query,
                    size: 10
                }
            }
        }
        );
    }

    adicionarOpcaoRegex() {
        this.listaExpressaoExpressaoSelecao = this.listaExpressaoRegex
            .filter(f => f.tipoDado == this.campoSelecionado.tipoDado)
            .map(m => {
                return {
                    value: m.expressao,
                    label: m.nome
                };
            });

        if (this.filtros.filter(f => f.regex).length > 0) return;

        this.filtros.push({
            name: "Aplicar validação",
            content: "regexp",
            regex: true,
            negation: false,
            filter: RegexElasticsearchFilter,
            parameters: [{ name: "query", alias: "Válidos" }]
        });
    }

    obterListaOpcoes(): any {
        var format = new Intl.NumberFormat(navigator.language);
        this.elasticsearchRepositoryService
            .getOptionForField(
                this.index,
                this.listaId,
                this.templateId,
                formatFieldName(this.campoSelecionado.nome),
                this.data.isEdit ? this.queryAnterior : this.query,
                this.filtroSelecionado.name == "Não contém"
            )
            .subscribe(collection => {
                if (Array.isArray(this.parameters) == false) this.parameters = [];

                this.listaOpcoes = Object.keys(collection).map(label => ({
                    label: label.replace(/_/g, " "),
                    value: label,
                    suffix: format.format(collection[label])
                }));
            });
    }

    atualizarEncontrado() {

        if (this.isEdit)
            this.atualizarValor = true;
        else {
            this.query = this.queryInicial;
            this.atualizarValor = false;
        }

        this.encontrado = this.disponivel;
    }

    adicionarItemLista() {
        return this.parameters != undefined && (Object.keys(this.parameters).length > 0 ||
            (this.parameters.query != undefined && this.parameters.query != "") ||
            this.filtroTipoCampo || this.parameters.length > 0)
    }

    fechar() {
        if (!this.adicionarItemLista()) {
            this.finalizar(true);
        }
        else {
            this.customAlertService
                .confirmationMessage("telaEstrategia.SalvarFiltros")
                .then(() => {
                    this.ref.close({ 'cancelado': true });
                }).catch(() => { });
        }
    }

    parseNumericValues() {
        if (this.ehTipoDadoNumerico && !this.ehFiltroRegex) {
            Object.keys(this.parameters).forEach(
                key =>
                (this.parameters[key] = parseFloat(
                    this.parameters[key].replace(",", ".")
                ))
            );
        }
    }

    obterDadosElastic() {
        return {
            campoSelecionado: this.campoSelecionado,
            filtroSelecionado: this.filtroSelecionado,
            // filtroSelecionado: !this.ehTipoDadoBoolean
            // 	? this.filtroSelecionado
            // 	: this.filtros[0],
            parameters: this.parameters,
            extra: this.getExtra()
        };
    }

    getExtra() {
        if (this.ehFiltroRegex)
            return this.listaExpressaoRegex.find(f => f.expressao == this.parameters)
                .nome;

        return Array.isArray(this.parameters)
            ? this.listaOpcoes
                .filter(opcao => this.parameters.indexOf(opcao.value) == -1)
                .map(opcao => opcao.value)
            : null;
    }

    aplicarESalvar(fecharModal){
        this.adicionar(false);

        this.listaFiltro['aplicarESalvar'] = true;

        if (fecharModal) {
            this.ref.close(this.listaFiltro);
        }
    }

    finalizar(fecharModal) {
        this.adicionar(false);

        if (fecharModal) {
            this.ref.close(this.listaFiltro);
        }
    }

    adicionar(mostrarMensagem) {
        if (this.data.isEdit) {
            if (Array.isArray(this.data.parameters))
                this.data.parameters.splice(
                    0,
                    this.data.parameters.length,
                    ...this.parameters
                );
            this.listaFiltro.push(this.obterDadosElastic());

            return;
        }

        if (this.adicionarItemLista()) {
            this.parseNumericValues();
            this.listaFiltro.push(this.obterDadosElastic());
        }

        if (mostrarMensagem) {
            let filter = this.convertQueryToFilterType();
            if (!filter.negation) {
                this.queryInicial.bool.must.push(filter.convertedFilter);
                this.queryAnterior.bool.must.push(filter.convertedFilter);
            } else {
                this.queryInicial.bool.must_not.push(filter.convertedFilter);
                this.queryAnterior.bool.must_not.push(filter.convertedFilter);
            }
            // this.queryInicial.bool.must.push(filter.convertedFilter);
            // this.queryAnterior.bool.must.push(filter.convertedFilter);
            this.query = Object.assign({}, this.queryInicial);
            this.setFocus = false;

            this.limparFiltro();
            this.customAlertService.show(
                "telaEstrategia.filtro",
                "telaEstrategia.sucessoIncluir",
                "success"
            ).then(() => this.setFocus = true);

            this.atualizarValor = true
        }
    }

    limparFiltro() {
        this._nomeFiltroSelecionado = undefined;
        this._campoSelecionado = undefined;
        this._parameters = {};
        this._nomeCampoSelecionado = undefined;
        this.listaOpcoes = [];
        this.filtros = [];
    }

    buscarQuantidadeFiltro() {
        var qa = JSON.parse(JSON.stringify(this.queryAnterior));
        var query = Object.assign({}, qa, {
            bool: {
                must: [...qa.bool.must],
                must_not: qa.bool.must_not
                
            }
        });

        var filterType = this.convertQueryToFilterType();

        if (this.data.isEdit) {
            this.atualizarValor = false;

            if (!filterType.negation) {
                query.bool.must.push(filterType.convertedFilter);
            } else {
                query.bool.must_not.push(filterType.convertedFilter);
            }


        } else {
            //if (Array.isArray(filterType.parameters) && filterType.parameters.length > 0 || !Array.isArray(filterType.parameters)) {
            if (!filterType.negation)
                query.bool.must.push(filterType.convertedFilter);
            else
                query.bool.must_not.push(filterType.convertedFilter);
            //}
        }
        this.query = query;
    }

    convertQueryToFilterType() {
        var dadosElastic = this.obterDadosElastic();

        let keyword = (dadosElastic.campoSelecionado.tipoDado == "Lista" || dadosElastic.filtroSelecionado.keyword);

        var FilterType = this.ehTipoDadoBoolean
            ? getFilterTypeByName(dadosElastic.filtroSelecionado.content)
            : getFilterTypeByName(this.filtroSelecionado.content);

        return new FilterType(
            dadosElastic.campoSelecionado.alias,
            dadosElastic.filtroSelecionado.content,
            dadosElastic.parameters,
            dadosElastic.extra,
            keyword,
            this.filtroSelecionado.nestedContent,
            this.filtroSelecionado.nestedType,
            dadosElastic.campoSelecionado.listaNested,
            dadosElastic.filtroSelecionado.negation
        );
    }

    habilitarFinalizar() {
        return !this.habilitaAplicar && !this.filtroTipoCampo;
    }

    ngOnInit() {
        this.disponivel = this.data.disponivel;
    }
}
