import { Component, Inject, ViewChild, OnDestroy, DebugElement } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";
import { NaturalLanguage } from "../../common/model/natural-language";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { EsBuilderService } from "../libs/services/esbuilder.service";
import { ModelToQuery, QueryToModel } from "../libs/utils";

@Component({
    selector: "app-esbuilder-modal-ia",
    templateUrl: "./esbuilder-modal-ia.component.html",
    styleUrls: ["./esbuilder-modal-ia.component.scss"],
    providers: [
        { useClass: EsBuilderService, provide: EsBuilderService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class EsBuilderModalIAComponent {
    submited: boolean = false;
    errorConvertion: boolean = false;
    debug: boolean = false;
    naturalLanguage: NaturalLanguage;
    subNaturalLanguage: Subscription;

    public get error() {
        if (this.submited)
            return this.naturalLanguage.validate();
    }

    public liveError(property: string) {
        if(this.submited == false) return null;
        let validationResult = this.naturalLanguage.validate();
        if (!validationResult) return null;
        return validationResult[property] ? validationResult[property][0] : null;
    }

    constructor(
        private esBuilderService: EsBuilderService,
        private customAlertService: CustomAlertService,
        private ref: MatDialogRef<EsBuilderModalIAComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { listId: number, debug: boolean }
    ) {
        this.debug = data.debug;

        this.naturalLanguage = new NaturalLanguage();
        this.naturalLanguage.listId = data.listId;
        //this.naturalLanguage.condition = 'O campo inteiro precisa ser maior que 500 e o campo decimal menor que 200 e o campo lista contém que "A"';
    }

    apply() {
        this.submited = true;
        this.errorConvertion = false;

        if (this.naturalLanguage.validate()) {
            this.customAlertService.show("esBuilderComponent.filtroPorIA", "telaPadrao.camposInvalidos", "error");
            return;
        }

        this.subNaturalLanguage = this.esBuilderService.searchByNaturalLanguage(this.naturalLanguage).subscribe(
            (result: NaturalLanguage) => {
                try {

                    // //result.query="{\"query\":{\"bool\":{\"must\":[{\"range\":{\"campo_Inteiro\":{\"gt\":500}}},{\"range\":{\"campo_Decimal\":{\"lt\":200}}},{\"terms\":{\"campo_Lista.keyword\":["A"]}}]}}}
                    // result.query = "{\"query\":{\"bool\":{\"must\":[{\"range\":{\"campo_Inteiro\":{\"gt\":500}}},{\"range\":{\"campo_Decimal\":{\"lt\":200}}},{\"query_string\":{\"default_field\":\"campo_Lista\",\"query\":\"A\"}}]}}}";
                    // result.query = "{\"query\":{\"bool\":{\"must\":[{\"range\":{\"campo_Inteiro\":{\"gt\": 500}}},{\"range\":{\"campo_Decimal\":{\"lt\":200}}},{\"terms\":{\"campo_Lista\":[\"A\"]}}]}";


                    let dataRules = QueryToModel.convert(result.query, result.mappedFields, "[]");
                    let query = JSON.stringify(ModelToQuery.convert(dataRules));
                    this.ref.close({ 'query': query, 'dataRules': dataRules });
                }
                catch (error) {

                    if (this.debug) {
                        console.error(error);
                        console.info(result);
                    }
                    this.errorConvertion = true;
                }
            },
            (error: any) => {
                this.customAlertService.show("esBuilderComponent.filtroPorIA", error.error[0], "error");
            }
        );
    }

    close() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subNaturalLanguage)
            this.subNaturalLanguage.unsubscribe();
    }
}
