import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DownloadRegistroStatus } from "../../../common/model/download-registro";

@Pipe({ name: "statusDownloadRegistro" })
export class StatusDownloadRegistroPipe implements PipeTransform {

	constructor(private translate: TranslateService) { }

	transform(value: DownloadRegistroStatus, args?: any): any {
		switch (value) {
			case DownloadRegistroStatus.Pendente:
				return this.translate.instant('telaDownloadRegistro.statusDownload.pendente');
			case DownloadRegistroStatus.Processando:
				return this.translate.instant('telaDownloadRegistro.statusDownload.processando');
			case DownloadRegistroStatus.Concluido:
				return this.translate.instant('telaDownloadRegistro.statusDownload.concluido');
				case DownloadRegistroStatus.Erro:
					return this.translate.instant('telaDownloadRegistro.statusDownload.erro');				
			default:
				return "";
		}
	}
}