import swal from "sweetalert2";
import { Component, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UsuarioService } from "../../common/service/usuario.service";
import { Usuario } from "../../common/model/usuario";

@Component({
  selector: "app-filtro-apikey",
  templateUrl: "./filtro-apikey.component.html",
  styleUrls: ["../style.scss"],
  providers: []
})

export class FiltroApiKeyComponent {
  filtro: any = {};

  @Output()
  filtrar: EventEmitter<any> = new EventEmitter<any>();

  constructor(  ) { }

  filtrarUsuario() {
    this.filtrar.emit(this.filtro);
  }

  limpar() {
    this.filtro = {};
  }
}
