import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { LazyLoadEvent } from "primeng/api";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { NotificacaoService } from "../../common/service/notificacao.service";
import { NotificacaoEventoPipe } from "../libs/pipes/notificacao-evento-pipe"
import { NNotificacaoHistorico, NNotificacaoTipoEvento } from "../../common/model/nnotificacao.index";
import { QueryFilter, SorterContainer } from "../../common/service/query-filter.dto";
import { capitalizeFirstLetter } from "../../common/utils/string-utils";
import { isNull, isNullOrEmpty, isNullOrZero, isObjectEmpty } from "../../common/utils";
import { GuidService } from "../../common/service/guid.service";
import { DateFormatPipe } from "../../common/pipes/date-format.pipe";
import { error } from "jquery";

@Component({
    selector: "app-notificacao-historico",
    templateUrl: "./notificacao-historico.component.html",
    styleUrls: ["./notificacao-historico.component.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: TranslateService, provide: TranslateService },
        { useClass: NotificacaoEventoPipe, provide: NotificacaoEventoPipe },
        { useClass: DateFormatPipe, provide: DateFormatPipe }
    ]
})
export class NotificacaoHistoricoComponent implements OnInit {
    // Main object
    historicoSelecionado: NNotificacaoHistorico | null;

    notificacaoHistoricos: Array<NNotificacaoHistorico> = [];
    filtroPesquisa = {
        //notificacaoId: 0,
        //itemsPagina: 14,
        eventos: null,
        //pagina: 0
    };

    // Table properties
    first: number = 0;
    rows: number = 10;
    totalRecords: number = 0;
    loading: boolean = true;

    // Back-end filter
    identifier: string = "DataCadastro";
    direction: number = -1;
    queryFilter: QueryFilter;

    @Output()
    onAtualizarQuantidadeNaoLido: EventEmitter<any> = new EventEmitter();

    public get existeHistoricoSelecionado(): boolean {
        return (isNull(this.historicoSelecionado) == false);
    }

    exibirReportDestinatario(destinatario: any, campo: string): boolean {
        if (!isNull(destinatario.Report))
            return (!isNullOrEmpty(destinatario.Report[campo]));

        return false;
    }


    constructor(
        private notificacaoService: NotificacaoService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        private customAlertService: CustomAlertService,
        private dateFormatPipe: DateFormatPipe
    ) {
        this.onAtualizarQuantidadeNaoLido = new EventEmitter<any>();
    }

    ngOnInit() { }

    loadData(event: LazyLoadEvent) {
        this.loading = true;
        this.first = event.first;
        this.rows = event.rows;
        this.queryFilter = new QueryFilter();
        this.queryFilter.start = this.first;
        this.queryFilter.limit = this.rows;

        this.setSortProperties(event);

        this.buscar();
    }

    setSortProperties(event: LazyLoadEvent) {
        let sort: SorterContainer[] = [];

        if (event.sortField !== undefined) {
            sort = [{ property: capitalizeFirstLetter(event.sortField), direction: event.sortOrder.toString() }];
        }
        else {
            sort = [{ property: this.identifier, direction: this.direction.toString() }];
        }

        this.queryFilter.sort = sort;
    }

    obterNotificacaoHistorico(notificacaoId: number, eventos: number[]) {
        this.notificacaoHistoricos = [];
        //this.filtroPesquisa.notificacaoId = notificacaoId;
        this.filtroPesquisa.eventos = eventos;
        this.loading = true;
        this.buscar();
    }

    buscar() {
        let filter = { ...this.queryFilter, ...this.filtroPesquisa }

        // if (isNullOrZero(filter.notificacaoId)){
        //     this.loading = false;
        //     return;
        // }

        this.notificacaoService.obterNotificacaoHistorico(filter).subscribe((result: any) => {
            this.loading = false;
            this.notificacaoHistoricos = result.data.map((m: any) => NNotificacaoHistorico.fromRaw(m));
            this.totalRecords = result.total;
        }, (error: any) => {
            this.loading = false;
        });
    }

    definirHistoricoLido(historico: NNotificacaoHistorico) {

        if(!isNullOrEmpty(historico.dataLeitura))
            return;

        this.notificacaoService.definirHistoricoLido(historico.id).subscribe((result: any) => {
            this.notificacaoHistoricos.forEach((f: NNotificacaoHistorico) => {
                if (f.id == historico.id) {
                    if (!isNull(this.historicoSelecionado))
                        this.historicoSelecionado.dataLeitura = Object.assign(new Date(), result);

                    f.dataLeitura = Object.assign(new Date(), result);
                    this.onAtualizarQuantidadeNaoLido.emit()
                    return;
                }
            });
        }, (error: any) => { });
    }

    onRowSelect(event: any) {

        this.historicoSelecionado.mensagem = this.gerarMensagem();

        setTimeout(() => {
            this.definirHistoricoLido(this.historicoSelecionado);
        }, 1500)

    }
    onRowUnselect(event: any) {
        //this.historicoSelecionado.mensagem = '';
    }

    gerarMensagem() {

        let interpolacao = {
            'nn-201': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true),
                '3': this.historicoSelecionado.notificacaoSnapshot.Parametros.UsuarioDownload
            },
            'nn-301': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true)
            },
            'nn-401': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true),
                '3': this.historicoSelecionado.notificacaoSnapshot.Parametros.UsuarioAlteracao
            },
            'nn-501': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true),
                '3': this.historicoSelecionado.notificacaoSnapshot.Parametros.UsuarioAlteracao
            },
            'nn-601': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true)
            },
            'nn-701': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true),
                '3': this.historicoSelecionado.notificacaoSnapshot.Parametros.QuantidadeRegistrosEnviados
            },
            'nn-801': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true),
                '3': this.historicoSelecionado.notificacaoSnapshot.Parametros.UsuarioCancelamento
            },
            'nn-901': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true)
            },
            'nn-1001': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaEstrategiaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true)
            },
            'nn-1101': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true)
            },
            'nn-1201': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true),
                '3': this.historicoSelecionado.notificacaoSnapshot.Parametros.UsuarioAlteracao
            },
            'nn-1301': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true),
                '3': this.historicoSelecionado.notificacaoSnapshot.Parametros.UsuarioLimpeza
            },
            'nn-1401': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaNome,
            },
            'nn-1501': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaTemplateId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaTemplateNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true)
            },
            'nn-1601': {
                '0': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaTemplateId,
                '1': this.historicoSelecionado.notificacaoSnapshot.Parametros.ListaTemplateNome,
                '2': this.dateFormatPipe.transform(this.historicoSelecionado.notificacaoSnapshot.Parametros.Data, true),
                '3': this.historicoSelecionado.notificacaoSnapshot.Parametros.UsuarioAlteracao
            }
        };

        let mensagem: string = '';

        switch (this.historicoSelecionado.notificacaoTipoEventoId) {
            case NNotificacaoTipoEvento.BancoDadosOffLine:
                break
            case NNotificacaoTipoEvento.DownloadRegistro:
                break
            case NNotificacaoTipoEvento.EstrategiaCriada:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-301", interpolacao["nn-301"]);
                break
            case NNotificacaoTipoEvento.EstrategiaAlterada:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-401", interpolacao["nn-401"]);
                break
            case NNotificacaoTipoEvento.FiltroAlterado:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-501", interpolacao["nn-501"]);
                break
            case NNotificacaoTipoEvento.EstrategiaIniciada:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-601", interpolacao["nn-601"]) + "<br>"
                if (this.historicoSelecionado.notificacaoSnapshot.Parametros.PossuiOrdenacao)
                    mensagem += this.translate.instant("telaNNotificacao.notificacoes.nn-602");
                else
                    mensagem += this.translate.instant("telaNNotificacao.notificacoes.nn-603");
                break
            case NNotificacaoTipoEvento.EstrategiaFinalizada:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-701", interpolacao["nn-701"]);
                break
            case NNotificacaoTipoEvento.EstrategiaCancelada:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-801", interpolacao["nn-801"]);
                break
            case NNotificacaoTipoEvento.EstrategiaErro:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-901", interpolacao["nn-901"]);
                break
            case NNotificacaoTipoEvento.EstrategiaZerada:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-1001", interpolacao["nn-1001"]);
                break
            case NNotificacaoTipoEvento.ListaCriada:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-1101", interpolacao["nn-1101"]);
                break
            case NNotificacaoTipoEvento.ListaAlterada:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-1201", interpolacao["nn-1201"]);
                break
            case NNotificacaoTipoEvento.ListaLimpa:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-1301", interpolacao["nn-1301"]);
                break
            case NNotificacaoTipoEvento.SaudeIndice:
                break
            case NNotificacaoTipoEvento.TemplateImportacaoCriado:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-1501", interpolacao["nn-1501"]);
                break
            case NNotificacaoTipoEvento.TemplateImportacaoAlterado:
                mensagem = this.translate.instant("telaNNotificacao.notificacoes.nn-1601", interpolacao["nn-1601"]);
                break
            default:
                break
        }

        return mensagem;
    }


    public getOrdemFake(n: number): any {
        if (n == 1)
            return [{ "Operador": "Desc", "Campo": "CInteiro", "Valor": "", "Ordem": "0" }];
        else
            return [{ "EID1": "1", "ENome1": "Estratégia de teste 01", "Camp": "", "DataI": "26/09/2024 17:23:54", "TemO": "True", "Ordem": [{ "Operador": "Desc", "Campo": "CInteiro", "Valor": "", "Ordem": "0" }], "Qto": "" }];
    }


    defaultSettings() {
        this.filtroPesquisa = {
            //notificacaoId: 0,
            eventos: null
        };
    }
}