import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";

import { CustomAlertService } from "../../common/service/custom-alert.service";
import { FiltroQueryService } from "../../common/service/filtro-query.service";
import { ListaService } from "../../common/service/lista.service";
import { FiltroQuery } from "../../common/model/filtro-query";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { EstrategiaAcaoCampanhaTipo } from "../../common/model/situacao-execucao";
import { EstrategiaRefactor } from "../../common/model/estrategiaRefactor";
import MD5 from 'crypto-js/md5';
import { FiltroQueryEstrategia } from "../../common/model/filtro-query-estrategia";

@Component({
    selector: "app-filtro-query-padrao-modal",
    templateUrl: "./filtro-query-padrao-modal.component.html",
    styleUrls: ["./filtro-query-padrao-modal.component.scss"],
    providers: [
        { useClass: FiltroQueryService, provide: FiltroQueryService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class FiltroQueryPadraoModalComponent implements OnDestroy {


    debug: boolean = false;
    listaId: number;
    filtroQuery: FiltroQuery;

    estrategias: EstrategiaRefactor[] = [];
    estrategiasSource: EstrategiaRefactor[] = [];
    estrategiasTarget: EstrategiaRefactor[] = [];

    subEstrategias: Subscription;

    constructor(
        private filtroQueryService: FiltroQueryService,
        private estrategiaService: EstrategiaService,
        private customAlertService: CustomAlertService,
        private ref: MatDialogRef<FiltroQueryPadraoModalComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { listaId: number, filtroQuery: FiltroQuery, debug:boolean }
    ) {
        this.listaId = this.data.listaId;
        this.filtroQuery = this.data.filtroQuery;
        this.debug = this.data.debug;

        this.obterEstrategias();
    }

    obterEstrategias() {
        let filtro = { 'lista': this.filtroQuery.listaId, 'estrategiaAcaoCampanhaTipos': [EstrategiaAcaoCampanhaTipo.Exportacao] };

        this.subEstrategias = this.estrategiaService.obterEstrategiasPaginadas(filtro).subscribe(result => {
            this.estrategias = result
                .filter((f: EstrategiaRefactor) => f.filtroMigrado)
                .map((m: EstrategiaRefactor) => { m.hashFiltroAntigo = MD5(m.filtro).toString(); return m; });
             
            this.estrategiasSource = this.estrategias.filter((f: EstrategiaRefactor) => 
                f.filtroQueryEstrategias.length == 0 ||  f.filtroQueryEstrategias.filter((a:FiltroQueryEstrategia)=> a.filtroQueryId == this.filtroQuery.filtroQueryId && a.ativo).length == 0 );
            this.estrategiasTarget = this.estrategias.filter((f: EstrategiaRefactor) => 
                f.filtroQueryEstrategias.filter((a:FiltroQueryEstrategia)=> a.filtroQueryId == this.filtroQuery.filtroQueryId && a.ativo).length>0);
        });
    }

    gravar() {
        let sucesso = (response: any) => {
            this.customAlertService.show("telaFiltrosPadroes.filtrosPadroes", "telaPadrao.sucessoSalvar", "success");
            this.fechar();
        };

        let erro = () => {
            this.customAlertService.show("telaFiltrosPadroes.filtrosPadroes", "telaPadrao.erroSalvar", "error");
        };

        let e: EstrategiaRefactor[] = [...this.estrategiasSource, ...this.estrategiasTarget];

        let filtroQueryEstrategiasAlterar = e
            .filter((f: EstrategiaRefactor) => (f.filtroAlterado))
            .map((m: EstrategiaRefactor) => ({
                "listaEstrategiaId": m.listaEstrategiaId,
                "filtro": m.filtro,
                "filtroRegras": m.filtroRegras,
                "filtroQueryEstrategias": m.filtroQueryEstrategias
            }));

        this.filtroQueryService.vincularEstrategias(this.filtroQuery.filtroQueryId, filtroQueryEstrategiasAlterar).subscribe(sucesso, erro);
    }


    fechar() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subEstrategias) this.subEstrategias.unsubscribe();
    }
}
