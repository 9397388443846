import { Component, OnInit, Inject } from "@angular/core";
import { TemplateService } from "../../common/service/template.service";
import { obterOperacaoAgregacaoMapeada } from "../card-configuravel/operacao-agregacao";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { FormatacaoCampo } from "../../common/model/formatacao-campo";

@Component({
	selector: "app-card-somar-unicos",
	templateUrl: "./card-somar-unicos.component.html",
	providers: [
		{ provide: TemplateService, useClass: TemplateService },
		{ provide: DefaultValuesService, useClass: DefaultValuesService }
	]
})
export class CardSomarUnicosComponent implements OnInit {
	camposMapeados: any[] = [];
	listaCamposAgrupadoresPossiveis: any[] = [];

	_somaUnicosCardCampoSelecionado: string;
	somaUnicosCardAgrupadorSelecionado: string;
	somaUnicosCardFormatacaoSelecionada: string;
	somaUnicosCardValor: string;
	somaUnicosCardProcessamento: Date;
	somaUnicosCardAlterado: boolean;

	listaFormatacaoCampo: Array<any>;
	formatacaoCampo = FormatacaoCampo;
	formatacaoSelecionada: string;

	public get somaUnicosCardCampoSelecionado(): string {
		return this._somaUnicosCardCampoSelecionado;
	}

	public set somaUnicosCardCampoSelecionado(campo: string) {
		this._somaUnicosCardCampoSelecionado = campo;
		this.mapearCamposPossiveis();
		this.mapearCamposAgrupadoresPossiveis();
	}

	constructor(@Inject(MAT_DIALOG_DATA) public data,
		private templateService: TemplateService,
		private defaultValuesService: DefaultValuesService,
		private ref: MatDialogRef<CardSomarUnicosComponent>
	) { }

	ngOnInit() {

		let tipoDadoHabilitado = ["Data", "Decimal", "Inteiro", "Lista", "Long", "Texto"];
		this.templateService
			.obterCamposMapeados(this.data.templateId, this.data.listaId)
			.subscribe(camposMapeados => {
				this.camposMapeados = camposMapeados.filter(f => tipoDadoHabilitado.indexOf(f.tipoDado) != -1);

				this.somaUnicosCardCampoSelecionado = this.data.somaUnicosCardCampoSelecionado;
				this.somaUnicosCardAgrupadorSelecionado = this.data.somaUnicosCardAgrupadorSelecionado;
				this.somaUnicosCardFormatacaoSelecionada = this.data.somaUnicosCardFormatacaoSelecionada;
				this.somaUnicosCardValor = this.data.somaUnicosCardValor;
				this.somaUnicosCardProcessamento = this.data.somaUnicosCardProcessamento;
				this.somaUnicosCardAlterado = this.data.somaUnicosCardAlterado;

				this.mapearCamposPossiveis();
			});
	}

	mapearCamposPossiveis() {
		if (undefined == this.somaUnicosCardCampoSelecionado)
			return;

		let tipoDado = this.camposMapeados.find(c => c.nome == this.somaUnicosCardCampoSelecionado).tipoDado;

		this.listaFormatacaoCampo = this.formatacaoCampo
			.filter((f: any) => f.tipo == tipoDado)
			.map((m: any) => ({ value: m.valor, label: m.nome }));
	}

	mapearCamposAgrupadoresPossiveis() {
		this.listaCamposAgrupadoresPossiveis = this.camposMapeados.filter((f: any) => f.nome != this.somaUnicosCardCampoSelecionado)
	}

	selecaoCampo() {
		this.formatacaoSelecionada = null;
	}

	validarCampo() {
		return !(this.somaUnicosCardCampoSelecionado);
	}



	save() {
		this.ref.close({
			somaUnicosCardCampo: this.somaUnicosCardCampoSelecionado,
			somaUnicosCardAgrupador: this.somaUnicosCardAgrupadorSelecionado,
			somaUnicosCardFormatacao: this.somaUnicosCardFormatacaoSelecionada,
			somaUnicosCardValor: this.somaUnicosCardValor,
			somaUnicosCardProcessamento: this.somaUnicosCardProcessamento,
			somaUnicosCardAlterado: this.somaUnicosCardAlterado
		});
	}

	close() {
		this.ref.close();
	}

	isNullOrEmpty(valor: any) {
		return (valor == undefined || valor == null || valor == '');
	}


}
