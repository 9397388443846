import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TabFloatingBoxComponent } from "./tab-floating-box.component";
import { TabFloatingBoxItemComponent } from "./tab-floating-box-item.component";
import { TabFloatingBoxLabelComponent } from "./tab-floating-box-label.component";
import { TabFloatingBoxBodyComponent } from "./tab-floating-box-body.component";
import { TabFloatingBoxContentComponent } from "./tab-floating-box-content.component";

@NgModule({
  declarations: [
    TabFloatingBoxComponent,
    TabFloatingBoxItemComponent,
    TabFloatingBoxLabelComponent,
    TabFloatingBoxBodyComponent,
    TabFloatingBoxContentComponent
  ],
  imports: [CommonModule],
  exports: [
    TabFloatingBoxComponent,
    TabFloatingBoxItemComponent,
    TabFloatingBoxLabelComponent,
    TabFloatingBoxBodyComponent,
    TabFloatingBoxContentComponent
  ],
})
export class TabFloatingBoxModule { }
