export class ESBuilderQuantityList {
    // Total 		 = totalGeral
    // Não Utilizado = quantidadeLivre
    // Utilizado	 = quantidadeDistribuida
    // Descartado	 = quantidadeDescartado
    // Encontrado 	 = quantidadeEstrategia

    quantidadeDescartado: number;
    quantidadeDistribuida: number;
    quantidadeEstrategia: number;
    quantidadeLivre: number;
    quantidadeTotal: number
    totalGeral: number
    valorCampoExtra: string;
    valorCampoSomaUnicosLista: string;

    constructor() {
        this.quantidadeDescartado = 0;
        this.quantidadeDistribuida = 0;
        this.quantidadeEstrategia = 0;
        this.quantidadeLivre = 0;
        this.quantidadeTotal = 0;
        this.totalGeral = 0;
        this.valorCampoExtra = "";
        this.valorCampoSomaUnicosLista = "";
    }

    /*
        naoUtilizado = 0,
        total = 1,
        utilizado = 2
    
        obterTotal(){
            let total = 0;
    
            if (this.allRegisters)
                total = quantityList.totalGeral;
    
            if (this.onlyRegistersAvailable)
                total = quantityList.quantidadeLivre;
    
            if (this.onlyUsedRegisters)
                total = quantityList.quantidadeDistribuida;
    
            if (total <= 0)
                total = 1;
        }
    
        pctEncontrados() {
    
            
    
            let pctEncontrados = quantityList.quantidadeEstrategia * 100 / total;
            let pctDescartados = quantityList.quantidadeDescartado * 100 / total;
    
        }
    */

    limpar() {
        this.quantidadeDescartado = 0;
        this.quantidadeDistribuida = 0;
        this.quantidadeEstrategia = 0;
        this.quantidadeLivre = 0;
        this.quantidadeTotal = 0;
        this.totalGeral = 0;
        this.valorCampoExtra = "";
        this.valorCampoSomaUnicosLista = "";
    }

    static fromRaw(raw: any) {
        let data = new ESBuilderQuantityList();
        Object.assign(data, raw);
        return data;
    }
}