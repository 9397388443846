export class DashIndividualLinhaTempo {
    public listaItemId: number
    public listaNome: string;
    public dataAcao: Date;
    public listaEstrategiaId: number;
    public estrategiaDescricao: string;
    public acao: number;
    public providerId: number;
    public providerNome: string;
}

export class DashIndividualLinhaTempoConsolidada {
    public qtdEnvio: number;
    public qtdRetorno: number;
    public dataUltimoEnvio: Date;
    public dataUltimoRetorno: Date;

    constructor(linhaTempo: Array<DashIndividualLinhaTempo>) {
        this.qtdEnvio = linhaTempo.filter(l => l.acao == 1).length;
        this.qtdRetorno = linhaTempo.filter(l => l.acao == 2).length;
        this.dataUltimoEnvio = this.getLatestDate(linhaTempo.filter(l => l.acao == 1));
        this.dataUltimoRetorno = this.getLatestDate(linhaTempo.filter(l => l.acao == 2));
    }

    getLatestDate(arr: Array<DashIndividualLinhaTempo>) {
        if (arr.length) {
            return arr.reduce((m, v, i) => (v.dataAcao > m.dataAcao) && i ? v : m).dataAcao;
        }
    }
}