import { Component, Input, Output, EventEmitter } from "@angular/core";
import { isNull } from "../../common/utils/isNull";

@Component({
    selector: "app-esbuilder-item-field-filter",
    styleUrls: ["./esbuilder-item-field-filter.component.scss"],
    templateUrl: "./esbuilder-item-field-filter.component.html"
})
export class EsBuilderItemFieldFilterComponent {

    mappedFieldsSameType: any[];

    @Input()
    appendToSelect: string = "body"

    @Input()
    filterParameters: any;

    @Input()
    field: string;

    @Input()
    fieldType: string;

    @Input()
    fieldNestedType: string;

    @Input()
    ruleReadOnly: boolean;

    @Input()
    readOnly: boolean = false;

    @Output()
    valuesChange = new EventEmitter<any>();

    teste: string = '';

    //#region [ GET/SET - CamposMapeados ]

    _camposMapeados: any;

    public get camposMapeados() {
        return this._camposMapeados;
    }

    @Input()
    public set camposMapeados(cm: any) {
        if (!cm) return;
        this._camposMapeados = cm;

        if (this.fieldType == 'Nested')
            this.mappedFieldsSameType = [];
        else {
            this.mappedFieldsSameType = this.camposMapeados.filter((f: any) => (f.tipoDado == this.fieldType && f.nome != this.field));
        }

    }

    //#endregion 

    //#region  [ GET/SET - FilterDataType ]

    _filterDataType: number;

    @Input()
    get filterDataType() {
        return this._filterDataType;
    }

    @Output()
    filterDataTypeChange = new EventEmitter<any>();

    set filterDataType(fdt: any) {
        this._filterDataType = fdt;
        //this.filterDataTypeChange.emit(fdt);
    }

    //#endregion 

    //#region [ GET/SET - FilterValues ]

    _filterValues: any;

    @Input()
    get filterValues(): any {
        return this._filterValues;
    }

    @Output()
    filterValuesChange = new EventEmitter<any>();

    set filterValues(fv: any) {
        this._filterValues = fv;
        this.filterValuesChange.emit(fv);
    }

    //#endregion 
    
    public get isTextTypeField() {
        return (this.fieldType == 'Texto');
    }

    public get isDateTypeField() {
        return (this.fieldType == 'Data' || (this.fieldType == 'Nested' && this.fieldNestedType == 'Data'));
    }

    public get isNestedTypeField() {
        return (this.fieldType == 'Nested');
    }

    public get isDataTypeNumeric(): boolean {
        let num = ["Inteiro", "Long", "Decimal"];
        return num.some((s: any) => (s == this.fieldType)) || (this.fieldType == 'Nested' && num.some((s1: any) => (s1 == this.fieldNestedType)));
    }

    public get viewLabel() {
        return ((this.isDateTypeField && this.filterDataType == 1 || this.filterDataType == 3) || (this.isDateTypeField == false && (this.filterDataType == 1 || this.filterDataType == 3 || isNull(this.filterDataType))));
    }

    public get viewInput() {
        return this.isDateTypeField == false && (this.filterDataType == 1 || isNull(this.filterDataType));
    }

    public get viewFilterDataType() {
        let num = ["Inteiro", "Long", "Decimal"];
        return (this.fieldType == 'Data' || (this.fieldType == 'Nested' && this.fieldNestedType == 'Data') || num.some((s: any) => (s == this.fieldType)));
    }

    constructor() {
        this.valuesChange = new EventEmitter<any>();
    }

    listFilterDataTypeOne: Array<any> = [ { 'value': 1, 'label': 'fixo'}, { 'value': 3, 'label': 'campo'} ]
    listFilterDataTypeTwo: Array<any> = [ { 'value': 1, 'label': 'fixo'}, { 'value': 2, 'label': 'dinamico'} ]

    radioChange(event: any) {
        setTimeout(function () {
            this.filterDataTypeChange.emit(this.filterDataType);
        }.bind(this), 100);

    }

    valuesChanged(event: any) {
        this.valuesChange.emit(event);
    }
}