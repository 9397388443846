import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
	MatButtonModule,
	MatInputModule,
	MatIconModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatStepperModule,
	MatSelectModule,
	MatCardModule,
	MatRadioModule,
	MatDividerModule,
	MatSlideToggleModule,
	MatExpansionModule,
	MatListModule,
	MatPaginatorModule,
	MatTooltipModule,
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
import { TableModule } from "primeng/table";
import { DataViewModule } from "primeng/dataview";
import { AppCommonModule } from "../common/common.module";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { ElasticsearchModule } from "../elasticsearch/elasticsearch.module";
import { environment } from "../../../environments/environment";
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { EsBuilderModule } from "../esbuilder/esbuilder.module";

import { NgSelectModule } from '@ng-select/ng-select';
import { ListaDocumentacaoComponent } from "./lista-documentacao/lista-documentacao.component";

@NgModule({
	imports: [
		CommonModule,
		AppCommonModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		MatTooltipModule,
		FormsModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatStepperModule,
		MatSlideToggleModule,
		ElasticsearchModule,
		EsBuilderModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatCardModule,
		MatDividerModule,
		MatListModule,
		NgSelectModule,
		TableModule,
		DataViewModule,
		AmazingTimePickerModule,
		MatRadioModule,
		MatExpansionModule,
		MatCardModule,
		MatPaginatorModule,
		MatDialogModule,
		NgxGraphModule,
		DragDropModule
	],
	providers: [
		{ provide: FormBuilder, useClass: FormBuilder },
		{ provide: LOCALE_ID, useValue: "pt-BR" },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` } }
	],
	entryComponents: [ ],
	declarations: [ ListaDocumentacaoComponent  ],
	exports: [ ListaDocumentacaoComponent ]
})
export class DocumentacaoModule { }
