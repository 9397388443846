import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';

import { Regua } from "../model/regua";
import { convertToQueryString } from "../../common/service/query-string";
import { ReguaEstrategia } from '../model/regua-estrategia';

@Injectable()
export class ReguaService {
	
	private api: string;

	constructor(private httpClient: HttpClient) { 
		this.api = '/api/reguas';
	}

	public obterReguas(filtro: any) {
		return this.httpClient.get<Array<Regua>>(
			`${this.api}${convertToQueryString(filtro)}`
		);
	}

	public obterPorId(id) {
		return this.httpClient.get<Regua>(`${this.api}/${id}`)
			.pipe(map(regua => Regua.fromRaw(regua)));
	}

	public reordernarRegua(ordem: Array<any>) {
		return this.httpClient.post<number>(
			`${this.api}/reordenar-regua`,
			ordem
		);
	}

	public obterJornadasPorLista(listaId: number) {
		return this.httpClient.get<Array<Regua>>(
			`${this.api}/${listaId}/jornadas-por-lista`
		);
	}

	public obterJornadasPorListaEstrategia(listaId: number) {
		return this.httpClient.get<Regua>(
			`${this.api}/${listaId}/jornadas-por-estrategia`
		);
	}

	public criar(regua: Regua) {
		return this.httpClient.post<number>(`${this.api}/`,regua);
	}

	public atualizar(regua: Regua) {
		return this.httpClient.put<number>(`${this.api}/${regua.reguaId}`,regua);
	}

	public gravarReguaEstrategia(reguaEstrategia: ReguaEstrategia) {
		return this.httpClient.post<number>(
			`${this.api}/gravar-reguaestrategia`,
			reguaEstrategia
		);
	}

	public desativarRegua(reguaId: number) {
		return this.httpClient.patch<any>(
			`${this.api}/${reguaId}/desativar-regua`,
			null
		);
	}

	public desativarReguaEstrategia(reguaEstrategiaId: number) {
		return this.httpClient.patch<Array<any>>(
			`${this.api}/${reguaEstrategiaId}/desativar-reguaestrategia`,
			null
		);
	}

	public obterEstrategias(listaId: number) {
		return this.httpClient.get<Array<any>>(
			`${this.api}/estrategias-sem-regua/${listaId}`
		);
	}
}
