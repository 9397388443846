import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashPrincipalComponent } from "./dash-principal/dash-principal.component";
import { FiltroDashComponent } from "./dash-principal/filtro-dash/filtro-dash.component";
import { TableModule } from "primeng/table";
import {
  MatInputModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatButtonModule,
  MatTooltipModule,
  MatIconModule,
  MatCardModule,
  MatTabsModule
} from "@angular/material";
import { AppCommonModule } from "../common/common.module";
import { DashCardDisplayComponent } from "./dash-principal/dash-card-display/dash-card-display.component";
import { DashListaProvedorComponent } from "./dash-lista-provedor/dash-lista-provedor.component";
import { DashEstrategiasComponent } from "./dash-estrategias/dash-estrategias.component";
import { CardComponent } from "./card/card.component";
import { SharedModule } from "../../shared/shared.module";
import { DashListaProvedorValidacaoComponent } from "./dash-lista-provedor/dash-lista-provedor-validacao/dash-lista-provedor-validacao.component";
import { DashListaProvedorService } from "../common/service/dash-lista-provedor.service";
import { DashListaProvedorRegistroComponent } from "./dash-lista-provedor/dash-lista-provedor-registro/dash-lista-provedor-registro.component";
import { DashListaProvedorConfiguradoComponent } from "./dash-lista-provedor/dash-lista-provedor-configurado/dash-lista-provedor-configurado.component";
import { DashIndividualComponent } from "./dash-individual/dash-individual.component";
import { DashIndividualDetalheModalComponent } from "./dash-individual/dash-individual-detalhe-modal/dash-individual-detalhe-modal.component";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    AppCommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    SharedModule,
    TableModule,
    MatTooltipModule,
    NgSelectModule
  ],
  entryComponents: [DashIndividualDetalheModalComponent],
  declarations: [
    DashPrincipalComponent,
    FiltroDashComponent,
    DashCardDisplayComponent,
    DashListaProvedorComponent,
    DashListaProvedorValidacaoComponent,
    DashListaProvedorRegistroComponent,
    DashListaProvedorConfiguradoComponent,
    DashIndividualComponent,
    DashIndividualDetalheModalComponent,
    DashEstrategiasComponent,
    CardComponent
  ],
  providers: [
    DashListaProvedorService
  ]
})
export class DashModule { }
