import { BaseElasticsearchFilter } from "./base";

export class TermElasticsearchFilter extends BaseElasticsearchFilter {
	protected getInstance(
		queryType: string,
		propertyName: string,
		parameters: any,
		extra?: any,
		keyword?: boolean
	): BaseElasticsearchFilter {
		let instance = new TermElasticsearchFilter(propertyName, queryType, parameters, extra, keyword);
		return instance;
	}

	public friendlyParameters(dataType: string, queryTypes) {
		var value = (typeof this.parameters === "object") ? (this.parameters.query === true) : this.parameters;

		if (dataType.toLowerCase() == 'boolean')
			return `É igual a ${value ? "sim" : "não"}`;
		else
			return `É igual a ${this.parameters.query}`;
	}

	getConvertedInlinedContains() {
		let property = (this.keyword) ? `${this.property}.keyword` : this.property;

		return {
			[this.queryType]: {
				[property]: this.parameters.query // === true
			}
		};
	}

	convertFilter(): any {
		if (this.parameters.query != undefined)
			return this.getConvertedInlinedContains();
		return super.convertFilter();
	}

	static fromRaw(raw): TermElasticsearchFilter {
		return Object.assign(new TermElasticsearchFilter(), raw);
	}
}
