
export enum TipoValidacao {
    Telefone = 1,
    CPF = 2,
    Email = 3,
    CEP = 4
}

export class ListaCampoValidacao {
    public listaCampoValidacaoId: number;
    public listaCampoTipoId: number;
    public descricao: string;
    public tipo: TipoValidacao;
    public ativo: boolean;

    constructor() { }

    static fromRaw(raw: any): ListaCampoValidacao {
        return Object.assign(new ListaCampoValidacao(), raw);
    }
}