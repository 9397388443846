import { Component, Inject, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatSlideToggleChange } from "@angular/material";


import { EstrategiaService } from "../../../common/service/estrategia.service";
import { Estrategia } from "../../../common/model/estrategia";
import { environment } from "../../../../../environments/environment";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
import { isNullOrEmpty } from "../../../common/utils";
import { QueryToModel } from "../../../esbuilder/libs/utils";
import { TemplateService } from "../../../common/service/template.service";
import { generateCanvas } from "../../../common/utils/generateCanvas";
import { AgendamentoModel } from "../../models/agendamento.model";

@Component({
    selector: "app-configuracoes-agendamento-modal",
    templateUrl: "./configuracoes-agendamento-modal.component.html",
    styleUrls: ["./configuracoes-agendamento-modal.component.scss"],
    providers: [
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ConfiguracoesAgendamentoModalComponent {
    dirty: boolean = false;
    agendamento: AgendamentoModel;
    horariosReservados: Array<string>;

    constructor(
        public estrategiaService: EstrategiaService,
        private templateService: TemplateService,
        public dialogRef: MatDialogRef<ConfiguracoesAgendamentoModalComponent>,
        private customAlertService: CustomAlertService,
        @Inject(MAT_DIALOG_DATA)
        public data: { agendamento: AgendamentoModel, horariosReservados: Array<string> }
    ) {
        this.agendamento = new AgendamentoModel();
        this.agendamento = data.agendamento;
    }

    agendamentoChange(event: AgendamentoModel) {
        this.agendamento = event;
    }

    save() {
        this.dirty = true;

        if (this.agendamento.validar()) {
            this.customAlertService.show("telaTemplate.template", "telaPadrao.camposInvalidos", "error");
            return;
        };

        return this.dialogRef.close(this.agendamento);
    }

    fechar() {
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }
}
