import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';

import { Fluxo, FluxoTipo } from "../../common/model/fluxo";
import { Lista } from "../../common/model/lista";
import { FiltroLista } from '../../common/model/filtro-lista';

import { FluxoService } from "../../common/service/fluxo.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { ESBuilderData } from "../models";
import { isNull, isNullOrEmpty } from "../../common/utils";
import { QueryToModel } from "../libs/utils";
import { TemplateService } from "../../common/service/template.service";


@Component({
    selector: "app-esbuilder-test",
    templateUrl: "./esbuilder-test.component.html",
    styleUrls: ["./esbuilder-test.component.scss"],
    providers: [
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: FluxoService, provide: FluxoService },
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class EsBuilderTestComponent implements OnInit {
    listas: Array<Lista>;
    listaId: number;
    templateId: number;

    //#region [ GET/SET - Campos Mapeados ]  

    _camposMapeados: Array<any>;

    public set camposMapeados(cm: Array<any>) {
        if (!cm) return;
        this._camposMapeados = cm;
    }

    public get camposMapeados() {
        return this._camposMapeados;
    }

    //#endregion

    jsonView: string = 'dataRules';
    dataRulesQueryToModel: ESBuilderData | null = null;

    public get jsonViewPreview(): any {
        if (this.jsonView == 'dataRules')
            return this.dataRules;
        else if (this.jsonView == 'query')
            return this.query;
        else
            return {};
    }

    quantidade: number = 0;
    query: any = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
    dataRules: ESBuilderData = new ESBuilderData();

    jsonQueryToModel: string;

    @ViewChild(DataView, { static: false }) dataTable: DataView;
    constructor(
        private listaService: ListaService,
        private templateService: TemplateService,
        private fluxoService: FluxoService,
        private route: ActivatedRoute,
        private customAlertService: CustomAlertService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.obterListas();
        this.obterCamposMapeados();
    }

    obterListas() {
        let filtroLista = new FiltroLista();
        filtroLista.ignorarErroPesquisa = true;
        this.listaService.obterListaSemQuantidade(filtroLista).subscribe(listas => {
            this.listas = listas;
            this.listaId = listas[0].listaId;
        });
    }

    obterCamposMapeados() {
        this.templateService.obterCamposMapeados(this.templateId, this.listaId).subscribe((result: any) => (this.camposMapeados = result.filter(f => f.itemTipo != 2)));
    }


    listaChange(event: any) {
        let lista = this.listas.find((f: Lista) => (f.listaId.toString() == this.listaId.toString()));

        if (!isNull(lista)) {
            this.templateId = lista.listaTemplateId;
            this.obterCamposMapeados();
            this.jsonView = 'dataRules';
            this.query = JSON.parse('{"size":5,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
            this.dataRules = new ESBuilderData();
            this.dataRulesQueryToModel = null;
            this.updateQuantidade();
        }
    }


    updateQuery(event: any) {
        if (!isNullOrEmpty(event.query))
            this.query = event.query;

        if (!isNullOrEmpty(event.dataRules))
            this.dataRules = event.dataRules;

        this.updateQuantidade();
    }

    updateQuantidade() {
        this.fluxoService
            .obterQueryQuantidadeRegistrosPost(this.listaId, JSON.stringify(this.query))
            .subscribe((result: any) => (this.quantidade = (result) ? result : 0));
    }

    mostrarPreview() {

        let query: any = this.query;
        query["size"] = 10;

        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: this.templateId, //this.listaTemplateId,
                lista: `lista_${this.listaId}`, //this.listaAlias,
                listaId: this.listaId, //this.listaId,
                query: query
            }
        });
    }



    queryToModel() {
        ///this.esBuilderTestService.queryCondicao07().subscribe((result: string) => {
        //let query = result;

        let model = QueryToModel.convert(this.jsonQueryToModel, this.camposMapeados);
        //this.listaExpressaoRegex = this.listaExpressaoRegex;
        this.dataRulesQueryToModel = model;
        //});
    }
}

