import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FiltroNotificacao } from "../../common/model/filtro-notificacao";
import { Notificacao, NotificacaoEvento, NotificacaoTipo } from "../../common/model/notificacao";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { NotificacaoService } from "../../common/service/notificacao.service";
import { EnumToArray } from "../../common/utils/enumToArray";
import { DetalheNotificacaoComponent } from "../detalhe-notificacao/detalhe-notificacao.component";
import { NotificacaoEventoPipe } from "../libs/pipes/notificacao-evento-pipe"
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

@Component({
    selector: "app-lista-notificacao-antigo",
    templateUrl: "./lista-notificacao-antigo.component.html",
    styleUrls: ["./lista-notificacao-antigo.component.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: NotificacaoEventoPipe, provide: NotificacaoEventoPipe }
    ]
})
export class ListaNotificacaoAntigoComponent implements OnInit {
    isDirty: boolean;
    filtro: FiltroNotificacao;
    eventos: Array<any>;
    tipos: Array<any>;
    lstNotificacao: Array<Notificacao>;
    notificacaoTipo = NotificacaoTipo;
    notificacaoTipoIcone: any = {"error": "cancel", "warning": "warning", "info": "info" };

    public get erros() {
        if (!this.isDirty)
            return false;

        return this.filtro.validate();
    }

    constructor(
        private notificacaoService: NotificacaoService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        private customAlertService: CustomAlertService) {

        this.setarFiltroPadrao();
        this.popularSelectEvento();
        this.popularSelectTipo();

        this.pesquisar();

        this.route.params.subscribe(params => {
            if (!params.id)
                return;

            this.visualizarNotificacao(null, params.id);
        });
    }

    ngOnInit() {
    }

    popularSelectEvento() {
        observableCombineLatest([
            this.translate.get("telaNotificacao.exportacaoEstrategia"),
            this.translate.get("telaNotificacao.indexacaoLista"),
            this.translate.get("telaNotificacao.downloadRegistro"),
            this.translate.get("telaNotificacao.envioSemRetorno"),
            this.translate.get("telaNotificacao.processamentoRetorno")
        ])
            .toPromise()
            .then(result => {

                this.eventos = [
                    { 'value': '1', 'label': result[0] },
                    { 'value': '2', 'label': result[1] },
                    { 'value': '3', 'label': result[2] },
                    { 'value': '4', 'label': result[3] },
                    { 'value': '5', 'label': result[4] }
                ];
            });
    }

    popularSelectTipo() {
        this.tipos = EnumToArray(NotificacaoTipo);
    }

    pesquisar() {
        this.isDirty = true;

        if (this.erros)
            return this.customAlertService.show(
                ".historicoRetorno",
                "telaPadrao.camposInvalidos",
                "error"
            );

        this.notificacaoService.obterNotificacaoAntigo(this.filtro).subscribe(notificacao => {
            this.lstNotificacao = notificacao;
        });
    }

    visualizarNotificacao(notificacao: Notificacao, id: number) {
        this.dialog.open(DetalheNotificacaoComponent, {
            hasBackdrop: true,
            width: "50%",
            data: {
                'notificacao': notificacao,
                'id': id
            }
        });
    }

    //#region [ Métodos Filtro ]

    setarFiltroPadrao() {
        let dataFim = new Date();
        let dataInicio = new Date(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate() - 1);
        this.filtro = new FiltroNotificacao();
        this.filtro.dataInicio = dataInicio;
        this.filtro.dataFim = dataFim;
        this.filtro.notificacaoEventoId = null;
        this.filtro.notificacaoTipoId = null;
        this.filtro.tamanhoLote = 100;
    }

    limpar() {
        this.setarFiltroPadrao();
    }

    //#endregion
}