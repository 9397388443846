import "rxjs/add/operator/map";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DownloadRegistro } from "../model/download-registro";
import { FiltroDownloadRegistro } from "../model/filtro-download-registro";
import { AutenticacaoService } from "./autenticacao.service";
import { downloadFile } from "./download-file";
import { convertToQueryString } from "./query-string";
import { ResponseContentType, Http, Headers } from "@angular/http";
import { CustomAlertService } from './custom-alert.service';
import { ConfigProvider } from '../../../common/providers/config.provider';
import { StorageProxy } from "./storage-proxy.service";

@Injectable()
export class DownloadRegistroService {
    private api: string;
    private urlApiCompleta: string

    constructor(private httpClient: HttpClient,
        private authService: AutenticacaoService,
        private configProvider: ConfigProvider,
        private customAlertService: CustomAlertService,
        private http: Http
    ) {
        this.api = '/api/downloadregistro';
        let appConfig = this.configProvider.getConfig();
		let language: string = (StorageProxy.getString('linguagem') === "pt") ? "pt-BR" : StorageProxy.getString('linguagem');
		this.urlApiCompleta = `${appConfig.serverUrl}/${language}${this.api}`;
    }

    public criarDownloadTotal(model: DownloadRegistro) {
        return this.httpClient.post(`${this.api}`, model);
    }

    public obterDownloadRegistro(filtro: FiltroDownloadRegistro) {
        return this.httpClient.get<Array<DownloadRegistro>>(
            `${this.api}${convertToQueryString(filtro)}`
        );
    }

    public baixarArquivoRegistro(downloadregistro: DownloadRegistro) {
        this.http
            .get(
                `${this.urlApiCompleta}/${downloadregistro.downloadRegistroId}/download-arquivo`,
                {
                    responseType: ResponseContentType.Blob,
                    headers: new Headers({ Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`})
                }
            )
            .subscribe(
                (res) => { downloadFile(res, `${downloadregistro.nomeArquivo}`) },
                () => (this.customAlertService.show("telaEstrategia.download", "telaEstrategia.expurgoArquivo", "info")))
    }
}