
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SweetAlertIcon } from "sweetalert2";
import swal from "sweetalert2";
import "rxjs/add/operator/combineLatest";

@Injectable()
export class CustomAlertService {
	constructor(private translateService: TranslateService) { }

	SweetAlertOptions

	show(title: string, description: string, status: SweetAlertIcon) {
		return observableCombineLatest([
			this.translateService.get(title),
			this.translateService.get(description)
		])
			.toPromise()
			.then(resultCollection =>
				swal.fire({
					title: resultCollection[0],
					text: resultCollection[1],
					icon: status,
					showCloseButton: true
				})
			);
	}

	showWithConfiguration(configuration) {
		return observableCombineLatest([
			this.translateService.get(configuration.title),
			this.translateService.get(configuration.text),
			this.translateService.get(configuration.confirmButtonText),
			this.translateService.get(configuration.cancelButtonText)
		])
			.toPromise()
			.then(resultCollection =>
				swal.fire(
					Object.assign({}, configuration, {
						html: resultCollection[1].replace(new RegExp('\n', 'g'), '<br>'),
						title: resultCollection[0],
						text: resultCollection[1].replace(new RegExp('\n', 'g'), '<br>'),
						confirmButtonText: resultCollection[2] || undefined,
						cancelButtonText: resultCollection[3] || undefined
					})
				)
			);
	}

	confirmationMessageWithThreeButton(configuration) {
		return observableCombineLatest([
			this.translateService.get(configuration.title),
			this.translateService.get(configuration.text),
			this.translateService.get(configuration.confirmButtonText),
			this.translateService.get(configuration.cancelButtonText),
			this.translateService.get(configuration.denyButtonText)
		])
			.toPromise()
			.then(resultCollection => {
				
				let texto = resultCollection[1].replace(new RegExp('\n', 'g'), '<br>')
											   .replace(new RegExp('{ds}', 'g'), '<div style="font-size: 12px; color: #C00; line-height: 16px;">')
											   .replace(new RegExp('{/ds}', 'g'), '</div>')
											   .replace(new RegExp('{b}', 'g'), '<strong>')
											   .replace(new RegExp('{/b}', 'g'), '</strong>')
											   .replace(new RegExp('{i}', 'g'), '<i>')
											   .replace(new RegExp('{/i}', 'g'), '</i>')
											   .replace(new RegExp('{fs13}', 'g'), '<span style="font-size: 13px;">')
											   .replace(new RegExp('{/fs13}', 'g'), '</span>');

				return swal.fire(
					Object.assign({}, configuration, {
						showDenyButton: true,
						showCloseButton: true,
						showCancelButton: configuration.showCancelButton,
						html: texto,
						title: resultCollection[0],
						text: texto,
						confirmButtonText: resultCollection[2] || undefined,
						cancelButtonText: resultCollection[3] || undefined,
						denyButtonText: resultCollection[4] || undefined
					})
				)
			});
	}

	confirmationMessage(
		description: string,
		title: string = "telaPadrao.confirmacao"
	) {
		return this.showWithConfiguration({
			html: description,
			title,
			text: description,
			icon: "warning",
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonText: "telaPadrao.sim",
			cancelButtonText: "telaPadrao.nao"
		}).then(result => {
			if (result.value !== true) throw "canceled";
		});
	}


}
