import { Component, Inject, ViewChild, OnDestroy, DebugElement } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";
import { NaturalLanguage } from "../../common/model/natural-language";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";
import { isNullOrEmpty } from "../../common/utils";

@Component({
    selector: "app-registros-modal-ia",
    templateUrl: "./registros-modal-ia.component.html",
    styleUrls: ["./registros-modal-ia.component.scss"],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class RegistrosModalIAComponent {
    
    
    submited: boolean = false;
    errorConvertion: boolean = false;
    debug: boolean = false;
    
    listId:number;
    naturalLanguage: NaturalLanguage;
    subNaturalLanguage: Subscription;



    showLoader: boolean = false;

    public get error() {
        if (this.submited)
            return this.naturalLanguage.validate();
    }

    public liveError(property: string) {
        let validationResult = this.naturalLanguage.validate();
        if (!validationResult) return null;
        return validationResult[property] ? validationResult[property][0] : null;
    }

    constructor(
        private listaService: ListaService,
        private customAlertService: CustomAlertService,
        private ref: MatDialogRef<RegistrosModalIAComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { listId: number, debug: boolean }
    ) {
        this.debug = false; //data.debug;

        this.naturalLanguage = new NaturalLanguage();
        this.listId = data.listId;
    }

    create() {
        this.submited = true;
        this.showLoader = true;
        this.naturalLanguage = new NaturalLanguage();
        this.naturalLanguage.listId = this.listId;

        this.subNaturalLanguage = this.listaService.gerarRegistrosIA(this.listId).subscribe(
            (result: NaturalLanguage) => {
                try {

                    this.naturalLanguage = result;
                    this.showLoader = false;
                }
                catch (error) {

                    if (this.debug) {
                        console.error(error);
                        console.info(result);
                    }
                    this.errorConvertion = true;
                    this.showLoader = false;
                }
            },
            (error: any) => {
                this.customAlertService.show("esBuilderComponent.filtroPorIA", error.error[0], "error");
                this.showLoader = false;
            }
        );
    }

    existsRegisters(){
        return (!isNullOrEmpty(this.naturalLanguage.registers))
    }

    getRegisters() {
        if (isNullOrEmpty(this.naturalLanguage.registers))
            return [];

        return JSON.parse(this.naturalLanguage.registers);
    }

    close() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subNaturalLanguage)
            this.subNaturalLanguage.unsubscribe();
    }
}
