import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { ExpressaoRegex } from "../model/expressao-regex";

@Injectable()
export class ListaExpressaoService {
  private api: string;

  constructor(private httpClient: HttpClient) { 
      this.api = '/api/listaexpressao';
  }

  obterExpressao(): Observable<Array<ExpressaoRegex>> {
    return this.httpClient.get<Array<ExpressaoRegex>>(`${this.api}`);
  }
}
