import { ModelToQuery } from "../../esbuilder/libs/utils";
import { ESBuilderData, ESBuilderRules } from "../../esbuilder/models";
import { isNull, isNullOrZero, isObjectEmpty } from "../utils";
import { EstrategiaRefactor } from "./estrategiaRefactor";
import { FiltroQuery } from "./filtro-query";

export class FiltroQueryEstrategia {
	filtroQueryEstrategiaId: number;
	filtroQueryId: number;
	listaEstrategiaId: number;
	dataCadastro: Date;
	usuarioCadastro: number;
	dataAlteracao: Date;
	usuarioAlteracao: number;
	ativo: boolean;

	filtroQuery: FiltroQuery;

	static fromRaw(raw: any) {
		let filtroQueryEstrategia = Object.assign(new FiltroQueryEstrategia(), raw);

		if (!isNull(raw.filtroQuery))
			filtroQueryEstrategia.filtroQuery = FiltroQuery.fromRaw(raw.filtroQuery);

		return filtroQueryEstrategia;
	}

	private static TratarFiltroPadrao(filtro: FiltroQuery,ativarFiltro :boolean, estrategia: EstrategiaRefactor): any
	{
		let data: any={};
		let filtroRegrasOriginal = ESBuilderData.fromRaw(JSON.parse(estrategia.filtroRegras));
		let filtroRegrasAlterado: Array<ESBuilderRules> = [];
		let query: any;
		
		if (!isNull(filtro))
		{
			let filtroNovo = true;
			//gera lista com IDS dos filtros padrões Original para manter a ordem
			let filtrosPadroesIdsOriginal = filtroRegrasOriginal.rules.filter((r: ESBuilderRules) => !isNullOrZero(r.standardFilter)).map(m=> m.standardFilter);
			filtrosPadroesIdsOriginal= Array.from(new Set(filtrosPadroesIdsOriginal));

			//Monta lista com filtros padrão na ordem
			filtrosPadroesIdsOriginal.forEach((standardFilterId: number) => {
				if (filtro.filtroQueryId == standardFilterId)
				{
					filtroNovo = false;

					//só adiciona o filtro se for para ativar 
					if (ativarFiltro===true)
					{
						//caso já exista o filtro atualiza (caso tenha sido adicionado novo campos ou parametros)
						let filtroRegrasData = ESBuilderData.fromRaw(JSON.parse(filtro.filtroRegras));
						filtroRegrasData.rules.forEach((rule: ESBuilderRules) => {
							rule.ruleReadOnly = true;
							rule.standardFilter = filtro.filtroQueryId;
							filtroRegrasAlterado.push(rule);
						});
					}
				}
				else
				{
					//Adiciona os filtros já existem
					filtroRegrasOriginal.rules
					.filter((r: ESBuilderRules) =>  r.standardFilter == standardFilterId )
					.forEach((rule: ESBuilderRules) => {
						filtroRegrasAlterado.push(rule);
					});
				}
			})

			
			//Insere o filtro caso não exista na lista para atualizar
			if (filtroNovo === true && ativarFiltro === true)
			{
				let filtroRegrasData = ESBuilderData.fromRaw(JSON.parse(filtro.filtroRegras));
				filtroRegrasData.rules.forEach((rule: ESBuilderRules) => {
					rule.ruleReadOnly = true;
					rule.standardFilter = isNullOrZero(filtro.filtroQueryId) ? -1 : filtro.filtroQueryId;
					filtroRegrasAlterado.push(rule);
				});
			}
		}

		// Adiciona os filtros já existentes que não sao de nenhum filtro padrão
		filtroRegrasOriginal.rules
				 .filter((r: ESBuilderRules) => isNullOrZero(r.standardFilter))
				 .forEach((rule: ESBuilderRules) => {
					 filtroRegrasAlterado.push(rule);
				 });

		if (isNull(estrategia.filtroQueryEstrategias))
			estrategia.filtroQueryEstrategias = [];

		// Reativa ou adiciona os filtro padrão da estratégia
		let s = estrategia.filtroQueryEstrategias.some((s: FiltroQueryEstrategia) => s.filtroQueryId == filtro.filtroQueryId);
		if (s) {
			estrategia.filtroQueryEstrategias.forEach((f: FiltroQueryEstrategia) => {
			if (f.filtroQueryId == filtro.filtroQueryId)
				f.ativo = ativarFiltro;
			});
		} else {
				let fqe = FiltroQueryEstrategia.fromRaw({ filtroQueryId: filtro.filtroQueryId, listaEstrategiaId: estrategia.listaEstrategiaId, ativo: true });
				estrategia.filtroQueryEstrategias.push(fqe);
		}
 
		filtroRegrasOriginal.rules = filtroRegrasAlterado;
		data.dataRules = filtroRegrasOriginal;
		query = ModelToQuery.convert(filtroRegrasOriginal);
		data.query =  JSON.stringify(query);
		data.filtroRegras = JSON.stringify(filtroRegrasOriginal);
		data.filtroQueryEstrategias = estrategia.filtroQueryEstrategias;

		return data;
	}

	static AdicionarFiltroPadrao(filtro: FiltroQuery, estrategia: EstrategiaRefactor): any {
		return this.TratarFiltroPadrao(filtro,true, estrategia);
	}

	static AtualizarFiltroPadrao(filtro: FiltroQuery, estrategia: EstrategiaRefactor): any {
		return this.TratarFiltroPadrao(filtro,true, estrategia);
	}
	
	static RemoverFiltroPadrao(filtro: FiltroQuery, estrategia: EstrategiaRefactor): any {
		return this.TratarFiltroPadrao(filtro,false, estrategia);
	}
}
