import { NNotificacaoConexao } from "./nnotificacao-conexao";
import { NNotificacaoCamposCliente } from "./nnotificacao-campos";

import { NNotificacaoTipoEvento } from "./nnotificacao-tipo-evento";
import { isNull, isNullOrEmpty } from "../utils";
import { GuidService } from "../service/guid.service";
import { NNotificacaoTipoEnvio } from "./nnotificacao-tipo-envio";

export class NNotificacaoEventoConexao {

    id: number;
    notificacaoEventoId: number;
    notificacaoConexaoId: number;
    assincrono: boolean;
    dispararErro: boolean;
    jsonSaida: string;
    ordem: number;

    conexao: NNotificacaoConexao;

    // Usados pela tela
    guid: string;
    conexoesPorTipoEnvio: Array<any> = [];
    camposPorTipoEvento: Array<NNotificacaoCamposCliente> = [];



    public get exibirCampos(): boolean {
        if (isNull(this.camposPorTipoEvento) == false)
            return this.camposPorTipoEvento.some((s: NNotificacaoCamposCliente) => s.ativo);

        return false;
    }

    public get conexaoApi(): boolean {
        if(isNull(this.conexao) == false)
            return (this.conexao.notificacaoTipoEnvioId == NNotificacaoTipoEnvio.Api);

        return false;
    }

    constructor() {
        this.id = 0;
        this.guid = GuidService.newGuid();
        this.assincrono = true;
        this.dispararErro = false;
        this.ordem = 0;

        this.conexao = new NNotificacaoConexao();
    }



    static fromRaw(raw: any): NNotificacaoEventoConexao {
        let model: NNotificacaoEventoConexao = Object.assign(new NNotificacaoEventoConexao(), raw);
        model.guid = GuidService.newGuid();

        if (isNullOrEmpty(raw.jsonSaida) == false)
            model.camposPorTipoEvento = JSON.parse(raw.jsonSaida).map((m: any) => NNotificacaoCamposCliente.fromRaw(m));

        return model;
    }


}