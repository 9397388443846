import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { ElasticSearch } from "../model/elastic-search";

@Injectable()
export class ElasticSearchService {
    private api: string;

    constructor(private httpClient: HttpClient) { 
        this.api = '/api/elasticsearch';
    }

    public obterElasticSearchs(filtro?: any) {
        let url = `${this.api}/obter-todos`;

        if (filtro)
            url += "?" + Object.keys(filtro).map(prop => `${prop}=${filtro[prop]}`).join("&");

        return this.httpClient.get<Array<ElasticSearch>>(url);
    }

    public obterPorId(id: number) {
        return this.httpClient.get<ElasticSearch>(`${this.api}/obter/${id}`)
            .pipe(map(f => {
                let elasticSearch = ElasticSearch.fromRaw(f);
                return elasticSearch;
            }));
    }

    public validarVersao(elasticSearch: ElasticSearch) {
        return this.httpClient.post<ElasticSearch>(`${this.api}/validar-versao`, elasticSearch).pipe(map(f => ElasticSearch.fromRaw(f)));
    }

    public criar(elasticSearch: ElasticSearch) {
        return this.httpClient.post<number>(`${this.api}/gravar`, elasticSearch);
    }

    public atualizar(elasticSearch: ElasticSearch) {
        return this.httpClient.put<number>(`${this.api}/${elasticSearch.elasticSearchId}/gravar`, elasticSearch);
    }

    public desativar(elasticSearchId: number) {
        return this.httpClient.patch<any>(`${this.api}/${elasticSearchId}/desativar`, null);
    }
}
