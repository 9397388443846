import { ConfiguracaoService } from './../../common/service/configuracao.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';

import { Lista } from '../../common/model/lista';
import { Template } from '../../common/model/template';
import { ListaArquivo } from '../../common/model/lista-arquivo';

import { ListaService } from '../../common/service/lista.service';
import { TemplateService } from '../../common/service/template.service';
import { CustomAlertService } from '../../common/service/custom-alert.service';

import { DisplayTemplateComponent } from './display-template/display-template.component';
import { PreviewDadosArquivoComponent } from './preview-dados-arquivo/preview-dados-arquivo.component';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { AutenticacaoService } from '../../common/service/autenticacao.service';
import { ImportacaoAutomaticaComponent } from './importacao-automatica/importacao-automatica.component';
import { ImportacaoAutomaticaConfiguracao } from '../../common/model/importacao-automatica';
import { ImportacaoAutomaticaVisualizacaoComponent } from './importacao-automatica-visualizacao/importacao-automatica-visualizacao.component';
import * as signalR from "@aspnet/signalr";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";
import { isNull } from '../../common/utils';
import { downloadFile } from "../../common/service/download-file";
import { number } from '@amcharts/amcharts4/core';
import { ConfigProvider } from '../../../common/providers/config.provider';

@Component({
	selector: 'app-upload-arquivos',
	templateUrl: './upload-arquivos.component.html',
	styleUrls: ["./upload-arquivos.component.css"],
	providers: [
		{ useClass: ListaService, provide: ListaService },
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ useClass: ConfiguracaoService, provide: ConfiguracaoService}
	]
})
export class UploadArquivosComponent implements OnInit, OnDestroy {

	@BlockUI()
	blockUI: NgBlockUI;

	lista: Lista = new Lista();
	template: Template = new Template();
	listaId;
	listaArquivoEnviado: any[];
	exibirMsgArquivo: boolean = true;
	apiUpload: string;
	uploadLimit = environment.uploadLimit;
	importacaoAutomaticaAtiva: boolean;
	jobImportacaoAutomaticaAtivo: boolean;
	processarArquivoAutomaticamente:boolean = true;
	importacaoAutomatica: ImportacaoAutomaticaConfiguracao;
	private _hubConnection: HubConnection | undefined;

	constructor(
		private listaService: ListaService,
		private configuracaoService: ConfiguracaoService,
		private templateService: TemplateService,
		route: ActivatedRoute,
		private authService: AutenticacaoService,
		private customAlertService: CustomAlertService,
		public dialog: MatDialog,
		private configProvider: ConfigProvider,
	) {
		route.params.subscribe((params) => {
			this.listaId = parseInt(params.id, 10);
			this.apiUpload = listaService.getArquivoListaUrl(this.listaId, this.processarArquivoAutomaticamente);
			this.listaService.obterListaPorId(this.listaId).subscribe((lista) => {
				this.lista = lista;
				this.templateService
					.obterTemplateComLista(lista.listaTemplateId, lista.listaId)
					.subscribe((template) => (this.template = Template.fromRaw(template)));
			});

			this.configuracaoService.obterConfiguracao("BackgroundManutencao","ConfiguracaoImportacaoAutomatica:Ativo").subscribe((result) => {
				this.jobImportacaoAutomaticaAtivo = false;
				if (result)
					this.jobImportacaoAutomaticaAtivo =  result.valor.toLowerCase() == "true";

				if (this.jobImportacaoAutomaticaAtivo) {
					this.listaService.getImportacaoAutomaticaPorListaId(this.listaId)
						.subscribe((result) => {
							this.importacaoAutomatica = result;
							this.importacaoAutomaticaAtiva = (result && result.ativo)
						});
				}
			});

			
		});

		let endpoint = this.configProvider.getConfig().serverUrl;

		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${endpoint}/notificacaohub`, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
			.configureLogging(signalR.LogLevel.Information)
			.build();
	}

	ngOnInit() {
		this.obterArquivosLista();
		this._hubConnection.start().then(() => {
			this._hubConnection.on("AtualizarImportacao", conteudo => {
				if (conteudo.listaArquivoId > 0) {
					var objIndex = this.listaArquivoEnviado.findIndex((obj => obj.listaArquivoId == conteudo.listaArquivoId));
					this.listaArquivoEnviado[objIndex].progressoImportacao = conteudo.percentualProcessado;

					if (conteudo.listaArquivoStatusId > 0)
						this.listaArquivoEnviado[objIndex].listaArquivoStatusId = conteudo.listaArquivoStatusId;
				}
				else this.obterArquivosLista();
			});
		});
	}

	ngOnDestroy() {
		this._hubConnection.stop();
	}

	public get listaArquivoParaProcessar() {
		if (isNull(this.listaArquivoEnviado)) return [];
		return this.listaArquivoEnviado.filter((a) => a.listaArquivoStatusId <= 3 || a.listaArquivoStatusId == 6);
	}

	public get listaHistoricoImportacao() {
		if (isNull(this.listaArquivoEnviado)) return [];
		return this.listaArquivoEnviado.filter((a) => a.listaArquivoStatusId > 3 && a.listaArquivoStatusId != 6);
	}

	public get listaArquivoSelecionado() {
		if (isNull(this.listaArquivoEnviado)) return [];
		return this.listaArquivoEnviado.filter((a) => a.selecionado);
	}

	public get existeAguardandoProcessamento() {
		if (isNull(this.listaArquivoParaProcessar)) return false;
		return this.listaArquivoParaProcessar.some((a) => a.listaArquivoStatusId == 6);

	}

	obterArquivosLista() {
		this.listaService
			.obterArquivosLista(this.listaId)
			.subscribe((listaArquivoEnviado) => {
				this.listaArquivoEnviado = listaArquivoEnviado.map((data) => {
					let arquivo = Object.assign(new ListaArquivo(), data);
					if (arquivo.listaArquivoStatusId == 3)
						arquivo.selecionado = true;
					return arquivo;
				});
			});
	}

	preview(arquivoId, invalido = false) {
		this.dialog.open(PreviewDadosArquivoComponent, {
			hasBackdrop: true,
			width: '85%',
			height: '650px',
			data: {
				listaId: this.listaId,
				listaArquivoId: arquivoId,
				abaSelecionada: invalido ? 1 : 0,
				template: this.template
			}
		});
	}

	abrirModalTemplate(template) {
		this.dialog.open(DisplayTemplateComponent, {
			hasBackdrop: true,
			width: '75%',
			data: {
				template
			}
		});
	}

	abrirModalImportacaoAutomaticaConfiguracao() {

		if (!this.importacaoAutomaticaAtiva) return;

		this.dialog.open(ImportacaoAutomaticaComponent, {
			hasBackdrop: true,
			width: '80%',
			data: { importacaoAutomaticaConfiguracaoId: this.importacaoAutomatica.importacaoAutomaticaConfiguracaoId }
		});
	}

	abrirModalImportacaoAutomaticaVisualizacao() {

		if (!this.importacaoAutomaticaAtiva) return;

		this.dialog.open(ImportacaoAutomaticaVisualizacaoComponent, {
			hasBackdrop: true,
			width: '65%',
			data: { importacaoAutomaticaConfiguracaoId: this.importacaoAutomatica.importacaoAutomaticaConfiguracaoId }
		});
	}

	importacaoAutomaticaAtivaChange() {

		let id = (this.importacaoAutomatica) ? this.importacaoAutomatica.importacaoAutomaticaConfiguracaoId : 0;

		this.listaService.setStatusImportacaoAutomatica(id, this.listaId, this.importacaoAutomaticaAtiva)
			.subscribe((result) => {
				this.importacaoAutomatica = result;
			});
	}

	processarArquivoAutomaticaChange() {
		this.apiUpload = this.listaService.getArquivoListaUrl(this.listaId, this.processarArquivoAutomaticamente);
	}


	confirmar() {
		this.listaService
			.confirmarArquivos(
				this.listaId,
				this.listaArquivoSelecionado.map((arquivo) => arquivo.listaArquivoId)
			)
			.subscribe(() => {
				this.customAlertService.show('telaLista.lista', 'telaLista.arquivosProcessadosSucesso', 'success');
				this.obterArquivosLista();
			});
	}

	baixar() {
		this.templateService.baixarExemplo(this.template);
	}

	excluir(listaArquivos) {
		this.listaService
			.excluirArquivos(
				this.listaId,
				listaArquivos.map((arquivo) => arquivo.listaArquivoId)
			)
			.subscribe(() => this.obterArquivosLista());
	}

	excluirSelecionado(arquivo) {
		this.excluir([arquivo]);
	}

	excluirTodos() {
		this.excluir(this.listaArquivoSelecionado);
	}

	//Upload
	onBasicUpload($event) {

		if (this.blockUI.isActive)
			this.blockUI.stop();

		if ($event.xhr.status === '413') {
			this.customAlertService.show(
				'telaLista.lista',
				'telaLista.erroTamanhoUpload',
				'error'
			);
		} else {
			if ($event.xhr.type === 0) { return; }
			this.blockUI.stop();
			const response = JSON.parse($event.xhr.responseText);
			if (response.data === false) {
				this.customAlertService.show('telaLista.lista', 'telaLista.arquivoRejeitado', 'error');
			}

			this.exibirMsgArquivo = true;
			this.obterArquivosLista();
		}
	}

	onSelect($event) {
		this.exibirMsgArquivo = $event.files.length === 0;
	}

	onError($event) {
		if (this.blockUI.isActive)
			this.blockUI.stop();

		if ($event.xhr.response) {
			if ($event.xhr.statusText === 'Unknown Error') {
				this.customAlertService.show(
					'telaLista.lista',
					'telaLista.erroTamanhoUpload',
					'error'
				);
			}
		}

		this.obterArquivosLista();
	}

	onBeforeSend($event) {
		this.blockUI.start();
		$event.xhr.setRequestHeader('Authorization', 'Bearer ' + this.authService.obterTokenAutenticado());
		$event.xhr.setRequestHeader('Access-Control-Allow-Origin', '*');		
	}

	baixarArquivoErro(idArquivo: number) {

		this.listaService.baixarArquivoErro(idArquivo).subscribe(
			(res) => {
				let extensao = res["_body"].type == "application/text" ? "txt" : "zip";
				let nomeArquivo = `Arquivo_${idArquivo}.${extensao}`;
				(downloadFile(res, nomeArquivo))
			},
			() => (this.customAlertService.show("telaEstrategia.download", "telaEstrategia.expurgoArquivo", "info")));
	}
}
