export enum SituacaoExecucao {
    play = 1,
    pause = 2,
    cancel = 3
}

export enum TipoEstrategia {
	provedor = 1,
	arquivo = 2
}

export enum EstrategiaRecorrencia {
	imediata = 1,
	agendada = 2,
	recorrente = 3
}

export enum EstrategiaAcaoCampanhaTipo {
	Exportacao = 1,
	RemocaoCampanhaEpbx = 2,
	RemocaoTodasCampanhasEpbx = 3,
	Multipla = 4,
	ExportacaoFluxo = 5
}