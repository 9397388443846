import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NaturalLanguage } from "../../../common/model/natural-language";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EsBuilderService {

    private api: string;

    onDadosListaCarregados: EventEmitter<any> = new EventEmitter();
    onDadosListaExpressaoCarregados: EventEmitter<any> = new EventEmitter();
    onFilterTypeChange: EventEmitter<any> = new EventEmitter();
    // private messageSource = new BehaviorSubject('default message');
    // currentMessage = this.messageSource.asObservable();


    constructor(private _http: HttpClient) {
        this.onDadosListaCarregados = new EventEmitter();
        this.onDadosListaExpressaoCarregados = new EventEmitter();

        this.api = `/api/search`;
    }

    emitDadosListaCarregados(listaNome: string, templateId: number) {
        this.onDadosListaCarregados.emit({ 'listaNome': listaNome, 'templateId': templateId });
    }

    emitDadosListaExpressaoCarregados(listaExpressao: any) {
        this.onDadosListaExpressaoCarregados.emit(listaExpressao);
    }

    emitFilterTypeChange(message: string){
        //this.messageSource.next(message)
        this.onFilterTypeChange.emit(null)
    }

    public searchByNaturalLanguage(naturalLanguage: NaturalLanguage): Observable<NaturalLanguage> {
		return this._http.post<NaturalLanguage>(`${this.api}/busca-por-linguagem-natural`, naturalLanguage);
	}
}
