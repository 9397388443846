import { Directive, ElementRef, Input } from "@angular/core";
import { ImportacaoAutomaticaHistoricoStatus } from "../../common/model/importacao-automatica-historico";

@Directive({
	selector: "[appImportacaoAutomaticaHistoricoStatusDisplay]"
})
export class ImportacaoAutomaticaHistoricoStatusDisplayDirective {
	_status: number;

	@Input("appImportacaoAutomaticaHistoricoStatusDisplay")

	public set status(status: ImportacaoAutomaticaHistoricoStatus) {
		this._status = status;
		this.changeDisplayName();
	}

	public get status(): ImportacaoAutomaticaHistoricoStatus {
		return this._status;
	}

	changeDisplayName() {
		switch (parseInt(<any>this.status)) {
			case ImportacaoAutomaticaHistoricoStatus.uploadArquivos:
				this.el.nativeElement.innerHTML = "Upload de Arquivos";
				break;
			case ImportacaoAutomaticaHistoricoStatus.processandoArquivos:
				this.el.nativeElement.innerHTML = "Processando Arquivos";
				break;
			case ImportacaoAutomaticaHistoricoStatus.concluidoComErros:
				this.el.nativeElement.innerHTML = "Concluído Com Erros";
				break;
			case ImportacaoAutomaticaHistoricoStatus.concluido:
				this.el.nativeElement.innerHTML = "Concluído";
				break;
			case ImportacaoAutomaticaHistoricoStatus.erro:
				this.el.nativeElement.innerHTML = "Erro";
				break;
		}
		return "";
	}

	constructor(private el: ElementRef) { }
}