import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, ViewChild, NgZone } from "@angular/core";

import { ListaService } from "../../common/service/lista.service";
import { MatDialog } from '@angular/material';
import { FiltroLista } from "../../common/model/filtro-lista";
import { Lista } from "../../common/model/lista";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { PreviewDadosHistoricoComponent } from "../historico-indexacao/preview-dados-historico/preview-dados-historico.component";
import { Table } from "primeng/table";
import { ActivatedRoute } from "@angular/router";
import * as dayjs from 'dayjs';
import * as validate from "validate.js";
import { environment } from "../../../../environments/environment";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";
import * as signalR from "@aspnet/signalr";
import { Carteira } from "../../common/model/carteira";
import { CarteiraService } from "../../common/service/carteira.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { ConfigProvider } from "../../../common/providers/config.provider";

@Component({
	selector: "app-lista-dashboard",
	templateUrl: "./lista-dashboard.component.html",
	styleUrls: ["./lista-dashboard.component.scss"],
	providers: [
		{ useClass: ListaService, provide: ListaService },
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ provide: CarteiraService, useClass: CarteiraService }
	]
})
export class ListaDashboardComponent implements OnInit, OnDestroy {
	preferenciasUsuario: PreferenciasUsuario;
	dirty: boolean;
	filtro: any = {};
	listas: Array<Lista>;
	listaOrigem: any = [] ;
	listasEmIndexacao: Array<any> = [];
	listasIndexadas: Array<any> = [];
	carteiras: Array<Carteira> = [];
	quantidadeTotal: number;
	

	private _hubConnection: HubConnection | undefined;
	signalRStatus: boolean = false;

	//#region [ Validação ]

	private _regrasValidacao: any = {
		"dataInicio": {
			"presence": { allowEmpty: false, message: "campoObrigatorio" },
			"dataMenorQue": { campo: "dataFim" }
		},
		"dataFim": {
			"presence": { allowEmpty: false, message: "campoObrigatorio" }
		}
	};

	public get erros() {
		if (!this.dirty) return;
		return validate(this, this._regrasValidacao, { fullMessages: false });
	}

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	//#endregion

	@ViewChild(Table, { static: false }) dataTable: Table;
	constructor(
		private carteiraService: CarteiraService,
		private listaService: ListaService,
		private preferenciasService: PreferenciasUsuarioService,
		private customAlertService: CustomAlertService,
		private translateService: TranslateService ,
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private zone: NgZone,
		private configProvider: ConfigProvider
	) {
		// Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();
        this.listaOrigem = [{id:1, value: translateService.instant("telaIndexacao.importacao")},
		                    { id: 2, value: translateService.instant("telaIndexacao.retorno")},
							{ id: 3, value: translateService.instant("telaIndexacao.exportacao")}]
		this.setarDatasPadrao();
		this.route.params.subscribe(params => this.filtro.lista = parseInt(params.id));
		this.conectarSignalR();
	}

	//#region [ Eventos da página ]

	ngOnInit() {
		this.carregarCarteira();
	}

	ngOnDestroy() {
		this.paraEscutarProgressoLista();
	}

	//#endregion 	

	//#region [ Eventos de changed ]

	carteiraChange() {
		this.carregarListas();
		this.carregarListasEmIndexacao();
		this.carregarListasIndexadas();
	}

	//#endregion 

	//#region [ Carregar dados ]

	carregarCarteira() {
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
			this.carteiras = carteiras.sort((a,b) => {
				if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
					return -1;
				}
				if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
					return 1;
				}
				return 0;
			});

			if (this.preferenciasUsuario.existePreferenciaCarteira) {
				this.filtro.carteiraId = this.preferenciasUsuario.carteiraId as number;
			} else {
				let padrao = this.carteiras.find(f => f.padrao) as Carteira;
				this.filtro.carteiraId = padrao.carteiraId;
			}

			this.carregarListas();
			this.carregarListasEmIndexacao();
			this.carregarListasIndexadas();
		});
	}

	setarDatasPadrao() {
		let dataFim = new Date();
		let dataInicio = new Date(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate() - 5);
		this.filtro.dataInicio = dataInicio;
		this.filtro.dataFim = dataFim;
		this.filtro.origemId=1;
		
	}

	carregarListas() {
		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = false;
		filtroLista.carteiraId = this.filtro.carteiraId;

		if (this.preferenciasUsuario.existePreferenciaLista) {
			this.filtro.lista = this.preferenciasUsuario.listaId as number;
		}

		this.paraEscutarProgressoLista();
		this.listaService.obterListaSemQuantidade(filtroLista).subscribe(listas => {
			this.listas = listas.sort((a,b) => {
                    if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                        return -1;
                    }
                    if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                        return 1;
                    }
                    return 0;
                });
			this.escutarProgressoListas(this.listas);
			this.reconectarSignalR(this.listas);
			
		});
	}

	carregarListasEmIndexacao() {
		this.listaService.obterListaEmIndexacao(this.filtro.carteiraId)
			.subscribe((listaLista: any[]) => {
				this.listasEmIndexacao = listaLista;

				if (this.listasEmIndexacao.length > 0)
					this.escutarProgressoListas(this.listasEmIndexacao);
			});
	}

	carregarListasIndexadas() {
		let inicio = dayjs(this.filtro.dataInicio).format("YYYY/MM/DD") + " 00:00:00";
		let fim = dayjs(this.filtro.dataFim).format("YYYY/MM/DD") + " 23:59:59";
   		this.listaService.obterHistoricoIndexacaoTodasListas(this.filtro.lista, inicio, fim, this.filtro.carteiraId,this.filtro.origemId).subscribe(result => {
			this.listasIndexadas = result;
			this.setCurrentPage(0);
			this.quantidadeTotal = result.map(x => x.registrosOk ).reduce(function (a, b) {
				return a + b;
			}, 0);
		});
	}

	carregarTempoExecucao() {
		// 	setInterval(() => {
		// 		if (this.listasEmIndexacao != null) {
		// 			this.listasEmIndexacao.forEach(lista => {
		// 				if (lista["execucaoInicio"] && (lista["execucaoAtualDiferenca"] != undefined || lista["execucaoAtualDiferenca"] != null)) {
		// 					var execucaoInicio = dayjs(lista["execucaoInicio"], "DD/MM/YYYY HH:mm:ss").add(lista["execucaoAtualDiferenca"], 'seconds');
		// 					var execucaoAtual = dayjs();
		// 					lista['tempoExecucao'] = moment.utc(execucaoAtual.diff(execucaoInicio)).format('HH:mm:ss');
		// 				}

		// 				if (lista["tempoPagina"] && lista["paginasRestantes"] && lista["execucaoAtual"] && (lista["execucaoAtualDiferenca"] != undefined || lista["execucaoAtualDiferenca"] != null)) {
		// 					var execucaoAtual = dayjs(lista["execucaoAtual"], "DD/MM/YYYY HH:mm:ss").add(lista["execucaoAtualDiferenca"], 'seconds');
		// 					let seguntosRestantes = lista["tempoPagina"] * lista["paginasRestantes"];
		// 					lista['terminoPrevisto'] = execucaoAtual.add(seguntosRestantes, 'seconds').format('HH:mm:ss');
		// 				}
		// 			});
		// 		}
		// 	}, 1000);
	}

	//#endregion

	//#region [ Eventos da Lista ]	

	filtrarLista() {
		this.carregarListasIndexadas();
	}

	limpar() {
		this.filtro = {};
		let padrao = this.carteiras.find(f => f.padrao);
		this.filtro.carteiraId = padrao.carteiraId;
	}

	setCurrentPage(n: number) {
		if (this.dataTable)
			this.dataTable.first = n;
	}

	pausarLista(lista: Lista) {
		this.customAlertService
			.confirmationMessage("telaLista.confirmacaoPausar")
			.then(() => this.listaService.pausarLista(lista.listaId).subscribe(() => lista.pausarLista()));
	}

	ativarLista(lista: Lista) {
		this.listaService.ativarLista(lista.listaId).subscribe(() => lista.ativarLista());
	}

	mostrarPreview(historico: any) {
		this.dialog.open(PreviewDadosHistoricoComponent, {
			hasBackdrop: true,
			height: "650px",
			width: "80%",
			data: { historicoIndexacaoId: historico.historicoIndexacaoId }
		});
	}

	//#endregion

	//#region [ SignalR ]

	public conectarSignalR() {

		let endpoint = this.configProvider.getConfig().serverUrl;

		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${endpoint}/notificacaohub`, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
			.configureLogging(signalR.LogLevel.Information)
			.build();
	}

	public reconectarSignalR(listas: Array<any>) {
		this._hubConnection.onclose(() => {
			this.signalRStatus = false;
			this.conectarSignalR();
			this.escutarProgressoListas(listas);
			this.reconectarSignalR(listas);
		});
	}

	public escutarProgressoListas(listas: Array<any>) {
		return this._hubConnection.start().then(() => {
			this.signalRStatus = true;
			this._hubConnection.on("ProgressoIndexacao", conteudo => {
				this.listasEmIndexacao = conteudo;
			});
		});
	}

	public paraEscutarProgressoLista() {
		this._hubConnection.stop();
	}

	//#endregion 
}
