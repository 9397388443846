import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NotificacaoEvento } from "../../../common/model/notificacao";

@Pipe({ name: "eventoNotificacao" })
export class NotificacaoEventoPipe implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(value: NotificacaoEvento, args?: any): any {
        switch (value) {
            case NotificacaoEvento.ExportacaoEstrategia:
                return this.translate.instant('telaNotificacao.exportacaoEstrategia');
            case NotificacaoEvento.IndexacaoLista:
                return this.translate.instant('telaNotificacao.indexacaoLista');
            case NotificacaoEvento.DownloadRegistro:
                return this.translate.instant('telaNotificacao.downloadRegistro');
            case NotificacaoEvento.EnvioSemRetorno:
                return this.translate.instant('telaNotificacao.envioSemRetorno');
            case NotificacaoEvento.ProcessamentoRetorno:
                return this.translate.instant('telaNotificacao.processamentoRetorno');

            default:
                return "";
        }
    }
}