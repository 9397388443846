import * as validateJS from "validate.js";
import { EstrategiaRefactor } from "./estrategiaRefactor";
import { Template } from "./template";
import { ESBuilderQuantityList } from "../../esbuilder/models";

export class ReguaEstrategia {
    reguaEstrategiaId: number;
    reguaId: number;
    listaEstrategiaId: number | null;
    ordem: number;
    dataCadastro: Date;
    usuarioCadastro: number;
    dataAlteracao: Date;
    usuarioAlteracao: number;
    ativo: boolean;
    estrategia: EstrategiaRefactor | null;

    // Usados apenas na tela
    vincularEstrategia: boolean = false;
    criarEstrategia: boolean = false;
    estrategiaSelecionada: number = 0;
    estrategiaNomeInformado: string = '';
    provedorSelecionado: number = 0;
    listaEstrategiaTipoId: number | null;
    templateSelecionado: number = 0;
    campanhaSelecionada: number = 0;
    estrategiaEnviarReutilizar: boolean = false;
    envioManualRealizado: boolean = false;

    dirtyVincularEstrategia: boolean = false;
    dirtyCriarEstrategia: boolean = false;
    templatesExportacaoFiltrado: Array<Template> = [];
    campanhas: Array<any> = [];
    quantityList: ESBuilderQuantityList = new ESBuilderQuantityList();
    
    constructor(reguaId: number, listaEstrategiaId: number | null, ordem: number) {
        this.reguaId = reguaId;
        this.listaEstrategiaId = listaEstrategiaId;
        this.ordem = ordem;
        this.ativo = true;
    }

    static fromRaw(rawRegua: any): ReguaEstrategia {
        return Object.assign(new ReguaEstrategia(0, 0, 0), rawRegua);
    }

    // #region [ Validação ]

    validarVincularEstrategia() {
        let regras: any = {
            'estrategiaSelecionada': {
                'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                'numericality': { 'onlyInteger': true, 'greaterThan': 0, 'message': '^menorque' }
            }
        };

        return validateJS(this, regras);
    }

    validarCriarEstrategia(validarCampoCampanha: boolean) {
        let regrasPadrao: any = {
            'estrategiaNomeInformado': {
                'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' }
            },
            'provedorSelecionado': {
                'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                'numericality': { 'onlyInteger': true, 'greaterThan': 0, 'message': '^menorque' }
            },
            'templateSelecionado': {
                'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                'numericality': { 'onlyInteger': true, 'greaterThan': 0, 'message': '^menorque' }
            }
        };

        let regrasCampoCampanha = this.regrasValidacaoCampoCampanha(validarCampoCampanha);

        let regras = {
            ...regrasPadrao,
            ...regrasCampoCampanha
        };

        return validateJS(this, regras);
    }

    private regrasValidacaoCampoCampanha(validarCampoCampanha) {
        let regras: any = {};

        if (validarCampoCampanha) {
            regras.campanhaSelecionada = {
                'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
                'numericality': { 'onlyInteger': true, 'greaterThan': 0, 'message': '^menorque' }
            };
        }

        return regras;
    }

    //#endregion
}

export class ReguaEstrategiaEstrategia {

}

