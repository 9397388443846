import { Component, Input, ElementRef, HostListener, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FabMenuModel } from './fab-menu.model'

@Component({
    selector: 'fab-menu',
    templateUrl: "./fab-menu.component.html",
    styleUrls: ["./fab-menu.component.scss"]
})
export class FabMenuComponent {

    public _active: any;
    public _element: any;
    @ViewChildren('anchor') buttons: QueryList<ElementRef>;
    @Input() dir = 'up';
    @Input() itens: FabMenuModel[];

    get active() {
        return this._active;
    }

    set active(val) {
        this.updateButtons(val);
        this._active = val;
    }

    constructor(element: ElementRef) {
        this._element = element.nativeElement;
    }

    toggleClick(event: any) {
        this.active = !this.active;
    }

    getTranslate(idx: number) {
        //console.log(`AIDX:${idx}`);

        let n: number = 53;
        if (idx == 1)
            n = 60;
        else if (idx == 2)
            n = 55;
        else
            n = 53;

        //console.log(`IDX:${idx} / N:${n}`);

        if (this.dir === 'left') {
            return `translate3d(${(0 - n) * idx}px,0,0)`;
        } else if (this.dir === 'right') {
            return `translate3d(${n * idx}px,0,0)`;
        } else if (this.dir === 'up') {
            return `translate3d(0,${(0 - n) * idx}px,0)`;
        } else if (this.dir === 'down') {
            return `translate3d(0,${n * idx}px,0)`;
        } else {
            console.error(`Unsupported direction for FabMenu; ${this.dir}`);
        }
    }

    updateButtons(active) {
        let idx = 1;

        this.buttons.forEach(btn => {

            let style = btn.nativeElement.style;
            style['transition-duration'] = active ? `${90 + (100 * idx)}ms` : '';
            style['transform'] = active ? this.getTranslate(idx) : '';
            idx++;
        });
    }

    @HostListener('document:click', ['$event.target'])
    onDocumentClick(target: any) {
        if (this.active && !this._element.contains(target)) {
            this.active = false;
        }
    }
}