import { validate } from "validate.js";

import { OrderOperation } from "./ordertypes";

export class Order {
	listaEstrategiaPrioridadeId:number;
	ordem: number;
	ativo: boolean=true;
	listaPrioridadePadraoItemId:number;
	listaPrioridadePadraoNome:string;
	listaTemplateItemId?: string;
	operador?: OrderOperation;
	valor?:any;

	constructor(
	) { }

	validate(validarValor: boolean = true) {

		let validacao: any = {
			listaTemplateItemId: { presence: { allowEmpty: false } },
			operador: { presence: { allowEmpty: false } }
		};

		if (validarValor)
			validacao.valor = { presence: { allowEmpty: false } };

		return validate(this, validacao);
	}

	static fromRaw(rawFluxo: any): Order {
        return Object.assign(new Order(), rawFluxo);
    }
}
