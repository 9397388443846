import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";

const nomeCrescente = "nomeCrescente";
const nomeDecrescente = "nomeDecrescente";
const valorCrescente = "valorCrescente";
const valorDecrescente = "valorDecrescente";

function toInt(text: string) {
  return parseInt(text.replace(".", ""));
}

@Component({
  selector: "app-custom-listbox",
  styleUrls: ["./custom-listbox.component.scss"],
  templateUrl: "./custom-listbox.component.html"
})
export class CustomListboxComponent implements OnInit {
  // @Input()
  // focus: boolean;
  _focus: boolean;
  @ViewChild("input", {static: false})
  input: any;
  @Input()
  label: string;
  @Input()
  cssHeight: string = '';
  @Input()
  value: string;
  _data: Array<any>;
  formatedData: Array<any>;
  @Input("model")
  model;
  @Output("modelChange")
  modelChange: EventEmitter<any>;
  @Output("changed")
  changed: EventEmitter<any>;
  @Input()
  disabled: boolean;
  @Input()
  multiple: boolean;
  @Input()
  showToggleAll: boolean;
  filter: string;
  @Input()
  enableOrderBy: boolean = false;
  @Input()
  showFilter: boolean = true;
  
  orderBy;
  listaTipoOrdenacao = [
    {
      nome: nomeCrescente,
      func: (data: Array<any>) =>
        data.sort((a, b) => a.label.localeCompare(b.label))
    },
    {
      nome: nomeDecrescente,
      func: (data: Array<any>) =>
        data.sort((a, b) => b.label.localeCompare(a.label))
    },
    {
      nome: valorCrescente,
      func: (data: Array<any>) =>
        data.sort((a, b) => toInt(a.suffix) - toInt(b.suffix))
    },
    {
      nome: valorDecrescente,
      func: (data: Array<any>) =>
        data.sort((a, b) => toInt(b.suffix) - toInt(a.suffix))
    }
  ];

  @Input()
  public set focus(val: boolean) {
    this._focus = val;
    this.filter = "";
    this.onFocus();
  }

  public get focus() {
    return this._focus
  }

  @Input()
  public set data(data: Array<any>) {
    if (data == null) return;
    this._data = data;
    this.formatedData = this.data.map(item =>
      Object.assign(
        {
          label: item[this.label],
          value: item[this.value]
        },
        item
      )
    );
  }

  filteredClick:boolean = false;

  public get filteredFormatedData() {
	if (!this.filter || this.filteredClick) return this.formatedData;
    return this.formatedData.filter(
      c => c.label.toLowerCase().indexOf(this.filter.toLowerCase()) != -1
    );
  }

  filterChange(event){
	this.filteredClick = false;	
  }

  public get data() {
    return this._data;
  }

  constructor() {
    this.formatedData = [];
    this.modelChange = new EventEmitter<any>();
    this.changed = new EventEmitter<any>();
  }

  isChecked(item) {
    if (Array.isArray(this.model)) return this.model.some(c => c == item);
    return false;
  }

  changeValue(data) {

	this.filteredClick = true;

    this.filter = data.label;
    this.modelChange.emit(data.value);
    this.changed.emit(data.value);
  }

  changeArray(item, event) {
    if (Array.isArray(this.model) == false) this.model = [];
    var data = this.model.map(c => c);
    if (event.checked) data.push(item);
    else data = data.filter(c => c != item);

    this.modelChange.emit(data);
    this.changed.emit(data);
  }

  changeOrder() {
    var query = this.listaTipoOrdenacao.find(c => c.nome == this.orderBy);
    this.formatedData = query.func(this.formatedData);
  }

  ngOnInit() {
    this.onFocus();
  }

  onFocus() {
    if (this._focus) setTimeout(() => this.input.nativeElement.focus(), 0);
  }
}
