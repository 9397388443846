import { Login } from "../model/login";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit,Inject } from "@angular/core";
import { AutenticacaoService } from "../../common/service/autenticacao.service";
import { UsuarioService } from "../../common/service/usuario.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { PermissoesUsuario } from "../../common/model/permissoes-usuario";
import { PermissaoDefault } from "../../common/model/permissao-default";
import * as validate from "validate.js";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Usuario } from "../../common/model/usuario";

@Component({
  selector: "app-esqueci-senha",
  templateUrl: "./esqueci-senha.component.html",
  providers: [{ useClass: CustomAlertService, provide: CustomAlertService },
    { useClass: UsuarioService, provide: UsuarioService }]
})
export class EsqueciSenhaComponent implements OnInit {
  public email:string="";
  public usuario: Usuario = new  Usuario();
  public error:any;
  
  constructor(
    private usuarioService: UsuarioService,
    private customAlertService: CustomAlertService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<EsqueciSenhaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  {
  }

  ngOnInit() {
    
  }

  recuperarSenha() {
    let validacao=this.validate();
    if(validacao == null){
      this.error=null;
      this.usuario = new Usuario();
      this.usuario.email= this.email;
      this.usuarioService.recuperarSenha(this.usuario).subscribe(s=>
        {
          if(s.isValid){
            this.customAlertService.show(
              "telaUsuario.recuperacaoSenha",
              "login.sucessoRecuperacao",
              "success"
            );
            this.dialogRef.close();
          }
          else{
            this.customAlertService.show(
              "Login",
              "login.usuarioNaoEncontrado",
              "error"
            );
          }
        }
      );
    }
    else{
      this.error = validacao;
    }
  }
  fechar() {
    this.dialogRef.close();
  }

  validate() {
    return validate.validate(this, { email: { email: true } });
  }
}
