
import { map, filter } from 'rxjs/operators';
import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { TemplateService } from "../../common/service/template.service";
import { ListaPrioridadePadraodService } from "../../common/service/lista-prioridade-padrao.service";
import { ElasticsearchRepositoryService } from "../repository/elasticsearch-repository.service";
import { formatFieldName } from "../model/elasticsearch-field-formarter";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DefaultValuesService } from "../../common/service/default-values.service";
import { BaseElasticsearchFilter } from "../model/elasticsearch-filter/base";
import { changeOrder } from "../../common/model/change-collection-item-order";
import { FiltroStatusRegistroLista } from '../../common/model/lista';
import { isNullOrEmpty, isNullOrZero } from '../../common/utils';
import { ElasticsearchAddOrderComponent } from '../elasticsearch-add-order/elasticsearch-add-order.component';
import { ListaPrioridadePadrao } from '../../common/model/lista-prioridade-padrao';
import { array } from '@amcharts/amcharts4/core';
import { CustomAlertService } from '../../common/service/custom-alert.service';
import { Order } from '../../estrategia/models/order';

@Component({
	selector: "app-elasticsearch-add-order-modal",
	styleUrls: ["./elasticsearch-add-order-modal.component.scss"],
	templateUrl: "./elasticsearch-add-order-modal.component.html",
	providers: [
		{ useClass: TemplateService, provide: TemplateService },
		{ useClass: ListaPrioridadePadraodService, provide: ListaPrioridadePadraodService },
		{ useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ElasticsearchAddOrderModalComponent implements OnInit {

	listaPrioridades: ListaPrioridadePadrao[] = [];
	listaPrioridadesSelecionadas: ListaPrioridadePadrao[] = [];

	@ViewChild("elasticsearchOrder", { static: false })
	elasticsearchOrder: ElasticsearchAddOrderComponent;

	constructor(
		private ref: MatDialogRef<ElasticsearchAddOrderModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private templateService: TemplateService,
		private customAlertService: CustomAlertService,
		private listaPrioridadePadraodService: ListaPrioridadePadraodService,
		private elasticsearchService: ElasticsearchRepositoryService,
		private defaultValuesService: DefaultValuesService
	) {
		this.carregarMarcarListaPadraoSelecionada(data.listaId);
	}


	carregarMarcarListaPadraoSelecionada(listaId) {
		this.listaPrioridadePadraodService.obterListasPrioridadePadrao({ 'listaId': listaId }).subscribe((result: ListaPrioridadePadrao[]) => {
			
			this.listaPrioridades = result;

			let ids: any[] = this.data.listaEstrategiaPrioridade.filter((f: Order) => isNullOrZero(f.listaPrioridadePadraoItemId) == false && f.ativo).map((m: any) => m.listaPrioridadePadraoItemId);

			this.listaPrioridades.forEach((f: ListaPrioridadePadrao) => {
				if (f.listaPrioridadePadraoItens.some((s: any) => ids.includes(s.listaPrioridadePadraoItemId)))
					this.listaPrioridadesSelecionadas.push(f);
			})

		});
	}

	aplicar(ordenacao: ListaPrioridadePadrao) {
		let AddOrdenacao: boolean = false;

		//Verificar se esta adicionando ou removendo uma ordenação padrão
		ordenacao.listaPrioridadePadraoItens.forEach(item => {
			let existe = this.elasticsearchOrder.orderCollection.filter((ordenacaoLista: Order) => item.listaPrioridadePadraoItemId == ordenacaoLista.listaPrioridadePadraoItemId && ordenacaoLista.ativo)

			if (!isNullOrEmpty(existe) || existe.length > 0) {
				this.elasticsearchOrder.orderCollection = this.elasticsearchOrder.orderCollection.filter((f: Order) => f.listaPrioridadePadraoItemId !== item.listaPrioridadePadraoItemId);
			}
			else {
				if (!AddOrdenacao)
					AddOrdenacao = true;
			}
		});

		let ordenacaoFinal = [];
		//Separa as ordenações por grupo para adicionar na ordem correta
		let ordernacaoPadrao = this.elasticsearchOrder.activeOrderCollection.filter((f: Order) => !isNullOrZero(f.listaPrioridadePadraoItemId));
		let ordenacaoLocal = this.elasticsearchOrder.activeOrderCollection.filter((f: Order) => isNullOrZero(f.listaPrioridadePadraoItemId));
		//Primeiro as ordenações padrões já existente
		ordernacaoPadrao.forEach(element => {
			element.ordem = ordenacaoFinal.length;
			ordenacaoFinal.push(element);
		});

		//adiciona nova ordenação
		if (AddOrdenacao) {
			let campoNaoAdicionado = true;

			//Valida se não já existe esse campo já adicionado em outra ordenação
			ordenacao.listaPrioridadePadraoItens.forEach(item => {
				let existe = this.elasticsearchOrder.orderCollection.filter((ordenacaoLista: Order) => item.listaTemplateItemId == ordenacaoLista.listaTemplateItemId && ordenacaoLista.ativo)

				if (!isNullOrEmpty(existe) || existe.length > 0) {
					this.customAlertService.show("telaPrioridadePadrao.PrioridadePadrao", "telaPrioridadePadrao.erroAdd", "error");
					campoNaoAdicionado = false;
					return
				}
			});

			if (campoNaoAdicionado) {
				this.listaPrioridadesSelecionadas.push(ordenacao);

				//Segundo a nova ordenação padrão
				ordenacao.listaPrioridadePadraoItens.forEach(element => {
					element.listaPrioridadePadraoNome = ordenacao.nome;
					element.ordem = ordenacaoFinal.length;
					ordenacaoFinal.push(element);
				});
			}
		}
		else {
			this.listaPrioridadesSelecionadas = this.listaPrioridadesSelecionadas.filter(o => o !== ordenacao);
		}

		//Terceiro as ordenações locais
		ordenacaoLocal.forEach(element => {
			element.ordem = ordenacaoFinal.length;
			ordenacaoFinal.push(element);
		});

		this.elasticsearchOrder.orderCollection = ordenacaoFinal;
	}

	selecionado(item) {
		return this.listaPrioridadesSelecionadas.filter(o => o === item).length > 0;
	}

	save() {
	
		var ids = this.elasticsearchOrder.orderCollection.map((m: Order) => m.listaEstrategiaPrioridadeId);

		this.elasticsearchOrder._listaEstrategiaPrioridade.forEach((valor: Order) => {
			if (!ids.includes(valor.listaEstrategiaPrioridadeId)) {
				valor.ativo = false;
				this.elasticsearchOrder.orderCollection.push(valor);
			}
		});
		
	
		this.ref.close(this.elasticsearchOrder.orderCollection);
	}

	close() {
		this.ref.close();
	}

	ngOnInit() { }
}
