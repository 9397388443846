import { ModelToQuery } from "../../esbuilder/libs/utils";
import { ESBuilderData, ESBuilderRules } from "../../esbuilder/models";
import { isNull, isNullOrEmpty, isNullOrZero, isObjectEmpty } from "../utils";

export class FiltroQueryAgrupado {
    filtroQueryPadraoId: number;
    filtroQueryNome: string;
    listaNome: string;

    constructor() {
    }

    static fromRaw(rawFluxo): FiltroQueryAgrupado {
        return Object.assign(new FiltroQueryAgrupado(), rawFluxo);
    }

}
