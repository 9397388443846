import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NNotificacao } from "../../common/model/nnotificacao";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { NotificacaoService } from "../../common/service/notificacao.service";
import { NotificacaoEventoPipe } from "../libs/pipes/notificacao-evento-pipe"
import { isNull, isNullOrEmpty } from "../../common/utils";

@Component({
    selector: "app-notificacao-configuracao-email",
    templateUrl: "./notificacao-configuracao-email.component.html",
    styleUrls: ["./notificacao-configuracao-email.component.scss"],
    providers: [
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: NotificacaoEventoPipe, provide: NotificacaoEventoPipe }
    ]
})
export class NotificacaoConfiguracaoEmailComponent implements OnInit {

    submitted: boolean = false;

    //#region [ GET/SET - Notificacao ]  

    _notificacao: NNotificacao;

    public get notificacao() {
        return this._notificacao;
    }

    @Input()
    public set notificacao(v: NNotificacao) {
        if (!v) return;
        this._notificacao = v;
    }

    //#endregion

    liveError(property: string, message: string) {
        if (this.submitted) {
            let result = this.notificacao.validateEmail();
            if (!result) return null;
            result = eval('result.notificacaoConexaoDb[0].errors[0]');

            if (isNullOrEmpty(message))
                return (isNull(result[property]) == false);
            else
                return result[property] ? result[property][0] == message : false;
        }
    }

    constructor(
        private notificacaoService: NotificacaoService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        private customAlertService: CustomAlertService) {
    }

    ngOnInit() { }
}