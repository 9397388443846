import { ValidacaoSenhaModel } from "../model/validacao-senha";

   export function mostrarSenha(input){
    input.type = input.type =="password" ? "text" : "password";
   }

   function compararSenha(filtro:ValidacaoSenhaModel,senha:string )
   {
       if (senha != filtro.confirmacaoSenha)
        filtro.senhaDiferente = true;
       else
        filtro.senhaDiferente = false;
   }

   export function impedirEspaco(event: any) {
       if(event.key === " ") 
           event.preventDefault();
     }

   export function onKey(filtro:ValidacaoSenhaModel,senha:string): ValidacaoSenhaModel{
       compararSenha(filtro,senha);
       checkStrength(filtro,senha);
       return filtro;
   }

   function checkStrength(filtro:ValidacaoSenhaModel, senha:string) {
       filtro.strength = 0;
       if (senha != undefined )
       {
            //If password contains both lower and uppercase characters
            if (senha.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
                filtro.strength += 1;
                filtro.lowUpperCase = true;
            } else {
                filtro.lowUpperCase = false;
            }

            //If it has numbers and characters
            if (senha.match(/([0-9])/)) {
                filtro.strength += 1;
                filtro.number = true;
            } else {
                filtro.number = false;
            }
            //If it has one special character
            if (senha.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
                filtro.strength += 1;
                filtro.specialChar = true;
            } else {
                filtro.specialChar = false;
            }
            //If password is greater than 7
            if (senha.length > 7) {
                filtro.strength += 1;
                filtro.eightChar = true;
            } else {
                filtro.eightChar = false;  
            }
        }
   }