import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ConfiguracaoCampoProvider, ProvedorConfiguracaoTipo } from "../../../common/model/configuracao-campo-provedor";

@Component({
  selector: "app-campo-modal",
  templateUrl: "./campo-modal.component.html",
  styleUrls: ["./campo-modal.component.scss"]
})
export class CampoModalComponent implements OnInit {
  public isDirty: boolean;
  public provedorConfiguracaoTipo: string;

  public get erros() {
    if (this.isDirty)
      return this.data.campoTipo.validate();
  }

  constructor(
    private ref: MatDialogRef<CampoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.provedorConfiguracaoTipo = ProvedorConfiguracaoTipo[this.data.campoTipo.provedorConfiguracaoTipoId];
  }

  ngOnInit() { }

  close() {
    this.ref.close();
  }

  save() {
    this.isDirty = true;
    if (this.erros)
      return;

    this.ref.close(this.data.campoTipo);
  }
}