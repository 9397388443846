export enum OrderOperation {
  asc = 5,
  desc = 6,
  equal = 1,
  biggerThan = 2,
  smallerThan = 3,
  in = 4  
}

export const OrderTypes = {
  Texto: [
    OrderOperation.equal,
    OrderOperation.in,
    OrderOperation.asc,
    OrderOperation.desc
  ],
  Decimal: [
    OrderOperation.equal,
    OrderOperation.biggerThan,
    OrderOperation.smallerThan,
    OrderOperation.asc,
    OrderOperation.desc
  ],
  Inteiro: [
    OrderOperation.equal,
    OrderOperation.biggerThan,
    OrderOperation.smallerThan,
    OrderOperation.asc,
    OrderOperation.desc
  ],
  Long: [
    OrderOperation.equal,
    OrderOperation.biggerThan,
    OrderOperation.smallerThan,
    OrderOperation.asc,
    OrderOperation.desc
  ],
  Data: [
    OrderOperation.equal,
    OrderOperation.biggerThan,
    OrderOperation.smallerThan,
    OrderOperation.asc,
    OrderOperation.desc
  ],
  Lista: [
    OrderOperation.in,
    OrderOperation.asc,
    OrderOperation.desc
   
  ]
  
};
