import { lPad } from "./lPad";


export function generateMinutes(min: number = 1) {
    let minutes = [];

    for (let i = 0; i < 59; i = i + min)
        minutes.push((i < 10) ? lPad(i.toString(), 2) : i.toString());

    return minutes;
}