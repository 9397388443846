
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/do";

@Injectable()
export class CampanhaService {
  private api: string;

  constructor(private httpClient: HttpClient) { 
      this.api = '/api/campanhas';
  }

  obterCampanhas() {
    return this.httpClient
      .get<any>(`${this.api}`).pipe(
      map(campanhas =>
        Object.keys(campanhas).map(id => ({
          id: parseInt(id),
          nome: campanhas[id]
        }))
      ));
  }
}
