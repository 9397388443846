import { Directive, Input, ElementRef } from "@angular/core";

@Directive({
  selector: "[appPercentage]"
})
export class PercentageDirective {
  _percentage;
  @Input("appPercentage")
  public set percentage(percentage: number) {
    if (!percentage) percentage = 0;
    this._percentage = percentage;
    this.updateInterface();
  }

  public get percentage(): number {
    return this._percentage;
  }
  constructor(private el: ElementRef) {}

  public updateInterface() {
    this.el.nativeElement.innerHTML = `${this.percentage
      .toFixed(2)
      .replace(".", ",")} %`;
  }
}
