// const handler = {
//   get: function(target, name) {
//     if (!target.hasOwnProperty(name)) return undefined;

//     try {
//       return JSON.parse(target[name]);
//     } catch {
//       return target[name];
//     }
//   },

//   set: function(obj, prop, value) {
//     obj[prop] = typeof value === "object" ? JSON.stringify(value) : value;
//     return true;
//   }
// };

// export const StorageProxy = new Proxy(localStorage, handler);



export const StorageProxy = {
  getString: (prop: string): string => {
    return localStorage.getItem(prop);
  },
  get: <T extends object>(prop: string): T => {
    const data = localStorage.getItem(prop);
    try {
      return JSON.parse(data) as T;
    } catch {
      return null;
    }
  },
  set: (prop: string, value: any) => {
    localStorage.setItem(prop, typeof value === 'object' ? JSON.stringify(value) : value);
  }
};
