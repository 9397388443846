import { LOCALE_ID, NgModule } from "@angular/core";
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule
} from "@angular/material";
import { AppCommonModule } from "../common/common.module";
import { CommonModule } from "@angular/common";
import { FormBuilder, FormsModule } from "@angular/forms";
import { TableModule } from "primeng/table";
import { ListaNotificacaoAntigoComponent } from "./lista-notificacao/lista-notificacao-antigo.component";
import { ListaNotificacaoComponent } from "./lista-notificacao/lista-notificacao.component";
import { DetalheNotificacaoComponent } from "./detalhe-notificacao/detalhe-notificacao.component";
import { NotificacaoEventoPipe } from "./libs/pipes/notificacao-evento-pipe";
import { NotificacaoService } from "../common/service/notificacao.service";
import { NgSelectModule } from '@ng-select/ng-select';
import { NotificacaoTipoEventoPipe } from "./libs/pipes/notificacao-tipo-evento.pipe";
import { NotificacaoConfiguracaoEmailComponent } from "./notificacao-configuracao-email/notificacao-configuracao-email.component";
import { NotificacaoConfiguracaoRabbitMQComponent } from "./notificacao-configuracao-rabbitmq/notificacao-configuracao-rabbitmq.component";
import { NotificacaoEventosComponent } from "./notificacao-eventos/notificacao-eventos.component";
import { NotificacaoHistoricoComponent } from "./notificacao-historico/notificacao-historico.component";
import { NotificacaoTipoEnvioPipe } from "./libs/pipes/notificacao-tipo-envio.pipe";
import { NotificacaoConexaoApiComponent } from "./notificacao-conexao-api/notificacao-conexao-api.component";
import { NotificacaoConexaoWhatsappComponent } from "./notificacao-conexao-whatsapp/notificacao-conexao-whatsapp.component";
import { ContextMenuModule } from "ngx-contextmenu";


@NgModule({
    imports: [
        AppCommonModule,
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatCardModule,
        MatRadioModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatListModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        MatTabsModule,
        NgSelectModule,
        FormsModule,
        TableModule,
        ContextMenuModule.forRoot()
    ],
    providers: [
        { provide: FormBuilder, useClass: FormBuilder },
        { provide: LOCALE_ID, useValue: "pt-BR" },
        { useClass: NotificacaoService, provide: NotificacaoService }
    ],
    declarations: [
        ListaNotificacaoAntigoComponent,
        ListaNotificacaoComponent,
        DetalheNotificacaoComponent,
        NotificacaoConexaoApiComponent,
        NotificacaoConexaoWhatsappComponent,
        NotificacaoConfiguracaoEmailComponent,
        NotificacaoConfiguracaoRabbitMQComponent,
        NotificacaoEventosComponent,
        NotificacaoHistoricoComponent,
        NotificacaoEventoPipe,
        NotificacaoTipoEventoPipe,
        NotificacaoTipoEnvioPipe
    ],
    entryComponents: [
        DetalheNotificacaoComponent,
        NotificacaoConexaoApiComponent,
        NotificacaoConexaoWhatsappComponent,
        NotificacaoConfiguracaoEmailComponent,
        NotificacaoConfiguracaoRabbitMQComponent,
        NotificacaoEventosComponent,
        NotificacaoHistoricoComponent
    ],
    exports: [
        NotificacaoEventoPipe,
        NotificacaoTipoEventoPipe,
        NotificacaoTipoEnvioPipe
    ]
})

export class NotificacaoModule { }