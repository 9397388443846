import { GuidService } from "../service/guid.service";
import { isNull, isNullOrEmpty, isObjectEmpty } from "../utils";
import { UsuarioSimples } from "./usuario";

export class NNotificacaoDestinatario {
    id: number;
    notificacaoId: number;
    nome: string | null;
    celular: string | null;
    email: string | null;
    dataCadastro: Date;
    usuarioCadastroId: number;
    dataAlteracao: Date | null;
    usuarioAlteracaoId: number | null;
    ativo: boolean;

    usuarioCadastro: UsuarioSimples;
    usuarioAlteracao: UsuarioSimples | null;

    // Campos usados apenas no front
    guid: string;

    constructor() {
        this.guid = GuidService.newGuid();
        this.ativo = true;
    }

    static fromRaw(raw: any): NNotificacaoDestinatario {
        let model: NNotificacaoDestinatario = Object.assign(new NNotificacaoDestinatario(), raw);

        if (!isNull(raw.usuarioCadastro))
            model.usuarioCadastro = UsuarioSimples.fromRaw(raw.usuarioCadastro);

        if (!isNull(raw.usuarioAlteracao))
            model.usuarioAlteracao = UsuarioSimples.fromRaw(raw.usuarioAlteracao);

        return model;
    }
}
