import { isNull, isNullOrEmpty } from "../utils";

const validate = require("validate.js");

export class LimpezaFiltroArquivo {
    limpezaFiltroArquivoId: number;
    listaId: number;
    limpezaFiltroArquivoTipoId: LimpezaFiltroArquivoTipo;
    limpezaFiltroArquivoStatusId: LimpezaFiltroArquivoStatus;
    filtro: string;
    filtroRegras: string;
    diretorio: string;
    nome: string;
    totalRegistros: number;
    totalRegistrosSucesso: number;
    totalRegistrosErro: number;
    dataCadastro: Date;
    usuarioCadastro: number;
    exclusaoElasticInicio: Date;
    exclusaoElasticFim: Date;
    exclusaoBancoDadosInicio: Date;
    exclusaoBancoDadosFim: Date;
    registrosExcluidosElastic: number;
    registrosExcluidosBanco: number;
    erro: string;

    tempoExecucaoElastic: string;
    tempoExecucaoBancoDados: string;

    porcentagem: number;
    executados: number;
    restantes: number;

    constructor() { }

    validar() {
        var validacao: any = {
            nome: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            },
            listaId: {
                presence: { allowEmpty: false, message: '^campoObrigatorio' }
            }
        };

        return validate.validate(this, validacao);
    }

    static fromRaw(raw: any): LimpezaFiltroArquivo {
        return Object.assign(new LimpezaFiltroArquivo(), raw);
    }

    static fromRawArray(raw: Array<any>): Array<LimpezaFiltroArquivo> {
        return raw.map((r: any) => Object.assign(new LimpezaFiltroArquivo(), r))
    }
}

export enum LimpezaFiltroArquivoTipo {
    Filtro = 1,
    Arquivo = 2
}

export enum LimpezaFiltroArquivoStatus {
    AguardandoProcessamento = 1,
    ProcessandoBuscaItens = 2,
    AguardandoExclusaoBanco = 3,
    ProcessandoExclusaoBanco = 4,
    Concluido = 5,
    ConcluidoErros = 6
}
